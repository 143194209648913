<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
					<li class="nav-item">
						<a id="tab-hirakudmap" href="#pane-hirakudmap" class="nav-link active" data-toggle="tab"
							role="tab">Map</a>
					</li>
					<li class="nav-item">
						<a id="tab-hirakudinfo" href="#pane-hirakudinfo" class="nav-link" data-toggle="tab"
							role="tab">Information</a>
					</li>
				</ul>

				<div id="accordion" class="tab-content" role="tablist">
					<div id="pane-hirakudmap" class="card tab-pane fade show active" role="tabpanel"
						aria-labelledby="tab-hirakudmap">
						<div class="card-header" role="tab" id="heading-A">
							<h5 class="mb-0">
								<a data-toggle="collapse" href="#collapse-hirakudmap" aria-expanded="true"
									aria-controls="collapse-hirakudmap">
									Map
								</a>
							</h5>
						</div>
						<div id="collapse-hirakudmap" class="collapse show" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-hirakudmap">
							<div class="card-body">
								<div class="map" id="hirakudWLmap">
									<div class="gle-icon">
										<a href="javascript:void(0)">
											<img src="assets/images/icon-street.png" (click)='streetchange()'
												class="img-fluid" alt="Street View" title="Street View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-satellite.png" class="img-fluid"
												(click)='stelitechange()' alt="Satellite View" title="Satellite View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-topo.png" class="img-fluid"
												(click)='topochange()' alt="Topo View" title="Topo View">
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div id="pane-hirakudinfo" class="card tab-pane fade" role="tabpanel"
						aria-labelledby="tab-hirakudinfo">
						<div class="card-header" role="tab" id="heading-hirakudinfo">
							<h5 class="mb-0">
								<a class="collapsed" data-toggle="collapse" href="#collapse-hirakudinfo"
									aria-expanded="false" aria-controls="collapse-hirakudinfo">
									Information
								</a>
							</h5>
						</div>
						<div id="collapse-hirakudinfo" class="collapse" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-hirakudinfo">
							<div class="card-body">
								<h4 class="card-title">
									Hirakud Wildlife Division
									<span class="custom-down">
										<button type="" (click)="print()"
											class="btn btn-sm btn-info mr-1"><i
												class="fa fa-file-pdf">&nbsp;</i>Information</button>
										<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
												class="fa fa-file-pdf">&nbsp;</i>Map</button>
									</span>
								</h4>
								<div id="printcontent">
									<div class="row">
										<div class="col-md-9">
											<div class="table-responsive">
												<table class="table table-sm table-bordered">
													<tbody>
														<tr>
															<td><strong>District : </strong></td>
															<td>Bargarh</td>
														</tr>
														<tr>
															<td><strong>Geographical Extent : </strong></td>
															<td>Longitude 83° 30'E and 83° 46'E & latitude 21° 28'N and
																21° 44'N</td>
														</tr>
														<tr>
															<td><strong>Climate : </strong></td>
															<td>8°- 20°C in winters to a high of 20°- 48°C in summers
																and average rainfall is 369.34 mm (August) and minimum
																average rainfall is 18.80 mm (November)</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div class="col-md-3">
											<img src="assets/images/wildlifeunits-hirakud-01.jpg"
												class="img-fluid float-right img-inner" alt="image">
										</div>
									</div>
									<p>This Hirakud Wildlife Division, Sambalpur has been created vide Government
										Resolution NO.1F(A)-100/2003/13228/ F&E dt.8.8.2003 of Forest and Environment
										Department with divisional headquarters at Sambalpur. The Division office is
										located near office of the Regional Chief Conservator of Forests, Sambalpur. The
										total geographical area of Hirakud Wildlife Division,Sambalpur is 477.25 Sq.
										Kms. Since then Protection and development of Wildlife is one of the top
										priorities in the present scenario to have a positive impact of dwindling
										environmental conditions.The Debrigarh Sanctuary lies in this division and it is
										under the territorial jurisdiction of Hirakud Wildlife Division.The sanctuary is
										like an emerald nestled in the serene blue waters of Hirakud Reservoir. The
										Reservoir is the largest man made water body in Asia and flanks about one third
										of the sanctuary boundary on the north and eastern side.</p>
									<h4 class="card-title">Flora </h4>
									<p>Sal, a gregarious species, is mostly confined to foot hills, valleys and
										sheltered pockets where moisture status of soil is moderate. Vegetation on hill
										slopes and upper portion is predominantly miscellaneous consisting of high
										proportion of Anogeissus latifolia (Dhaura), Lannea coromandelica (Moi),
										Boswellia serrata (Salai), Cleistanthus collinus (Karada) and Dalbergia
										paniculata (Barabakulia).Bamboos occur in almost all parts of the division over
										extensive areas of forests. The Species of bamboo covering large tracts of Hills
										is Dendrocalamus strictus (Salia bamboo).The common trees are Moi, Girdhini,
										Gelgela, Dhaura, Kekut etc. The under growths are Kurdu, Gangaseoli, Dhatuki,
										Thelko, Telia, Khajuri, Thua, etc. The grasses are Sinkula, Sabai, Thatch Grass
										(Dab) in marshy areas close to reservoir and their occurrence is light. Commonly
										found climbers are Siali, Dantari, Latapalas,etc.</p>
									<h4 class="card-title">Fauna </h4>
									<p>Herbivores such as Spotted deer, Sambhar, Four horned Antelope, Nilgai, and
										Carnivores such as Leopard and other animals such as Indian Gaur, Bear, Wild
										Boar, Civet, Porcupine, Hanuman Langur etc. are noticed.Among birds Peafowls are
										seen in plenty. Other birds such as Eagle, Owl, Parrot, Flycatcher, Sunbird,
										Hornbill, Crow, Pigeon, Nightjar etc. are seen.Python, Cobra and other snakes
										are rarely seen. There are 19 numbers of elephants in this sanctuary and captive
										one named Kharshel. Wild life including leopards are very often sighted.Bison
										and Sambhar are endemic to this region. Nilgai is a rare species which is
										occasionally sighted.</p>
									<h4 class="card-title">Set-Ups</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td><strong>Administrative set-up (Range, Section, beats) :</strong>
													</td>
													<td>Ranges- 4, Sections- 12,Beats- 36</td>
												</tr>
												<tr>
													<td><strong>EDC / JFM set-up: </strong></td>
													<td>
														EDCs - 14, VSS - 0
													</td>
												</tr>
												<tr>
													<td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
													<td>No. of RF- 2, No. of PRF- 0, No. of DPF- 0, No. of UDPF- 0, No.
														of VF- 0</td>
												</tr>
											</tbody>
										</table>
									</div>
									<h4 class="card-title">Major Attraction</h4>
									<div class="row">
										<div class="col-md-12">
											<img src="assets/images/wildlifeunits-hirakud-02.jpg"
												class="img-fluid float-right img-inner" alt="image">
											<ul class="custom-list">
												<li>The reservoir at the Hirakud lake is the Centre of attraction.</li>
												<li>The sighting of wild animal is a major attraction.</li>
												<li>The wild animal conservation centre at Motijharan in Sambalpur which
													is given the status of small zoo.</li>
												<li>The Hirakud dam project is also one of the major tourist attraction.
												</li>
												<li>Ecotourism Complex having 12 double bedded cottage and one 4 bedded
													cottage. The booking is done online from the website
													www.ecotourodisha.com .</li>
												<li>Boating facilities is available at Barakhandia Eco-tourism complex.
													The hiring cost will be Rs.1500/- per hour and maximum perosn
													allowed 7 numbers</li>
											</ul>
										</div>
									</div>
									<h4 class="card-title">Salient Detail</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td width="15%"><strong>HQ Address :</strong></td>
													<td width="85%">Hirakud Wildlife Division,Motijharan,near deer
														park,Sambalpur- 768001</td>
												</tr>
												<tr>
													<td><strong>Contact officer : </strong></td>
													<td>
														<span class="address-inner"><i
																class="fa fa-user">&nbsp;</i></span>Divisional Forest
														Officer <br>
														<span class="address-inner"><i
																class="fa fa-map-marker">&nbsp;</i></span>Hirakud
														Wildlife Division, Sambalpur<br>
														<span class="address-inner"><i
																class="fa fa-phone">&nbsp;</i></span>0663-2548743<br>
														<span class="address-inner"><i
																class="fa fa-envelope">&nbsp;</i></span>dfohirakudwl.od@gov.in
														& hirakudwildlife1@gmail.com<br>
													</td>
												</tr>
												<tr>
													<td><strong>Key activities : </strong></td>
													<td>Regular patrolling by staff and anti-smuggling squad to prevent
														illicit felling and poaching is ensured. There are 4 watch
														towers at Patheidurga,Budduma in Lakhanpur range.
														Parbatitong,Barkhandia in Kamgaon range. There are 8 numbers of
														check gates,5 in Lakhanpur range and 3 in Kamgaon range. 114.6km
														of forest road maintained for internal supervision and
														protection , patrolling. For conservation and management of
														wildlife SMC activity,maintenance of salt ,meadow development,
														waterbody are maintain</td>
												</tr>
												<tr>
													<td><strong>Places to see : </strong></td>
													<td>Hirakud dam while going to Sanctuary , Eco cottages at
														Barkhandia ,Watch tower at Parbatitong ,Barkhandia ,Ushakothi
														fall in side Sanctuary. Ghanteswari temple and Huma temple
														,Samleswari Temple within a radius of 50 kms out side Sanctuary
														are other tourist attraction. Sighting of wild animal from 3.00
														pm to 6.00 pm and 6.00 am to 8.00 am during October to June is
														possible.</td>
												</tr>
											</tbody>
										</table>
									</div>
									<p class="text-danger"><small><i
												class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
											</strong>The information contained herein are provided solely for
											informational purposes only.The content is dynamic and may undergo change
											from time to time. The snapshot of the content at a particular time is not
											to be referred as a legally binding document.</small></p>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>
