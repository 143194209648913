<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <ul id="tabs" class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a id="tab-kapilashPAmap" href="#pane-kapilashPAmap" class="nav-link active" data-toggle="tab"
              role="tab">Map</a>
          </li>
          <li class="nav-item">
            <a id="tab-kapilashPAinfo" href="#pane-kapilashPAinfo" class="nav-link" data-toggle="tab"
              role="tab">Information</a>
          </li>
        </ul>

        <div id="accordion" class="tab-content" role="tablist">
          <div id="pane-kapilashPAmap" class="card tab-pane fade show active" role="tabpanel"
            aria-labelledby="tab-kapilashPAmap">
            <div class="card-header" role="tab" id="heading-kapilashPAmap">
              <h5 class="mb-0">
                <a data-toggle="collapse" href="#collapse-kapilashPAmap" aria-expanded="true"
                  aria-controls="collapse-kapilashPAmap">
                  Map
                </a>
              </h5>
            </div>
            <div id="collapse-kapilashPAmap" class="collapse show" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-kapilashPAmap">
              <div class="card-body">
                <div class="map" id="mapkapilash">
                  <div class="gle-icon">
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid"
                        alt="Street View" title="Street View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()'
                        alt="Satellite View" title="Satellite View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View"
                        title="Topo View">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="pane-kapilashPAinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-kapilashPAinfo">
            <div class="card-header" role="tab" id="heading-kapilashPAinfo">
              <h5 class="mb-0">
                <a class="collapsed" data-toggle="collapse" href="#collapse-kapilashPAinfo" aria-expanded="false"
                  aria-controls="collapse-kapilashPAinfo">
                  Information
                </a>
              </h5>
            </div>
            <div id="collapse-kapilashPAinfo" class="collapse" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-kapilashPAinfo">

              <div class="card-body">

                <h4 class="card-title">
                  Kapilash Sanctuary
                  <span class="custom-down">
                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i
                        class="fa fa-file-pdf">&nbsp;</i>Information</button>
                        <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                  </span>
                </h4>
                <div id="printcontent">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td><strong>Forest Division : </strong></td>
                              <td>Dhenkanal (T) Forest Division</td>
                            </tr>
                            <tr>
                              <td><strong>District : </strong></td>
                              <td>Dhenkanal</td>
                            </tr>
                            <tr>
                              <td><strong>Date of Notification : </strong></td>
                              <td>31-03-2011</td>
                            </tr>
                            <tr>
                              <td><strong>Notified Area : </strong></td>
                              <td>12559.59 Ha or 125.5 sq. kms</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-2 video-box no-print">
                      <a href="#" class="video-btn" data-toggle="modal"
                        data-src="https://www.youtube.com/embed/-x7pXnjTs-c" data-target="#myModal">
                        <h4>Kapilash Sanctuary</h4>
                        <div class="overlay">
                          <span class="icon">
                            <i class="fa fa-play-circle"></i>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="col-md-2">
                      <img src="assets/images/Kapilash.jpg" class="img-fluid float-right img-inner" alt="image">
                    </div>
                  </div>
                  <p>
                    Kapilash Wildlife Sanctuary in Dhenkanal district lies in Central Odisha which attracts tourists in
                    droves because of its unique floral, faunal and geomorphological diversity.There is an abudance sal,
                    saguan, kendu, kumbhi and mohua apart from other trees,shrubs, herbs & climber. There are mammals
                    like Elephant, wild boar, deer, monkeys,jungle cats, giant squirrels and birds like peacock, oride,
                    hill myna, parakeet, owl, grey hornbills etc. The dense forests and the presence of wild animals
                    draw many nature lovers to this area. A temple of Lord Shiva "Lord Chandrasekhar" is located at the
                    top of the hill. To get to the temple one has to climb 1390 nos. steps. The way to the temple is
                    thrilling with dense forests on both sides. There is a mortable road too. The singing birds,
                    colorful butterflies and amazing waterfalls mesmerize the visitors.
                  </p>
                  <h4 class="card-title">Quick Facts</h4>
                  <ul class="custom-list">
                    <li>Kapilash being a religious place having a Shiva shrine attracts people round the year from
                      Odisha West Bengal, Chhattisgarh and other neighboring State.</li>
                    <li>A temple of Lord Vishwanath is also there in Kapilash.</li>
                    <li>The Gharial breeding centre and science park are an added attraction for the tourist.</li>
                    <li>There is a small zoo at the foot of the Kapilash called" Kapilash Zoo".</li>
                    <li>One can go for boating situated beside the zoo.</li>
                  </ul>
                  <h4 class="card-title">How to reach</h4>
                  <p class="address-inner">
                    <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>From Dhenkanal-21 km by road. Dhenkanal
                    is well connected by road from Bhubaneswar 90km and from Cuttack 60km.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong>Train is available fron Howrah to
                    Cuttack and from Cuttack to Sambalpur.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>Nearest Air Port is at Bhubaneswar.
                  </p>
                  <h4 class="card-title">Facilities available</h4>
                  <ul class="custom-list">
                    <li>No Facility Available.</li>
                  </ul>
                  <h4 class="card-title">Salient Detail</h4>
                  <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                      <tbody>
                        <tr>
                          <td width="15%"><strong>Geographic Extent : </strong></td>
                          <td width="85%">Longitude 85° 40' to 85° 56'(East) Latitude 20° 35' to 20° 46' (North)</td>
                        </tr>
                        <tr>
                          <td><strong>Climate : </strong></td>
                          <td>Moderate</td>
                        </tr>
                        <tr>
                          <td><strong>Villages :</strong></td>
                          <td>None</td>
                        </tr>
                        <tr>
                          <td><strong>Contact officer : </strong></td>
                          <td>
                            <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest
                            Officer<br>
                            <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Dhenkanal Division,
                            Dhenkanal <br>
                            <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>(06762) 226717 <br>
                            <span class="address-inner"><i class="fa fa-fax">&nbsp;</i></span>(06762) 226717<br>
                            <span class="address-inner"><i
                                class="fa fa-envelope">&nbsp;</i></span>dfo.dhenkanal@gmail.com
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Best time to visit : </strong></td>
                          <td>October to March</td>
                        </tr>
                        <tr>
                          <td><strong>Accommodation : </strong></td>
                          <td>Kuanria, Chhamundia & Badmul</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
                      </strong>The information contained herein are provided solely for informational purposes only.The
                      content is dynamic and may undergo change from time to time. The snapshot of the content at a
                      particular time is not to be referred as a legally binding document.</small></p>
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
