<div id="fp-nav" class="fp-show-active right" style="margin-top: 0px;">
  <ul>
    <li>
      <a [routerLink]="['/']"><span></span></a>
      <a [routerLink]="['/']" class="fp-tooltip right">Amazing Wildlife</a>
    </li>
    <li>
      <a [routerLink]="['/slidercarousel']"><span></span></a>
      <a [routerLink]="['/slidercarousel']" class="fp-tooltip right">Discover Wildlife Odisha</a>
    </li>
    <li>
      <a [routerLink]="['/homecontent']" ><span></span></a>
      <a [routerLink]="['/homecontent']" class="fp-tooltip right">About Wildlife</a>
    </li>
    <li>
      <a [routerLink]="['/blogall']"><span></span></a>
      <a [routerLink]="['/blogall']" class="fp-tooltip right">Blog / Publication</a>
    </li>
    <li>
      <a [routerLink]="['/filtergallery']" class="active"><span></span></a>
      <a [routerLink]="['/filtergallery']" class="fp-tooltip right active">Gallery</a>
    </li>
    <li>
      <a [routerLink]="['/footerlanding']"><span></span></a>
      <a [routerLink]="['/footerlanding']" class="fp-tooltip right">Quick Links</a>
    </li>
  </ul>
</div>

<app-login></app-login>

<div class="loader-base" *ngIf="loader">
  <div class="loader"></div>
</div>
<div class="login-floating">
  <button mat-raised-button data-toggle="modal" data-target="#modalLogin"><i class="fa fa-lock">&nbsp;</i>Login</button>
</div>
<section class="slider-gallery pt-50 pb-50">

  <div class="container">

    <div class="row">
      <div class="col-md-8 col-sm-8 col-xs-12">
        <div class="section-title">
          <h2>Our Gallery</h2>
        </div>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-12">
        <a [routerLink]="['/gallery']" class="thm-btn style-2 mb-3 pull-right">View All</a>
      </div>
    </div>
    <!-- <div class="spinner text-center">
        <div *ngIf="loader" class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
      </div> -->
    <div class="row">

      <div *ngIf="Gtlist" class="gallery col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="text-center">
          <button class="btn btn-default filter-button active" id="all" onclick="call(this.id)">All</button>
          <button class="btn btn-default filter-button" *ngFor="let btn of Gtlist" id={{btn.gtype}}
            onclick="call(this.id)">
            {{btn.typename}}
          </button>
          <!-- <button class="btn btn-default filter-button" id="elephant" onclick="call(this.id)">Project Elephant</button>
            <button class="btn btn-default filter-button" id="tiger" onclick="call(this.id)">Project Tiger</button>
            <button class="btn btn-default filter-button" id="crocodile" onclick="call(this.id)">Crocodile Conservation</button>
            <button class="btn btn-default filter-button" id="turtle" onclick="call(this.id)">Sea Turtle Conservation</button> -->
        </div>
      </div>

      <a href="{{img.imgPath}}" *ngFor="let img of GalleryList" data-fancybox="gallery"
        class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter {{img.gtype}}">
        <img src={{img.imgPath}} class="image">
        <div class="overlay">
          <span class="icon">
            <i class="fa fa-search"></i>
          </span>
        </div>
      </a>

      <!-- <a href="assets/images/gallery-elephant-01.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter elephant" data-fancybox="gallery" data-caption="Project Elephant">
          <img src="assets/images/gallery-elephant-01.jpg" class="image">
          <div class="overlay">
            <span class="icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </a>

        <a href="assets/images/gallery-crocodile-01.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter crocodile" data-fancybox="gallery" data-caption="Crocodile Conservation">
          <img src="assets/images/gallery-crocodile-01.jpg" class="image">
          <div class="overlay">
            <span class="icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </a>

        <a href="assets/images/gallery-turtle-01.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter turtle" data-fancybox="gallery" data-caption="Sea Turtle Conservation">
          <img src="assets/images/gallery-turtle-01.jpg" class="image">
          <div class="overlay">
            <span class="icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </a>

        <a href="assets/images/gallery-elephant-02.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter elephant" data-fancybox="gallery" data-caption="Project Elephant">
          <img src="assets/images/gallery-elephant-02.jpg" class="image">
          <div class="overlay">
            <span class="icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </a>

        <a href="assets/images/gallery-turtle-02.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter turtle" data-fancybox="gallery" data-caption="Sea Turtle Conservation">
          <img src="assets/images/gallery-turtle-02.jpg" class="image">
          <div class="overlay">
            <span class="icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </a>

        <a href="assets/images/gallery-tiger-01.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter tiger" data-fancybox="gallery" data-caption="Project Tiger">
          <img src="assets/images/gallery-tiger-01.jpg" class="image">
          <div class="overlay">
            <span class="icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </a>

        <a href="assets/images/gallery-crocodile-02.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter crocodile" data-fancybox="gallery" data-caption="Crocodile Conservation">
          <img src="assets/images/gallery-crocodile-02.jpg" class="image">
          <div class="overlay">
            <span class="icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </a>

        <a href="assets/images/gallery-turtle-03.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter turtle" data-fancybox="gallery" data-caption="Sea Turtle Conservation">
          <img src="assets/images/gallery-turtle-03.jpg" class="image">
          <div class="overlay">
            <span class="icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </a>

        <a href="assets/images/gallery-elephant-03.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter elephant" data-fancybox="gallery" data-caption="Project Elephant">
          <img src="assets/images/gallery-elephant-03.jpg" class="image">
          <div class="overlay">
            <span class="icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </a>

        <a href="assets/images/gallery-tiger-02.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter tiger" data-fancybox="gallery" data-caption="Project Tiger">
          <img src="assets/images/gallery-tiger-02.jpg" class="image">
          <div class="overlay">
            <span class="icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </a>

        <a href="assets/images/gallery-turtle-04.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter turtle" data-fancybox="gallery" data-caption="Sea Turtle Conservation">
          <img src="assets/images/gallery-turtle-04.jpg" class="image">
          <div class="overlay">
            <span class="icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </a>

        <a href="assets/images/gallery-crocodile-03.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter crocodile" data-fancybox="gallery" data-caption="Crocodile Conservation">
          <img src="assets/images/gallery-crocodile-03.jpg" class="image">
          <div class="overlay">
            <span class="icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </a> -->

    </div>

  </div>

</section>
