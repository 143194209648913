<app-masterheader></app-masterheader>

<div class="wrapper">

    <app-sidebar></app-sidebar>

    <div id="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                        <div class="dashboard-header">
                            <h1>Squad Monitoring Dashboard</h1>
                            <div class="last-updated">
                                Last updated: {{ lastUpdated | date:'mediumTime' }}
                            </div>
                        </div>
           
                        <div class="card modern-card filter-card">
                            <div class="card-body">
                                <div class="row g-3">
                                    <div class="col-md-3">
                                        <label class="filter-label">Circle</label>
                                        <select class="form-control modern-select" (change)="onCircleChangeloadDashboard(circleSelect.value)"
                                            [(ngModel)]='circleid' [disabled]="circleDisabled" #circleSelect>
                                            <option value="0">All Circle</option>
                                            <option *ngFor="let circle of circleList" value={{circle.circleId}}>
                                                {{circle.circleName}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="filter-label">Division</label>
                                        <select class="form-control modern-select" (change)="onDivChangeloadWLDashboard(divisionSelect.value)"
                                            [(ngModel)]='divid' [disabled]="divisionDisabled" #divisionSelect>
                                            <option value="0">All Division</option>
                                            <option *ngFor="let division of divisionlist" value={{division.divisionId}}>
                                                {{division.divisionName}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="filter-label">Range</label>
                                        <select class="form-control modern-select" (change)="onRangeChangeloadDashboard(rangeSelect.value)"
                                            [(ngModel)]='rangeid' [disabled]="rangeDisabled" #rangeSelect>
                                            <option value="0">All Range</option>
                                            <option *ngFor="let range of rangelist" value={{range.rangeId}}>
                                                {{range.rangeName}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 d-flex align-items-end">
                                        <button class="btn btn-primary refresh-btn" (click)="refreshData()">
                                            <i class="mdi mdi-refresh"></i> Refresh Data
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div class="row summary-cards">
                            <div class="col-md-4">
                                <div class="card summary-card total-card">
                                    <div class="card-body">
                                        <div class="d-flex align-items-center">
                                            <div class="summary-icon">
                                                <i class="mdi mdi-account-group"></i>
                                            </div>
                                            <div class="ms-3">
                                                <h5>Total Squads</h5>
                                                <h2>{{squadDashboardSummary?.totalSquad}}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card summary-card reported-card">
                                    <div class="card-body">
                                        <div class="d-flex align-items-center">
                                            <div class="summary-icon">
                                                <i class="mdi mdi-check-circle"></i>
                                            </div>
                                            <div class="ms-3">
                                                <h5>Reported (24h)</h5>
                                                <h2>{{ squadDashboardSummary.reportedSquad }}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card summary-card not-reported-card">
                                    <div class="card-body">
                                        <div class="d-flex align-items-center">
                                            <div class="summary-icon">
                                                <i class="mdi mdi-alert-circle"></i>
                                            </div>
                                            <div class="ms-3">
                                                <h5>Not Reported (24h)</h5>
                                                <h2>{{ squadDashboardSummary.notReportedSquad }}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-md-4">
                         
                                <div class="card modern-card" *ngIf="totalSquadCountDivisionList.length > 0">
                                    <div class="card-header bg-transparent border-0">
                                    <h4 class="card-title modern-title">
                                        <i class="mdi mdi-account-group-outline text-primary me-2"></i>
                                        Squad Distribution by Division
                                    </h4>
                                    </div>
                                    <div class="card-body p-0">
                                    <div class="table-responsive modern-table-wrapper">
                                        <table class="table modern-table table-hover">
                                        <thead class="modern-table-header">
                                            <tr>
                                            <th class="ps-4">#</th>
                                            <th>Division</th>
                                            <th class="text-end pe-4">Registered</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of totalSquadCountDivisionList" 
                                                class="modern-table-row"
                                                [class.highlight-row]="item.index < 4">
                                            <td class="ps-4">{{ item.index }}</td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                <div class="division-badge bg-primary-soft">
                                                    {{ item.divisionName | slice:0:3 | uppercase }}
                                                </div>
                                                <span class="ms-3">{{ item.divisionName }}</span>
                                                </div>
                                            </td>
                                            <td class="text-end pe-4">
                                                <span class="count-badge">{{ item.totalCount }}</span>
                                            </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                    </div>
                                </div>
                                <div class="card" *ngIf="totalSquadCountRangeWiseList.length>0">
                                    <div class="card-body">
                                        <h4 class="card-title">Total No. of Squads Registered</h4>
                                        <div class="table-responsive" >
                                            <table id="totalSquadRangeTable" class="table table-bordered row-border hover"
                                                width="99%">
                                                <thead>
                                                    <tr>
                                                        <th>Sl. No.</th>
                                                        <th>Range Name</th>
                                                        <th>Squad Unit Id</th>
                                                        <th>TeamLeader</th>
                                                        <th>TeamLeader Mobile No.</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let report of totalSquadCountRangeWiseList; let i = index" 
                                                    [class.highlight-row]="i < 3"
                                                    >
                                                <td class="ps-3">{{ i + 1 }}</td>
                                                <td class="fw-medium">{{ report.divisionName }}</td>
                                                <td class="text-center">{{ report.totalCount}}</td>
                                                <td class="text-center">{{ report.totalCount}}</td>
                                                <td class="text-center">{{ report.totalCount}}</td>
                                                <td class="text-center">{{ report.totalCount}}</td>
                                                </tr>  
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title">Squad Reporting in Last 24 Hours</h4>
                                        <div class="table-responsive">
                                            <table id="squadreportTable" class="table table-bordered row-border hover"
                                                width="99%">
                                                <thead>
                                                    <tr>
                                                        <th>Sl. No.</th>
                                                        <th>Range Name</th>
                                                        <th>Squad Unit Id</th>
                                                        <th>TeamLeader</th>
                                                        <th>TeamLeader Mobile No.</th>
                                                        <th>Date & Time of Reporting</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Rourkela</td>
                                                        <td>675</td>
                                                        <td>675</td>
                                                        <td>675</td>
                                                        <td>675</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="card" *ngIf="totalSquadCountRangeWiseList.length>0">
                                    <div class="card-body">
                                        <h4 class="card-title">Squad Not Reported in Last 24 Hours</h4>
                                        <div class="table-responsive" >
                                            <table id="reportTable" class="table table-bordered row-border hover"
                                                width="99%">
                                                <thead>
                                                    <tr>
                                                        <th>Sl. No.</th>
                                                        <th>Range Name</th>
                                                        <th>Squad Unit Id</th>
                                                        <th>Team Leader</th>
                                                        <th>Team Leader Mobile No.</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let report of totalSquadCountRangeWiseList; let i = index" 
                                                    [class.highlight-row]="i < 3"
                                                    >
                                                <td class="ps-3">{{ i + 1 }}</td>
                                                <td class="fw-medium">{{ report.divisionName }}</td>
                                                <td class="text-center">{{ report.totalCount}}</td>
                                                <td class="text-center">{{ report.totalCount}}</td>
                                                <td class="text-center">{{ report.totalCount}}</td>
                                                <td class="text-center">{{ report.totalCount}}</td>
                                                </tr>  
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> -->
                
                        <div class="dashboard-container">
                            <div class="row g-3">
                        
                              <div class="col-lg-8">
                                <div class="card shadow-sm border-0">
                                  <div class="card-header bg-white border-0 pb-0">
                                    <div class="d-flex justify-content-between align-items-center">
                                      <h4 class="card-title mb-0">Consolidated Report in Last 24 Hours</h4>
                                      <div class="last-updated text-muted small">
                                        Last updated: {{ lastUpdated | date:'mediumTime' }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="card-body pt-2">
                                    <div class="table-responsive">
                                      <table class="table table-hover table-striped mb-0">
                                        <thead class="table-light">
                                          <tr>
                                            <th class="ps-3">#</th>
                                            <th>Division</th>
                                            <th class="text-center">Registered</th>
                                            <th class="text-center">Reported</th>
                                            <th class="text-center">Not Reported</th>
                                            <th class="text-center pe-3">% Reported</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let report of consolidatedReport; let i = index" 
                                              [class.highlight-row]="i < 3"
                                              >
                                            <td class="ps-3">{{ i + 1 }}</td>
                                            <td class="fw-medium">{{ report.divisionName }}</td>
                                            <td class="text-center">{{ report.totalCount | number }}</td>
                                            <td class="text-center text-success fw-medium">{{ report.reportedCount | number }}</td>
                                            <td class="text-center text-danger">{{ report.notReportedCount | number }}</td>
                                            <td class="text-center pe-3">
                                              <span class="badge rounded-pill" 
                                                    [ngClass]="{
                                                      'bg-success': (report.reportedCount / report.totalCount) >= 0.8,
                                                      'bg-warning': (report.reportedCount / report.totalCount) >= 0.5 && (report.reportedCount / report.totalCount) < 0.8,
                                                      'bg-danger': (report.reportedCount / report.totalCount) < 0.5
                                                    }">
                                                {{ (report.reportedCount / report.totalCount * 100) | number:'1.0-0' }}%
                                              </span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="card-footer bg-white border-0 pt-0">
                                    <div class="d-flex justify-content-between align-items-center">
                                      <div class="legend">
                                        <span class="legend-item me-3"><span class="legend-color bg-success"></span> ≥80% Reported</span>
                                        <span class="legend-item me-3"><span class="legend-color bg-warning"></span> 50-79% Reported</span>
                                        <span class="legend-item"><span class="legend-color bg-danger"></span> <50% Reported</span>
                                      </div>
                                      <button class="btn btn-sm btn-outline-primary" (click)="refreshData()">
                                        <i class="bi bi-arrow-clockwise me-1"></i> Refresh
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          
                              <div class="col-lg-4">
                                <div class="card shadow-sm border-0 h-100">
                                  <div class="card-header bg-white border-0">
                                    <h4 class="card-title mb-0">Top 10 Division in Squad Reporting Performance ( {{ showLast7Days ? 'Last 7 Days' : 'Last 30 Days' }})</h4>
                                  </div>
                                  <div class="card-body d-flex flex-column">
                                    <div class="chart-container flex-grow-1">
                                      <google-chart
                                        *ngIf="barChartData?.length"
                                        [title]="title"
                                        [type]="type"
                                        [data]="barChartData"
                                        [columns]="columns"
                                        [options]="options"
                                        [width]="width"
                                        [height]="height"
                                      ></google-chart>
                                      
                                      <div *ngIf="!barChartData?.length" class="no-data-placeholder">
                                        <div class="placeholder-content">
                                          <i class="bi bi-bar-chart-line text-muted"></i>
                                          <p>Performance data loading...</p>
                                          <div class="spinner-border text-primary spinner-border-sm" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div class="chart-footer mt-2">
                                      <div class="d-flex justify-content-between align-items-center">
                                        <small class="text-muted">Updated: {{ lastUpdated | date:'shortTime' }}</small>
                                        <div class="view-options">
                                          <button class="btn btn-sm btn-outline-secondary me-1" 
                                                  (click)="toggleTimeRange()">
                                            {{ showLast7Days ? 'Last 30 Days' : 'Last 7 Days' }}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>

                        
                </div>
            </div>
        </div>
    </div>
    <div id="footer">
        <app-masterfooter></app-masterfooter>
    </div>

</div>


