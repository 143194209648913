import { Component, OnInit } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-elephantanalyticsintrend',
  templateUrl: './elephantanalyticsintrend.component.html',
  styleUrls: ['./elephantanalyticsintrend.component.css']
})
export class ElephantanalyticsintrendComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  	(function ($) {

		$("document").ready(function () {
			$(".tab-slider--body").hide();
			$(".tab-slider--body:first").show();
		});

		$(".tab-slider--nav li").click(function () {
			$(".tab-slider--body").hide();
			var activeTab = $(this).attr("rel");
			$("#" + activeTab).fadeIn();
			if ($(this).attr("rel") == "tab2") {
				$('.tab-slider--tabs').addClass('slide');
			} else {
				$('.tab-slider--tabs').removeClass('slide');
			}
			$(".tab-slider--nav li").removeClass("active");
			$(this).addClass("active");
		});

		$(function () {
			$(document).ready(function () {

				$("#multiselect").CreateMultiCheckBox({ width: '100%', defaultText: 'Select Division ...', height: '250px' });
			});

			/*$('#queryBtn').click(function () {
				$('#queryContent').slideToggle("slow");
				$('.rotate').toggleClass("down");
			});*/
			/*$('.rotate').click(function () {
				$(this).toggleClass("down");
			});*/
		});

		$(function () {
			$("#datepicker, #datepicker2").datepicker({
				autoclose: true,
				todayHighlight: true
			}).datepicker('update', new Date());
		});

		$('#queryBtn').click(function(){
			$('#queryContent').animate({'width': 'toggle', 'height':'toggle'});
		})
		$(".rotate").click(function () {
		    $(this).toggleClass("down");
    	});


	})(jQuery);

  }

}
