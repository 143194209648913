import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from '../service/api.service';
declare var jQuery: any;
const baseUrlFile = environment.FILE_API_URL;

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  typelist: any;
  Imglist: any;
  GalleryList: any[];
  public loader: boolean=false;
  Gtlist: any[];

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.loadgalleryType();
    this.getAllGalleryImg();
  }

  getAllGalleryImg() {
    this.loader=true;
    this.apiService.request('GET', 'api/v1/cms/getAllGallery').subscribe((data: any) => {
      if (data) {
        this.Imglist=data;
        this.GalleryList = new Array() ;
        data.map(item=>{
          return {
            imgPath : baseUrlFile+'gallery/'+item['imgPath'],
            type: item['typemaster']['type'],
            typeId: item['typemaster']['typeId'],
            gtype: "g"+item['typemaster']['typeId']
          }
        }).forEach(item=> this.GalleryList.push(item));
        this.loader=false;
      }
    },err=>{
      this.loader = false;
    });
  }
  loadgalleryType(){
    this.loader=true;
    this.apiService.request('GET', 'api/v1/cms/getActiveGalleryType').subscribe((data: any) => {
      if (data) {
        this.Gtlist = new Array() ;
        data.map(item=>{
          return {
            typename: item['type'],
            gtype: "g"+item['typeId']
          }
        }).forEach(item=> this.Gtlist.push(item));
        this.loader=false;
      }
    },err=>{
      this.loader = false;
    });
  }

}
