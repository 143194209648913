import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProtectedareasBadramaComponent } from './protectedareas-badrama/protectedareas-badrama.component';
import { ProtectedareasBaisipalliComponent } from './protectedareas-baisipalli/protectedareas-baisipalli.component';
import { ProtectedareasBalukhandKonarkComponent } from './protectedareas-balukhand-konark/protectedareas-balukhand-konark.component';
import { ProtectedareasBhitarakanikaComponent } from './protectedareas-bhitarakanika/protectedareas-bhitarakanika.component';
import { ProtectedareasChandakaDampadaComponent } from './protectedareas-chandaka-dampada/protectedareas-chandaka-dampada.component';
import { ProtectedareasChilikaNalabanComponent } from './protectedareas-chilika-nalaban/protectedareas-chilika-nalaban.component';
import { ProtectedareasDebrigarhComponent } from './protectedareas-debrigarh/protectedareas-debrigarh.component';
import { ProtectedareasGahirmathaMarineComponent } from './protectedareas-gahirmatha-marine/protectedareas-gahirmatha-marine.component';
import { ProtectedareasHadgarhComponent } from './protectedareas-hadgarh/protectedareas-hadgarh.component';
import { ProtectedareasKarlapatComponent } from './protectedareas-karlapat/protectedareas-karlapat.component';
import { ProtectedareasKapilashComponent } from './protectedareas-kapilash/protectedareas-kapilash.component';
import { ProtectedareasKhalasuniComponent } from './protectedareas-khalasuni/protectedareas-khalasuni.component';
import { ProtectedareasKuldihaComponent } from './protectedareas-kuldiha/protectedareas-kuldiha.component';
import { ProtectedareasKotgarhComponent } from './protectedareas-kotgarh/protectedareas-kotgarh.component';
import { ProtectedareasLahkeriValleyComponent } from './protectedareas-lahkeri-valley/protectedareas-lahkeri-valley.component';
import { ProtectedareasNandankananComponent } from './protectedareas-nandankanan/protectedareas-nandankanan.component';
import { ProtectedareasSatkosiaGorgeComponent } from './protectedareas-satkosia-gorge/protectedareas-satkosia-gorge.component';
import { ProtectedareasSimilipalComponent } from './protectedareas-similipal/protectedareas-similipal.component';
import { ProtectedareasSunabedaComponent } from './protectedareas-sunabeda/protectedareas-sunabeda.component';


const routes: Routes = [
  {
    path:  'ProtectedareasBadrama',
    component: ProtectedareasBadramaComponent
  },
  {
    path:  'ProtectedareasBaisipalli',
    component: ProtectedareasBaisipalliComponent
  },
  {
    path:  'ProtectedareasBalukhandKonark',
    component: ProtectedareasBalukhandKonarkComponent
  },
  {
    path:  'ProtectedareasBhitarakanika',
    component: ProtectedareasBhitarakanikaComponent
  },
  {
    path:  'ProtectedareasChandakaDampada',
    component: ProtectedareasChandakaDampadaComponent
  },
  {
    path:  'ProtectedareasChilikaNalaban',
    component: ProtectedareasChilikaNalabanComponent
  },
  {
    path:  'ProtectedareasDebrigarh',
    component: ProtectedareasDebrigarhComponent
  },
  {
    path:  'ProtectedareasGahirmathaMarine',
    component: ProtectedareasGahirmathaMarineComponent
  },
  {
    path:  'ProtectedareasHadgarh',
    component: ProtectedareasHadgarhComponent
  },
  {
    path:  'ProtectedareasKarlapat',
    component: ProtectedareasKarlapatComponent
  },
  {
    path:  'ProtectedareasKapilash',
    component: ProtectedareasKapilashComponent
  },
  {
    path:  'ProtectedareasKhalasuni',
    component: ProtectedareasKhalasuniComponent
  },
  {
    path:  'ProtectedareasKuldiha',
    component: ProtectedareasKuldihaComponent
  },
  {
    path:  'ProtectedareasKotgarh',
    component: ProtectedareasKotgarhComponent
  },
  {
    path:  'ProtectedareasLahkeriValley',
    component: ProtectedareasLahkeriValleyComponent
  },
  {
    path:  'ProtectedareasNandankanan',
    component: ProtectedareasNandankananComponent
  },
  {
    path:  'ProtectedareasSatkosiaGorge',
    component: ProtectedareasSatkosiaGorgeComponent
  },
  {
    path:  'ProtectedareasSimilipal',
    component: ProtectedareasSimilipalComponent
  },
  {
    path:  'ProtectedareasSunabeda',
    component: ProtectedareasSunabedaComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProtectedareasRoutingModule { }
