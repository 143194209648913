import { Component, OnInit } from '@angular/core';
declare var jQuery: any;
declare var swal : any;

@Component({
  selector: 'app-commonelement',
  templateUrl: './commonelement.component.html',
  styleUrls: ['./commonelement.component.css']
})
export class CommonelementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  	(function($){

  		// Alert Modal Type
    $(document).on('click', '#success', function(e) {
      swal(
        'Success',
        'You clicked the <b style="color:green;">Success</b> button!',
        'success'
      )
    });

    $(document).on('click', '#error', function(e) {
      swal(
        'Error!',
        'You clicked the <b style="color:red;">error</b> button!',
        'error'
      )
    });

    $(document).on('click', '#warning', function(e) {
      swal(
        'Warning!',
        'You clicked the <b style="color:coral;">warning</b> button!',
        'warning'
      )
    });

    $(document).on('click', '#info', function(e) {
      swal(
        'Info!',
        'You clicked the <b style="color:cornflowerblue;">info</b> button!',
        'info'
      )
    });

    $(document).on('click', '#question', function(e) {
      swal(
        'Question!',
        'You clicked the <b style="color:grey;">question</b> button!',
        'question'
      )
    });

    // Alert With Custom Icon and Background Image
    $(document).on('click', '#icon', function(event) {
      swal({
        title: 'Custom icon!',
        text: 'Alert with a custom image.',
        imageUrl: 'https://image.shutterstock.com/z/stock-vector--exclamation-mark-exclamation-mark-hazard-warning-symbol-flat-design-style-vector-eps-444778462.jpg',
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: 'Custom image',
        animation: false
      })
    });

    $(document).on('click', '#image', function(event) {
      swal({
        title: 'Custom background image, width and padding.',
        width: 700,
        padding: 150,
        background: '#fff url(https://image.shutterstock.com/z/stock-vector--exclamation-mark-exclamation-mark-hazard-warning-symbol-flat-design-style-vector-eps-444778462.jpg)'
      })
    });


  // Alert Redirect to Another Link
    $(document).on('click', '#confirm', function(e) {
        swal({
        title: "Are you sure?",
        text: "You want to delete the record",
        type: "warning",
        confirmButtonText: "Yes",
        cancelButtonColor: '#d33',
        showCancelButton: true
        })
          .then((result) => {
          if (result.value) {
              swal(
                  'Deleted!',
                  'Your file has been deleted.',
                  'success'
                )
          } else if (result.dismiss === 'cancel') {
              swal(
                'Cancelled',
                'Your stay here :)',
                'error'
              )
          }
        })
    });

    })(jQuery)

  }
  success(msg){
    swal(
      'Success',
      '<b style="color:green;">"'+msg+'"</b>',
      'success'
    )
   }
   error(msg){
    swal(
      'Error!',
      '<b style="color:red;">"'+msg+'"</b>',
      'error'
    )
   }
   warning(msg){
    swal(
      'Warning!',
      '<b style="color:coral;">"'+msg+'"</b>',
      'warning'
    )
   }
   info(msg){
    swal(
      'Info!',
      '<b style="color:cornflowerblue;">"'+msg+'"</b>',
      'info'
    )
   }
   question(msg){
    swal(
      'Question!',
      '<b style="color:grey;">"'+msg+'"</b>',
      'question'
    )
   }
   icon(msg){
    swal({
      title: 'Custom icon!',
      text: 'Alert with a custom image.',
      imageUrl: 'https://image.shutterstock.com/z/stock-vector--exclamation-mark-exclamation-mark-hazard-warning-symbol-flat-design-style-vector-eps-444778462.jpg',
      imageWidth: 200,
      imageHeight: 200,
      imageAlt: 'Custom image',
      animation: false
    })
   }
   image(msg){
    swal({
      title: 'Custom background image, width and padding.',
      width: 700,
      padding: 150,
      background: '#fff url(https://image.shutterstock.com/z/stock-vector--exclamation-mark-exclamation-mark-hazard-warning-symbol-flat-design-style-vector-eps-444778462.jpg)'
    })
   }
   confirmDelete(){
    swal({
      title: "Are you sure?",
      text: "You want to delete the record",
      type: "warning",
      confirmButtonText: "Yes",
      cancelButtonColor: '#d33',
      showCancelButton: true
      })
        .then((result) => {
        if (result.value) {
            swal(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
        } else if (result.dismiss === 'cancel') {
            swal(
              'Cancelled',
              'Your stay here :)',
              'error'
            )
        }
      })
   }
   confirmUpdate(){
    swal({
      title: "Are you sure?",
      text: "You want to Update the record",
      type: "warning",
      confirmButtonText: "Yes",
      cancelButtonColor: '#d33',
      showCancelButton: true
      })
        .then((result) => {
        if (result.value) {
            swal(
                'Update!',
                'Your record has been updated.',
                'success'
              )
        } else if (result.dismiss === 'cancel') {
            swal(
              'Cancelled',
              'Your stay here :)',
              'error'
            )
        }
      })
   }
}
