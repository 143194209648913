<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
					<li class="nav-item">
						<a id="tab-chandakamap" href="#pane-chandakamap" class="nav-link active" data-toggle="tab"
							role="tab">Map</a>
					</li>
					<li class="nav-item">
						<a id="tab-chandakainfo" href="#pane-chandakainfo" class="nav-link" data-toggle="tab"
							role="tab">Information</a>
					</li>
				</ul>

				<div id="accordion" class="tab-content" role="tablist">
					<div id="pane-chandakamap" class="card tab-pane fade show active" role="tabpanel"
						aria-labelledby="tab-chandakamap">
						<div class="card-header" role="tab" id="heading-chandakamap">
							<h5 class="mb-0">
								<a data-toggle="collapse" href="#collapse-chandakamap" aria-expanded="true"
									aria-controls="collapse-chandakamap">
									Map
								</a>
							</h5>
						</div>
						<div id="collapse-chandakamap" class="collapse show" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-chandakamap">
							<div class="card-body">
								<div class="map" id="chandakaWLmap">
									<div class="gle-icon">
										<a href="javascript:void(0)">
											<img src="assets/images/icon-street.png" (click)='streetchange()'
												class="img-fluid" alt="Street View" title="Street View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-satellite.png" class="img-fluid"
												(click)='stelitechange()' alt="Satellite View" title="Satellite View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-topo.png" class="img-fluid"
												(click)='topochange()' alt="Topo View" title="Topo View">
										</a>
									</div>
								</div>


							</div>
						</div>
					</div>

					<div id="pane-chandakainfo" class="card tab-pane fade" role="tabpanel"
						aria-labelledby="tab-chandakainfo">
						<div class="card-header" role="tab" id="heading-chandakainfo">
							<h5 class="mb-0">
								<a class="collapsed" data-toggle="collapse" href="#collapse-chandakainfo"
									aria-expanded="false" aria-controls="collapse-chandakainfo">
									Information
								</a>
							</h5>
						</div>
						<div id="collapse-chandakainfo" class="collapse" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-chandakainfo">
							<div class="card-body">
								<h4 class="card-title">
									Chandaka Wildlife Division
									<span class="custom-down">
										<button type="" (click)="print()"
											class="btn btn-sm btn-info mr-1"><i
												class="fa fa-file-pdf">&nbsp;</i>Information</button>
										<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
												class="fa fa-file-pdf">&nbsp;</i>Map</button>
									</span>
								</h4>
								<div id="printcontent">
									<div class="row">
										<div class="col-md-9">
											<div class="table-responsive">
												<table class="table table-sm table-bordered">
													<tbody>
														<tr>
															<td><strong>District : </strong></td>
															<td>Khordha, Cuttack</td>
														</tr>
														<tr>
															<td><strong>Geographical Extent : </strong></td>
															<td>Longitude 85°49'35" to 85°34'42"(East) and Latitude
																20°12'30" to 20°26'03" (North)</td>
														</tr>
														<tr>
															<td><strong>Climate : </strong></td>
															<td>Temperature - In Summer: 42°C ,In winter:11°C, Average
																annual rainfall is 1500 mm. Humidity - 77%(morning) and
																68% (evening)</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div class="col-md-3">
											<img src="assets/images/wildlifeunits-chandaka-01.jpg"
												class="img-fluid float-right img-inner" alt="image">
										</div>
									</div>
									<p>Chandaka Wildlife Division located in Khurda district of Odisha represents the
										north-eastern limits of Eastern Ghats. This is now an isolated forest, which
										once formed a part of vast Eastern Ghats forest and Central Indian Elephant
										range. Within this Division lies Chandaka-Dampara Sanctuary. The sanctuary lies
										partly within Khurda and partly in Cuttack Districts of Orissa state and in
										close proximity of the state capital, Bhubaneswar.</p>
									<h4 class="card-title">Flora </h4>
									<p>Flora is moderately diverse with intimate mixture of evergreen and deciduous
										elements. The area comes under semi-evergreen forest zone but the interplay of
										biotic factors has changed the original character of the vegetation. The
										resultant secondary growth is stunted in nature, which seldom goes beyond 10m in
										height. Species composition is heterogeneous. Thorny bamboo (Bambusa bambos)
										occurs pure in valleys and mixed with tree growth on hill slopes. In the overall
										habitat, Motamotaka (Helictres isora)and Kangada(Xylia xylocarpa)have the
										heighest relative density followed by Giringa (Pterospermum xylocarpum),
										Jharberi (Ziziphus mauritiana), Kontei koli (Ziziphus oenoplia), Chunkuli
										(Ziziphus rugosa) and Anantamool (Hemidesmus indicus). Siali (Bauhinia vahlii),
										Laha Palas (Butea superba), Bhualu (Cordia obligua) and Chadheigodia (Vitex
										Peduncularis) has the lowest relative density. In Bharatpur segment of the
										sanctuary, Ganga tulasi (Hyptis suaveolens), Modaphal (Helictres isora) and
										Poksunga (Chromolaena odorata) are the species forming the highest density while
										Bamboo, Muturi (Smilax zeylanica),Bajramuli (Sida rhombifolia) and Bhuin Nimbo
										(Andrographis paniculata ) are species forming the lowest density.</p>
									<h4 class="card-title">Fauna </h4>
									<p>Elephant is the keystone or flagship species in the forest ecosystem. Hanuman
										Langur, the macaque, three deer (spotted deer, barking deer, and mouse deer),
										wild pig, porcupine and hare form other herbivores. Among carnivores Leopard,
										sloth bear, hyena, wolf, fox, jackal, mongoose (common, small, ruddy). Civets
										(small, palm), tree shrew and Pangolins are important. The reptilian fauna
										include Bengal monitor, mud turtle, the Chameleon, Python, Cobra, Russels viper,
										Bamboo pit viper, Rat snake, Earth Boa etc.</p>
									<h4 class="card-title">Set-Ups</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td><strong>Administrative set-up (Range, Section, beats) :</strong>
													</td>
													<td>Ranges- 4, Sections- 11,Beats- 33</td>
												</tr>
												<tr>
													<td><strong>EDC/JFM set-up: </strong></td>
													<td>EDCs - 27, VSS - 30</td>
												</tr>
												<tr>
													<td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
													<td>No. of RF- 0, No. of PRF- 0, No. of DPF- 0, No. of UDPF- 0, No.
														of VF- 0</td>
												</tr>
											</tbody>
										</table>
									</div>
									<h4 class="card-title">Major Attraction</h4>
									<div class="row">
										<div class="col-md-12">
											<img src="assets/images/wildlifeunits-chandaka-02.jpg"
												class="img-fluid float-right img-inner" alt="image">
											<ul class="custom-list">
												<li>Bhubaneswar Capital City, Khandagiri & Udaygiri Caves, Dhauli
													Sthupa, State Museum, Regional Museum of Natural History,
													Planetarium, Nandankanan Biological Park, Lingaraj Temple, Ekamra
													Vana, Medicinal Plant Knowledge Centre at Patrapada, Smruti Vana &
													Smruti Batika, Jayadev Batika are all at a distance of 20 kms.</li>
												<li>Atri hot spring is at a distance of 50 kms.</li>
												<li>Shree Shree Giridhari Dev Temple, established in 1840 AD is a living
													temple constructed by King Kishori Mansingh at Dampara. This place
													of religious congregation was listed by Odisha State Archeology in
													2013 and conserved.</li>
												<li>Charchika Temple, Banki is at a distance of 30 kms. via Dampara.
												</li>
												<li>Ansupa the largest fresh water lake and eco-tourism destination is
													at a distance of 45 Kms.</li>
											</ul>
										</div>
									</div>
									<h4 class="card-title">Salient Detail</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td><strong>HQ Address :</strong></td>
													<td>Chandaka Wildlife Division, At-Bhubaneswar.</td>
												</tr>
												<tr>
													<td><strong>Contact officer : </strong></td>
													<td>
														<span class="address-inner"><i
																class="fa fa-user">&nbsp;</i></span>Divisional Forest
														Officer<br>
														<span class="address-inner"><i
																class="fa fa-map-marker">&nbsp;</i></span>Chandaka
														Wildlife Division Gajavihar, Barmunda, Bhubaneswar – 751003<br>
														<span class="address-inner"><i
																class="fa fa-phone">&nbsp;</i></span>0674 2355885<br>
														<span class="address-inner"><i
																class="fa fa-fax">&nbsp;</i></span>0674 2355400<br>
														<span class="address-inner"><i
																class="fa fa-envelope">&nbsp;</i></span>chandakadampara@gmail.com<br>
													</td>
												</tr>
												<tr>
													<td><strong>Key activities : </strong></td>
													<td>Net walking,Rope climbing ,Elephant ride</td>
												</tr>
												<tr>
													<td><strong>Programmes : </strong></td>
													<td>Nature camps are developed at Godibari and Derass</td>
												</tr>
												<tr>
													<td><strong>Places to see : </strong></td>
													<td>Chandaka-Dampara
														Sanctuary,Deras,Bharatpur,Kuasara,Godibari,Ambakhali,Baunsaberena,Bualigarh,etc.
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<p class="text-danger"><small><i
												class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
											</strong>The information contained herein are provided solely for
											informational purposes only.The content is dynamic and may undergo change
											from time to time. The snapshot of the content at a particular time is not
											to be referred as a legally binding document.</small></p>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>
