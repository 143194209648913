<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

        		<h4 class="card-title">Tender</h4>
            <table id="tableTender" class="table table-bordered responsive" width="100%" datatable [dtOptions]="dtOptions"  [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th width="6%">Sl. No.</th>
                  <th>Tender No.</th>
                  <th>Title</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>File</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let list of tenderList; let i = index" [attr.data-index]="i">
                  <td>{{i+1}} </td>
                  <td>{{list.tenderNum}}</td>
                  <td>{{list.title}}</td>
                  <td>{{ (list.publicationDate  !== 'null') ? (list.publicationDate | date:'dd-MM-yyyy')  : '' }}</td>
                  <td> {{ (list.publicationDate  !== 'null') ? (list.publicationDate   | date:'shortTime')  : '' }} </td>
                  <td> <a href="{{list.uploadPath}}" target="_blank" class="btn btn-sm btn-success"><i class="fa fa-file-pdf-o text-danger"></i></a></td>

                </tr>
              </tbody>

            </table>
        		<p class="text-danger mt-3"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>
