<div class="loader-base" *ngIf="loader">
  <div class="loader"></div>
</div>
<app-masterheader></app-masterheader>

<div class="wrapper">

  <app-sidebar></app-sidebar>

  <div id="content">

    <!-- <app-breadcrumb></app-breadcrumb> -->

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">User management</h4>
                  <form [formGroup]="registrationForm" (ngSubmit)="signup()">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="">First Name <span class="text-danger">*</span></label>
                          <input type="text" formControlName="fname"
                            [ngClass]="{ 'is-invalid': registrationForm.controls['fname'].invalid && (registrationForm.controls['fname'].dirty || registrationForm.controls['fname'].touched) }"
                            class="form-control" id="fname" placeholder="First Name">
                          <div
                            *ngIf="registrationForm.controls['fname'].invalid && (registrationForm.controls['fname'].dirty || registrationForm.controls['fname'].touched)"
                            class="text-danger mt-1">
                            <small *ngIf="registrationForm.controls['fname'].errors.required">
                              First Name is required.
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="">Middle Name <span class="text-danger"></span></label>
                          <input type="text" formControlName="mname" class="form-control" id="mname"
                            placeholder="Middle Name">
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="">Last Name <span class="text-danger">*</span></label>
                          <input type="text" formControlName="lname"
                            [ngClass]="{ 'is-invalid': registrationForm.controls['lname'].invalid && (registrationForm.controls['lname'].dirty || registrationForm.controls['lname'].touched) }"
                            class="form-control" id="lname" placeholder="Last Name">
                          <div
                            *ngIf="registrationForm.controls['lname'].invalid && (registrationForm.controls['lname'].dirty || registrationForm.controls['lname'].touched)"
                            class="text-danger mt-1">
                            <small *ngIf="registrationForm.controls['lname'].errors.required">
                              Last Name is required.
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="form-group">
                          <!-- (change)="emailCheckUnique($event.target.value)" -->
                          <label for="">Email ID <span class="text-danger">*</span></label>
                          <input type="email" formControlName="email"
                            [ngClass]="{ 'is-invalid': registrationForm.controls['email'].invalid && (registrationForm.controls['email'].dirty || registrationForm.controls['email'].touched) }"
                            class="form-control" id="email" placeholder="Email ID">
                          <div
                            *ngIf="registrationForm.controls['email'].invalid && (registrationForm.controls['email'].dirty || registrationForm.controls['email'].touched)"
                            class="text-danger mt-1">
                            <small *ngIf="registrationForm.controls['email'].errors.required">
                              E-mail is required.
                            </small>
                            <small *ngIf="registrationForm.controls['email'].errors.email">
                              Enter Valid E-mail
                            </small>
                          </div>
                          <!-- <div *ngIf="emailAlredyExist != ''" class="alert alert-danger">
                                                      {{emailAlredyExist}}
                                                    </div>      (change)="phoneCheckUnique($event.target.value)" -->
                        </div>
                      </div>
                 
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="">Mobile Number <span class="text-danger">*</span></label>
                          <input type="number" formControlName="userPhoneNumber"
                            [ngClass]="{ 'is-invalid': registrationForm.controls['userPhoneNumber'].invalid && (registrationForm.controls['userPhoneNumber'].dirty || registrationForm.controls['userPhoneNumber'].touched) }"
                            class="form-control" id="userPhoneNumber" placeholder="Mobile Number">
                          <div *ngIf="registrationForm.controls['userPhoneNumber'].invalid && (registrationForm.controls['userPhoneNumber'].dirty || registrationForm.controls['userPhoneNumber'].touched)"
                            class="text-danger mt-1">
                            <small *ngIf="registrationForm.controls['userPhoneNumber'].errors.required">
                              Phone Number is required.
                            </small>
                            <small *ngIf="registrationForm.controls['userPhoneNumber'].errors.pattern">
                              Phone Number is invalid. Phone Number must be at least 10 characters long.
                              Phone Number cannot be more than 10 characters long.
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="">Designation <span class="text-danger">*</span></label>
                          <select class="form-control" name="designation" formControlName="designation"
                            [ngClass]="{ 'is-invalid': registrationForm.controls['designation'].invalid && (registrationForm.controls['designation'].dirty || registrationForm.controls['designation'].touched) }"
                            id="designation" (change)="onRoleSelect(roleSelect.value)" #roleSelect required>
                            <option selected="selected" value="">Select Designation...</option>
                            <option *ngFor="let role of rolelist" value={{role.id}}>{{role.name}}</option>
                          </select>
                          <div
                            *ngIf="registrationForm.controls['designation'].invalid && (registrationForm.controls['designation'].dirty || registrationForm.controls['designation'].touched)"
                            class="text-danger mt-1">
                            <small *ngIf="registrationForm.controls['designation'].errors.required">
                              Designation is required.
                            </small>
                          </div>

                          <!-- <ng-multiselect-dropdown
                                                       formControlName="role"
                                                      [placeholder]="'Select Designation...'"
                                                      [data]="rolelist"
                                                      [settings]="dropdownSettings"

                                                      (onSelect)="onItemSelect($event)"
                                                      (onSelectAll)="onSelectAll($event)"
                                                    >
                                                    </ng-multiselect-dropdown> -->
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="isShownBox">
                      <div class="col-md-12">
                        <div class="user-box">
                          <div class="row">

                            <div class="col-md-2" *ngIf="isShownCircle">
                              <label>Circle</label>
                              <select class="form-control" id="circlewl"
                                (change)="getAllDivisionByCircleId(circleSelect.value)" formControlName="circlewl"
                                [(ngModel)]='circleid' [disabled]="circleDisabled" #circleSelect>
                                <option selected="selected" value="0">Select Circle</option>
                                <option *ngFor="let circle of circleList " value={{circle.circleId}}>
                                  {{circle.circleName}}
                                </option>
                              </select>
                            </div>
                            <div class="col-md-2" *ngIf="isShownDivision">
                              <label>Division</label>
                              <select class="form-control" id="divisionwl"
                                (change)="getAllRangeByDivId(divisionSelect.value)" formControlName="dividwl"
                                [(ngModel)]='divid' [disabled]="divisionDisabled" #divisionSelect>
                                <option selected="selected" value="0">Select Division</option>
                                <option *ngFor="let division of divisionlist " value={{division.divisionId}}>
                                  {{division.divisionName}}
                                </option>
                              </select>
                            </div>
                            <div class="col-md-2" *ngIf="isShownRange">
                              <label>Range</label>
                              <select class="form-control" id="rangewl"
                                (change)="getAllSectionByRangeId(rangeSelect.value)" #rangeSelect>
                                <option selected="selected" value="0">Select Range</option>
                                <option *ngFor="let range of rangelist " value={{range.rangeId}}>
                                  {{range.rangeName}}
                                </option>
                              </select>
                            </div>
                            <div class="col-md-2" *ngIf="isShownsection">
                              <label>Section</label>
                              <select class="form-control" id="sectionwl"
                                (change)="getAllBeatBySectionId(sectionSelect.value)" #sectionSelect>
                                <option selected="selected" value="0">Select Section</option>
                                <option *ngFor="let section of sectionlist " value={{section.secId}}>
                                  {{section.secName}}
                                </option>
                              </select>
                            </div>
                            <div class="col-md-2" *ngIf="isShownBeat">
                              <label>Beat</label>
                              <select class="form-control" id="beatwl" #beatSelect>
                                <option selected="selected" value="0">Select Beat</option>
                                <option *ngFor="let beat of beatlist " value={{beat.beatId}}>
                                  {{beat.beatName}}
                                </option>
                              </select>
                            </div>
                            <div class="col-md-3" *ngIf="isShownJuridicition">
                              <label for="">Juridicition / {{JuridicitionFor}} <span
                                  class="text-danger">*</span></label>
                              <select name="Juridicition" class="form-control" id="Juridicition" required>
                                <option selected="selected" value="">Select Juridicition...</option>
                                <option *ngFor="let item of Juridicition" value={{item.id}}>
                                  {{item.name}}
                                </option>
                              </select>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                     
                        <div class="col-md-4">
                          <!-- <div class="form-group">
                            <label for="username">Username <span class="text-danger">*</span></label>
                            <input type="text" class="form-control"
                                   id="username" formControlName="username" placeholder="Username"
                                   (keyup)="checkUsername($event)" maxlength="20">
                            <div *ngIf="registrationForm.controls['username'].touched || registrationForm.controls['username'].dirty">
                              <small *ngIf="registrationForm.controls['username'].hasError('required')" class="text-danger">
                                Username is required.
                              </small>
                              <small *ngIf="registrationForm.controls['username'].hasError('pattern')" class="text-danger">
                                Username can only contain letters (A-Z, a-z), numbers (0-9), and underscore (_).
                              </small>
                              <small *ngIf="registrationForm.controls['username'].hasError('maxlength')" class="text-danger">
                                Username cannot be more than 20 characters long.
                              </small>
                            </div>
                          </div> -->
                          <div class="form-group">
                            <label for="username">Username <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" id="username" formControlName="username" placeholder="Username"
                                   (input)="restrictInput($event)" maxlength="20" 
                                   [ngClass]="{ 'is-invalid': registrationForm.controls['username'].invalid && (registrationForm.controls['username'].dirty || registrationForm.controls['username'].touched) }" (change)="userNameCheckUnique($event.target.value)">
                            
                            <!-- Combined guideline message, shown only when the user starts typing -->
                            <small *ngIf="registrationForm.controls['username'].dirty" class="text-info">
                              Username can only contain letters (A-Z, a-z), numbers (0-9), and underscore (_), and cannot be more than 20 characters.
                            </small>
                          
                            <!-- Validation error messages -->
                            <div *ngIf="registrationForm.controls['username'].touched || registrationForm.controls['username'].dirty">
                              <small *ngIf="registrationForm.controls['username'].hasError('required')" class="text-danger">
                                Username is required.
                              </small>
                              <small *ngIf="registrationForm.controls['username'].hasError('pattern')" class="text-danger">
                                Invalid characters entered.
                              </small>
                              <small *ngIf="registrationForm.controls['username'].hasError('maxlength')" class="text-danger">
                                Username cannot be more than 20 characters long.
                              </small>
                            </div>
                          </div>
                          
                          
                          
                        </div>
                 
                      

                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="">Password <span class="text-danger">*</span></label>
                          <div class="input-group" id="show_hide_password">
                            <input type="password" class="form-control"
                              [ngClass]="{ 'is-invalid': registrationForm.controls['password'].invalid && (registrationForm.controls['password'].dirty || registrationForm.controls['password'].touched) }"
                              formControlName="password" id="password" placeholder="Password"
                              autocomplete="new-password">
                              <div class="input-group-addon pull-right">
                                <a href=""><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                              </div>
                          </div>

                          <div
                            *ngIf="registrationForm.controls['password'].invalid && (registrationForm.controls['password'].dirty || registrationForm.controls['password'].touched)"
                            class="text-danger mt-1">
                            <small *ngIf="registrationForm.controls['password'].errors.required">
                              Password is required.
                            </small>
                            <small *ngIf="registrationForm.controls['password'].errors.minlength">
                              Password must be 8 characters.
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="">Confirm Password <span class="text-danger">*</span></label>
                          <div class="input-group" id="show_hide_password_2">
                          <input type="password" class="form-control"
                            [ngClass]="{ 'is-invalid': registrationForm.controls['cpassword'].invalid && (registrationForm.controls['cpassword'].dirty || registrationForm.controls['cpassword'].touched) }"
                            formControlName="cpassword" id="cpassword" placeholder="Confirm Password">
                            <div class="input-group-addon pull-right">
                              <a href=""><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                            </div>
                          </div>
                          <div
                            *ngIf="registrationForm.controls['cpassword'].invalid && (registrationForm.controls['cpassword'].dirty || registrationForm.controls['cpassword'].touched)"
                            class="text-danger mt-1">
                            <small *ngIf="registrationForm.controls['cpassword'].errors.required">
                              Confirm Password is required.
                            </small>

                            <small *ngIf="registrationForm.controls['cpassword'].errors.confirmedValidator">
                              Password and Confirm Password must be match.
                            </small>
                          </div>
                        </div>
                      </div>

                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <div class="spinner text-center">
                          <div *ngIf="loader2" class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                        <button *ngIf="!loader2" [disabled]="registrationForm.invalid || loader2" type="submit"
                          class="btn btn-sm btn-success">Sign Up</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">View User</h4>
                  <form [formGroup]="userListForm" (ngSubmit)="onSubmitViewUserForm(userListForm.value)">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-4 form-group mb-1">
                            <label>Circle</label>
                            <select class="form-control" id="circlewl_userView" formControlName="userView_circle"
                              (change)="getAllDivisionByCircleId_userView(circleSelect_userView.value)"
                              [(ngModel)]='circleid_userView' [disabled]="circleDisabled_userView"
                              #circleSelect_userView>
                              <option value="0">All Circle</option>
                              <option *ngFor="let circle of circleList_userView " value={{circle.circleId}}>
                                {{circle.circleName}}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-4 form-group mb-1">
                            <label>Division</label>
                            <select class="form-control" id="divisionwl_userView" formControlName="userView_division"
                              (change)="getAllRangeByDivId_userView(divisionSelect_userView.value)"
                              [(ngModel)]='divid__userView' [disabled]="divisionDisabled_userView"
                              #divisionSelect_userView>
                              <option value="0">All Division</option>
                              <option *ngFor="let division of divisionlist_userView " value={{division.divisionId}}>
                                {{division.divisionName}}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-4 form-group mb-1">
                            <label>Range</label>
                            <select class="form-control" id="rangewl_userView" formControlName="userView_range"
                              (change)="getAllSectionByRangeId_userView(rangeSelect_userView.value)"
                              [(ngModel)]='rangeid_userView' [disabled]="rangeDisabled_userView" #rangeSelect_userView>
                              <option value="0">All Range</option>
                              <option *ngFor="let range of rangelist_userView " value={{range.rangeId}}>
                                {{range.rangeName}}
                              </option>
                            </select>
                          </div>

                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-3 form-group mb-1">
                            <label>Section</label>
                            <select class="form-control" id="sectionwl_userView" formControlName="userView_section"
                              (change)="getAllBeatBySectionId_userView(sectionSelect_userView.value)"
                              [(ngModel)]='sectionid_userView' #sectionSelect_userView>
                              <option value="0">All Section</option>
                              <option *ngFor="let section of sectionlist_userView " value={{section.secId}}>
                                {{section.secName}}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-3 form-group mb-1">
                            <label>Beat</label>
                            <select class="form-control" id="beatwl_userView" formControlName="userView_beat"
                              [(ngModel)]='beatid_userView' #beatSelect_userView>
                              <option value="0">All Beat</option>
                              <option *ngFor="let beat of beatlist_userView " value={{beat.beatId}}>
                                {{beat.beatName}}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-4 form-group mb-1">
                            <label>Designation</label>
                            <ng-multiselect-dropdown formControlName="role_userView"
                              [placeholder]="'Select Designation...'" [data]="rolelist" [settings]="dropdownSettings"
                              (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                            </ng-multiselect-dropdown>
                          </div>
                          <div class="col-md-2 form-group mb-1">
                            <div class="spinner text-center">
                              <div *ngIf="loader4" class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                            <button *ngIf="!loader4" type="submit" class="btn btn-sm btn-success custom-btn-top mr-1">View</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <hr>


                  <div  class="table-responsive loader-sm">
                    <!-- <div class="loader-base-sm"  *ngIf="smloader">
                                        <div class="loader-sm-sm"></div>
                                      </div> -->
                    <table id="userTable" class="table table-bordered row-border hover" datatable
                      [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" width="99%">
                      <thead>
                        <tr>
                          <th width="7%">Sl. No.</th>
                          <th>Name</th>
                          <th>Designation</th>

                          <th>Circle</th>
                          <th>Division</th>
                          <th>Range</th>
                          <th>Section</th>
                          <th>Beat</th>
                          <!-- <th>Wildlife Juridicition</th> -->

                          <th>Phone</th>
                          <th>Username</th>
                          <th>Status</th>
                          <th>Action</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let group of tabledata; let i = index" [attr.data-index]="i">
                          <td>{{i+1}} </td>
                          <td>{{group.firstName}} {{ (group.middleName !== 'null') ? group.middleName : '' }}
                            {{group.lastName}}</td>
                          <td>{{group.userDesignation}}</td>
                          <td>{{ (group.circleName !== 'null') ? group.circleName : '' }}</td>
                          <td>{{ (group.divisionName !== 'null') ? group.divisionName : '' }}</td>
                          <td>{{ (group.rangeName !== 'null') ? group.rangeName : '' }}</td>
                          <td>{{ (group.sectionName !== 'null') ? group.sectionName : '' }}</td>
                          <td>{{ (group.beatName !== 'null') ? group.beatName : '' }}</td>
                          <!-- <td>{{group.jurdictionName}}</td> -->
                          <td>{{group.userPhoneNumber}}</td>
                          <td>{{group.userName}}</td>
                          <td><span class="badge badge-pills badge-success" *ngIf="group.active">Active</span>
                            <span class="badge badge-pills badge-secondary" *ngIf="!group.active">Inactive</span>
                          </td>
                          <!-- <td>
                                                       <button type="button" class="btn btn-sm btn-toggle" data-toggle="button" aria-pressed="true" [checked]="group.active">
                                                            <div class="handle"></div>
                                                        </button> -->

                          <!-- <label class="switch">
                                                            <input type="checkbox" [checked]="group.active"   (change)="getUserByIDAndDeactivate(group.userName,group.active)">
                                                            <span class="slider round"></span>
                                                            <span class="absolute-no">Inactive</span>
                                                        </label>
                                                    </td> -->
                          <td>
                            <div class="dropdown">
                                <button
                                  class="btn btn-sm btn-info btn-icon btn-radius dropdown-toggle"
                                  type="button" id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false">
                                  <i class="fa fa-cog"></i>
                                </button>
                                <div class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton">
                                  <a class="dropdown-item" href="javascript:void(0)"  data-toggle="modal" 
                              data-target="#userManagementModal" (click)="getUserByID(group.userid)"><i class="fa fa-eye" aria-hidden="true">&nbsp;</i>Edit User</a>
                                    <a class="dropdown-item" href="javascript:void(0)" *ngIf="isAdmin" (click)="onClickResetDefaultPwd(group.userid)"><i class="fa fa-refresh" aria-hidden="true">&nbsp;</i>Reset Password</a>
                                </div>
                              </div>
                            <!-- <button class="btn btn-sm btn-info mr-1 no-print" title="View User"  data-toggle="modal" 
                              data-target="#userManagementModal" (click)="getUserByID(group.userid)"><i
                                class="fa fa-edit"></i></button>
                             <button class="btn btn-sm btn-danger" title="Reset Password" *ngIf="isAdmin" (click)="onClickResetDefaultPwd(group.userid)" ><i class="fa fa-refresh"></i></button> -->
                              	<!-- <button class="btn btn-sm btn-danger mr-1"
																			data-toggle="modal"
																			data-target="#resetDefaultPasswordModal"
																			id="resetPwdbtn" *ngIf="isAdmin"
																			(click)="resetDefaultPwd(group.userid)">
                                      <i class="fa fa-key"></i>
																		</button> -->
                            <!-- <button class="btn btn-sm btn-danger"><i class="fa fa-trash"></i></button> -->
                          </td>
                          
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="modal fade" id="userManagementModal" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLongTitle">Edit User Details</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="updateForm" (ngSubmit)="editUser()">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">First Name <span class="text-danger">*</span></label>
                    <input type="hidden" formControlName="userId" [(ngModel)]="userId">

                    <input type="text" formControlName="firstName"
                      [ngClass]="{ 'is-invalid': updateForm.controls['firstName'].invalid && (updateForm.controls['firstName'].dirty || updateForm.controls['firstName'].touched) }"
                      class="form-control" id="firstName" placeholder="First Name" [(ngModel)]="firstName">
                    <div
                      *ngIf="updateForm.controls['firstName'].invalid && (updateForm.controls['firstName'].dirty || updateForm.controls['firstName'].touched)"
                      class="text-danger mt-1">
                      <small *ngIf="updateForm.controls['firstName'].errors.required">
                        First Name is required.
                      </small>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">Middle Name</label>
                    <input type="text" class="form-control" formControlName="middleName" id="middlename"
                      placeholder="Middle Name" [(ngModel)]="middleName">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">Last Name <span class="text-danger">*</span></label>
                    <input type="text" formControlName="lastName"
                      [ngClass]="{ 'is-invalid': updateForm.controls['lastName'].invalid && (updateForm.controls['lastName'].dirty || updateForm.controls['lastName'].touched) }"
                      class="form-control" id="lastName" placeholder="First Name" [(ngModel)]="lastName">
                    <div
                      *ngIf="updateForm.controls['lastName'].invalid && (updateForm.controls['lastName'].dirty || updateForm.controls['lastName'].touched)"
                      class="text-danger mt-1">
                      <small *ngIf="updateForm.controls['lastName'].errors.required">
                        Last Name is required.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">Username <span class="text-danger">*</span></label>
                    <input type="text" formControlName="userName" class="form-control"
                      [ngClass]="{ 'is-invalid': updateForm.controls['userName'].invalid && (updateForm.controls['userName'].dirty || updateForm.controls['userName'].touched) }"
                      id="userName" placeholder="userName" (change)="userNameCheckUnique($event.target.value)">
                    <div
                      *ngIf="updateForm.controls['userName'].invalid && (updateForm.controls['userName'].dirty || updateForm.controls['userName'].touched)"
                      class="text-danger mt-1">
                      <small *ngIf="updateForm.controls['userName'].errors.required">
                        Username is required.
                      </small>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">Email ID <span class="text-danger">*</span></label>

                    <input type="email" formControlName="email"
                      [ngClass]="{ 'is-invalid': updateForm.controls['email'].invalid && (updateForm.controls['email'].dirty || updateForm.controls['email'].touched) }"
                      class="form-control" id="email" placeholder="Email ID" [(ngModel)]="email">
                    <div
                      *ngIf="updateForm.controls['email'].invalid && (updateForm.controls['email'].dirty || updateForm.controls['email'].touched)"
                      class="text-danger mt-1">
                      <small *ngIf="updateForm.controls['email'].errors.required">
                        E-mail is required.
                      </small>
                      <small *ngIf="updateForm.controls['email'].errors.email">
                        Enter Valid E-mail
                      </small>
                    </div>

                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">Contact Number <span class="text-danger">*</span></label>
                    <!-- <input type="number" class="form-control" id=""  formControlName="userPhoneNumber" placeholder="Contact Number" [(ngModel)]="userPhoneNumber"> -->
                    <input type="number" formControlName="userPhoneNumber"
                      [ngClass]="{ 'is-invalid': updateForm.controls['userPhoneNumber'].invalid && (updateForm.controls['userPhoneNumber'].dirty || updateForm.controls['userPhoneNumber'].touched) }"
                      class="form-control" id="userPhoneNumber" placeholder="Mobile Number"
                      [(ngModel)]="userPhoneNumber" (change)="phoneCheckUnique($event.target.value)">
                    <div
                      *ngIf="updateForm.controls['userPhoneNumber'].invalid && (updateForm.controls['userPhoneNumber'].dirty || updateForm.controls['userPhoneNumber'].touched)"
                      class="text-danger mt-1">
                      <small *ngIf="updateForm.controls['userPhoneNumber'].errors.required">
                        Phone Number is required.
                      </small>
                      <small *ngIf="updateForm.controls['userPhoneNumber'].errors.pattern">
                        Phone Number is invalid. Phone Number must be at least 10 characters long.
                        Phone Number cannot be more than 10 characters long.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">User Address</label>
                    <input type="text" class="form-control" formControlName="userAddress" id="userAddress"
                      placeholder="User Address">
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">User Status <span class="text-danger">*</span></label>

                    <select class="form-control" id="userStatus" formControlName="active">
                      <option value=true>Active</option>
                      <option value=false>Inactive</option>
                    </select>
                  </div>
                </div>

              </div>
              <hr>
              <div class="row">
                <div class="col-md-12 text-center">
                  <div class="spinner text-center">
                    <div *ngIf="loader3" class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <button *ngIf="!loader3" [disabled]="updateForm.invalid || loader3" type="submit"
                    class="btn btn-sm btn-success">Update</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  
    <div class="modal fade" id="resetDefaultPasswordModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle_delete" aria-hidden="true">
    <div class="modal-dialog modal-confirm">
      <div class="modal-content">
        <div class="modal-header flex-column">
          <div class="icon-box">
            <i class="material-icons">&#xE5CD;</i>
          </div>
          <h4 class="modal-title w-100">Are you sure?</h4>
        </div>
        <div class="modal-body">
          <p>Do you really want to Reset Password?</p>
        </div>
        <div class="modal-footer justify-content-center">
          <div class="spinner text-center">
            <div *ngIf="loader2" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-danger" *ngIf="!loader2"
            (click)="confirmReset()">Reset</button>
        </div>
      </div>
    </div>
  </div>

  </div>

  <div id="footer">
    <app-masterfooter></app-masterfooter>
  </div>

</div>
