import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from '../service/api.service';
declare var jQuery: any;
const baseUrlFile = environment.FILE_API_URL;

@Component({
  selector: 'app-slidermain',
  templateUrl: './slidermain.component.html',
  styleUrls: ['./slidermain.component.css']
})
export class SlidermainComponent implements OnInit {
  noticeList: any[];
  isExpired:boolean=true;
  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
  	(function($){
      $(document).ready(function(){
        $('#myCarousel').carousel({
          interval: 6000,
        })
      });
    })(jQuery);
    this.getAllNotice();
    this.isSliderBannerExpired();

  }
  isSliderBannerExpired(){
      const expirationDate = new Date('2025-04-01');  // Set the expiration date to April 01, 2025
      const currentDate = new Date();  // Get the current date

      // Check if the current date is past the expiration date
      if (currentDate > expirationDate) {
        this.isExpired = false;  // If expired, set isExpired to true
      } else {
        this.isExpired = true;  // Otherwise, set isExpired to false
      }
  }
  getAllNotice() {
    this.apiService.request('GET', 'api/v1/cms/getAllNotice').subscribe((data: any) => {
      if (data) {
        this.noticeList = new Array();
        var arrr=new Array();
        data.sort((a, b) => {
          return <any>new Date(b.noticeValidDate) - <any>new Date(a.noticeValidDate);
        });
        data.map(item => {
          return {
            noticeId: item['id'],
            uploadPath: baseUrlFile + 'notice/' + item['noticePath'],
            noticeName: item['noticeName'],
            noticeNumber: item['noticeNumber'],
            noticeValidDate: item['noticeValidDate'],
            noticeDate: item['noticeDate'],
            noticeIsExpire:this.isNoticeExpire(item['noticeValidDate'])
          }
        }).forEach(item => arrr.push(item));
        if(arrr.length>5){
          for (var i = 0; i < 5; i++) {
            this.noticeList.push(arrr[i]);
          }

        }else{
          for (var i = 0; i < arrr.length; i++) {
            this.noticeList.push(arrr[i]);
          }
        }
      }
    });
  }

  isNoticeExpire(date:Date){
    var fdate=new Date(date);
    const today = new Date();
    // 👇️ OPTIONAL!
    // This line sets the hour of the current date to midnight
    // so the comparison only returns `true` if the passed in date
    // is at least yesterday
    today.setHours(0, 0, 0, 0);
    return fdate > today;
  }
}
