import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { NotificationService } from '../service/notification.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-managesquard',
  templateUrl: './managesquard.component.html',
  styleUrls: ['./managesquard.component.css']
})
export class ManagesquardComponent implements OnInit {
  public loader: boolean=false;
    tabledata1:any[]=[];
    UserFilterForm!:FormGroup;
    dtTrigger: Subject<any> = new Subject<any>();
    dtOptions: any = {};
 
    circleList: any[]=[];
    divisionlist: any[]=[];
    rangelist: any[]=[];
    sectionlist: any[]=[];
    beatlist: any[]=[];

    squardList: any[]=[];
  constructor(
      private fb: FormBuilder,private notifyService: NotificationService,private router: Router, private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      dom: 'Bfrtip',
         buttons: [
             'copy', 'excel', 'pdf', 'print'
         ]
    };
    this.UserFilterForm = this.fb.group({
      circle:['0'],
      division:['0'],
      range:['0'],
      section:['0'],
      beat:['0'],
    });
    this.getAllCircle();
    this.getAllSquardUser();
  }
getAllCircle() {
    this.apiService.request('GET', 'api/v1/masters/getAllCircle').subscribe((data: any) => {
     if(data)
     {
       this.circleList=data;
     }
   });
 }
 getAllDivisionByCircleId(circle:any){
   if(circle!=0){
     this.apiService.request('GET', 'api/v1/masters/getAllDivisionByCircleId?id='+circle).subscribe((data: any) => {
     if(data)
     {
       this.divisionlist=data;
       this.rangelist=[];
       this.sectionlist=[];
       this.beatlist=[];
       this.UserFilterForm.patchValue({
        range:['0'],
        section:['0'],
        beat:['0'],
       });
     }
    });
  }
 }
 getAllRangeByDivId(div:any) {
   if(div!=0){
  this.apiService.request('GET', 'api/v1/masters/getAllRangeByDivid?id='+div).subscribe((data: any) => {
     if(data)
     {
       this.rangelist=data;
       this.sectionlist=[];
       this.beatlist=[];
       this.UserFilterForm.patchValue({
        section:['0'],
        beat:['0'],
       });
     }
   });
  }
 }
 getAllSectionByRangeId(range:any) {
   if(range!=0){
   this.apiService.request('GET', 'api/v1/masters/getAllSectionByRangeid?id='+range).subscribe((data: any) => {
     if(data)
     {
       this.sectionlist=data;
       this.beatlist=[];
       this.UserFilterForm.patchValue({
        beat:['0'],
       });
     }
    });
  }
 }
 getAllBeatBySectionId(section:any) {
   if(section!=0){
   this.apiService.request('GET', 'api/v1/masters/getAllBeatBySecId?id='+section).subscribe((data: any) => {
     if(data)
     {
       this.beatlist=data;
     }
   }); }
 }
 getAllSquardUser() {
  this.apiService.request('GET', '/api/v1/auth/getSquardUserByFilter?circle="'+this.UserFilterForm.value.circle+'"&division="'+this.UserFilterForm.value.division+'"&range="'+this.UserFilterForm.value.range+'"&section="'+this.UserFilterForm.value.section+'"&beat="'+this.UserFilterForm.value.beat).subscribe((data: any) => {
   if(data)
   {
     this.squardList=data;
   }
 });
}
 onSubmitFilter(){

 }
 resetFilter(){
  //this.UserFilterForm.reset();
  this.divisionlist=[];
  this.rangelist=[];
  this.sectionlist=[];
  this.beatlist=[];
  this.UserFilterForm.patchValue({
   circle :['0'], 
   division :['0'],
   range:['0'],
   section:['0'],
   beat:['0'],
  });
  this.getAllSquardUser();
 }

}
