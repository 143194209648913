

<div class="modal fade modal-custom" id="modalContact" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
        	<button type="button" class="close" data-dismiss="modal" aria-label="Close">
	          <span aria-hidden="true">&times;</span>
	        </button>
        	<div class="col-md-4">
        		<div class="bg-login"></div>
        	</div>
        	<div class="col-md-6 offset-md-1">
        		<h2 class="text-center mt-3">Contact</h2>
    			<div class="row icon-contact">
        			<div class="col-md-2">
        				<i class="fa fa-map-marker"></i>
        			</div>
        			<div class="col-md-10">
        				<p>
        					Office of the principal chief conservator of forests (Wildlife) and chief wildlife warden,<br>
        					Odisha Prakruti Bhawan, Plot no-1459,<br>
        					Saheed nagar, Bhubaneswar - 751007, Odisha, India
        				</p>
        			</div>
        		</div>
        		<div class="row icon-contact">
        			<div class="col-md-2">
        				<i class="fa fa-phone"></i>
        			</div>
        			<div class="col-md-10">
        				<p>
        					<strong>PCCF (WL) & CWLW : </strong>0674-2602251<br>
        					<strong>Addl. PCCF : </strong>0674-2602252<br>
        				</p>
                <p class="badge-pill bg-warning pt-1 pb-1">
                  <strong>Toll Free No. : </strong>18003456771
                </p>
        			</div>
        		</div>
        		<div class="row icon-contact">
        			<div class="col-md-2">
        				<i class="fa fa-envelope"></i>
        			</div>
        			<div class="col-md-10">
        				<p>
        					odishawildlife&#64;gmail.com
        				</p>
        			</div>
        		</div>				
        	</div>
        </div>
      </div>
    </div>
  </div>
</div>
