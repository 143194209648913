import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../service/api.service';
import { NotificationService } from '../../service/notification.service';

declare var jQuery: any;

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  feedbackForm: any;
 // public loader: boolean=false;

  constructor(private formBuilder: FormBuilder,private notifyService:NotificationService, private router: Router, private apiService: ApiService) { }


  ngOnInit(): void {

    this.feedbackForm = this.formBuilder.group({
      name: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        Validators.pattern("^[a-zA-Z ]*$") // Only letters and spaces allowed
      ]],
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,6}$') // Enhanced domain validation
      ]],
      
      userPhoneNumber: ['', [
        Validators.required,
        Validators.pattern("^\\d{10}$") // Enforces exactly 10 digits
      ]],
      
      comments: ['', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(250) // Restricting to avoid spam and ensure meaningful feedback
      ]],
    });
    var ds=this;
    (function($){
    //  $('#modalFeedback').on('shown.bs.modal', function (e) {
    //   ds.loader=false;
    //  ds.createCaptchaforFeedback();
    //  e.preventDefault();
    //  });
    }
    )(jQuery);
  }
  onSubmitForm(){
    try{
     // var y: number=parseInt(sessionStorage.getItem('feedBackcode'));
     // if (this.feedbackForm.value.captchaValue== y) {
    if (this.feedbackForm.valid)
    {
    //  this.loader=true;
    var feedbackdo ={
      name:this.feedbackForm.value.name,
      emailId:this.feedbackForm.value.email,
      phoneNumber:this.feedbackForm.value.userPhoneNumber,
      comments:this.feedbackForm.value.comments
    }
    return this.apiService.request('POST', 'api/v1/cms/addFeedback',feedbackdo).subscribe((response: any) => {
        if(response.message=='success'){
          this.feedbackForm.reset();
          this.notifyService.showSuccesswithTimeout("You have Successfully Submitted.","Success");
         // this.loader=false;
         }else{
          this.notifyService.showError("Kindly Resubmit","Error");
         }
        },(err)=>{
          //console.log(err);
       //   this.loader=false;
          this.notifyService.showError(err.error,"Error");
        }
        );
      }
      else{
       // this.loader=false;
      }
   // }
    }catch{
     // this.loader=false;
    }
  }
  createCaptchaforFeedback() {
    //console.log("hi")
    document.getElementById('captchafeedback').innerHTML = "";
    var charsArray ="0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
    let x = Math.floor((Math.random() * 10) + 1);
    let y=  Math.floor((Math.random() * 10) + 1);
    let captcha = x.toString() + " + " +y.toString() + " = ";

    var canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 100;
    canv.height = 50;
    var ctx = canv.getContext("2d");
    ctx.font = "25px Georgia";
    ctx.strokeText(captcha, 0, 30);
    document.getElementById("captcha").appendChild(canv);
    let z = x + y;
    sessionStorage.setItem('feedBackcode',z.toString());

  }

}
