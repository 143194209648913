<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <ul id="tabs" class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a id="tab-chandakaPAmap" href="#pane-chandakaPAmap" class="nav-link active" data-toggle="tab"
              role="tab">Map</a>
          </li>
          <li class="nav-item">
            <a id="tab-chandakaPAinfo" href="#pane-chandakaPAinfo" class="nav-link" data-toggle="tab"
              role="tab">Information</a>
          </li>
        </ul>

        <div id="accordion" class="tab-content" role="tablist">
          <div id="pane-chandakaPAmap" class="card tab-pane fade show active" role="tabpanel"
            aria-labelledby="tab-chandakaPAmap">
            <div class="card-header" role="tab" id="heading-chandakaPAmap">
              <h5 class="mb-0">
                <a data-toggle="collapse" href="#collapse-chandakaPAmap" aria-expanded="true"
                  aria-controls="collapse-chandakaPAmap">
                  Map
                </a>
              </h5>
            </div>
            <div id="collapse-chandakaPAmap" class="collapse show" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-chandakaPAmap">
              <div class="card-body">
                <div class="map" id="mapchandaka">
                  <div class="gle-icon">
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid"
                        alt="Street View" title="Street View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()'
                        alt="Satellite View" title="Satellite View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View"
                        title="Topo View">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="pane-chandakaPAinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-chandakaPAinfo">
            <div class="card-header" role="tab" id="heading-chandakaPAinfo">
              <h5 class="mb-0">
                <a class="collapsed" data-toggle="collapse" href="#collapse-chandakaPAinfo" aria-expanded="false"
                  aria-controls="collapse-chandakaPAinfo">
                  Information
                </a>
              </h5>
            </div>
            <div id="collapse-chandakaPAinfo" class="collapse" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-chandakaPAinfo">

              <div class="card-body">

                <h4 class="card-title">
                  Chandaka-Dampada Sanctuary
                  <span class="custom-down">
                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i
                        class="fa fa-file-pdf">&nbsp;</i>Information</button>
                        <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                  </span>
                </h4>
                <div id="printcontent">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td><strong>Forest Division : </strong></td>
                              <td>Chandaka (Wildlife) Division</td>
                            </tr>
                            <tr>
                              <td><strong>District : </strong></td>
                              <td>Khurda</td>
                            </tr>
                            <tr>
                              <td><strong>Date of Notification : </strong></td>
                              <td>30-12-1985</td>
                            </tr>
                            <tr>
                              <td><strong>Notified Area : </strong></td>
                              <td>193.39 sq. Km</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-2 video-box no-print">
                      <a href="#" class="video-btn" data-toggle="modal"
                        data-src="https://www.youtube.com/embed/b4YrZoeE5Ec" data-target="#myModal">
                        <h4>Chandaka-Dampada Sanctuary</h4>
                        <div class="overlay">
                          <span class="icon">
                            <i class="fa fa-play-circle"></i>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="col-md-2">
                      <img src="assets/images/cahn-pic.jpg" class="img-fluid float-right img-inner" alt="image">
                    </div>
                  </div>
                  <p>
                    Chandaka Sanctuary is a wildlife reserve located in north western fringe of Bhubaneswar in the
                    Indian state of Odisha.Chandaka –Dampara Wildlife Sanctuary comprises major forest area i.e.
                    notified forest blocks of Chandaka Wildlife Division. This Protected Area is notified in the Orissa
                    Gazette vide Notification No.21 dated 7th January 1994.
                  </p>
                  <h4 class="card-title">Quick Facts</h4>
                  <ul class="custom-list">
                    <li>It is a popular destination for solitude seekers, hunt for eco-tourists, laboratory for
                      researchers and temple of learning for all.</li>
                    <li>Chandaka Wildlife Sanctuary was declared primarily to preserve wild elephants and elephant
                      habitat.</li>
                    <li>A variety of snakes, like the python, and the monitor lizard can also be sighted there.</li>
                    <li>The bird life of the sanctuary is no less significant as large number of species of birds can be
                      sighted there.</li>
                    <li>Chandaka is a premier wildlife destination in Odisha.</li>
                  </ul>
                  <h4 class="card-title">How to reach</h4>
                  <p class="address-inner">
                    <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>Chandaka in about 20 km by road from
                    Bhubaneswar, which has good inter-state road linking all major cities in INDIA.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong> Nearest Railhead is Bhubaneswar on
                    Eastcost Rly., which has super fast trains connecting all major cities.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>Nearest airport Bhubaneswar (20 km) with
                    air connection to Bangalore, Kolkata, delhi, Mumbai, Chennai, Vizag, Hydrabad.
                  </p>
                  <h4 class="card-title">Facilities available</h4>
                  <ul class="custom-list">
                    <li>At present, camping at Godibari (3 suites) through online booking.</li>
                    <li>Camping at Godibari (another 5 suites and dinning) and Deras (8 cottages, dinning & view point)
                      are being developed and expected to be opened for visitors by October, 2017.</li>
                    <li>Safari vehicles (12 and 8 seaters) are expected by October, 2017 to make trips in designated
                      circuits.</li>
                    <li>Watch towers at strategic locations to experience wilderness of nature.</li>
                    <li>Nature trails near camping sites at Godibari and Deras.</li>
                    <li>Interpretation Centre and Souvenir Shop at Godibari.</li>
                    <li>Elephant ride.</li>
                    <li>Adventure sports.</li>
                  </ul>
                  <h4 class="card-title">Salient Detail</h4>
                  <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                      <tbody>
                        <tr>
                          <td width="15%"><strong>Geographic Extent : </strong></td>
                          <td width="85%">Longitude 85°49'35" to 85°34'42"(East) and Latitude 20°12'30" to 20°26'03"
                            (North)</td>
                        </tr>
                        <tr>
                          <td><strong>Climate : </strong></td>
                          <td>Temperature - In Summer: 42°C ,In winter:11°C, Average annual rainfall is 1500 mm.
                            Humidity - 77%(morning) and 68% (evening).</td>
                        </tr>
                        <tr>
                          <td><strong>Villages :</strong></td>
                          <td>57 villages(40 villages of Khordha District and 17 villages of Cuttack district)</td>
                        </tr>
                        <tr>
                          <td><strong>Contact officer : </strong></td>
                          <td>
                            <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest
                            Officer<br>
                            <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Chandaka Wildlife
                            Division Gajavihar, Barmunda, Bhubaneswar – 751003<br>
                            <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>0674 2355885 <br>
                            <span class="address-inner"><i class="fa fa-fax">&nbsp;</i></span>0674 2355400<br>
                            <span class="address-inner"><i
                                class="fa fa-envelope">&nbsp;</i></span>chandakadampara@gmail.com
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Best time to visit : </strong></td>
                          <td>Round the year.</td>
                        </tr>
                        <tr>
                          <td><strong>Accommodation : </strong></td>
                          <td>Watch towers can be occupied for animal watching during day time only.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
                      </strong>The information contained herein are provided solely for informational purposes only.The
                      content is dynamic and may undergo change from time to time. The snapshot of the content at a
                      particular time is not to be referred as a legally binding document.</small></p>
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
