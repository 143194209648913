import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationCheckService implements CanActivate{

  constructor(private router: Router,private notifyService:NotificationService,private auth:AuthService ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //If token data exist, user may login to application
    if (sessionStorage.getItem('token')) {
      let activateurl=route.url[0].path
      if(activateurl==="elephantwebreporting"){
        if(this.auth.isWebRoportingAccess()==true){
          return true;
        }else{
          this.notifyService.showError("Unauthorized","Error");
          this.router.navigate(['/']);
          return false;
        }
      }
      return true;
      }else{
        //console.log('FGD')
        this.notifyService.showError("Unauthorized","Error");
        // otherwise redirect to login page with the return url
        //this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
        return false;
      }

    }
}
