<div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div>
<div class="login-floating">
  <button mat-raised-button data-toggle="modal" data-target="#modalLogin"><i class="fa fa-lock">&nbsp;</i>Login</button>
</div>
<section class="event-style1 blog-content font-script">
	<div class="pt-50 pb-50">
		<div class="container">

	        <div class="row">
	            <div class="col-md-8 col-sm-8 col-xs-12">
	                <div class="section-title">
	                    <h2>Publication</h2>
	                </div>
	            </div>
	            <div class="col-md-4 col-sm-4 col-xs-12">
	                <a [routerLink]="['/Publication']" class="thm-btn style-2 mb-3 pull-right">All Publication</a>
	            </div>
	        </div>

	        <div class="row">
            <div class="col-md-2" *ngFor="let list of PubListArray">
              <a href="{{list.pdfPath}}" target="_blank">
                <div class="publication">
                  <img src={{list.thumbnilImgPath}} (error)="defaultUrl($event)"  class="img-fluid" alt="image">
                  <h4 class="title">{{list.publicationTitle}}</h4>
                </div>
              </a>
            </div>
              <!-- <div class="col-md-2">
                <a href="assets/files/WildlifeOdisha2016.pdf" target="_blank">
                  <div class="publication">
                    <img src="assets/images/WO2016.JPG" class="img-fluid" alt="image">
                    <h4 class="title">Wildlife Odisha - 2016</h4>
                  </div>
                </a>
              </div>
              <div class="col-md-2">
                <a href="assets/files/WildlifeOdisha2015.pdf" target="_blank">
                  <div class="publication">
                    <img src="assets/images/WO2015.JPG" class="img-fluid" alt="image">
                    <h4 class="title">Wildlife Odisha - 2015</h4>
                  </div>
                </a>
              </div>
              <div class="col-md-2">
                <a href="assets/files/DolphinsOdisha.pdf" target="_blank">
                  <div class="publication">
                    <img src="assets/images/DolphinsOdisha.jpg" class="img-fluid" alt="image">
                    <h4 class="title">Whales, Dolphins & Porpoise of Odisha</h4>
                  </div>
                </a>
              </div>
              <div class="col-md-2">
                <a href="assets/files/ThreatendedBird.pdf" target="_blank">
                  <div class="publication">
                    <img src="assets/images/ThreatendedBird.jpg" class="img-fluid" alt="image">
                    <h4 class="title">Threatended Birds of Odisha</h4>
                  </div>
                </a>
              </div>
              <div class="col-md-2">
                <a href="assets/files/Amphibians.pdf" target="_blank">
                  <div class="publication">
                    <img src="assets/images/Amphibian.jpg" class="img-fluid" alt="image">
                    <h4 class="title">Amphibians of Nanadankanan</h4>
                  </div>
                </a>
              </div>
              <div class="col-md-2">
                <a href="assets/files/Spiders.pdf" target="_blank">
                  <div class="publication">
                    <img src="assets/images/Spider.jpg" class="img-fluid" alt="image">
                    <h4 class="title">Spiders of Nanadankanan</h4>
                  </div>
                </a>
              </div> -->
			  </div>
	    </div>
	</div>
</section>
