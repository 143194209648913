<app-header *ngIf="isDashboard"></app-header>
<app-menu  *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
			        <li class="nav-item">
			            <a id="tab-mahanadimap" href="#pane-mahanadimap" class="nav-link active" data-toggle="tab" role="tab">Map</a>
			        </li>
			        <li class="nav-item">
			            <a id="tab-mahanadiinfo" href="#pane-mahanadiinfo" class="nav-link" data-toggle="tab" role="tab">Information</a>
			        </li>
			    </ul>
			    <div id="accordion" class="tab-content" role="tablist">
			        <div id="pane-mahanadimap" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-mahanadimap">
			            <div class="card-header" role="tab" id="heading-mahanadimap">
			                <h5 class="mb-0">
			                    <a data-toggle="collapse" href="#collapse-mahanadimap" aria-expanded="true" aria-controls="collapse-mahanadimap">
			                        Map
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-mahanadimap" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-mahanadimap">
			                <div class="card-body">
								<div class="map" id="mahanadiWLmap">
                                    <div class="gle-icon">
										<a href="javascript:void(0)">
                                            <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid" alt="Street View" title="Street View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()' alt="Satellite View" title="Satellite View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View" title="Topo View">
                                        </a>
                                    </div>
                                </div>


			                </div>
			            </div>
			        </div>

			        <div id="pane-mahanadiinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-mahanadiinfo">
			            <div class="card-header" role="tab" id="heading-mahanadiinfo">
			                <h5 class="mb-0">
			                    <a class="collapsed" data-toggle="collapse" href="#collapse-mahanadiinfo" aria-expanded="false" aria-controls="collapse-mahanadiinfo">
			                       Information
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-mahanadiinfo" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-mahanadiinfo">
			                <div class="card-body">
			                	<h4 class="card-title">
			                		Mahanadi Wildlife Division
			                		<span class="custom-down">
			                			<button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
							<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
								class="fa fa-file-pdf">&nbsp;</i>Map</button>
			                		</span>
			                	</h4>
                        <div id="printcontent">
                        <div class="row">
                        	<div class="col-md-9">
                        		<div class="table-responsive">
                            	<table class="table table-sm table-bordered">
                            		<tbody>
                            			<tr>
                            				<td><strong>District : </strong></td>
                            				<td>Angul, Nayagarh, Phulbani</td>
                            			</tr>
                                  <tr>
                                    <td><strong>Geographical Extent : </strong></td>
                                    <td>Longitude 84° 35' and 84° 58'E & Latitude 20° 37'N and 20° 19'N</td>
                                  </tr>
                                  <tr>
                                    <td><strong>Climate : </strong></td>
                                    <td>Extreme</td>
                                  </tr>
                            		</tbody>
                            	</table>
                            </div>
                        	</div>
                        	<div class="col-md-3">
                        		<img src="assets/images/wildlifeunits-mahanadi-01.jpg" class="img-fluid float-right img-inner" alt="image">
                        	</div>
                        </div>
                        <p>Mahanadi Wildlife Division was created vide Resolution No.13060/ F & E dt. 12th July, 1999 of Government of Orissa, Forest and Environment Department. The geographical area of this Division is 433.86 Sq Kms. comprising Baisipalli Wildlife Sanctuary (138.35 Sq Kms) & a part of Satkosia Gorge Sanctuary (265.51 Sq Kms). The whole division is a part of Satkosia Tiger Reserve. Baisipalli Wildlife Sanctuary is situated on the southern side of Satkosia Gorge Sanctuary. The area of this Division comes under two District administration viz. Boudh & Nayagarh and the Division Office is situated at Nayagarh.</p>
                        <h4 class="card-title">Flora </h4>
                        <p>Flora of these two sanctuaries is dominated by Sal and its associates like Bija, Asan, Amala, Amba, Bahada, Kurum, Dhoura, Kochila, Harida Palas, Kusum, Kendu, Neem, Oau, Panas, Rai, Siris, Sisoo, Sunari, Bamboo and in addition to this a number of shrubs, climbers and grasses are found. Among the grasses Sabai grass is an important member. Along with the above flora a number of gymnosperms, bryophytes and pteridophytes are also found which needs documentation. Bamboo brakes are very common in the area.</p>
                        <h4 class="card-title">Fauna </h4>
                        <p>Tiger, Leopard, Striped hyena, Wild dog, Indian Wolf, Fox, Sloth bear, Sambar, Civet Cat, Fishing cat, Leopard cat, Chousingha, Chital, Flying Squirrel, Wild boar, Gaur migratory population of Elephants, Gharial and Mugger crocodile in the Mahanadi River, monitor lizard, tortoises, king cobra, pythons and other snakes, hornbills, Hill mynas, Brahamany ducks and different varieties of both resident and migratory birds. Check list of Birds in Satkosia landscape has been updated from 167 numbers to 332 numbers.</p>
                        <h4 class="card-title">Set-Ups</h4>
                        <div class="table-responsive">
                        	<table class="table table-sm table-bordered">
                        		<tbody>
                              <tr>
                        				<td><strong>Administrative set-up (Range, Section, beats) :</strong></td>
                        				<td>Ranges- 4, Sections- 12,Beats- 36</td>
                        			</tr>
                        			<tr>
                        				<td><strong>EDC/JFM set-up:  </strong></td>
                        				<td>
                                  EDCs - 19, VSS - 0
                        				</td>
                        			</tr>
                        			<tr>
                                <td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
                                <td>	No. of RF- 0, No. of PRF- 0, No. of DPF- 0, No. of UDPF- 0, No. of VF- 0</td>
                              </tr>
                        		</tbody>
                        	</table>
                        </div>
                        <h4 class="card-title">Major Attraction</h4>
                        <div class="row">
                          <div class="col-md-12">
                            <img src="assets/images/wildlifeunits-mahanadi-02.jpg" class="img-fluid float-right img-inner" alt="image">
                            <ul class="custom-list">
                            	<li>Wilderness of forest & Mahanadi Gorge, chilling winter & pristine sand bars.</li>
                            	<li>Eco-Tourism at Badmul in Satkosia Gorge Sanctuary in the name of Satkosia Sands Resort operating since the year, 2015. Nine Swiss cottage tents on sand bar of river Mahanadi and three A/C deluxe cottages on the hill slope are there for accommodation for Eco-Tourists with other attractions like Jungle trekking, camp fire, sand sports, music festival, birding, boating etc.</li>
                              <li>River cruise for 45/30 mins on River Mahanadi at Satkosia Gorge. High resolution Binoculars & “know your Birds” programme for tourists.</li>
                              <li>Life saving jackets has been made compulsory for tourist during boating.</li>
                              <li>Camp fire on Sand bar. Swiss cottage tents on Mahanadi sand bar and A/C Deluxe Cottages on hill slope for accommodation of nature loves/ eco-tourists.</li>
                              <li>Watch a wide array of Birds (rare Indian Skimmers, Hill Mynas and Malabar pied Hornbills and many more), Crocodiles (endangered Gharial & Mugger), Butter files and other Wild animals.</li>
                              <li>Trekking & Jungle walk along Mahanadi River Bank within the wilderness of Tiger Reserve.</li>
                              <li>Gokulananda Temple at Sidhamula</li>
                              <li>Deer Park and Kuanria Dam at Kuanria, Daspalla.</li>
                            </ul>
                          </div>
                        </div>
                        <h4 class="card-title">Salient Detail</h4>
                        <div class="table-responsive">
                        	<table class="table table-sm table-bordered">
                        		<tbody>
                              <tr>
                        				<td width="15%"><strong>HQ Address :</strong></td>
                        				<td width="85%">Mahanadi Wildlife Division, Nayagarh At-College Road, Nayagarh P/o-Dist-Nayagarh, State-Odisha, PIN-752069.</td>
                        			</tr>
                        			<tr>
                        				<td><strong>Contact officer : </strong></td>
                        				<td>
                        					<span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest Officer<br>
                        				</td>
                        			</tr>
                        			<tr>
                        				<td><strong>Key activities : </strong></td>
                        				<td>Alternate livelihood Provision Supply of fuel efficient chullah Distribution of LPG on subsidized price Development of Meadow Eradication of weeds Initialization of Camera Traps in Core and Buffer of Tiger Reserve Formation of 500 Nos. of PIP in and around Tiger Reserve.</td>
                        			</tr>
                        			<tr>
                        				<td><strong>Programmes : </strong></td>
                        				<td>Functioning of Anti-poaching camps, Soil & Moisture Conservation programme, Development of Eco-Tourism</td>
                              </tr>
                              <tr>
                        				<td><strong>Places to see : </strong></td>
                        				<td>Badmul, Kuanria Deer Park , Sitalpani, Satakosia Gorge, Kankei Temple etc.</td>
                        			</tr>
                        		</tbody>
                        	</table>
                        </div>
                        <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>
                      </div>
			                </div>
			            </div>
			        </div>

			    </div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>
