import { Component, OnInit } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var jQuery: any;

@Component({
  selector: 'app-importantcirculars',
  templateUrl: './importantcirculars.component.html',
  styleUrls: ['./importantcirculars.component.css']
})
export class ImportantcircularsComponent implements OnInit {

	public data = [
	    {slno: '1', circularno: '258', date: '01-02-2018', circularname: 'schedule of rates',pdfpath:'assets/files/schedule_of_rates.pdf'},
	    {slno: '2', circularno: 'FE-WL-WLF-0001-2016/11656/F & E', date: '01-06-2017', circularname: 'Notification for Declaration the PCCF (WL) & CWLW ,Odisha as "Member Secretary" of Odisha Eco Touris',pdfpath:'assets/files/Notification_for_Declaration_the_PCCF(WL)&CWLW.pdf'},
	    {slno: '3', circularno: '6551', date: '30-03-2017', circularname: 'Regarding abolition of inter-state board Check- gates in Odisha',pdfpath:'assets/files/Regarding_abolition_of_inter_state _board_Check_gates.pdf'},
	    {slno: '4', circularno: 'GAD-AR-ARC-0001-2016/2900/CS', date: '09-02-2017', circularname: 'Implementation of the recommendations of the Administrative Reforms Commission regarding - "Adoption',pdfpath:'assets/files/Implementation_of_the_recommendations.pdf'},
	    {slno: '5', circularno: '7-23/2015-NTCA', date: '19-08-2016', circularname: 'Establish Tiger Safari In Buffer & Fringe Areas',pdfpath:'assets/files/EstablishTigerSafariInBuffer&FringeAreas.pdf'},
	    {slno: '6', circularno: '1-87/2015WL', date: '09-06-2016', circularname: 'Elephant Census Results 2015',pdfpath:'assets/files/schedule_of_rates.pdf'},
	    {slno: '7', circularno: '879', date: '07-06-2016', circularname: 'Relocation Of Villages From Sanctuaries NP',pdfpath:'assets/files/schedule_of_rates.pdf'},
	    {slno: '8', circularno: '7/854', date: '05-08-2015', circularname: 'National Park & Zoo In Odisha (Odia)',pdfpath:'assets/files/schedule_of_rates.pdf'},
	    {slno: '9', circularno: '24/26-89', date: '05-08-2015', circularname: 'National Park & Zoo In Odisha',pdfpath:'assets/files/schedule_of_rates.pdf'},
	    {slno: '10', circularno: '85/78', date: '15-05-2015', circularname: 'Tiger Elephant Biosphere Reserve',pdfpath:'assets/files/schedule_of_rates.pdf'},
	];

  constructor() { }

  dtOptions: any = {};

  ngOnInit(): void {

  	this.dtOptions = {
      responsive: true,
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: 'Bfrtip',
        buttons: [
            //'copy', 'excel', 'pdf', 'print'
        ]
    };

  	(function($){



    })(jQuery);

  }

}
