
<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

				<h4 class="card-title">Corridor</h4>
				<p>Elephants are big migratory animals and move a long distance in search of food and require substantial areas. But habitat loss, expansion of human habitation and fragmentation of traditional elephant corridors has forced the elephants to split into a number of meta- populations or herds and move to new areas in search of food and shelter. As a result of this, the interface of elephant and man has increased manifold. This results in conflicts with humans due to elephants raiding or destroying of agricultural fields/ crops. And as retaliation by villagers, there is killing of elephants either by electrocution or poisoning.</p>
				<p>In order to protect the animals and its habitat there is a need to connect these fragmented patches of forest, which is called a corridor. This corridor will not only help the animals move without any disturbances, but also increase the chance of genetic interchange. As the risk of inbreeding within the localized patch of forest will be reduced, it will result in a healthy population. </p>
				<p>Traditionally fourteen corridors have been identified in the State which were to be used by elephants during course of their migration. These corridors need to be protected. All round development like human settlements, roads, railway line, electric lines, canal and mining etc is the main cause of corridor fragmentation.</p>
				<p>For preparation of Corridor Management Plan, survey of each of the fourteen corridors has been done by the DFOs in the field. First task in this regard has been to know the extent of width of corridors. Generally the areas around the old corridor up to which elephants have reached during past has been tentatively taken as width of the corridor with due rationalisation to see the extent of problem which has to be faced while securing the corridor. Then in this width, broad land use survey has been done using Satellite imageries and ground truthing. Demographic survey has also been done to know the no. of villages, and house holds, village population along with other administrative units like Name of district, Thana and Tehsil covering the area. House hold survey has not been taken up at this stage.</p>
				<p>Due to fragmentation of traditional Corridors and degradation of habitat these elephants are in search of new habitat and corridors. Presence of elephants has been noticed in places where elephants have never been found before. It is being noticed that few elephants are using new corridors. To assess these new corridors, Government has engaged an expert Shri Kishor Choudhary to identify new corridors. He has identified 9 new corridors. Its detailed survey is being conducted and these 9 new corridors are not part of this Management Plan.</p>

				<h4 class="card-title">The Plan covers only 14 traditional corridors</h4>
				<div class="table-responsive">
					<table class="table table-bordered">
						<thead>
							<tr>
								<th>Sl. No.</th>
								<th>Name of the Corridor</th>
								<th>Extends over Districts</th>
								<th>Name of the Divisions</th>
								<th>Length of corridor (km)</th>
								<th>Width of corridor (km)</th>
								<th>Total area (sq.km)</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td>Badampahar (Mayurbhanj) - Dhobadhobin(Jharkhand) INTERSTATE</td>
								<td>Mayurbhanj</td>
								<td>Karanjia, Rairangapur</td>
								<td>16</td>
								<td>0.5 - 1.5</td>
								<td>24.3</td>
							</tr>
							<tr>
								<td>2</td>
								<td>Badampahar (Mayurbhanj) - Karida(Jharkhand) INTERSTATE</td>
								<td>Mayurbhanj</td>
								<td>Rairangapur</td>
								<td>43</td>
								<td>0.2 - 2.6</td>
								<td>26.09</td>
							</tr>
							<tr>
								<td>3</td>
								<td>W.Bengal - Deuli - Suliapada (Passage) INTERSTATE</td>
								<td>Mayurbhanj, Balsore; (WestBengal)</td>
								<td>Baripada</td>
								<td>72</td>
								<td>0.1 - 0.7</td>
								<td>39.5</td>
							</tr>
							<tr>
								<td>4</td>
								<td>Similipal - Hadagarh - Kuldiha INTER - DISTRICT</td>
								<td>Mayurbhanj, Keonjhar, Balasore</td>
								<td>Baripada, Balasore WL, Keonjhar WL</td>
								<td>41.7</td>
								<td>0.7 - 3.5</td>
								<td>91.39</td>
							</tr>
							<tr>
								<td>5</td>
								<td>Telkoi - Pallahara INTER - DISTRICT</td>
								<td>Keonjhar; Angul</td>
								<td>Keonjhar, Deogarh</td>
								<td>30.4</td>
								<td>0.2 - 0.6</td>
								<td>13.24</td>
							</tr>
							<tr>
								<td>6</td>
								<td>Karo (Keonjhar) - Karampada (Saranda, Jharkhand) INTERSTATE</td>
								<td>Keonjhar, (Jharkhand)</td>
								<td>Keonjhar</td>
								<td>15</td>
								<td>0.3 - 2.3</td>
								<td>17.3</td>
							</tr>
							<tr>
								<td>7</td>
								<td>Maulabhanja Jiridamali - Anantapur INTER - DISTRICT</td>
								<td>Dhenkanal</td>
								<td>Dhenkanal</td>
								<td>6.5</td>
								<td>0.25 - 0.28</td>
								<td>1.55</td>
							</tr>
							<tr>
								<td>8</td>
								<td>Kahneijena - Anantapur INTER - DISTRICT</td>
								<td>Angul; Dhenkanal</td>
								<td>Angul</td>
								<td>6.6</td>
								<td>0.4 - 1.1</td>
								<td>5.22</td>
							</tr>
							<tr>
								<td>9</td>
								<td>Buguda - Central RF of Nayagarh Divn</td>
								<td>Nayagarh</td>
								<td>Nayagarh</td>
								<td>2.6</td>
								<td>0.8 - 0.6</td>
								<td>0.76</td>
							</tr>
							<tr>
								<td>10</td>
								<td>Nuagaon - Baruni</td>
								<td>Angul</td>
								<td>Athmallik</td>
								<td>4.5</td>
								<td>0.4 - 4.6</td>
								<td>20.7</td>
							</tr>
							<tr>
								<td>11</td>
								<td>Tal - Kholgarh</td>
								<td>Sambalpur</td>
								<td>Rairakhol</td>
								<td>6.3</td>
								<td>0.5 - 0.08</td>
								<td>4.56</td>
							</tr>
							<tr>
								<td>12</td>
								<td>Barpahad - Tarava - Kantamal INTER - DISTRICT</td>
								<td>Sonpur, Boudh</td>
								<td>Subarnapur</td>
								<td>24.2</td>
								<td>0.38 - 1.5</td>
								<td>21.7</td>
							</tr>
							<tr>
								<td>13</td>
								<td>Kotagarh - Chandrapur INTER - DISTRICT</td>
								<td>Kandhamal, Rayagada</td>
								<td>Balliguda, Rayagada</td>
								<td>77</td>
								<td>2.0 - 22.4</td>
								<td>575.4</td>
							</tr>
							<tr>
								<td>14</td>
								<td>Karlapat - Urladani INTER - DISTRICT</td>
								<td>Kandhamal, Rayagada</td>
								<td>Kalahandi(S), Kalahandi(N), Rayagada</td>
								<td>75</td>
								<td>0.2 - 0.5</td>
								<td>28.9</td>
							</tr>
						</tbody>
					</table>
				</div>

				<p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>
