<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<app-masterheader></app-masterheader>

<div class="wrapper">

    <app-sidebar></app-sidebar>

    <div id="content">

        <!-- <app-breadcrumb></app-breadcrumb> -->

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">

                                    <h4 class="card-title">Change Password</h4>

                                    <form [formGroup]="changePasswordForm" (ngSubmit)="passwordReset()">
                                        <div class="row form-group">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="">Current Password <span class="text-danger">*</span></label>
                                                    <div class="input-group" id="show_hide_password">
                                                        <input type="password"  [ngClass]="{ 'is-invalid':changePasswordForm.controls['oldpass'].invalid && changePasswordForm.controls['oldpass'].touched}" formControlName="oldpass" class="form-control" id="oldpass" placeholder="Current Password" required>
                                                        <div class="input-group-addon pull-right">
                                                            <a href=""><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="changePasswordForm.controls['oldpass'].invalid && (changePasswordForm.controls['oldpass'].dirty || changePasswordForm.controls['oldpass'].touched)" class="text-danger mt-1">
                                                         <small *ngIf="changePasswordForm.controls['oldpass'].errors.required">
                                                            Current Password is Required.
                                                        </small>
                                                        <!-- <span class="text-success"  *ngIf="(!changePasswordForm.controls['oldpass'].invalid) &&  (changePasswordForm.controls['oldpass'].dirty || changePasswordForm.controls['oldpass'].touched)"><i class="fa fa-check">&nbsp;</i></span> -->
                                                      </div>
                                                </div>
                                            </div>
                                        
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="">New Password <span class="text-danger">*</span></label>
                                                    <div class="input-group" id="show_hide_password_2">
                                                        <input type="password" formControlName="newpass"
                                                    [ngClass]="{ 'is-invalid':changePasswordForm.controls['newpass'].invalid && changePasswordForm.controls['newpass'].touched}"  class="form-control" id="newpass" placeholder="New Password" required>
                                                        <div class="input-group-addon pull-right">
                                                            <a href=""><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="changePasswordForm.controls['newpass'].invalid && (changePasswordForm.controls['newpass'].dirty || changePasswordForm.controls['newpass'].touched)" class="text-danger mt-1">
                                                    <small *ngIf="changePasswordForm.controls['newpass'].errors.required">
                                                      New Password is Required.
                                                    </small>
                                                    <small *ngIf="changePasswordForm.controls['newpass'].errors.minlength">
                                                      Password must be  8 characters.
                                                    </small>
                                                    <small *ngIf="changePasswordForm.controls['newpass'].errors.pWDExistsValidator">
                                                      New Password must be different than current password.
                                                    </small>

                                                    </div>
                                              </div>
                                            </div>
                                        
                                            <div class="col-md-4">
                                                <div class="form-group" >
                                                    <label for="">Confirm Password <span class="text-danger">*</span></label>
                                                    <div class="input-group" id="show_hide_password_3">
                                                        <input type="password" formControlName="cpassword"   [ngClass]="{ 'is-invalid':changePasswordForm.controls['cpassword'].invalid && changePasswordForm.controls['cpassword'].touched}" class="form-control" id="cpassword" placeholder="Confirm Password" required>
                                                        <div class="input-group-addon pull-right">
                                                            <a href=""><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="changePasswordForm.controls['cpassword'].invalid && (changePasswordForm.controls['cpassword'].dirty || changePasswordForm.controls['cpassword'].touched)" class="text-danger mt-1">
                                                    <small *ngIf="changePasswordForm.controls['cpassword'].errors.required">
                                                      Confirm New is Required.
                                                    </small>
                                                    <small *ngIf="changePasswordForm.controls['cpassword'].errors.confirmedValidator">
                                                      New Password and Confirm Password must be match.
                                                    </small>
                                                    </div>

                                              </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row form-group">
                                            <div class="col-md-12 text-center">
                                                <button [disabled]="changePasswordForm.invalid"  type="submit" class="btn btn-sm btn-success">Save</button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

    <div id="footer">
        <app-masterfooter></app-masterfooter>
    </div>

</div>

