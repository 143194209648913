import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {Router} from "@angular/router";
import {ApiService} from "../service/api.service";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { AuthService } from "../service/auth.service";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subject } from 'rxjs';
import { SidebarComponent } from '../sidebar/sidebar.component';
import{CommonelementComponent} from "src/app/commonelement/commonelement.component"
import { NotificationService } from '../service/notification.service'
import { ConfirmedValidator } from '../validators/confirmed.validator';
import Swal from 'sweetalert2';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var jQuery: any;

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.css']
})

export class UsermanagementComponent implements OnInit {
  public loader: boolean=false;
  public loader2: boolean=false;
  public loader3: boolean=false;
  public loader4: boolean=false;
  public isAdmin: boolean=false;
  registrationForm: FormGroup;
  updateForm:FormGroup;
  public loginid:any;
  public roleid:any;
  public tabledata:any[];
  public email:any;
  public userPhoneNumber:any;
  public firstName:any;
  public userId:any;
  public middleName:any;
  public lastName:any;

  public circleDisabled:boolean=false;
  public divisionDisabled:boolean=false;

  public divisionDisabled_userView: boolean=false;
  public rangeDisabled_userView: boolean=false;
  public circleDisabled_userView: boolean=false;

  username: any;
  rolelist: any;
  dropdownSettings:IDropdownSettings;
  Juridicition: any[];
  userDesignation: any;
  public isShownBox: boolean = false ;
  public isShownJuridicition: boolean = false ; // hidden by default
  public isShownCircle: boolean = false ; // hidden by default
  public isShownDivision: boolean = false ; // hidden by default
  public isShownRange: boolean = false ; // hidden by default
  public isShownsection: boolean = false ; // hidden by default
  public isShownBeat: boolean = false ; // hidden by default
  circleList: any[];
  divisionlist: string[];
  rangelist: any[];
  sectionlist: any[];
  beatlist: any[];
  roleSelect: any;
  JuridicitionFor: string;
  userEmailcheck:any;
  public emailAlredyExist: string="";
  userNamecheck:any;
  public userNameAlredyExist: string="";
  role: any;
  user: any;
  loginuserId: any;
  seqNo: any;
  juridictionId: string;
  circleid: any;
  circleSelected: any;
  divisionSelected: any;
  divid: any;
  userListForm: FormGroup;
  divisionlist_userView: any;
  divisionId_userView: string;
  rangelist_userView: any;
  sectionlist_userView: any;
  beatlist_userView: any;
  rangeid_userView: string;
  divid__userView: string;

  rangeId_userView: string;
  sectionid_userView: string;

  rangeSelected__userView: string;
  beatid_userView: string;
  circleList_userView: any;
  circleid_userView: string;
  rolearr: any[];
  UserName: any;
  query: any;
  defaultPwdResetUserid: any;
  

  constructor(private formBuilder: FormBuilder,private notifyService:NotificationService,private sweetalert: CommonelementComponent, private router: Router, private apiService: ApiService,private authService: AuthService) { }
  @ViewChild(SidebarComponent) _sidebarEleRef: SidebarComponent;
  role_userView
  ngAfterViewInit() {
    this._sidebarEleRef.contains='2';
  }
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    this.loader=true;//set loader true for visible
    const userdata=sessionStorage.getItem("user");
    if(userdata){
     const user=JSON.parse(userdata);
     this.loginuserId=user.userid;
     this.UserName=user.username;
     this.role=user.roleId;
     if(user.roleId==1){
      this.isAdmin=true;
    }
     this.roleid=user.roles;
     this.seqNo=user.seqNo;
     this.juridictionId=user.juridictionId;
    }
    if(this.role==1){
      this.isAdmin=true;
    }
    if( this.role!=2){
      this.circleid=0;
      this.divid=0;
    }
    this.getAllCircle_userView();
    this.circleid_userView="0";
    this.divid__userView="0";
    this.rangeid_userView="0";
    this.sectionid_userView="0";
    this.beatid_userView="0";

    this.getAllDesignation();
    
    // this.registrationForm = this.formBuilder.group({
    //   fname: ['', Validators.compose([Validators.required])],
    //   mname: [],
    //   lname: ['', Validators.compose([Validators.required])],
    //   email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    //   userPhoneNumber: ['',Validators.compose([Validators.required,Validators.pattern("[0-9 ]{10}")])],
    //   designation:['', Validators.required],
    //   circlewl:[''],
    //   dividwl:[''],
    //   username: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9._-]{4,20}')])],
    //   password: ['',Validators.compose([Validators.required,Validators.minLength(8)])],
    //   cpassword: ['', Validators.required],

    // }, {
    //   validator: ConfirmedValidator('password', 'cpassword')
    // });
    
    this.registrationForm = this.formBuilder.group({
      fname: ['', Validators.compose([Validators.required])],
      mname: [],
      lname: ['', Validators.compose([Validators.required])],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      userPhoneNumber: ['', Validators.compose([Validators.required, Validators.pattern("[0-9 ]{10}")])],
      designation: ['', Validators.required],
      circlewl: [''],
      dividwl: [''],
      username: ['', [
        Validators.required, 
        Validators.pattern('^[a-zA-Z0-9_]*$'),  // Pattern validator
        Validators.maxLength(20)                // Maximum length validator
      ]],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      cpassword: ['', Validators.required],
    }, {
      validator: ConfirmedValidator('password', 'cpassword')
    });
    
    this.userListForm= this.formBuilder.group({
      userView_circle:[''],
      userView_division:[''],
      userView_range:[''],
      userView_section:[''],
      userView_beat:[''],
      role_userView:['']
    });
    this.updateForm = this.formBuilder.group({
      userId:['', Validators.required],
      firstName: ['', Validators.compose([Validators.required])],
      middleName: [],
      lastName: ['', Validators.compose([Validators.required])],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      userPhoneNumber: ['', Validators.compose([Validators.required,Validators.pattern("[0-9 ]{10}")]),],
      userDesignation: ['', Validators.required],
      userName:['', Validators.required],
      active:['', Validators.required],
      juridicitionName:[],
      userAddress:[],
      imgPath:[]
    });

  	this.dtOptions = {
      // responsive: true,
      // pagingType: 'full_numbers',
      // pageLength: 5,
      // processing: true,
      dom: 'Bfrtip',
        buttons: [
            'copy', 'excel', 'pdf', 'print'
        ]
    };


  	(function($){

      $(document).ready(function () {
          //$("#multiselect").CreateMultiCheckBox({ width: '230px', defaultText : 'Select Below', height:'250px' });
         // $("#multiselect").CreateMultiCheckBox({ width: '100%', defaultText : 'Select Designation', height:'250px' });
      });

      $(".toggle-password").click(function() {
        $(this).toggleClass("fa-eye fa-eye-slash");
        var input = $(this).parent().find("input");
        if (input.attr("type") == "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    $(document).ready(function() {
      $("#show_hide_password a").on('click', function(event : any) {
          event.preventDefault();
          if($('#show_hide_password input').attr("type") == "text"){
              $('#show_hide_password input').attr('type', 'password');
              $('#show_hide_password i').addClass( "fa-eye-slash" );
              $('#show_hide_password i').removeClass( "fa-eye" );
          }else if($('#show_hide_password input').attr("type") == "password"){
              $('#show_hide_password input').attr('type', 'text');
              $('#show_hide_password i').removeClass( "fa-eye-slash" );
              $('#show_hide_password i').addClass( "fa-eye" );
          }
      });
  });
  $(document).ready(function() {
    $("#show_hide_password_2 a").on('click', function(event : any) {
        event.preventDefault();
        if($('#show_hide_password_2 input').attr("type") == "text"){
            $('#show_hide_password_2 input').attr('type', 'password');
            $('#show_hide_password_2 i').addClass( "fa-eye-slash" );
            $('#show_hide_password_2 i').removeClass( "fa-eye" );
        }else if($('#show_hide_password_2 input').attr("type") == "password"){
            $('#show_hide_password_2 input').attr('type', 'text');
            $('#show_hide_password_2 i').removeClass( "fa-eye-slash" );
            $('#show_hide_password_2 i').addClass( "fa-eye" );
        }
    });
});

    })(jQuery);
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    if( this.role==2){
      this.getAllCircle();
      this.getAssignCircleDivision();
    }
    this.loader=false;//set loader  for invisible
    //this.userInfTable();
  }
  getAssignCircleDivision() {
    if (this.role == 2) {
      //alert(this.roleId)

      this.apiService.request('GET', 'api/v1/masters/getAssignCircleDivisionRangeByUserId?roleId=' +  this.role + '&jurdicitionId=' + this.juridictionId).subscribe((data: any) => {
        if (data) {
          this.registrationForm.get('circlewl').disable();
          this.userListForm.get('userView_circle').disable();
          this.circleDisabled = true;
          this.circleDisabled_userView = true;
          this.circleid = data.circleId;
          this.circleid_userView=data.circleId;
          this.circleSelected = data.circleId;
          this.apiService.request('GET', 'api/v1/masters/getAllDivisionByCircleId?id='+data.circleId).subscribe((data: any) => {
            if(data)
            {
              this.divisionlist=data;
              this.divisionlist_userView=data;
            }
           });
          this.divisionSelected = data.divisonId;
          this.divid = data.divisonId;
          this.divid__userView=data.divisonId;
          this.registrationForm.get('dividwl').disable();
          this.userListForm.get('userView_division').disable();
          this.divisionDisabled = true;
          this.divisionDisabled_userView=true;
         this.apiService.request('GET', 'api/v1/masters/getAllRangeByDivid?id='+data.divisonId).subscribe((data: any) => {
            if(data)
            {
              this.rangelist=data;
              this.rangelist_userView=data;
            }
          });
        }
      });
    }if (this.role == 6) {
      //alert(this.roleId)

      this.apiService.request('GET', 'api/v1/masters/getAssignCircleDivisionRangeByUserId?roleId=' +  this.role + '&jurdicitionId=' + this.juridictionId).subscribe((data: any) => {
        if (data) {
          this.registrationForm.get('circlewl').disable();
          this.userListForm.get('userView_circle').disable();
          this.circleDisabled = true;
          this.circleDisabled_userView = true;
          this.circleid = data.circleId;
          this.circleid_userView=data.circleId;
          this.circleSelected = data.circleId;
          this.apiService.request('GET', 'api/v1/masters/getAllDivisionByCircleId?id='+data.circleId).subscribe((data: any) => {
            if(data)
            {
              this.divisionlist=data;
              this.divisionlist_userView=data;
            }
           });
        }
      });
    }
  }

  userInfTable(){

    try{
    if(this.role=='2'){
      return this.apiService.request('GET', 'api/v1/auth/getAllUserByCreatedBy?id='+this.loginuserId).subscribe((data: any) => {
        if(data){
          data.sort((a, b) => {
            return <any>new Date(b.lastUpdateOn) - <any>new Date(a.lastUpdateOn);
          });
          $("#userTable").DataTable().destroy();
          this.tabledata=new Array();
          this.tabledata=data;
          this.dtTrigger.next();

          }
      });
    }else{
      return this.apiService.request('GET', 'api/v1/auth/getuser').subscribe((data: any) => {
        if(data){
          data.sort((a, b) => {
            return <any>new Date(b.lastUpdateOn) - <any>new Date(a.lastUpdateOn);
          });
          $("#userTable").DataTable().destroy();

          this.tabledata=new Array();
          this.tabledata=data;
          this.dtTrigger.next();

          }
        });
    }
   }catch{
   }
  }
  signup()
  {
    try{
      this.loader=true;

      var Juridicition=$( "#Juridicition option:selected" ).val();
      var Designation=$( "#designation option:selected" ).val();
      if(Designation==3){
        Juridicition="";
      }else{
        if(Juridicition=="" )
        {
             alert('Juridicition must be Required.')
             this.loader=false;
            return false;
        }
      }


    if (this.registrationForm.valid)
    {
      this.loader2=true;
          // var names = this.registrationForm.value.role.map(function(item) {
          //   return item['name'];
          // });
          // var userName = this.registrationForm.value.userName.replace(/\s/g, "");
          // var password = this.registrationForm.value.password.replace(/\s/g, "");
          // var userPhoneNumber = this.registrationForm.value.userPhoneNumber.replace(/\s/g, "");
          // var email = this.registrationForm.value.email.replace(/\s/g, "");
        return this.apiService.request('POST', 'api/v1/auth/signup', {
          firstName: this.registrationForm.value.fname,
          middleName: this.registrationForm.value.mname,
          lastName: this.registrationForm.value.lname,
          email:this.registrationForm.value.email,
          jurdiction:Juridicition,
          userPhoneNumber:this.registrationForm.value.userPhoneNumber,
          role: Designation,
          username:this.registrationForm.value.username,
          password: this.registrationForm.value.password,
          createdBy: this.loginuserId
        }).subscribe((response: any) => {
        if(response){

          //this.sweetalert.success("register successfull");
          this.notifyService.showSuccess("Register Successfull","Success");
          $("#userTable").DataTable().destroy();
          //this.ngOnInit();
          this.registrationForm.patchValue({
            designation: '',
            lastName: '',
          });
          this.registrationForm.reset();
          this.isShownJuridicition=false;
          this.isShownCircle = false ;
          this.isShownDivision = false ;
          this.isShownRange=false;
          this.isShownBox=false;
          if(this.query!=null){
            this.onSubmitViewUserForm(this.query);
          }
          this.loader2=false;
          this.loader=false;//set loader  for invisible
          //window.location.reload();
        }
        },(err)=>{
          //console.log(err);
          this.notifyService.showError(err.error,"Error");
          //this.sweetalert.error(err.error.message);
          this.loader=false;//set loader  for invisible
          this.loader2=false;
        }
        );
      }
      else{
        this.notifyService.showError("Please fill all the required fields. ","Error");
        this.loader=false;//set loader  for invisible
        this.loader2=false;
      }
    } catch(error)
    {
      this.loader=false;//set loader invisible
      this.loader2=false;
      //console.log("Error Occured"+error.name);
    }

  }
  editUser(){
    this.loader=true;
    this.loader3=true;
    try{
    if (!this.updateForm.invalid)
    {
       var signupReqModel={
                'firstName': this.updateForm.value.firstName,
                'middleName': (this.updateForm.value.middleName!=null)?this.updateForm.value.middleName:'',
                'lastName': this.updateForm.value.lastName,
                'username':this.updateForm.value.userName,
                'email':this.updateForm.value.email,
                'userPhoneNumber':this.updateForm.value.userPhoneNumber,
                'userAddress':(this.updateForm.value.userAddress!=null)?this.updateForm.value.userAddress:'',
                'isActive':this.updateForm.value.active,
                'userid':this.updateForm.value.userId,
                //roleId:this.role,
                'updatedBy':this.loginuserId
        };
        console.log(JSON.stringify(signupReqModel));

        let formdata: FormData = new FormData();
        formdata.append('model', JSON.stringify(signupReqModel));
        formdata.append('file', '');
        return this.apiService.request('PUT', 'api/v1/auth/updateuser',formdata).subscribe((response: any) => {
          var res = JSON.parse(response);
          if(res.message=="success"){
          console.log(response);

          $("#userTable").DataTable().destroy();
          if(this.query!=null){
            console.log(this.query);

            this.loader4=true;
            this.onSubmitViewUserForm(this.query);
          }
          this.loader3=false;
          this.loader=false;
          jQuery("#userManagementModal").modal("hide");
          this.notifyService.showSuccess("User updated successfully!","Success");

          //this.userInfTable();
        }else{
          this.loader3=false;
          this.loader=false;
          this.notifyService.showError("Unsuccessfull","Error");
        }
        },err=>{
          this.loader = false;
          this.loader3=false;
        });
    }
   }catch{
    this.loader3=false;
    this.loader=false;
   }finally{
    this.loader3=false;
    this.loader=false;
   }
  }
  getUserByID(userid:any){
    this.loader=true;
    return this.apiService.request('GET', 'api/v1/auth/getuserById?loginId='+userid).subscribe((data: any) => {
      if(data){
          // this.email=data.email,
          // this.userPhoneNumber= data.mobile,
          // this.firstName= data.firstName;
          // this.middleName= data.middleName;
          // this.lastName= data.lastName;
          // this.userDesignation=data.userDesignation;
          this.user=data.userName;
          this.loader=false;
          this.updateForm.setValue(data);

        }
      });

  }
  getUserByIDAndDeactivate(loginId:any,status:boolean){
    //console.log(status);
   var flag=confirm('Are you sure!!!!');
   if(flag){
    this.apiService.request('PUT', 'api/v1/auth/getuserByIdAndDeactivate?loginId='+loginId+'&role='+this.roleid+'&isActive='+status).subscribe((message:any) => {
      if(message){
        //console.log(message)
        if(message=="deactivated successfully"){
          if(status==true){
            this.notifyService.showSuccess("Deactivate Successfull","Success");
          }else{
            this.notifyService.showSuccess("Activate Successfull","Success");
          }
          $("#userTable").DataTable().destroy();
          //this.ngOnInit();
          this.userInfTable();
          this.loader=false;

        }else{
          this.notifyService.showError("UnSuccessfull","Error");
          $("#userTable").DataTable().destroy();
          this.userInfTable();
          this.loader=false;
        }

        // alert("deactivate successfull");
         // window.location.reload();
        }
      });
   }else{
    $("#userTable").DataTable().destroy();
    this.userInfTable();
    this.notifyService.showError("You clicked the Cancel button! ","Error");
    this.loader=false;
   }

  }
  getAllCircle() {
    this.loader=true;
    return this.apiService.request('GET', 'api/v1/masters/getAllCircle').subscribe((data: any) => {
	    if(data)
	    {
        this.circleList=data;

        if(this.roleSelect==6){
          //alert(this.roleId)
          this.getJuridicitionByRoleId(this.roleSelect);
        }
        this.loader=false;
      }
    });
  }

  getAllDivisionByCircleId(circle:any){
    this.loader=true;
    //console.log(circle);
     return this.apiService.request('GET', 'api/v1/masters/getAllDivisionByCircleId?id='+circle).subscribe((data: any) => {
	    if(data)
	    {
        this.divisionlist=data;
        this.rangelist=[];
        this.sectionlist=[];
        this.beatlist=[];
        if(this.roleSelect==2){
          //alert(this.roleId)
          this.getJuridicitionByRoleId(this.roleSelect);
        }
        this.loader=false;
      }
     });
  }
  getAllRangeByDivId(div:any) {
    this.loader=true;
    return this.apiService.request('GET', 'api/v1/masters/getAllRangeByDivid?id='+div).subscribe((data: any) => {
	    if(data)
	    {
        this.rangelist=data;
        this.sectionlist=[];
        this.beatlist=[];
        if(this.roleSelect==5){
          //alert(this.roleId)
          this.getJuridicitionByRoleId(this.roleSelect);
        }
        this.loader=false;
      }
    });
  }
  getAllSectionByRangeId(range:any) {
    this.loader=true;
    return this.apiService.request('GET', 'api/v1/masters/getAllSectionByRangeid?id='+range).subscribe((data: any) => {
	    if(data)
	    {
        this.sectionlist=data;
        this.beatlist=[];
        //console.log(this.sectionlist);
        if(this.roleSelect==4){
          this.getJuridicitionByRoleId(this.roleSelect);
        }
        this.loader=false;
      }
    });
  }
  getAllBeatBySectionId(section:any) {
    this.loader=true;
    return this.apiService.request('GET', 'api/v1/masters/getAllBeatBySecId?id='+section).subscribe((data: any) => {
	    if(data)
	    {
        this.beatlist=data;
        if(this.roleSelect==7 || this.roleSelect==8 || this.roleSelect==9){
          this.getJuridicitionByRoleId(this.roleSelect);
        }
        this.loader=false
      }
    });
  }
  getAllDesignation(){
   if(this.role==2){
    return this.apiService.request('GET', 'api/v1/link/getAllRoleByRoleId?roleId='+this.seqNo).subscribe((data: any) => {
      if(data){
        this.rolelist=data;
        this.sectionlist=[];
        this.beatlist=[];
        }
      });
   }else{
    return this.apiService.request('GET', 'api/v1/link/getAllRole').subscribe((data: any) => {
      if(data){
        this.rolelist=data;
        this.circleList=[];
        this.rangelist=[];
        this.sectionlist=[];
        this.beatlist=[];
        }
      });
   }
  }
  onRoleSelect(roleId: any){
    //this.loader=true;
    this.roleSelect=roleId;
    if(roleId==1 || roleId==3){
      this.isShownJuridicition=false;
      this.isShownCircle = false ;
      this.isShownDivision = false ;
      this.isShownRange=false;
      this.isShownBox=false;
    }else if(roleId==6){
      this.isShownBox=true;
      this.isShownCircle = false ;
      this.isShownDivision = false ;
      this.isShownRange=false;
      this.isShownJuridicition=true;
      this.JuridicitionFor="Circle";
      this.Juridicition=[];
      this.getAllCircle();
    }else if(roleId==2){
      this.isShownBox=true;
      this.isShownCircle = true ;
      this.isShownDivision = false ;
      this.divisionlist=[];
      this.isShownRange=false;
      this.isShownJuridicition=true;
      this.JuridicitionFor="Division";
      this.Juridicition=[];
      this.getAllCircle();
    } else if(roleId==5){//ranger
      if(this.role==2){
        this.isShownBox=true;
        this.isShownCircle = true ;
        this.isShownDivision = true ;
        this.isShownRange=false;
        this.isShownsection=false;
        this.isShownBeat=false;
        this.isShownJuridicition=true;
        this.JuridicitionFor="Range";
        this.getJuridicitionByRoleId(roleId);

      }else{
        this.isShownBox=true;
        this.isShownCircle = true ;
        this.isShownDivision = true ;
        this.divisionlist=[];
        this.isShownRange=false;
        this.isShownsection=false;
        this.isShownBeat=false;
        this.isShownJuridicition=true;
        this.JuridicitionFor="Range";
        this.Juridicition=[];
        this.getAllCircle();
      }
    } else if(roleId==4){//forester
      if(this.role==2){
        //console.log("hi")
       this.isShownBox=true;
       this.isShownCircle = true ;
       this.isShownDivision = true ;
       this.isShownRange=true;
       this.isShownsection=false;
       this.isShownBeat=false;
       this.isShownJuridicition=true;
       this.JuridicitionFor="Section";
       this.Juridicition=[];
      }else{
        //console.log("hiiiiii")
        this.isShownBox=true;
        this.isShownCircle = true ;
        this.isShownDivision = true ;
        this.isShownRange=true;
        this.isShownsection=false;
        this.isShownBeat=false;
        this.divisionlist=[];
        this.rangelist=[];
        this.isShownJuridicition=true;
        this.JuridicitionFor="Section";
        this.Juridicition=[];
        this.getAllCircle();
      }
    }
    else if(roleId==7 || roleId==8 || roleId==9){
      if(this.role==2){
        //console.log("hi2")
        this.isShownBox=true;
        this.isShownCircle = true ;
        this.isShownDivision = true ;
        this.isShownRange=true;
        this.isShownsection=true;
        this.isShownBeat=false;
        this.isShownJuridicition=true;
        this.JuridicitionFor="Beat";
        this.Juridicition=[];
      }
      else{
        //console.log("hi2iiiiiiiii")
        this.isShownBox=true;
        this.isShownCircle = true ;
        this.isShownDivision = true ;
        this.isShownRange=true;
        this.isShownsection=true;
        this.isShownBeat=false;
        this.divisionlist=[];
        this.rangelist=[];
        this.sectionlist=[];
        this.isShownJuridicition=true;
        this.JuridicitionFor="Beat";
        this.Juridicition=[];
        this.getAllCircle();
      }
    }
  }
  getJuridicitionByRoleId(roleId:any){
    if(roleId==2){
      if(this.divisionlist.length!=0){
        this.Juridicition=new  Array();
        this.divisionlist.map(item=>{
          return {
            id : item['divisionId'],
            name: item['divisionName'],
          }
        }).forEach(item=> this.Juridicition.push(item));
        this.loader=false;
      }
    }
    else if(roleId==4){
      if(this.sectionlist.length!=0){
        this.Juridicition=new  Array();
        this.sectionlist.map(item=>{
          return {
            id : item['secId'],
            name: item['secName'],
          }
        }).forEach(item=> this.Juridicition.push(item));
        this.loader=false;
      }
    }
    else if(roleId==5){
      if(this.rangelist.length!=0){
        this.Juridicition=new  Array();
        this.rangelist.map(item=>{
          return {
            id : item['rangeId'],
            name: item['rangeName'],
          }
        }).forEach(item=> this.Juridicition.push(item));
        this.loader=false;
      }
    }
    else if(roleId==6){
      if(this.circleList.length!=0){
        this.Juridicition=new  Array();
        this.circleList.map(item=>{
          return {
            id : item['circleId'],
            name: item['circleName'],
          }
        }).forEach(item=> this.Juridicition.push(item));
        this.loader=false;
      }
    }
    else if(roleId==7 || roleId==8 || roleId==9){
      if(this.beatlist.length!=0){
        this.Juridicition=new  Array();
        this.beatlist.map(item=>{
          return {
            id : item['beatId'],
            name: item['beatName'],
          }
        }).forEach(item=> this.Juridicition.push(item));
        this.loader=false;
      }
    }
    // this.apiService.request('GET', 'api/v1/auth/getJuridicitionByRoleId?roleId='+roleId).subscribe((data: any) => {
    //   if(data){
    //     this.Juridicition=data;
    //     this.isShownJuridicition=true;
    //     this.loader=false;
    //     }
    //   });
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
                                                                 // UserTable

  getAllCircle_userView(){

    return this.apiService.request('GET', 'api/v1/masters/getAllCircle').subscribe((data: any) => {
	    if(data)
	    {
        this.circleList_userView=data;

      }
    });
  }
  getAllDivisionByCircleId_userView(circle:any){
    if(circle!=0){
       this.apiService.request('GET', 'api/v1/masters/getAllDivisionByCircleId?id='+circle).subscribe((data: any) => {
        if(data)
        {
           this.divisionlist_userView=data;
           if(this.rangelist_userView!=""){
            this.rangelist_userView=[];
            this.sectionlist_userView=[];
            this.beatlist_userView=[];
             this.rangeid_userView="0";
           }
        }
      });
    }else{
      if(this.divisionlist_userView!=""){
        this.divisionlist_userView=[];
        this.rangelist_userView=[];
        this.sectionlist_userView=[];
        this.beatlist_userView=[];
       }
        this.divid__userView="0";
    }
  }
   getAllRangeByDivId_userView(div:any) {
    if(div!=0){
      this.apiService.request('GET', 'api/v1/masters/getAllRangeByDivid?id='+div).subscribe((data: any) => {
	    if(data)
	    {
        this.rangelist_userView=data;
        this.rangeid_userView="0";
          if(this.sectionlist_userView!=""){
            this.sectionid_userView="0";
            this.sectionlist_userView=[];
            this.beatlist_userView=[];
         }
      }
     });
    }else{
      if(this.rangelist_userView!=""){
        this.rangelist_userView=[];
        this.sectionlist_userView=[];
        this.beatlist_userView=[];
       }
       this.rangeid_userView="0";
      }
  }
   getAllSectionByRangeId_userView(range:any) {
    if(range!=0){
     this.apiService.request('GET', 'api/v1/masters/getAllSectionByRangeid?id='+range).subscribe((data: any) => {
	    if(data)
	    {
        this.sectionlist_userView=data;
        this.sectionid_userView="0";
        if(this.beatlist_userView!=""){
          this.beatlist_userView=[];
          this.beatid_userView="0";
        }
      }
    });
  }else{
    if(this.sectionlist_userView!=""){
      this.sectionlist_userView=[];
      this.beatlist_userView=[];
     }
     this.sectionid_userView="0";
    }
  }
  getAllBeatBySectionId_userView(section:any) {
    if(section!=0){
    this.apiService.request('GET', 'api/v1/masters/getAllBeatBySecId?id='+section).subscribe((data: any) => {
	    if(data)
	    {
        this.beatlist_userView=data;
        this.beatid_userView="0";
      }
    });
  }else{
    if(this.beatlist_userView!=""){
      this.beatlist_userView=[];
     }

    }
  }
  onItemSelect(item: any) {
    //console.log(item);
  }
  onSelectAll(items: any) {
    //console.log(items);
  }
  onSubmitViewUserForm(element:any){
    console.log(element);

    this.loader4=true;
    this.query=element;
     //console.log(element)
     if( element.userView_circle==0){
      element.userView_circle="All";
     }else{
      element.userView_circle=this.circleid_userView.toString();
     }
     if( element.userView_division==0){
       element.userView_division="All";
     }else{
      element.userView_division=this.divid__userView.toString();
     }if( element.userView_range==0){
       element.userView_range="All";
    }else{
      element.userView_range=this.rangeid_userView.toString();
     }if( element.userView_section==0){
       element.userView_section="All";
    }else{
      element.userView_section=this.sectionid_userView.toString();
     }if( element.userView_beat==0){
       element.userView_beat="All";
    }else{
      element.userView_beat=this.beatid_userView.toString();
     }
    //console.log(element);
     if(element.role_userView.length==0){
        this.notifyService.showError("Select Designation","Error");
        this.loader4=false;
     }else{

     this.rolearr = new Array() ;
     this.rolearr= element.role_userView.map(element => element.id);

    //console.log(this.rolearr);
    //console.log( this.userListForm.value);
    //console.log(this.circleid_userView);
    return this.apiService.request('POST', 'api/v1/auth/getAllUserByFilter',{
        circle_id: element.userView_circle,
        division_id:element.userView_division,
        range_id:element.userView_range,
        section_id:element.userView_section,
        beat_id:element.userView_beat,
        array:this.rolearr
    }).subscribe((data: any) => {
      if(data){
        data.sort((a, b) => {
          return <any>new Date(b.lastUpdateOn) - <any>new Date(a.lastUpdateOn);
        });
        $("#userTable").DataTable().destroy();
        this.tabledata=new Array();
        this.tabledata=data;
         this.dtTrigger.next();
          this.loader=false;
          this.loader4=false;
        }
      });
  }
  }
  onClickResetDefaultPwd(userid:any){
    Swal.fire({
      title: 'Are you sure want to Reset Default Password?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes Reset it!',
      cancelButtonText: 'No',
    }).then((x) => {
      if (x.value) {
          this.apiService.request('PUT', 'api/v1/auth/resetDefaultPassword?userId='+userid+'&actionBy='+this.loginuserId,'').subscribe( {
            next:(data:any)=>{
                  data= JSON.parse(data);

                if(data.message=="Success"){
                  this.notifyService.showSuccess("Success","Reset Successfully. Default Password is 'pccf@12345' .");
                }else  if(data.message=="failed"){
                  this.notifyService.showError("Failed","Failed");
                }else  if(data.message=="invalidUserId"){
                  this.notifyService.showError("Failed","Invalid User");
                }
              },error:(err:any)=>{
                this.notifyService.showError("Failed","Error");
              }
          }); 
      }
    }); 
  }
  resetDefaultPwd(userid:any){
    this.defaultPwdResetUserid=userid;
  }
  confirmReset(){
     try{
    if(this.defaultPwdResetUserid!=null){
      this.loader2=true;
     this.apiService.request('PUT', 'api/v1/auth/resetDefaultPassword',{
      userId: this.defaultPwdResetUserid.toString(),
      actionBy:this.loginuserId.toString(),
     }).subscribe((response:any) => {
       if(response){
        var res=JSON.parse(response);
            if(res["message"]=="Success")
            {
                this.notifyService.showSuccess("Reset Successfully","Success");
                this.loader=false;
                this.loader2=false;
                jQuery("#resetDefaultPasswordModal").modal("hide");
            }else if(res["message"]=="failed"){
              jQuery("#resetDefaultPasswordModal").modal("hide");
              this.notifyService.showError("UnSuccessfull","Error");
              this.loader=false;
              this.loader2=false;
            }else if(res["message"]=="invalidUserId"){
              jQuery("#resetDefaultPasswordModal").modal("hide");
              this.notifyService.showError("Record Not Available","Error");
              this.loader=false;
              this.loader2=false;
            }
         }
       },(error)=>{
        console.log(error);
       });
    }else{
      jQuery("#resetDefaultPasswordModal").modal("hide");
     this.notifyService.showError("Record Not Available! ","Error");
     this.loader=false;
     this.loader2=false;
    }
    }catch{
      jQuery("#resetDefaultPasswordModal").modal("hide");
      this.loader2=false;
    }finally{
      jQuery("#resetDefaultPasswordModal").modal("hide");
      this.loader2=false;
    }
  }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  emailCheckUnique(emailid:any) {
   this.loader=true;
   var email = emailid.replace(/\s/g, "");
    return this.apiService.request('GET', 'api/v1/auth/checkEmailAvail?email='+email).subscribe(res => {
      this.userEmailcheck = res;
      if (this.userEmailcheck==true) {
        //this.emailAlredyExist = "Email Already Exist";
        this.notifyService.showError("Email ID Already Exist!!!","Error");
        this.registrationForm.controls.email.setValue("");
        this.updateForm.controls.email.setValue("");
        this.loader=false;
      }
      else{
        if(this.registrationForm.controls.email.valid){
          this.notifyService.showSuccess("Email ID Available","Success");
        }else  if(this.updateForm.controls.email.valid){
          this.notifyService.showSuccess("Email ID Available","Success");
        }
        this.loader=false;
      }
    });
  }
  phoneCheckUnique(phone:any) {
    this.loader=true;
    //var email = phone.replace(/\s/g, "");
     return this.apiService.request('GET', 'api/v1/auth/checkPhoneAvail?phone='+phone).subscribe(res => {
       if (res==true) {
         this.notifyService.showError("Mobile number Already Exist!!!","Error");
         this.registrationForm.controls.userPhoneNumber.setValue("");
         this.updateForm.controls.userPhoneNumber.setValue("");
         this.loader=false;
       }
       else{
         if(this.registrationForm.controls.userPhoneNumber.valid){
           this.notifyService.showSuccess("Mobile number avail","Success");
         }else  if(this.updateForm.controls.userPhoneNumber.valid){
           this.notifyService.showSuccess("Mobile number avail","Success");
         }
         this.loader=false;
       }
     });
   }
  userNameCheckUnique(loginid:any) {
   this.loader=true;
   try{
    var username = loginid.replace(/\s/g, "");
    if(this.UserName!=username){
       this.apiService.request('GET', 'api/v1/auth/checkUserNameAvail?userName='+username).subscribe(res => {
        this.userNamecheck = res;
      });
      if (this.userNamecheck) {
       if(this.user==username){
          this.loader=false;
          this.notifyService.showSuccess("Login ID Available","Success");
        }else{
          this.loader=false;
          this.notifyService.showError("Login ID Alredy Exist!!!","Error");
          this.registrationForm.controls.username.setValue("");
          this.updateForm.controls.userName.setValue("");
        }
        //this.userNameAlredyExist = "Username Alredy Exist";

      }else{
        this.loader=false;
        if(this.registrationForm.controls.username.valid){
          this.loader=false;
          this.notifyService.showSuccess("Login ID Available","Success");
        }else if(this.updateForm.controls.userName.valid){
          this.loader=false;
          this.notifyService.showSuccess("Login ID Available","Success");
        }
        this.loader=false;
      }
    }

    

   }catch{
     this.loader=false;
   }finally{
    this.loader=false;
   }
  }
//   async isCircleDivisionRangeDisabled(){
//     if(this.roleId==1 || this.roleId==3){
//       this.circleDisabled=false;
//       this.divisionDisabled=false;
//       this.rangeDisabled=false;
//     }
//     else if(this.roleId==6){
//       this.circleDisabled=true;
//       this.incidentForm.get('incident_circle').disable();
//       this.elepDeathForm.get('elepDeath_circle').disable();
//     }
//     else if(this.roleId==2)
//     {
//       this.incidentForm.get('incident_circle').disable();
//       this.elepDeathForm.get('elepDeath_circle').disable();
//       this.circleDisabled=true;
//       this.circleid_incident=this.circleId;
//       this.circleid_elepDeath=this.circleId;
//       this.circleSelected_incident=this.circleId;
//       this.circleSelected_elepDeath=this.circleId;
//       this.getAllDivisionByCircleId(this.circleId);

//       //this.viewDashboardinfo();
//       //this.reporttable();
//     }
//     else if(this.roleId==4 || this.roleId==5 )
//     {
//         this.getAllDivisionByCircleId(this.circleId);
//           this.circleDisabled=true;
//           this.incidentForm.get('incident_circle').disable();
//           this.elepDeathForm.get('elepDeath_circle').disable();
//           this.circleid_incident=this.circleId;
//           this.circleid_elepDeath=this.circleId;
//           this.circleSelected_incident=this.circleId;
//           this.circleSelected_elepDeath=this.circleId;
//     }
// }
// usernameValidator(control: FormControl): { [key: string]: boolean } | null {
//   const validPattern = /^[a-zA-Z0-9_]*$/;
//   if (control.value && !validPattern.test(control.value)) {
//     return { invalidCharacters: true }; // Return an error object if validation fails
//   }
//   return null; // No error if valid
// }
// checkUsername(event: KeyboardEvent): void {
//   const input = (event.target as HTMLInputElement).value;
//   const validPattern = /^[a-zA-Z0-9_]*$/;
//   if (!validPattern.test(input)) {
//     this.registrationForm.controls['username'].setErrors({ pattern: true });
//   } else {
//     this.registrationForm.controls['username'].setErrors(null);
//   }
// }

restrictInput(event: Event): void {
  const inputElement = event.target as HTMLInputElement;
  const validPattern = /^[a-zA-Z0-9_]*$/;

  // Replace any invalid characters with an empty string
  inputElement.value = inputElement.value.replace(/[^a-zA-Z0-9_]/g, '');

  // Manually trigger form control update for reactive form validation
  this.registrationForm.controls['username'].setValue(inputElement.value);
}


  }
