import { Component, OnInit } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from '../service/api.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const baseUrlFile = environment.FILE_API_URL;
declare var jQuery: any;

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.css']
})
export class NoticesComponent implements OnInit {
 // public loader: boolean = false;
	// public data = [
	//     {slno: '1', noticeno: '1', date: '17-11-2020', noticename: 'Auction Sale of Cashew Nut of Mangrove Forest Division (Wildlife) Rajnagar.'},
	//     {slno: '2', noticeno: '1', date: '09-07-2020', noticename: 'Nomination for the Biju Patnaik Award for Wildlife Conservation -2020'},
	//     {slno: '3', noticeno: '2', date: '09-07-2020', noticename: 'Nomination for the Biju Patnaik Award for Wildlife Conservation at Sub-divisional level 2020'},
	//     {slno: '4', noticeno: '1', date: '22-06-2020', noticename: 'Quotation call notice for AMC of CCTV'},
	//     {slno: '5', noticeno: '2', date: '22-06-2020', noticename: 'Quotation call notice for AMC of Networking'},
	//     {slno: '6', noticeno: '3', date: '22-06-2020', noticename: 'Quotation call notice for AMC of EPABX / Intercom system machine'},
	//     {slno: '7', noticeno: '4', date: '22-06-2020', noticename: 'Quotation call notice for AMC of Video Conferencing'},
	//     {slno: '8', noticeno: '6257', date: '10-07-2018', noticename: 'Empanellment of Organisation'},
	//     {slno: '9', noticeno: '1', date: '12-06-2018', noticename: 'QUOTATION CALL NOTICE'},
	//     {slno: '10', noticeno: '6175', date: '01-03-2018', noticename: 'Guidelines for water pipeline and OFC inside PAs'},
	// ];
  noticeList:any[];


  constructor(private apiService: ApiService) { }

  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {};

  ngOnInit(): void {

  	this.dtOptions = {
      responsive: true,
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: 'Bfrtip',
        buttons: [
            //'copy', 'excel', 'pdf', 'print'
        ]
    };

  	(function($){



    })(jQuery);
    this.getAllNotice();

  }
  getAllNotice() {
    try{
      //this.loader = true;
    this.apiService.request('GET', 'api/v1/cms/getAllNotice').subscribe((data: any) => {
      if (data) {
        this.noticeList = new Array();
        data.sort((a, b) => {
          return <any>new Date(b.noticeValidDate) - <any>new Date(a.noticeValidDate);
        });
        data.map(item => {
          return {
            noticeId: item['id'],
            uploadPath: baseUrlFile + 'notice/' + item['noticePath'],
            noticeName: item['noticeName'],
            noticeNumber: item['noticeNumber'],
            noticeValidDate: item['noticeValidDate'],
            noticeDate: item['noticeDate'],
            noticeIsExpire:this.isNoticeExpire(item['noticeValidDate'])
          }
        }).forEach(item => this.noticeList.push(item));
        this.dtTrigger.next();
        //this.loader = false;
      }
    },err=>{
      //this.loader = false;
    });
  }catch(e){
    //this.loader = false;
  }
  }

  isNoticeExpire(date:Date){
    var fdate=new Date(date);
    const today = new Date();
    // 👇️ OPTIONAL!
    // This line sets the hour of the current date to midnight
    // so the comparison only returns `true` if the passed in date
    // is at least yesterday
    today.setHours(0, 0, 0, 0);
    return fdate > today;
  }
}
