<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<app-masterheader></app-masterheader>

<div class="wrapper">

	<app-sidebar></app-sidebar>

	<div id="content">

		<!-- <app-breadcrumb></app-breadcrumb> -->

		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12">

					<div class="row">
						<div class="col-md-12">
							<div class="card">
								<div class="card-body">
									<h4 class="card-title"> <i class="mdi mdi-file-chart text-primary me-2"></i> Reports</h4>
									<ul id="tabs" class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                      <a id="tab-B" href="#pane-B" class="nav-link active" data-toggle="tab" (click)="loadtable(0)" role="tab">
                          <i class="mdi mdi-paw text-danger me-2"></i> Elephant Death Report
                      </a>
                  </li>
                  <li class="nav-item">
                      <a id="tab-C" href="#pane-D" class="nav-link" data-toggle="tab" (click)="loadtable(3)" role="tab">
                          <i class="mdi mdi-paw text-danger me-2"></i> Tiger Death Report
                      </a>
                  </li>
                  <li class="nav-item">
                      <a id="tab-C" href="#pane-E" class="nav-link" data-toggle="tab" (click)="loadtable(4)" role="tab">
                          <i class="mdi mdi-paw text-warning"></i> Leopard Death Report
                      </a>
                  </li>
                  <li class="nav-item">
                      <a id="tab-C" href="#pane-F" class="nav-link" data-toggle="tab" (click)="loadtable(5)" role="tab">
                          <i class="mdi mdi-paw text-brown"></i> Pangolin Death Report
                      </a>
                  </li>
                  <li class="nav-item">
                      <a id="tab-C" href="#pane-C" class="nav-link" data-toggle="tab" (click)="loadtable(2)" role="tab">
                          <i class="mdi mdi-human-male text-dark"></i> Human Death
                      </a>
                  </li>
                  <li class="nav-item">
                      <a id="tab-G" href="#pane-G" class="nav-link" data-toggle="tab" (click)="loadtable(6)" role="tab">
                          <i class="mdi mdi-hospital text-primary"></i> Human Injury
                      </a>
                  </li>
                  <li class="nav-item">
                      <a id="tab-A" href="#pane-A" class="nav-link" data-toggle="tab" (click)="loadtable(1)" role="tab">
                          <i class="mdi mdi-alert-circle text-info"></i> Other Incident Alert Report
                      </a>
                  </li>
									</ul>
									<div id="accordion" class="tab-content" role="tablist">
                    <div id="pane-A" class="card tab-pane fade " role="tabpanel"
                    aria-labelledby="tab-A">
                    <div class="card-header" role="tab" id="heading-A">
                      <h5 class="mb-0">
                        <a class="collapsed" data-toggle="collapse" href="#collapse-A"
                          aria-expanded="false" aria-controls="collapse-A">
                         Incident Record
                        </a>
                      </h5>
                    </div>
                    <div id="collapse-A" class="collapse" role="tabpanel"
                      data-parent="#accordion" aria-labelledby="heading-A">
                      <div class="card-body">
                        <form [formGroup]="incidentForm"
                          (ngSubmit)="onSubmitIncidentMovementRecord(incidentForm.value)">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-3 form-group mb-1">
                                  <label>Circle</label>
                                  <select class="form-control" id="circlewl_incident" formControlName="incident_circle"  (change)="getAllDivisionByCircleId(circleSelect_incident.value)" [(ngModel)]='circleid_incident'   [disabled]="circleDisabled"  #circleSelect_incident>
                                    <option value="0">All Circle</option>
                                    <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                      {{circle.circleName}}
                                    </option>
                                </select>
                                </div>
                                <div class="col-md-3 form-group mb-1">
                                  <label>Division</label>
                                  <select class="form-control" id="divisionwl_incident" formControlName="incident_division" (change)="getAllRangeByDivId(divisionSelect_incident.value)" [(ngModel)]='divid_incident'  [disabled]="divisionDisabled" #divisionSelect_incident>
                                    <option value="0">All Division</option>
                                    <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                      {{division.divisionName}}
                                    </option>
                                </select>
                                </div>
                                <div class="col-md-3 form-group mb-1">
                                  <label>Range</label>
                                  <select class="form-control" id="rangewl_incident"  formControlName="incident_range" (change)="getAllSectionByRangeId(rangeSelect_incident.value)" [(ngModel)]='rangeid_incident'  [disabled]="rangeDisabled"  #rangeSelect_incident>
                                    <option value="0">All Range</option>
                                    <option    *ngFor="let range of rangelist "   value={{range.rangeId}} >
                                      {{range.rangeName}}
                                    </option>
                                </select>
                                </div>
                                <div class="col-md-3 form-group mb-1">
                                  <label>Section</label>
                                  <select class="form-control" id="sectionwl_incident" formControlName="incident_section" (change)="getAllBeatBySectionId(sectionSelect_incident.value)" [(ngModel)]='sectionid_incident'  #sectionSelect_incident>
                                    <option value="0">All Section</option>
                                    <option    *ngFor="let section of sectionlist "   value={{section.secId}} >
                                      {{section.secName}}
                                    </option>
                                </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-3 form-group mb-1">
                                  <label>Beat</label>
                                  <select class="form-control" id="beatwl_incident" formControlName="incident_beat"  [(ngModel)]='beatid_incident'  #beatSelect_incident>
                                    <option value="0">All Beat</option>
                                    <option    *ngFor="let beat of beatlist "   value={{beat.beatId}} >
                                      {{beat.beatName}}
                                    </option>
                                </select>
                                </div>
                              <div class="col-md-3 form-group mb-1">
                                <label>From Date</label>
                                  <div id="datepicker7" class="input-group date"  data-date-format="dd-mm-yyyy" title="From Date">
                                    <input class="form-control" formControlName="incident_startDate" #sd_incident id="startDate_incident" type="text" />
                                    <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
                                  </div>
                              </div>
                              <div class="col-md-3 form-group mb-1">
                                <label>To Date</label>
                                  <div id="datepicker8" class="input-group date" data-date-format="dd-mm-yyyy" title="To Date">
                                    <input class="form-control"
                                      formControlName="incident_endDate" #ed_incident id="endDate_incident"
                                      type="text" />
                                    <span class="input-group-addon"><i
                                        class="mdi mdi-calendar"></i></span>
                                  </div>
                              </div>
                              <div class="col-md-3 form-group mb-1">
                                  <button type="submit"
                                    class="btn btn-sm btn-success custom-btn-top mr-1">View</button>
                              </div>
                              </div>
                            </div>

                          </div>
                        </form>
                        <hr>
                        <div class="table-responsive">
                          <table id="IncidentTable"
                            class="table table-bordered display responsive nowrap"
                            width="99%" datatable [dtOptions]="dtOptions"
                            [dtTrigger]="dtTrigger4">
                            <thead>
                              <tr>
                                <th class="all" width="5%">Sl. No.</th>
                                <th class="all">Division</th>
                                <th class="all">Incident Date</th>
                                <th class="all">Lat</th>
                                <th class="all">Long</th>
                                <th class="all">Incident Report Type</th>
                                <th class="all">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="let element4 of tabledata4 ; let i = index;">
                                <td>{{i+1}}</td>
                                <td>{{element4.division}}</td>
                                <td>{{ (element4.incidentReport  !== 'null') ? (element4.incidentReport | date:'dd-MM-yyyy')  : '' }}  </td>
                                <td>{{element4.latitude}}</td>
                                <td>{{element4.longitude}}</td>
                                <td>{{element4.incidentReportType}}</td>
                                <td>
                                  <div class="dropdown">
                                    <button
                                      class="btn btn-sm btn-info btn-icon btn-radius dropdown-toggle"
                                      type="button" id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false">
                                      <i class="fa fa-cog"></i>
                                    </button>
                                    <div class="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton">
                                      <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal"
                                          data-target="#incidentElephantMovementRecordModal"
                                          (click)="getDeathRecordByID(element4)"><i class="fa fa-eye" aria-hidden="true">&nbsp;</i>View</a>
                                        <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                         data-target="#mapFireRecordModal" id="firebtn"
                                         (click)="zoomthepoint(element4.latitude,element4.longitude,element4.divisionId)"><i class="fa fa-map-marker" aria-hidden="true">&nbsp;</i>Map View</a>
                                         <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                         data-target="#editRecordModal"  *ngIf="isAdmin"
                                         (click)="getDeathRecordByIDForEdit(element4,'incident')"><i class="fa fa-edit" aria-hidden="true">&nbsp;</i>Edit</a>
                              
                                    <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                         data-target="#deleteElephantMovementRecordModal"  id="incidentDeletebtn"   *ngIf="isAdmin"  (click)="setDeleteRecord(element4.report_Id,'incident')"><i class="fa fa-trash" aria-hidden="true">&nbsp;</i>Delete</a>
                                    </div>
                                  </div>
                                  <!-- <button class="btn btn-sm btn-success mr-1" data-toggle="modal"
                                    data-target="#incidentElephantMovementRecordModal" (click)="getMovementRecordByID(element4)">
                                    <i class="fa fa-eye"></i></button>
                                  <button class="btn btn-sm btn-info mr-1" data-toggle="modal"  id="firebtn" (click)="zoomthepoint(element4.latitude,element4.longitude,element4.divisionId)"
																			data-target="#mapFireRecordModal"><i class="fa fa-map-marker" aria-hidden="true"></i></button>
                                      <button
                                      data-toggle="modal"
                                      data-target="#deleteElephantMovementRecordModal"
                                       class="btn btn-sm btn-danger mr-1" id="incidentDeletebtn"   *ngIf="isAdmin"  (click)="setDeleteRecord(element4.report_Id,'incident')">
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                      </button> -->
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div id="pane-B" class="card tab-pane fade show active" role="tabpanel"
                    aria-labelledby="tab-B">
                    <div class="card-header" role="tab" id="heading-B">
                      <h5 class="mb-0">
                        <a class="collapsed" data-toggle="collapse" href="#collapse-B"
                          aria-expanded="false" aria-controls="collapse-B">
                        Elephant Death Record
                        </a>
                      </h5>
                    </div>
                    <div id="collapse-B" class="collapse" role="tabpanel"
                      data-parent="#accordion" aria-labelledby="heading-B">
                      <div class="card-body">
                        <form [formGroup]="elepDeathForm"
                          (ngSubmit)="onSubmitElepDeathMovementRecord(elepDeathForm.value)">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-3 form-group mb-1">
                                  <label>Circle</label>
                                  <select class="form-control" id="circlewl_elepDeath" formControlName="elepDeath_circle"  (change)="getAllDivisionByCircleId(circleSelect_elepDeath.value)" [(ngModel)]='circleid_elepDeath'    #circleSelect_elepDeath>
                                    <option value="0">All Circle</option>
                                    <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                      {{circle.circleName}}
                                    </option>
                                </select>
                                </div>
                                <div class="col-md-3 form-group mb-1">
                                  <label>Division</label>
                                  <select class="form-control" id="divisionwl_elepDeath" formControlName="elepDeath_division" (change)="getAllRangeByDivId(divisionSelect_elepDeath.value)" [(ngModel)]='divid_elepDeath' #divisionSelect_elepDeath>
                                    <option value="0">All Division</option>
                                    <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                      {{division.divisionName}}
                                    </option>
                                </select>
                                </div>
                                <div class="col-md-3 form-group mb-1">
                                  <label>Range</label>
                                  <select class="form-control" id="rangewl_elepDeath"  formControlName="elepDeath_range" (change)="getAllSectionByRangeId(rangeSelect_elepDeath.value)" [(ngModel)]='rangeid_elepDeath'   #rangeSelect_elepDeath>
                                    <option value="0">All Range</option>
                                    <option    *ngFor="let range of rangelist "   value={{range.rangeId}} >
                                      {{range.rangeName}}
                                    </option>
                                </select>
                                </div>
                                <div class="col-md-3 form-group mb-1">
                                  <label>Section</label>
                                  <select class="form-control" id="sectionwl_elepDeath" formControlName="elepDeath_section" (change)="getAllBeatBySectionId(sectionSelect_elepDeath.value)" [(ngModel)]='sectionid_elepDeath'  #sectionSelect_elepDeath>
                                    <option value="0">All Section</option>
                                    <option    *ngFor="let section of sectionlist "   value={{section.secId}} >
                                      {{section.secName}}
                                    </option>
                                </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-3 form-group mb-1">
                                  <label>Beat</label>
                                  <select class="form-control" id="beatwl_elepDeath" formControlName="elepDeath_beat"  [(ngModel)]='beatid_elepDeath'  #beatSelect_elepDeath>
                                    <option value="0">All Beat</option>
                                    <option    *ngFor="let beat of beatlist "   value={{beat.beatId}} >
                                      {{beat.beatName}}
                                    </option>
                                </select>
                                </div>
                              <div class="col-md-3 form-group mb-1">
                                <label>From Date</label>
                                  <div id="datepicker9" class="input-group date"  data-date-format="yyyy-mm-dd" title="From Date">
                                    <input class="form-control" formControlName="elepDeath_startDate" #sd_elepDeath id="startDate_elepDeath" type="text" />
                                    <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
                                </div>
                              </div>
                              <div class="col-md-3 form-group mb-1">
                                <label>To Date</label>
                                  <div id="datepicker10" class="input-group date" data-date-format="yyyy-mm-dd" title="To Date">
                                    <input class="form-control"
                                      formControlName="elepDeath_endDate" #ed_elepDeath id="endDate_elepDeath"
                                      type="text" />
                                    <span class="input-group-addon"><i
                                        class="mdi mdi-calendar"></i></span>
                                </div>
                              </div>
                              <div class="col-md-3 form-group mb-1">
                                  <button type="submit"
                                    class="btn btn-sm btn-success custom-btn-top mr-1">View</button>
                              </div>
                              </div>
                            </div>

                          </div>
                        </form>
                        <hr>
                        <div class="table-responsive">
                          <table id="ElepDeathTable"
                            class="table table-bordered display responsive nowrap"
                            width="99%" datatable [dtOptions]="dtOptions"
                            [dtTrigger]="dtTrigger5">
                            <thead>
                              <tr>
                                <th class="all" width="5%">Sl. No.</th>
                                <th class="all">Division</th>
                                <th class="all">Date of Report</th>
                                <th class="all">Reason of Death</th>
                                <th >Total Death</th>
                                <th class="all">Lat</th>
                                <th class="all">Long</th>
                                <th class="all">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="let element5 of tabledata5 ; let i = index;">
                                <td>{{i+1}}</td>
                                <td>{{element5.division}}</td>
                                <td>{{ (element5.incidentReport  !== 'null') ? (element5.incidentReport | date:'dd-MM-yyyy h:mm a')  : '' }}  </td>
                                <td>{{element5.deathReason}}</td>
                                <td>{{element5.totalDeath}}</td>
                                <td>{{element5.latitude}}</td>
                                <td>{{element5.longitude}}</td>

                                <td>
                                  <div class="dropdown">
                                    <button
                                      class="btn btn-sm btn-info btn-icon btn-radius dropdown-toggle"
                                      type="button" id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false">
                                      <i class="fa fa-cog"></i>
                                    </button>
                                    <div class="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton">
                                      <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal"
                                          data-target="#ElephantDeathRecordModalView"
                                        (click)="getDeathRecordByID(element5)"><i class="fa fa-eye" aria-hidden="true">&nbsp;</i>View</a>
                                        <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                         data-target="#mapElephantDeathRecordModal" id="deathbtn"
                                         (click)="zoomthepoint(element5.latitude,element5.longitude,element5.divisionId)"><i class="fa fa-map-marker" aria-hidden="true">&nbsp;</i>Map View</a>
                                         <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                         data-target="#editDeathRecordModal"  *ngIf="isAdmin"
                                         (click)="getDeathRecordByIDForEdit(element5,'elephant')"><i class="fa fa-edit" aria-hidden="true">&nbsp;</i>Edit</a>
                                    <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                         data-target="#deleteElephantMovementRecordModal"  id="deathDeletebtn"  *ngIf="isAdmin"  (click)="setDeleteRecord(element5.report_Id,'death')"><i class="fa fa-trash" aria-hidden="true">&nbsp;</i>Delete</a>
                                    </div>
                                  </div>
                                  <!-- <button class="btn btn-sm btn-success mr-1"
                                    data-toggle="modal"
                                    data-target="#ElephantDeathRecordModal"
                                    (click)="getMovementRecordByID(element5)"><i
                                      class="fa fa-eye"></i></button>
                                      <button class="btn btn-sm btn-info mr-1"
																			data-toggle="modal"  id="deathbtn" (click)="zoomthepoint(element5.latitude,element5.longitude,element5.divisionId)"
																			data-target="#mapElephantDeathRecordModal"><i
																				class="fa fa-map-marker"
																				aria-hidden="true"></i></button>
                                        <button
                                        data-toggle="modal"
                                        data-target="#deleteElephantMovementRecordModal"
                                        class="btn btn-sm btn-danger mr-1" id="deathDeletebtn"  *ngIf="isAdmin"  (click)="setDeleteRecord(element5.report_Id,'death')">
                                          <i class="fa fa-trash" aria-hidden="true" ></i>
                                        </button> -->
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div id="pane-C" class="card tab-pane fade" role="tabpanel"
                    aria-labelledby="tab-C">
                    <div class="card-header" role="tab" id="heading-C">
                      <h5 class="mb-0">
                        <a class="collapsed" data-toggle="collapse" href="#collapse-C"
                          aria-expanded="false" aria-controls="collapse-C">
                        Human Kill
                        </a>
                      </h5>
                    </div>
                    <div id="collapse-C" class="collapse" role="tabpanel"
                      data-parent="#accordion" aria-labelledby="heading-C">
                      <div class="card-body">
                        <form [formGroup]="humanDeathForm"
                          (ngSubmit)="onSubmithumanDeathMovementRecord(humanDeathForm.value)">
                          <div class="row">
                            <div class="col-md-10">
                              <div class="row">
                                <div class="col-md-3 form-group mb-1">
                                  <label>Circle</label>
                                  <select class="form-control" id="circlewl_humanDeath" formControlName="humanDeath_circle"  (change)="getAllDivisionByCircleId(circleSelect_humanDeath.value)" [(ngModel)]='circleid_humanDeath'    #circleSelect_humanDeath>
                                    <option value="0">All Circle</option>
                                    <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                      {{circle.circleName}}
                                    </option>
                                </select>
                                </div>
                                <div class="col-md-3 form-group mb-1">
                                  <label>Division</label>
                                  <select class="form-control" id="divisionwl_humanDeath" formControlName="humanDeath_division" (change)="getAllRangeByDivId(divisionSelect_humanDeath.value)" [(ngModel)]='divid_humanDeath' #divisionSelect_humanDeath>
                                    <option value="0">All Division</option>
                                    <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                      {{division.divisionName}}
                                    </option>
                                </select>
                                </div>
                                <div class="col-md-3 form-group mb-1">
                                  <label>From Date</label>
                                    <div id="datepicker11" class="input-group date"  data-date-format="yyyy-mm-dd" title="From Date">
                                      <input class="form-control" formControlName="humanDeath_startDate" #sd_humanDeath id="startDate_humanDeath" type="text" />
                                      <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
                                  </div>
                                </div>
                                <div class="col-md-3 form-group mb-1">
                                  <label>To Date</label>
                                    <div id="datepicker12" class="input-group date" data-date-format="yyyy-mm-dd" title="To Date">
                                      <input class="form-control"
                                        formControlName="humanDeath_endDate" #ed_humanDeath id="endDate_humanDeath"
                                        type="text" />
                                      <span class="input-group-addon"><i
                                          class="mdi mdi-calendar"></i></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="row">
                              <div class="col-md-12 form-group mb-1">
                                  <button type="submit"
                                    class="btn btn-sm btn-success custom-btn-top mr-1">View</button>
                              </div>
                              </div>
                            </div>

                          </div>
                        </form>
                        <hr>
                        <div class="table-responsive">
                          <table id="humanDeathTable"
                            class="table table-bordered display responsive nowrap"
                            width="99%" datatable [dtOptions]="dtOptions"
                            [dtTrigger]="dtTrigger6">
                            <thead>
                              <tr>
                                <th class="all" width="5%">Sl. No.</th>
                                <th class="all">Circle</th>
                                <th class="all">Division</th>
                                <th class="all">Range</th>
                                <th class="all">Section</th>
                                <th class="all">Beat</th>
                                <th class="all">Incident Type</th>
                                <th class="all">Total Number</th>
                                <th class="all">Date Of Incident</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="let element6 of tabledata6 ; let i = index;">
                                <td>{{i+1}}</td>
                                 <td>{{element6.circleName}}</td>
                                <td>{{element6.divName}}</td>
                                <td>{{element6.rangeName}}</td>
                                <td>{{element6.secName}}</td>
                                <td>{{element6.beatName}}</td>
                                <td>{{element6.incidentType}}</td>
                                <td>{{element6.totalCasulaity}}</td>    
                                 <td>{{ (element6.incidentTime  !== 'null') ? (element6.incidentTime | date:'dd-MM-yyyy')  : '' }}  </td>
                                  <!-- <td>
                                  <div class="dropdown">
                                    <button
                                      class="btn btn-sm btn-info btn-icon btn-radius dropdown-toggle"
                                      type="button" id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false">
                                      <i class="fa fa-cog"></i>
                                    </button>
                                    <div class="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton">
                                      <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal"
                                          data-target="#HumanDeathRecordModal"
                                        (click)="getMovementRecordByID(element6)"><i class="fa fa-eye" aria-hidden="true">&nbsp;</i>View</a>
                                        <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                         data-target="#mapElephantDeathRecordModal" id="deathbtn"
                                         (click)="zoomthepoint(element6.latitude,element6.longitude,element6.divisionId)"><i class="fa fa-map-marker" aria-hidden="true">&nbsp;</i>Map View</a>
                                      <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                         data-target="#ElephantDeathRecordModal"  *ngIf="isAdmin"
                                         (click)="getMovementRecordByID(element6)"><i class="fa fa-edit" aria-hidden="true">&nbsp;</i>Edit</a>
                                    <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                         data-target="#deleteElephantMovementRecordModal"  id="deathDeletebtn"  *ngIf="isAdmin"  (click)="setDeleteRecord(element6.report_Id,'death')"><i class="fa fa-trash" aria-hidden="true">&nbsp;</i>Delete</a>
                                    </div>
                                  </div>
                              </tr>  -->
                              
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                   </div>
                   <div id="pane-D" class="card tab-pane fade" role="tabpanel"
                    aria-labelledby="tab-D">
                    <div class="card-header" role="tab" id="heading-D">
                      <h5 class="mb-0">
                        <a class="collapsed" data-toggle="collapse" href="#collapse-D"
                          aria-expanded="false" aria-controls="collapse-D">
                        Tiger Death Record
                        </a>
                      </h5>
                    </div>
                    <div id="collapse-D" class="collapse" role="tabpanel"
                      data-parent="#accordion" aria-labelledby="heading-D">
                      <div class="card-body">
                        <form [formGroup]="tigerDeathForm"
                          (ngSubmit)="onSubmitTigerDeathRecordForm(tigerDeathForm.value)">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-3 form-group mb-1">
                                  <label>Circle</label>
                                  <select class="form-control" id="circlewl_tigerDeath" formControlName="tigerDeath_circle"  (change)="getAllDivisionByCircleId(circleSelect_tigerDeath.value)" [(ngModel)]='circleid_tigerDeath'    #circleSelect_tigerDeath>
                                    <option value="0">All Circle</option>
                                    <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                      {{circle.circleName}}
                                    </option>
                                </select>
                                </div>
                                <div class="col-md-3 form-group mb-1">
                                  <label>Division</label>
                                  <select class="form-control" id="divisionwl_tigerDeath" formControlName="tigerDeath_division" (change)="getAllRangeByDivId(divisionSelect_tigerDeath.value)" [(ngModel)]='divid_tigerDeath' #divisionSelect_tigerDeath>
                                    <option value="0">All Division</option>
                                    <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                      {{division.divisionName}}
                                    </option>
                                </select>
                                </div>
                                <div class="col-md-3 form-group mb-1">
                                  <label>Range</label>
                                  <select class="form-control" id="rangewl_tigerDeath"  formControlName="tigerDeath_range" (change)="getAllSectionByRangeId(rangeSelect_tigerDeath.value)" [(ngModel)]='rangeid_tigerDeath'   #rangeSelect_tigerDeath>
                                    <option value="0">All Range</option>
                                    <option    *ngFor="let range of rangelist "   value={{range.rangeId}} >
                                      {{range.rangeName}}
                                    </option>
                                </select>
                                </div>
                                <div class="col-md-3 form-group mb-1">
                                  <label>Section</label>
                                  <select class="form-control" id="sectionwl_tigerDeath" formControlName="tigerDeath_section" (change)="getAllBeatBySectionId(sectionSelect_tigerDeath.value)" [(ngModel)]='sectionid_tigerDeath'  #sectionSelect_tigerDeath>
                                    <option value="0">All Section</option>
                                    <option    *ngFor="let section of sectionlist "   value={{section.secId}} >
                                      {{section.secName}}
                                    </option>
                                </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-3 form-group mb-1">
                                  <label>Beat</label>
                                  <select class="form-control" id="beatwl_tigerDeath" formControlName="tigerDeath_beat"  [(ngModel)]='beatid_tigerDeath'  #beatSelect_tigerDeath>
                                    <option value="0">All Beat</option>
                                    <option    *ngFor="let beat of beatlist "   value={{beat.beatId}} >
                                      {{beat.beatName}}
                                    </option>
                                </select>
                                </div>
                              <div class="col-md-3 form-group mb-1">
                                <label>From Date</label>
                                  <div id="datepicker13" class="input-group date"  data-date-format="yyyy-mm-dd" title="From Date">
                                    <input class="form-control" formControlName="tigerDeath_startDate" #sd_tigerDeath id="startDate_tigerDeath" type="text" />
                                    <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
                                </div>
                              </div>
                              <div class="col-md-3 form-group mb-1">
                                <label>To Date</label>
                                  <div id="datepicker14" class="input-group date" data-date-format="yyyy-mm-dd" title="To Date">
                                    <input class="form-control"
                                      formControlName="tigerDeath_endDate" #ed_tigerDeath id="endDate_tigerDeath"
                                      type="text" />
                                    <span class="input-group-addon"><i
                                        class="mdi mdi-calendar"></i></span>
                                </div>
                              </div>
                              <div class="col-md-3 form-group mb-1">
                                  <button type="submit"
                                    class="btn btn-sm btn-success custom-btn-top mr-1">View</button>
                              </div>
                              </div>
                            </div>

                          </div>
                        </form>
                        <hr>
                        <div class="table-responsive">
                          <table id="TigerDeathTable"
                            class="table table-bordered display responsive nowrap"
                            width="99%" datatable [dtOptions]="dtOptions"
                            [dtTrigger]="dtTrigger7">
                            <thead>
                              <tr>
                                <th class="all" width="5%">Sl. No.</th>
                                <th class="all">Division</th>
                                <th class="all">Date of Report</th>
                                <th class="all">Reason of Death</th>
                                <th >Total Death</th>
                                <th class="all">Lat</th>
                                <th class="all">Long</th>
                                <th class="all">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="let element7 of tabledata7 ; let i = index;">
                                <td>{{i+1}}</td>
                                <td>{{element7.division}}</td>
                                <td>{{ (element7.incidentReport  !== 'null') ? (element7.incidentReport | date:'dd-MM-yyyy')  : '' }}  </td>
                                <td>{{element7.deathReason}}</td>
                                <td>{{ element7.totalDeath}}</td>
                                <td>{{element7.latitude}}</td>
                                <td>{{element7.longitude}}</td>

                                <td>
                                  <div class="dropdown">
                                    <button
                                      class="btn btn-sm btn-info btn-icon btn-radius dropdown-toggle"
                                      type="button" id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false">
                                      <i class="fa fa-cog"></i>
                                    </button>
                                    <div class="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton">
                                      <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal"
                                          data-target="#OthersAnimalDeathRecordModal"
                                        (click)="getDeathRecordByID(element7)"><i class="fa fa-eye" aria-hidden="true">&nbsp;</i>View</a>
                                        <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                         data-target="#mapElephantDeathRecordModal" id="deathbtn"
                                         (click)="zoomthepoint(element7.latitude,element7.longitude,element7.divisionId)"><i class="fa fa-map-marker" aria-hidden="true">&nbsp;</i>Map View</a>
                                         <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                         data-target="#editRecordModal"  *ngIf="isAdmin"
                                         (click)="getDeathRecordByIDForEdit(element7,'tiger')"><i class="fa fa-edit" aria-hidden="true">&nbsp;</i>Edit</a>
                                    <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                         data-target="#deleteElephantMovementRecordModal"  id="deathDeletebtn"  *ngIf="isAdmin"  (click)="setDeleteRecord(element7.report_Id,'death')"><i class="fa fa-trash" aria-hidden="true">&nbsp;</i>Delete</a>
                                    </div>
                                  </div>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                   </div>
                   <div id="pane-E" class="card tab-pane fade" role="tabpanel"
                   aria-labelledby="tab-E">
                   <div class="card-header" role="tab" id="heading-E">
                     <h5 class="mb-0">
                       <a class="collapsed" data-toggle="collapse" href="#collapse-E"
                         aria-expanded="false" aria-controls="collapse-E">
                           Leopard Death Record
                       </a>
                     </h5>
                   </div>
                   <div id="collapse-E" class="collapse" role="tabpanel"
                     data-parent="#accordion" aria-labelledby="heading-E">
                     <div class="card-body">
                       <form [formGroup]="leopardDeathForm"
                         (ngSubmit)="onSubmitLeopardDeathRecordForm(leopardDeathForm.value)">
                         <div class="row">
                           <div class="col-md-6">
                             <div class="row">
                               <div class="col-md-3 form-group mb-1">
                                 <label>Circle</label>
                                 <select class="form-control" id="circlewl_leopardDeath" formControlName="leopardDeath_circle"  (change)="getAllDivisionByCircleId(circleSelect_leopardDeath.value)" [(ngModel)]='circleid_leopardDeath'    #circleSelect_leopardDeath>
                                   <option value="0">All Circle</option>
                                   <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                     {{circle.circleName}}
                                   </option>
                               </select>
                               </div>
                               <div class="col-md-3 form-group mb-1">
                                 <label>Division</label>
                                 <select class="form-control" id="divisionwl_leopardDeath" formControlName="leopardDeath_division" (change)="getAllRangeByDivId(divisionSelect_leopardDeath.value)" [(ngModel)]='divid_leopardDeath' #divisionSelect_leopardDeath>
                                   <option value="0">All Division</option>
                                   <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                     {{division.divisionName}}
                                   </option>
                               </select>
                               </div>
                               <div class="col-md-3 form-group mb-1">
                                 <label>Range</label>
                                 <select class="form-control" id="rangewl_leopardDeath"  formControlName="leopardDeath_range" (change)="getAllSectionByRangeId(rangeSelect_leopardDeath.value)" [(ngModel)]='rangeid_leopardDeath'   #rangeSelect_leopardDeath>
                                   <option value="0">All Range</option>
                                   <option    *ngFor="let range of rangelist "   value={{range.rangeId}} >
                                     {{range.rangeName}}
                                   </option>
                               </select>
                               </div>
                               <div class="col-md-3 form-group mb-1">
                                 <label>Section</label>
                                 <select class="form-control" id="sectionwl_leopardDeath" formControlName="leopardDeath_section" (change)="getAllBeatBySectionId(sectionSelect_leopardDeath.value)" [(ngModel)]='sectionid_leopardDeath'  #sectionSelect_leopardDeath>
                                   <option value="0">All Section</option>
                                   <option    *ngFor="let section of sectionlist "   value={{section.secId}} >
                                     {{section.secName}}
                                   </option>
                               </select>
                               </div>
                             </div>
                           </div>
                           <div class="col-md-6">
                             <div class="row">
                               <div class="col-md-3 form-group mb-1">
                                 <label>Beat</label>
                                 <select class="form-control" id="beatwl_leopardDeath" formControlName="leopardDeath_beat"  [(ngModel)]='beatid_leopardDeath'  #beatSelect_leopardDeath>
                                   <option value="0">All Beat</option>
                                   <option    *ngFor="let beat of beatlist "   value={{beat.beatId}} >
                                     {{beat.beatName}}
                                   </option>
                               </select>
                               </div>
                             <div class="col-md-3 form-group mb-1">
                               <label>From Date</label>
                                 <div id="datepicker15" class="input-group date"  data-date-format="yyyy-mm-dd" title="From Date">
                                   <input class="form-control" formControlName="leopardDeath_startDate" #sd_leopardDeath id="startDate_leopardDeath" type="text" />
                                   <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
                               </div>
                             </div>
                             <div class="col-md-3 form-group mb-1">
                               <label>To Date</label>
                                 <div id="datepicker16" class="input-group date" data-date-format="yyyy-mm-dd" title="To Date">
                                   <input class="form-control"
                                     formControlName="leopardDeath_endDate" #ed_leopardDeath id="endDate_leopardDeath"
                                     type="text" />
                                   <span class="input-group-addon"><i
                                       class="mdi mdi-calendar"></i></span>
                               </div>
                             </div>
                             <div class="col-md-3 form-group mb-1">
                                 <button type="submit"
                                   class="btn btn-sm btn-success custom-btn-top mr-1">View</button>
                             </div>
                             </div>
                           </div>

                         </div>
                       </form>
                       <hr>
                       <div class="table-responsive">
                         <table id="LeopardDeathTable"
                           class="table table-bordered display responsive nowrap"
                           width="99%" datatable [dtOptions]="dtOptions"
                           [dtTrigger]="dtTrigger8">
                           <thead>
                             <tr>
                               <th class="all" width="5%">Sl. No.</th>
                               <th class="all">Division</th>
                               <th class="all">Date of Report</th>
                               <th class="all">Reason of Death</th>
                               <th >Total Death</th>
                               <th class="all">Lat</th>
                               <th class="all">Long</th>
                               <th class="all">Action</th>
                             </tr>
                           </thead>
                           <tbody>
                             <tr
                               *ngFor="let element8 of tabledata8 ; let i = index;">
                               <td>{{i+1}}</td>
                               <td>{{element8.division}}</td>
                               <td>{{ (element8.incidentReport  !== 'null') ? (element8.incidentReport | date:'dd-MM-yyyy')  : '' }}  </td>
                               <td>{{element8.deathReason}}</td>
                               <td>{{ element8.totalDeath}}</td>
                               <td>{{element8.latitude}}</td>
                               <td>{{element8.longitude}}</td>

                               <td>
                                 <div class="dropdown">
                                   <button
                                     class="btn btn-sm btn-info btn-icon btn-radius dropdown-toggle"
                                     type="button" id="dropdownMenuButton"
                                     data-toggle="dropdown"
                                     aria-haspopup="true"
                                     aria-expanded="false">
                                     <i class="fa fa-cog"></i>
                                   </button>
                                   <div class="dropdown-menu"
                                     aria-labelledby="dropdownMenuButton">
                                     <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal"
                                         data-target="#OthersAnimalDeathRecordModal"
                                       (click)="getDeathRecordByID(element8)"><i class="fa fa-eye" aria-hidden="true">&nbsp;</i>View</a>
                                       <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                        data-target="#mapElephantDeathRecordModal" id="deathbtn"
                                        (click)="zoomthepoint(element8.latitude,element8.longitude,element8.divisionId)"><i class="fa fa-map-marker" aria-hidden="true">&nbsp;</i>Map View</a>
                                     <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                        data-target="#editRecordModal"  *ngIf="isAdmin"
                                        (click)="getDeathRecordByIDForEdit(element8,'leopard')"><i class="fa fa-edit" aria-hidden="true">&nbsp;</i>Edit</a>
                                   <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                        data-target="#deleteElephantMovementRecordModal"  id="deathDeletebtn"  *ngIf="isAdmin"  (click)="setDeleteRecord(element8.report_Id,'death')"><i class="fa fa-trash" aria-hidden="true">&nbsp;</i>Delete</a>
                                   </div>
                                 </div>
                             </tr>
                           </tbody>
                         </table>
                       </div>
                     </div>
                   </div>
                   </div>
                   <div id="pane-F" class="card tab-pane fade" role="tabpanel"
                   aria-labelledby="tab-F">
                   <div class="card-header" role="tab" id="heading-F">
                     <h5 class="mb-0">
                       <a class="collapsed" data-toggle="collapse" href="#collapse-F"
                         aria-expanded="false" aria-controls="collapse-F">
                           Pangolin Death Record
                       </a>
                     </h5>
                   </div>
                   <div id="collapse-F" class="collapse" role="tabpanel"
                     data-parent="#accordion" aria-labelledby="heading-F">
                     <div class="card-body">
                       <form [formGroup]="pangolinDeathForm"
                         (ngSubmit)="onSubmitPangolinDeathRecordForm(pangolinDeathForm.value)">
                         <div class="row">
                           <div class="col-md-6">
                             <div class="row">
                               <div class="col-md-3 form-group mb-1">
                                 <label>Circle</label>
                                 <select class="form-control" id="circlewl_pangolinDeath" formControlName="pangolinDeath_circle"  (change)="getAllDivisionByCircleId(circleSelect_pangolinDeath.value)" [(ngModel)]='circleid_pangolinDeath'    #circleSelect_pangolinDeath>
                                   <option value="0">All Circle</option>
                                   <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                     {{circle.circleName}}
                                   </option>
                               </select>
                               </div>
                               <div class="col-md-3 form-group mb-1">
                                 <label>Division</label>
                                 <select class="form-control" id="divisionwl_pangolinDeath" formControlName="pangolinDeath_division" (change)="getAllRangeByDivId(divisionSelect_pangolinDeath.value)" [(ngModel)]='divid_pangolinDeath' #divisionSelect_pangolinDeath>
                                   <option value="0">All Division</option>
                                   <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                     {{division.divisionName}}
                                   </option>
                               </select>
                               </div>
                               <div class="col-md-3 form-group mb-1">
                                 <label>Range</label>
                                 <select class="form-control" id="rangewl_pangolinDeath"  formControlName="pangolinDeath_range" (change)="getAllSectionByRangeId(rangeSelect_pangolinDeath.value)" [(ngModel)]='rangeid_pangolinDeath'   #rangeSelect_pangolinDeath>
                                   <option value="0">All Range</option>
                                   <option    *ngFor="let range of rangelist "   value={{range.rangeId}} >
                                     {{range.rangeName}}
                                   </option>
                               </select>
                               </div>
                               <div class="col-md-3 form-group mb-1">
                                 <label>Section</label>
                                 <select class="form-control" id="sectionwl_pangolinDeath" formControlName="pangolinDeath_section" (change)="getAllBeatBySectionId(sectionSelect_pangolinDeath.value)" [(ngModel)]='sectionid_pangolinDeath'  #sectionSelect_pangolinDeath>
                                   <option value="0">All Section</option>
                                   <option    *ngFor="let section of sectionlist "   value={{section.secId}} >
                                     {{section.secName}}
                                   </option>
                               </select>
                               </div>
                             </div>
                           </div>
                           <div class="col-md-6">
                             <div class="row">
                               <div class="col-md-3 form-group mb-1">
                                 <label>Beat</label>
                                 <select class="form-control" id="beatwl_pangolinDeath" formControlName="pangolinDeath_beat"  [(ngModel)]='beatid_pangolinDeath'  #beatSelect_pangolinDeath>
                                   <option value="0">All Beat</option>
                                   <option    *ngFor="let beat of beatlist "   value={{beat.beatId}} >
                                     {{beat.beatName}}
                                   </option>
                               </select>
                               </div>
                             <div class="col-md-3 form-group mb-1">
                               <label>From Date</label>
                                 <div id="datepicker17" class="input-group date"  data-date-format="yyyy-mm-dd" title="From Date">
                                   <input class="form-control" formControlName="pangolinDeath_startDate" #sd_pangolinDeath id="startDate_pangolinDeath" type="text" />
                                   <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
                               </div>
                             </div>
                             <div class="col-md-3 form-group mb-1">
                               <label>To Date</label>
                                 <div id="datepicker18" class="input-group date" data-date-format="yyyy-mm-dd" title="To Date">
                                   <input class="form-control"
                                     formControlName="pangolinDeath_endDate" #ed_pangolinDeath id="endDate_pangolinDeath"
                                     type="text" />
                                   <span class="input-group-addon"><i
                                       class="mdi mdi-calendar"></i></span>
                               </div>
                             </div>
                             <div class="col-md-3 form-group mb-1">
                                 <button type="submit"
                                   class="btn btn-sm btn-success custom-btn-top mr-1">View</button>
                             </div>
                             </div>
                           </div>

                         </div>
                       </form>
                       <hr>
                       <div class="table-responsive">
                         <table id="PangolinDeathTable"
                           class="table table-bordered display responsive nowrap"
                           width="99%" datatable [dtOptions]="dtOptions"
                           [dtTrigger]="dtTrigger9">
                           <thead>
                             <tr>
                               <th class="all" width="5%">Sl. No.</th>
                               <th class="all">Division</th>
                               <th class="all">Date of Report</th>
                               <th class="all">Reason of Death</th>
                               <th >Total Death</th>
                               <th class="all">Lat</th>
                               <th class="all">Long</th>
                               <th class="all">Action</th>
                             </tr>
                           </thead>
                           <tbody>
                             <tr
                               *ngFor="let element9 of tabledata9 ; let i = index;">
                               <td>{{i+1}}</td>
                               <td>{{element9.division}}</td>
                               <td>{{ (element9.incidentReport  !== 'null') ? (element9.incidentReport | date:'dd-MM-yyyy')  : '' }}  </td>
                               <td>{{element9.deathReason}}</td>
                               <td>{{ element9.totalDeath}}</td>
                               <td>{{element9.latitude}}</td>
                               <td>{{element9.longitude}}</td>

                               <td>
                                 <div class="dropdown">
                                   <button
                                     class="btn btn-sm btn-info btn-icon btn-radius dropdown-toggle"
                                     type="button" id="dropdownMenuButton"
                                     data-toggle="dropdown"
                                     aria-haspopup="true"
                                     aria-expanded="false">
                                     <i class="fa fa-cog"></i>
                                   </button>
                                   <div class="dropdown-menu"
                                     aria-labelledby="dropdownMenuButton">
                                     <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal"
                                         data-target="#OthersAnimalDeathRecordModal"
                                       (click)="getDeathRecordByID(element9)"><i class="fa fa-eye" aria-hidden="true">&nbsp;</i>View</a>
                                       <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                        data-target="#mapElephantDeathRecordModal" id="deathbtn"
                                        (click)="zoomthepoint(element9.latitude,element9.longitude,element9.divisionId)"><i class="fa fa-map-marker" aria-hidden="true">&nbsp;</i>Map View</a>
                                     <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                        data-target="#editRecordModal"  *ngIf="isAdmin"
                                        (click)="getDeathRecordByIDForEdit(element9,'pangolin')"><i class="fa fa-edit" aria-hidden="true">&nbsp;</i>Edit</a>
                                   <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                        data-target="#deleteElephantMovementRecordModal"  id="deathDeletebtn"  *ngIf="isAdmin"  (click)="setDeleteRecord(element9.report_Id,'death')"><i class="fa fa-trash" aria-hidden="true">&nbsp;</i>Delete</a>
                                   </div>
                                 </div>
                             </tr>
                           </tbody>
                         </table>
                       </div>
                     </div>
                   </div>
                   </div>
                   <div id="pane-G" class="card tab-pane fade" role="tabpanel"
                   aria-labelledby="tab-G">
                   <div class="card-header" role="tab" id="heading-G">
                     <h5 class="mb-0">
                       <a class="collapsed" data-toggle="collapse" href="#collapse-G"
                         aria-expanded="false" aria-controls="collapse-G">
                       Human Injury
                       </a>
                     </h5>
                   </div>
                    <div id="collapse-G" class="collapse" role="tabpanel"
                    data-parent="#accordion" aria-labelledby="heading-C">
                    <div class="card-body">
                      <form [formGroup]="humanInjuryForm"
                        (ngSubmit)="onSubmithumanInjuryMovementRecord(humanInjuryForm.value)">
                        <div class="row">
                          <div class="col-md-10">
                            <div class="row">
                              <div class="col-md-3 form-group mb-1">
                                <label>Circle</label>
                                <select class="form-control" id="circlewl_humanInjury" formControlName="humanInjury_circle"  (change)="getAllDivisionByCircleId(circleSelect_humanInjury.value)" [(ngModel)]='circleid_humanInjury'    #circleSelect_humanInjury>
                                  <option value="0">All Circle</option>
                                  <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                    {{circle.circleName}}
                                  </option>
                              </select>
                              </div>
                              <div class="col-md-3 form-group mb-1">
                                <label>Division</label>
                                <select class="form-control" id="divisionwl_humanInjury" formControlName="humanInjury_division" (change)="getAllRangeByDivId(divisionSelect_humanInjury.value)" [(ngModel)]='divid_humanInjury' #divisionSelect_humanInjury>
                                  <option value="0">All Division</option>
                                  <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                    {{division.divisionName}}
                                  </option>
                              </select>
                              </div>
                              <div class="col-md-3 form-group mb-1">
                                <label>From Date</label>
                                  <div id="datepicker11" class="input-group date"  data-date-format="yyyy-mm-dd" title="From Date">
                                    <input class="form-control" formControlName="humanInjury_startDate" #sd_humanInjury id="startDate_humanInjury" type="text" />
                                    <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
                                </div>
                              </div>
                              <div class="col-md-3 form-group mb-1">
                                <label>To Date</label>
                                  <div id="datepicker12" class="input-group date" data-date-format="yyyy-mm-dd" title="To Date">
                                    <input class="form-control"
                                      formControlName="humanInjury_endDate" #ed_humanInjury id="endDate_humanInjury"
                                      type="text" />
                                    <span class="input-group-addon"><i
                                        class="mdi mdi-calendar"></i></span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="row">
                            <div class="col-md-12 form-group mb-1">
                                <button type="submit"
                                  class="btn btn-sm btn-success custom-btn-top mr-1">View</button>
                            </div>
                            </div>
                          </div>

                        </div>
                      </form>
                      <hr>
                      <div class="table-responsive">
                        <table id="humanInjuryTable"
                          class="table table-bordered display responsive nowrap"
                          width="99%" datatable [dtOptions]="dtOptions"
                          [dtTrigger]="dtTrigger10">
                          <thead>
                            <tr>
                              <th class="all" width="5%">Sl. No.</th>
                              <th class="all">Circle</th>
                              <th class="all">Division</th>
                              <th class="all">Range</th>
                              <th class="all">Section</th>
                              <th class="all">Beat</th>
                              <th class="all">Incident Type</th>
                              <th class="all">Total Number</th>
                              <th class="all">Date Of Incident</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="let element10 of tabledata10 ; let i = index;">
                              <td>{{i+1}}</td>
                                <td>{{element10.circleName}}</td>
                              <td>{{element10.divName}}</td>
                              <td>{{element10.rangeName}}</td>
                              <td>{{element10.secName}}</td>
                              <td>{{element10.beatName}}</td>
                              <td>{{element10.incidentType}}</td>
                              <td>{{element10.totalCasulaity}}</td>    
                                <td>{{ (element10.incidentTime  !== 'null') ? (element10.incidentTime | date:'dd-MM-yyyy')  : '' }}  </td>
                                <!-- <td>
                                <div class="dropdown">
                                  <button
                                    class="btn btn-sm btn-info btn-icon btn-radius dropdown-toggle"
                                    type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false">
                                    <i class="fa fa-cog"></i>
                                  </button>
                                  <div class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal"
                                        data-target="#HumanDeathRecordModal"
                                      (click)="getMovementRecordByID(element6)"><i class="fa fa-eye" aria-hidden="true">&nbsp;</i>View</a>
                                      <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                        data-target="#mapElephantDeathRecordModal" id="deathbtn"
                                        (click)="zoomthepoint(element6.latitude,element6.longitude,element6.divisionId)"><i class="fa fa-map-marker" aria-hidden="true">&nbsp;</i>Map View</a>
                                    <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                        data-target="#ElephantDeathRecordModal"  *ngIf="isAdmin"
                                        (click)="getMovementRecordByID(element6)"><i class="fa fa-edit" aria-hidden="true">&nbsp;</i>Edit</a>
                                  <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
                                        data-target="#deleteElephantMovementRecordModal"  id="deathDeletebtn"  *ngIf="isAdmin"  (click)="setDeleteRecord(element6.report_Id,'death')"><i class="fa fa-trash" aria-hidden="true">&nbsp;</i>Delete</a>
                                  </div>
                                </div>
                            </tr>  -->
                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  </div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
<div class="modal fade" id="incidentElephantMovementRecordModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLongTitle">Incident  Record</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td width="50%"><strong>Incident Date : </strong></td>
                  <td width="50%">{{incidentReportDate | date:'dd-MMM-yyyy'}}</td>
                </tr>
                <tr>
                  <td><strong>Location (village, RF, PRF, etc...) : </strong></td>
                  <td>{{location}}</td>
                </tr>
                <tr>
                  <td><strong>Latitude : </strong></td>
                  <td>{{latitude}}</td>
                </tr>
                <tr>
                  <td><strong>Longitude : </strong></td>
                  <td>{{longitude}}</td>
                </tr>
                <tr>
                  <td><strong>Circle : </strong></td>
                  <td>{{circle}}</td>
                </tr>
                <tr>
                  <td><strong>Division : </strong></td>
                  <td>{{division}}</td>
                </tr>
                <tr>
                  <td><strong>Range : </strong></td>
                  <td>{{range}}</td>
                </tr>
                <tr>
                  <td><strong>Section : </strong></td>
                  <td>{{section}}</td>
                </tr>
                <tr>
                  <td><strong>Beat : </strong></td>
                  <td>{{beat}}</td>
                </tr>
                <tr>
                  <td><strong>Photo : </strong></td>
                  <td>
                    <!-- <img [src]="incidentPhoto" *ngIf="incidentPhoto"
                      class="img-fluid mr-2 mb-2" style="height:85px">
                     -->
                     <a href="{{incidentPhoto}}" class="glightbox gallery_product filter elephant" data-fancybox="gallery" data-caption="">
                      <img [src]="incidentPhoto" *ngIf="incidentPhoto"
            class="img-fluid mr-2 mb-2" style="height:85px" (error)="defaultUrl($event)">
                      <div class="overlay">
                        <span class="icon">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                    </a>

                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</div>
<div class="modal fade" id="ElephantDeathRecordModalView" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLongTitle">Elephant Death Record</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td width="50%"><strong>Incident Date : </strong></td>
                <td width="50%">{{incidentReportDate | date:'dd-MMM-yyyy'}}</td>
              </tr>
              <tr>
                <td width="50%"><strong>Incident Time : </strong></td>
                <td width="50%">{{incidentReportDate | date:'shortTime'}}</td>
              </tr>
              <tr>
                <td><strong>Location (village, RF, PRF, etc...) : </strong></td>
                <td>{{location}}</td>
              </tr>
              <tr>
                <td><strong>Latitude (N): </strong></td>
                <td>{{latitude}}</td>
              </tr>
              <tr>
                <td><strong>Longitude (N) : </strong></td>
                <td>{{longitude}}</td>
              </tr>
              <tr>
                <td><strong>Circle : </strong></td>
                <td>{{circle}}</td>
              </tr>
              <tr>
                <td><strong>Division : </strong></td>
                <td>{{division}}</td>
              </tr>
              <tr>
                <td><strong>Range : </strong></td>
                <td>{{range}}</td>
              </tr>
              <tr>
                <td><strong>Section : </strong></td>
                <td>{{section}}</td>
              </tr>
              <tr>
                <td><strong>Beat : </strong></td>
                <td>{{beat}}</td>
              </tr>
              <tr>
                <td><strong>Total Death : </strong></td>
                <td>{{(totalDeath !== 'null') ? totalDeath : 0 }}</td>
              </tr>
              <tr>
                <td><strong>Calf : </strong></td>
                <td>{{(calf !== 'null') ? calf : 0 }}</td>
              </tr>
              <tr>
                <td><strong>Female : </strong></td>
                <td>{{(female !== 'null') ? female : 0 }}</td>
              </tr>
              <tr>
                <td><strong>Tusker : </strong></td>
                <td>{{(tusker !== 'null') ? tusker : 0 }}</td>
              </tr>

              <tr>
                <td><strong>Mukhna : </strong></td>
                <td>{{(mukhna !== 'null') ? mukhna : 0 }}</td>
              </tr>
              <tr>
                <td><strong>Remarks : </strong></td>
                <td>{{(incidentRemark !== 'null') ? incidentRemark : "" }}</td>
              </tr>

              <tr>
                <td><strong>Photo : </strong></td>
                <td>
                  <!-- <img [src]="incidentPhoto" *ngIf="incidentPhoto"
                    class="img-fluid mr-2 mb-2" style="height:85px"> -->

                  <a href="{{incidentPhoto}}" class="glightbox gallery_product filter elephant" data-fancybox="gallery"
                    data-caption="">
                    <img [src]="incidentPhoto" *ngIf="incidentPhoto" class="img-fluid mr-2 mb-2" style="height:85px"
                      (error)="defaultUrl($event)">
                    <div class="overlay">
                      <span class="icon">
                        <i class="fa fa-search"></i>
                      </span>
                    </div>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="OthersAnimalDeathRecordModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLongTitle">Death Record</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td width="50%"><strong>Incident Date : </strong></td>
                <td width="50%">{{incidentReportDate | date:'dd-MMM-yyyy'}}</td>
              </tr>
              <tr>
                <td width="50%"><strong>Incident Time : </strong></td>
                <td width="50%">{{incidentReportDate | date:'shortTime'}}</td>
              </tr>
              <tr>
                <td><strong>Location (village, RF, PRF, etc...) : </strong></td>
                <td>{{location}}</td>
              </tr>
              <tr>
                <td><strong>Latitude (N): </strong></td>
                <td>{{latitude}}</td>
              </tr>
              <tr>
                <td><strong>Longitude (N) : </strong></td>
                <td>{{longitude}}</td>
              </tr>
              <tr>
                <td><strong>Circle : </strong></td>
                <td>{{circle}}</td>
              </tr>
              <tr>
                <td><strong>Division : </strong></td>
                <td>{{division}}</td>
              </tr>
              <tr>
                <td><strong>Range : </strong></td>
                <td>{{range}}</td>
              </tr>
              <tr>
                <td><strong>Section : </strong></td>
                <td>{{section}}</td>
              </tr>
              <tr>
                <td><strong>Beat : </strong></td>
                <td>{{beat}}</td>
              </tr>
              <tr>
                <td><strong>Total Death : </strong></td>
                <td>{{(totalDeath !== 'null') ? totalDeath : 0 }}</td>
              </tr>
              <tr>
                <td><strong>Male : </strong></td>
                <td>{{(male !== 'null') ? male : 0 }}</td>
              </tr>
              <tr>
                <td><strong>Female : </strong></td>
                <td>{{(female !== 'null') ? female : 0 }}</td>
              </tr>
              <tr>
                <td><strong>Calf : </strong></td>
                <td>{{(calf !== 'null') ? calf : 0 }}</td>
              </tr>

              <tr>
                <td><strong>Remarks : </strong></td>
                <td>{{(incidentRemark !== 'null') ? incidentRemark : "" }}</td>
              </tr>

              <tr>
                <td><strong>Photo : </strong></td>
                <td>
                  <a href="{{incidentPhoto}}" class="glightbox gallery_product filter elephant" data-fancybox="gallery"
                    data-caption="">
                    <img [src]="incidentPhoto" *ngIf="incidentPhoto" class="img-fluid mr-2 mb-2" style="height:85px"
                      (error)="defaultUrl($event)">
                    <div class="overlay">
                      <span class="icon">
                        <i class="fa fa-search"></i>
                      </span>
                    </div>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade gallery" id="editRecordModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLongTitle">Edit Record</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="reporteditForm" (ngSubmit)="updateReport()">
          <div class="row form-group">
            <div class="col-md-4">
              <label>Date of Incident</label>
              <div id="datepicker7" class="input-group date" data-date-format="dd-mm-yyyy" title="From Date">
                <input class="form-control" #sd7 id="startDate7" type="text" />
                <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
              </div>
            </div>
            <div class="col-md-4">
              <label>Incident Time</label>
              <div class="input-group clockpicker1" data-autoclose="true">
                <input type="text" class="form-control" value="09:30" #from_time id="from_time">
                <span class="input-group-addon">
                  <span class="fa fa-clock-o"></span>
                </span>
              </div>
            </div>
            <div class="col-md-4">
              <label>Location (village, RF, PRF, etc...)</label>
              <input type="text" class="form-control" placeholder="Location (village, RF, PRF, etc...)"
                formControlName="location">
            </div>
          </div>
          <div class="row form-group">


          </div>
          <div class="row form-group">

            <div class="col-md-4">
              <label>Calf</label>
              <input type="number" class="form-control" placeholder="Calf" formControlName="calf"
                [ngClass]="{ 'is-invalid': reporteditForm.controls['calf'].invalid && (reporteditForm.controls['calf'].dirty || reporteditForm.controls['calf'].touched) }">
              <div
                *ngIf="reporteditForm.controls['calf'].invalid && (reporteditForm.controls['calf'].dirty || reporteditForm.controls['calf'].touched)"
                class="text-danger mt-1">
                <small *ngIf="reporteditForm.controls['calf'].errors?.required">
                  Calf is required.
                </small>
                <small *ngIf="reporteditForm.controls['calf'].errors?.pattern">
                  Please enter a valid number.
                </small>
              </div>
            </div>
            <div class="col-md-4">
              <label>Female</label>
              <input type="number" class="form-control" placeholder="Female" formControlName="female"
                [ngClass]="{ 'is-invalid': reporteditForm.controls['female'].invalid && (reporteditForm.controls['female'].dirty || reporteditForm.controls['female'].touched) }">
              <div
                *ngIf="reporteditForm.controls['female'].invalid && (reporteditForm.controls['female'].dirty || reporteditForm.controls['female'].touched)"
                class="text-danger mt-1">
                <small *ngIf="reporteditForm.controls['female'].errors?.required">
                  Female is required.
                </small>
                <small *ngIf="reporteditForm.controls['female'].errors?.pattern">
                  Please enter a valid number.
                </small>
              </div>
            </div>

          </div>
          <div class="row form-group">

            <div class="col-md-4">
              <label>Remarks</label>
              <input type="text" class="form-control" placeholder="Remarks" formControlName="remarks">
            </div>
          </div>


          <hr>
          <div class="text-center">
            <button type="submit" class="btn btn-sm btn-success mr-1"
              [disabled]="!reporteditForm.touched">Update</button>
            <button type="button" class="btn btn-sm btn-danger" (click)="onclickCancel()">Cancel</button>
          </div>
        </form>



      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="photoElephantMovementRecordModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLongTitle">View Iploaded Photo</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="height:auto">
        <img src="assets/images/9.jpg" class="img-fluid">
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="mapFireRecordModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLongTitle">Map View</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="emfire" style="height: 500px; width: 100%"></div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="mapElephantDeathRecordModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLongTitle">Map View</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="emelephDeath" style="height: 500px; width: 100%"></div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="deleteElephantMovementRecordModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle_delete" aria-hidden="true">
  <div class="modal-dialog modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <div class="icon-box">
          <i class="material-icons">&#xE5CD;</i>
        </div>
        <h4 class="modal-title w-100">Are you sure?</h4>
      </div>
      <div class="modal-body">
        <p>Do you really want to delete these records? This process cannot be undone.</p>
      </div>
      <div class="modal-footer justify-content-center">
        <div class="spinner text-center">
          <div *ngIf="loader2" class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" *ngIf="!loader2" (click)="confirmDelete()">Delete</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade gallery" id="editDeathRecordModal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLongTitle">Edit Death Record</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <form [formGroup]="reporteditForm" (ngSubmit)="updateReport()">
          <div class="row form-group">
            <div class="col-md-4">
              <label>Date of Incident</label>
              <div id="datepicker7" class="input-group date" data-date-format="dd-mm-yyyy" title="From Date">
                <input class="form-control" #sd7 id="startDate7" type="text" />
                <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
              </div>
            </div>
            <div class="col-md-4">
              <label>Incident Time</label>
              <div class="input-group clockpicker1" data-autoclose="true">
                <input type="text" class="form-control" value="09:30" #from_time id="from_time">
                <span class="input-group-addon">
                  <span class="fa fa-clock-o"></span>
                </span>
              </div>
            </div>
            <div class="col-md-4">
              <label>Location (village, RF, PRF, etc...)</label>
              <input type="text" class="form-control" placeholder="Location (village, RF, PRF, etc...)"
                formControlName="location">
            </div>
          </div>
          <div class="row form-group">


          </div>
          <div class="row form-group">

            <div class="col-md-4">
              <label>Calf</label>
              <input type="number" class="form-control" placeholder="Calf" formControlName="calf"
                [ngClass]="{ 'is-invalid': reporteditForm.controls['calf'].invalid && (reporteditForm.controls['calf'].dirty || reporteditForm.controls['calf'].touched) }">
              <div
                *ngIf="reporteditForm.controls['calf'].invalid && (reporteditForm.controls['calf'].dirty || reporteditForm.controls['calf'].touched)"
                class="text-danger mt-1">
                <small *ngIf="reporteditForm.controls['calf'].errors?.required">
                  Calf is required.
                </small>
                <small *ngIf="reporteditForm.controls['calf'].errors?.pattern">
                  Please enter a valid number.
                </small>
              </div>
            </div>
            <div class="col-md-4">
              <label>Female</label>
              <input type="number" class="form-control" placeholder="Female" formControlName="female"
                [ngClass]="{ 'is-invalid': reporteditForm.controls['female'].invalid && (reporteditForm.controls['female'].dirty || reporteditForm.controls['female'].touched) }">
              <div
                *ngIf="reporteditForm.controls['female'].invalid && (reporteditForm.controls['female'].dirty || reporteditForm.controls['female'].touched)"
                class="text-danger mt-1">
                <small *ngIf="reporteditForm.controls['female'].errors?.required">
                  Female is required.
                </small>
                <small *ngIf="reporteditForm.controls['female'].errors?.pattern">
                  Please enter a valid number.
                </small>
              </div>
            </div>
            <div class="col-md-4">
              <label>Tusker</label>
              <input type="number" class="form-control" placeholder="Tusker" formControlName="tusker"
                [ngClass]="{ 'is-invalid': reporteditForm.controls['tusker'].invalid && (reporteditForm.controls['tusker'].dirty || reporteditForm.controls['tusker'].touched) }">
              <div
                *ngIf="reporteditForm.controls['tusker'].invalid && (reporteditForm.controls['tusker'].dirty || reporteditForm.controls['tusker'].touched)"
                class="text-danger mt-1">
                <small *ngIf="reporteditForm.controls['tusker'].errors?.required">
                  Tusker is required.
                </small>
                <small *ngIf="reporteditForm.controls['tusker'].errors?.pattern">
                  Please enter a valid number.
                </small>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-md-4">
              <label>Mukhna</label>
              <input type="number" class="form-control" placeholder="Mukhna" formControlName="mukhna"
                [ngClass]="{ 'is-invalid': reporteditForm.controls['mukhna'].invalid && (reporteditForm.controls['mukhna'].dirty || reporteditForm.controls['mukhna'].touched) }">
              <div
                *ngIf="reporteditForm.controls['mukhna'].invalid && (reporteditForm.controls['mukhna'].dirty || reporteditForm.controls['mukhna'].touched)"
                class="text-danger mt-1">
                <small *ngIf="reporteditForm.controls['mukhna'].errors?.required">
                  Mukhna is required.
                </small>
                <small *ngIf="reporteditForm.controls['mukhna'].errors?.pattern">
                  Please enter a valid number.
                </small>
              </div>
            </div>
            <div class="col-md-4">
              <label>Remarks</label>
              <input type="text" class="form-control" placeholder="Remarks" formControlName="remarks">
            </div>
          </div>


          <hr>
          <div class="text-center">
            <button type="submit" class="btn btn-sm btn-success mr-1"
              [disabled]="!reporteditForm.touched">Update</button>
            <button type="button" class="btn btn-sm btn-danger" (click)="onclickCancel()">Cancel</button>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
</div>
<div id="footer">
  <app-masterfooter></app-masterfooter>
</div>

</div>
