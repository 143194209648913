import { Component, OnInit } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var jQuery: any;

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

	public data = [
	    {slno: '1', notificationno: '6257', notificationname: 'Empanellment of Organisation', year: '2018', month: 'July'},
	    {slno: '2', notificationno: '3469', notificationname: 'Clarification on payment of RWLMP fund', year: '2018', month: 'February'},
	    {slno: '3', notificationno: '690', notificationname: 'Empanelment of Organisations', year: '2018', month: 'January'},
	    {slno: '4', notificationno: 'sswlmp', notificationname: 'Site specific WLMP-Guide lines', year: '2018', month: 'January'},
	    {slno: '5', notificationno: '18118', notificationname: 'Advertisement', year: '2018', month: 'January'},
	    {slno: '6', notificationno: 'FEWLWLF0038/14/12826', notificationname: 'N20160715_PS_PersonsAsHonoraryWLW', year: '2016', month: 'July'},
	    {slno: '7', notificationno: '8F(WL)-67/2016', notificationname: 'N20160622_PS_ReloacationOfVillagersFromSanctuaries', year: '2016', month: 'June'},
	    {slno: '8', notificationno: '22551500382014/3425', notificationname: 'N20160222_PS_PersonsAsHonoraryWLW', year: '2016', month: 'February'},
	    {slno: '9', notificationno: 'WLF/0052/2015/2430', notificationname: 'N20160208_SSG_StateGovRules', year: '2016', month: 'February'},
	    {slno: '10', notificationno: '10539/4WL-438/2015', notificationname: 'N20151205_CWLW_AirborneCompaignMahanadi', year: '2015', month: 'December'},
	];

  constructor() { }

  dtOptions: any = {};

  ngOnInit(): void {

  	this.dtOptions = {
      responsive: true,
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: 'Bfrtip',
        buttons: [
            //'copy', 'excel', 'pdf', 'print'
        ]
    };

  	(function($){
        
      	

    })(jQuery);

  }

}
