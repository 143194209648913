<app-header *ngIf="isDashboard"></app-header>
<app-menu  *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
			        <li class="nav-item">
			            <a id="tab-nandankananmap" href="#pane-nandankananmap" class="nav-link active" data-toggle="tab" role="tab">Map</a>
			        </li>
			        <li class="nav-item">
			            <a id="tab-nandankananinfo" href="#pane-nandankananinfo" class="nav-link" data-toggle="tab" role="tab">Information</a>
			        </li>
			    </ul>

			    <div id="accordion" class="tab-content" role="tablist">
			        <div id="pane-nandankananmap" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-nandankananmap">
			            <div class="card-header" role="tab" id="heading-nandankananmap">
			                <h5 class="mb-0">
			                    <a data-toggle="collapse" href="#collapse-nandankananmap" aria-expanded="true" aria-controls="collapse-nandankananmap">
			                        Map
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-nandankananmap" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-nandankananmap">
			                <div class="card-body">
								<div class="map" id="nandankananWLmap">
                                    <div class="gle-icon">
										<a href="javascript:void(0)">
                                            <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid" alt="Street View" title="Street View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()' alt="Satellite View" title="Satellite View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View" title="Topo View">
                                        </a>
                                    </div>
                                </div>


			                </div>
			            </div>
			        </div>

			        <div id="pane-nandankananinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-nandankananinfo">
			            <div class="card-header" role="tab" id="heading-nandankananinfo">
			                <h5 class="mb-0">
			                    <a class="collapsed" data-toggle="collapse" href="#collapse-nandankananinfo" aria-expanded="false" aria-controls="collapse-nandankananinfo">
			                       Information
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-nandankananinfo" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-nandankananinfo">
			                <div class="card-body">
								                <h4 class="card-title">
                                  Nandankanan Zoological Park
                                  <span class="custom-down">
                                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
							<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
								class="fa fa-file-pdf">&nbsp;</i>Map</button>
                                  </span>
                                </h4>
                                <div id="printcontent">
                                <div class="row">
                                	<div class="col-md-9">
                                		<div class="table-responsive">
                                    	<table class="table table-sm table-bordered">
                                    		<tbody>
                                    			<tr>
                                    				<td><strong>District : </strong></td>
                                    				<td>Khurda</td>
                                    			</tr>
                                          <tr>
                                            <td><strong>Geographical Extent : </strong></td>
                                            <td>Longitude 85° 48'8"E and 85° 49'55"E & latitude 20° 23'14"N and 20° 24'35"N</td>
                                          </tr>
                                          <tr>
                                            <td><strong>Climate : </strong></td>
                                            <td>Temperature- In summer: 40°C,In winter: 14°C</td>
                                          </tr>
                                    		</tbody>
                                    	</table>
                                    </div>
                                	</div>
                                  <div class="col-md-3">
                                		<img src="assets/images/wildlifeunits-nandankanan-01.jpg" class="img-fluid float-right img-inner" alt="image">
                                	</div>
                                </div>
                                <p>Nandankanan Zoological Park is a premier large zoo of India. Unlike other zoos in the country, Nandankanan is built right inside the forest and set in a completely natural environment. The animals are housed in enclosures with greenery with a feel of real forest and live with no fear or apprehension of becoming the hunter’s prey. Nandankanan is God’s garden where man, animal and plant live in harmony. Each has their own space as animals, birds, reptiles and plants live in complete freedom. Deer, sambar, hare, porcupines and peacocks roam freely in the sanctuary without any fear. Freedom prevails where there is no fear, where there is only love and affection.</p>
                                <h4 class="card-title">Flora </h4>
                                <p>The Zoo is also home to different varieties of plants, shrubs and trees. A botanical garden spreading over 75 Ha is situated in the sylvan settings of the moist deciduous forest in the Sanctuary. A diverse variety of plants, orchids, creepers, cacti, palms and 750 species of plants are seen here. The State Botanical Garden, for tree lovers, was established in the year 1963 for the conservation and study of trees, plants, shrubs and creepers. Many rare species of flowers, leaves and medicinal plants are on display here for visitors to see and identify.</p>
                                <h4 class="card-title">Fauna </h4>
                                <p>This premier zoo was the world’s first captive crocodile breeding centre, where gharials were bred in captivity in 1980. The same year, two normal tigers in Nandankanan gave birth to three white tiger cubs, the first such occurrence anywhere in the world. Since then, white tigers have been regularly bred here. Nandankanan has been internationally acclaimed for its highest collection of white tigers in the world. Besides, gharials and white tigers, leopards, pangolin, mouse deer, lions, ratel and vultures are also bred here. It is recognized as a leading zoo for the breeding of the Indian pangolin and white tiger. Many of the animals and birds are also exchanged with animals and birds from other zoos in the country and outside. It has the glory of having more than 3081 animals of 156 species, including 1198 mammals of 41 species, 1596 birds of 83 species, 266 reptiles of 26 species and 21 amphibians of 6 species.</p>
                                <h4 class="card-title">Set-Ups</h4>
                                <div class="table-responsive">
                                	<table class="table table-sm table-bordered">
                                		<tbody>
                                      <tr>
                                				<td><strong>Administrative set-up (Range, Section, beats) :</strong></td>
                                				<td>	Ranges- 0, Sections- 0,Beats- 0</td>
                                			</tr>
                                			<tr>
                                				<td><strong>EDC/JFM set-up:  </strong></td>
                                				<td>EDCs - 0, VSS - 0</td>
                                			</tr>
                                			<tr>
                                        <td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
                                        <td>No. of RF- 0, No. of PRF- 0, No. of DPF- 0, No. of UDPF- 0, No. of VF- 0</td>
                                      </tr>
                                		</tbody>
                                	</table>
                                </div>
                                <h4 class="card-title">Major Attraction</h4>
                                <div class="row">
                                  <div class="col-md-12">
                                    <img src="assets/images/wildlifeunits-nandankanan-02.jpg" class="img-fluid float-right img-inner" alt="image">
                                    <ul class="custom-list">
                                    	<li>The State Botanical Garden of Nandankanan is one of the pioneering plant conservation and nature education centre of the State,which provides a momorable experience to the green enthusiasts.</li>
                                    	<li>The Sanctuary offers trekking to reach to the Deodhara water fall</li>
                                      <li>Nandankanan is the first zoo in the World to breed White tiger and Melanistic tiger.</li>
                                      <li>Nandankanan is the only conservation breeding centre of Indian Pangolins in the world.</li>
                                      <li>Nandankanan is the only zoological park in India to become an institutional member of World Association of Zoos and Aquarium (WAZA).</li>
                                      <li>Nandankanan is the only zoo in India after which an express train Puri-New Delhi express has been named as “Nandankanan Express”.</li>
                                      <li>Nandankanan is the first zoo in India where endangered Ratel was born in captivity.</li>
                                      <li>Nandankanan is the only zoo in India to have an Open-top Leopard Enclosure.</li>
                                      <li>Nandankanan is the second largest heronry for Open Billied Storks in Odisha.</li>
                                      <li>Nandankanan has a museum where trophies of rare dead animal species are preserved, which draws researchers and other visitors.</li>
                                    </ul>
                                  </div>
                                </div>
                                <h4 class="card-title">Salient Detail</h4>
                                <div class="table-responsive">
                                	<table class="table table-sm table-bordered">
                                		<tbody>
                                      <tr>
                                				<td width="15%"><strong>HQ Address :</strong></td>
                                				<td width="85%" colspan="2">Office of the Director,Nandankanan Biological Park, Mayur Bhawan, Saheednagar,Bhubaneswar</td>
                                			</tr>
                                			<tr>
                                				<td><strong>Contact officer : </strong></td>
                                				<td>
                                					<span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>1. Director<br>
                                					<span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Nandankanan Biological Park, Mayur Bhawan, Saheed Nagar Bhubaneswar-751007, (Odisha), India<br>
                                					<span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>+91-674-2547850<br>
                                					<span class="address-inner"><i class="fa fa-fax">&nbsp;</i></span>+91-674-2547840<br>
                                        </td>
                                        <td>
                                					<span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>2. Deputy Director<br>
                                					<span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Nandankanan Zoo, Dist- Khurda<br>
                                					<span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>+91-674-2466075<br>
                                				</td>
                                			</tr>
                                			<tr>
                                				<td><strong>Key activities : </strong></td>
                                				<td colspan="2">In situ and ex situ wildlife conservation,peripheral vaccination for cattle,tranquilization,capture and translocation of problematic wildlife,rescue and rehabilitation,conservation breeding and nature education.</td>
                                			</tr>
                                			<tr>
                                				<td><strong>Programmes : </strong></td>
                                				<td colspan="2">Conservation and protection of wildlife,Siminar/Workshope/Training Programmer for field staff on tranquilization and wildlife management,wetland education and management,conservation awareness programme/zoo education programme.</td>
                                      </tr>
                                      <tr>
                                				<td><strong>Places to see : </strong></td>
                                				<td colspan="2">Nandankanan Zoological Park, State Botanical Garden and Kanjia lake.</td>
                                			</tr>
                                		</tbody>
                                	</table>
                                </div>
                                <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>
                              </div>
			                </div>
			            </div>
			        </div>

			    </div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>
