import { Component, OnInit } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.css']
})
export class PiechartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  	(function($){
      	
  		google.charts.load("current", {packages:["corechart"]});
	      google.charts.setOnLoadCallback(drawChart);
	      function drawChart() {
	        var data = google.visualization.arrayToDataTable([
	          ['Task', 'Hours per Day'],
	          ['New',     8],
	          ['Pending',      6],
	          ['Resolved',    2]
	        ]);

	        var options = {
	          title: 'No. of Incidents',
	          is3D: true,
	        };

	        var chart = new google.visualization.PieChart(document.getElementById('PieChart'));
	        chart.draw(data, options);
	      }

    })(jQuery);
  }

}
