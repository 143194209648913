
  <!-- Modal -->
<div class="modal fade homepopup" id="initialPopup" tabindex="-1" aria-labelledby="initialPopupLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
 
        <div class="background-image">
            <button type="button" class="close btn-close-pop" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
          <a routerLink="/event"  class="btn btn-dark btn-cts" >Know More</a>
        </div>
  </div>
</div> 
</div>


<!-- <div class="modal fade  homepopup" id="initialPopup" tabindex="-1" aria-labelledby="initialPopupLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content shadow-lg border-0">
    
      <div class="modal-header bg-primary text-white">
        <h5 class="modal-title fw-bold text-white" id="initialPopupLabel" >🐢 Protect Olive Ridley Turtles & Dolphins! 🐬</h5>
        <button type="button" class="close btn-close-pop" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <div class="background-image" style="margin-bottom: 10px;"> 
        </div>
         <a routerLink="/event" class="btn btn-lg btn-success fw-bold px-4 py-2 shadow">Join the Movement 🚀</a>
      </div>
    </div>
  </div>
</div>  -->
