import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from '../service/api.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const baseUrlFile = environment.FILE_API_URL;
declare var jQuery: any;

@Component({
  selector: 'app-innews',
  templateUrl: './innews.component.html',
  styleUrls: ['./innews.component.css']
})
export class InnewsComponent implements OnInit {
  //public loader: boolean = false;
  NewsList: any;

	// public data = [
	//     {slno: '1', newsno: '284/1WL(BT)-ET-13/2019', date: '12-01-2021', newstitle: 'Request for proposal for supply and Erection of Eco-Friendly Luxury Tents at Six Ecotourism Destinat'},
	//     {slno: '2', newsno: '1', date: '12-01-2021', newstitle: 'Request for Proposal for Supply and Election of Eco-Friendly Luxury Tents at Six Ecotourism Destinat'},
	//     {slno: '3', newsno: '1', date: '17-11-2020 	', newstitle: 'Auction Sale of Cashew Nut of Mangrove Forest Division (Wildlife) Rajnagar.'},
	//     {slno: '4', newsno: '1', date: '07-09-2020 	', newstitle: 'Participate in On-line Quiz Competition held on 25th September,2020'},
	//     {slno: '5', newsno: '1', date: '24-08-2020', newstitle: '"Wildlife Photography Competition-2020"'},
	//     {slno: '6', newsno: '1', date: '18-08-2020', newstitle: 'Quotation call notice for printing of books for“Wildlife Odisha -2020”'},
	//     {slno: '7', newsno: '1', date: '09-07-2020', newstitle: 'Nomination for the Biju Patnaik Award for Wildlife Conservation at Sub-divisional level 2020'},
	//     {slno: '8', newsno: '2', date: '09-07-2020', newstitle: 'Nomination for the state level Biju Patnaik Award for Wildlife Conservation -2020'},
	//     {slno: '9', newsno: '2202/02.03.2020', date: '02-03-2020', newstitle: 'Quotation for Designing & Printing of Coffee Table Book on Odisha Ecotourism'},
	//     {slno: '10', newsno: 'N0.39-MM', date: '20-07-2019', newstitle: 'WILDLIFE PHOTOGRAPHY COMPETITION 2019'},
	// ];

  constructor(private apiService: ApiService) { }

  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {};

  ngOnInit(): void {
  	this.dtOptions = {
      responsive: true,
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: 'Bfrtip',
        buttons: [
            //'copy', 'excel', 'pdf', 'print'
        ]
    };
  	(function($){
    })(jQuery);
    this.loadnews();
  }
  loadnews(){
    try{
      //this.loader = true;
      this.apiService.request('GET', 'api/v1/cms/getNews').subscribe((data: any) => {
        if (data) {
          data.sort((a, b) => {
            return <any>new Date(b.updatedOn) - <any>new Date(a.updatedOn);
          });
          this.NewsList=data;
          this.dtTrigger.next();
          //this.loader = false;
        }
      },err=>{
        //this.loader = false;
      });
    }
    catch(e){
      //this.loader = false;
    }
  }

}
