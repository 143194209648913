import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpResponse, HttpEventType } from '@angular/common/http';
import {Router} from "@angular/router";
import {ApiService} from "../service/api.service";
import { AuthService } from "../service/auth.service";
import { UploadService} from '../upload/upload.service';
import { environment } from 'src/environments/environment.prod';
import { NotificationService } from '../service/notification.service'

const baseUrl = environment.API_URL;
const baseUrlFile = environment.FILE_API_URL;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  //public loader: boolean=false;
  public imagePath;
  imgURL: any;
  public message: string;
  profileData: any;
  username: any;
  selectedFiles: FileList
  progress: { percentage: number } = { percentage: 0 }
  userEmailcheck: Object;
  userId: any;

  constructor(private formBuilder: FormBuilder,private notifyService:NotificationService, private router: Router,private uploadService: UploadService, private apiService: ApiService,private authService: AuthService) { }

  ngOnInit(): void {
    this.profileForm = this.formBuilder.group({
      firstName: [''],
      middleName: [''],
      lastName: [''],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      userPhoneNumber: [''],
      userAddress: [''],
       imgPath: [''],
      juridicitionName: [''],
      userDesignation: [''],
      userName:[''],
      userId:[''],
      active:['']
    });
    this.loadProfile();
    this.imgURL ="assets/images/profile.png";
  }
  emailCheckUnique(emailid:any) {
    //this.loader=true;
    var email = emailid.replace(/\s/g, "");
     return this.apiService.request('GET', 'api/v1/auth/checkEmailAvail?email='+email).subscribe(res => {
       this.userEmailcheck = res;
       if (this.userEmailcheck==true) {
         //this.emailAlredyExist = "Email Already Exist";
         this.notifyService.showError("Email ID Already Exist!!!","Error");
         this.profileForm.controls.email.setValue("");
         //this.loader=false;
       }
       else{
         if(this.profileForm.controls.email.valid){
           this.notifyService.showSuccess("Email ID Available","Success");
         }
         //this.loader=false;
       }
     });
   }
  preview(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }
  loadProfile(){
    //console.log(baseUrlFile)
    const userdata=sessionStorage.getItem('user');
    if(userdata!=null){
      const user=JSON.parse(userdata);
      this.username=user.username;
      this.userId=user.userid;
      return this.apiService.request('GET', 'api/v1/auth/getuserById?loginId='+this.userId).subscribe((data: any) => {
        if (data) {
          this.profileData = data;
          //this.getImage(data.imgPath);
          if(data.imgPath!=null){

            this.imgURL= baseUrlFile+'profile/'+data.imgPath;

          }else{
            this.imgURL='assets/images/profile.png';
          }
         // //console.log(this.imgURL)
           this.profileForm.setValue(data);
         // //console.log(this.profileData)

        }
      }, err => {
        this.imgURL='assets/images/profile.png';
      });
    }else{
      //this.loader=false;//set loader  for invisible
      this.router.navigate(['']);
      return;

    }

  }
//   getImage(data) {

//     this.uploadService.getFiles('api/v1/uplaodController/downloadFile?location='+data).subscribe
//    (
//      res =>
//        {
//          //console.log(res)
//         // this.retrieveResonse = res;
//         // this.base64Data = this.retrieveResonse.picByte;
//           //this.retrievedImage = 'data:image/jpeg;base64,' + this.base64Data;
//           //this.imgURL=res;
//        //this.profileImg = baseUrl+'api/v1/uploadController/downloadFile?location='+data.imgPath;
//        if(res!=null){
//         this.imgURL= res;
//       }else{
//         this.imgURL='assets/images/profile.png';
//       }
//        }, err => {
//          this.imgURL='assets/images/profile.png';
//        },
//   );
// }
  updateProfile()
  {
    try{
       if(this.userId!=null){
              ////this.loader=true;//set loader  for invisible
              this.progress.percentage = 0;
              var signupReqModel={
                      'firstName': this.profileForm.value.firstName,
                      'lastName': this.profileForm.value.lastName,
                      'middleName': this.profileForm.value.middleName,
                      'email':this.profileForm.value.email,
                      'userPhoneNumber':this.profileForm.value.userPhoneNumber,
                      'userAddress':this.profileForm.value.userAddress,
                      'username':this.profileForm.value.userName,
                      'userid':this.userId
              };

              let formdata: FormData = new FormData();
              formdata.append('model', JSON.stringify(signupReqModel));
              if(this.selectedFiles!=undefined){
              formdata.append('file', this.selectedFiles.item(0));
              }else{
                formdata.append('file', '');
              }
               this.apiService.request('PUT', 'api/v1/auth/updateuser',formdata).subscribe((response: any) => {
                  var res = JSON.parse(response);
                  if(res.message=="success"){
                      //this.ngOnInit();
                      this.loadProfile();
                      //window.location.reload();
                      //this.loader=false;//set loader  for invisible
                      this.notifyService.showSuccess("Profile Update Successfull","Success");
                    }else{
                      this.notifyService.showError("Unsuccessfull","Error");
                    }
                });
              //this.uploadService.pushFileToStorage(this.currentFileUpload,this.username,"profile").subscribe(data => {
              //  console.log(data)
                // if (event.type === HttpEventType.UploadProgress) {
                //   this.progress.percentage = Math.round(100 * event.loaded / event.total);
                // } else if (event instanceof HttpResponse) {
                //   //console.log('File is completely uploaded!');
                // }

             // })
              this.selectedFiles = undefined;
          // else{
          //     //this.loader=true;
          //       console.log(this.profileForm.value)
          //           return this.apiService.request('PUT', 'api/v1/auth/updateuser',
          //             {
          //               firstName: this.profileForm.value.firstName,
          //               lastName: this.profileForm.value.lastName,
          //               middleName: this.profileForm.value.middleName,
          //               email:this.profileForm.value.email,
          //               userPhoneNumber:this.profileForm.value.userPhoneNumber,
          //               userAddress:this.profileForm.value.userAddress,
          //               username:this.profileForm.value.userName,
          //               userid:this.userId
          //             }).subscribe((response: any) => {
          //               if(response){
          //                 console.log(response);
          //                 //alert("Update successfull");
          //               // window.location.reload();
          //                 this.ngOnInit();
          //                 //this.loader=false;//set loader  for invisible
          //                 this.notifyService.showSuccess("Profile Update Successfull","Success");
          //               }
          //           });
          //   }
        }else{
          //this.loader=false;
          this.router.navigate(['']);
          return;
        }
      }catch(error)
      {
        //console.log("Error Occured"+error.error);
        //this.loader=false;//set loader  for invisible
        this.notifyService.showError(error.error,"Error");
     }
  }
  selectFile(event) {
    if (event.length === 0)
    return;
    const file = event.target.files.item(0)
      //console.log(file)
      //console.log(event)
    if (file.type.match('image.*')) {
      this.selectedFiles = event.target.files;
    } else {
      alert('invalid format!');
    }

    // var mimeType = event[0].type;
    // if (mimeType.match(/image\/*/) == null) {
    //   this.message = "Only images are supported.";
    //   return;
    // }

    // var reader = new FileReader();
    // this.imagePath = event;
    // reader.readAsDataURL(event[0]);
    // reader.onload = (_event) => {
    //   this.imgURL = reader.result;
    // }
  }

  // upload() {
  //   this.progress.percentage = 0;

  //   this.currentFileUpload = this.selectedFiles.item(0)
  //   let done=this.uploadService.pushFileToStorage(this.currentFileUpload,this.username).subscribe(event => {
  //     if (event.type === HttpEventType.UploadProgress) {
  //       this.progress.percentage = Math.round(100 * event.loaded / event.total);
  //     } else if (event instanceof HttpResponse) {
  //       //console.log('File is completely uploaded!');
  //     }
  //   })
  //   //console.log(done)

  //   this.selectedFiles = undefined;
  // }

}
