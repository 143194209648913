<app-header *ngIf="isDashboard"></app-header>
<app-menu  *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
			        <li class="nav-item">
			            <a id="tab-purimap" href="#pane-purimap" class="nav-link active" data-toggle="tab" role="tab">Map</a>
			        </li>
			        <li class="nav-item">
			            <a id="tab-puriinfo" href="#pane-puriinfo" class="nav-link" data-toggle="tab" role="tab">Information</a>
			        </li>
			    </ul>

			    <div id="accordion" class="tab-content" role="tablist">
			        <div id="pane-purimap" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-purimap">
			            <div class="card-header" role="tab" id="heading-purimap">
			                <h5 class="mb-0">
			                    <a data-toggle="collapse" href="#collapse-purimap" aria-expanded="true" aria-controls="collapse-purimap">
			                        Map
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-purimap" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-purimap">
			                <div class="card-body">
								<div class="map" id="puriWLmap">
									<div class="gle-icon">
										<a href="javascript:void(0)">
                                            <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid" alt="Street View" title="Street View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()' alt="Satellite View" title="Satellite View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View" title="Topo View">
                                        </a>
                                    </div>
								</div>


			                </div>
			            </div>
			        </div>

			        <div id="pane-puriinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-puriinfo">
			            <div class="card-header" role="tab" id="heading-puriinfo">
			                <h5 class="mb-0">
			                    <a class="collapsed" data-toggle="collapse" href="#collapse-puriinfo" aria-expanded="false" aria-controls="collapse-B">
			                       Information
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-puriinfo" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-puriinfo">
			                <div class="card-body">
								<h4 class="card-title">
									Puri Wildlife Division
									<span class="custom-down">
                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
					<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
						class="fa fa-file-pdf">&nbsp;</i>Map</button>
			                		</span>
								</h4>
                <div id="printcontent">
								<div class="row">
									<div class="col-md-9">
										<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td><strong>District : </strong></td>
													<td>Puri</td>
												</tr>
										  <tr>
											<td><strong>Geographical Extent : </strong></td>
											<td>Longitude 85° 30'E and 86° 22'E & Latitude 20° 9'N and 19° 38'N</td>
										  </tr>
										  <tr>
											<td><strong>Climate : </strong></td>
											<td>Coastal climate with highly humid</td>
										  </tr>
											</tbody>
										</table>
									</div>
									</div>
									<div class="col-md-3">
										<img src="assets/images/wildlifeunits-puri-01.jpg" class="img-fluid float-right img-inner" alt="image">
									</div>
								</div>
								<p>After reorganization of the Odisha Forest Department on 1st October, 2003, portions of the erstwhile Puri Division HQ at Khurdha has been carved out to form Puri (Wildlife) Division with headquarters at Puri . Puri Wildlife Division lies between 19° 41’ 09” and 20° 07’38” North latitude and between 85° 30’ 4.7” and 86° 22’ 29” East longitude . The area covered in Puri (WL) Division spreads over the part of Revenue Districts of Puri i.e. Puri Sadar, Satyabadi, Nimapada, Gop, Kakatpur, Astaranga, Brahmagiri blocks.</p>
								<h4 class="card-title">Flora </h4>
								<p>Most of the forests in the Division are located along the coastal line and the forest are capable of supporting Casuarina with associate species like Neem, Cashew, Acacia and Eucalyptus sps. The forests in the Division can be classified as 5B/ DS1- Dry Deciduous Scrub Forests as per the Champian and Seth’s classification. However, Mangrove species like Bani, Keruan, Rai, Guan and Harkanch are seen growing well in the Devi – Kadua estuary area. The forest area i.e. Tavagolora PRF under Astaranga Wildlife Range Golara PRF of Konark Wildlife range and Pattnaikia PRF under Balukhanda Range have natural forest growth of miscellaneous species like Bamboo, Karanja, Phasi, Neem, Achu, Giringa etc. Acacia, Eucalyptus, Jhaun etc. are mostly planted during previous years. All the PRFs are present along the coast line are full of coastal plantations of Casuarina with few neem, cashew and Acacia etc. In addition to the above tree species, some other species of shrub, herb and climbers are also seen in the forest areas.</p>
								<h4 class="card-title">Fauna </h4>
								<p>The rich varied Fauna of this division are due to existence of mangrove forest, casuarina and Cashew plantations along the coast of Bay of Bengal and different type of natural habitat in the coastal plane. Presence of considerable number of spotted deer, hyena, jungle cat, fishing cat, water monitor lizards, snakes are noticed in the sanctuary and all the PRFs along sea coast. Olive Ridley sea Turtle nest on the sea beach near Devi river mouth and along the coast of Puri district during January to March every year. Substantial population of small animals like monkeys is seen all over the Division, i.e. both in the forest as well as rural / urban areas and monkey depredation has become a cause of concern for the Division.</p>
								<h4 class="card-title">Set-Ups</h4>
								<div class="table-responsive">
									<table class="table table-sm table-bordered">
										<tbody>
									  <tr>
												<td><strong>Administrative set-up (Range, Section, beats) :</strong></td>
												<td>Ranges- 5, Sections- 15,Beats- 46</td>
											</tr>
											<tr>
												<td><strong>EDC/JFM set-up:  </strong></td>
												<td>
										  EDCs - 29, VSS - 38
												</td>
											</tr>
											<tr>
										<td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
										<td>	No. of RF- 1, No. of PRF- 19, No. of DPF- 0, No. of UDPF- 0, No. of VF- 0</td>
									  </tr>
										</tbody>
									</table>
								</div>
								<h4 class="card-title">Major Attraction</h4>
								<div class="row">
								  <div class="col-md-12">
									<img src="assets/images/wildlifeunits-puri-02.jpg" class="img-fluid float-right img-inner" alt="image">
									<ul class="custom-list">
										<li>The archeological heritage site of Sun Temple in Konark and along the marine drive road from Puri to Konark which forms part of golden triangle the major tourism circuit of Odisha.</li>
										<li>The temple of Lord Jagannath and Ratha Yatra.</li>
									  <li>Ramchandi Temple on Marine Drive Road.</li>
									  <li>Mangala Temple at Kakatapur.</li>
									  <li>Golden Sea Beach at Chandrabhaga and Puri is another attraction which is on the shore of Bay of Bengal.It remains crowded throughout the year as it is the most preferred spot for vacations in Odisha</li>
									  <li>Marine Drive Road from Puri to Konark.</li>
									  <li>Lush green coastal forest along the coast of Bay of Bengal.</li>
									</ul>
								  </div>
								</div>
								<h4 class="card-title">Salient Detail</h4>
								<div class="table-responsive">
									<table class="table table-sm table-bordered">
										<tbody>
									  <tr>
												<td width="15%"><strong>HQ Address :</strong></td>
												<td width="85%">AT/PO- Chakratirtha Road, Dist:- Puri,Odisha PIN NO. 752002.</td>
											</tr>
											<tr>
												<td><strong>Contact officer : </strong></td>
												<td>
													<span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest Officer<br>
													<span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Puri Wildlife Division<br>
													<span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06752-228281<br>
										  <span class="address-inner"><i class="fa fa-fax">&nbsp;</i></span>06752-228281<br>
										  <span class="address-inner"><i class="fa fa-envelope">&nbsp;</i></span>dfopuri@gmail.com<br>
												</td>
											</tr>
											<tr>
												<td><strong>Key activities : </strong></td>
												<td>Protection of forest and wildlife with deployment of protection squad and foot patrolling by the staff, Protection of forest from forest fire, Conservation and protection of Olive Ridley Sea Turtles, Enforcement of Wildlife(P) Act’1972, Odisha Forest Act’1972, and other Act & Rules.</td>
											</tr>
											<tr>
												<td><strong>Programmes : </strong></td>
												<td>Management of Balukhanda-Konark Wildlife Sanctuary, Conservation and Management of mangroves in Devi-Kadua estuary,Development & Management of Eco-Tourism .</td>
									  </tr>
									  <tr>
												<td><strong>Places to see : </strong></td>
												<td>Jagannath Temple , Sun Temple , Ramchandi Temple, Mangala Temple, Nature Camp Nuania, Golden Sea Beach at Chandrabhaga and Puri,</td>
											</tr>
										</tbody>
									</table>
								</div>
								<p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>
              </div>
			                </div>
			            </div>
			        </div>

			    </div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>
