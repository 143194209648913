import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ApiService } from '../service/api.service';
import { NotificationService } from '../service/notification.service';
declare var jQuery: any;

@Component({
  selector: 'app-loginreportaudit',
  templateUrl: './loginreportaudit.component.html',
  styleUrls: ['./loginreportaudit.component.css']
})
export class LoginreportauditComponent implements OnInit {

  circleList: any;
  divisionlist: any;
  public circleSelected='All';
  public divisionSelected='All';

  public circleid: string;
  public divid: string;

  loginqueryForm : FormGroup;
  @ViewChild('sd') sdate : ElementRef;
  @ViewChild('ed') edate : ElementRef;

  dtTrigger1: Subject<any> = new Subject<any>();
  dtTrigger2: Subject<any> = new Subject<any>();
  dtOptions: any = {};


  public startDateSelected="";
  public endDateSelected="";

  tabledata1: any[];
  tabledata2: any[];
  constructor(private formBuilder: FormBuilder,private router: Router,private notifyService: NotificationService,private apiService: ApiService) { }

  ngOnInit(): void {
    this.circleid="0";
    this.divid="0";
    this.startDateSelected="";
    this.endDateSelected="";

    this.loginqueryForm = this.formBuilder.group({
      circle:[''],
      division:[''],
      //startDate:[''],
     // endDate:['']
    });
    this.dtOptions = {
      dom: 'Bfrtip',
         buttons: [
             'copy', 'excel', 'pdf', 'print'
         ]
    };
    this.getAllCircle();
    this.getLoginAuditReport();
    var ds = this ;
    (function($){
      // ------------ Datepicker -----------
      var day1before = new Date();
      $(function () {
        $("#datepicker2").datepicker({
          autoclose: true,
          todayHighlight: true
        }).datepicker('update', new Date());
        $("#datepicker1").datepicker({
          autoclose: true,
          todayHighlight: true
       }).datepicker('update', new Date(day1before.setDate(day1before.getDate() - 7)));
      });
      // ----------- Tooltip ------------
      $('[data-toggle="tooltip"]').tooltip({
        'placement': 'top'
      });
      $('[data-toggle="popover"]').popover({
        trigger: 'hover',
        'placement': 'top'
      });

    })(jQuery);
  }
 getAllCircle() {
    this.apiService.request('GET', 'api/v1/masters/getAllCircle').subscribe((data: any) => {
     if(data)
     {
       this.circleList=data;
     }
   });
 }
 getAllDivisionByCircleId(circle:any){
   if(circle!=0){
     this.apiService.request('GET', 'api/v1/masters/getAllDivisionByCircleId?id='+circle).subscribe((data: any) => {
     if(data)
     {
       this.divisionlist=data;
     }
   });
 }
 }
formatDate(date) {
    let dateexct=date.split('-');
    var day = dateexct [0];
    var month = dateexct [1];
    var year = dateexct [2];
    var fullDay=year+'-'+month+'-'+day;
    return fullDay;
 }
 getCurentDate(){
  var Yestrerdaywithtime;
  var Todaywithtime;
  var mydate = new Date();
  var yester = new Date();
  yester = new Date(yester.setDate(yester.getDate() - 7));
  if (mydate.getMonth() < 9){
    Todaywithtime = mydate.getFullYear() + "-0" + (mydate.getMonth() + 1) + "-" + mydate.getDate() ;
  }else{
    Todaywithtime = mydate.getFullYear() + "-" + (mydate.getMonth() + 1) + "-" + mydate.getDate() ;
  }if(yester.getMonth() < 9){
    Yestrerdaywithtime = yester.getFullYear() + "-0" + (yester.getMonth() + 1) + "-" + yester.getDate() ;
  }else{
    Yestrerdaywithtime = yester.getFullYear() + "-" + (yester.getMonth() + 1) + "-" + yester.getDate() ;
  }
 if(this.startDateSelected==""){this.startDateSelected=Yestrerdaywithtime;}
 if(this.endDateSelected==""){this.endDateSelected=Todaywithtime;}
 }

backtoauditpage(){
  this.router.navigate(['auditreport']);
}
getLoginAuditReport(){
  try{
      //this.getCurentDate()
      this.apiService.request('GET', 'api/v1/audit/getLoginAuditReport?circle='+this.circleSelected+'&division='+this.divisionSelected).subscribe((data: any) => {
        if(data.length>0){
         $("#audittable").DataTable().destroy();
         this.tabledata1=data;
         this.dtTrigger1.next();
       }
     });
   }catch{
  }finally{
  }
}
onSubmit(value:any){
  try{
        // let startDate = this.sdate.nativeElement.value;
        // let endDate = this.edate.nativeElement.value;
        // if(startDate!=''){
        //   startDate=this.formatDate(startDate);
        //   this.startDateSelected=startDate;
        // }else if(startDate==''){
        //   alert("Please Provide Start date")
        //   return false;
        // }
        // if(endDate!=''){
        //   endDate=this.formatDate(endDate);
        //   this.endDateSelected=endDate
        // }else if(endDate==''){
        //   alert("Please Provide End date")
        //   return false;
        // }
        // if(startDate>endDate){
        //   alert("Start date should be greater then End date.")
        //   return false;
        // }
        if(value.circle=="0"){
          this.circleSelected="All";
        }else{
          this.circleSelected=value.circle;
        }if(value.division=="0"){
          this.divisionSelected="All";
        }else{
          this.divisionSelected=value.division;
        }
        this.getLoginAuditReport();
     }catch{ 
     }finally{
     }
  }
}
