import { ModuleWithProviders,NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedhomeModule } from '../sharedhome/sharedhome.module';
import { ProtectedareasRoutingModule } from './protectedareas-routing.module';
import { ProtectedareasBadramaComponent } from './protectedareas-badrama/protectedareas-badrama.component';
import { ProtectedareasBaisipalliComponent } from './protectedareas-baisipalli/protectedareas-baisipalli.component';
import { ProtectedareasBalukhandKonarkComponent } from './protectedareas-balukhand-konark/protectedareas-balukhand-konark.component';
import { ProtectedareasBhitarakanikaComponent } from './protectedareas-bhitarakanika/protectedareas-bhitarakanika.component';
import { ProtectedareasChandakaDampadaComponent } from './protectedareas-chandaka-dampada/protectedareas-chandaka-dampada.component';
import { ProtectedareasChilikaNalabanComponent } from './protectedareas-chilika-nalaban/protectedareas-chilika-nalaban.component';
import { ProtectedareasDebrigarhComponent } from './protectedareas-debrigarh/protectedareas-debrigarh.component';
import { ProtectedareasGahirmathaMarineComponent } from './protectedareas-gahirmatha-marine/protectedareas-gahirmatha-marine.component';
import { ProtectedareasHadgarhComponent } from './protectedareas-hadgarh/protectedareas-hadgarh.component';
import { ProtectedareasKarlapatComponent } from './protectedareas-karlapat/protectedareas-karlapat.component';
import { ProtectedareasKapilashComponent } from './protectedareas-kapilash/protectedareas-kapilash.component';
import { ProtectedareasKhalasuniComponent } from './protectedareas-khalasuni/protectedareas-khalasuni.component';
import { ProtectedareasKuldihaComponent } from './protectedareas-kuldiha/protectedareas-kuldiha.component';
import { ProtectedareasKotgarhComponent } from './protectedareas-kotgarh/protectedareas-kotgarh.component';
import { ProtectedareasLahkeriValleyComponent } from './protectedareas-lahkeri-valley/protectedareas-lahkeri-valley.component';
import { ProtectedareasNandankananComponent } from './protectedareas-nandankanan/protectedareas-nandankanan.component';
import { ProtectedareasSatkosiaGorgeComponent } from './protectedareas-satkosia-gorge/protectedareas-satkosia-gorge.component';
import { ProtectedareasSimilipalComponent } from './protectedareas-similipal/protectedareas-similipal.component';
import { ProtectedareasSunabedaComponent } from './protectedareas-sunabeda/protectedareas-sunabeda.component';


@NgModule({
  declarations: [
    ProtectedareasBadramaComponent,
    ProtectedareasBaisipalliComponent,
    ProtectedareasBalukhandKonarkComponent,
    ProtectedareasBhitarakanikaComponent,
    ProtectedareasChandakaDampadaComponent,
    ProtectedareasChilikaNalabanComponent,
    ProtectedareasDebrigarhComponent,
    ProtectedareasGahirmathaMarineComponent,
    ProtectedareasHadgarhComponent,
    ProtectedareasKarlapatComponent,
    ProtectedareasKapilashComponent,
    ProtectedareasKhalasuniComponent,
    ProtectedareasKuldihaComponent,
    ProtectedareasKotgarhComponent,
    ProtectedareasLahkeriValleyComponent,
    ProtectedareasNandankananComponent,
    ProtectedareasSatkosiaGorgeComponent,
    ProtectedareasSimilipalComponent,
    ProtectedareasSunabedaComponent
  ],
  imports: [
    CommonModule,
    SharedhomeModule,
    ProtectedareasRoutingModule
  ],exports: [
    ProtectedareasBadramaComponent,
    ProtectedareasBaisipalliComponent,
    ProtectedareasBalukhandKonarkComponent,
    ProtectedareasBhitarakanikaComponent,
    ProtectedareasChandakaDampadaComponent,
    ProtectedareasChilikaNalabanComponent,
    ProtectedareasDebrigarhComponent,
    ProtectedareasGahirmathaMarineComponent,
    ProtectedareasHadgarhComponent,
    ProtectedareasKarlapatComponent,
    ProtectedareasKapilashComponent,
    ProtectedareasKhalasuniComponent,
    ProtectedareasKuldihaComponent,
    ProtectedareasKotgarhComponent,
    ProtectedareasLahkeriValleyComponent,
    ProtectedareasNandankananComponent,
    ProtectedareasSatkosiaGorgeComponent,
    ProtectedareasSimilipalComponent,
    ProtectedareasSunabedaComponent
  ],providers: []
})
export class ProtectedareasModule {static forRoot(): any[] | import("@angular/core").Type<any> | ModuleWithProviders<{}> {
  return {
    ngModule: ProtectedareasModule,
    providers: []
  };
} }
