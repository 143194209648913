
<div class="modal-header">
  <h4 class="modal-title">You Have Been Idle!!</h4>
  <button type="button" class="btn-close btn btn-sm btn-danger" aria-label="Close" (click)="activeModal.dismiss('Cross click')">&times;</button>
</div>
<div class="modal-body">
  <p>You will time out in {{info}}  seconds!</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-close" (click)="activeModal.close('Close click')">Close</button>
</div>
