import { Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	currentYear: number = new Date().getFullYear();
  constructor() { }

  ngOnInit(): void {
  	(function($){
        $(document).ready(function () {

		    $(window).scroll(function () {
		        if ($(this).scrollTop() > 100) {
		            $('.scrollup').fadeIn();
		        } else {
		            $('.scrollup').fadeOut();
		        }
		    });

		    $('.scrollup').click(function () {
		        $("html, body").animate({
		            scrollTop: 0
		        }, 600);
		        return false;
		    });

	    });
    })(jQuery);
  }

}
