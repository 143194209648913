import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ApiService } from '../service/api.service';
import { NotificationService } from '../service/notification.service';
declare var jQuery: any;

@Component({
  selector: 'app-smsaudit',
  templateUrl: './smsaudit.component.html',
  styleUrls: ['./smsaudit.component.css']
})
export class SmsauditComponent implements OnInit {
 // public loader: boolean=false;

  circleList: any;
  divisionlist: any;
  public circleSelected='All';
  public divisionSelected='All';

  public circleid: string;
  public divid: string;

  smsqueryForm : FormGroup;
  @ViewChild('sd') sdate : ElementRef;
  @ViewChild('ed') edate : ElementRef;

  dtTrigger1: Subject<any> = new Subject<any>();
  dtTrigger2: Subject<any> = new Subject<any>();
  dtOptions: any = {};


  public startDateSelected="";
  public endDateSelected="";

  tabledata1: any[];
  tabledata2: any[];
  constructor(private formBuilder: FormBuilder,private router: Router,private notifyService: NotificationService,private apiService: ApiService) { }

  ngOnInit(): void {
    this.circleid="0";
    this.divid="0";
    this.startDateSelected="";
    this.endDateSelected="";

    this.smsqueryForm = this.formBuilder.group({
      circle:[''],
      division:[''],
      startDate:[''],
      endDate:['']
    });
    this.dtOptions = {
      // pagingType: 'full_numbers',
      // pageLength: 5,
      // lengthMenu : [5, 10, 25],
      // processing: true,
      // responsive: true,
      dom: 'Bfrtip',
         buttons: [
             'copy', 'excel', 'pdf', 'print'
         ]
    };
    this.getAllCircle();
    this.getSMSAuditReport();
    var ds = this ;
    (function($){
      // ------------ Datepicker -----------
      var day1before = new Date();
      $(function () {
        $("#datepicker2").datepicker({
          autoclose: true,
          todayHighlight: true
        }).datepicker('update', new Date());
        $("#datepicker1").datepicker({
          autoclose: true,
          todayHighlight: true
       }).datepicker('update', new Date(day1before.setDate(day1before.getDate() - 7)));
      });
      // ----------- Tooltip ------------
      $('[data-toggle="tooltip"]').tooltip({
        'placement': 'top'
      });
      $('[data-toggle="popover"]').popover({
        trigger: 'hover',
        'placement': 'top'
      });

    })(jQuery);



  }
  getAllCircle() {
   // this.loader=true;
    this.apiService.request('GET', 'api/v1/masters/getAllCircle').subscribe((data: any) => {
     if(data)
     {
       this.circleList=data;
      // this.loader=false;
     }
   });
 }
 getAllDivisionByCircleId(circle:any){
   if(circle!=0){
    // this.loader=true;
     this.apiService.request('GET', 'api/v1/masters/getAllDivisionByCircleId?id='+circle).subscribe((data: any) => {
     if(data)
     {
       this.divisionlist=data;
     //  this.loader=false;
     }
   });
 }
 }
loadtable(value:any){

  }
formatDate(date) {
    let dateexct=date.split('-');
    var day = dateexct [0];
    var month = dateexct [1];
    var year = dateexct [2];
    var fullDay=year+'-'+month+'-'+day;
    return fullDay;
 }
 getCurentDate(){
  var Yestrerdaywithtime;
  var Todaywithtime;
  var mydate = new Date();
  var yester = new Date();
  yester = new Date(yester.setDate(yester.getDate() - 7));
  if (mydate.getMonth() < 9){
    Todaywithtime = mydate.getFullYear() + "-0" + (mydate.getMonth() + 1) + "-" + mydate.getDate() ;
  }else{
    Todaywithtime = mydate.getFullYear() + "-" + (mydate.getMonth() + 1) + "-" + mydate.getDate() ;
  }if(yester.getMonth() < 9){
    Yestrerdaywithtime = yester.getFullYear() + "-0" + (yester.getMonth() + 1) + "-" + yester.getDate() ;
  }else{
    Yestrerdaywithtime = yester.getFullYear() + "-" + (yester.getMonth() + 1) + "-" + yester.getDate() ;
  }
 if(this.startDateSelected==""){this.startDateSelected=Yestrerdaywithtime;}
 if(this.endDateSelected==""){this.endDateSelected=Todaywithtime;}
 }

backtoauditpage(){
  this.router.navigate(['auditreport']);
}
getSMSAuditReport(){
  try{
      this.getCurentDate()
    //  this.loader=true;
      this.apiService.request('GET', 'api/v1/audit/getSMSAuditReport?circle='+this.circleSelected+'&division='+this.divisionSelected+'&startDate='+this.startDateSelected+'&endDate='+this.endDateSelected).subscribe((data: any) => {
      if(data.length>0){
      //  this.loader=false;
        $("#dateWisesmsTable").DataTable().destroy();
        this.tabledata1=data[0].dateWiseSMSReport;
        this.dtTrigger1.next();
        $("#divisionWisesmsTable").DataTable().destroy();
        this.tabledata2=data[1].divisionWiseSMSReport;
        this.dtTrigger2.next();
     //   this.loader=false;
       }
     });
   }catch{
   // this.loader=false;
  }finally{
  //  this.loader=false;
  }
}
onSubmit(value:any){
  try{
       // this.loader=true;//set loader for visible
        let startDate = this.sdate.nativeElement.value;
        let endDate = this.edate.nativeElement.value;
        if(startDate!=''){
          startDate=this.formatDate(startDate);
          this.startDateSelected=startDate;
        }else if(startDate==''){
          alert("Please Provide Start date")
       //   this.loader=false;
          return false;
        }
        if(endDate!=''){
          endDate=this.formatDate(endDate);
          this.endDateSelected=endDate
        }else if(endDate==''){
          alert("Please Provide End date")
       //   this.loader=false;
          return false;
        }
        if(startDate>endDate){
          alert("Start date should be greater then End date.")
        //  this.loader=false;
          return false;
        }
        if(value.circle=="0"){
          this.circleSelected="All";
        }else{
          this.circleSelected=value.circle;
        }if(value.division=="0"){
          this.divisionSelected="All";
        }else{
          this.divisionSelected=value.division;
        }
        this.getSMSAuditReport();
     }catch{
     //  this.loader=false;
     }finally{
     //  this.loader=false;
     }
  }

}
