<app-header *ngIf="isDashboard"></app-header>
<app-menu  *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
			        <li class="nav-item">
			            <a id="tab-satkosiaPAmap" href="#pane-satkosiaPAmap" class="nav-link active" data-toggle="tab" role="tab">Map</a>
			        </li>
			        <li class="nav-item" >
			            <a id="tab-satkosiaPAinfo" href="#pane-satkosiaPAinfo" class="nav-link" data-toggle="tab" role="tab">Information</a>
			        </li>
			    </ul>

			    <div id="accordion" class="tab-content" role="tablist">
			        <div id="pane-satkosiaPAmap" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-satkosiaPAmap">
			            <div class="card-header" role="tab" id="heading-satkosiaPAmap">
			                <h5 class="mb-0">
			                    <a data-toggle="collapse" href="#collapse-satkosiaPAmap" aria-expanded="true" aria-controls="collapse-satkosiaPAmap">
                            Map
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-satkosiaPAmap" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-satkosiaPAmap">
                    <div class="card-body">
                           <div class="map" id="mapsatakosia">
                             <div class="gle-icon">
                              <a href="javascript:void(0)">
                                <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid" alt="Street View" title="Street View">
                            </a>
                            <a href="javascript:void(0)">
                                <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()' alt="Satellite View" title="Satellite View">
                            </a>
                            <a href="javascript:void(0)">
                                <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View" title="Topo View">
                            </a>
                                    </div>
                           </div>
                    </div>
			            </div>
			        </div>

			        <div id="pane-satkosiaPAinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-satkosiaPAinfo">
			            <div class="card-header" role="tab" id="heading-satkosiaPAinfo">
			                <h5 class="mb-0">
			                    <a class="collapsed" data-toggle="collapse" href="#collapse-satkosiaPAinfo" aria-expanded="false" aria-controls="collapse-satkosiaPAinfo">
			                       Information
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-satkosiaPAinfo" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-satkosiaPAinfo">


                    <div class="card-body">

                      <h4 class="card-title">
                        Satkosia Gorge Sanctuary
                        <span class="custom-down">
                          <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
                          <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                          </span>
                      </h4>
                      <div id="printcontent">
                      <div class="row">
                        <div class="col-md-8">
                          <div class="table-responsive">
                            <table class="table table-sm table-bordered">
                              <tbody>
                                <tr>
                                  <td><strong>Forest Division : </strong></td>
                                  <td>Satkasia (Wildlife) Division </td>
                                </tr>
                                <tr>
                                  <td><strong>District : </strong></td>
                                  <td>Angul</td>
                                </tr>
                                <tr>
                                  <td><strong>Date of Notification : </strong></td>
                                  <td>19-05-1976</td>
                                </tr>
                                <tr>
                                  <td><strong>Notified Area : </strong></td>
                                  <td>795.52sq.kms</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-md-2 video-box no-print">
                          <a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/iR2B66ZxWvQ" data-target="#myModal">
                            <h4>Satkosia Gorge Sanctuary</h4>
                            <div class="overlay">
                              <span class="icon">
                                <i class="fa fa-play-circle"></i>
                              </span>
                            </div>
                          </a>
                        </div>
                        <div class="col-md-2">
                          <img src="assets/images/Gharial-crocodile_SatkosiaPA.jpg" class="img-fluid float-right img-inner" alt="image">
                        </div>
                      </div>
                      <p>
                        Satkosia Gorge Sanctuary owes its name to the narrow stretch of River Mahanadi that is "sat-kosh" or seven miles long near Tikarpada, 60km south of Angul. Here Mahanadi has cut across the Eastern Ghats and is known for the gharials, mugger crocodile and rare freshwater turtles like Chitra indica and Trionyx (Aspideretes) gangeticus. The Gorge is famous for the scenic beauty and boating experiences in Mahanadi. A Nature Interpretation Centre at Tikarpada supplements an ecotourist's search for subject and thrill. A journey to stay in the Eco cottages/tented accommodation at Chhotkei, Purunakote, Tikarpada, Baghmunda Tarava & Baliput offer scope for chance encounter with tiger, leopard, elephant, Indian bison, Sambar, Spotted deer, Barking deer varieties of resident and migratory birds like Oriental Pied Hornbills and Indian Skimmers. The composite Satkosia-Baisipalli sanctuary having an area of 963.87 Sq.kms. is declared as "Satkosia Tiger Reserve' during 2007.
                      </p>
                      <h4 class="card-title">Quick Facts</h4>
                      <ul class="custom-list">
                        <li>Satkosia Gorge Sanctuary extending in an area of 795.52 sq.km, is a rare place of beauty where nature is generously bountiful in all its forms of flaura and fauna.</li>
                          <li>Satkosia Gorge Wildlife Sanctuary was established in 1976 in Odisha. Spread out in four districts of Odisha namely Angul, Budh, Cuttack and Nayagarh.</li>
                          <li>Satkosia Gorge has an exclusive geomorphology feature as Mahanadi cuts it through the Eastern Ghats thus forming a wonderful gorge.</li>
                          <li>High range of hills on the southern portion of Mahanadi river and the northern sides are covered with different range of mountains.</li>
                          <li>The sanctuary functions as a carbon sink to the fast-growing industrialization around Angul.</li>
                          <li>Heavy biotic pressure on the sanctuary due to presence of several human habitations in and around the sanctuary.</li>
                          <li> The enchanting gorge spreads over 22 kms. forms a special habitat for aquatic fauna and flora.</li>
                      </ul>
                      <h4 class="card-title">How to reach</h4>
                      <p class="address-inner">
                        <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>Travellers can reach the sanctuary by buses operated by the Orissa Tourism Development Corporation (OTDC) from Athagarh.
                      </p>
                      <p class="address-inner">
        <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong>Angul Railway Station is the nearest railway station, situated at a distance of around 113 km.
      </p>
      <p class="address-inner">
        <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>The nearest airport is Bhubaneswar Airport in Odisha, which is located at a distance of around 125 km.
                      </p>
                      <h4 class="card-title">Facilities available</h4>
                      <ul class="custom-list">
                        <li>Community managed Nature camps are operated at Chhotkei, Baghmunda, Tarava, Tikarpada, Purunakote and Baliput.</li>
                        <li>Ecofriendly cottages with beautiful scenic places and all facilities at Chhotkei, Baghmunda, Purunakote.</li>
                        <li>New Luxurious, Waterproof, Spacious, Deluxe Tents with beds, mattresses, quilts, table, chair and attached Bathroom at Tarava,Tikarpada and Baliput.</li>
                          <li>Eco-friendly dining space at camp site with ethnic touch, Complimentary Bed Tea and drinking Water , Hot water on request, Breakfast, lunch and dinner in the nature camps.</li>
                          <li>Sports Tourism like Volley Ball , Handball, Nature trekking, Rope walking at Camp Site</li>
                          <li>Boat cruise in river Mahanadi</li>
                          <li>Bird watching</li>
                      </ul>
                      <h4 class="card-title">Salient Detail</h4>
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td width="15%"><strong>Geographic Extent : </strong></td>
                              <td width="85%">Longitude 84° 38' to 85° 7'(East) and Latitude 20° 47' to 20° 26'(North)</td>
                            </tr>
                            <tr>
                              <td><strong>Climate : </strong></td>
                              <td>Temperature - In summer: 47°C, In winter: 4.5°C Rainfall - 1300mm, rainy days: 75 days</td>
                            </tr>
                            <tr>
                              <td><strong>Villages :</strong></td>
                              <td>	52 villages</td>
                            </tr>
                            <tr>
                              <td><strong>Contact officer : </strong></td>
                              <td>
                                <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest Officer  <br>
                                <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Ecotourism Cell, Satkosia Wildlife Division, Angul-759143 <br>
                                <span class="address-inner"><i class="fa fa-mobile">&nbsp;</i></span>8763102681<br>
                              </td>
                            </tr>
                            <tr>
                              <td><strong>Best time to visit : </strong></td>
                              <td>Winter</td>
                            </tr>
                            <tr>
                              <td><strong>Accommodation : </strong></td>
                              <td>FRHs at Tikarpara, Baghmunda &Purunakote. Eco-cottages at Chhotkei, Purunakote & Baghmunda Luxurious Tents at Tarava,Tikarpada ,Chhotkei & Baliput. Booking: Online booking can be done from website-www.ecotourodisha.com</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>
                    </div>

            </div>
			            </div>
			        </div>

			    </div>

			</div>
		</div>
	</div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video"  allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
