
<section class="service font-script pt-50">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-6 col-x-12">
            	<a [routerLink]="['/protectedareasodisha']">
	                <div class="service-item center">
	                    <div class="icon-box">
	                        <img src="assets/images/icon-service-01.png" alt="image">
	                    </div>
	                    <h4 class="text-center">Protected Areas in Odisha</h4>
	                </div>
	            </a>
            </div>
            <div class="col-md-4 col-sm-6 col-x-12">
            	<a href="http://www.ecotourodisha.com" target="_blank">
	                <div class="service-item center">
	                    <div class="icon-box">
	                         <img src="assets/images/icon-service-02.png" alt="image">
	                    </div>
	                    <h4 class="text-center">EcoTour Odisha</h4>
	                </div>
	            </a>
            </div>
            <div class="col-md-4 col-sm-6 col-x-12">
            	<a [routerLink]="['/shortfilmsprotectedareas']">
	                <div class="service-item center">
	                    <div class="icon-box">
	                         <img src="assets/images/icon-service-03.png" alt="image">
	                    </div>
	                    <h4 class="text-center">Short Films on Protected Areas</h4>
	                </div>
	            </a>
            </div>
        </div>
    </div>
</section>
