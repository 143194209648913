<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <ul id="tabs" class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a id="tab-hadgarhPAmap" href="#pane-hadgarhPAmap" class="nav-link active" data-toggle="tab"
              role="tab">Map</a>
          </li>
          <li class="nav-item">
            <a id="tab-hadgarhPAinfo" href="#pane-hadgarhPAinfo" class="nav-link" data-toggle="tab"
              role="tab">Information</a>
          </li>
        </ul>

        <div id="accordion" class="tab-content" role="tablist">
          <div id="pane-hadgarhPAmap" class="card tab-pane fade show active" role="tabpanel"
            aria-labelledby="tab-hadgarhPAmap">
            <div class="card-header" role="tab" id="heading-hadgarhPAmap">
              <h5 class="mb-0">
                <a data-toggle="collapse" href="#collapse-hadgarhPAmap" aria-expanded="true"
                  aria-controls="collapse-hadgarhPAmap">
                  Map
                </a>
              </h5>
            </div>
            <div id="collapse-hadgarhPAmap" class="collapse show" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-hadgarhPAmap">
              <div class="card-body">
                <div class="map" id="maphadgarh">
                  <div class="gle-icon">
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid"
                        alt="Street View" title="Street View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()'
                        alt="Satellite View" title="Satellite View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View"
                        title="Topo View">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="pane-hadgarhPAinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-hadgarhPAinfo">
            <div class="card-header" role="tab" id="heading-hadgarhPAinfo">
              <h5 class="mb-0">
                <a class="collapsed" data-toggle="collapse" href="#collapse-hadgarhPAinfo" aria-expanded="false"
                  aria-controls="collapse-hadgarhPAinfo">
                  Information
                </a>
              </h5>
            </div>
            <div id="collapse-hadgarhPAinfos" class="collapse" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-hadgarhPAinfo">

              <div class="card-body">

                <h4 class="card-title">
                  Hadgarh Sanctuary
                  <span class="custom-down">
                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i
                        class="fa fa-file-pdf">&nbsp;</i>Information</button>
                        <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                  </span>
                </h4>
                <div id="printcontent">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td><strong>Forest Division : </strong></td>
                              <td>Keonjhar (Wildlife) Division</td>
                            </tr>
                            <tr>
                              <td><strong>District : </strong></td>
                              <td>Keonjhar</td>
                            </tr>
                            <tr>
                              <td><strong>Date of Notification : </strong></td>
                              <td>06-12-1978</td>
                            </tr>
                            <tr>
                              <td><strong>Notified Area : </strong></td>
                              <td>191.06sq.km</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-2 video-box no-print">
                      <a href="#" class="video-btn" data-toggle="modal"
                        data-src="https://www.youtube.com/embed/NVL7VD979kc" data-target="#myModal">
                        <h4>Hadgarh Sanctuary</h4>
                        <div class="overlay">
                          <span class="icon">
                            <i class="fa fa-play-circle"></i>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="col-md-2">
                      <img src="assets/images/hadh-pic.jpg" class="img-fluid float-right img-inner" alt="image">
                    </div>
                  </div>
                  <p>
                    Hadgarh Wildlife Sanctuary lies in the district of Keonjhar and Mayurbhanj. It is near the Hadgarh
                    reservoir of Salandi Dam. This region is rich in mixed deciduous forest and play home to variety of
                    wildlife. The wide range of fauna that can be seen at Hadgarh Wildlife Sanctuary in Odisha, India
                    includes leopard,tiger, fishing cat, jungle cat,langur, pangolin and hyena.Hadgarh Wildlife
                    Sanctuary is also abode of several species of birds and reptiles.The conservation area of Hadgarh
                    Wildlife Sanctuary is very popular with the tourists who wish to relish the wild and unspoilt
                    natural environment.
                  </p>
                  <h4 class="card-title">Quick Facts</h4>
                  <ul class="custom-list">
                    <li>It was established in the year 1978 covering an area of regarding 191.06sq kms.</li>
                    <li>The Salandi river passing through its forest is its lifeline.</li>
                    <li>The Salandi river is home to mugger crocodile.</li>
                    <li>There is a dam in the Salandi river named Salandi dam.</li>
                    <li>The Baula hill range lying on the east and west of Salandi River, the valley engaged by the
                      reservoir and its catchment are the main features.</li>
                  </ul>
                  <h4 class="card-title">How to reach</h4>
                  <p class="address-inner">
                    <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>The township Anandapur of is linked with
                    Calcutta, Bhubaneshwar, Ranchi and Jamshedpur.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong>The nearest railhead is
                    Jajpur-Keojhar.Road which is about 30 kms from Anandapur. The railhead is the closest Railway
                    Station that connects the place to the rest of Odisha.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>The nearest airport to Anandapur is the
                    Bhubaneshwar Airport.There are domestic flights from Bhubaneshwar which connects it to the rest of
                    India.
                  </p>
                  <h4 class="card-title">Facilities available</h4>
                  <ul class="custom-list">
                    <li>A wireless communication system covering all important points within and around the sanctuary
                      has been established. During emergency this communication network will usually be available to
                      multi-day tourists through the established base stations.</li>
                    <li>Forest rest house and watch towers are also available.</li>
                    <li>Accommodation for stay at Salandi Nilaya at Hadagarh can be available from Executive Engineer,
                      Salandi Irrigation Dam, Anandapur.</li>
                  </ul>
                  <h4 class="card-title">Salient Detail</h4>
                  <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                      <tbody>
                        <tr>
                          <td width="15%"><strong>Geographic Extent : </strong></td>
                          <td width="85%">Longitude 86° 10' to 86° 22'(East) Latitude 21° 23' to 21° 12'(North)</td>
                        </tr>
                        <tr>
                          <td><strong>Climate : </strong></td>
                          <td>Moderate</td>
                        </tr>
                        <tr>
                          <td><strong>Villages :</strong></td>
                          <td>None</td>
                        </tr>
                        <tr>
                          <td><strong>Contact officer : </strong></td>
                          <td>
                            <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest
                            Officer<br>
                            <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Keonjhar Wildlife
                            Division,Anandapur.<br>
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Best time to visit : </strong></td>
                          <td>October to May</td>
                        </tr>
                        <tr>
                          <td><strong>Accommodation : </strong></td>
                          <td>Inspection Bungalows at Anandpur,Hadgarh and Keonjhar.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
                      </strong>The information contained herein are provided solely for informational purposes only.The
                      content is dynamic and may undergo change from time to time. The snapshot of the content at a
                      particular time is not to be referred as a legally binding document.</small></p>
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
