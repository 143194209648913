<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
			        <li class="nav-item">
			            <a id="tab-bamramap" href="#pane-bamramap" class="nav-link active" data-toggle="tab" role="tab">Map</a>
			        </li>
			        <li class="nav-item">
			            <a id="tab-bamrainfo" href="#pane-bamrainfo" class="nav-link" data-toggle="tab" role="tab">Information</a>
			        </li>
			    </ul>

			    <div id="accordion" class="tab-content" role="tablist">
			        <div id="pane-bamramap" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-bamramap">
			            <div class="card-header" role="tab" id="heading-bamramap">
			                <h5 class="mb-0">
			                    <a data-toggle="collapse" href="#collapse-bamramap" aria-expanded="true" aria-controls="collapse-bamramap">
			                        Map
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-bamramap" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-bamramap">
			                <div class="card-body">
								<div id="bamaraWLmap" class="map">
                                    <div class="gle-icon">
										<a href="javascript:void(0)">
                                            <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid" alt="Street View" title="Street View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()' alt="Satellite View" title="Satellite View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View" title="Topo View">
                                        </a>
                                    </div>
                                </div>


			                </div>
			            </div>
			        </div>

			        <div id="pane-bamrainfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-bamrainfo">
			            <div class="card-header" role="tab" id="heading-bamrainfo">
			                <h5 class="mb-0">
			                    <a class="collapsed" data-toggle="collapse" href="#collapse-bamrainfo" aria-expanded="false" aria-controls="collapse-bamrainfo">
									Information
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-bamrainfo" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-bamrainfo">
			                <div class="card-body">
								<h4 class="card-title">
									Bamra Wildlife Division
									<span class="custom-down">
                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
					<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
						class="fa fa-file-pdf">&nbsp;</i>Map</button>
			                		</span>
								</h4>
                <div id="printcontent">
                                <div class="row">
                                	<div class="col-md-9">
                                		<div class="table-responsive">
		                                	<table class="table table-sm table-bordered">
		                                		<tbody>
		                                			<tr>
		                                				<td><strong>District : </strong></td>
		                                				<td>Sambalpur and Deogarh</td>
		                                			</tr>
                                                    <tr>
                                                        <td><strong>Geographical Extent : </strong></td>
                                                        <td>Longitude 84° 8'E and 84° 37'E & Latitude 22° 11'N and 21° 15'N</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Climate : </strong></td>
                                                        <td>Temperature - In summer: 40°C, in winter: 1°C</td>
                                                    </tr>
		                                		</tbody>
		                                	</table>
		                                </div>
                                	</div>
                                	<div class="col-md-3">
                                		<img src="assets/images/wildlifeunits-bamra-01.jpg" class="img-fluid float-right img-inner" alt="image">
                                	</div>
                                </div>
                                <p>Bamra Wildlife Division is located between 21° 15’ 56’’ and 22° 11’ 21’’ latitude and 84° 08’ 08’’ and 84° 37’ 49’’ longitude. Areas coming under this division mainly belong to Sambalpur district and a small part is coming under Deogarh District. This division is comprised of 5 ranges , 21 sections and 90 beats. The two picturesque Wildlife Sanctuaries namely Badrama Wildlife Sanctuary and Khalsauni Wildlife Sanctuary are located in this wildlife division.</p>
                                <h4 class="card-title">Flora </h4>
                                <p>The forests of this wildlife division are mainly dry deciduous, comprises of Sandalwood, Arjun, Neem, Acacia, Sal and Casuarinas.Sal is predominant with associates consisting of Asan, Dhaura, Bija, Sisoo, Bandhan, Sidha, Kurum, Kasi, Patuli, Char, Mahul etc. Bamboo is predominant in certain patches and at higher slopes.The herbs and shrubs seen are Dhatki, Telkurume, Gurudu, Kurei, and Begunia etc. The orchids & ferns seen are Vanda, Eulophizanuda, Derdrobiume herbacium, Hebineria plantazenia saccolobium spp. Silagenela proniflora etc.</p>
                                <h4 class="card-title">Fauna </h4>
                                <p>The residents of this division includes Elephant, Bison, Leopard, Bear, Sambar, Spotted Deer, Barking Deer, Monkey, Wild boar, Mouse Deer, Porcupine, Kingcobra, Cobra, Monitor, Lizard, Hornbill, Flying Squirrels etc.Egret Cattle, Egret little, weaver bird, Bulbul Red, varted, Goose, Jungle fowl, Drange, Hornbill, kite, crow, pigeon, myna, bustard, oriole, Indian cuckoo, Done king, Hornbill malaharpied, wood pecker, common peafowl, Hill myna, Indian parakeet etc. are also seen inside the division.</p>
                                <h4 class="card-title">Set-Ups</h4>
                                <div class="table-responsive">
                                	<table class="table table-sm table-bordered">
                                		<tbody>
                                            <tr>
                                				<td><strong>Administrative set-up (Range, Section, beats) :</strong></td>
                                				<td> Ranges- 5, Sections- 21,Beats- 90</td>
                                			</tr>
                                			<tr>
                                				<td><strong>EDC/JFM set-up:  </strong></td>
                                				<td>
                                					EDCs - 27, VSS - 201
                                				</td>
                                			</tr>
                                			<tr>
                                                <td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
                                                <td>No. of RF- 21, No. of PRF- 63, No. of DPF- 10, No. of UDPF- 21, No. of VF- 0</td>
                                            </tr>
                                		</tbody>
                                	</table>
                                </div>
                                <h4 class="card-title">Major Attraction</h4>
                                <div class="row">
                                    <div class="col-md-12">
                                        <img src="assets/images/wildlifeunits-bamra-02.jpg" class="img-fluid float-right img-inner" alt="image">
                                        <ul class="custom-list">
                                        	<li>The Masonry watch tower and water body at “Kutab”, The picnic spot called “University Spot” at Badrama and Deodhara Water fall at Argen are major attractions</li>
                                        	<li>Kuladera Water fall located around 15 Km away from Kisinda and Sukhakani picnic spot which is around 7 Km from Kisianda are major attractions</li>
                                            <li>The Badrama sanctuary is one of Odisha's smaller sanctuaries and popular tourist destinations.</li>
                                            <li>Khalasuni Wildlife Sanctuary is another important location ideal for naturalists and wildlife lovers</li>
                                        </ul>
                                    </div>
                                </div>
                                <h4 class="card-title">Salient Detail</h4>
                                <div class="table-responsive">
                                	<table class="table table-sm table-bordered">
                                		<tbody>
                                            <tr>
                                				<td width="15%"><strong>HQ Address :</strong></td>
                                				<td width="85%">AT/PO: Bamra PS: Govindpur Dist : Smbalpur PIN: 768221, Odisha, India</td>
                                			</tr>
                                			<tr>
                                				<td><strong>Contact officer : </strong></td>
                                				<td>
                                					<span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest Officer<br>
                                					<span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Bamra Wildlife Division<br>
                                					<span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06642 - 229211<br>
                                					<span class="address-inner"><i class="fa fa-envelope">&nbsp;</i></span>dfobmbwl004@gmail.com<br>
                                				</td>
                                			</tr>
                                			<tr>
                                				<td><strong>Key activities : </strong></td>
                                				<td>Forest Protection Activities, Wildlife Management Activities, Eco development activities, Eco tourism activities, and Nature Education.</td>
                                			</tr>
                                			<tr>
                                				<td><strong>Programmes : </strong></td>
                                				<td>Nature Education Camps, Nature trail, Important Day celebrations, Awareness programmes.</td>
                                            </tr>
                                            <tr>
                                				<td><strong>Places to see : </strong></td>
                                				<td>Gudguda Water Fall, Kutab Watch Tower, University Spot, Kuladera Water fall, Deodhara Water fall and Sukhakani picnic spot</td>
                                			</tr>
                                		</tbody>
                                	</table>
                                </div>
                                <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>
                                </div>
			                </div>
			            </div>
			        </div>

			    </div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>
