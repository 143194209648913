
<section class="event-style1 blog-content font-script">
	<div class="pt-0 pb-100">
		<div class="container">
	        <div class="row">
	            <div class="col-md-8 col-sm-8 col-xs-12">
	                <div class="section-title">
	                    <h2>Our Blog</h2>
	                </div>
	            </div>
	            <div class="col-md-4 col-sm-4 col-xs-12">
	                <a [routerLink]="['/blogdetails']" class="thm-btn style-2 mb-3 pull-right">All Blogs</a>
	            </div>
	        </div>
	        <div class="row" *ngIf="blogExist">
            <article class="col-md-6 col-sm-12 col-xs-12" *ngFor="let blog of blogList;">
              <div class="item style-2 overlay">
                  <div class="clearfix">
                      <div class="img-column">
                          <figure class="img-holder">
                              <img src={{blog.blogImgPath}} (error)="defaultUrl($event)" alt="">
                          </figure>
                      </div>
                      <div class="text-column">
                          <div class="lower-content">
                              <h4>  {{ (blog.title.length>60)? (blog.title | slice:0:60)+'....':(blog.title) }} </h4>
                              <div class="text">
                              <p>{{ (blog.title.blogDesc>100)? (blog.blogDesc | slice:0:100)+'....':(blog.blogDesc) }}</p>
                            </div>
                          </div>
                          <ul class="post-meta list_inline">
                          <li><i class="fa fa-clock-o"></i>{{ (blog.craetedOn  !== 'null') ? (blog.craetedOn | date:'dd-MM-yyyy')  : '' }} {{ (blog.craetedOn  !== 'null') ? (blog.craetedOn  | date:'shortTime')  : '' }}</li>  |&nbsp;&nbsp;&nbsp;
                          <li><i class="fa fa-map-marker"></i> {{blog.createdByName}}</li>
                      </ul>
                      </div>
                  </div>
              </div>
          </article>






	            <!-- <article class="col-md-6 col-sm-12 col-xs-12">
	                <div class="item style-2 overlay">
	                    <div class="clearfix">
	                        <div class="img-column">
	                            <figure class="img-holder">
	                                <img src="assets/images/blog-01.jpg" alt="">
	                            </figure>
	                        </div>
	                        <div class="text-column">
	                            <div class="lower-content">
	                                <h4>Human elephant conflict management – A review of current management ...</h4>
	                                <div class="text">
		                            	<p>Human elephant conflict management – A review of current management and future directions ...</p>
		                            </div>
	                            </div>
	                            <ul class="post-meta list_inline">
			                        <li><i class="fa fa-clock-o"></i>21.07.2020 9:23:00 PM </li>  |&nbsp;&nbsp;&nbsp;
			                        <li><i class="fa fa-map-marker"></i> Similipal North WL</li>
			                    </ul>
	                        </div>
	                    </div>
	                </div>
	            </article>
	            <article class="col-md-6 col-sm-12 col-xs-12">
	                <div class="item style-2 overlay">
	                    <div class="clearfix">
	                        <div class="img-column">
	                            <figure class="img-holder">
	                                <img src="assets/images/blog-02.jpg" alt="">
	                            </figure>
	                        </div>
	                        <div class="text-column">
	                            <div class="lower-content">
	                                <h4>Supply of FRP 10 Seater Sea Worthy Speed Boats with outboard Petrol ...</h4>
	                                <div class="text">
		                            	<p>Supply of FRP 10 Seater Sea Worthy Speed Boats with outboard Petrol engine which should be ...</p>
		                            </div>
	                            </div>
	                            <ul class="post-meta list_inline">
			                        <li><i class="fa fa-clock-o"></i>13.02.2020 5:31:00 PM </li> |&nbsp;&nbsp;&nbsp;
			                        <li><i class="fa fa-map-marker"></i> DFO Balasore</li>
			                    </ul>
	                        </div>
	                    </div>
	                </div>
	            </article> -->
	        </div>
	    </div>
	</div>
</section>
