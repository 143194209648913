<app-header *ngIf="isDashboard"></app-header>
<app-menu  *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
			        <li class="nav-item">
			            <a id="tab-paralakhemundimap" href="#pane-paralakhemundimap" class="nav-link active" data-toggle="tab" role="tab">Map</a>
			        </li>
			        <li class="nav-item">
			            <a id="tab-paralakhemundiinfo" href="#pane-paralakhemundiinfo" class="nav-link" data-toggle="tab" role="tab">Information</a>
			        </li>
			    </ul>

			    <div id="accordion" class="tab-content" role="tablist">
			        <div id="pane-paralakhemundimap" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-paralakhemundimap">
			            <div class="card-header" role="tab" id="heading-paralakhemundimap">
			                <h5 class="mb-0">
			                    <a data-toggle="collapse" href="#collapse-paralakhemundimap" aria-expanded="true" aria-controls="collapse-paralakhemundimap">
			                        Map
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-paralakhemundimap" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-paralakhemundimap">
			                <div class="card-body">
								<div class="map" id="paralaWLmap">
                                    <div class="gle-icon">
										<a href="javascript:void(0)">
                                            <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid" alt="Street View" title="Street View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()' alt="Satellite View" title="Satellite View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View" title="Topo View">
                                        </a>
                                    </div>
                                </div>


			                </div>
			            </div>
			        </div>

			        <div id="pane-paralakhemundiinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-paralakhemundiinfo">
			            <div class="card-header" role="tab" id="heading-paralakhemundiinfo">
			                <h5 class="mb-0">
			                    <a class="collapsed" data-toggle="collapse" href="#collapse-paralakhemundiinfo" aria-expanded="false" aria-controls="collapse-paralakhemundiinfo">
			                       Information
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-paralakhemundiinfo" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-paralakhemundiinfo">
			                <div class="card-body">
			                	<h4 class="card-title">
			                		Parlakhemundi Forest Division
			                		<span class="custom-down">
                            <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
							<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
								class="fa fa-file-pdf">&nbsp;</i>Map</button>
			                		</span>
			                	</h4>
                        <div id="printcontent">
                                <div class="row">
                                	<div class="col-md-9">
                                		<div class="table-responsive">
		                                	<table class="table table-sm table-bordered">
		                                		<tbody>
		                                			<tr>
		                                				<td><strong>District : </strong></td>
		                                				<td>Gajapati</td>
		                                			</tr>
                                                    <tr>
                                                        <td><strong>Geographical Extent : </strong></td>
                                                        <td>Longitude 84° 32'E and 83° 47'E & Latitude 18° 44'N and 19° 39'N</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Climate : </strong></td>
                                                        <td>Summer : March to mid June (max - 46°C), Rainy : Mid June to October (1000mm), Winter : November to February(Min -9°C)</td>
                                                    </tr>
		                                		</tbody>
		                                	</table>
		                                </div>
                                	</div>
                                	<div class="col-md-3">
                                		<img src="assets/images/wildlifeunits-parlekhemundi-01.jpg" class="img-fluid float-right img-inner" alt="image">
                                	</div>
                                </div>
                                <p>Paralakhemundi Forest Division situated at the Southeastern part of Orissa, covers the entire area of Gajapati district, with portion of Berhampur and Bhanjanagar civil sub-division of Ganjam district. The Lakhary Valley lies in this Division. Lakhary Valley Wildlife Sanctuary is situated entirely within Gajapati District of Odisha under the administrative jurisdiction of Paralakhemundi Forest Division and Berhampur Forest Circle. Lakhary Valley as the name denotes is a plain land surrounded by dense hill forest.Rich and varied in flora and fauna, the Sanctuary is bestowed with panoramic landscape.</p>
                                <h4 class="card-title">Flora </h4>
                                <p>al is found mixed with miscellaneous species, i.e Asan, Halanda, Mundi, Jamun, Bandhan, Piasal, Dhaura, Kasi, Dhamana, etc. The other species are Kusum, Char, Bhalia, Amla etc. All along water course, Sal is replaced by Rohini, Arjun, Jamun and Mankarakendu. The under growth is dominated by eupatorium, lantana and grasses, Piasal is a characteristic associate of Sal in these forests reaching the top canopy. Moist and dry deciduous vegetation with pure patches of Bheru and abundance of Kamalagundi and Bamboo are found here.Other species found are Phasi, Kanchana, Moi, Karada etc.</p>
                                <h4 class="card-title">Fauna </h4>
                                <p>33 species of Mammals, 90 species of Birds, 05 species of Amphibians, 31 species of Reptiles & 17 species of fishes are available .Indian elephant (Elephants maximus indicus) is the flagship animal.Other animals include Leopard or Panther,Jackal ,Small Indian Cive, Sambar , Spotted deer, Barking Deer,etc.</p>
                                <h4 class="card-title">Set-Ups</h4>
                                <div class="table-responsive">
                                	<table class="table table-sm table-bordered">
                                		<tbody>
                                            <tr>
                                				<td><strong>Administrative set-up (Range, Section, beats) :</strong></td>
                                				<td>Ranges- 7, Sections- 26,Beats- 91</td>
                                			</tr>
                                			<tr>
                                				<td><strong>EDC/JFM set-up:  </strong></td>
                                				<td>
                                                    EDCs - 5, VSS - 0
                                				</td>
                                			</tr>
                                			<tr>
                                                <td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
                                                <td>	No. of RF- 36, No. of PRF- 14, No. of DPF- 14, No. of UDPF- 47, No. of VF- 0</td>
                                            </tr>
                                		</tbody>
                                	</table>
                                </div>
                                <h4 class="card-title">Major Attraction</h4>
                                <div class="row">
                                    <div class="col-md-12">
                                        <img src="assets/images/wildlifeunits-parlekhemundi-02.jpg" class="img-fluid float-right img-inner" alt="image">
                                        <ul class="custom-list">
                                        	<li>Brundaban Palace”, A picturesque spot was made by the Maharaja of Paralakhemundi for their use during summer. It is situated on the bank of river Mahendratanaya surrounded by thick teak trees.</li>
                                        	<li>Picturesque Mahendragiri hills (abode Lord Parsuram according to Hindu Mythology) is about 65 KMs from Paralakhemundi. It is reach ecologically as well as lot of archaeological evidences. Gudguda Waterfal on Mahendratanaya River is on the way.</li>
                                            <li>Tibatian Budhist settlements around Chandragiri is a unique center of attraction where one of the largest in Asia Budhist monastery have been constracted.</li>
                                            <li>Gandahati: A beautiful waterfall situated at about 30 kms. from Paralakhemundi. There is a waterfall surrounded by the dense red sander forest. Wild animals and sight seeing is another attraction.</li>
                                            <li>SERANGO: It is situated at about 34 kms. from Paralakhemundi and 6 kms. from Gumma. The place was remembered for “Paik Unrest” during British Raj. It is famous for its scenic beauty and also a health resort. It is also known as the “Kashmir of Gajapati”.</li>
                                            <li>Jeerango : It is situated 37 KMs from Paralakhemundi and 13 KMs. From Koinpur. It is famous for the Baladev Temple surrounded by Dense Forest and horticulture plantation.</li>
                                        </ul>
                                    </div>
                                </div>
                                <h4 class="card-title">Salient Detail</h4>
                                <div class="table-responsive">
                                	<table class="table table-sm table-bordered">
                                		<tbody>
                                            <tr>
                                				<td width="15%"><strong>HQ Address :</strong></td>
                                				<td width="85%">Jeerango : It is situated 37 KMs from Paralakhemundi and 13 KMs. From Koinpur. It is famous for the Baladev Temple surrounded by Dense Forest and horticulture plantation.</td>
                                			</tr>
                                			<tr>
                                				<td><strong>Contact officer : </strong></td>
                                				<td>
                                					<span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest Officer<br>
                                					<span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Paralakhemundi Forest Division, Gajapati<br>
                                				</td>
                                			</tr>
                                			<tr>
                                				<td><strong>Key activities : </strong></td>
                                				<td>Deployment of anti poaching / fire preventing / anti smuggling squads.</td>
                                			</tr>
                                			<tr>
                                				<td><strong>Programmes : </strong></td>
                                				<td>Eco developmental programmes, awareness programmes, Development of Indigenous Forest and raising of forest plantation.</td>
                                            </tr>
                                            <tr>
                                				<td><strong>Places to see : </strong></td>
                                				<td>Lakhary-Valley sanctuary, Khasada water fall, Gandahati Waterfal, Budhist Monastery at Chandragiri & King Palace at Paralakhemundi etc.</td>
                                			</tr>
                                		</tbody>
                                	</table>
                                </div>
                                <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>
                              </div>
			                </div>
			            </div>
			        </div>

			    </div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>
