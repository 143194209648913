import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../service/api.service';

@Component({
  selector: 'app-protectedareasodisha',
  templateUrl: './protectedareasodisha.component.html',
  styleUrls: ['./protectedareasodisha.component.css']
})
export class ProtectedareasodishaComponent implements OnInit {
  public PAid: string;
  public loader: boolean = false;

  // public PADATA = [
  //   {
  //     "paId": 1,
  //     "paName": "Badrama",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 2,
  //     "paName": "Baisipalli",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 3,
  //     "paName": "Balukhand-Konark",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 4,
  //     "paName": "Bhitarakanika",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 5,
  //     "paName": "Chandaka-Dampada",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 6,
  //     "paName": "Chilika (Nalaban)",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 7,
  //     "paName": "Debrigarh",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 8,
  //     "paName": "Gahirmatha (Marine)",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 9,
  //     "paName": "Hadgarh",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 10,
  //     "paName": "Karlapat",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 11,
  //     "paName": "Kapilash",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 12,
  //     "paName": "Khalasuni",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 13,
  //     "paName": "Kuldiha",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 14,
  //     "paName": "Kotgarh",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 15,
  //     "paName": "Lahkeri-valley",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 16,
  //     "paName": "Nandankanan",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 17,
  //     "paName": "Satkosia Gorge",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 18,
  //     "paName": "Similipal",
  //     "isActive": true
  //   },
  //   {
  //     "paId": 19,
  //     "paName": "Sunabeda",
  //     "isActive": true
  //   }
  // ]
  sanctuaryList: any;

  constructor(private ref: ChangeDetectorRef,private apiService: ApiService) { }

  ngOnInit(): void {
    //this.loader = true;
    this.PAid = "0";
    //this.loader = false;
    this.getPAData();
  }
   getPAData() {
    return this.apiService.request('GET', 'api/v1/masters/getAllSanctuary').subscribe((data: any) => {
      if(data){

          this.sanctuaryList=data;
        }
      });
   }
  onDivChangeloadPADashboard(value: any) {
    this.PAid = value;
    this.ref.detectChanges();
  }

}
