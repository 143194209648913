import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { domainToUnicode } from 'url';
import { ApiService } from '../service/api.service';
import { AuthService } from '../service/auth.service';
import { NotificationService } from '../service/notification.service';
declare var jQuery: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
//  public loader: boolean=false;
  menuItems: any;
  isActive: string;
  contains : any;
  roleId: any;
  public GlinkList: Array<any>;
  linksArray: any;
  linkList: any[];
  currentUrl: any;

  constructor(private authService:AuthService, private apiService: ApiService,private router: Router,private notifyService: NotificationService) {
  }

  ngOnInit(): void {
   // this.loader=true;
  	(function($){

      	$(document).ready(function () {
            // if($("li").hasClass('active') ){
            //   alert(3)
            //   $(this).find('a.dropdown-toggle').attr('aria-expanded', true);
            //   $(this.a).hasClass('dropdown-toggle').removeClass('collapsed');
            // }



            $('#sidebarCollapse').on('click', function () {
                $('#sidebar').toggleClass('active');
                $('#content').toggleClass('active');
                $('#footer').toggleClass('active');
            });


        });
        /*$(document).ready(function() {
            $("html").niceScroll({cursorwidth: '10px', autohidemode: false, zindex: 999 });
        });*/
    })(jQuery);
    this.getAllLink();
   // this.loader=false;
  }
  getAllLink() {
    try{
    const userdata=sessionStorage.getItem('user');
    if(userdata!=null){
      const user=JSON.parse(userdata);
      this.roleId=user.roleId;
      this.linkList=JSON.parse(sessionStorage.getItem("link"));
      if(this.linkList==null){
        this.apiService.request('GET', 'api/v1/link/getAllGlinkByRoleId?roleId='+this.roleId).subscribe((data: Array<any>) => {
          if (data) {
            this.GlinkList = new Array() ;
            var validUrl=new Array();
            data.map(item=>{
              return {
                glink_id : item['glink_id'],
                globalSeq: item['globalSeq'],
                isActive: (item['isActive'] == null) ? (this.checkplink(item['pLInk']) == true ? true : false ): (item['glink_id']==10?(this.isWebReportAccess(item['glink_id'],this.roleId,user.divisionId)==true?'true':'false'):item['isActive'] ),
                linkName: item['linkName'],
                gIcon: item['gIcon'],
                name: item['name'],
                pLInk:item['pLInk'] == null ? [] : item['pLInk']
              }
            }).forEach(item=> this.GlinkList.push(item));
            this.GlinkList.forEach(element => {
             if(element.isActive==true || element.isActive=='true')
                 if(element.isActive==true){
                   if( element.pLInk.length>0){
                      element.pLInk.forEach(link => {
                        if(link.isActive=='true'){
                           validUrl.push({
                            linkName: link.linkName
                          })
                        }
                      });
                    }
                 }else if(element.isActive=='true'){
                    validUrl.push({
                      linkName: element.linkName
                    })
                 }
            });
            sessionStorage.setItem('link', JSON.stringify(this.GlinkList));
            //this.validateUrl(validUrl);
         //   this.loader=false;
          }
        });
      }else{

        this.GlinkList=new Array();
        var validUrl=new Array();
        this.linkList.map(item=>{
          return {
            glink_id : item['glink_id'],
            globalSeq: item['globalSeq'],
            isActive: (item['isActive'] == null) ? (this.checkplink(item['pLInk']) == true ? true : false ): (item['glink_id']==10?(this.isWebReportAccess(item['glink_id'],this.roleId,user.divisionId)==true?'true':'false'):item['isActive'] ),
            linkName: item['linkName'],
            gIcon: item['gIcon'],
            name: item['name'],
            pLInk:item['pLInk'] == null ? [] : item['pLInk']
          }
        }).forEach(item=> this.GlinkList.push(item));
         this.GlinkList.forEach(element => {
          if(element.isActive==true || element.isActive=='true')
              if(element.isActive==true){
                if( element.pLInk.length>0){
                   element.pLInk.forEach(link => {
                     if(link.isActive=='true'){
                        validUrl.push({
                         linkName: link.linkName
                       })
                     }
                   });
                 }
              }else if(element.isActive=='true'){
                 validUrl.push({
                   linkName: element.linkName
                 })
              }
         });
        //this.validateUrl(validUrl);
       // this.loader=false;
      }
    }
  }
  catch(e){
    // this.loader=false;
  }finally{
     // this.loader=false;
  }
  }
  validateUrl(data:any[]) {
    let count=0;
    var url = this.router.url.split('/');
    for (var i = 0; i < data.length; i++) {
      if (data[i]["linkName"].replace('/', '').trim() === url[1]) {
        count++
      }
    }
    if(url[1]=="profile" || url[1]=="changepassword")
    {
      count++
    }
    if (count == 0) {
      this.notifyService.showError("Unauthorized","Error");
      this.router.navigateByUrl('/dashboard');


     //this.router.navigate(['dashboard'])
      //sessionStorage.clear();
      //this.router.navigate(['/']);
    //  this.loader = false;
    }
   // this.loader = false;
  }
  isWebReportAccess(item: any,roleid:any,divId:any) {
    var returnstmnt ;
    if(roleid==2)//for DFO role id
    {
      if(item==10)//for WEB Reporting link id
      {
        if(divId==57 || divId==58 || divId==46 ){//57 similipal south 58 similipal north division 46 Satkosia Wl
          this.authService.setWebReportingAccess(true);
          returnstmnt=true;
        }else{
          returnstmnt=false;
        }
      }else{
        returnstmnt=false;
      }
    }else{
      returnstmnt=false;
    }
   return returnstmnt ;
  }

  checkplink(item : Array<any>)
   {
     var returnstmnt ;
     var count = 0;
    for (let index = 0; index < item.length; index++) {
      const element = item[index];
      if(element.isActive == "true")
      {
        count = count+ 1 ;
      }
    }
     if(count > 0 )
     {
       returnstmnt = true;
     }
     else
     {
       returnstmnt = false;
     }
    return returnstmnt ;
   }
  _activeLink($event,path)
  {
    // if(sessionStorage.getItem("isactive")=="a")
    // {
    //   sessionStorage.setItem("isactive","");
    //   // this.router.navigateByUrl('/refresh', { skipLocationChange: true }).then(() => {
    //   //   this.router.navigate([decodeURI(path)]);
    //   // });
    // }
    // else
    // {
    //   sessionStorage.setItem("isactive","");
    // }
    this.isActive="active";
  }
}
