import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ApiService } from '../service/api.service';
import { Subject } from 'rxjs';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { ReportIncident } from '../model/reportIncident.model';
import { environment } from 'src/environments/environment.prod';


const baseUrl = environment.API_URL;
const baseUrlFile = environment.FILE_API_URL;

import {Fill, Stroke,Text } from 'ol/style';
import Map from 'ol/Map'
import * as layer from 'ol/layer';
import { Tile as Tile, Vector as VectorLayer } from 'ol/layer';
import { OSM as OSM, Vector as VectorSource, Vector as MeasureSource } from 'ol/source';
import XYZ from 'ol/source/XYZ';
import BingMaps from 'ol/source/BingMaps'
import ImageWMS from 'ol/source/ImageWMS';
import { View } from 'ol';
import * as control from 'ol/control';
import * as proj from 'ol/proj';
import 'ol/ol.css';
import Feature from 'ol/Feature';
import { Icon, Style } from 'ol/style';
import Point from 'ol/geom/Point';
import { WFS, GeoJSON } from 'ol/format';
import 'ol/ol.css';
import { NotificationService } from '../service/notification.service';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var jQuery: any;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
 // public loader: boolean=false;
  public loader2: boolean=false;
  public isAdmin: boolean=false;

  public circleDisabled:boolean=false;
  public divisionDisabled:boolean=false;
  public rangeDisabled:boolean=false;

  public circleSelected_incident='All';
  public divisionSelected_incident='All';
  public rangeSelected_incident='All';
  public sectionSelected_incident='All';
  public beatSelected_incident='All';

  public circleSelected_elepDeath='All';
  public divisionSelected_elepDeath='All';
  public rangeSelected_elepDeath='All';
  public sectionSelected_elepDeath='All';
  public beatSelected_elepDeath='All';

  public circleSelected_humanDeath='All';
  public divisionSelected_humanDeath='All';
  public rangeSelected_humanDeath='All';
  public sectionSelected_humanDeath='All';
  public beatSelected_humanDeath='All';

  public circleSelected_humanInjury='All';
  public divisionSelected_humanInjury='All';
  public rangeSelected_humanInjury='All';
  public sectionSelected_humanInjury='All';
  public beatSelected_humanInjury='All';

  public circleSelected_tigerDeath='All';
  public divisionSelected_tigerDeath='All';
  public rangeSelected_tigerDeath='All';
  public sectionSelected_tigerDeath='All';
  public beatSelected_tigerDeath='All';

  public circleSelected_leopardDeath='All';
  public divisionSelected_leopardDeath='All';
  public rangeSelected_leopardDeath='All';
  public sectionSelected_leopardDeath='All';
  public beatSelected_leopardDeath='All';

  public circleSelected_pangolinDeath='All';
  public divisionSelected_pangolinDeath='All';
  public rangeSelected_pangolinDeath='All';
  public sectionSelected_pangolinDeath='All';
  public beatSelected_pangolinDeath='All';

  public circleid_incident: string;
  public divid_incident: string;
  public rangeid_incident: string;
  public sectionid_incident: string;
  public beatid_incident: string;

  public circleid_elepDeath: string;
  public divid_elepDeath: string;
  public rangeid_elepDeath: string;
  public sectionid_elepDeath: string;
  public beatid_elepDeath: string;

  public circleid_humanDeath: string;
  public divid_humanDeath: string;
  public rangeid_humanDeath: string;
  public sectionid_humanDeath: string;
  public beatid_humanDeath: string;

  public circleid_humanInjury: string;
  public divid_humanInjury: string;
  public rangeid_humanInjury: string;
  public sectionid_humanInjury: string;
  public beatid_humanInjury: string;

  public circleid_tigerDeath: string;
  public divid_tigerDeath: string;
  public rangeid_tigerDeath: string;
  public sectionid_tigerDeath: string;
  public beatid_tigerDeath: string;

  public circleid_leopardDeath: string;
  public divid_leopardDeath: string;
  public rangeid_leopardDeath: string;
  public sectionid_leopardDeath: string;
  public beatid_leopardDeath: string;

  public circleid_pangolinDeath: string;
  public divid_pangolinDeath: string;
  public rangeid_pangolinDeath: string;
  public sectionid_pangolinDeath: string;
  public beatid_pangolinDeath: string;


  tabledata4: any[];
  tabledata5: any[];
  tabledata6: any[];
  tabledata7: any[];
  tabledata8: any[];
  tabledata9: any[];
  tabledata10: any[];

  elepDeathForm:FormGroup;
  incidentForm:FormGroup;
  humanDeathForm:FormGroup;
  tigerDeathForm:FormGroup;
  leopardDeathForm:FormGroup;
  pangolinDeathForm:FormGroup;
  humanInjuryForm:FormGroup;

  reporteditForm: FormGroup;
  reportIdEdit: any;
  reportTypeEdit: any;


  public startDateSelected_incident="";
  public endDateSelected_incident="";
  public startDateSelected_elepDeath="";
  public endDateSelected_elepDeath="";
  public startDateSelected_humanDeath="";
  public endDateSelected_humanDeath="";
  public startDateSelected_humanInjury="";
  public endDateSelected_humanInjury="";
  public startDateSelected_tigerDeath="";
  public endDateSelected_tigerDeath="";
  public startDateSelected_leopardDeath="";
  public endDateSelected_leopardDeath="";
  public startDateSelected_pangolinDeath="";
  public endDateSelected_pangolinDeath="";

  @ViewChild('sd_incident') sdate_incident : ElementRef;
  @ViewChild('ed_incident') edate_incident : ElementRef;
  @ViewChild('sd_elepDeath') sdate_elepDeath : ElementRef;
  @ViewChild('ed_elepDeath') edate_elepDeath : ElementRef;
  @ViewChild('sd_humanDeath') sdate_humanDeath : ElementRef;
  @ViewChild('ed_humanDeath') edate_humanDeath : ElementRef;
  @ViewChild('sd_humanInjury') sdate_humanInjury : ElementRef;
  @ViewChild('ed_humanInjury') edate_humanInjury : ElementRef;
  @ViewChild('sd_tigerDeath') sdate_tigerDeath : ElementRef;
  @ViewChild('ed_tigerDeath') edate_tigerDeath : ElementRef;
  @ViewChild('sd_leopardDeath') sdate_leopardDeath : ElementRef;
  @ViewChild('ed_leopardDeath') edate_leopardDeath : ElementRef;
  @ViewChild('sd_pangolinDeath') sdate_pangolinDeath : ElementRef;
  @ViewChild('ed_pangolinDeath') edate_pangolinDeath : ElementRef;

  @ViewChild('sd7') incident_Date_ElementRef : ElementRef;
  @ViewChild('from_time') incident_time_ElementRef : ElementRef;

  @ViewChild(SidebarComponent) _sidebarEleRef: SidebarComponent;

  dtTrigger4: Subject<any> = new Subject<any>();
  dtTrigger5: Subject<any> = new Subject<any>();
  dtTrigger6: Subject<any> = new Subject<any>();
  dtTrigger7: Subject<any> = new Subject<any>();
  dtTrigger8: Subject<any> = new Subject<any>();
  dtTrigger9: Subject<any> = new Subject<any>();
  dtTrigger10: Subject<any> = new Subject<any>();
  
  dtOptions: any = {};
  divisionlist: any;
  circleList: any;
  rangelist: any;
  sectionlist: any;
  beatlist: any;

  loginid: string;

  imegary: layer.Image;
	streetmap: layer.Image;
  osmMap: layer.Image;
  _raster:layer.Image;
  _carto:layer.Image;
  mapFire:Map;
  mapDeath:Map;
	modify: any;

  report_Id: string;
  latitude: string;
  longitude: string;
  location: string;
  incidentReportDate: string;
  incidentPhoto: string;
  accuracy: string;
  altitude: string;
  circle: string;
  division: string;
  range: string;
  section: string;
  beat: string;
  totalDeath: string;
  calf: string;
  female: string;
  male:string;
  tusker: string;
  mukhna: string;
  incidentRemark: string;
  roleId: any;
  juridictionId: any;
  circleId: "";
  divisionId: "";
  rangeId: "";
  sectionId: "";
  beatId: "";
  divisionidtoextend: any;
  elephantpoint : any;
  elephantDeathpoint: any;
  elephantpointdirect_fire: any;
  elephantpointdirect_death: any;
  deleteSelectedRecord: any;
  deleteReportType: any;
  loginuserid: any;

  constructor( public datepipe: DatePipe,private formBuilder: FormBuilder,private notifyService: NotificationService,private router: Router, private apiService: ApiService) {}

  ngOnInit(): void {
    this.circleid_incident="0";
    this.divid_incident="0";
    this.rangeid_incident="0";
    this.sectionid_incident="0";
    this.beatid_incident="0";

    this.circleid_elepDeath="0";
    this.divid_elepDeath="0";
    this.rangeid_elepDeath="0";
    this.sectionid_elepDeath="0";
    this.beatid_elepDeath="0";

    this.circleid_humanDeath="0";
    this.divid_humanDeath="0";
    this.rangeid_humanDeath="0";
    this.sectionid_humanDeath="0";
    this.beatid_humanDeath="0";

    this.circleid_humanInjury="0";
    this.divid_humanInjury="0";
    this.rangeid_humanInjury="0";
    this.sectionid_humanInjury="0";
    this.beatid_humanInjury="0";

    this.circleid_tigerDeath="0";
    this.divid_tigerDeath="0";
    this.rangeid_tigerDeath="0";
    this.sectionid_tigerDeath="0";
    this.beatid_tigerDeath="0";

    this.circleid_leopardDeath="0";
    this.divid_leopardDeath="0";
    this.rangeid_leopardDeath="0";
    this.sectionid_leopardDeath="0";
    this.beatid_leopardDeath="0";

    this.circleid_pangolinDeath="0";
    this.divid_pangolinDeath="0";
    this.rangeid_pangolinDeath="0";
    this.sectionid_pangolinDeath="0";
    this.beatid_pangolinDeath="0";
  

    this.incidentForm= this.formBuilder.group({
      incident_circle:new FormControl({value: '', disabled: false}),
      incident_division:new FormControl({value: '', disabled: false}),
      incident_range:new FormControl({value: '', disabled: false}),
      incident_section:[''],
      incident_beat:[''],
      incident_startDate:[''],
      incident_endDate:['']
    });
    this.elepDeathForm= this.formBuilder.group({
      elepDeath_circle:new FormControl({value: '', disabled: false}),
      elepDeath_division:new FormControl({value: '', disabled: false}),
      elepDeath_range:new FormControl({value: '', disabled: false}),
      elepDeath_section:[''],
      elepDeath_beat:[''],
      elepDeath_startDate:[''],
      elepDeath_endDate:['']
    });
    this.humanDeathForm= this.formBuilder.group({
      humanDeath_circle:new FormControl({value: '', disabled: false}),
      humanDeath_division:new FormControl({value: '', disabled: false}),
      humanDeath_range:new FormControl({value: '', disabled: false}),
      humanDeath_section:[''],
      humanDeath_beat:[''],
      humanDeath_startDate:[''],
      humanDeath_endDate:['']
    });
    this.humanInjuryForm= this.formBuilder.group({
      humanInjury_circle:new FormControl({value: '', disabled: false}),
      humanInjury_division:new FormControl({value: '', disabled: false}),
      humanInjury_range:new FormControl({value: '', disabled: false}),
      humanInjury_section:[''],
      humanInjury_beat:[''],
      humanInjury_startDate:[''],
      humanInjury_endDate:['']
    });
    this.tigerDeathForm= this.formBuilder.group({
      tigerDeath_circle:new FormControl({value: '', disabled: false}),
      tigerDeath_division:new FormControl({value: '', disabled: false}),
      tigerDeath_range:new FormControl({value: '', disabled: false}),
      tigerDeath_section:[''],
      tigerDeath_beat:[''],
      tigerDeath_startDate:[''],
      tigerDeath_endDate:['']
    });
    this.leopardDeathForm= this.formBuilder.group({
      leopardDeath_circle:new FormControl({value: '', disabled: false}),
      leopardDeath_division:new FormControl({value: '', disabled: false}),
      leopardDeath_range:new FormControl({value: '', disabled: false}),
      leopardDeath_section:[''],
      leopardDeath_beat:[''],
      leopardDeath_startDate:[''],
      leopardDeath_endDate:['']
    });
    this.pangolinDeathForm= this.formBuilder.group({
      pangolinDeath_circle:new FormControl({value: '', disabled: false}),
      pangolinDeath_division:new FormControl({value: '', disabled: false}),
      pangolinDeath_range:new FormControl({value: '', disabled: false}),
      pangolinDeath_section:[''],
      pangolinDeath_beat:[''],
      pangolinDeath_startDate:[''],
      pangolinDeath_endDate:['']
    });

    this.reporteditForm= this.formBuilder.group({
      location:[''],
      calf:[0,[
        Validators.required,           
        Validators.pattern('^[0-9]*$') 
      ]],
      female:[0,[
          Validators.required,          
          Validators.pattern('^[0-9]*$') 
        ]],
      tusker:[0,[
          Validators.required,          
          Validators.pattern('^[0-9]*$') 
        ]],
      mukhna:[0,[
          Validators.required,          
          Validators.pattern('^[0-9]*$') 
        ]],
      remarks:['']
     
    });

    this.dtOptions = {
      // pagingType: 'full_numbers',
      // pageLength: 5,
      // lengthMenu : [5, 10, 25],
      // processing: true,
      // responsive: true,
      dom: 'Bfrtip',
         buttons: [
             'copy', 'excel', 'pdf', 'print'
         ]
    };
    var ds = this ;
  	(function($){
        $('a[data-toggle="tab"]').on('shown.bs.tab', function(e){
            $($.fn.dataTable.tables(true)).DataTable()
                .columns.adjust()
                .responsive.recalc();
        });
        $('#mapFireRecordModal').on('shown.bs.modal', function () {
          if(ds.mapFire == null)
          {
            ds.inhisiatemapFire();
            ds.letzoomin(ds.thelatitude, ds.thelongitude,"Fire",ds.divisionidtoextend);
          }
          else
          {
            ds.letzoomin(ds.thelatitude, ds.thelongitude,"Fire",ds.divisionidtoextend);
          }
        });
        $('#mapElephantDeathRecordModal').on('shown.bs.modal', function () {
          if(ds.mapDeath== null)
          {
            ds.inhisiatemapDeath('emelephDeath');
            ds.letzoomin(ds.thelatitude, ds.thelongitude,"Death",ds.divisionidtoextend);
          }
          else
          {
            ds.letzoomin(ds.thelatitude, ds.thelongitude,"Death",ds.divisionidtoextend);
          }
        });
        $('#mapTigerDeathRecordModal').on('shown.bs.modal', function () {
          if(ds.mapDeath== null)
          {
            ds.inhisiatemapDeath('tigerDeath');
            ds.letzoomin(ds.thelatitude, ds.thelongitude,"Death",ds.divisionidtoextend);
          }
          else
          {
            ds.letzoomin(ds.thelatitude, ds.thelongitude,"Death",ds.divisionidtoextend);
          }
        });

        var day1before = new Date();
        $(function () {
            $("#datepicker8,#datepicker10,#datepicker12,#datepicker14,#datepicker16,#datepicker18").datepicker({
                  autoclose: true,
                  todayHighlight: true
            }).datepicker('update', new Date());
            $("#datepicker7, #datepicker9,#datepicker11,#datepicker13,#datepicker15,#datepicker17").datepicker({
              autoclose: true,
              todayHighlight: true
            }).datepicker('update', new Date(day1before.setDate(day1before.getDate() - 1)));
          
        });
    })(jQuery);
  //this.loader=true;//set loader for visible
  const userdata=sessionStorage.getItem('user');
  if(userdata!=null){
    const user=JSON.parse(userdata);
    this.roleId=user.roleId;
    this.loginuserid=user.userid;
    if(user.roleId==1){
      this.isAdmin=true;
    }
    this.juridictionId=user.juridictionId;
    this.circleId=user.circleId;
    this.divisionId=user.divisionId;
    this.rangeId=user.rangeId;
    this.sectionId=user.sectionId;
    this.beatId=user.beatId;
    this.getAllCircle();
    this.getElepDeathMovementRecord();
  }else{
    this.router.navigate(['']);
   // this.loader=false;
    return;
  }
  }
  async isCircleDivisionRangeDisabled(){
      if(this.roleId==1 || this.roleId==3){
        this.circleDisabled=false;
        this.divisionDisabled=false;
        this.rangeDisabled=false;
      }
      else if(this.roleId==6){
        this.circleDisabled=true;
        this.incidentForm.get('incident_circle').disable();
        this.elepDeathForm.get('elepDeath_circle').disable();
        this.humanDeathForm.get('humanDeath_circle').disable();
        this.humanInjuryForm.get('humanInjury_circle').disable();
        this.tigerDeathForm.get('tigerDeath_circle').disable();
        this.leopardDeathForm.get('leopardDeath_circle').disable();
        this.pangolinDeathForm.get('pangolinDeath_circle').disable();
      }
      else if(this.roleId==2)
      {
        this.incidentForm.get('incident_circle').disable();
        this.elepDeathForm.get('elepDeath_circle').disable();
        this.humanDeathForm.get('humanDeath_circle').disable();
        this.humanInjuryForm.get('humanInjury_circle').disable();
        this.tigerDeathForm.get('tigerDeath_circle').disable();
        this.leopardDeathForm.get('leopardDeath_circle').disable();
        this.pangolinDeathForm.get('pangolinDeath_circle').disable();
        this.circleDisabled=true;

        this.circleid_incident=this.circleId;
        this.circleid_elepDeath=this.circleId;
        this.circleid_humanDeath=this.circleId;
        this.circleid_humanInjury=this.circleId;
        this.circleid_tigerDeath=this.circleId;
        this.circleid_leopardDeath=this.circleId;
        this.circleid_pangolinDeath=this.circleId;

        this.circleSelected_incident=this.circleId;
        this.circleSelected_elepDeath=this.circleId;
        this.circleSelected_humanDeath=this.circleId;
        this.circleSelected_humanInjury=this.circleId;
        this.circleSelected_tigerDeath=this.circleId;
        this.circleSelected_leopardDeath=this.circleId;
        this.circleSelected_pangolinDeath=this.circleId;
        this.getAllDivisionByCircleId(this.circleId);

        //this.viewDashboardinfo();
        //this.reporttable();
      }
      else if(this.roleId==4 || this.roleId==5 )
      {
          this.getAllDivisionByCircleId(this.circleId);
            this.circleDisabled=true;
            this.incidentForm.get('incident_circle').disable();
            this.elepDeathForm.get('elepDeath_circle').disable();
            this.humanDeathForm.get('humanDeath_circle').disable();
            this.humanInjuryForm.get('humanInjury_circle').disable();
            this.tigerDeathForm.get('tigerDeath_circle').disable();
            this.leopardDeathForm.get('leopardDeath_circle').disable();
            this.pangolinDeathForm.get('pangolinDeath_circle').disable();

            this.circleid_incident=this.circleId;
            this.circleid_elepDeath=this.circleId;
            this.circleid_humanDeath=this.circleId;
            this.circleid_humanInjury=this.circleId;
            this.circleid_tigerDeath=this.circleId;
            this.circleid_leopardDeath=this.circleId;
            this.circleid_pangolinDeath=this.circleId;

            this.circleSelected_incident=this.circleId;
            this.circleSelected_elepDeath=this.circleId;
            this.circleSelected_humanDeath=this.circleId;
            this.circleSelected_humanInjury=this.circleId;
            this.circleSelected_tigerDeath=this.circleId;
            this.circleSelected_leopardDeath=this.circleId;
            this.circleSelected_pangolinDeath=this.circleId;
      }
  }
  getAllCircle() {
    return this.apiService.request('GET', 'api/v1/masters/getAllCircle').subscribe((data: any) => {
	    if(data)
	    {
        this.circleList=data;
        this.isCircleDivisionRangeDisabled();
        if(this.divisionlist!=""){
          this.divisionlist=[];
          this.rangelist=[];
          this.sectionlist=[];
          this.beatlist=[];
          this.divid_incident="0";
          this.divid_elepDeath="0";
          this.divid_humanDeath="0";
          this.divid_humanInjury="0";
          this.divid_tigerDeath="0";
          this.divid_leopardDeath="0";
          this.divid_pangolinDeath="0";
        }
      }
    });
  }
   getAllDivisionByCircleId(circle:any){
    if(circle!=0){
       this.apiService.request('GET', 'api/v1/masters/getAllDivisionByCircleId?id='+circle).subscribe((data: any) => {
        if(data)
        {
           this.divisionlist=data;
          if(this.divisionId!=""){
            this.getAllRangeByDivId(this.divisionId);
          }
           if(this.rangelist!=""){
            this.rangelist=[];
            this.sectionlist=[];
            this.beatlist=[];
             this.rangeid_incident="0";
             this.rangeid_elepDeath="0";
             this.rangeid_humanDeath="0";
             this.rangeid_humanInjury="0";
             this.rangeid_tigerDeath="0";
             this.rangeid_leopardDeath="0";
             this.rangeid_pangolinDeath="0";
            
           }
        }
      });
    }else{
      if(this.divisionlist!=""){
        this.divisionlist=[];
        this.rangelist=[];
        this.sectionlist=[];
        this.beatlist=[];
       }
       this.circleSelected_humanDeath="All";
       this.circleSelected_humanInjury="All";
       this.circleSelected_elepDeath="All";
       this.circleSelected_incident="All";
       this.circleSelected_tigerDeath="All";
       this.circleSelected_pangolinDeath="All";
       this.circleSelected_leopardDeath="All";

        this.divid_humanDeath="0";
        this.divid_humanInjury="0";
        this.divid_elepDeath="0";
        this.divid_incident="0";
        this.divid_tigerDeath="0";
        this.divid_leopardDeath="0";
        this.divid_pangolinDeath="0";
    }
  }
   getAllRangeByDivId(div:any) {
    if(div!=0){
      this.apiService.request('GET', 'api/v1/masters/getAllRangeByDivid?id='+div).subscribe((data: any) => {
	    if(data)
	    {
        this.rangelist=data;
        if(this.divisionId!=""){
          this.divisionDisabled=true;
          this.incidentForm.get('incident_division').disable();
          this.elepDeathForm.get('elepDeath_division').disable();
          this.humanDeathForm.get('humanDeath_division').disable();
          this.humanInjuryForm.get('humanInjury_division').disable();
          this.tigerDeathForm.get('tigerDeath_division').disable();
          this.leopardDeathForm.get('leopardDeath_division').disable();
          this.pangolinDeathForm.get('pangolinDeath_division').disable();

          this.divid_humanDeath=this.divisionId;
          this.divid_humanInjury=this.divisionId;
          this.divid_incident=this.divisionId;
          this.divid_elepDeath=this.divisionId;
          this.divid_tigerDeath=this.divisionId;
          this.divid_leopardDeath=this.divisionId;
          this.divid_pangolinDeath=this.divisionId;

          this.divisionSelected_humanDeath=this.divisionId;
          this.divisionSelected_humanInjury=this.divisionId;
          this.divisionSelected_incident=this.divisionId;
          this.divisionSelected_elepDeath=this.divisionId;
          this.divisionSelected_tigerDeath=this.divisionId;
          this.divisionSelected_leopardDeath=this.divisionId;
          this.divisionSelected_pangolinDeath=this.divisionId;
        }
        if(this.rangeId!=""){
          this.getAllSectionByRangeId(this.rangeId);
        }
          if(this.sectionlist!=""){
            this.sectionid_humanDeath="0";
            this.sectionid_humanInjury="0";
            this.sectionid_incident="0";
            this.sectionid_elepDeath="0";
            this.sectionid_tigerDeath="0";
            this.sectionid_leopardDeath="0";
            this.sectionid_pangolinDeath="0";
            this.sectionlist=[];
            this.beatlist=[];
         }
      }
   });
     }else{
    if(this.rangelist!=""){
      this.rangelist=[];
      this.sectionlist=[];
      this.beatlist=[];
     }
       this.rangeid_humanDeath="0";
       this.rangeid_humanInjury="0";
       this.rangeid_incident="0";
       this.rangeid_elepDeath="0";
       this.rangeid_tigerDeath="0";
       this.rangeid_leopardDeath="0";
       this.rangeid_pangolinDeath="0";
    }
  }
  getAllSectionByRangeId(range:any) {
    if(range!=0){
     this.apiService.request('GET', 'api/v1/masters/getAllSectionByRangeid?id='+range).subscribe((data: any) => {
	    if(data)
	    {
        this.sectionlist=data;
        this.sectionid_humanDeath="0";
        this.sectionid_humanInjury="0";
        this.sectionid_incident="0";
         this.sectionid_elepDeath="0";
         this.sectionid_tigerDeath="0";
         this.sectionid_leopardDeath="0";
         this.sectionid_pangolinDeath="0";
         if(this.rangeId!=""){
          this.rangeid_humanDeath=this.rangeId;
          this.rangeid_humanInjury=this.rangeId;
          this.rangeid_incident=this.rangeId;
          this.rangeid_elepDeath=this.rangeId;
          this.rangeid_tigerDeath=this.rangeId;
          this.rangeid_leopardDeath=this.rangeId;
          this.rangeid_pangolinDeath=this.rangeId;

          this.rangeSelected_humanDeath=this.rangeId;
          this.rangeSelected_humanInjury=this.rangeId;
          this.rangeSelected_elepDeath=this.rangeId;
          this.rangeSelected_tigerDeath=this.rangeId;
          this.rangeSelected_leopardDeath=this.rangeId;
          this.rangeSelected_pangolinDeath=this.rangeId;
          this.rangeSelected_incident=this.rangeId;

          this.rangeDisabled=true;
          this.humanDeathForm.get('humanDeath_range').disable();
          this.humanInjuryForm.get('humanInjury_range').disable();
          this.incidentForm.get('incident_range').disable();
          this.elepDeathForm.get('elepDeath_range').disable();
          this.tigerDeathForm.get('tigerDeath_range').disable();
          this.leopardDeathForm.get('leopardDeath_range').disable();
          this.pangolinDeathForm.get('pangolinDeath_range').disable();
         }
        if(this.beatlist!=""){
          this.beatlist=[];
          this.beatid_humanDeath="0";
          this.beatid_humanInjury="0";
          this.beatid_incident="0";
          this.beatid_elepDeath="0";
          this.beatid_tigerDeath="0";
          this.beatid_leopardDeath="0";
          this.beatid_pangolinDeath="0";
        }
      }
    });
  }else{
    if(this.sectionlist!=""){
      this.sectionlist=[];
      this.beatlist=[];
     }
     this.sectionid_humanDeath="0";
     this.sectionid_humanInjury="0";
     this.sectionid_incident="0";
     this.sectionid_elepDeath="0";
     this.sectionid_tigerDeath="0";
     this.sectionid_leopardDeath="0";
     this.sectionid_pangolinDeath="0";
    }
  }
  getAllBeatBySectionId(section:any) {
    if(section!=0){
    this.apiService.request('GET', 'api/v1/masters/getAllBeatBySecId?id='+section).subscribe((data: any) => {
	    if(data)
	    {
        this.beatlist=data;
        this.beatid_humanDeath="0";
        this.beatid_humanInjury="0";
        this.beatid_incident="0";
        this.beatid_elepDeath="0";
        this.beatid_tigerDeath="0";
        this.beatid_leopardDeath="0";
        this.beatid_pangolinDeath="0";
      }
    });
  }else{
    if(this.beatlist!=""){
      this.beatlist=[];
     }

    }
  }
  getCurentDate(){
    var Yestrerdaywithtime;
		var Todaywithtime;
    var mydate = new Date();
		var yester = new Date();
    yester = new Date(yester.setDate(yester.getDate() - 1));
    if (mydate.getMonth() < 9){
      Todaywithtime = mydate.getFullYear() + "-0" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
    }else{
      Todaywithtime = mydate.getFullYear() + "-" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
    }if(yester.getMonth() < 9){
      Yestrerdaywithtime = yester.getFullYear() + "-0" + (yester.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
    }else{
      Yestrerdaywithtime = yester.getFullYear() + "-" + (yester.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
    }
   if(this.startDateSelected_incident==""){this.startDateSelected_incident=Yestrerdaywithtime;}
   if(this.endDateSelected_incident==""){this.endDateSelected_incident=Todaywithtime;}
   if(this.startDateSelected_elepDeath==""){ this.startDateSelected_elepDeath=Yestrerdaywithtime;}
   if(this.endDateSelected_elepDeath==""){this.endDateSelected_elepDeath=Todaywithtime;}
   if(this.startDateSelected_humanDeath==""){ this.startDateSelected_humanDeath=Yestrerdaywithtime;}
   if(this.endDateSelected_humanDeath==""){this.endDateSelected_humanDeath=Todaywithtime;}
   if(this.startDateSelected_humanInjury==""){ this.startDateSelected_humanInjury=Yestrerdaywithtime;}
   if(this.endDateSelected_humanInjury==""){this.endDateSelected_humanInjury=Todaywithtime;}
   if(this.startDateSelected_tigerDeath==""){ this.startDateSelected_tigerDeath=Yestrerdaywithtime;}
   if(this.endDateSelected_tigerDeath==""){this.endDateSelected_tigerDeath=Todaywithtime;}
   if(this.startDateSelected_leopardDeath==""){ this.startDateSelected_leopardDeath=Yestrerdaywithtime;}
   if(this.endDateSelected_leopardDeath==""){this.endDateSelected_leopardDeath=Todaywithtime;}
   if(this.startDateSelected_pangolinDeath==""){ this.startDateSelected_pangolinDeath=Yestrerdaywithtime;}
   if(this.endDateSelected_pangolinDeath==""){this.endDateSelected_pangolinDeath=Todaywithtime;}
  }
  getIncidentMovementRecord(){
    if(this.circleId.toString()!="0" ){this.circleSelected_incident=this.circleId.toString();}
    if(this.divisionId.toString()!="0"){this.divisionSelected_incident=this.divisionId.toString();}
    if(this.rangeId.toString()!="0"){this.rangeSelected_incident=this.rangeId.toString();}
  //  this.loader=true;
    this.getCurentDate();
    return this.apiService.request('GET', 'api/v1/IncidentReport/viewAllIncidentReportzz?circlreId='+this.circleSelected_incident+'&divisionId='+this.divisionSelected_incident+'&rangeId='+this.rangeSelected_incident+'&sectionId='+this.sectionSelected_incident+'&beatId='+this.beatSelected_incident+'&startDate='+this.startDateSelected_incident+'&endDate='+this.endDateSelected_incident+'&reportType=fire').subscribe((data: any) => {
    if(data){

       $("#IncidentTable").DataTable().destroy();
        this.tabledata4=data;
        this.dtTrigger4.next();
      }
    });
  }
  getElepDeathMovementRecord(){
    if(this.circleId.toString()!="0" ){this.circleSelected_elepDeath=this.circleId.toString();}
    if(this.divisionId.toString()!="0"){this.divisionSelected_elepDeath=this.divisionId.toString();}
    if(this.rangeId.toString()!="0"){this.rangeSelected_elepDeath=this.rangeId.toString();}
   // this.loader=true;
    this.getCurentDate();
    return this.apiService.request('GET', 'api/v1/IncidentReport/viewAllElephantDeathReportzz?circlreId='+this.circleSelected_elepDeath+'&divisionId='+this.divisionSelected_elepDeath+'&rangeId='+this.rangeSelected_elepDeath+'&sectionId='+this.sectionSelected_elepDeath+'&beatId='+this.beatSelected_elepDeath+'&startDate='+this.startDateSelected_elepDeath+'&endDate='+this.endDateSelected_elepDeath).subscribe((data: any) => {
    if(data){
      $("#ElepDeathTable").DataTable().destroy();
        this.tabledata5=data;
        this.dtTrigger5.next();
      }
    });
  }
  getTigerDeathRecord(){
    if(this.circleId.toString()!="0" ){this.circleSelected_tigerDeath=this.circleId.toString();}
    if(this.divisionId.toString()!="0"){this.divisionSelected_tigerDeath=this.divisionId.toString();}
    if(this.rangeId.toString()!="0"){this.rangeSelected_tigerDeath=this.rangeId.toString();}
    this.getCurentDate();
    return this.apiService.request('GET', 'api/v1/IncidentReport/viewAllTigerDeathReportzz?circlreId='+this.circleSelected_tigerDeath+'&divisionId='+this.divisionSelected_tigerDeath+'&rangeId='+this.rangeSelected_tigerDeath+'&sectionId='+this.sectionSelected_tigerDeath+'&beatId='+this.beatSelected_tigerDeath+'&startDate='+this.startDateSelected_tigerDeath+'&endDate='+this.endDateSelected_tigerDeath).subscribe((data: any) => {
    if(data){
     // console.log(data);
        $("#TigerDeathTable").DataTable().destroy();
        this.tabledata7=data;
        this.dtTrigger7.next();
      }
    });
  }
  getLeopardDeathRecord(){
    if(this.circleId.toString()!="0" ){this.circleSelected_leopardDeath=this.circleId.toString();}
    if(this.divisionId.toString()!="0"){this.divisionSelected_leopardDeath=this.divisionId.toString();}
    if(this.rangeId.toString()!="0"){this.rangeSelected_leopardDeath=this.rangeId.toString();}
    this.getCurentDate();
    return this.apiService.request('GET', 'api/v1/IncidentReport/viewAllLeopardDeathReportzz?circlreId='+this.circleSelected_leopardDeath+'&divisionId='+this.divisionSelected_leopardDeath+'&rangeId='+this.rangeSelected_leopardDeath+'&sectionId='+this.sectionSelected_leopardDeath+'&beatId='+this.beatSelected_leopardDeath+'&startDate='+this.startDateSelected_leopardDeath+'&endDate='+this.endDateSelected_leopardDeath).subscribe((data: any) => {
    if(data){
      $("#LeopardDeathTable").DataTable().destroy();
        this.tabledata8=data;
        this.dtTrigger8.next();
      }
    });
  }
  getPangolinDeathRecord(){
    if(this.circleId.toString()!="0" ){this.circleSelected_pangolinDeath=this.circleId.toString();}
    if(this.divisionId.toString()!="0"){this.divisionSelected_pangolinDeath=this.divisionId.toString();}
    if(this.rangeId.toString()!="0"){this.rangeSelected_pangolinDeath=this.rangeId.toString();}
   // this.loader=true;
    this.getCurentDate();
    return this.apiService.request('GET', 'api/v1/IncidentReport/viewAllPangolinDeathReportzz?circlreId='+this.circleSelected_pangolinDeath+'&divisionId='+this.divisionSelected_pangolinDeath+'&rangeId='+this.rangeSelected_pangolinDeath+'&sectionId='+this.sectionSelected_pangolinDeath+'&beatId='+this.beatSelected_pangolinDeath+'&startDate='+this.startDateSelected_pangolinDeath+'&endDate='+this.endDateSelected_pangolinDeath).subscribe((data: any) => {
    if(data){
      $("#pangolinDeathTable").DataTable().destroy();
        this.tabledata9=data;
        this.dtTrigger9.next();
      }
    });
  }
  getHumanDeathRecord(){
    if(this.circleId.toString()!="0" ){this.circleSelected_humanDeath=this.circleId.toString();}
    if(this.divisionId.toString()!="0"){this.divisionSelected_humanDeath=this.divisionId.toString();}
    if(this.rangeId.toString()!="0"){this.rangeSelected_humanDeath=this.rangeId.toString();}
    this.getCurentDate();
    return this.apiService.request('GET', 'api/v1/IncidentReport/viewAllhumanDeathReport?circleId='+this.circleSelected_humanDeath+'&divisionId='+this.divisionSelected_humanDeath+'&startDate='+this.startDateSelected_humanDeath+'&endDate='+this.endDateSelected_humanDeath).subscribe((data: any) => {
    if(data){
      $("#humanDeathTable").DataTable().destroy();
              this.tabledata6=data;
        this.dtTrigger6.next();
      }
    });
  }
  getHumanInjuryRecord(){
    if(this.circleId.toString()!="0" ){this.circleSelected_humanInjury=this.circleId.toString();}
    if(this.divisionId.toString()!="0"){this.divisionSelected_humanInjury=this.divisionId.toString();}
    if(this.rangeId.toString()!="0"){this.rangeSelected_humanInjury=this.rangeId.toString();}
    this.getCurentDate();
    return this.apiService.request('GET', 'api/v1/IncidentReport/viewAllhumanDeathReport?circleId='+this.circleSelected_humanDeath+'&divisionId='+this.divisionSelected_humanDeath+'&startDate='+this.startDateSelected_humanDeath+'&endDate='+this.endDateSelected_humanDeath).subscribe((data: any) => {
    if(data){
      $("#humanInjuryTable").DataTable().destroy();
              this.tabledata10=data;
        this.dtTrigger10.next();
      }
    });
  }
  loadtable(value:any){
    if(this.divisionlist!=""){
      if(this.circleId==""){
        this.divisionlist=[];
        this.rangelist=[];
        this.sectionlist=[];
        this.beatlist=[];
        this.circleid_incident="0";
        this.circleid_elepDeath="0";
        this.circleid_humanDeath="0";
        this.circleid_humanInjury="0";
        this.circleid_tigerDeath="0";
        this.circleid_leopardDeath="0";
        this.circleid_pangolinDeath="0";

        this.divid_humanInjury="0";
        this.divid_humanDeath="0";
        this.divid_elepDeath="0";
        this.divid_incident="0";
        this.divid_tigerDeath="0";
        this.divid_leopardDeath="0";
        this.divid_pangolinDeath="0";
        
        this.rangeid_humanDeath="0";
        this.rangeid_humanInjury="0";
        this.rangeid_elepDeath="0";
        this.rangeid_incident="0";
        this.rangeid_tigerDeath="0";
        this.rangeid_leopardDeath="0";
        this.rangeid_pangolinDeath="0";

        this.sectionid_humanDeath="0";
        this.sectionid_humanInjury="0";
        this.sectionid_elepDeath="0";
        this.sectionid_incident="0";
        this.sectionid_tigerDeath="0";
        this.sectionid_leopardDeath="0";
        this.sectionid_pangolinDeath="0";

        this.beatid_humanDeath="0";
        this.beatid_humanInjury="0";
        this.beatid_elepDeath="0";
        this.beatid_incident="0";
        this.beatid_tigerDeath="0";
        this.beatid_leopardDeath="0";
        this.beatid_pangolinDeath="0";
      }
     }

   if(value=="0"){
    $("#ElepDeathTable").DataTable().destroy();
    this.getElepDeathMovementRecord();
    }
    else if(value=="1"){
      $("#IncidentTable").DataTable().destroy();
      this.getIncidentMovementRecord();
    }
    else if(value=="2"){
      $("#humanDeathTable").DataTable().destroy();
      this.getHumanDeathRecord();
    } 
    else if(value=="3"){
      $("#TigerDeathTable").DataTable().destroy();
      this.getTigerDeathRecord();
    }
    else if(value=="4"){
      $("#LeopardDeathTable").DataTable().destroy();
      this.getLeopardDeathRecord();
    }
    else if(value=="5"){
      $("#PangolinDeathTable").DataTable().destroy();
      this.getPangolinDeathRecord();
    } else if(value=="6"){
      $("#humanInjuryTable").DataTable().destroy();
      this.getHumanInjuryRecord();
    }
  
  }
  formatDate(date) {
    let dateexct=date.split('-');
    var day = dateexct [0];
    var month = dateexct [1];
    var year = dateexct [2];
    var fullDay=year+'-'+month+'-'+day;
    return fullDay;
  }
  formatDateEdit(date) {
  let dateexct= date.split(' ')[0].split('-');
  var year = dateexct [0];
  var month = dateexct [1];
  var day = dateexct[2].split(' ')[0];
  var fullDay=month+'-'+day+'-'+year;
  return fullDay;
  }
  onSubmitIncidentMovementRecord(value:any){
     // this.loader=true;//set loader for visible
      let startDate_incident = this.sdate_incident.nativeElement.value;
      let endDate_incident= this.edate_incident.nativeElement.value;

      if(startDate_incident!=null){
        startDate_incident=this.formatDate(startDate_incident);
        this.startDateSelected_incident=startDate_incident;
      }else{
        alert("Please Provide Start date");
      //  this.loader=false;
        return false;
      }
      if(endDate_incident!=null){
        endDate_incident=this.formatDate(endDate_incident);
       this.endDateSelected_incident=endDate_incident;
      }else{
        alert("Please Provide End date")
       // this.loader=false;
        return false;
      }
      //alert(this.startDateSelected_incident)
      //alert(this.endDateSelected_incident)
      if(this.startDateSelected_incident>this.endDateSelected_incident){

        alert("Start date should be greater then End date.")
      //  this.loader=false;
        return false;
      }
      if(value.incident_circle!="0"){this.circleSelected_incident=value.incident_circle;}
      if(value.incident_division!="0"){this.divisionSelected_incident=value.incident_division;}
      if(value.incident_range!="0"){this.rangeSelected_incident=value.incident_range;}
      if(value.incident_section!="0"){this.sectionSelected_incident=value.incident_section;}
      if(value.incident_beat!="0"){this.beatSelected_incident=value.incident_beat;}
      $("#IncidentTable").DataTable().destroy();
       this.getIncidentMovementRecord();
      // this.loader=false;
  }
  onSubmitElepDeathMovementRecord(value:any){
   // this.loader=true;//set loader for visible
    let startDate_elepDeath = this.sdate_elepDeath.nativeElement.value;
    let endDate_elepDeath= this.edate_elepDeath.nativeElement.value;
    if(startDate_elepDeath!=null){
      this.startDateSelected_elepDeath=startDate_elepDeath;
    }else{
      alert("Please Provide Start date")
    //  this.loader=false;
      return false;
    }
    if(endDate_elepDeath!=null){
      this.endDateSelected_elepDeath=endDate_elepDeath;
    }else{
      alert("Please Provide End date")
    //  this.loader=false;
      return false;
    }
    if(startDate_elepDeath>endDate_elepDeath){
      alert("Start date should be greater then End date.")
    //  this.loader=false;
      return false;
    }
    if(value.elepDeath_circle!="0"){this.circleSelected_elepDeath=value.elepDeath_circle;}
    if(value.elepDeath_division!="0"){this.divisionSelected_elepDeath=value.elepDeath_division;}
    if(value.elepDeath_range!="0"){this.rangeSelected_elepDeath=value.elepDeath_range;}
    if(value.elepDeath_section!="0"){this.sectionSelected_elepDeath=value.elepDeath_section;}
    if(value.elepDeath_beat!="0"){this.beatSelected_elepDeath=value.elepDeath_beat;}
    $("#ElepDeathTable").DataTable().destroy();
     this.getElepDeathMovementRecord();
          //this.loader=false;
  }
  onSubmitTigerDeathRecordForm(value:any){
     let startDate_tigerDeath = this.sdate_tigerDeath.nativeElement.value;
     let endDate_tigerDeath= this.edate_tigerDeath.nativeElement.value;
     if(startDate_tigerDeath!=null){
       this.startDateSelected_tigerDeath=startDate_tigerDeath;
     }else{
       alert("Please Provide Start date")
       return false;
     }
     if(endDate_tigerDeath!=null){
       this.endDateSelected_tigerDeath=endDate_tigerDeath;
     }else{
       alert("Please Provide End date")
       return false;
     }
     if(startDate_tigerDeath>endDate_tigerDeath){
       alert("Start date should be greater then End date.");
       return false;
     }
     if(value.tigerDeath_circle!="0"){this.circleSelected_tigerDeath=value.tigerDeath_circle;}
     if(value.tigerDeath_division!="0"){this.divisionSelected_tigerDeath=value.tigerDeath_division;}
     if(value.tigerDeath_range!="0"){this.rangeSelected_tigerDeath=value.tigerDeath_range;}
     if(value.tigerDeath_section!="0"){this.sectionSelected_tigerDeath=value.tigerDeath_section;}
     if(value.tigerDeath_beat!="0"){this.beatSelected_tigerDeath=value.tigerDeath_beat;}
     $("#TigerDeathTable").DataTable().destroy();
      this.getTigerDeathRecord();
  }
  onSubmitLeopardDeathRecordForm(value:any){
    let startDate_leopardDeath = this.sdate_leopardDeath.nativeElement.value;
    let endDate_leopardDeath= this.edate_leopardDeath.nativeElement.value;
    if(startDate_leopardDeath!=null){
      this.startDateSelected_leopardDeath=startDate_leopardDeath;
    }else{
      alert("Please Provide Start date")
      return false;
    }
    if(endDate_leopardDeath!=null){
      this.endDateSelected_leopardDeath=endDate_leopardDeath;
    }else{
      alert("Please Provide End date")
      return false;
    }
    if(startDate_leopardDeath>endDate_leopardDeath){
      alert("Start date should be greater then End date.");
      return false;
    }
    if(value.leopardDeath_circle!="0"){this.circleSelected_leopardDeath=value.leopardDeath_circle;}
    if(value.leopardDeath_division!="0"){this.divisionSelected_leopardDeath=value.leopardDeath_division;}
    if(value.leopardDeath_range!="0"){this.rangeSelected_leopardDeath=value.leopardDeath_range;}
    if(value.leopardDeath_section!="0"){this.sectionSelected_leopardDeath=value.leopardDeath_section;}
    if(value.leopardDeath_beat!="0"){this.beatSelected_leopardDeath=value.leopardDeath_beat;}
    $("#LeopardDeathTable").DataTable().destroy();
     this.getLeopardDeathRecord();
  }
  onSubmitPangolinDeathRecordForm(value:any){
    let startDate_pangolinDeath = this.sdate_pangolinDeath.nativeElement.value;
    let endDate_pangolinDeath= this.edate_pangolinDeath.nativeElement.value;
    if(startDate_pangolinDeath!=null){
      this.startDateSelected_pangolinDeath=startDate_pangolinDeath;
    }else{
      alert("Please Provide Start date")
      return false;
    }
    if(endDate_pangolinDeath!=null){
      this.endDateSelected_pangolinDeath=endDate_pangolinDeath;
    }else{
      alert("Please Provide End date")
      return false;
    }
    if(startDate_pangolinDeath>endDate_pangolinDeath){
      alert("Start date should be greater then End date.");
      return false;
    }
    if(value.pangolinDeath_circle!="0"){this.circleSelected_pangolinDeath=value.pangolinDeath_circle;}
    if(value.pangolinDeath_division!="0"){this.divisionSelected_pangolinDeath=value.pangolinDeath_division;}
    if(value.pangolinDeath_range!="0"){this.rangeSelected_pangolinDeath=value.pangolinDeath_range;}
    if(value.pangolinDeath_section!="0"){this.sectionSelected_pangolinDeath=value.pangolinDeath_section;}
    if(value.pangolinDeath_beat!="0"){this.beatSelected_pangolinDeath=value.pangolinDeath_beat;}
    $("#PangolinDeathTable").DataTable().destroy();
     this.getPangolinDeathRecord();
  }
  onSubmithumanDeathMovementRecord(value:any){
    let startDate_humanDeath = this.sdate_humanDeath.nativeElement.value;
    let endDate_humanDeath= this.edate_humanDeath.nativeElement.value;
    if(startDate_humanDeath!=null){
      this.startDateSelected_humanDeath=startDate_humanDeath;
    }else{
      alert("Please Provide Start date")
    //  this.loader=false;
      return false;
    }
    if(endDate_humanDeath!=null){
      this.endDateSelected_humanDeath=endDate_humanDeath;
    }else{
      alert("Please Provide End date")
    //  this.loader=false;
      return false;
    }
    if(startDate_humanDeath>endDate_humanDeath){
      alert("Start date should be greater then End date.")
    //  this.loader=false;
      return false;
    }
    if(value.humanDeath_circle!="0"){this.circleSelected_humanDeath=value.humanDeath_circle;}
    if(value.humanDeath_division!="0"){this.divisionSelected_humanDeath=value.humanDeath_division;}
    $("#humanDeathTable").DataTable().destroy();
    this.getHumanDeathRecord();
    
  }
  onSubmithumanInjuryMovementRecord(value:any){
    let startDate_humanInjury = this.sdate_humanInjury.nativeElement.value;
    let endDate_humanInjury= this.edate_humanInjury.nativeElement.value;
    if(startDate_humanInjury!=null){
      this.startDateSelected_humanInjury=startDate_humanInjury;
    }else{
      alert("Please Provide Start date")
    //  this.loader=false;
      return false;
    }
    if(endDate_humanInjury!=null){
      this.endDateSelected_humanInjury=endDate_humanInjury;
    }else{
      alert("Please Provide End date")
    //  this.loader=false;
      return false;
    }
    if(startDate_humanInjury>endDate_humanInjury){
      alert("Start date should be greater then End date.")
    //  this.loader=false;
      return false;
    }
    if(value.humanInjury_circle!="0"){this.circleSelected_humanInjury=value.humanInjury_circle;}
    if(value.humanInjury_division!="0"){this.divisionSelected_humanInjury=value.humanInjury_division;}
    $("#humanInjuryTable").DataTable().destroy();
    this.getHumanInjuryRecord();
    
  }
  getDeathRecordByID(report : ReportIncident){
    this.report_Id=report.report_Id;
    this.latitude=report.latitude;
    this.longitude=report.longitude;
    this.location=report.location;
    this.incidentReportDate=report.incidentReport;
    this.incidentPhoto=baseUrlFile+'report/'+report.incidentPhoto;
    //this.incidentPhoto=baseUrl+'api/v1/uploadController/downloadFile?location='+report.incidentPhoto;
    this.accuracy=report.accuracy;
    this.altitude=report.altitude;
    this.circle=report.circle;
    this.division=report.division;
    this.range=report.range;
    this.section=report.section;
    this.beat=report.beat;
    this.totalDeath=report.totalDeath!=null?report.totalDeath:String((Number(report.makhna)+Number(report.tusker)+Number(report.male)+Number(report.female)+Number(report.calf)));
    this.calf=report.calf;
    this.female=report.female;
    this.male=report.male;
    this.tusker=report.tusker;
    this.mukhna=report.makhna;
    this.incidentRemark=report.incidentRemark;
  }
  getDeathRecordByIDForEdit(report : ReportIncident,reportType:any){
     //  console.log(report);
       this.reportIdEdit=report.report_Id;
       this.reportTypeEdit=reportType;
     //  console.log(this.reportIdEdit);
      // console.log(this.reportTypeEdit);
       jQuery("#datepicker7").datepicker({
              autoclose: true,
          }).datepicker('update', new Date(this.formatDateEdit(report.incidentReport)));
         jQuery('.clockpicker1').clockpicker({'default': this.setTimeEdit(report.incidentReport),autoclose: true}).find('input').val(this.setTimeEdit(report.incidentReport));
       this.reporteditForm= this.formBuilder.group({
        location:[report.location],
        calf:[Number(report.calf)],
        female:[Number(report.female)],
        tusker:[Number(report.tusker)],
        mukhna:[Number(report.makhna)],
        remarks:[report.incidentRemark]
      });
  }
  setTimeEdit(times){
    // var date = new Date(times);
    // var hours:any = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    // hours = hours < 10 ? "0" + hours : hours;
    // var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    // console.log(date.getSeconds())
    // var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    // console.log(seconds)
    // var time = hours + ":" + minutes + ":"+ seconds ;
    // console.log(time)
    // //time = hours + ":" + minutes + am_pm;
    // return time;
    var date = new Date(times);

    // Extract the hours in 12-hour format and handle the AM/PM conversion
    var hours:any = date.getHours();
    var minutes:any = date.getMinutes();
    var seconds:any = date.getSeconds();
    var am_pm = hours >= 12 ? 'PM' : 'AM';


    //hours = hours > 12 ? hours - 12 : hours;
    //hours = hours === 0 ? 12 : hours; // Handle the case for 12 AM (midnight)

    // Pad minutes and seconds with leading zeros if necessary
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    // Format the time as hh:mm:ss AM/PM
    var time = `${hours}:${minutes}:${seconds} ${am_pm}`;
    return time;
  }
  onclickCancel(){
    jQuery("#editDeathRecordModal").modal("hide");
  }
  formatDateToYrMMDD(date) {
      let dateexct=date.split('-');
      var day = dateexct [0];
      var month = dateexct [1];
      var year = dateexct [2];
      var fullDay=year+'-'+month+'-'+day;
      return fullDay;
  }
  updateReport(){
      let incidentDate=this.incident_Date_ElementRef.nativeElement.value;
      incidentDate=this.formatDateToYrMMDD(incidentDate);
      let incidentTime=incidentDate+" "+this.incident_time_ElementRef.nativeElement.value;
      incidentTime=this.setTimeEdit(incidentTime);
      let fulldatetime=incidentDate+" "+incidentTime;

      const today = new Date();  // Get today's date
      const targetDate = new Date(fulldatetime); 

      if(incidentDate=="" || incidentDate==undefined){
        alert("Please Provide  date");
        return false;
      }
      if(incidentTime==""){
        alert("Please Provide time");
        return false;
      }
      if(targetDate>today){
        alert("Date should be greater than Today's date");
        return false;
      }

      if(incidentDate!="" && incidentTime!=""){
        var updateReportReq={
          incidentType:this.reportTypeEdit,
          updatedBy:this.loginuserid,
          incidentDate: fulldatetime,
          location:this.reporteditForm.value.location,
          tusker:this.reporteditForm.value.tusker!=null?Number(this.reporteditForm.value.tusker):0,
          mukhna:this.reporteditForm.value.mukhna!=null?Number(this.reporteditForm.value.mukhna):0,
          female:this.reporteditForm.value.female!=null?Number(this.reporteditForm.value.female):0,
          calf:this.reporteditForm.value.calf!=null?Number(this.reporteditForm.value.calf):0,
          remarks:this.reporteditForm.value.remarks
        }
        //  console.log(updateReportReq);
        //  console.log(this.reportIdEdit);
          this.apiService.request('PUT', 'api/v1/IncidentReport/updateDeathReportById?reportId='+this.reportIdEdit, updateReportReq).subscribe((res: any) => {
            if(res=="success"){
              this.notifyService.showSuccess("Report Update Successfully","Success");
              jQuery("#editDeathRecordModal").modal("hide");
              if(this.reportTypeEdit=="elephant"){
                 this.getElepDeathMovementRecord();
              }else if(this.reportTypeEdit=="tiger"){
               
              }else if(this.reportTypeEdit=="leopard"){
               
              }else if(this.reportTypeEdit=="pangolin"){
               
              }
            }
            else if(res=="invalid"){
             this.notifyService.showError("invalid report","Error");
           } else if(res=="failed"){
             this.notifyService.showError("Failed","Error");
           }else if(res=="notavailable"){
             this.notifyService.showError("Not Available","Error");
           }
          });   
      } 
  }

  getIncidentRecordByIDForEdit(element){
    
  }


  setDeleteRecord(elementId:any,reportType:any){
    this.deleteSelectedRecord=elementId;
    this.deleteReportType=reportType;
  }
  confirmDelete(){
    try{
      this.loader2=true;
    if(this.deleteSelectedRecord!=null &&  this.deleteReportType!=null){
    this.apiService.request('PUT', 'api/v1/IncidentReport/deleteReportById?loginId='+this.loginuserid+'&reportId='+this.deleteSelectedRecord+'&reportType='+this.deleteReportType).subscribe((message:any) => {
      if(message){
            if(message=="success")
            {
                this.notifyService.showSuccess("Deleted Successfully","Success");
            //   this.loader=false;
                this.loader2=false;
                jQuery("#deleteElephantMovementRecordModal").modal("hide");
                if(this.deleteReportType=='incident'){
                  $("#IncidentTable").DataTable().destroy();
                    this.getIncidentMovementRecord();
                }else if(this.deleteReportType=='death'){
                  $("#ElepDeathTable").DataTable().destroy();
                  this.getElepDeathMovementRecord();
              }
            }else if(message=="failed"){
              jQuery("#deleteElephantMovementRecordModal").modal("hide");
              this.notifyService.showError("UnSuccessfull","Error");
          //   this.loader=false;
              this.loader2=false;
            }else if(message=="notavailable"){
              jQuery("#deleteElephantMovementRecordModal").modal("hide");
              this.notifyService.showError("Record Not Available","Error");
            //  this.loader=false;
              this.loader2=false;
            }
        }
      });
    }else{
      jQuery("#deleteElephantMovementRecordModal").modal("hide");
    this.notifyService.showError("Record Not Available! ","Error");
    // this.loader=false;
    this.loader2=false;
    }
  }catch{
    this.loader2=false;
  }finally{
    this.loader2=false;
  }
  }
  defaultUrl(event) {
    event.target.src='assets/images/no_img.png';
  }
  inhisiatemapFire(){
    //alert("i execute second");
      var imegary = new Tile({
        visible: false,
        source: new OSM({
          url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
        })
      });
      var streerMap = new Tile({
        visible: true,
        source: new OSM({
          url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        })
      });
      this.imegary = imegary;
      this.streetmap = streerMap;

      // State Boundary
      var _StateBoundary = new layer.Image({
        source: new ImageWMS({
          url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wms',
          params: {
            'FORMAT': 'image/png',
            'VERSION': '1.1.0',
            STYLES: '',
            LAYERS: 'iwlms:odisha_state_boundary'
          },
          serverType: 'geoserver',
          crossOrigin: 'anonymous'
        }),
        visible: true
      });
        setTimeout(() => {
        // generate map to its target and set to a global variable
        this.mapFire = new Map({
        layers: [imegary, streerMap, _StateBoundary],
        controls: [
          new control.Zoom(),
          new control.ZoomSlider(),
          new control.ScaleLine
          ({
            units: 'metric',
            bar: true,
            steps: 4,
            text: true,
            minWidth: 140
          })
        ],
        overlays: [],
        target: 'emfire',
        view:
          new View({
          center: proj.fromLonLat([85.190055, 20.886785]),
          zoom: 7,
          maxZoom: 23,
          minZoom: 5,
          })
        });
      }, 100);
  }
  inhisiatemapDeath(targetdiv:any){
    //alert("i execute second");
      var imegary = new Tile({
        visible: false,
        source: new OSM({
          url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
        })
      });
      var streerMap = new Tile({
        visible: true,
        source: new OSM({
          url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        })
      });
      this.imegary = imegary;
      this.streetmap = streerMap;

      // State Boundary
      var _StateBoundary = new layer.Image({
        source: new ImageWMS({
          url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wms',
          params: {
            'FORMAT': 'image/png',
            'VERSION': '1.1.0',
            STYLES: '',
            LAYERS: 'iwlms:odisha_state_boundary'
          },
          serverType: 'geoserver',
          crossOrigin: 'anonymous'
        }),
        visible: true
      });
        setTimeout(() => {
        // generate map to its target and set to a global variable
        this.mapDeath = new Map({
        layers: [imegary, streerMap, _StateBoundary],
        controls: [
          new control.Zoom(),
          new control.ZoomSlider(),
          new control.ScaleLine
          ({
            units: 'metric',
            bar: true,
            steps: 4,
            text: true,
            minWidth: 140
          })
        ],
        overlays: [],
        target: targetdiv,
        view:
          new View({
          center: proj.fromLonLat([85.190055, 20.886785]),
          zoom: 7,
          maxZoom: 23,
          minZoom: 5,
          })
        });
      }, 100);
  }
  //elephantmovementmap2
  public thelatitude : any;
  public thelongitude : any;
  zoomthepoint(latitude:any,longitude:any,divisionid:any)
  {
    //alert("i execute first");
    this.divisionidtoextend=divisionid;
    this.thelatitude = latitude;
    this.thelongitude = longitude;

  }

  letzoomin(latitude:any,longitude:any,type,div)
  {
    const layername_div = 'https://odishaforestgis.in/ofms/index.php/webservice_api/getDivisionGeojson';
		var querydiv;
    if(div!="0"){
      querydiv = "{\"division_id\" : \"" + div + "\"}";
    }
    if(type=="Fire")
    {
        if(this.elephantpoint)
        {
          this.mapFire.removeLayer(this.elephantpoint);
        }
        setTimeout(() => {
          var iconFeature = new Feature({
            geometry: new Point(proj.fromLonLat([longitude, latitude])),
            name: 'Null Island',
            population: 4000,
            rainfall: 500,
          });
          var iconStyle = new Style({
            image: new Icon({
            src: '../assets/images/elephant_marker.png',
            }),
          });
          iconFeature.setStyle(iconStyle);
          var vectorSource = new VectorSource({
            features: [iconFeature],
          });
          var vectorLayer = new VectorLayer({
            source: vectorSource
          });
          this.elephantpoint = vectorLayer;
          this.mapFire.addLayer(vectorLayer);

          if(querydiv != "")
          {
            let formdata: FormData = new FormData();
            formdata.append('data', querydiv);
            try {
              this.apiService.requestGeoJson(layername_div, formdata).subscribe((response: any[]) => {
                if (response) {
                  //console.log(response);
                  if (response["status"] == 1) {
                    if (this.elephantpointdirect_fire) {
                      this.mapFire.removeLayer(this.elephantpointdirect_fire);
                    }
                    const styleFunction =
                      new Style({
                        stroke: new Stroke({
                          color: 'rgba(31, 28, 28, 2)',
                          width: 3,
                          lineDash: [.1, 5] //or other combinations
                        }),
                        zIndex: 2,
                        fill: new Fill({
                          color: 'rgba(32, 28, 28, 0.1)',
                        }),
                        text: new Text({
                          font: 'bold 17px Calibri,sans-serif',
                          fill: new Fill({
                            color: '#eb7734'
                          })
                        })
                      });
                    const vectorSource = new VectorSource();
                    var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
                    var gjFormat = new GeoJSON({
                      featureProjection: 'EPSG:3857',
                    });
                    var features = gjFormat.readFeatures(geojson);
                    vectorSource.clear();
                    var featureOverlayNew = new VectorLayer({
                      source: vectorSource,
                      style: function () {
                        styleFunction.getText().setText(response["post"][0].name_e + " Division");
                        return styleFunction;
                      },
                      updateWhileAnimating: true,
                      updateWhileInteracting: true
                    });
                    featureOverlayNew.getSource().addFeatures(features);
                    this.mapFire.addLayer(featureOverlayNew);
                    this.mapFire.getView().setZoom(10);
                    this.mapFire.getView().fit(featureOverlayNew.getSource().getExtent(), "");
                    this.elephantpointdirect_fire = featureOverlayNew;
                  //  this.loader=false;
                  } else {
                    this.notifyService.showError("Map Data Not Available", "Error");
                   // this.loader = false
                  }
                } else {
                  this.notifyService.showError("Map Data Not Available", "Error");
                //  this.loader = false
                }
              }, (error) => {
                const errors = error;
              //  this.loader = false;
              });
            } catch (e) {
              this.notifyService.showError("Something Error!!", "Error");
            //  this.loader = false
            } finally {
            //  this.loader = false
            }
          }
      },100);
    } else if (type == "Death"){
      if(this.elephantDeathpoint)
      {
        this.mapDeath.removeLayer(this.elephantDeathpoint);
      }
      setTimeout(() => {
        var iconFeature = new Feature({
          geometry: new Point(proj.fromLonLat([longitude, latitude])),
          name: 'Null Island',
          population: 4000,
          rainfall: 500,
        });
        var iconStyle = new Style({
          image: new Icon({
          src: '../assets/images/elephant_marker.png',
          }),
        });
        iconFeature.setStyle(iconStyle);
        var vectorSource = new VectorSource({
          features: [iconFeature],
        });
        var vectorLayer = new VectorLayer({
          source: vectorSource
        });
        this.elephantDeathpoint = vectorLayer;
        this.mapDeath.addLayer(vectorLayer);
        if(querydiv != "")
        {
          let formdata: FormData = new FormData();
          formdata.append('data', querydiv);
          try {
            this.apiService.requestGeoJson(layername_div, formdata).subscribe((response: any[]) => {
              if (response) {
                console.log(response);
                if (response["status"] == 1) {
                  if (this.elephantpointdirect_death) {
                    this.mapDeath.removeLayer(this.elephantpointdirect_death);
                  }
                  const styleFunction =
                    new Style({
                      stroke: new Stroke({
                        color: 'rgba(31, 28, 28, 2)',
                        width: 3,
                        lineDash: [.1, 5] //or other combinations
                      }),
                      zIndex: 2,
                      fill: new Fill({
                        color: 'rgba(32, 28, 28, 0.1)',
                      }),
                      text: new Text({
                        font: 'bold 17px Calibri,sans-serif',
                        fill: new Fill({
                          color: '#eb7734'
                        })
                      })
                    });
                  const vectorSource = new VectorSource();
                  var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
                  var gjFormat = new GeoJSON({
                    featureProjection: 'EPSG:3857',
                  });
                  var features = gjFormat.readFeatures(geojson);
                  vectorSource.clear();
                  var featureOverlayNew = new VectorLayer({
                    source: vectorSource,
                    style: function () {
                      styleFunction.getText().setText(response["post"][0].name_e + " Division");
                      return styleFunction;
                    },
                    updateWhileAnimating: true,
                    updateWhileInteracting: true
                  });
                  featureOverlayNew.getSource().addFeatures(features);
                  this.mapDeath.addLayer(featureOverlayNew);
                  this.mapDeath.getView().setZoom(10);
                  this.mapDeath.getView().fit(featureOverlayNew.getSource().getExtent(), "");
                  this.elephantpointdirect_death = featureOverlayNew;
                 // this.loader=false;
                } else {
                  this.notifyService.showError("Map Data Not Available", "Error");
                //  this.loader = false
                }
              } else {
                this.notifyService.showError("Map Data Not Available", "Error");
             //   this.loader = false
              }
            }, (error) => {
              const errors = error;
           //   this.loader = false;
            });
          } catch (e) {
            this.notifyService.showError("Something Error!!", "Error");
        //    this.loader = false
          } finally {
       //     this.loader = false
          }
        }
      },100);
    }
  }
  imagerychange(e)
  {
    if (e.target.checked == true) {
      this.imegary.setVisible(true);
    }
    else if (e.target.checked == false) {
      this.imegary.setVisible(false);
    }
  }
  streetchange(e)
  {
    if (e.target.checked == true) {
      this.streetmap.setVisible(true);
    }
    else if (e.target.checked == false) {
      this.streetmap.setVisible(false);
    }
  }
  bingmapchange(e)
  {
    if (e.target.checked == true) {}
    else if (e.target.checked == false) {}
  }
	topochange(e) {
		if (e.target.checked == true) {
			this._carto.setVisible(true);
		}
		else if (e.target.checked == false) {
			this._carto.setVisible(false);
		}
	}

}
