<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
					<li class="nav-item">
						<a id="tab-mangrovemap" href="#pane-mangrovemap" class="nav-link active" data-toggle="tab"
							role="tab">Map</a>
					</li>
					<li class="nav-item">
						<a id="tab-mangroveinfo" href="#pane-mangroveinfo" class="nav-link" data-toggle="tab"
							role="tab">Information</a>
					</li>
				</ul>

				<div id="accordion" class="tab-content" role="tablist">
					<div id="pane-mangrovemap" class="card tab-pane fade show active" role="tabpanel"
						aria-labelledby="tab-mangrovemap">
						<div class="card-header" role="tab" id="heading-mangrovemap">
							<h5 class="mb-0">
								<a data-toggle="collapse" href="#collapse-mangrovemap" aria-expanded="true"
									aria-controls="collapse-mangrovemap">
									Map
								</a>
							</h5>
						</div>
						<div id="collapse-mangrovemap" class="collapse show" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-mangrovemap">
							<div class="card-body">
								<div class="map" id="mangroveWLmap">
									<div class="gle-icon">
										<a href="javascript:void(0)">
											<img src="assets/images/icon-street.png" (click)='streetchange()'
												class="img-fluid" alt="Street View" title="Street View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-satellite.png" class="img-fluid"
												(click)='stelitechange()' alt="Satellite View" title="Satellite View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-topo.png" class="img-fluid"
												(click)='topochange()' alt="Topo View" title="Topo View">
										</a>
									</div>
								</div>


							</div>
						</div>
					</div>

					<div id="pane-mangroveinfo" class="card tab-pane fade" role="tabpanel"
						aria-labelledby="tab-mangroveinfo">
						<div class="card-header" role="tab" id="heading-mangroveinfo">
							<h5 class="mb-0">
								<a class="collapsed" data-toggle="collapse" href="#collapse-mangroveinfo"
									aria-expanded="false" aria-controls="collapse-mangroveinfo">
									Information
								</a>
							</h5>
						</div>
						<div id="collapse-mangroveinfo" class="collapse" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-mangroveinfo">
							<div class="card-body">
								<h4 class="card-title">
									Mangrove Forest Division Wildlife Rajnagar
									<span class="custom-down">
										<button type="" (click)="print()"
											class="btn btn-sm btn-info mr-1"><i
												class="fa fa-file-pdf">&nbsp;</i>Information</button>
										<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
												class="fa fa-file-pdf">&nbsp;</i>Map</button>

									</span>
								</h4>
								<div id="printcontent">
									<div class="row">
										<div class="col-md-9">
											<div class="table-responsive">
												<table class="table table-sm table-bordered">
													<tbody>
														<tr>
															<td><strong>District : </strong></td>
															<td>Kendrapara</td>
														</tr>
														<tr>
															<td><strong>Geographical Extent : </strong></td>
															<td>Longitude 86° 30'E and 87° 06'E & Latitude 20° 30'N and
																20° 50'N</td>
														</tr>
														<tr>
															<td><strong>Climate : </strong></td>
															<td>Temperature - In summer: 43°C, In winter: 10°C</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div class="col-md-3">
											<img src="assets/images/wildllifeunits-mangrove-01.jpg"
												class="img-fluid float-right img-inner" alt="image">
										</div>
									</div>
									<p>Mangrove Forest Division (Wildlife) Rajnagar is located in Jagatsinghpur &
										Kendrapara districts of Odisha. Two Sanctuaries nestled in this wildlife
										division namely, Bhitarkanika Wildlife Sanctuary and Gahirmatha (Marine)
										Wildlife Sanctuary.Mangroves are not as much known as other ecosystem.It is a
										compound word formed by portuguese ‘Mangue’ & English ‘Grove’.In Portuguese
										‘Mang-ue’ is used for individual species of Mangrove habitat and thus the word
										Mangrove can be said to be grove of Mangrove species(tree,shrubs,grass etc.)In
										other words they are known as ‘tidal forests’ or ‘deltaic swamp forests’ or
										‘litorial swamp forest’ Mangroves are also describe as ‘coastal
										woodland’,’Mangals’ or Mangrove Forest.Mangrove are characteristics salt
										tolerant forest Ecosystem of tropical and sub tropical intertidal regions,which
										typically occur on sheltered mudflats between high and low tide level in areas
										with high annual precipitation.The dynamic mangrove Eco-System provides
										exploring flora and fauna.</p>
									<h4 class="card-title">Flora </h4>
									<p>More than 300 plant species has been enumerated so far which include two ferns
										and 02 orchids.It includes many rare/endemic/endangered mangrove taxa.Three wild
										relatives of crop plants (Paddy,Lemon and Guava) enrich the ecosystem.</p>
									<h4 class="card-title">Fauna </h4>
									<p>28 Species of Mammals,14 Species of Amphibians,42 Species of Reptilians 285
										Species of Birds,Variety of fish and crab. Mammals: Sambar,Spotted deer,Wild
										Pigs,fishing Cat,Leopard Cat,Hyena,Porcupine,Smooth India Otter & Dolphin.
										Significant Reptiles: Estuarine Crocodile,Water Monitor Lizard,Indian Python &
										king Cobra. Significant Bird: Indian Skimmer,White bellied Sea Eagle,Bar headed
										Geese, Brahminy Duck, Pied Avocet, Spot Billed Pelican, Lesser Adjutant Stork,
										Mangrove Pitta, Goliath Heron, Painted Stork, Spoon Bill, Ibis and Grey
										Hornbill.</p>
									<h4 class="card-title">Set-Ups</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td><strong>Administrative set-up (Range, Section, beats) :</strong>
													</td>
													<td>Ranges- 5, Sections- 15,Beats- 47</td>
												</tr>
												<tr>
													<td><strong>EDC/JFM set-up: </strong></td>
													<td>EDCs - 48, VSS - 23</td>
												</tr>
												<tr>
													<td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
													<td>No. of RF- 5, No. of PRF- 29, No. of DPF- 0, No. of UDPF- 0, No.
														of VF- 0</td>
												</tr>
											</tbody>
										</table>
									</div>
									<h4 class="card-title">Major Attraction</h4>
									<div class="row">
										<div class="col-md-12">
											<img src="assets/images/wildllifeunits-mangrove-02.jpg"
												class="img-fluid float-right img-inner" alt="image">
											<ul class="custom-list">
												<li>Cruising in Rivers and Creeks amidst thick Mangroves Forests.</li>
												<li>Batighar (Light House) at False Point.</li>
												<li>Ancient Port at Hukitola.</li>
												<li>The shallow waters of Chatka.</li>
												<li>Breeding and nesting marine turtles</li>
												<li>Horse shoe crab , the living fossil</li>
												<li>Migratory water birds .</li>
												<li>Extensive salubrious sandy beach .</li>
											</ul>
										</div>
									</div>
									<h4 class="card-title">Salient Detail</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td width="15%"><strong>HQ Address :</strong></td>
													<td width="85%">Mangrove Forests Division Wildlife Rajnagar,
														Kendrapara Sanabada Gopalpur, Rajnagar, Kendrapara</td>
												</tr>
												<tr>
													<td><strong>Contact officer : </strong></td>
													<td>
														<span class="address-inner"><i
																class="fa fa-user">&nbsp;</i></span>Divisional Forest
														Officer<br>
														<span class="address-inner"><i
																class="fa fa-map-marker">&nbsp;</i></span>Mangrove
														Forests Division (WL), Rajnagar, Dist - Kendrapara<br>
														<span class="address-inner"><i
																class="fa fa-phone">&nbsp;</i></span>06729-242460<br>
														<span class="address-inner"><i
																class="fa fa-fax">&nbsp;</i></span>06729-242460<br>
													</td>
												</tr>
												<tr>
													<td><strong>Key activities : </strong></td>
													<td>Protection of Habitat, Habitat improvement,Resolving Man-animal
														conflict, Carrying out research, Eco-tourism, Eco-development,
														Creating awareness , etc</td>
												</tr>
												<tr>
													<td><strong>Programmes : </strong></td>
													<td>Wildlife Health Programmes, Interagency Programmes, Development
														Programmes, etc.</td>
												</tr>
												<tr>
													<td><strong>Places to see : </strong></td>
													<td>Bhitarkanika Wildlife Sanctuary & Gahirmatha (Marine) Wildlife
														Sanctuary, Hukitola,etc.</td>
												</tr>
											</tbody>
										</table>
									</div>
									<p class="text-danger"><small><i
												class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
											</strong>The information contained herein are provided solely for
											informational purposes only.The content is dynamic and may undergo change
											from time to time. The snapshot of the content at a particular time is not
											to be referred as a legally binding document.</small></p>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>
