<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
					<li class="nav-item">
						<a id="tab-keonjharmap" href="#pane-keonjharmap" class="nav-link active" data-toggle="tab" role="tab">Map</a>
					</li>
					<li class="nav-item">
						<a id="tab-keonjharinfo" href="#pane-keonjharinfo" class="nav-link" data-toggle="tab" role="tab">Information</a>
					</li>
				</ul>

				<div id="accordion" class="tab-content" role="tablist">
					<div id="pane-keonjharmap" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-keonjharmap">
						<div class="card-header" role="tab" id="heading-keonjharmap">
							<h5 class="mb-0">
								<a data-toggle="collapse" href="#collapse-keonjharmap" aria-expanded="true"
									aria-controls="collapse-keonjharmap">
									Map
								</a>
							</h5>
						</div>
						<div id="collapse-keonjharmap" class="collapse show" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-keonjharmap">
							<div class="card-body">
								<div class="map" id="keonjharWLmap">
									<div class="gle-icon">
										<a href="javascript:void(0)">
                                            <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid" alt="Street View" title="Street View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()' alt="Satellite View" title="Satellite View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View" title="Topo View">
                                        </a>
                                    </div>
								</div>


							</div>
						</div>
					</div>

					<div id="pane-keonjharinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-keonjharinfo">
						<div class="card-header" role="tab" id="heading-keonjharinfo">
							<h5 class="mb-0">
								<a class="collapsed" data-toggle="collapse" href="#collapse-keonjharinfo" aria-expanded="false"
									aria-controls="collapse-keonjharinfo">
									Information
								</a>
							</h5>
						</div>
						<div id="collapse-keonjharinfo" class="collapse" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-keonjharinfo">
							<div class="card-body">
								<h4 class="card-title">
									Keonjhar Wildlife Division
									<span class="custom-down">
                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
					<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
						class="fa fa-file-pdf">&nbsp;</i>Map</button>
			                		</span>
								</h4>
                <div id="printcontent">
								<div class="row">
									<div class="col-md-9">
										<div class="table-responsive">
											<table class="table table-sm table-bordered">
												<tbody>
													<tr>
														<td><strong>District : </strong></td>
														<td>Keonjhar</td>
													</tr>
													<tr>
														<td><strong>Geographical Extent : </strong></td>
														<td>Longitude 85° 37'E and 86° 21'E & Latitude 21° 33'N and 20°
															59'N</td>
													</tr>
													<tr>
														<td><strong>Climate : </strong></td>
														<td>Summer- March to June(maximum temp-35° C ), Rainy- July to
															October and winter – November to February</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div class="col-md-3">
										<img src="assets/images/wildlifeunits-keonjhar-01.jpg"
											class="img-fluid float-right img-inner" alt="image">
									</div>
								</div>
								<p>Keonjhar Wildlife Division extends to the whole of Anandapur sub-division. Hadgarh
									Sanctuary lies in this Wildlife division.This division with miscellaneous vegetation
									and smaller hills contains variety of flora and fauna and surrounds Salandi
									reservoir, which contains fresh water. The entire landscape is an ideal habitat for
									many wild lives in general and flag ship species like elephant in particular.The
									wildlife division has immense biodiversity and aesthetic and geomorphologic values
									with an unique still water mass of 31.83 Sq km. The beauty of lush green undulating
									forest all around gives birth to Salandi River.</p>
								<h4 class="card-title">Flora </h4>
								<p>The main portion of division comprises of mixed deciduous forests with miscellaneous
									species.The major forest types are Northern Tropical Dry deciduous forests,Dry
									Peninsular Sal Forests,Northern dry mixed Deciduous forests.Climbers found are
									Bauhinia vahalii, Millettia auriculata and Butea superba in Hadagarh Sanctuary. Some
									common grasses found are spear grass, Sabai and broom grass</p>
								<h4 class="card-title">Fauna </h4>
								<p>Hadagarh Wild life sanctuary is famous for migrating elephants and resident
									population of wild boar, bears, deers, hare and sambars. In addition to it Langur,
									Macaques, Tiger, Leopard, Jackal, Hyaena, Jungle cat, Mongoose, Bears, Otter,
									fishing cat, Chital, Barking deer, wild bear, Flying squirrel, Porcupine, Hare,
									Rodents, Pangolin, Snakes are commonly found. Among birds jungle crow, jungle myna,
									crow phesants, Bulbull, parakeets, peacocks, Babblers, Magpie Robin, woodpeckers,
									Koel, bee eater, Swifts, Vulture, Owls, Pigeons, doves, jungle bush quail, patridge,
									Egrets, Ducks, Teals, Hornbills, drongo, Winter migrants like ducks are also found.
								</p>
								<h4 class="card-title">Set-Ups</h4>
								<div class="table-responsive">
									<table class="table table-sm table-bordered">
										<tbody>
											<tr>
												<td><strong>Administrative set-up (Range, Section, beats) :</strong>
												</td>
												<td> Ranges- 4, Sections- 12,Beats- 36</td>
											</tr>
											<tr>
												<td><strong>EDC/JFM set-up: </strong></td>
												<td>
													EDCs - 0, VSS - 0
												</td>
											</tr>
											<tr>
												<td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
												<td>No. of RF- 0, No. of PRF- 0, No. of DPF- 0, No. of UDPF- 0, No. of
													VF- 0</td>
											</tr>
										</tbody>
									</table>
								</div>
								<h4 class="card-title">Major Attraction</h4>
								<div class="row">
									<div class="col-md-12">
										<img src="assets/images/wildlifeunits-keonjhar-02.jpg"
											class="img-fluid float-right img-inner" alt="image">
										<ul class="custom-list">
											<li>Thakurani hill : It is situated by the side of a perennial spring of
												Thakurani hill. It is famous for the temple of Murga Mahadev.</li>
											<li>Sanghagra : It is a perennial waterfall.</li>
											<li>Badaghagara :It is famous for a small charming waterfall about 50 ft.
												high on the river Mudala</li>
											<li>Keonjhar hill :A quiet hillside town, Keonjhar district is the
												playground of nature, away from the hoards of tourists that throng to
												popular hill stations.</li>
											<li>Gonasika :Surrounded by a series of valleys and wooded hills of varying
												colours, the place is famous as a centre of pilgrimage for the temple of
												Brahmeswar Mahadev established by the side of river Baitarani.</li>
											<li>Hadagarh Dam on the river Salandi in ANANDAPUR subdivision of Keonjhar.
												It is an ideal picnic spot for round the year.</li>
										</ul>
									</div>
								</div>
								<h4 class="card-title">Salient Detail</h4>
								<div class="table-responsive">
									<table class="table table-sm table-bordered">
										<tbody>
											<tr>
												<td width="15%"><strong>HQ Address :</strong></td>
												<td width="85%">Keonjhar Wildlife Division At-Anandapur in the district
													of Keonjhar,odisha</td>
											</tr>
											<tr>
												<td><strong>Contact officer : </strong></td>
												<td>
													<span class="address-inner"><i
															class="fa fa-user">&nbsp;</i></span>Divisional Forest
													Officer<br>
													<span class="address-inner"><i
															class="fa fa-map-marker">&nbsp;</i></span>Keonjhar Wildlife
													Division, Anandapur<br>
													<span class="address-inner"><i
															class="fa fa-phone">&nbsp;</i></span>06782- 256142<br>
													<span class="address-inner"><i
															class="fa fa-fax">&nbsp;</i></span>06782<br>
												</td>
											</tr>
											<tr>
												<td><strong>Key activities : </strong></td>
												<td>Construction & maintenance of contur bunds, Maintenance of soil
													conservation,Agro forestry, silvipasture, fuel wood planting
													wildlife management protection of forest and wildlife.</td>
											</tr>
											<tr>
												<td><strong>Programmes : </strong></td>
												<td>Buffer Zone management Programmes ,development programmes,etc.</td>
											</tr>
											<tr>
												<td><strong>Places to see : </strong></td>
												<td>Hadgarh Sanctuary,Salandi dam,etc.</td>
											</tr>
										</tbody>
									</table>
								</div>
								<p class="text-danger"><small><i
											class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The
										information contained herein are provided solely for informational purposes
										only.The content is dynamic and may undergo change from time to time. The
										snapshot of the content at a particular time is not to be referred as a legally
										binding document.</small></p>
                  </div>
							</div>
						</div>
					</div>

				</div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>
