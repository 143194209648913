import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SlidermainComponent } from './slidermain/slidermain.component';
import { SlidergalleryComponent } from './slidergallery/slidergallery.component';
import { SlidernewsComponent } from './slidernews/slidernews.component';
import { SlidercarouselComponent } from './slidercarousel/slidercarousel.component';
import { HomecontentComponent } from './homecontent/homecontent.component';
import { HomeinfoComponent } from './homeinfo/homeinfo.component';
import { BlogComponent } from './blog/blog.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MasterheaderComponent } from './masterheader/masterheader.component';
import { MasterfooterComponent } from './masterfooter/masterfooter.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BarchartComponent } from './barchart/barchart.component';
import { PiechartComponent } from './piechart/piechart.component';
import { BarchartmultipleoneComponent } from './barchartmultipleone/barchartmultipleone.component';
import { BarchartmultipletwoComponent } from './barchartmultipletwo/barchartmultipletwo.component';
import { ManageprimarylinkComponent } from './manageprimarylink/manageprimarylink.component';
import { AddrolemanagementComponent } from './addrolemanagement/addrolemanagement.component';
import { ViewrolemanagementComponent } from './viewrolemanagement/viewrolemanagement.component';
import { UsermanagementComponent } from './usermanagement/usermanagement.component';
import { ElephantmovementrecordComponent } from './elephantmovementrecord/elephantmovementrecord.component';
import { ElephantcorridormapComponent } from './elephantcorridormap/elephantcorridormap.component';
import { DashboarduserComponent } from './dashboarduser/dashboarduser.component';
import { WlOrganisationComponent } from './wl-organisation/wl-organisation.component';
import { VisionComponent } from './vision/vision.component';
import { OrganogramComponent } from './organogram/organogram.component';
import { ResearchComponent } from './research/research.component';
import { WildlifeCensusComponent } from './wildlife-census/wildlife-census.component';
import { CorridorsComponent } from './corridors/corridors.component';
import { PublicationComponent } from './publication/publication.component';
import { ProjectelephantComponent } from './projectelephant/projectelephant.component';
import { ProjecttigerComponent } from './projecttiger/projecttiger.component';
import { CrocodileconservationComponent } from './crocodileconservation/crocodileconservation.component';
import { SeaturtleconservationComponent } from './seaturtleconservation/seaturtleconservation.component';
import { BlackbuckconservationComponent } from './blackbuckconservation/blackbuckconservation.component';
import { MangrovesComponent } from './mangroves/mangroves.component';
import { CommonelementComponent } from './commonelement/commonelement.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { EcotourismComponent } from './ecotourism/ecotourism.component';
import { MananimalinterfaceComponent } from './mananimalinterface/mananimalinterface.component';
import { ImportantcircularsComponent } from './importantcirculars/importantcirculars.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { InnewsComponent } from './innews/innews.component';
import { NoticesComponent } from './notices/notices.component';
import { InfodashboardComponent } from './infodashboard/infodashboard.component';
import { FiltergalleryComponent } from './filtergallery/filtergallery.component';
import { BlogpublicationComponent } from './blogpublication/blogpublication.component';
import { ShortfilmsprotectedareasComponent } from './shortfilmsprotectedareas/shortfilmsprotectedareas.component';
import { ProtectedareasodishaComponent } from './protectedareasodisha/protectedareasodisha.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ElephantanalyticsComponent } from './elephantanalytics/elephantanalytics.component';
import { ReportsComponent } from './reports/reports.component';
import { ElephantanalyticsintrendComponent } from './elephantanalyticsintrend/elephantanalyticsintrend.component';
import { CmsComponent } from './cms/cms.component';
import { ViewcmsComponent } from './viewcms/viewcms.component';
import { BlogdetailsComponent } from './blogdetails/blogdetails.component';
import { BlogapplicationComponent } from './blogapplication/blogapplication.component';
import { GajabandhuComponent } from './gajabandhu/gajabandhu.component';
import { TenderComponent } from './tender/tender.component';
import { ElephantwebreportingComponent } from './elephantwebreporting/elephantwebreporting.component';
import { FeedbackviewComponent } from './feedbackview/feedbackview.component';
import { CitizenuserComponent } from './citizenuser/citizenuser.component';
import { HeaderlandingComponent } from './headerlanding/headerlanding.component';
import { AuthorityuserComponent } from './authorityuser/authorityuser.component';
import { BlogallComponent } from './blogall/blogall.component';
import { FooterlandingComponent } from './footerlanding/footerlanding.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthorizationCheckService } from './service/authorization-check.service';
import { WildlifeunitsModule } from './wildlifeunits/wildlifeunits.module';
import { ProtectedareasModule } from './protectedareas/protectedareas.module';
import { SharedhomeModule } from './sharedhome/sharedhome.module';
import { MprComponent } from './mpr/mpr.component';
import { AuditreportComponent } from './auditreport/auditreport.component';
import { SmsauditComponent } from './smsaudit/smsaudit.component';
import { CitizenauditComponent } from './citizenaudit/citizenaudit.component';
import { LoginreportauditComponent } from './loginreportaudit/loginreportaudit.component';
import { ControlroominfoComponent } from './controlroominfo/controlroominfo.component';
import { ElephantauditComponent } from './elephantaudit/elephantaudit.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProblematicelphantComponent } from './problematicelphant/problematicelphant.component';
import { ManagesquardComponent } from './managesquard/managesquard.component';
import { SquardreportComponent } from './squardreport/squardreport.component';
import { EventComponent } from './event/event.component';
import { AporeportComponent } from './aporeport/aporeport.component';
import { SquadreportdashboardComponent } from './squadreportdashboard/squadreportdashboard.component';

const routes: Routes = [
  {
    path:  '',
    component: HomeComponent
  },
  // {
  //   path:  '**',
  //   component: NotFoundComponent
  // },

  {path:"wildlifeunits", loadChildren:() => WildlifeunitsModule},
  {path: 'protectedareas',loadChildren:() =>ProtectedareasModule},
  {path: 'shared',loadChildren:() =>SharedhomeModule},
  {
    path:  'WlOrganisation',
    component: WlOrganisationComponent
  },
  {
    path:  'Organogram',
    component: OrganogramComponent
  },
  {
    path:  'Vision',
    component: VisionComponent
  },
  {
    path:  'Research',
    component: ResearchComponent
  },
  {
    path:  'WildlifeCensus',
    component: WildlifeCensusComponent
  },
  {
    path:  'Corridors',
    component: CorridorsComponent
  },
  {
    path:  'Publication',
    component: PublicationComponent
  },
  {
    path:  'event',
    component: EventComponent
  },
  {
    path:  'slidermain',
    component: SlidermainComponent
  },
  {
    path:  'slidergallery',
    component: SlidergalleryComponent
  },
  {
    path:  'slidernews',
    component: SlidernewsComponent
  },
  {
    path:  'slidercarousel',
    component: SlidercarouselComponent
  },
  {
    path:  'homecontent',
    component: HomecontentComponent
  },
  {
    path:  'homeinfo',
    component: HomeinfoComponent
  },
  {
    path:  'blog',
    component: BlogComponent
  },
  {
    path:  'dashboard',
    //canActivate:[AuthorizationCheckService],
    component: DashboardComponent,
    pathMatch: 'full'
  },
  {
    path:  'sidebar',
    component: SidebarComponent
  },
  {
    path:  'masterheader',
    component: MasterheaderComponent
  },
  {
    path:  'masterfooter',
    component: MasterfooterComponent
  },
  {
    path:  'breadcrumb',
    component: BreadcrumbComponent
  },
  {
    path:  'barchart',
    component: BarchartComponent
  },
  {
    path:  'piechart',
    component: PiechartComponent
  },
  {
    path:  'barchartmultipleone',
    component: BarchartmultipleoneComponent
  },
  {
    path:  'barchartmultipletwo',
    component: BarchartmultipletwoComponent
  },
  {
    path:  'manageprimarylink',
    component: ManageprimarylinkComponent
  },
  {
    path:  'addrolemanagement',
    component: AddrolemanagementComponent
  },
  {
    path:  'viewrolemanagement',
    component: ViewrolemanagementComponent
  },
  {
    path:  'usermanagement',
    component: UsermanagementComponent
  },
  {
    path:  'elephantmovementrecord',
    component: ElephantmovementrecordComponent
  },
  {
    path:  'elephantcorridormap',
    component: ElephantcorridormapComponent
  },
  {
    path:  'dashboarduser',
    component: DashboarduserComponent
  },

  {
    path:  'projectelephant',
    component: ProjectelephantComponent
  },
  {
    path:  'projecttiger',
    component: ProjecttigerComponent
  },
  {
    path:  'crocodileconservation',
    component: CrocodileconservationComponent
  },
  {
    path:  'seaturtleconservation',
    component: SeaturtleconservationComponent
  },
  {
    path:  'blackbuckconservation',
    component: BlackbuckconservationComponent
  },
  {
    path:  'mangroves',
    component: MangrovesComponent
  },
  {
    path:  'commonelement',
    component: CommonelementComponent
  },
  {
    path:  'profile',
    component: ProfileComponent
  },
  {
    path:  'changepassword',
    component: ChangepasswordComponent
  },
  {
    path:  'ecotourism',
    component: EcotourismComponent
  },
  {
    path:  'mananimalinterface',
    component: MananimalinterfaceComponent
  },
  {
    path:  'importantcirculars',
    component: ImportantcircularsComponent
  },
  {
    path:  'notifications',
    component: NotificationsComponent
  },
  {
    path:  'innews',
    component: InnewsComponent
  },
  {
    path:  'notices',
    component: NoticesComponent
  },
  {
    path:  'controlroominfo',
    component: ControlroominfoComponent
  },
  {
    path:  'infodashboard',
    component: InfodashboardComponent
  },
  {
    path:  'filtergallery',
    component: FiltergalleryComponent
  },
  {
    path:  'blogpublication',
    component: BlogpublicationComponent
  },
  {
    path:  'shortfilmsprotectedareas',
    component: ShortfilmsprotectedareasComponent
  },
  {
    path:  'protectedareasodisha',
    component: ProtectedareasodishaComponent
  },
  {
    path:  'gallery',
    component: GalleryComponent
  },
  {
    path:  'elephantanalytics',
    component: ElephantanalyticsComponent
  },
  {
    path:  'reports',
    component: ReportsComponent
  },
  {
    path:  'elephantanalyticsintrend',
    component: ElephantanalyticsintrendComponent
  },
  {
    path:  'cms',
    component: CmsComponent
  },
  {
    path:  'viewcms',
    component: ViewcmsComponent
  },
  {
    path:  'blogdetails',
    component: BlogdetailsComponent
  },
  {
    path:  'blogapplication',
    component: BlogapplicationComponent
  },
  {
    path:  'gajabandhu',
    component: GajabandhuComponent
  },
  {
    path:  'tender',
    component: TenderComponent
  },
  {
    path:  'elephantwebreporting',
    component: ElephantwebreportingComponent,
    //canActivate: [AuthorizationCheckService]
    
  },
  {
    path:  'feedbackview',
    component: FeedbackviewComponent
  },
  {
    path:  'citizenuser',
    component: CitizenuserComponent
  },
  {
    path:  'headerlanding',
    component: HeaderlandingComponent
  },
  {
    path:  'authorityuser',
    component: AuthorityuserComponent
  },
  {
    path:  'blogall',
    component: BlogallComponent
  },
  {
    path:  'footerlanding',
    component: FooterlandingComponent
  },
  {
    path:  'mpr',
    component: MprComponent
  },
  {
    path:  'auditreport',
    component: AuditreportComponent
  },
  {
    path:  'smsauditreport',
    component: SmsauditComponent
  },{
    path:  'elephantauditreport',
    component: ElephantauditComponent
  }
  ,{
    path:  'citizenauditreport',
    component: CitizenauditComponent
  }, {
    path:  'loginauditreport',
    component: LoginreportauditComponent
  }, {
    path:  'PrivacyPolicy',
    component: PrivacyPolicyComponent
  },
  {
    path:  'squardreport',
    component: SquardreportComponent
  },
  {
    path:  'squad-monitoring-dashboard',
    component: SquadreportdashboardComponent
  },
  {
    path:  'problematicelphant',
    component: ProblematicelphantComponent
  },
  {
    path:  'apo',
    component: AporeportComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
      preloadingStrategy: PreloadAllModules
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

