<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<app-masterheader></app-masterheader>

<div class="wrapper">

	<app-sidebar></app-sidebar>

	<div id="content">

		<!-- <app-breadcrumb></app-breadcrumb> -->

		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12">

					<h4 class="card-title">Audit Report</h4>

					<div class="row">
						<div class="col-md-4 card-audit">
							<a [routerLink]="['/smsauditreport']">
								<div class="card bg-gradient-pink">
									<div class="card-body">
										<h2>SMS Report</h2>
										<img src="assets/images/report-sms.png">
									</div>
								</div>
							</a>
						</div>
						<div class="col-md-4 card-audit">
							<a [routerLink]="['/elephantauditreport']">
								<div class="card bg-gradient-yellow">
									<div class="card-body">
										<h2>Elephant Report</h2>
										<img src="assets/images/report_audit_elephant.png">
									</div>
								</div>
							</a>
						</div>
						<div class="col-md-4 card-audit">
							<a [routerLink]="['/citizenauditreport']">
								<div class="card bg-gradient-yellow">
									<div class="card-body">
										<h2>Citizen Report</h2>
										<img src="assets/images/report-citizen.png">
									</div>
								</div>
							</a>
						</div>
						<div class="col-md-4 card-audit">
							<a [routerLink]="['/loginauditreport']">
								<div class="card bg-gradient-blue">
									<div class="card-body">
										<h2>Login Report</h2>
										<img src="assets/images/report-login.png">
									</div>
								</div>
							</a>
						</div>
					</div>

				</div>
			</div>
		</div>

	</div>

	<div id="footer">
		<app-masterfooter></app-masterfooter>
	</div>

</div>
