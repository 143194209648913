<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
					<li class="nav-item">
						<a id="tab-dhenkanalmap" href="#pane-dhenkanalmap" class="nav-link active" data-toggle="tab"
							role="tab">Map</a>
					</li>
					<li class="nav-item">
						<a id="tab-dhenkanalinfo" href="#pane-dhenkanalinfo" class="nav-link" data-toggle="tab"
							role="tab">Information</a>
					</li>
				</ul>

				<div id="accordion" class="tab-content" role="tablist">
					<div id="pane-dhenkanalmap" class="card tab-pane fade show active" role="tabpanel"
						aria-labelledby="tab-dhenkanalmap">
						<div class="card-header" role="tab" id="heading-dhenkanalmap">
							<h5 class="mb-0">
								<a data-toggle="collapse" href="#collapse-dhenkanalmap" aria-expanded="true"
									aria-controls="collapse-dhenkanalmap">
									Map
								</a>
							</h5>
						</div>
						<div id="collapse-dhenkanalmap" class="collapse show" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-dhenkanalmap">
							<div class="card-body">
								<div class="map" id="dhenkanalWLmap">
									<div class="gle-icon">
										<a href="javascript:void(0)">
											<img src="assets/images/icon-street.png" (click)='streetchange()'
												class="img-fluid" alt="Street View" title="Street View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-satellite.png" class="img-fluid"
												(click)='stelitechange()' alt="Satellite View" title="Satellite View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-topo.png" class="img-fluid"
												(click)='topochange()' alt="Topo View" title="Topo View">
										</a>
									</div>
								</div>


							</div>
						</div>
					</div>

					<div id="pane-dhenkanalinfo" class="card tab-pane fade" role="tabpanel"
						aria-labelledby="tab-dhenkanalinfo">
						<div class="card-header" role="tab" id="heading-dhenkanalinfo">
							<h5 class="mb-0">
								<a class="collapsed" data-toggle="collapse" href="#collapse-dhenkanalinfo"
									aria-expanded="false" aria-controls="collapse-B">
									Information
								</a>
							</h5>
						</div>
						<div id="collapse-dhenkanalinfo" class="collapse" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-dhenkanalinfo">
							<div class="card-body">
								<h4 class="card-title">
									Dhenkanal Forest Division
									<span class="custom-down">
										<button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i
												class="fa fa-file-pdf">&nbsp;</i>Information</button>
										<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
												class="fa fa-file-pdf">&nbsp;</i>Map</button>
									</span>
								</h4>
								<div id="printcontent">
									<div class="row">
										<div class="col-md-9">
											<div class="table-responsive">
												<table class="table table-sm table-bordered">
													<tbody>
														<tr>
															<td><strong>District : </strong></td>
															<td>Dhenkanal</td>
														</tr>
														<tr>
															<td><strong>Geographical Extent : </strong></td>
															<td>Longitude 85° 05'E and 86° 10'E & Latitude 20° 35'N and
																21° 10'N</td>
														</tr>
														<tr>
															<td><strong>Climate : </strong></td>
															<td>Maximum Temperature ranges from 35° C to 45° C, minimum
																ranges from 13° C to 17° C and average rainfall 1428 mm.
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div class="col-md-3">
											<img src="assets/images/wildlifeunits-dhenkanal-01.jpg"
												class="img-fluid float-right img-inner" alt="image">
										</div>
									</div>
									<p>Dhenkanal Forest Division has been established during the year 1948 having three
										Sub-divisions namely Dhenkanal, Kamakshyanagar and Hindol of Dhenkanal
										District.The Kapilash Wildlife Sanctuary is located in the Southern Hill Range
										of Dhenkanal Forest Division under Angul Circle in the State of Odisha.The
										Sanctuary is quite rich in biodiversity having significant genetic and
										ecological importance.Kapilash is the paradise of tourists.Important fauna of
										the Sanctuary includes, Leopard, Elephant, Sambar, Barking Deer, Mouse Deer,
										Bear, Pangolin, Peacocks and a wide variety of reptiles.</p>
									<h4 class="card-title">Flora </h4>
									<p>The trees in top storey are mostly deciduous and remain leafless for a shorter
										period of time but the middle story is mostly evergreen having species like
										Saraca India, Cane,Mangifera Indica. Sal and kangada are the usual associates in
										the higher altitude which are gradually replaced by bamboo at the foot hills.
										Species frequently come across are: Asan, Kumbhi, Alstonia Scholoris, Anogeissus
										acuminata etc. Various climbers are found in this type of Forests. Cane occurs
										in patches in moist areas. A variety of Shrubs like Kamini, Cycas, Nahalbeli
										etc. are observed as the ground flora in semi evergreen forests.The forest
										growth in the lateritic soil are mainly Xylia Xylocarpa, Pterocorpus marsupium,
										Anogecious latifolia, Grewia tilifolia, Terminalia species. Severall thorny
										shrubs like Carissa spina rum, flacourtia indica and hugonia myastax are seen.
										Climbers like combretum decandrum and zyzyphus oenoplea dominate in most of the
										forests.</p>
									<h4 class="card-title">Fauna </h4>
									<p>The fauna consists mainly of monkeys, Jungle cats, Squirrels, Leopard, Elephant,
										Sambar, Barking Deer, Mouse Deer, South Bear, Pangolin, Peacocks with a wide
										variety of reptiles and birds. The dense forests and the presence of wild
										animals draws many nature-lovers to this area.</p>
									<h4 class="card-title">Set-Ups</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td><strong>Administrative set-up (Range, Section, beats) :</strong>
													</td>
													<td>Ranges- 8, Sections- 29,Beats- 104</td>
												</tr>
												<tr>
													<td><strong>EDC/JFM set-up: </strong></td>
													<td>
														EDCs - 0, VSS - 0
													</td>
												</tr>
												<tr>
													<td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
													<td> No. of RF- 65, No. of PRF- 4, No. of DPF- 47, No. of UDPF- 0,
														No. of VF- 217</td>
												</tr>
											</tbody>
										</table>
									</div>
									<h4 class="card-title">Major Attraction</h4>
									<div class="row">
										<div class="col-md-12">
											<img src="assets/images/wildlifeunits-dhenkanal-02.jpg"
												class="img-fluid float-right img-inner" alt="image">
											<ul class="custom-list">
												<li>Kapilash Temple: The temple is situated at about 2239 feet above the
													sea level. The temple has a wooden Jagamohan. The deities are Sri
													Ganesh, Kartikeya, Gangadevi are found in the temple. "LordChandra
													Sekhar" is the presiding God in this holy place.</li>
												<li>Saptyasajya, a place of scenic beauty, where the holy temple of
													"Maryada Purusottama Sri Ramachandra" is located at about 900 feet
													at the hilltop.</li>
												<li>The hill has several caves as well as the ruins of a mediaeval fort
													which are regularly visited by pilgrims.</li>
												<li>The great names of Shridhar Swami, the celebrated commentator of the
													Bhagabat and Mahima Gosain, the founder of Mahima Cult, are
													historically associated with the place.</li>
												<li>The Kapilash Zoo and the Science Park are two other added
													attractions of the place.</li>
											</ul>
										</div>
									</div>
									<h4 class="card-title">Salient Detail</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td width="15%"><strong>HQ Address :</strong></td>
													<td width="85%">At/Po.-Dhenkanal</td>
												</tr>
												<tr>
													<td><strong>Contact officer : </strong></td>
													<td>
														<span class="address-inner"><i
																class="fa fa-user">&nbsp;</i></span>Divisional Forest
														Officer<br>
														<span class="address-inner"><i
																class="fa fa-map-marker">&nbsp;</i></span>Dhenkanal
														Forest Division, Dhenkanal <br>
														<span class="address-inner"><i
																class="fa fa-phone">&nbsp;</i></span>(06762) 226717<br>
														<span class="address-inner"><i
																class="fa fa-fax">&nbsp;</i></span>(06762) 226717<br>
														<span class="address-inner"><i
																class="fa fa-envelope">&nbsp;</i></span>dfo.dhenkanal@gmail.com<br>
													</td>
												</tr>
												<tr>
													<td><strong>Key activities : </strong></td>
													<td>Forest Protection,anti-poaching,Control illegal cutting of
														trees,Control on other illegal activities such as collection of
														boulders, sand from nalas, murrum collection etc.</td>
												</tr>
												<tr>
													<td><strong>Programmes : </strong></td>
													<td>Wildlife health programmes,Inter-agency programme,etc</td>
												</tr>
												<tr>
													<td><strong>Places to see : </strong></td>
													<td>Deer park, Science park at Kapilash, Dattatreya Ashram of Sai
														Baba at harekrushnapur, Tapoban Ashram of Alekha Dharma,
														Saptasajya Eco-tourism, [oranda the origin of Mahima Cult.</td>
												</tr>
											</tbody>
										</table>
									</div>
									<p class="text-danger"><small><i
												class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
											</strong>The information contained herein are provided solely for
											informational purposes only.The content is dynamic and may undergo change
											from time to time. The snapshot of the content at a particular time is not
											to be referred as a legally binding document.</small></p>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>