import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homeinfo',
  templateUrl: './homeinfo.component.html',
  styleUrls: ['./homeinfo.component.css']
})
export class HomeinfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
