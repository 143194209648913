<div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div>

<app-masterheader></app-masterheader>
<div class="wrapper">

    <app-sidebar></app-sidebar>

    <div id="content">

        <!-- <app-breadcrumb></app-breadcrumb> -->

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                	<div class="row">
						<div class="col-md-12">
							<div class="card">
								<div class="card-body">
									<h4 class="card-title">Blog</h4>
									<form  [formGroup]="blogForm" (ngSubmit)="addBlog()">
										<div class="row form-group">
											<div class="col-md-6">
			                                    <label>Title <span class="text-danger">*</span></label>
                                          <input class="form-control" type="text" placeholder="Title" formControlName="title" [ngClass]="{ 'is-invalid': blogForm.controls['title'].invalid && (blogForm.controls['title'].dirty || blogForm.controls['title'].touched) }"/>
                                          <div *ngIf="blogForm.controls['title'].invalid && (blogForm.controls['title'].dirty || blogForm.controls['title'].touched)" class="text-danger mt-1">
                                            <small *ngIf="blogForm.controls['title'].errors.required">
                                              Title is required.
                                            </small>
                                          </div>
			                                </div>
			                                <div class="col-md-6">
			                                    <label>Image Upload</label>
                                          <input type="file" formControlName="blogImg" accept='image/*' (change)="onSelectBlogImg($event)"  class="form-control"  placeholder="Upload  Image">
			                                </div>
			                            </div>
			                            <div class="row form-group">
			                                <div class="col-md-12">
			                                    <label>Description <span class="text-danger">*</span></label>
                                          <mat-form-field appearance="outline" class="custom-mat-textarea">
                                          <textarea id="desc" rows="" formControlName="blogDesc" class="form-control" placeholder="Description" #desc matInput  matTextareaAutosize ></textarea>
			                                    </mat-form-field>
                                          <div *ngIf="blogForm.controls['blogDesc'].invalid && (blogForm.controls['blogDesc'].dirty || blogForm.controls['blogDesc'].touched)" class="text-danger mt-1">
                                            <small *ngIf="blogForm.controls['blogDesc'].errors.required">
                                              Description is required.
                                            </small>
                                          </div>

                                        </div>
										</div>
										<hr>
										<div class="row form-group">
											<div class="col-md-12 text-center">
				                                <button type="submit" class="btn btn-sm btn-success" [disabled]="!blogForm.valid">Submit</button>
				                            </div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-12">
							<div class="card">
								<div class="card-body">

									<div class="table-responsive">
		                            	<table id="tableBlog" class="table table-bordered" width="99%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
		                            		<thead>
		                            			<tr>
		                            				<th width="6%">Sl. No.</th>
		                            				<th>Title</th>
		                            				<th>Description</th>
                                        <th *ngIf="roleId==1">Created By</th>
                                        <th width="7%">Date</th>
		                            				<th>Image</th>
		                            				<th width="7%">Action</th>
		                            			</tr>
		                            		</thead>
                                    <tbody>
                                      <tr *ngFor="let blog of blogList; let i = index" [attr.data-index]="i">
                                        <td>{{i+1}} </td>
                                        <th >{{blog.title}}</th>
                                        <td >{{blog.blogDesc}}</td>
                                        <th *ngIf="roleId==1">{{blog.createdByName}}</th>
                                        <td >{{ (blog.craetedOn  !== 'null') ? (blog.craetedOn | date:'dd-MM-yyyy')  : '' }} {{ (blog.craetedOn  !== 'null') ? (blog.craetedOn  | date:'shortTime')  : '' }}</td>
                                        <td > <img src={{blog.blogImgPath}} (error)="defaultUrl($event)"  class="img-blog-app" alt="image"> </td>
                                        <td>
                                          <button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditBlog" 	(click)="getblogRecordByID(blog)">
                                            <i class="fa fa-edit"></i></button>
                                          <button class="btn btn-sm btn-danger"  (click)="deleteBlog(blog)"><i class="fa fa-trash"></i></button>
                                        </td>
                                      </tr>
                                    </tbody>
		                            		<!-- <tbody>
		                            			<tr>
		                            				<td>1</td>
		                            				<td>Human elephant conflict management – A review of current management and future directions in Jharsuguda forest division, Odisha</td>
		                            				<td>Human elephant conflict management – A review of current management and future directions in Jharsuguda forest division, Odisha is an attempt to describe the current scenario of management and future suggestions to improve it. the study has been done by me during my ON JOB TRAINING in ACF training to Jharsuguda Division. Pradeep Kumar Dey ACF, Similipal North WL Division.</td>
		                            				<td>12-04-2021 11:15 AM</td>
                                        <td><img src="assets/images/blog-01.jpg" class="img-blog-app" alt="image"></td>
		                            				<td>
		                            					<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditBlog">
	                            							<i class="fa fa-edit"></i>
														</button>
														<button class="btn btn-sm btn-danger">
	                            							<i class="fa fa-trash"></i>
														</button>
		                            				</td>
		                            			</tr>
		                            			<tr>
		                            				<td>2</td>
		                            				<td>Supply of FRP 10 Seater Sea Worthy Speed Boats with outboard Petrol engine which should be feasible for large tidal affected creeks with comprehensive warranty and maintenance in Balasore WL Division</td>
		                            				<td>Supply of FRP 10 Seater Sea Worthy Speed Boats with outboard Petrol engine which should be feasible for large tidal affected creeks with comprehensive warranty and maintenance in Balasore WL Division.</td>
		                            				<td>12-04-2021</td>
                                        <td><img src="assets/images/blog-02.jpg" class="img-blog-app" alt="image"></td>
		                            				<td>
		                            					<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditBlog">
	                            							<i class="fa fa-edit"></i>
														</button>
														<button class="btn btn-sm btn-danger">
	                            							<i class="fa fa-trash"></i>
														</button>
		                            				</td>
		                            			</tr>
		                            			<tr>
		                            				<td>3</td>
		                            				<td>Supply Installation and Commissioning of HDPE Floating Jetties in Bichitrapur Mangrove and Risia Nature Camp Under Balasore WL Division</td>
		                            				<td>Supply Installation and Commissioning of HDPE Floating Jetties in Bichitrapur Mangrove and Risia Nature Camp Under Balasore WL Division.</td>
		                            				<td>12-04-2021</td>
                                        <td>-</td>
		                            				<td>
		                            					<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditBlog">
	                            							<i class="fa fa-edit"></i>
														</button>
														<button class="btn btn-sm btn-danger">
	                            							<i class="fa fa-trash"></i>
														</button>
		                            				</td>
		                            			</tr>
		                            		</tbody> -->
		                            	</table>
		                            </div>

								</div>
							</div>
						</div>
					</div>

                </div>
            </div>
        </div>

    </div>

    <!-- Modal Blog -->
    <div class="modal fade" id="modalEditBlog" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title" id="exampleModalLongTitle">Edit Blog</h4>
					<button type="button"  #closebutton class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<form [formGroup]="updateBlogForm" (ngSubmit)="UpdateBlog()">
						<div class="row form-group">
							<div class="col-md-6">
								<label>Title <span class="text-danger">*</span></label>
                <input type="hidden" formControlName="blogid">
								<input type="text" formControlName="title" [ngClass]="{ 'is-invalid': updateBlogForm.controls['title'].invalid && (updateBlogForm.controls['title'].dirty || updateBlogForm.controls['title'].touched) }" class="form-control"  placeholder="Title">
                <div *ngIf="updateBlogForm.controls['title'].invalid && (updateBlogForm.controls['title'].dirty || updateBlogForm.controls['title'].touched)" class="text-danger mt-1">
                  <small *ngIf="updateBlogForm.controls['title'].errors.required">
                    Title is required.
                  </small>
                </div>
							</div>
							<div class="col-md-6">
								<label>Image Upload</label>
                <input formControlName="file" type="file"  class="form-control" accept='image/*' (change)="onSelectBlogImg($event)" >
								<!-- <input type="file" formControlName="blogIMg" class="form-control" placeholder="Image Upload"> -->
							</div>
						</div>
						<div class="row form-group">
							<div class="col-md-12">
								<label>Description <span class="text-danger">*</span></label>
								<textarea rows=""  placeholder="Description" formControlName="blogDesc" [ngClass]="{ 'is-invalid': updateBlogForm.controls['blogDesc'].invalid && (updateBlogForm.controls['blogDesc'].dirty || updateBlogForm.controls['blogDesc'].touched) }" class="form-control"  >
                </textarea>
                <div *ngIf="updateBlogForm.controls['blogDesc'].invalid && (updateBlogForm.controls['blogDesc'].dirty || updateBlogForm.controls['blogDesc'].touched)" class="text-danger mt-1">
                  <small *ngIf="updateBlogForm.controls['blogDesc'].errors.required">
                    Desc is required.
                  </small>
                </div>
							</div>
						</div>
						<hr>
						<div class="row">
						    <div class="col-md-12 text-center">
						    	<button type="submit" class="btn btn-sm btn-success">Update</button>
						    </div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>

    <div id="footer">
        <app-masterfooter></app-masterfooter>
    </div>

</div>

