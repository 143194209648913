
<section class="font-script main-notice">
  <div id="myCarousel" class="carousel slide carousel-fade" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active" style="background:url('assets/images/bg-slider-01.jpg');background-size:cover">
        <div class="mask flex-center">
          <div class="container">
            <div class="row align-items-center">
              <!-- <div class="col-md-6 offset-md-3 col-12 order-md-1 order-2 text-center">
                <img src="assets/images/iWLMS-logo.png" class="mx-auto img-fluid mb-2 logo-iwlms" alt="logo">
                <h4><span>integrated</span> Wildlife Management System (<span>i</span>WLMS)</h4>
              </div> -->
              <!-- <div class="col-md-3 col-12 order-md-2 order-1"><img src="assets/images/slider-01.png" class="mx-auto" alt="slide"></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item" style="background:url('assets/images/bg-slider-02.jpg');background-size:cover">
        <div class="mask flex-center">
          <div class="container">
            <div class="row align-items-center">
              <!-- <div class="col-md-6 offset-md-3 col-12 order-md-1 order-2 text-center">
                <img src="assets/images/iWLMS-logo.png" class="mx-auto img-fluid mb-2 logo-iwlms" alt="logo">
                <h4><span>integrated</span> Wildlife Management System (<span>i</span>WLMS)</h4>
              </div> -->
              <!-- <div class="col-md-3 col-12 order-md-2 order-1"><img src="assets/images/slider-02.png" class="mx-auto" alt="slide"></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item" style="background:url('assets/images/bg-slider-03.jpg');background-size:cover">
        <div class="mask flex-center">
          <div class="container">
            <div class="row align-items-center">
              <!-- <div class="col-md-6 offset-md-3 col-12 order-md-1 order-2 text-center">
                <img src="assets/images/iWLMS-logo.png" class="mx-auto img-fluid mb-2 logo-iwlms" alt="logo">
                <h4><span>integrated</span> Wildlife Management System (<span>i</span>WLMS)</h4>
              </div> -->
              <!-- <div class="col-md-3 col-12 order-md-2 order-1"><img src="assets/images/slider-03.png" class="mx-auto" alt="slide"></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item" style="background:url('assets/images/bg-slider-04.jpg');background-size:cover">
        <div class="mask flex-center">
          <div class="container">
            <div class="row align-items-center">
              <!-- <div class="col-md-6 offset-md-3 col-12 order-md-1 order-2 text-center">
                <img src="assets/images/iWLMS-logo.png" class="mx-auto img-fluid mb-2 logo-iwlms" alt="logo">
                <h4><span>integrated</span> Wildlife Management System (<span>i</span>WLMS)</h4>
              </div> -->
              <!-- <div class="col-md-3 col-12 order-md-2 order-1"><img src="assets/images/slider-04.png" class="mx-auto" alt="slide"></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item" style="background:url('assets/images/bg-slider-05.jpg');background-size:cover">
        <div class="mask flex-center">
          <div class="container">
            <div class="row align-items-center">
              <!-- <div class="col-md-6 offset-md-3 col-12 order-md-1 order-2 text-center">
                <img src="assets/images/iWLMS-logo.png" class="mx-auto img-fluid mb-2 logo-iwlms" alt="logo">
                <h4><span>integrated</span> Wildlife Management System (<span>i</span>WLMS)</h4>
              </div> -->
              <!-- <div class="col-md-3 col-12 order-md-2 order-1"><img src="assets/images/slider-04.png" class="mx-auto" alt="slide"></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="carousel-item" style="background:url('assets/images/bg-slider-06.jpg');background-size:cover">
        <div class="mask flex-center">
          <div class="container">
            <div class="row align-items-center">
              <!-- <div class="col-md-6 offset-md-3 col-12 order-md-1 order-2 text-center">
                <img src="assets/images/iWLMS-logo.png" class="mx-auto img-fluid mb-2 logo-iwlms" alt="logo">
                <h4><span>integrated</span> Wildlife Management System (<span>i</span>WLMS)</h4>
              </div> -->
              <!-- <div class="col-md-3 col-12 order-md-2 order-1"><img src="assets/images/slider-04.png" class="mx-auto" alt="slide"></div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="carousel-item" *ngIf="isExpired" style="background:url('assets/images/bg-slider-olive-rider.jpg');background-size:cover">
        <div class="mask flex-center">
          <div class="container">
            <div class="row align-items-center">
           
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
      <!-- <span class="carousel-control-prev-icon" aria-hidden="true"></span> -->
      <i class="fa fa-angle-left"></i>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
        <!-- <span class="carousel-control-next-icon" aria-hidden="true"></span> -->
        <i class="fa fa-angle-right"></i>
        <span class="sr-only">Next</span>
    </a>
  </div>

  <div class="custom-main-notice">
    <h3>Notice</h3>
    <marquee behavior="scroll" direction="up" scrollamount="2" onmouseover="this.stop();" onmouseout="this.start();">
        <ul>
          <li *ngFor="let group of noticeList" >
            <a  href="{{group.uploadPath}}" target="_blank">{{group.noticeName}}
              <img src="assets/images/new_icon.gif" *ngIf="group.noticeIsExpire">
              <br><small>Notice Date : {{ (group.noticeDate  !== 'null') ? (group.noticeDate | date:'dd-MM-yyyy')  : '' }}</small>
          </a>
          <a [routerLink]="['/notices']" class="mb-3 pull-right">View All</a>
        </li>
        </ul>

      <!-- <ul>

        <li><a href="/notices">Auction Sale of Cashew Nut of Mangrove Forest Division (Wildlife) Rajnagar.<br><small>16.09.2021</small></a></li>
        <li><a href="/notices">Nomination for the Biju Patnaik Award for Wildlife Conservation - 2020.<br><small>04.09.2021</small></a></li>
        <li><a href="/notices">Nomination for the Biju Patnaik Award for Wildlife Conservation at Sub-divisional level 2020.<br><small>30.08.2021</small></a></li>
        <li><a href="/notices">Quotation call notice for AMC of CCTV.<br><small>12.08.2021</small></a></li>
        <li><a href="/notices">Quotation call notice for AMC of EPABX / Intercom system machine.<br><small>05.08.2021</small></a></li>
        <li><a href="/notices">Quotation call notice for AMC of Video Conferencing.<br><small>21.07.2021</small></a></li>
        <li><a href="/notices">Empanellment of Organisation.<br><small>14.07.2021</small></a></li>
        <li><a href="/notices">Guidelines for water pipeline and OFC inside PAs.<br><small>08.07.2021</small></a></li>
      </ul> -->
    </marquee>
  </div>
  <div class="custom-main-organization">
      <img src="assets/images/organization_chart.png" class="img-main-org">
  </div>

</section>
