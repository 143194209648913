import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest, HttpEvent, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import { environment } from 'src/environments/environment.prod';
import { retry } from 'rxjs/operators';


const baseUrl = environment.API_URL;
const baseUrlFile = environment.FILE_API_URL;

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private loggedIn = false;
  private token: string;

  constructor(private http: HttpClient) { }

  pushFileToStorage(file: File,userId:string,type:string):Observable<any> {
    let formdata: FormData = new FormData();

    formdata.append('file', file);

    // const req = new HttpRequest('POST', baseUrl+'api/v1/uploadController/uploadProfileImage?userId=1001', formdata, {
    //   reportProgress: true,
    //   responseType: 'text'
    // });
    // console.log(req)

    // return this.http.request(req);
   return this.http.post(baseUrl+'api/v1/uploadController/uploadImage?userId='+userId+'&type='+type, formdata
      ,{}).pipe(retry(1));

  }

  getFiles(route: string): Observable<any> {
    const header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;
    return this.http.get(baseUrl + route, {
     // headers: header
     });
  }

}
