<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
			        <li class="nav-item">
			            <a id="tab-satkosiamap" href="#pane-satkosiamap" class="nav-link active" data-toggle="tab" role="tab">Map</a>
			        </li>
			        <li class="nav-item">
			            <a id="tab-satkosiainfo" href="#pane-satkosiainfo" class="nav-link" data-toggle="tab" role="tab">Information</a>
			        </li>
			    </ul>

			    <div id="accordion" class="tab-content" role="tablist">
			        <div id="pane-satkosiamap" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-satkosiamap">
			            <div class="card-header" role="tab" id="heading-satkosiamap">
			                <h5 class="mb-0">
			                    <a data-toggle="collapse" href="#collapse-satkosiamap" aria-expanded="true" aria-controls="collapse-satkosiamap">
			                        Map
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-satkosiamap" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-satkosiamap">
			                <div class="card-body">
								<div class="map" id="satakosiaWLmap">
                                    <div class="gle-icon">
										<a href="javascript:void(0)">
                                            <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid" alt="Street View" title="Street View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()' alt="Satellite View" title="Satellite View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View" title="Topo View">
                                        </a>
                                    </div>
                                </div>


			                </div>
			            </div>
			        </div>

			        <div id="pane-satkosiainfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-satkosiainfo">
			            <div class="card-header" role="tab" id="heading-satkosiainfo">
			                <h5 class="mb-0">
			                    <a class="collapsed" data-toggle="collapse" href="#collapse-satkosiainfo" aria-expanded="false" aria-controls="collapse-satkosiainfo">
			                       Information
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-satkosiainfo" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-satkosiainfo">
			                <div class="card-body">
								<h4 class="card-title">
									Satkosia Wildlife Division
									<span class="custom-down">
                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
					<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
						class="fa fa-file-pdf">&nbsp;</i>Map</button>
			                		</span>
								</h4>
                <div id="printcontent">
                                <div class="row">
                                	<div class="col-md-9">
                                		<div class="table-responsive">
		                                	<table class="table table-sm table-bordered">
		                                		<tbody>
		                                			<tr>
		                                				<td><strong>District : </strong></td>
		                                				<td>Angul</td>
		                                			</tr>
                                                    <tr>
                                                        <td><strong>Geographical Extent : </strong></td>
                                                        <td>Longitude 84° 39' 00 "E and 85° 5' 24"E & Latitude 20° 25' 12"N and 20° 45' 36" N</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Climate : </strong></td>
                                                        <td>Temperature - In summer: 47°C, in winter: 4.5°C Average Annual rainfall - 1300 mm, Rainy days: 75. Average humidity - 80% but at mid day in summer this can be 10%</td>
                                                    </tr>
		                                		</tbody>
		                                	</table>
		                                </div>
                                	</div>
                                	<div class="col-md-3">
                                		<img src="assets/images/wildlifeunits-satkosia-01.jpg" class="img-fluid float-right img-inner" alt="image">
                                	</div>
                                </div>
                                <p>Satkosia spreads along the magnificent gorge over the mighty river Mahanadi in Odisha. Established in 1976 as a wildlife sanctuary, Satkosia is a paradise of immense scenic charm. It is one of the best ecosystems in the country, representing a diverse floral and faunal extravaganza. The name Satkosia originates from two words; sat meaning seven and kos meaning two miles, indicating the length of the gorge as 14 miles or 22 km. The area was declared as Satkosia Tiger Reserve in 2007, comprising two adjoining wildlife sanctuaries; the Satkosia Gorge sanctuary and Baisipalli sanctuary. The Reserve is spread over 4 districts like; Angul, Cuttack, Nayagarh and Boudh. The reserve has an area of 963.87sq km with 523.61sq km as core area. The area is also a part of the Mahanadi elephant reserve. Satkosia is the meeting point of two bio-geographic regions of India; the Deccan Peninsula and the Eastern Ghats, contributing immense biodiversity. About 530.00 Sq.kms. area of the Satkosia Tiger Reserve comes under Satkosia Wildlife Division and rest of the area comes under Mahanadi Wildlife Division. The total forest area in Satkosia Wildlife Division is 512.82 Sq.km. whereas the total geographical area of this Division is 663.05 Sq.kms.</p>
                                <h4 class="card-title">Flora </h4>
                                <p>The magnificent Hinjala, Dhauranja, Kochila , Arjuna and Phasi forests on the banks of river Mahanadi are typical elements of riverine forests. The floral diversity comprises mainly Shorea robusta, Dillenia pentagyna, Bridelia squamosa, Terminalia alata, Terminalia arjuna, Syzygium cuminii, Adina cordifolia (Haldinia cordifolia), Mitragyna parviflora, Lagerstroemia parviflora, Mangifera indica, Bursera serrata, Pterocarpus marsupium, Terminalia bellerica, Anthocephalus cadamba, Bombax ceiba, Gmelina arborea, Garuga piñnata. Suzygium cuminii, Miliusa velutina, Polyalthia cerasioides, Lannea coromandelica, Pterospermum xylocarpum, Macaranga peltata, Diospyrus Montana, Derris indica, Mallotus philippensis, Careya arborea, Glochidion lanceolarium. Bamboo brakes consisting of two species of bamboo, namely Bambusa arundinaceae and Dendrocalamus strictus are common to this Tiger reserve.</p>
                                <h4 class="card-title">Fauna </h4>
                                <p>The fauna includes Elephant, Tiger, Wild Dog, Sambar, Spotted Deer, Chousingha, Barking Deer, Mouse deer, Sloth bear, Indian gaur, Leopard, Jackal, Giant Squirrel, Indian porcupine, Indian hare, Ratel etc. The Gorge also has turtles, terrapins, carps, molluscs besides other aquatic life forms and amphibians. 332 species of birds have been documented inside the Tiger Reserve. The avifauna is rich and varied with both resident and migratory birds. The resident birds include the Malabar Pied Hornbills, Pea fowls, Kingfishers, Egrets, Cormorants, River terns, Owls, Pigeons, Hill myna, Parakeets, Drongo, Doves, Wood pecker, Golden oriole, Babblers, Barbets, Minivets, Sun birds, Eagle, Crow pheasants, Shaheen Falcon, Yellow legged bottom quail, Malabar trogon, Great stone plover, Black-bellied tern, River lapwing, blue tail bee-eater etc. The migratory birds include Brahminy ducks, Bar-headed geese, Pintails, Indian skimmer etc. 136 species of rare and endangered birds including butter flies are very common. The Satkosia Gorge of River Mahanadi inside the Tiger Reserve harbours a large population of varied fishes and aquatic fauna contributing housing of 4 species of endangered Turtles.</p>
                                <h4 class="card-title">Set-Ups</h4>
                                <div class="table-responsive">
                                	<table class="table table-sm table-bordered">
                                		<tbody>
                                            <tr>
                                				<td><strong>Administrative set-up (Range, Section, beats) :</strong></td>
                                				<td>Ranges- 5, Sections- 15,Beats- 45</td>
                                			</tr>
                                			<tr>
                                				<td><strong>EDC/JFM set-up:  </strong></td>
                                				<td>EDCs - 32, VSS - 31</td>
                                			</tr>
                                			<tr>
                                        <td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
                                        <td>	No. of RF- 16, No. of PRF- 3, No. of DPF- 4, No. of UDPF- 0, No. of VF- 0</td>
                                      </tr>
                                		</tbody>
                                	</table>
                                </div>
                                <h4 class="card-title">Major Attraction</h4>
                                <div class="row">
                                    <div class="col-md-12">
                                        <img src="assets/images/wildlifeunits-satkosia-02.jpg" class="img-fluid float-right img-inner" alt="image">
                                        <ul class="custom-list">
                                        	<li>Major attraction of the sanctuary is the magnificent gorge, river Mahanadi, the Gharial Research and Conservation Unit at Tikarapada, towering hills & hill slopes, various trekking routes and river cruise in Mahanadi, Greenery & tranquillity of the place.</li>
                                        </ul>
                                    </div>
                                </div>
                                <h4 class="card-title">Salient Detail</h4>
                                <div class="table-responsive">
                                	<table class="table table-sm table-bordered">
                                		<tbody>
                                            <tr>
                                				<td width="15%"><strong>HQ Address :</strong></td>
                                				<td width="85%" colspan="2">AT- HAKIMPADA, PO.-HAKIMPADA, DIST - ANGUL, STATE-ODISHA, PIN - 759143.</td>
                                			</tr>
                                			<tr>
                                				<td><strong>Contact officer : </strong></td>
                                				<td>
                                					<span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>1. Divisional Forest Officer<br>
                                					<span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Satkosia Wildlife Division, Angul <br>
                                					<span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06764236219<br>
                                					<span class="address-inner"><i class="fa fa-fax">&nbsp;</i></span>06764-236218<br>
                                                    <span class="address-inner"><i class="fa fa-mobile">&nbsp;</i></span>8280146664<br>
                                                </td>
                                                <td>
                                                    <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>2. Asst. Conservator of Forests<br>
                                        			<span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Satkosia Wildlife Division, Angul<br>
                                                    <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06764236356<br>
                                                    <span class="address-inner"><i class="fa fa-mobile">&nbsp;</i></span>9437190004<br>
                                                </td>
                                			</tr>
                                			<tr>
                                				<td><strong>Key activities : </strong></td>
                                				<td colspan="2">Forest protection, Habitat management, improvement & restoration, Fire prevention, raising of nursery and plantation, Wildlife conservation, Prevention of wildlife poaching, electrocution of wild animals as well as elephants, checking of illegal fishing in river Mahanadi, Creation & maintenance of water bodies, maintenance of forest roads, buildings, check gates, creation of meadows, saltlicks and maintenance.</td>
                                			</tr>
                                			<tr>
                                				<td><strong>Programmes : </strong></td>
                                				<td colspan="2">Observation of Vanamahotsava, Wildlife Week, World Forestry Day, World Biodiversity conservation Day, World Elephant Day,International Tiger Day etc.</td>
                                            </tr>
                                            <tr>
                                				<td><strong>Places to see : </strong></td>
                                				<td colspan="2">Gharial Research & Conservation Unit ( GRACU) at Tikarpada, Satkosia Gorge at Tikarpada, Nature camp at Chhotkei, Wildlife Conservation and Awareness Centre at Baghmunda, Ecotourism sites at Tarava, Tikarpada, Baliput and Purunakote, Maa Binkei temple and river view at Binkei, God Mahadev temple at Tikarpada, Goddess Maa Kalapat at Chhotkei.</td>
                                			</tr>
                                		</tbody>
                                	</table>
                                </div>
                                <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>
                              </div>
			                </div>
			            </div>
			        </div>

			    </div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>
