import { DatePipe } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { NotificationService } from '../service/notification.service';
import { UploadService } from '../upload/upload.service';
import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import * as layer from 'ol/layer';
import { Tile as Tile, Vector as VectorLayer, Image as ImageLayer } from 'ol/layer';
import { OSM as OSM, Vector as VectorSource, Vector as MeasureSource } from 'ol/source';
import { WFS, GeoJSON } from 'ol/format';
import ImageWMS from 'ol/source/ImageWMS';
import { View } from 'ol';
import { Icon, Style, Fill, Stroke } from 'ol/style';
import * as control from 'ol/control';
import * as proj from 'ol/proj';
import { environment } from 'src/environments/environment.prod';

const wms_url = environment.GEOSERVER_WMS_URL;
declare var jQuery: any;
map: Map;

@Component({
  selector: 'app-elephantwebreporting',
  templateUrl: './elephantwebreporting.component.html',
  styleUrls: ['./elephantwebreporting.component.css']
})
export class ElephantwebreportingComponent implements OnInit {
  public isShownrepotingFormDiv:boolean=false;
  public isShownReporting: boolean = false ;
  public isShownImgUpload: boolean = false ;
  public isShownNumberBox:boolean=false;
  public isShownRemarksBox:boolean=false;
  public isShownDivRngSecDropdown:boolean=false;
  public isTrumptingSound:boolean=false;
  public isTuskerInfo:boolean=false;

  public divisionDisabled:boolean=true;
  public totalNumberDisabled:boolean=true;

  public divid: string;
  public rangeid: string;
  public sectionid: string;
  public beatid: string;

  public tusker: number=0;
  public tuskerAdult: number=0;
  public tuskerSubAdult: number=0;
  public makhna: number=0;
  public female: number=0;
  public calf: number=0;
  public total:number=0;

  public trumptingApproxDistance:string="";
  public trumptingDirection:string="";

  public divisionlist: any;
  public rangelist: any;
  public sectionlist: any;
  public beatlist: any;

  roleIdSession: any;
  juridictionIdSession: any;
  circleIdSession: any;
  divisionIdSession: any;

  repotingForm: any;
  selectedFiles: any;
  username: any;

  @ViewChild('from_Date') from_Date_ElementRef : ElementRef;
  @ViewChild('from_time') from_time_ElementRef : ElementRef;
  @ViewChild('to_time') to_time_ElementRef : ElementRef;
  cuurentFilename: "";
  userid: any;
  private _CircleBoundary: any;
  private _DivisionBoundary: any;
  map: Map;
  circleSelected: any;
  divisionSelected: any;
  latitude: any="";
  longitude: any="";
  vectorPoint: any;
  popup: any;
  closer: any;

  constructor(private formBuilder: FormBuilder, private apiService: ApiService,private datePipe: DatePipe,private notifyService:NotificationService, private router: Router,private uploadService: UploadService) { }

  ngOnInit(): void {
    var _this=this;
    (function($){

      $('#mapmodal').on('shown.bs.modal', function () {
        if(_this.map==null){
          _this.initiatemap();
        }
        else{
          _this.latitude="";
          _this.longitude="";
          if(_this.vectorPoint!=null)
          _this.vectorPoint=null;
          _this.popup.setPosition(undefined);
          _this.closer.blur();
        }
         
       });
  		$("#datepicker").datepicker({
	        autoclose: true,
	        todayHighlight: true
      	}).datepicker('update', new Date());
  		$('.clockpicker').clockpicker();

    })(jQuery);
    this.divid="0";
   this.rangeid="0";
    this.sectionid="0";
   this.beatid="0";

    this.repotingForm= this.formBuilder.group({
      type:['0', Validators.required],
      fromDate:[''],
      location:['', Validators.required],
      latitude: ['', [Validators.required,Validators.pattern('^(\\+|-)?(?:90(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,6})?))$')]],
      longitude: ['', [Validators.required,Validators.pattern('^(\\+|-)?(?:180(?:(?:\\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,6})?))$')]],
      fromtime:['09:30'],
      totime:['09:30'],
      indirectReportingType:['0'],
      trumptingApproxDistance:[''],
      trumptingDirection:[''],
      remarks:[''],
      imgPath:[''],
      division: [{value: '',disabled:true}],
      range:[''],
      section:[''],
      beat:[''],
      total:[{value: 0,disabled:true}],
      herd:[0],
      tusker:[0],
      tuskerAdult:[0],
      tuskerSubAdult:[0],
      makhna:[0],
      female:[0],
      calf:[0],
    });
    this.repotingForm.get('fromDate').patchValue(this.formatDate(new Date()));
    const userdata=sessionStorage.getItem('user');
    //console.warn(userdata)
    if(userdata!=null){
      const user=JSON.parse(userdata);
      this.username=user.username;
      this.userid=user.userid;
      this.roleIdSession=user.roleId;
      this.juridictionIdSession=user.juridictionId;
      this.circleIdSession=user.circleId;
      this.divisionIdSession=user.divisionId;
      this.getAllDivision();
    }else{

    }

  }

  initiatemap(){
     //google Image
		var imegary = new Tile({
			visible: true,
			source: new OSM({
				url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
			})
		});
      var streerMap = new Tile({
        visible: true,
        source: new OSM({
          url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        })
      });
      // State Boundary
      var _StateBoundary = new layer.Image({
        source: new ImageWMS({
          url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wms',
          params: {
            'FORMAT': 'image/png',
            'VERSION': '1.1.0',
            STYLES: '',
            LAYERS: 'iwlms:odisha_state_boundary'
          },
          serverType: 'geoserver',
          crossOrigin: 'anonymous'
        }),
        visible: true
      });
      this.map = new Map({
        layers: [imegary, _StateBoundary],
        controls: [
          new control.Zoom(),
          new control.ZoomSlider(),
          new control.FullScreen(),
          new control.ScaleLine
            ({
              units: 'metric',
              bar: true,
              steps: 4,
              text: true,
              minWidth: 140
            })
        ],
        target: 'map',
        view:
          new View({
            center: proj.fromLonLat([85.190055, 20.886785]),
            zoom: 7,
            maxZoom: 23,
            minZoom: 5,
          })
      });
      this.setboundary();
      var _this=this;
           // generate map to its target and set to a global variable
    const container = document.getElementById('popup');
    const content = document.getElementById('popup-content');
    this.closer = document.getElementById('popup-closer');
    this.popup = new Overlay({
        element: container,
        autoPan: {
          animation: {
            duration: 250,
          },
        },
      });
     this.closer.onclick = function () {
      _this.popup.setPosition(undefined);
        _this.closer.blur();
        return false;
      };
      this.map.addOverlay(_this.popup);

      this.map.on('click', function (evt) {
            var lonlat = proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
            this.longitude = lonlat[0].toString().substring(0, 9);
            this.latitude= lonlat[1].toString().substring(0, 9);
            _this.map.removeLayer(this.vectorPoint);
            var pointSource= new VectorSource({});
            var iconFeature = new Feature({
              geometry: new Point([this.longitude, this.latitude]).transform('EPSG:4326', 'EPSG:3857'),
              name: 'A point',
          });
         pointSource.addFeature(iconFeature);
        
            this.vectorPoint = new VectorLayer({
              source: pointSource,
              style: new Style({
                image: new Icon({
                  src: "../assets/images/indirectmarker.png"
                })
              })
            });
            _this.map.addLayer(this.vectorPoint);
           _this.map.getView().fit(this.vectorPoint.getSource().getExtent(), { "maxZoom": 16 });
           content.innerHTML = '<p>Latitude :' + this.latitude + '<br>Longitude :' + this.longitude + '</p>';
           _this.popup.setPosition(undefined);
           _this.closer.blur();
           _this.popup.setPosition(evt.coordinate);
          _this.repotingForm.controls.longitude.setValue(this.longitude);
          _this.repotingForm.controls.latitude.setValue(this.latitude);
      });
  }
  setboundary() {
    const userdata = sessionStorage.getItem('user');
    if (userdata != null) {
      const user = JSON.parse(userdata);
       if (user.roleId == 2) {
        this.apiService.request('GET', 'api/v1/masters/getAssignCircleDivisionRangeByUserId?roleId=' + user.roleId + '&jurdicitionId=' + user.juridictionId).subscribe((data: any) => {
          if (data) {
            this.circleSelected = data.circleId;
            this.divisionSelected = data.divisonId;
            this.setTheMaptolayer();
          }
        });
      }
    }
  }
  setTheMaptolayer() {
    var layername_circle = 'https://odishaforestgis.in/ofms/index.php/webservice_api/getCircleGeojson';
    var layername_div = 'https://odishaforestgis.in/ofms/index.php/webservice_api/getDivisionGeojson';
    var querycircle = "";
    var querydiv = "";
    if (this.circleSelected != "0") {
      querycircle = "{\"circle_id\" : \"" + this.circleSelected + "\"}";
    }
    if (this.divisionSelected != "0") {
      querydiv = "{\"division_id\" : \"" + this.divisionSelected + "\"}";
    }
    if (querycircle != "") {
      let formdata: FormData = new FormData();
      formdata.append('data', querycircle);
      try {
        this.apiService.requestGeoJson(layername_circle, formdata).subscribe((response: any[]) => {
          if (response) {
            if (response["status"] == 1) {
              if (this._CircleBoundary) {
                this.map.removeLayer(this._CircleBoundary);
              }
              const styleFunction = [
                new Style({
                  stroke: new Stroke({
                    color: 'rgba(32, 5, 132, 2)',
                    width: 2,
                  }),
                  fill: new Fill({
                    color: 'rgba(0, 0, 255, 0.1)',
                  }),
                })];
              const vectorSource = new VectorSource();
              var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
              var gjFormat = new GeoJSON({
                featureProjection: 'EPSG:3857',
              });
              var features = gjFormat.readFeatures(geojson);
              vectorSource.clear();
              var featureOverlayNew = new VectorLayer({
                // map: this.map,
                source: vectorSource,
                style: styleFunction,
                updateWhileAnimating: true,
                updateWhileInteracting: true
              });
              featureOverlayNew.getSource().addFeatures(features);
              this.map.addLayer(featureOverlayNew);
              this.map.getView().setZoom(10);
              this.map.getView().fit(featureOverlayNew.getSource().getExtent(), "");
              this._CircleBoundary = featureOverlayNew;
            } else {
              this.notifyService.showError("Map Data Not Available", "Error");
            }
          } else {
            this.notifyService.showError("Map Data Not Available", "Error");
          }
        });
      } catch (e) {
        this.notifyService.showError("Something Error!!", "Error");
      } 
    }
    if (querydiv != "") {
        let formdata: FormData = new FormData();
        formdata.append('data', querydiv);
        try {
          this.apiService.requestGeoJson(layername_div, formdata).subscribe((response: any[]) => {
            if (response) {
              if (response["status"] == 1) {
                if (this._DivisionBoundary) {
                  this.map.removeLayer(this._DivisionBoundary);
                }
                const styleFunction = [
                  new Style({
                    stroke: new Stroke({
                      color: 'rgba(31, 28, 28, 2)',
                      width: 2,
                    }),
                    fill: new Fill({
                      color: 'rgba(32, 28, 28, 0.1)',
                    }),
                  })];
                const vectorSource = new VectorSource();
                var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
                var gjFormat = new GeoJSON({
                  featureProjection: 'EPSG:3857',
                });
                var features = gjFormat.readFeatures(geojson);
                vectorSource.clear();
                var featureOverlayNew = new VectorLayer({
                 // map: this.map,
                  source: vectorSource,
                  style: styleFunction,
                  updateWhileAnimating: true,
                  updateWhileInteracting: true
                });
                featureOverlayNew.getSource().addFeatures(features);
                this.map.addLayer(featureOverlayNew);
                this.map.getView().setZoom(10);
                this.map.getView().fit(featureOverlayNew.getSource().getExtent(), "");
                this._DivisionBoundary = featureOverlayNew;
              } else {
                this.notifyService.showError("Map Data Not Available", "Error"); 
              }
            } else {
              this.notifyService.showError("Map Data Not Available", "Error");
            }
          });
        } catch (e) {
          this.notifyService.showError("Something Error!!", "Error");
        } 
    }
  }
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day,month,year].join('-');
  }

  onTypeSelect(value:any){
    if(value!="0"){
      this.isShownrepotingFormDiv=true;
      if(value=="direct" ){
        this.isShownReporting=false;
        this.isShownImgUpload = false ;
        this.isShownNumberBox=true;
        this.isTuskerInfo=true;
        this.repotingForm.controls['tusker'].disable();
        
        this.isShownRemarksBox=false;
        this.isTrumptingSound=false;
        this.repotingForm.value.remarks="";
        this.repotingForm.value.indirectReportingType="";
      }else if(value=="indirect" ){
        this.isShownReporting=true;
        this.isShownImgUpload = false ;
        this.isShownNumberBox=true;
        this.repotingForm.controls['tusker'].enable();
        this.isTuskerInfo=false;
        this.isShownRemarksBox=false;
        this.cuurentFilename="";
        if(this.repotingForm.value.indirectReportingType=="Trumpeting call/Sound"){
          this.isTrumptingSound=true;
        }else{
          this.isTrumptingSound=false;
        }
      }
      else if(value=="nill" ){
        this.isShownReporting=false;
        this.isShownImgUpload = false ;
        this.isShownNumberBox=false;
        this.isTuskerInfo=false;
        this.isShownRemarksBox=false;
        this.isTrumptingSound=false;
        this.cuurentFilename="";
        this.repotingForm.value.remarks="";
        this.repotingForm.value.indirectReportingType="";
        this.tusker=0;
        this.makhna=0;
        this.calf=0;
        this.female=0;
        this.total=0;
      }
    }else{
      this.isShownrepotingFormDiv=false;
    }
  }
  onIndirectReportingTypeSelect(value:any){
    if(value=="Others"){
      this.isShownRemarksBox=true;
    }else{
      this.isShownRemarksBox=false;
    }
    if(value=="Trumpeting call/Sound"){
      this.isTrumptingSound=true;
    }else{
      this.isTrumptingSound=false;
    }
  }
  getAllDivision() {
    this.apiService.request('GET', 'api/v1/masters/getAllDivisionByCircleId?id='+this.circleIdSession).subscribe((data: any) => {
      if(data)
      {
        this.divisionlist=data;
        this.divid=this.divisionIdSession;
        this.getAllRangeByDivId(this.divid);
      }
    });
  }
  getAllRangeByDivId(div:any) {
    if(div!="0"){
   this.apiService.request('GET', 'api/v1/masters/getAllRangeByDivid?id='+div).subscribe((data: any) => {
	    if(data)
	    {
        this.rangelist=data;
        this.rangeid="0";
        if(this.sectionlist!=""){
          this.sectionid="0";
          this.sectionlist=[];
          this.beatlist=[];
        }
      }
    });
  }else{
    if(this.rangelist!=""){
      this.rangelist=[];
      this.sectionlist=[];
      this.beatlist=[];
     }
       this.rangeid="0";
    }
  }
  getAllSectionByRangeId(range:any) {
    if(range!=0){
    this.apiService.request('GET', 'api/v1/masters/getAllSectionByRangeid?id='+range).subscribe((data: any) => {
	    if(data)
	    {
        this.sectionlist=data;
        this.sectionid="0";
        if(this.beatlist!=""){
          this.beatlist=[];
          this.beatid="0";
        }
      }
    });
  }else{
    if(this.sectionlist!=""){
      this.sectionlist=[];
      this.beatlist=[];
     }
     this.sectionid="0";
    }
  }
  getAllBeatBySectionId(section:any) {
    if(section!="0"){
    this.apiService.request('GET', 'api/v1/masters/getAllBeatBySecId?id='+section).subscribe((data: any) => {
	    if(data)
	    {
        this.beatlist=data;
      }
    });
  }else{
    if(this.beatlist!=""){
      this.beatlist=[];
     }
     this.beatid="0";
    }
  }

  imageUpload(){
    if(this.selectedFiles!=undefined){
      this.uploadService.pushFileToStorage(this.selectedFiles.item(0),this.username,"report").subscribe(event => {
          //console.log(event);
          this.cuurentFilename=event.fileName;
        // if (event instanceof HttpResponse) {
        //   //console.log('File is completely uploaded!');
        // }
      })
   }
  }
  selectFile(event) {
    const file = event.target.files.item(0)
    if (file.type.match('image.*')) {
      this.selectedFiles = event.target.files;
    } else {
      this.repotingForm.controls.imgPath.setValue("");
      alert('invalid format!');
    }
  }
  getSumValue(){

      //let sum=Number(this.repotingForm.value.tusker)+Number(this.repotingForm.value.makhna)+Number(this.repotingForm.value.female)+Number(this.repotingForm.value.calf);
    ////console.log(sum);
    if(this.repotingForm.value.type=="direct"){
      this.tusker=this.tuskerAdult+this.tuskerSubAdult;

    }
    
    this.total=this.calf+this.female+this.makhna+this.tusker;
  }
  onSubmitrepotingForm(){
    if(this.repotingForm.value.type=="0"){
      document.getElementById("type").focus();
      alert("Please Select Report Type");
      return false;
    }else{
    let fromDate=this.from_Date_ElementRef.nativeElement.value;
    if(fromDate!=""){
       //let date=this.datePipe.transform(fromDate, 'yyyy-MM-dd HH:mm:ss');

      this.repotingForm.value.fromDate=fromDate;
      //this.repotingForm.value.fromDate=this.formatDateToYrMMDD(fromDate);
    }else{
      alert("Please Provide  date");
      return false;
    }
    let fromtime = this.from_time_ElementRef.nativeElement.value
    if(fromtime==""){
      alert("Please Provide from time");
      return false;
    }else{
      this.repotingForm.value.fromtime= this.formatTimeToYrMMDDHHMM(fromDate,fromtime);
      //console.log( this.repotingForm.value.fromtime);
    }
    let totime = this.to_time_ElementRef.nativeElement.value
    if(totime==""){
      alert("Please Provide To time");
      return false;
    }else{
      this.repotingForm.value.totime= this.formatTimeToYrMMDDHHMM(fromDate,totime);
      //console.log( this.repotingForm.value.totime);
    }
    if(this.repotingForm.value.fromtime>this.repotingForm.value.totime){
      alert(" from time should be smaller than To time");
      return false;
    }
    if(this.repotingForm.value.totime<this.repotingForm.value.fromtime){
      alert(" To time should be greater than from time");
      return false;
    }
    if(this.repotingForm.value.location==""){
      document.getElementById("location").focus();
      alert("Please Provide location");
      return false;
    }if(this.repotingForm.value.latitude==""){
      document.getElementById("latitude").focus();
      alert("Please Provide latitude");
      return false;
    }if(this.repotingForm.value.longitude==""){
      document.getElementById("longitude").focus();
      alert("Please Provide longitude");
      return false;
    }

    //this.directFieldValidation();
    this.indirectFieldValidation();
    //this.formValidation();
   // this.numberBoxValidation();

     if(this.repotingForm.value.indirectReportingType=="0"){
      this.repotingForm.value.indirectReportingType="";
      return true;
     }
     if(this.repotingForm.value.type=="indirect"){
      if(this.repotingForm.value.indirectReportingType=="0"){
        document.getElementById("indirectReportingType").focus();
        alert("Please Select Indirect Report Type");
        return false;
       }
    }

    //  if(this.repotingForm.value.type=="direct"){
    //   //console.log(this.repotingForm.value.imgPath);
    //   if(this.repotingForm.value.imgPath==""){
    //     document.getElementById("imgPath").focus();
    //     alert("Please Select Image");
    //     return false;
    //   }else{
    //         if(this.selectedFiles!=undefined){
    //   this.uploadService.pushFileToStorage(this.selectedFiles.item(0),this.username,"report").subscribe(event => {
    //       //console.log(event);
    //       this.cuurentFilename=event.fileName;
    //       this.insertdata();
    //    })
    //   }

    //   }
    // }else{
    //   this.cuurentFilename="";
    //   this.insertdata();
    // }
         this.cuurentFilename="";
       this.insertdata();
    }
  }
  insertdata(){
    let tusker=this.repotingForm.value.tusker!=null?this.repotingForm.value.tusker:0;
    let tuskerAdult=this.repotingForm.value.tuskerAdult!=null?this.repotingForm.value.tuskerAdult:0;
    let tuskerSubAdult=this.repotingForm.value.tuskerSubAdult!=null?this.repotingForm.value.tuskerSubAdult:0;
    let makhna=this.repotingForm.value.makhna!=null?this.repotingForm.value.makhna:0;
    let female=this.repotingForm.value.female!=null?this.repotingForm.value.female:0;
    let calf=this.repotingForm.value.calf!=null?this.repotingForm.value.calf:0;
    let total=this.total!=null?this.total:0;
    let herd=this.repotingForm.value.herd!=null?this.repotingForm.value.herd:0;
   this.apiService.request('POST', 'api/v1/reports/addReport?evidenceImg=&selfieImg=',
     {
      location:this.repotingForm.value.location,
      sightingTimefrom:this.repotingForm.value.fromtime,
      sightingTimeTo:this.repotingForm.value.totime,
      sightingDate:this.repotingForm.value.fromtime,
      remarks:this.repotingForm.value.remarks,
      division:"",
      range:"",
      section:"",
      beat:"",
      total:total,
      heard:herd,
      tusker:tusker,
      tuskerAdult:tuskerAdult,
      tuskerSubAdult: tuskerSubAdult,
      mukhna:makhna,
      female:female,
      calf:calf,
      latitude:this.repotingForm.value.latitude,
      longitude:this.repotingForm.value.longitude,
      report_through: "web",
      report_type: this.repotingForm.value.type,
      indirectreportType: this.repotingForm.value.indirectReportingType,
      trumptingApproxDistance:this.repotingForm.value.trumptingApproxDistance,
      trumptingDirection: this.repotingForm.value.trumptingDirection,
      user: this.userid,
      accuracy:"",
      altitude:""
     }).subscribe((res: any) => {
          if(res.response=="Reports added successfully"){
            this.notifyService.showSuccess("Reported Successfull","Success");
            //window.location.reload();
            //this.repotingForm.reset();
           this.router.navigateByUrl("/dashboard");
            //this.repotingForm.value.type='0';
            //this.repotingForm.value.indirectReportingType='0';
          }
        
        //window.location.reload();

      });
  }
  formatDateToYrMMDD(date) {
    let dateexct=date.split('-');
    var day = dateexct [0];
    var month = dateexct [1];
    var year = dateexct [2];
    var fullDay=year+'-'+month+'-'+day;
    return fullDay;
 }
 formatTimeToYrMMDDHHMM(fromDate: any, fromtime: any): any {
  let dateexct=fromDate.split('-');
  var day = dateexct [0];
  var month = dateexct [1];
  var year = dateexct [2];
  var fullDay=day+'-'+month+'-'+year+' '+fromtime+":00";
  return fullDay;
}
  indirectFieldValidation() {
    if(this.repotingForm.value.type=="indirect"){
      if(this.repotingForm.value.indirectReportingType=="0"){
        document.getElementById("indirectReportingType").focus();
        alert("Please Select indirect Reporting Type");
        return false;
      }
      if(this.repotingForm.value.indirectReportingType=="Others"){
        if(this.repotingForm.value.remarks==""){
          document.getElementById("remarks").focus();
          alert("Please Provide Remarks");
          return false;
        }
      }
    }
  }
  directFieldValidation() {
    if(this.repotingForm.value.type=="direct"){
      //console.log(this.repotingForm.value.imgPath);
      if(this.repotingForm.value.imgPath==""){
        document.getElementById("imgPath").focus();
        alert("Please Select Image");
        return false;
      }else{
        if(this.selectedFiles!=undefined){
          console.log("Image")
            this.imageUpload();
           }
      }
    }
  }
  numberBoxValidation() {
    if(this.repotingForm.value.type=="direct" ||  this.repotingForm.value.type=="indirect")
    {
      if(this.repotingForm.value.tusker==null){
        document.getElementById("tuskerwl").focus();
        alert("Please Provide  tusker");
        return false;
      }
      if(this.repotingForm.value.makhna==null){
        document.getElementById("makhnawl").focus();
        alert("Please Provide  makhna");
        return false;
      }
      if(this.repotingForm.value.female==null){
        document.getElementById("femalewl").focus();
        alert("Please Provide  female");
        return false;
      }
      if(this.repotingForm.value.calf==null){
        document.getElementById("calfwl").focus();
        alert("Please Provide  calf");
        return false;
      }
      if(this.repotingForm.value.herd==null){
        document.getElementById("herdwl").focus();
        alert("Please Provide  herd");
        return false;
      }
    }
  }
  formValidation() {
    if(this.divid=="0"){
      document.getElementById("divisionwl").focus();
      alert("Please Provide  division");
      return false;
    }
    if(this.repotingForm.value.range=="0"){
      document.getElementById("rangewl").focus();
      alert("Please Provide  range");
      return false;
    }
    if(this.repotingForm.value.section=="0"){
      document.getElementById("sectionwl").focus();
      alert("Please Provide  section");
      return false;
    }
    if(this.repotingForm.value.beat=="0"){
      document.getElementById("beatwl").focus();
      alert("Please Provide  beat");
      return false;
    }
  }
}
