<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->

<div class="modal fade modal-custom" id="modalFeedback"   #modalFeedback tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
        	<button type="button" class="close" data-dismiss="modal" aria-label="Close">
	          <span aria-hidden="true">&times;</span>
	        </button>
        	<div class="col-md-4">
        		<div class="bg-login"></div>
        	</div>
        	<div class="col-md-7">
				<h2 class="text-center mt-3">Feedback</h2>
				<form [formGroup]="feedbackForm" (ngSubmit)="onSubmitForm()">
				  <div class="form-group ml-4">
				    <input type="text" class="form-control" formControlName="name" [ngClass]="{ 'is-invalid': feedbackForm.controls['name'].invalid && (feedbackForm.controls['name'].dirty || feedbackForm.controls['name'].touched) }" placeholder="Enter Your Name">
            <div *ngIf="feedbackForm.controls['name'].invalid && (feedbackForm.controls['name'].dirty || feedbackForm.controls['name'].touched)" class="text-danger mt-1">
            <small *ngIf="feedbackForm.controls['name'].errors.required">
              Name is required.
           </small>
           </div>
          </div>
				  <div class="form-group ml-4">
				    <input type="number" class="form-control"   formControlName="userPhoneNumber" [ngClass]="{ 'is-invalid': feedbackForm.controls['userPhoneNumber'].invalid && (feedbackForm.controls['userPhoneNumber'].dirty || feedbackForm.controls['userPhoneNumber'].touched) }" class="form-control" id="userPhoneNumber" placeholder="Mobile Number" >
            <div *ngIf="feedbackForm.controls['userPhoneNumber'].invalid && (feedbackForm.controls['userPhoneNumber'].dirty || feedbackForm.controls['userPhoneNumber'].touched)" class="text-danger mt-1">
              <small *ngIf="feedbackForm.controls['userPhoneNumber'].errors.required">
                  Mobile Number is required.
              </small>
                <small *ngIf="feedbackForm.controls['userPhoneNumber'].errors.pattern">
                  Mobile Number is invalid. Mobile Number must be at least 10 characters long.
                  Mobile Number cannot be more than 10 characters long.
                </small>
            </div>
				  </div>
				  <div class="form-group ml-4">
				    <input type="email" class="form-control"  formControlName="email"  [ngClass]="{ 'is-invalid': feedbackForm.controls['email'].invalid && (feedbackForm.controls['email'].dirty || feedbackForm.controls['email'].touched) }" class="form-control" id="email" placeholder="Email ID" >
            <div *ngIf="feedbackForm.controls['email'].invalid && (feedbackForm.controls['email'].dirty || feedbackForm.controls['email'].touched)" class="text-danger mt-1">
              <small *ngIf="feedbackForm.controls['email'].errors.required">
                  E-mail is required.
              </small>
                <small *ngIf="feedbackForm.controls['email'].errors.email">
                  Enter Valid E-mail
                </small>
            </div>
				  </div>
				  <div class="form-group ml-4">
				    <textarea type="text" class="form-control" formControlName="comments" [ngClass]="{ 'is-invalid': feedbackForm.controls['comments'].invalid && (feedbackForm.controls['comments'].dirty || feedbackForm.controls['comments'].touched) }" class="form-control" id="feedback"
              placeholder="Enter Your Feedback"></textarea>
				  </div>
          <!-- <div class="form-group row">
            <div id="captchafeedback" class="col-md-5 img-fluid captcha">
            </div>
            <div class="col-md-1">
              <a id="refresfeed" href="#" class="" title="reCAPTCHA"
                (click)="createCaptchaforFeedback()"><i
                  class="fa fa-refresh mt-2 fa-captcha"></i></a>
            </div>
            <div class="col-md-6">
              <input type="text" class="form-control"
                formControlName="captchaValue" placeholder="Enter Captcha">
            </div>
          </div> -->
				  <div class="form-group ml-4 text-center">
				  	<button type="submit" class="login"  [disabled]="feedbackForm.invalid"><i class="fa fa-paper-plane">&nbsp;</i>Send</button>
				  </div>
				</form>
        	</div>
        </div>
      </div>
    </div>
  </div>
</div>
