
<app-header></app-header>
<app-menu></app-menu>

<section class="common-section slider-gallery">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

				<h4 class="card-title">Our Gallery</h4>

      <div class="row">
        <div *ngIf="Gtlist"class="gallery col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="text-center">
            <button class="btn btn-default filter-button active" id="all" onclick="call(this.id)">All</button>
            <button class="btn btn-default filter-button" *ngFor="let btn of Gtlist" id={{btn.gtype}}  onclick="call(this.id)">
              {{btn.typename}}
            </button>
          </div>
        </div>

        <a href="{{img.imgPath}}"  *ngFor="let img of GalleryList" data-fancybox="gallery"  class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter {{img.gtype}}"  >
          <img src={{img.imgPath}} class="image">
          <div class="overlay">
            <span class="icon">
              <i class="fa fa-search"></i>
            </span>
          </div>
        </a>
                    <!-- <div class="gallery col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="text-center">
                        <button class="btn btn-default filter-button active" id="all" onclick="call(this.id)">All</button>
                        <button class="btn btn-default filter-button" id="elephant" onclick="call(this.id)">Project elephant</button>
                        <button class="btn btn-default filter-button" id="tiger" onclick="call(this.id)">Project Tiger</button>
                        <button class="btn btn-default filter-button" id="crocodile" onclick="call(this.id)">Crocodile Conservation</button>
                        <button class="btn btn-default filter-button" id="turtle" onclick="call(this.id)">Sea Turtle Conservation</button>
                      </div>
                    </div> -->

                    <!-- <a href="assets/images/gallery-elephant-01.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter elephant" data-fancybox="gallery" data-caption="Eco Tourism">
                      <img src="assets/images/gallery-elephant-01.jpg" class="image">
                      <div class="overlay">
                        <span class="icon">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                    </a>

                    <a href="assets/images/gallery-crocodile-01.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter crocodile" data-fancybox="gallery" data-caption="Eco Tourism">
                      <img src="assets/images/gallery-crocodile-01.jpg" class="image">
                      <div class="overlay">
                        <span class="icon">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                    </a>

                    <a href="assets/images/gallery-turtle-01.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter turtle" data-fancybox="gallery" data-caption="Eco Tourism">
                      <img src="assets/images/gallery-turtle-01.jpg" class="image">
                      <div class="overlay">
                        <span class="icon">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                    </a>

                    <a href="assets/images/gallery-elephant-02.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter elephant" data-fancybox="gallery" data-caption="Eco Tourism">
                      <img src="assets/images/gallery-elephant-02.jpg" class="image">
                      <div class="overlay">
                        <span class="icon">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                    </a>

                    <a href="assets/images/gallery-turtle-02.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter turtle" data-fancybox="gallery" data-caption="Eco Tourism">
                      <img src="assets/images/gallery-turtle-02.jpg" class="image">
                      <div class="overlay">
                        <span class="icon">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                    </a>

                    <a href="assets/images/gallery-tiger-01.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter tiger" data-fancybox="gallery" data-caption="Eco Tourism">
                      <img src="assets/images/gallery-tiger-01.jpg" class="image">
                      <div class="overlay">
                        <span class="icon">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                    </a>

                    <a href="assets/images/gallery-crocodile-02.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter crocodile" data-fancybox="gallery" data-caption="Eco Tourism">
                      <img src="assets/images/gallery-crocodile-02.jpg" class="image">
                      <div class="overlay">
                        <span class="icon">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                    </a>

                    <a href="assets/images/gallery-turtle-03.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter turtle" data-fancybox="gallery" data-caption="Eco Tourism">
                      <img src="assets/images/gallery-turtle-03.jpg" class="image">
                      <div class="overlay">
                        <span class="icon">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                    </a>

                    <a href="assets/images/gallery-elephant-03.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter elephant" data-fancybox="gallery" data-caption="Eco Tourism">
                      <img src="assets/images/gallery-elephant-03.jpg" class="image">
                      <div class="overlay">
                        <span class="icon">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                    </a>

                    <a href="assets/images/gallery-tiger-02.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter tiger" data-fancybox="gallery" data-caption="Eco Tourism">
                      <img src="assets/images/gallery-tiger-02.jpg" class="image">
                      <div class="overlay">
                        <span class="icon">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                    </a>

                    <a href="assets/images/gallery-turtle-04.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter turtle" data-fancybox="gallery" data-caption="Eco Tourism">
                      <img src="assets/images/gallery-turtle-04.jpg" class="image">
                      <div class="overlay">
                        <span class="icon">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                    </a>

                    <a href="assets/images/gallery-crocodile-03.jpg" class="glightbox gallery_product col-lg-3 col-md-3 col-sm-4 col-xs-6 filter crocodile" data-fancybox="gallery" data-caption="Eco Tourism">
                      <img src="assets/images/gallery-crocodile-03.jpg" class="image">
                      <div class="overlay">
                        <span class="icon">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                    </a> -->

      </div>
			</div>
		</div>
	</div>
</section>


<app-footer></app-footer>
