<app-header></app-header>
<app-menu ></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

				<h4 class="card-title">Short Films on Protected Areas</h4>

				<div class="row">

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/xhxvy3XCFzc" data-target="#myModal">
							<h4>Similipal Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/K4SWB5k1lcY" data-target="#myModal">
							<h4>Bhitarakanika Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/PFMAQF6nKIg" data-target="#myModal">
							<h4>Debrigarh Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/B_JpTonioZE" data-target="#myModal">
							<h4>Nanadankanan Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/QrqQWO1D47g" data-target="#myModal">
							<h4>Chilika Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/b4YrZoeE5Ec" data-target="#myModal">
							<h4>Chandaka Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/iR2B66ZxWvQ" data-target="#myModal">
							<h4>Satkosia Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/kozy-xnlRmE" data-target="#myModal">
							<h4>Badrama Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/v-fkenyReDs" data-target="#myModal">
							<h4>Baisipalli Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/6zIWGY0A_1w" data-target="#myModal">
							<h4>Balukhand Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/71qLj6AUCBI" data-target="#myModal">
							<h4>Gahirmatha Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/zlV2x6lcAeg" data-target="#myModal">
							<h4>Lakhari Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/FJIsrsVZRH0" data-target="#myModal">
							<h4>Kuldiha Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/NVL7VD979kc" data-target="#myModal">
							<h4>Hadgarh Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/_VRZ7uPf17s" data-target="#myModal">
							<h4>Sunabeda Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/8mtiZHdE4ho" data-target="#myModal">
							<h4>Karlapat Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/-x7pXnjTs-c" data-target="#myModal">
							<h4>Kapilash Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/sP5BqCvUwSA" data-target="#myModal">
							<h4>Khalasuni Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

					<div class="col-md-3 video-box">
						<a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/0dnMDqNviMk" data-target="#myModal">
							<h4>Kotagarh Wildlife Sanctuary</h4>
							<div class="overlay">
					            <span class="icon">
					            	<i class="fa fa-play-circle"></i>
					            </span>
					        </div>
						</a>
					</div>

				</div>

	    	</div>
		</div>
    </div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video"  allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer></app-footer>
