<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-3">

				<div class="custom-menu-inner">
					<h4 class="card-title">Projects & Conservation</h4>
					<ul>
						<li><a [routerLink]="['/projectelephant']">Project Elephant</a></li>
						<li><a [routerLink]="['/projecttiger']">Project Tiger</a></li>
						<li><a [routerLink]="['/crocodileconservation']">Crocodile Conservation</a></li>
						<li><a [routerLink]="['/seaturtleconservation']">Sea Turtle Conservation</a></li>
						<li><a [routerLink]="['/blackbuckconservation']">Blackbuck Conservation</a></li>
						<li><a [routerLink]="['/mangroves']">Mangroves</a></li>
					</ul>
				</div>

			</div>
			<div class="col-md-9">

				<h4 class="card-title">BlackBuck in Odisha</h4>

				<img src="assets/images/blackbuck_img2.gif" class="img-fluid float-right img-inner" alt="image">
				<p>Local Oriya names: ('Krushnasar mriga', 'Bali Harina', 'Kala bahutia')</p>
				<p>The Indian Blackbuck (Antilope cervicapra), is one of the three species of antelopes found in Odisha.
					The other two are Nilgai (Boselaphus tragocamelus) and the Chowsingha (Tetracercus quadricornis).
					All the three are even-toed (Artiodactyla) Bovids. Blackbuck is considered to be the fastest animal
					in the world next to Cheetah. There has been a dissolute decline in the population of Blackbucks
					throughout the country due to poaching and habitat loss. In the recent past, this endemic animal was
					most numerous, commonly seen large wild mammal in the Indian subcontinent. Subsequently, within a
					short span of time, this animal has suffered much reduction in numbers. Blackbuck is included in the
					Schedule-I of Wildlife (Protection) Act, 1972 and is designated as Vulnerable as per Red Data Book
					(1994). It is now one of the most popular exhibits in most of the zoos of the country and elsewhere.
				</p>

				<h4 class="card-title">Distribution and Status</h4>
				<p>In India the species is wide spread in Rajasthan, Gujarat, Madhya Pradesh, Tamilnadu and other areas
					throughout peninsular India. In 1982, the estimated population of Blackbuck in India was between
					22,500 to 24,500. According to 1993 estimation, the population of Blackbuck in India was between
					10,000 and is stable or increasing.</p>

				<h4 class="card-title">Past distribution in Odisha</h4>
				<p>This species were widely spread in Balesore and Puri Districts but scarcely seen in Bolangir,
					Kalahandi and in coastal sand dunes of Bhitarkanika and Kujang area. Until the 1960s, the Blackbuck
					number reported was 1200 -1300.</p>

				<h4 class="card-title">Present distribution in Odisha</h4>
				<img src="assets/images/blackbuck_img1.gif" class="img-fluid float-right img-inner" alt="image">
				<p>Blackbucks are confined to Balukhand-Konark coastal plain / wildlife sanctuary in Puri District;
					Balipadar-Bhetnoi and adjacent areas in Ganjam District. In Odisha the estimated population of
					Blackbuck is about 800 to 900.</p>
				<p>The Balipadar-Bhetnoi area comprises of about 70 villages of Buguda, Aska and Kodala forest ranges of
					Ganjam District. The Blackbucks of these areas are protected religiously by the local society. The
					belief that the presence of Blackbuck in the paddy fields brings prosperity to the villagers has
					greatly contributed to the conservation of this species. The villagers do not kill the animal even
					if it strays into the fields and grazes their crop.</p>
				<h5>Blackbucks of Balipadar-Bhetnoi and its Historical Significance :</h5>
				<p>The Blackbucks of Balipadar-Bhetnoi areas are protected by the local people for several generations.
					As the story goes more than a century ago, there had a long spell of drought in the locality. During
					this period, a small group of Blackbuck appeared in the area after which it rained and the drought
					spell was broken. Since then, people started protecting these animals devotedly as they feel that
					their fate is linked with these Blackbucks. During 1918, a Britisher known as "Green saheb" and the
					'Sardar' of the locality, Sri Madeshi Chandramani Dora, took an initiative for the protection of
					these species and published a notification in the Oriya newspaper "Prajamitra" prohibiting killing
					of the Blackbuck.</p>

				<h4 class="card-title">Research</h4>
				<p>A research scheme was partially implemented with the financial assistance from the Ministry of
					Environment and Forests, Government of India, for collection of basic scientific information on the
					isolated population of Blackbucks in Balipadar- Bhetnoi area during l995-96. Based on the findings
					of the research, few studies were documented like; (i) population status, sex composition, herd
					structure and social grouping (ii) distribution pattern of the animal and threat aspects, and (iii)
					breeding biology (natality /mortality parameters), etc.</p>

				<h4 class="card-title">Rehabilitation</h4>
				<p>Steps were taken to rehabilitate Blackbucks into Bhitarkanika Wildlife Sanctuary during 1985-87 by
					introducing 14 (9M+5F) zoo bred specimens of Nanadanakanan Zoological Park stock. They could not
					adjust to the new surrounding and perished within a couple of months.</p>
				<p><strong>Realizing the significance of the Blackbuck, the Ballipadar area is being developed as a
						"Community Reserve" in accordance to the provisions of Wildlife (Protection) Act as amended in
						2002.</strong></p>

				<h4 class="card-title">Blackbuck : An Overview</h4>
				<p>The Blackbuck is also found in Western Nepal which had 176 animals back in 1991. A large feral
					population also occurred in USA (more than 20,000 in 326 Texas Ranches, in 1988). On the Pampas on
					north-western and central Argentina more than 10,000 animals were available in 1980s.</p>

				<h4 class="card-title">Morphology</h4>
				<p>Blackbuck is a medium-sized Antelope which stands about 80 cm. at the shoulder and weighs about 40
					kg. They are sexually dimorphic. The males at their initial stage are brown in color, without horns.
					However, with secretion of sexual hormone, males develop a pair of un- branched, 'corkscrew' horns
					on each side of head and change their body color to elegant black. The beautiful spiral horns (which
					never shed like deer's antler) may grow upto 50 cm. The color of the body coat is light yellow of
					young females. Males have a pronounced post orbital glands which exude a pungent sticky secretion.
				</p>

				<h4 class="card-title">Habitat</h4>
				<p>It primarily covers three Forest Ranges i.e. Buguda and Aska under Ghumsur south Division and
					Khallikote Range of Berhampur Forest Division. The state highway from Khurda to Berhampur, via
					Nayagarh, also passes through their habitat. The Blackbuck habitat covers about 60% cultivated
					lands/cropped fields, 15% rocky elevations, 10% man made houses and roads, 8% forest cover, 5% water
					bodies and 2% horticulture farms and waste lands.</p>

				<h4 class="card-title">Food</h4>
				<p>Blackbucks live on fresh tender leaves, grass, crops, cereals, vegetables and leaves of shrubs and
					trees. They feed for a long time, and select succulent grasses, tender shoots of crops and plants,
					which help them to maintain water balance in their bodies. They can survive without drinking water
					for a day to a week.</p>

				<h4 class="card-title">Movement Pattern</h4>
				<p>Blackbucks are gregarious in nature. Their movement depends upon the following factors :</p>
				<ul class="custom-list">
					<li>Availability of fresh vegetation</li>
					<li>Availability of water</li>
					<li>Human interference</li>
					<li>Interference by domestic animals within their activity limit</li>
					<li>Environmental parameters like temperature, wind, rainfall play important role for determining
						the movement pattern of Blackbuck</li>
				</ul>

				<h4 class="card-title">Herd Structure and Social Grouping</h4>
				<p>The social organisations of Blackbucks are categorized into the following groups :</p>
				<ul class="custom-list">
					<li>Mixed herd formed by males and females of different age groups</li>
					<li>Harem herd or territorial herd with one territorial male and females with all age groups</li>
					<li>Bachelor herd of all male members</li>
					<li>Herd of all female groups</li>
					<li>Lone adult male (wandering)</li>
				</ul>

				<h4 class="card-title">Breeding</h4>
				<p>Blackbucks breed in all seasons but main rut takes place in between February and May. The gestation
					period is about 5-6 months. Usually only one young is born at a time. Females of about two years old
					and above give birth to young ones. At the Nandanakanan Zoo in Odisha, a female fawned for the first
					time, at the age of two years and one month, and another at the age of almost two and half years.
				</p>

				<h4 class="card-title">Research Amortality</h4>
				<ul class="custom-list">
					<li>The normal life span of Blackbuck is about 12 to 15 years. The maximum age recorded was 16 years
						and 10 months.</li>
					<li>The young ones fall prey to a number of predators such as wolf, hyena, jungle cat, jackal,
						pythons, wild pigs, feral dogs, etc.</li>
				</ul>

				<p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
						</strong>The information contained herein are provided solely for informational purposes
						only.The content is dynamic and may undergo change from time to time. The snapshot of the
						content at a particular time is not to be referred as a legally binding document.</small></p>

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>