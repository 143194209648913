import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import Map from 'ol/Map'
import * as layer from 'ol/layer';
import { Tile as Tile, Vector as VectorLayer } from 'ol/layer';
import { OSM as OSM, Vector as VectorSource, Vector as MeasureSource } from 'ol/source';
import XYZ from 'ol/source/XYZ';
import BingMaps from 'ol/source/BingMaps'
import ImageWMS from 'ol/source/ImageWMS';
import { View } from 'ol';
import * as control from 'ol/control';
import * as proj from 'ol/proj';
import Overlay from 'ol/Overlay';
import * as reqwest from 'reqwest';
import geom from 'ol/geom';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { WFS, GeoJSON } from 'ol/format';
import { defaults as defaultInteractions, Modify, Select } from 'ol/interaction';
import ZoomToExtent from 'ol/control/ZoomToExtent';
import { Fill, Stroke, Style, Circle, Icon } from 'ol/style';
import { click, pointerMove, altKeyOnly } from 'ol/events/condition';
import * as interaction from 'ol/interaction';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getArea, getLength } from 'ol/sphere';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { NotificationService } from '../service/notification.service';

// import { EventList } from '../model/eventlist';
// import { Audit_Trail } from '../audit_trail';
// import { TranslateService } from '@ngx-translate/core'
import {
	equalTo as equalToFilter,
	like as likeFilter,
	and as andFilter
} from 'ol/format/filter';


// import {Coordinate, toStringHDMS} from 'ol/Coordinate';

// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { getTreeNoValidDataSourceError } from '@angular/cdk/tree';
import { LazyRoute } from '@angular/compiler';
import { IfStmt, THIS_EXPR } from '@angular/compiler/src/output/output_ast';

declare var jQuery: any;

@Component({
	selector: 'app-elephantcorridormap',
	templateUrl: './elephantcorridormap.component.html',
	styleUrls: ['./elephantcorridormap.component.css']
})
export class ElephantcorridormapComponent implements OnInit {
  public circleDisabled: boolean = false;
  public divisionDisabled: boolean = false;
  public rangeDisabled: boolean = false;
  public circleSelected = 'All';
  public divisionSelected = 'All';
  public rangeSelected = 'All';
	public Sdate: any;
	public Edate: any;
	public circleid :string;
	public divid :string;
	public rangeid :string;
	public sectnid :string;
	public beatid :string;
  public queryselect = "0";
	public divisionlist: any[];
	public rangelist: any[];
	public sectionlist: any[];
	public beatlist: any[];
	public PAid: string;
	public loader: boolean = false;

	_protectedAreaKuldhia: layer.Image;
	_protectedAreaChandaka: layer.Image;
	_protectedAreaChilika: layer.Image;
	_protectedAreaKapilash: layer.Image;
	_protectedAreaHadgarh: layer.Image;
	_protectedAreaBaisipali: layer.Image;
	_protectedAreaBalukhandaKonark: layer.Image;
	_protectedAreaSatakosia: layer.Image;
	_protectedAreaSunabeda: layer.Image;
	_protectedAreaBadramaKhalasuni: layer.Image;
	_protectedAreaDebrigarh: layer.Image;
	_protectedAreaGahiramathaBhitarakanika: layer.Image;
	_protectedAreaKarlapat: layer.Image;
	_protectedAreaLahkeri: layer.Image;
	_protectedAreaKotagarh: layer.Image;
	_protectedAreaSimilipal: layer.Image;
	_protectedAreaNandaankanan: layer.Image;

	_divisionBoundarySunabeda: layer.Image;
	_divisionBoundarySatakosia: layer.Image;
	_divisionBoundaryChilika: layer.Image;
	_divisionBoundaryChandaka: layer.Image;
	_divisionBoundaryBalesore: layer.Image;
	_divisionBoundaryBhadrak: layer.Image;
	_divisionBoundaryDhenkanal: layer.Image;
	_divisionBoundaryPuri: layer.Image;
	_divisionBoundaryMahanadi: layer.Image;
	_divisionBoundaryHirakud: layer.Image;
	_divisionBoundaryKeonjhar: layer.Image;
	_divisionBoundaryBaliguda: layer.Image;
	_divisionBoundaryParalakhemundi: layer.Image;
	_divisionBoundaryBamara: layer.Image;
	_divisionBoundaryKalahandiS: layer.Image;
	_divisionBoundaryRajanagar: layer.Image;
	_divisionBoundaryBaripada: layer.Image;
	_divisionBoundaryAll: layer.Image;

	_balesorerange: layer.Image;
	_bhadrakrange: layer.Image;
	_chandakarange: layer.Image;
	_chilikarange: layer.Image;
	_dhenkanalrange: layer.Image;
	_hirakudrange: layer.Image;
	_kaeojhararange: layer.Image;
	_mahanadirange: layer.Image;
	_purirange: layer.Image;
	_satakosiarange: layer.Image;
	_sunabedarange: layer.Image;
	_baligudarange: layer.Image;
	_paralakhemundirange: layer.Image;
	_bamararange: layer.Image;
	_kalahandiSrange: layer.Image;
	_rajanagarrange: layer.Image;
	_baripadarange: layer.Image;
	_allrange: layer.Image;

	_balesoreBeat: layer.Image;
	_bhadrakBeat: layer.Image;
	_chandakaBeat: layer.Image;
	_chilikaBeat: layer.Image;
	_dhenkanalBeat: layer.Image;
	_hirakudBeat: layer.Image;
	_kaeojharaBeat: layer.Image;
	_mahanadiBeat: layer.Image;
	_puriBeat: layer.Image;
	_satakosiaBeat: layer.Image;
	_sunabedaBeat: layer.Image;
	_baligudaBeat: layer.Image;
	_bamaraBeat: layer.Image;
	_paralakhemundiBeat: layer.Image;
	_kalahandiSBeat: layer.Image;
	_rajanagarBeat: layer.Image;
	_baripadaBeat: layer.Image;
	_allBeat: layer.Image;

	_balesoreSection: layer.Image;
	_bhadrakSection: layer.Image;
	_chandakaSection: layer.Image;
	_chilikaSection: layer.Image;
	_dhenkanalSection: layer.Image;
	_hirakudSection: layer.Image;
	_kaeojharaSection: layer.Image;
	_mahanadiSection: layer.Image;
	_puriSection: layer.Image;
	_satakosiaSection: layer.Image;
	_sunabedaSection: layer.Image;
	_baligudaSection: layer.Image;
	_bamaraSection: layer.Image;
	_paralakhemundiSection: layer.Image;
	_kalahandiSSection: layer.Image;
	_rajanagarSection: layer.Image;
	_baripadaSection: layer.Image;
	_allSection: layer.Image;

	_blockBaleswar: layer.Image;
	_blockBaliguda: layer.Image;
	_blockChilika: layer.Image;
	_blockDhenkanal: layer.Image;
	_blockKalahandi: layer.Image;
	_blockKeonjhar: layer.Image;
	_blockParalakhemundi: layer.Image;
	_blockPuri: layer.Image;
	_blockRajanagar: layer.Image;

	imegary: layer.Image;
	streetmap: layer.Image;
	osmMap: layer.Image;
	_raster: layer.Image;
	_carto: layer.Image;
	map: Map;
	modify: any;
	vectorparcelextendglobal: any;
	legndivision: any;
	legndrange: any;
	legndsection: any;
	legendbeat: any;
	legendsanctuary: any;
	legendPA: any;
	vectorparcelextendglobal_range: any;
	vectorparcelextendglobal_division: any;
	circlelist: any;
	vectorparcelextendglobal_circle: any;
	vectorparcelextendglobal_section: any;
	vectorparcelextendglobal_beat: any;
  Existing_point: any;
  elephantpoitnsglobal:  Array<any> = new Array();


	constructor(private formBuilder: FormBuilder,private notifyService:NotificationService, private apiService: ApiService, private ref: ChangeDetectorRef) { }

	ngOnInit(): void {
		this.loader = true;//set loader true for visible
    this.circleid = "0";
    this.divid = "0";
    this.rangeid = "0";
    this.sectnid = "0";
    this.beatid = "0";
    this.getAllCircle();
    setTimeout(() => {
      this.isCircleDivisionRangeDisabled();
    }, 100);


		//this.getAllDivision();
		this.legndivision = document.getElementById("divisionBoundary") as HTMLInputElement;
		this.legndrange = document.getElementById("rangeBoundary") as HTMLInputElement;
		this.legndsection = document.getElementById("sectionBoundary") as HTMLInputElement;
		this.legendbeat = document.getElementById("beatBoundary") as HTMLInputElement;
		this.legendPA = document.getElementById("sanctuaryBoundary") as HTMLInputElement;

		(function ($) {

			$("document").ready(function () {
				$(".tab-slider--body").hide();
				$(".tab-slider--body:first").show();
			});

			$(".tab-slider--nav li").click(function () {
				$(".tab-slider--body").hide();
				var activeTab = $(this).attr("rel");
				$("#" + activeTab).fadeIn();
				if ($(this).attr("rel") == "tab2") {
					$('.tab-slider--tabs').addClass('slide');
				} else {
					$('.tab-slider--tabs').removeClass('slide');
				}
				$(".tab-slider--nav li").removeClass("active");
				$(this).addClass("active");
			});

			$(function () {
				$(document).ready(function () {

					//$("#multiselect").CreateMultiCheckBox({ width: '100%', defaultText: 'Select Division ...', height: '250px' });
				});

				/*$('#queryBtn').click(function () {
					$('#queryContent').slideToggle("slow");
					$('.rotate').toggleClass("down");
				});*/
				/*$('.rotate').click(function () {
					$(this).toggleClass("down");
				});*/
			});
			var day1before = new Date();
			$(function () {
				$("#datepicker2").datepicker({
					autoclose: true,
					todayHighlight: true
				}).datepicker('update', new Date());
				$("#datepicker").datepicker({
					autoclose: true,
					todayHighlight: true
				}).datepicker('update', new Date(day1before.setDate(day1before.getDate() - 1)));
			});


		})(jQuery);
		var format = 'image/png';
		this.inhisiatemap();
		this.loader = false;//set loader false for invisible
	}
  isCircleDivisionRangeDisabled() {
    const userdata = sessionStorage.getItem('user');
    if (userdata != null) {
      const user = JSON.parse(userdata);
      if (user.roleId == 1 || user.roleId == 3) {//for Admin
        this.circleDisabled = false;
        this.divisionDisabled = false;
        this.rangeDisabled = false;
      }
      else if (user.roleId == 6) {//For RCCF
        this.apiService.request('GET', 'api/v1/masters/getAssignCircleDivisionRangeByUserId?roleId=' + user.roleId + '&jurdicitionId=' + user.juridictionId).subscribe((data: any) => {
          if (data) {
            this.circleDisabled = true;
            this.circleid = data.circleId;
            this.circleSelected = data.circleId;
            this.circleFilter(data.circleId);
          }
        });
      }
      else if (user.roleId == 2) {//For DFO
        this.apiService.request('GET', 'api/v1/masters/getAssignCircleDivisionRangeByUserId?roleId=' + user.roleId + '&jurdicitionId=' + user.juridictionId).subscribe((data: any) => {
          if (data) {

            this.circleid=data.circleId;
            this.circleDisabled = true;
            this.circleSelected = data.circleId;
            this.divid=data.divisionId;
            this.circleFilter(data.circleId);
            this.divisionSelected = data.divisonId;
            this.divisionDisabled = true;
              this.DivisionFilter(data.divisonId);


          }
        });
      }
      else if (user.roleId == 4 || user.roleId == 5) {//for Range
        this.apiService.request('GET', 'api/v1/masters/getAssignCircleDivisionRangeByUserId?roleId=' + user.roleId + '&jurdicitionId=' + user.juridictionId).subscribe((data: any) => {
          if (data) {
            this.circleFilter(data.circleId);
            this.circleDisabled = true;
            this.circleSelected = data.circleId;
            this.divid=data.divisionId;
            this.DivisionFilter(data.divisionId);
            this.divisionSelected = data.divisonId;
            this.divisionDisabled = true;
            this.Rangefilter(data.rangeId);
            this.rangeSelected = data.rangeId;
            this.rangeid = data.rangeId;
            this.rangeDisabled = true;

          }
        });
      }
    } else {

    }
  }
	inhisiatemap() {

		var raster = new Tile({
			type: 'base',
			title: 'Google Satelite',
			visible: false,
			source: new OSM()
		});
		// var raster = new Tile({
		// 	visible: false,
		// 	source: new OSM()
		//   });
		var carto = new Tile({
			title: 'Cartography',
			visible: false,
			type: 'base',
			source: new XYZ({
				url: 'https://{1-4}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
			})
		});
		//google Image
		var imegary = new Tile({
			visible: false,
			source: new OSM({
				url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
			})
		});
		//google Street
		var streerMap = new Tile({
			visible: true,
			source: new OSM({
				url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
			})
		});


		this.imegary = imegary;
		this.streetmap = streerMap;
		this._carto = carto;
		this._raster = raster;
		var streetbtn = document.getElementById("streetBTN") as HTMLInputElement;
		streetbtn.checked = true;

		// State Boundary

		var _StateBoundary = new layer.Image({
			source: new ImageWMS({
				url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wms',
				params: {
					'FORMAT': 'image/png',
					'VERSION': '1.1.0',
					STYLES: '',
					LAYERS: 'iwlms:odisha_state_boundary'
				},
				serverType: 'geoserver',
				crossOrigin: 'anonymous'
			}),
			visible: true
		});

		// -------------------------------------- PROTECTED AREA FINISHED ------------------------------------
		// info window pop up
		var container = document.getElementById('popup');
		var content1 = document.getElementById('popup-content');
		let closer1 = document.getElementById('popup-closer');
		const overlay = new Overlay({
			element: container,
			autoPan: true,
			autoPanAnimation:
			{
				duration: 250,
			},
			stopEvent: false
		});
		var Dis = this;
		//map variable instialization to view map
		var maplocal;
		setTimeout(() => {
			maplocal = new Map({
				layers:
					[
						raster, carto, imegary, streerMap, _StateBoundary
					],
				controls: [
					new control.Zoom(), new control.ZoomSlider(), new control.ScaleLine({
						units: 'metric',
						bar: true,
						steps: 4,
						text: true,
						minWidth: 140
					}),],
				overlays: [overlay],
				target: 'map',
				view: new View({
					center: proj.fromLonLat([85.190055, 20.886785]),
					zoom: 7,
					maxZoom: 23,
					minZoom: 5,
				})
			});

			this.map = maplocal;
			var ds = this;
    });
	}
	getAllCircle() {
		return this.apiService.request('GET', 'api/v1/masters/getAllCircle').subscribe((data: any) => {
			if (data) {
				this.circlelist = data;
			}
		});
	}
	// Advance filter dropdown change functions
	circleFilter(value: string) {
		if (value != "0") {
			this.circleid = value;
			this.apiService.request('GET', 'api/v1/masters/getAllDivisionByCircleId?id=' + value).subscribe((data: any) => {
				if (data) {
					this.divisionlist = data;
          ///MApVIEW
          setTimeout(() => {
            var circlebtn = document.getElementById("circleBoundary") as HTMLInputElement;
            circlebtn.checked = true;
            this.circleLayervisiblityConditions();
            var divbtn = document.getElementById("divisionBoundary") as HTMLInputElement;
            if (divbtn.checked == true) {
              this.divisionLayervisiblityConditions();
            }
            var rngbtn = document.getElementById("rangeBoundary") as HTMLInputElement;
            if (rngbtn.checked == true) {
              this.rangeLayervisibilityCondition();
            }
            var beatbtn = document.getElementById("beatBoundary") as HTMLInputElement;
            if (beatbtn.checked == true) {
              this.beatLayervisibilityCondition();
            }
            var sectionbtn = document.getElementById("sectionBoundary") as HTMLInputElement;
            if (sectionbtn.checked == true) {
              this.sectionLayervisibilityCondition();
            }
            // var santuarybtn = document.getElementById("sanctuaryBoundary") as HTMLInputElement;
            // if (santuarybtn.checked == true) {
            // 	this.sanctuaryLayerVisibilityCondition();
            // }
            // var blockheadbtn = document.getElementById("blockheadpoints") as HTMLInputElement;
            // if (blockheadbtn.checked == true) {
            // 	this.blockboundaryVisibilityCondition();
            // }
          }, 100);
				}
			});
		} else {
			this.circleid = "0";
			this.divid = "0";
			this.rangeid = "0";
			this.sectnid = "0";
			this.beatid = "0";
			this.divisionlist = [];
			this.rangelist = [];
			this.sectionlist = [];
			this.beatlist = [];
		}

	}
	DivisionFilter(value:string) {
		if (value != "0") {
      this.divid=value;
			this.rangeid = "0";
			this.sectnid = "0";
			this.beatid = "0";
			this.ref.detectChanges();
        this.apiService.request('GET', 'api/v1/masters/getAllRangeByDivid?id=' +value).subscribe((data: any) => {
          if (data) {
            this.rangelist = data;
            this.sectionlist = [];
            this.beatlist = [];
            //MApview
            setTimeout(() => {
              var divbtn = document.getElementById("divisionBoundary") as HTMLInputElement;
              divbtn.checked = true;
              this.divisionLayervisiblityConditions();
              var rngbtn = document.getElementById("rangeBoundary") as HTMLInputElement;
              if (rngbtn.checked == true) {
                this.rangeLayervisibilityCondition();
              }
              var beatbtn = document.getElementById("beatBoundary") as HTMLInputElement;
              if (beatbtn.checked == true) {
                this.beatLayervisibilityCondition();
              }
              var sectionbtn = document.getElementById("sectionBoundary") as HTMLInputElement;
              if (sectionbtn.checked == true) {
                this.sectionLayervisibilityCondition();
              }
              // var santuarybtn = document.getElementById("sanctuaryBoundary") as HTMLInputElement;
              // if (santuarybtn.checked == true) {
              // 	this.sanctuaryLayerVisibilityCondition();
              // }
              // var blockheadbtn = document.getElementById("blockheadpoints") as HTMLInputElement;
              // if (blockheadbtn.checked == true) {
              // 	this.blockboundaryVisibilityCondition();
              // }
              ////this.extendtofit("Division","",this.divid);
            }, 500);
          }
        });
		} else {
			this.rangeid = "0";
			this.sectnid = "0";
			this.beatid = "0";
			this.rangelist = [];
			this.sectionlist = [];
			this.beatlist = [];
		}

	}
	Rangefilter(value) {
		if (value != "0") {
			this.rangeid = value;
			this.sectnid = "0";
			this.beatid = "0";
			var rngbtn = document.getElementById("rangeBoundary") as HTMLInputElement;
			rngbtn.checked = true;
			this.apiService.request('GET', 'api/v1/masters/getAllSectionByRangeid?id=' + value).subscribe((data: any) => {
				if (data) {
					this.sectionlist = data;
					this.beatlist = [];
				}
			});
		} else {
			this.sectnid = "0";
			this.beatid = "0";
			this.sectionlist = [];
			this.beatlist = [];
		}
		setTimeout(() => {
			this.rangeLayervisibilityCondition();
			var rngbtn = document.getElementById("rangeBoundary") as HTMLInputElement;
			rngbtn.checked = true;
			var beatbtn = document.getElementById("beatBoundary") as HTMLInputElement;
			if (beatbtn.checked == true) {
				this.beatLayervisibilityCondition();
			}
			var sectionbtn = document.getElementById("sectionBoundary") as HTMLInputElement;
			if (sectionbtn.checked == true) {
				this.sectionLayervisibilityCondition();
			}
			// var santuarybtn = document.getElementById("sanctuaryBoundary") as HTMLInputElement;
			// if (santuarybtn.checked == true) {
			// 	this.sanctuaryLayerVisibilityCondition();
			// }
			// var blockheadbtn = document.getElementById("blockheadpoints") as HTMLInputElement;
			// if (blockheadbtn.checked == true) {
			// 	this.blockboundaryVisibilityCondition();
			// }
		}, 100);
	}
	SectionFilter(value) {
		if (value != "0") {
			this.sectnid = value;
			this.beatid = "0";
			this.apiService.request('GET', 'api/v1/masters/getAllBeatBySecId?id=' + value).subscribe((data: any) => {
				if (data) {
					this.beatlist = data;

          	//MAp View
		var sectionbtn = document.getElementById("sectionBoundary") as HTMLInputElement;
		sectionbtn.checked = true;
		setTimeout(() => {
			this.sectionLayervisibilityCondition();
			var rngbtn = document.getElementById("rangeBoundary") as HTMLInputElement;
			rngbtn.checked = true;
			var beatbtn = document.getElementById("beatBoundary") as HTMLInputElement;
			if (beatbtn.checked == true) {
				this.beatLayervisibilityCondition();
			}
			// var santuarybtn = document.getElementById("sanctuaryBoundary") as HTMLInputElement;
			// if (santuarybtn.checked == true) {
			// 	this.sanctuaryLayerVisibilityCondition();
			// }
			// var blockheadbtn = document.getElementById("blockheadpoints") as HTMLInputElement;
			// if (blockheadbtn.checked == true) {
			// 	this.blockboundaryVisibilityCondition();
			// }
		}, 100);
				}
			});
		} else {
			this.beatlist = [];
			this.beatid = "0";
		}

	}
	BeatFilter(value) {
		if (value != "0") {
			this.beatid = value;
		} else {
			this.beatid = "0";
		}
		var beatbtn = document.getElementById("beatBoundary") as HTMLInputElement;
		beatbtn.checked = true;
		this.beatLayervisibilityCondition();
		setTimeout(() => {
			// var santuarybtn = document.getElementById("sanctuaryBoundary") as HTMLInputElement;
			// if (santuarybtn.checked == true) {
			// 	this.sanctuaryLayerVisibilityCondition();
			// }
			// var blockheadbtn = document.getElementById("blockheadpoints") as HTMLInputElement;
			// if (blockheadbtn.checked == true) {
			// 	this.blockboundaryVisibilityCondition();
			// }
		}, 100);
	}
	// Check box functions layer activation
	circleonchange(e) {
		if (e.target.checked == true) {
			this.circleLayervisiblityConditions();
		}
		else if (e.target.checked == false) {
			if (this.vectorparcelextendglobal_circle) {
				this.map.removeLayer(this.vectorparcelextendglobal_circle);
			}
		}
	}
	divonchange(e) {
		if (e.target.checked == true) {
			this.divisionLayervisiblityConditions();
		}
		else if (e.target.checked == false) {
			if (this.vectorparcelextendglobal_division) {
				this.map.removeLayer(this.vectorparcelextendglobal_division);
			}
		}
	}
	setrangeVisibility(e) {
		if (e.target.checked == true) {
			this.rangeLayervisibilityCondition();
		}
		else if (e.target.checked == false) {
			if (this.vectorparcelextendglobal_range) {
				this.map.removeLayer(this.vectorparcelextendglobal_range);
			}
		}
	}
	setSectionVisibility(e) {
		if (e.target.checked == true) {
			this.sectionLayervisibilityCondition();
		}
		else if (e.target.checked == false) {
			if (this.vectorparcelextendglobal_section) {
				this.map.removeLayer(this.vectorparcelextendglobal_section);
			}
		}
	}
	setBeatVisibility(e) {
		if (e.target.checked == true) {
			this.beatLayervisibilityCondition();
		}
		else if (e.target.checked == false) {
			if (this.vectorparcelextendglobal_beat) {
				this.map.removeLayer(this.vectorparcelextendglobal_beat);
			}
		}
	}
	sanctuarychange(e) {
		if (e.target.checked == true) {
			this.sanctuaryLayerVisibilityCondition();
		}
		else if (e.target.checked == false) {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
		}
	}
	blockheadchange(e) {
		if (e.target.checked == true) {
			this.blockboundaryVisibilityCondition();
		}
		else if (e.target.checked == false) {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
	}
	circleLayervisiblityConditions() {
    if (this.elephantpoitnsglobal.length > 0) {
      for (let index = 0; index < this.elephantpoitnsglobal.length; index++) {
        const element = this.elephantpoitnsglobal[index];
        this.map.removeLayer(element);
      }
    }
		if (this.vectorparcelextendglobal_circle) {
			this.map.removeLayer(this.vectorparcelextendglobal_circle);
		}
		if (this.vectorparcelextendglobal_division) {
			this.map.removeLayer(this.vectorparcelextendglobal_division);
		}
		if (this.vectorparcelextendglobal_range) {
			this.map.removeLayer(this.vectorparcelextendglobal_range);
		}
		if (this.vectorparcelextendglobal_section) {
			this.map.removeLayer(this.vectorparcelextendglobal_section);
		}
		if (this.vectorparcelextendglobal_beat) {
			this.map.removeLayer(this.vectorparcelextendglobal_beat);
		}
		var layername_circle = 'https://odishaforestgis.in/ofms/index.php/webservice_api/getCircleGeojson';
		var querycircle = "";
		if (this.circleid != "0") {
			querycircle = "{\"circle_id\" : \"" + this.circleid + "\"}";
      if (querycircle != "") {
        let formdata:FormData = new FormData();
        formdata.append('data',querycircle);
        try{
        this.apiService.requestGeoJson(layername_circle,formdata).subscribe((response: any[]) => {
          if (response) {
          if(response["status"]==1){
            if (this.vectorparcelextendglobal_range) {
            this.map.removeLayer(this.vectorparcelextendglobal_range);
            }
            if (this.vectorparcelextendglobal_division) {
            this.map.removeLayer(this.vectorparcelextendglobal_division);
            }
            if (this.vectorparcelextendglobal_circle) {
            this.map.removeLayer(this.vectorparcelextendglobal_circle);
            }
            const vectorSource = new VectorSource();
            const styleFunction = [
              new Style({
                stroke: new Stroke({
                  color: 'rgba(32, 5, 132, 2)',
                  width: 2,
                }),
                fill: new Fill({
                  color: 'rgba(0, 0, 255, 0.1)',
                }),
              })];
            var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
            var gjFormat = new GeoJSON({
              featureProjection: 'EPSG:3857',
            });
            var features = gjFormat.readFeatures(geojson);
            vectorSource.clear();
            var featureOverlayNew = new VectorLayer({
              //map: this.map,
              source: vectorSource,
              style: styleFunction,
              updateWhileAnimating: true,
              updateWhileInteracting: true
            });
            featureOverlayNew.getSource().addFeatures(features);
            this.map.addLayer(featureOverlayNew);
            this.map.getView().setZoom(10);
            this.map.getView().fit(featureOverlayNew.getSource().getExtent(), "");
            this.vectorparcelextendglobal_circle=featureOverlayNew;
            this.loader=false;
          }else{
            this.notifyService.showError("Map Data Not Available","Error");
            this.loader=false
          }
          }else{
          this.notifyService.showError("Map Data Not Available","Error");
            this.loader=false
          }
        },(error) => {
          const errors = error;
          this.loader=false;

        });
        }catch(e){
          this.notifyService.showError("Something Error!!","Error");
          this.loader=false
        }finally{
          this.loader=false
        }
      }
		}
	}
	divisionLayervisiblityConditions() {
    if (this.elephantpoitnsglobal.length > 0) {
      for (let index = 0; index < this.elephantpoitnsglobal.length; index++) {
        const element = this.elephantpoitnsglobal[index];
        this.map.removeLayer(element);
      }
    }
		if (this.vectorparcelextendglobal_division) {
			this.map.removeLayer(this.vectorparcelextendglobal_division);
		}
		if (this.vectorparcelextendglobal_range) {
			this.map.removeLayer(this.vectorparcelextendglobal_range);
		}
		if (this.vectorparcelextendglobal_section) {
			this.map.removeLayer(this.vectorparcelextendglobal_section);
		}
		if (this.vectorparcelextendglobal_beat) {
			this.map.removeLayer(this.vectorparcelextendglobal_beat);
		}
   
		var layername_div = 'https://odishaforestgis.in/ofms/index.php/webservice_api/getDivisionGeojson';
		var querydiv, querycircle = "";
		if (this.divid != "0") {
			querydiv = "{\"division_id\" : \"" + this.divid + "\"}";
		}
		if (this.divid != "0") {
		  //	querycircle = "circle_id='" + this.circleid + "'";
      if (querydiv != "") {
        let formdata:FormData = new FormData();
        formdata.append('data',querydiv);
        try{
        this.apiService.requestGeoJson(layername_div,formdata).subscribe((response: any[]) => {
          if (response) {
          if(response["status"]==1){
            if (this.vectorparcelextendglobal_range) {
            this.map.removeLayer(this.vectorparcelextendglobal_range);
            }
            if (this.vectorparcelextendglobal_division) {
            this.map.removeLayer(this.vectorparcelextendglobal_division);
            }
            const vectorSource = new VectorSource();
            const styleFunction = [
              new Style({
                stroke: new Stroke({
                  color: 'rgba(31, 28, 28, 2.5)',
                  width: 3,
                }),
                fill: new Fill({
                  color: 'rgba(32, 28, 28, 0.1)',
                }),
              })];
            var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
            var gjFormat = new GeoJSON({
              featureProjection: 'EPSG:3857',
            });
            var features = gjFormat.readFeatures(geojson);
            vectorSource.clear();
            var featureOverlayNew = new VectorLayer({
              //map: this.map,
              source: vectorSource,
              style: styleFunction,
              updateWhileAnimating: true,
              updateWhileInteracting: true
            });
            featureOverlayNew.getSource().addFeatures(features);
            this.map.addLayer(featureOverlayNew);
            this.map.getView().setZoom(10);
            this.map.getView().fit(featureOverlayNew.getSource().getExtent(), "");
            this.vectorparcelextendglobal_division=featureOverlayNew;
            this.loader=false;
          }else{
            this.notifyService.showError("Map Data Not Available","Error");
            this.loader=false
          }
          }else{
          this.notifyService.showError("Map Data Not Available","Error");
            this.loader=false
          }
        },(error) => {
          const errors = error;
          this.loader=false;

        });
        }catch(e){
          this.notifyService.showError("Something Error!!","Error");
          this.loader=false
        }finally{
          this.loader=false
        }
      }
    }
	}
	rangeLayervisibilityCondition() {
    if (this.elephantpoitnsglobal.length > 0) {
      for (let index = 0; index < this.elephantpoitnsglobal.length; index++) {
        const element = this.elephantpoitnsglobal[index];
        this.map.removeLayer(element);
      }
    }
		if (this.vectorparcelextendglobal_range) {
			this.map.removeLayer(this.vectorparcelextendglobal_range);
		}
		if (this.vectorparcelextendglobal_section) {
			this.map.removeLayer(this.vectorparcelextendglobal_section);
		}
		if (this.vectorparcelextendglobal_beat) {
			this.map.removeLayer(this.vectorparcelextendglobal_beat);
		}
		var layername_rng = 'https://odishaforestgis.in/ofms/index.php/webservice_api/getRangeGeojson';
		var queryrng;

		if (this.rangeid != "0") {
			queryrng = "{\"range_id\" : \"" + this.rangeid + "\"}";
      if (queryrng != "") {
        let formdata: FormData = new FormData();
        formdata.append('data', queryrng);
        try {
          this.apiService.requestGeoJson(layername_rng, formdata).subscribe((response: any[]) => {
            if (response) {
              if (response["status"] == 1) {
                const vectorSource = new VectorSource();
                const styleFunction = [
                  new Style({
                    stroke: new Stroke({
                      color: 'rgba(66, 245, 78, 2.5)',
                      width: 3,
                    }),
                    fill: new Fill({
                      color: 'rgba(66, 245, 78, 0.1)',
                    }),
                  })];
                  var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
                 var gjFormat = new GeoJSON({
                    featureProjection: 'EPSG:3857',
                  });
                 var features = gjFormat.readFeatures(geojson);
                  vectorSource.clear();
                  var featureOverlayNew = new VectorLayer({
                     // map: this.map,
                      source: vectorSource,
                      style: styleFunction,
                      updateWhileAnimating: true,
                      updateWhileInteracting: true
                    });
                    featureOverlayNew.getSource().addFeatures(features);
                    this.map.addLayer(featureOverlayNew);
                    this.map.getView().setZoom(10);
                    this.map.getView().fit(featureOverlayNew.getSource().getExtent(), "");
                    this.vectorparcelextendglobal_range=featureOverlayNew;
                    this.loader=false;

              } else {
                this.notifyService.showError("Map Data Not Available", "Error");
                this.loader = false
              }
            } else {
              this.notifyService.showError("Map Data Not Available", "Error");
              this.loader = false
            }
          }, (error) => {
            const errors = error;
            this.loader = false;
          });
        } catch (e) {
          this.notifyService.showError("Something Error!!", "Error");
          this.loader = false
        } finally {
          this.loader = false
        }
      }
    }
	}
	sectionLayervisibilityCondition() {
    if (this.elephantpoitnsglobal.length > 0) {
      for (let index = 0; index < this.elephantpoitnsglobal.length; index++) {
        const element = this.elephantpoitnsglobal[index];
        this.map.removeLayer(element);
      }
    }
		if (this.vectorparcelextendglobal_section) {
			this.map.removeLayer(this.vectorparcelextendglobal_section);
		}
		if (this.vectorparcelextendglobal_beat) {
			this.map.removeLayer(this.vectorparcelextendglobal_beat);
		}
		var layername_section;
		var querysection;

		if (this.sectnid != "0") {
			layername_section = 'https://odishaforestgis.in/ofms/index.php/webservice_api/getSectionGeojson';
      querysection = "{\"section_id\" : \"" + this.sectnid + "\"}";
      if (querysection != "") {
        let formdata: FormData = new FormData();
        formdata.append('data', querysection);
        try {
          this.apiService.requestGeoJson(layername_section, formdata).subscribe((response: any[]) => {
            if (response) {
              if (response["status"] == 1) {
                const vectorSource = new VectorSource();
                const styleFunction = [
                  new Style({
                    stroke: new Stroke({
                      color: 'rgba(245, 90, 66, 2.5)',
                      width: 3,
                    }),
                    fill: new Fill({
                      color: 'rgba(245, 90, 66, 0.1)',
                    }),
                  })];
                  var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
                 var gjFormat = new GeoJSON({
                    featureProjection: 'EPSG:3857',
                  });
                 var features = gjFormat.readFeatures(geojson);
                  vectorSource.clear();
                  var featureOverlayNew = new VectorLayer({
                     // map: this.map,
                      source: vectorSource,
                      style: styleFunction,
                      updateWhileAnimating: true,
                      updateWhileInteracting: true
                    });
                    featureOverlayNew.getSource().addFeatures(features);
                    this.map.addLayer(featureOverlayNew);
                    this.map.getView().setZoom(10);
                    this.map.getView().fit(featureOverlayNew.getSource().getExtent(), "");
                    this.vectorparcelextendglobal_section=featureOverlayNew;
                    this.loader=false;
              } else {
                this.notifyService.showError("Map Data Not Available", "Error");
                this.loader = false
              }
            } else {
              this.notifyService.showError("Map Data Not Available", "Error");
              this.loader = false
            }
          }, (error) => {
            const errors = error;
            this.loader = false;
          });
        } catch (e) {
          this.notifyService.showError("Something Error!!", "Error");
          this.loader = false
        } finally {
          this.loader = false
        }
      }
    }
	}
	beatLayervisibilityCondition() {
		var layername;
		var querybeat;
    if (this.elephantpoitnsglobal.length > 0) {
      for (let index = 0; index < this.elephantpoitnsglobal.length; index++) {
        const element = this.elephantpoitnsglobal[index];
        this.map.removeLayer(element);
      }
    }
		if (this.vectorparcelextendglobal_beat) {
			this.map.removeLayer(this.vectorparcelextendglobal_beat);
		}
		if (this.beatid != "0") {
			layername = 'https://odishaforestgis.in/ofms/index.php/webservice_api/getBeatGeojson';
      querybeat = "{\"beat_id\" : \"" + this.beatid + "\"}";
      if (querybeat != "") {
        let formdata: FormData = new FormData();
        formdata.append('data', querybeat);
        try {
          this.apiService.requestGeoJson(layername, formdata).subscribe((response: any[]) => {
            if (response) {
              if (response["status"] == 1) {
                const vectorSource = new VectorSource();
                const styleFunction = [
                  new Style({
                    stroke: new Stroke({
                      color: 'rgba(252, 240, 3, 2.5)',
                      width: 3,
                    }),
                    fill: new Fill({
                      color: 'rgba(252, 240, 3, 0.1)',
                    }),
                  })];
                  var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
                 var gjFormat = new GeoJSON({
                    featureProjection: 'EPSG:3857',
                  });
                 var features = gjFormat.readFeatures(geojson);
                  vectorSource.clear();
                  var featureOverlayNew = new VectorLayer({
                     // map: this.map,
                      source: vectorSource,
                      style: styleFunction,
                      updateWhileAnimating: true,
                      updateWhileInteracting: true
                    });
                    featureOverlayNew.getSource().addFeatures(features);
                    this.map.addLayer(featureOverlayNew);
                    this.map.getView().setZoom(10);
                    this.map.getView().fit(featureOverlayNew.getSource().getExtent(), "");
                    this.vectorparcelextendglobal_beat=featureOverlayNew;
                  this.loader=false;

              } else {
                this.notifyService.showError("Map Data Not Available", "Error");
                this.loader = false
              }
            } else {
              this.notifyService.showError("Map Data Not Available", "Error");
              this.loader = false
            }
          }, (error) => {
            const errors = error;
            this.loader = false;
          });
        } catch (e) {
          this.notifyService.showError("Something Error!!", "Error");
          this.loader = false
        } finally {
          this.loader = false
        }
     }
    }
	}
	sanctuaryLayerVisibilityCondition() {
		if (this.divid == "0") {
			this._protectedAreaKuldhia.setVisible(true);
			this._protectedAreaChandaka.setVisible(true);
			this._protectedAreaChilika.setVisible(true);
			this._protectedAreaKapilash.setVisible(true);
			this._protectedAreaHadgarh.setVisible(true);
			this._protectedAreaBaisipali.setVisible(true);
			this._protectedAreaBalukhandaKonark.setVisible(true);
			this._protectedAreaSatakosia.setVisible(true);
			this._protectedAreaSunabeda.setVisible(true);
			this._protectedAreaBadramaKhalasuni.setVisible(true);
			this._protectedAreaDebrigarh.setVisible(true);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(true);
			this._protectedAreaKarlapat.setVisible(true);
			this._protectedAreaLahkeri.setVisible(true);
			this._protectedAreaKotagarh.setVisible(true);
			this._protectedAreaSimilipal.setVisible(true);
			this._protectedAreaNandaankanan.setVisible(true);
		}
		else if (this.divid == "1") {
			this._protectedAreaKuldhia.setVisible(true);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
		}
		else if (this.divid == "2") {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(true);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
		}
		else if (this.divid == "3") {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(true);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
		}
		else if (this.divid == "4") {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
		}
		else if (this.divid == "5") {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(true);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
		}
		else if (this.divid == "6") {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(true);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
		}
		else if (this.divid == "7") {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(true);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
		}
		else if (this.divid == "8") {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(true);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
		}
		else if (this.divid == "9") {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(true);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
		}
		else if (this.divid == "10") {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(true);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
		}
		else if (this.divid == "11") {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(true);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
		}
		else if (this.divid == "12") {
			// nandankanan
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(true);
		}
		else if (this.divid == "13") {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(true);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
		}
		else if (this.divid == "14") {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(true);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
		}
		else if (this.divid == "15") {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(true);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
			// rajanagar
		}
		else if (this.divid == "16") {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(true);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
		}
		else if (this.divid == "17" || this.divid == "18") {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(false);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(true);
			this._protectedAreaNandaankanan.setVisible(false);
			//similipala N baripada
		}
		else if (this.divid == "19") {
			this._protectedAreaKuldhia.setVisible(false);
			this._protectedAreaChandaka.setVisible(false);
			this._protectedAreaChilika.setVisible(false);
			this._protectedAreaKapilash.setVisible(false);
			this._protectedAreaHadgarh.setVisible(false);
			this._protectedAreaBaisipali.setVisible(false);
			this._protectedAreaBalukhandaKonark.setVisible(false);
			this._protectedAreaSatakosia.setVisible(false);
			this._protectedAreaSunabeda.setVisible(true);
			this._protectedAreaBadramaKhalasuni.setVisible(false);
			this._protectedAreaDebrigarh.setVisible(false);
			this._protectedAreaGahiramathaBhitarakanika.setVisible(false);
			this._protectedAreaKarlapat.setVisible(false);
			this._protectedAreaLahkeri.setVisible(false);
			this._protectedAreaKotagarh.setVisible(false);
			this._protectedAreaSimilipal.setVisible(false);
			this._protectedAreaNandaankanan.setVisible(false);
		}
	}
	blockboundaryVisibilityCondition() {
		if (this.divid == "0") {
			this._blockBaleswar.setVisible(true);
			this._blockBaliguda.setVisible(true);
			this._blockChilika.setVisible(true);
			this._blockDhenkanal.setVisible(true);
			this._blockKalahandi.setVisible(true);
			this._blockKeonjhar.setVisible(true);
			this._blockParalakhemundi.setVisible(true);
			this._blockPuri.setVisible(true);
			this._blockRajanagar.setVisible(true);
		}
		else if (this.divid == "1") {
			this._blockBaleswar.setVisible(true);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid == "2") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(true);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid == "3") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid == "4") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid == "5") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid = "6") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(true);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid == "7") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(true);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid == "8") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid == "9") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(true);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid == "10") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(true);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid == "11") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid == "12") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid == "13") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(true);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid == "14") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(true);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid == "15") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(true);
		}
		else if (this.divid == "16") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid == "17") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid == "18") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
		else if (this.divid == "19") {
			this._blockBaleswar.setVisible(false);
			this._blockBaliguda.setVisible(false);
			this._blockChilika.setVisible(false);
			this._blockDhenkanal.setVisible(false);
			this._blockKalahandi.setVisible(false);
			this._blockKeonjhar.setVisible(false);
			this._blockParalakhemundi.setVisible(false);
			this._blockPuri.setVisible(false);
			this._blockRajanagar.setVisible(false);
		}
	}
	extendtofit(layertype, layernames, value) {
		var layername;
		var query;
		if (this.vectorparcelextendglobal) {
			this.map.removeLayer(this.vectorparcelextendglobal);
		}
		if (layertype == "Circle") {
			if (this.circleid != "") {
				layername = "iwlms:circle_boundary";
				query = "circle_id='" + this.circleid + "'";
			}
		}
		else if (layertype == "Division") {
			if (this.divid != "") {
				layername = "iwlms:division_boundary";
				query = "division_id='" + this.divid + "'";
			}
		}
		else if (layertype == "Range") {
			layername = layernames;
			query = "range_id='" + value + "'";
		}
		else if (layertype == "Section") {
			layername = layernames;
			query = "section_id='" + value + "'";
		}
		else if (layertype == "Beat") {
			layername = "iwlms:beat_boundary";
			query = "beat_id='" + value + "'";
		}
		if (query != "") {
			var vectorparcel = new VectorSource({
				url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wfs?service=WFS&version=1.1.0&request=GetFeature&typeName=' + layername + '&maxFeatures=2000&outputFormat=application%2Fjson&CQL_FILTER=' + query,
				format: new GeoJSON(),
				wrapX: false,
			});
		}
		else {
			var vectorparcel = new VectorSource({
				url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wfs?service=WFS&version=1.1.0&request=GetFeature&typeName=' + layername + '&maxFeatures=2000&outputFormat=application%2Fjson',
				format: new GeoJSON(),
				wrapX: false,
			});
		}
		var vectorparcelextend = new VectorLayer({
			source: vectorparcel
		});
		this.vectorparcelextendglobal = vectorparcelextend;
		this.map.addLayer(vectorparcelextend);

		vectorparcelextend.setVisible(true);

		vectorparcelextend.getSource().on('addfeature', (e) => {
			this.map.getView().fit(vectorparcelextend.getSource().getExtent(), { "maxZoom": 13 });
		});
	}
  reset(){
    var circlebtn = document.getElementById("circleBoundary") as HTMLInputElement;
    var divbtn = document.getElementById("divisionBoundary") as HTMLInputElement;
    if(this.circleSelected=='All')
    {
      this.circleid = "0";
      if(circlebtn.checked == true){
        circlebtn.checked =false;
      }
      if (this.vectorparcelextendglobal_circle) {
        this.map.removeLayer(this.vectorparcelextendglobal_circle);
      }
    }
    if(this.divisionSelected=='All')
    {
      this.divid = "0";
      if (divbtn.checked == true) {
        divbtn.checked =false;
      }
      if (this.vectorparcelextendglobal_division) {
        this.map.removeLayer(this.vectorparcelextendglobal_division);
      }
    }
    this.rangeid = "0";
    this.sectnid = "0";
    this.beatid = "0";
    this.queryselect = "0";
    if (this.elephantpoitnsglobal.length > 0) {
      for (let index = 0; index < this.elephantpoitnsglobal.length; index++) {
        const element = this.elephantpoitnsglobal[index];
        this.map.removeLayer(element);
      }
    }

		if (this.vectorparcelextendglobal_range) {
			this.map.removeLayer(this.vectorparcelextendglobal_range);
		}
		if (this.vectorparcelextendglobal_section) {
			this.map.removeLayer(this.vectorparcelextendglobal_section);
		}
		if (this.vectorparcelextendglobal_beat) {
			this.map.removeLayer(this.vectorparcelextendglobal_beat);
		}

    var rngbtn = document.getElementById("rangeBoundary") as HTMLInputElement;
    if (rngbtn.checked == true) {
      rngbtn.checked =false;
    }
    var sectionbtn = document.getElementById("sectionBoundary") as HTMLInputElement;
    if (sectionbtn.checked == true) {
      sectionbtn.checked =false;
    }
    var beatbtn = document.getElementById("beatBoundary") as HTMLInputElement;
    if (beatbtn.checked == true) {
      beatbtn.checked =false;
    }
    // var santuarybtn = document.getElementById("sanctuaryBoundary") as HTMLInputElement;
    // if (santuarybtn.checked == true) {
    //   santuarybtn.checked =false;
    // }
    // var blockheadbtn = document.getElementById("blockheadpoints") as HTMLInputElement;
    // if (blockheadbtn.checked == true) {
    //   blockheadbtn.checked=false;
    // }

    this.map=null;
    this.inhisiatemap();
  }
  search(){
    if(this.queryselect=="0"){
      this.notifyService.showWarning("Please Select Query","Message");
    }else{
        this.querySelectfn();
    }

  }
	querySelectfn() {
		var querystring;
		var Yestrerdaywithtime;
		var Todaywithtime;

		var mydate = new Date();
		var yester = new Date();
		yester = new Date(yester.setDate(yester.getDate() - 1));

		var SDDate = document.getElementById("startDate") as HTMLInputElement;
		var EDDate = document.getElementById("endDate") as HTMLInputElement;

		if (this.queryselect = "1") {
			if (this.divid == "0") {
				if (SDDate.value == EDDate.value) {
					if (mydate.getMonth() < 9) {
						Yestrerdaywithtime = mydate.getFullYear() + "-0" + (mydate.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
						Todaywithtime = mydate.getFullYear() + "-0" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
					}
					else {
						Yestrerdaywithtime = mydate.getFullYear() + "-" + (mydate.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

						Todaywithtime = mydate.getFullYear() + "-" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
					}
					querystring = "api/v1/reports/getLatLongg?division=all&range=&section=&beat=&query=''&startDate=" + Yestrerdaywithtime + "&endDate=" + Todaywithtime;
				}
				else {
					Yestrerdaywithtime = SDDate.value + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

					Todaywithtime = EDDate.value + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

					querystring = "api/v1/reports/getLatLongg?division=all&range=&section=&beat=&query=''&startDate=" + Yestrerdaywithtime + "&endDate=" + Todaywithtime;
				}
			}
			else {
				if (this.rangeid == "0") {
					if (SDDate.value == EDDate.value) {
						if (mydate.getMonth() < 9) {
							Yestrerdaywithtime = mydate.getFullYear() + "-0" + (mydate.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

							Todaywithtime = mydate.getFullYear() + "-0" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
						}
						else {
							Yestrerdaywithtime = mydate.getFullYear() + "-" + (mydate.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

							Todaywithtime = mydate.getFullYear() + "-" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
						}
						querystring = "api/v1/reports/getLatLongg?division=" + this.divid + "&range=&section=&beat=&query=''&startDate=" + Yestrerdaywithtime + "&endDate=" + Todaywithtime;
					}
					else {
						Yestrerdaywithtime = SDDate.value + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

						Todaywithtime = EDDate.value + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

						querystring = "api/v1/reports/getLatLongg?division=" + this.divid + "&range=&section=&beat=&query=''&startDate=" + Yestrerdaywithtime + "&endDate=" + Todaywithtime;
					}
				}
				else {
					if (this.sectnid == "0") {
						if (SDDate.value == EDDate.value) {
							if (mydate.getMonth() < 9) {
								Yestrerdaywithtime = mydate.getFullYear() + "-0" + (mydate.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

								Todaywithtime = mydate.getFullYear() + "-0" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
							}
							else {
								Yestrerdaywithtime = mydate.getFullYear() + "-" + (mydate.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

								Todaywithtime = mydate.getFullYear() + "-" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
							}
							querystring = "api/v1/reports/getLatLongg?division=" + this.divid + "&range=" + this.rangeid + "&section=&beat=&query=''&startDate=" + Yestrerdaywithtime + "&endDate=" + Todaywithtime;
						}
						else {
							Yestrerdaywithtime = SDDate.value + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

							Todaywithtime = EDDate.value + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

							querystring = "api/v1/reports/getLatLongg?division=" + this.divid + "&range=" + this.rangeid + "&section=&beat=&query=''&startDate=" + Yestrerdaywithtime + "&endDate=" + Todaywithtime;
						}
					}
					else {
						if (this.beatid == "0") {
							if (SDDate.value == EDDate.value) {
								if (mydate.getMonth() < 9) {
									Yestrerdaywithtime = mydate.getFullYear() + "-0" + (mydate.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

									Todaywithtime = mydate.getFullYear() + "-0" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
								}
								else {
									Yestrerdaywithtime = mydate.getFullYear() + "-" + (mydate.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

									Todaywithtime = mydate.getFullYear() + "-" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
								}
								querystring = "api/v1/reports/getLatLongg?division=" + this.divid + "&range=" + this.rangeid + "&section=" + this.sectnid + "&beat=&query=''&startDate=" + Yestrerdaywithtime + "&endDate=" + Todaywithtime;
							}
							else {
								Yestrerdaywithtime = SDDate.value + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

								Todaywithtime = EDDate.value + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

								querystring = "api/v1/reports/getLatLongg?division=" + this.divid + "&range=" + this.rangeid + "&section=" + this.sectnid + "&beat=&query=''&startDate=" + Yestrerdaywithtime + "&endDate=" + Todaywithtime;
							}
						}
						else {
							if (SDDate.value == EDDate.value) {
								if (mydate.getMonth() < 9) {
									Yestrerdaywithtime = mydate.getFullYear() + "-0" + (mydate.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

									Todaywithtime = mydate.getFullYear() + "-0" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
								}
								else {
									Yestrerdaywithtime = mydate.getFullYear() + "-" + (mydate.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

									Todaywithtime = mydate.getFullYear() + "-" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
								}
								querystring = "api/v1/reports/getLatLongg?division=" + this.divid + "&range=" + this.rangeid + "&section=" + this.sectnid + "&beat=" + this.beatid + "&query=''&startDate=" + Yestrerdaywithtime + "&endDate=" + Todaywithtime;
							}
							else {
								Yestrerdaywithtime = SDDate.value + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

								Todaywithtime = EDDate.value + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();

								querystring = "api/v1/reports/getLatLongg?division=" + this.divid + "&range=" + this.rangeid + "&section=" + this.sectnid + "&beat=" + this.beatid + "&query=''&startDate=" + Yestrerdaywithtime + "&endDate=" + Todaywithtime;
							}
						}

					}
				}
			}
		}
		this.apiService.request('GET', querystring).subscribe((data: any) => {
			this.viewelephantcordinate(data);
		});
	}
	viewelephantcordinate(cordlist: any) {
    if (this.elephantpoitnsglobal.length > 0) {
      for (let index = 0; index < this.elephantpoitnsglobal.length; index++) {
        const element = this.elephantpoitnsglobal[index];
        this.map.removeLayer(element);
      }
    }
		//console.warn(cordlist)
		cordlist.forEach(element => {
			//console.warn(element.longitude);
			var iconFeature = new Feature({
				geometry: new Point(proj.fromLonLat([element.longitude, element.latitude])),
				name: 'Elephant Points',
				population: 4000,
				rainfall: 500,
			});
			var iconStyle = new Style({
				image: new Icon({
					// anchor: [0.5, 46],
					// anchorXUnits: 'fraction',
					// anchorYUnits: 'pixels',
					src: '../assets/images/elephant_marker.png',
				}),
			});

			iconFeature.setStyle(iconStyle);

			var vectorSource = new VectorSource({
				features: [iconFeature],
			});
			var vectorLayer = new VectorLayer({
				source: vectorSource,
			});
			this.map.addLayer(vectorLayer);
       this.elephantpoitnsglobal.push(vectorLayer);
		});
	}
	topochange(e) {
		if (e.target.checked == true) {
			this._carto.setVisible(true);
			this.imegary.setVisible(false);
			this.streetmap.setVisible(false);
			this._raster.setVisible(false);
		}
		else if (e.target.checked == false) {
			this._carto.setVisible(false);
		}
		var streetbtn = document.getElementById("streetBTN") as HTMLInputElement;
		var imagerybtn = document.getElementById("imageryBTN") as HTMLInputElement;
		var natgeoBTN = document.getElementById("natgeoBTN") as HTMLInputElement;

		streetbtn.checked = false;
		imagerybtn.checked = false;
		natgeoBTN.checked = false;

		//topoBTN streetBTN imageryBTN natgeoBTN
	}
	imagerychange(e) {
		if (e.target.checked == true) {
			this.imegary.setVisible(true);
			this.streetmap.setVisible(false);
			this._carto.setVisible(false);
			this._raster.setVisible(false);
		}
		else if (e.target.checked == false) {
			this.imegary.setVisible(false);
		}
		var streetbtn = document.getElementById("streetBTN") as HTMLInputElement;
		var topoBTN = document.getElementById("topoBTN") as HTMLInputElement;
		var natgeoBTN = document.getElementById("natgeoBTN") as HTMLInputElement;

		streetbtn.checked = false;
		topoBTN.checked = false;
		natgeoBTN.checked = false;
	}
	streetchange(e) {
		var imagerybtn = document.getElementById("imageryBTN") as HTMLInputElement;
		var topoBTN = document.getElementById("topoBTN") as HTMLInputElement;
		var natgeoBTN = document.getElementById("natgeoBTN") as HTMLInputElement;

		imagerybtn.checked = false;
		topoBTN.checked = false;
		natgeoBTN.checked = false;
		if (e.target.checked == true) {
			this.streetmap.setVisible(true);
			this.imegary.setVisible(false);
			this._raster.setVisible(false);
			this._carto.setVisible(false);
		}
		else if (e.target.checked == false) {
			this.streetmap.setVisible(false);
		}
	}
	bingmapchange(e) {
		if (e.target.checked == true) {
			this._raster.setVisible(true);
			this.streetmap.setVisible(false);
			this.imegary.setVisible(false);
			this._raster.setVisible(false);
		}
		else if (e.target.checked == false) {
			this._raster.setVisible(false);
		}
		var imagerybtn = document.getElementById("imageryBTN") as HTMLInputElement;
		var topoBTN = document.getElementById("topoBTN") as HTMLInputElement;
		var streetbtn = document.getElementById("streetBTN") as HTMLInputElement;

		imagerybtn.checked = false;
		topoBTN.checked = false;
		streetbtn.checked = false;
	}
}

