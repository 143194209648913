<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<app-loader></app-loader>
<nav id="sidebar" class="active">
  <div class="overlay-sidebar">
    <ul class="list-unstyled components accordion" id="accordionParent" *ngFor="let menuItem of GlinkList">
      <li *ngIf="menuItem.glink_id=='1' && menuItem['pLInk'].length>0 && menuItem['isActive'] == true"
        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a data-target="#submenudashboard" id="submenudashboardanchor" data-toggle="collapse" aria-expanded="false"
          class="dropdown-toggle collapsed">
          <i [ngClass]=menuItem.gIcon></i>
          {{menuItem.name}}
        </a>
        <ul class="collapse list-unstyled" [ngClass]="{'show':contains ==='1'}" id="submenudashboard"
          data-parent="#accordionParent" *ngIf="menuItem.pLInk.length>0">
          <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
            *ngFor="let menuPlinkItem of menuItem.pLInk">
            <a [routerLink]="[menuPlinkItem.linkName]" *ngIf=" menuPlinkItem['isActive'] == 'true'">
              <i [ngClass]=menuPlinkItem.pIcon></i>
              {{menuPlinkItem.name}}
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="menuItem.glink_id=='4' && menuItem['pLInk'].length>0 && menuItem['isActive'] == true"
        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a data-target="#submenuuser" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle collapsed">
          <i [ngClass]=menuItem.gIcon></i>
          {{menuItem.name}}
        </a>
        <ul class="collapse list-unstyled" id="submenuuser" [ngClass]="{'show':contains ==='2'}"
          data-parent="#accordionParent" *ngIf="menuItem.pLInk.length>0">
          <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
            *ngFor="let menuPlinkItem of menuItem.pLInk">
            <a [routerLink]="[menuPlinkItem.linkName]" *ngIf=" menuPlinkItem['isActive'] == 'true'">
              <i [ngClass]=menuPlinkItem.pIcon></i>
              {{menuPlinkItem.name}}
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="menuItem.glink_id=='7' && menuItem['pLInk'].length>0 && menuItem['isActive'] == true"
        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a data-target="#submenucms" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle collapsed">
          <i [ngClass]=menuItem.gIcon></i>
          {{menuItem.name}}
        </a>
        <ul class="collapse list-unstyled" id="submenucms" [ngClass]="{'show':contains ==='3'}"
          data-parent="#accordionParent" *ngIf="menuItem.pLInk.length>0">
          <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
            *ngFor="let menuPlinkItem of menuItem.pLInk">
            <a [routerLink]="[menuPlinkItem.linkName]" *ngIf=" menuPlinkItem['isActive'] == 'true'">
              <i [ngClass]=menuPlinkItem.pIcon></i>
              {{menuPlinkItem.name}}
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="menuItem.glink_id=='14' && menuItem['pLInk'].length>0 && menuItem['isActive'] == true"
        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a data-target="#submenusquard" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle collapsed">
          <i [ngClass]=menuItem.gIcon></i>
          {{menuItem.name}}
        </a>
        <ul class="collapse list-unstyled" id="submenusquard" [ngClass]="{'show':contains ==='3'}"
          data-parent="#accordionParent" *ngIf="menuItem.pLInk.length>0">
          <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
            *ngFor="let menuPlinkItem of menuItem.pLInk">
            <a [routerLink]="[menuPlinkItem.linkName]" *ngIf=" menuPlinkItem['isActive'] == 'true'">
              <i [ngClass]=menuPlinkItem.pIcon></i>
              {{menuPlinkItem.name}}
            </a>
          </li>
        </ul>
      </li>
      <li *ngIf="menuItem['pLInk'].length==0 && menuItem['isActive'] == 'true'" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}">
        <a class="nav-link" [routerLink]="[menuItem.linkName]">
          <i [ngClass]=menuItem.gIcon></i>
          {{menuItem.name}}
        </a>
      </li>
    </ul>
    <!-- <ul class="list-unstyled components accordion" id="accordionParent">
          <li class="active">
            <a data-target="#submenudashboard" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle collapsed">
                <i class="mdi mdi-account-check"></i>
                 Dashboard
            </a>
            <ul class="collapse list-unstyled" id="submenudashboard" data-parent="#accordionParent">
                <li  class="active">
                  <a href="/dashboarduser"><i class="mdi mdi-speedometer"></i>User Dashboard</a>
                </li>
                <li>
                  <a href="/dashboard"><i class="mdi mdi-speedometer"></i>Elephant Dashboard</a>
                </li>
            </ul>
          </li>

	        <li>
	            <a href="/elephantcorridormap">
	            	<i class="mdi mdi-view-quilt"></i>
	            	Map View
	            </a>
	        </li>
            <li>
	            <a href="/elephantmovementrecord">
	            	<i class="mdi mdi-view-quilt"></i>
	            	Elephant Movement Record
	            </a>
	        </li>
	        <li>
	            <a data-target="#submenu12" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle collapsed">
	            	<i class="mdi mdi-account-check"></i>
	            	User Management
	            </a>
	            <ul class="collapse list-unstyled" id="submenu12" data-parent="#accordionParent">
	                <li>
	                    <a href="/usermanagement">Manage User</a>
	                </li>
	                <li>
	                    <a href="/viewrolemanagement">View Role Management</a>
					        </li>
				         	<li>
	                    <a href="/addrolemanagement">Add Role Management</a>
	                </li>

	            </ul>
	        </li>

	    </ul> -->
  </div>
</nav>