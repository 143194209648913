<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
					<li class="nav-item">
						<a id="tab-kalahandimap" href="#pane-kalahandimap" class="nav-link active" data-toggle="tab"
							role="tab">Map</a>
					</li>
					<li class="nav-item">
						<a id="tab-kalahandiinfo" href="#pane-kalahandiinfo" class="nav-link" data-toggle="tab"
							role="tab">Information</a>
					</li>
				</ul>

				<div id="accordion" class="tab-content" role="tablist">
					<div id="pane-kalahandimap" class="card tab-pane fade show active" role="tabpanel"
						aria-labelledby="tab-kalahandimap">
						<div class="card-header" role="tab" id="heading-kalahandimap">
							<h5 class="mb-0">
								<a data-toggle="collapse" href="#collapse-kalahandimap" aria-expanded="true"
									aria-controls="collapse-kalahandimap">
									Map
								</a>
							</h5>
						</div>
						<div id="collapse-kalahandimap" class="collapse show" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-kalahandimap">
							<div class="card-body">
								<div class="map" id="kalahandiWLmap">
									<div class="gle-icon">
										<a href="javascript:void(0)">
											<img src="assets/images/icon-street.png" (click)='streetchange()'
												class="img-fluid" alt="Street View" title="Street View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-satellite.png" class="img-fluid"
												(click)='stelitechange()' alt="Satellite View" title="Satellite View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-topo.png" class="img-fluid"
												(click)='topochange()' alt="Topo View" title="Topo View">
										</a>
									</div>
								</div>


							</div>
						</div>
					</div>

					<div id="pane-kalahandiinfo" class="card tab-pane fade" role="tabpanel"
						aria-labelledby="tab-kalahandiinfo">
						<div class="card-header" role="tab" id="heading-kalahandiinfo">
							<h5 class="mb-0">
								<a class="collapsed" data-toggle="collapse" href="#collapse-kalahandiinfo"
									aria-expanded="false" aria-controls="collapse-kalahandiinfo">
									Information
								</a>
							</h5>
						</div>
						<div id="collapse-kalahandiinfo" class="collapse" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-kalahandiinfo">
							<div class="card-body">
								<h4 class="card-title">
									Kalahandi (South) Forest Division
									<span class="custom-down">
										<button type="" (click)="print()"
											class="btn btn-sm btn-info mr-1"><i
												class="fa fa-file-pdf">&nbsp;</i>Information</button>
										<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
												class="fa fa-file-pdf">&nbsp;</i>Map</button>
									</span>
								</h4>
								<div id="printcontent">
									<div class="row">
										<div class="col-md-9">
											<div class="table-responsive">
												<table class="table table-sm table-bordered">
													<tbody>
														<tr>
															<td><strong>District : </strong></td>
															<td>Kalahandi</td>
														</tr>
														<tr>
															<td><strong>Geographical Extent : </strong></td>
															<td>Longitude 82° 30'E and 83° 28'E & Latitude 20° 1'N and
																19° 10'N</td>
														</tr>
														<tr>
															<td><strong>Climate : </strong></td>
															<td>Temperature - In summer: 45ºC, in winter: 6ºC and
																average rainfall is 1385 mm</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div class="col-md-3">
											<img src="assets/images/wildlifeunits-kalahandi-01.jpg"
												class="img-fluid float-right img-inner" alt="image">
										</div>
									</div>
									<p>This Kalahandi South forest division was bifurcated from erstwhile Kalahandi
										division in 2003. The headquater is located at Bhawanipatna , Kalahandi district
										is The entire sanctuary area comes under karlapat range of kalahandi south
										division with head quarter is at Bhawanipatna. The sanctuary was notified u/s 18
										of Wildlife Protection Act.1972 vide Notification o.24498-8F-41/92FE Govt. of
										Orissa.Karlapat inhabits healthy for its elephant population and serves as a
										crucial corrider for the elephants i.e. Karlapat-Ueladani elephant corridor.</p>
									<h4 class="card-title">Flora </h4>
									<p>The Upper storey consists of : Arjun (Terminalia arjuna), Asan (Terminalia
										tomentosa), Bahada (Terminalia belerica), Bija (Pterocarpus marsupium), Dharua
										(Anogeissus latifolia), Haldu (Adina cordifolia), Harida (Terminalia chebula),
										Jamun (Syzygium cumini) along nallah bank,Kendu (Diospyros melanoxylon), Kusum
										(Schleichera oleosa), Moi (Garuga pinnata), Mundi (Mitragyna parviflora), Sissoo
										(Dalbergia latifolia), Sidha (Lagerstroemia parviflora), Simul (Bombax ceiba),
										Teak (Tectona grandis) The middle storey : Amla (Emblica officinalis), Bandhan
										(Ougenia oojenensis), Bheru (Chloroxylon swietenia), Chara (Buchnania lanzan),
										Karla (Cleistanthus collinus), Kumbhi (Careya arborea), Khair (Acacia catechu),
										Siris (Albizzia lebbek), Sunari (Cassia fistula) Ground flora: Bana Khajuri
										(Phoenix acaulis), Gange siuli (Nyctanthus arbortristics), Gindhi (Indigofera
										pulchela), Kurei (Holorrhena antidysenterica), Ranikathi (Flemengia chapper),
										Mirgachara (Grewia elastica), Dhatuki (Woodfordia fruiticosa), Muraphal
										(Helecteres isora) Climbers: Asadhua (Caparis zeylanica), Atundi (Combratum
										decandrum), Gaj (Milletia auriculata), Latapalas (Butea superba), Muturi (Smilax
										macrophylla), Patchurimia (Ventilago calyculata), Satabari (Asparagus
										racemosus), Siali (Bauhinia vahili) Grasses: Basana (Bethriochlooa pterma), Bena
										(Khus-Khus) (Vetiveria zizanoides), Broom grass (Thysanolaena maxima), Chhana
										(Imperata arundinacea), Dhanwantary (Cymbopogon martini), Phurphuri (Eragrostis
										unioloides), Sinkulia (Heteropogon conturtus), Sabai (Eulaliopsis binata), Tenda
										(Sacharrum spontaneum)</p>
									<h4 class="card-title">Fauna </h4>
									<p>The residents of this division includes Leopard,Chital,Sambar,Barking deer,Wild
										pig,Porcupine,Rufous tailed hare,Common Langur,Indian Elephants,Sloth
										Bear,Pangolin,Hyena,Jackal,Indian civet,Jungle cat,Common lizard,Indian
										Chameleon,Monitor lizard,Cobra,Rusels viper,Bonded Krait,Python,Ferguson
										toad,Common Indian toad,Common tree frog,Indian skipper frog.</p>
									<h4 class="card-title">Set-Ups</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td><strong>Administrative set-up (Range, Section, beats) :</strong>
													</td>
													<td>Ranges- 7, Sections- 24,Beats- 84</td>
												</tr>
												<tr>
													<td><strong>EDC/JFM set-up: </strong></td>
													<td>
														EDCs - 10, VSS - 0
													</td>
												</tr>
												<tr>
													<td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
													<td>No. of RF- 4, No. of PRF- 2, No. of DPF- 0, No. of UDPF- 0, No.
														of VF- 0</td>
												</tr>
											</tbody>
										</table>
									</div>
									<h4 class="card-title">Major Attraction</h4>
									<div class="row">
										<div class="col-md-12">
											<img src="assets/images/wildlifeunits-kalahandi-02.jpg"
												class="img-fluid float-right img-inner" alt="image">
											<ul class="custom-list">
												<li>Phurlijharana waterfalls is key tourist attration.People from
													Bhawanipatna and surrounding districts visit the place for "day
													picnic".In peak season people from Jharakhand,Chatishgarh and
													West-Bengal do visit the place.</li>
												<li>Natural movement of wild animals inside the forests.</li>
												<li>The luxuriant growth of forest trees providing impenetrable cover to
													the hill slopes with good Vegetation,in and Jacum.</li>
												<li>Maa Mani Keswari temple,adjacent to old palace.</li>
												<li>Ravandhara waterfalls which is 10km from Bhawanipatna.</li>
												<li>Dokrichanchara waterfall near Ram Mandir,Dharamgarh which is 45km
													from Bhawanipatna.</li>
												<li>Vadanta Alumina which is Lanjigarh 55km from Bhawanipatna.</li>
											</ul>
										</div>
									</div>
									<h4 class="card-title">Salient Detail</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td width="15%"><strong>HQ Address :</strong></td>
													<td width="85%" colspan="2">AT/PO-Bhawanipatna, Dist:- Kalahandi
														Ralway station Road,Padampur.</td>
												</tr>
												<tr>
													<td><strong>Contact officer : </strong></td>
													<td>
														<span class="address-inner"><i
																class="fa fa-user">&nbsp;</i></span>Range Officer<br>
														<span class="address-inner"><i
																class="fa fa-map-marker">&nbsp;</i></span>Karlapat
														sanctuary range, Bhawanipatna. <br>
													</td>
													<td>
														<span class="address-inner"><i
																class="fa fa-user">&nbsp;</i></span>Divisional Forest
														Officer<br>
														<span class="address-inner"><i
																class="fa fa-map-marker">&nbsp;</i></span> Kalahandi
														South Forest Division<br>
													</td>
												</tr>
												<tr>
													<td><strong>Places to see : </strong></td>
													<td colspan="2">Phurlijharan, Jakam wooden guest house,etc.,</td>
												</tr>
											</tbody>
										</table>
									</div>
									<p class="text-danger"><small><i
												class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
											</strong>The information contained herein are provided solely for
											informational purposes only.The content is dynamic and may undergo change
											from time to time. The snapshot of the content at a particular time is not
											to be referred as a legally binding document.</small></p>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>
