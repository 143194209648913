import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from '../model/User';
import {Login} from '../model/login.model';
import {Observable,from} from "rxjs/index";
import { AppConfig } from '../config/config';
import { environment } from 'src/environments/environment.prod';
import { post } from 'jquery';



const baseUrl = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private loggedIn = false;
  private token: string;

  constructor(private http: HttpClient) { }
  setLoggedIn(loggedIn: boolean, token?: string) {
    this.loggedIn = loggedIn;
    this.token = token;
  }

  request(method: string, route: string, data?: any) {

   const header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;
    if (method === 'GET') {
      return this.get(route, data);
    }
     else if (method === 'PUT') {
      return this.http.request(method, baseUrl + route, {
        body: data,
        responseType: 'text',
        observe: 'body',//'response',
        headers: header
      });
    }
    return this.http.request(method, baseUrl + route, {
      body: data,
      responseType: 'json',
      observe: 'body',
      headers: header
    });
  }
  requestGeoJson(route: string, data?: FormData):Observable<any>{
   return this.http.post(route,data);
  }
  get(route: string, data?: any) {
    const header = (this.loggedIn) ? { Authorization: `Bearer ${this.token}` } : undefined;

    let params = new HttpParams();
    if (data !== undefined) {
      Object.getOwnPropertyNames(data).forEach(key => {
        params = params.set(key, data[key]);
      });
    }
    //console.log(route)
    return this.http.get(baseUrl + route, {
      responseType: 'json',
      headers: header,
      params
    });
  }
  // UpdateUser(loginid:any,roles:any,updatedata:any)
  // {
  //   return this.http.post<any[]>(this.baseUrl)
  // }

}
