<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-3">

				<div class="custom-menu-inner">
					<h4 class="card-title">Projects & Conservation</h4>
					<ul>
						<li><a [routerLink]="['/projectelephant']">Project Elephant</a></li>
						<li><a [routerLink]="['/projecttiger']">Project Tiger</a></li>
						<li class="active"><a [routerLink]="['/crocodileconservation']">Crocodile Conservation</a></li>
						<li><a [routerLink]="['/seaturtleconservation']">Sea Turtle Conservation</a></li>
						<li><a [routerLink]="['/blackbuckconservation']">Blackbuck Conservation</a></li>
						<li><a [routerLink]="['/mangroves']">Mangroves</a></li>
					</ul>
				</div>

			</div>
			<div class="col-md-9">

				<h4 class="card-title">Crocodile Conservation</h4>

				<img src="assets/images/crocodile_1.gif" class="img-fluid float-right img-inner" alt="image">
				<p>Crocodilians were threatened in India due to indiscriminate killing for commercial purpose and severe habitat loss until the enactment of the Wildlife (Protection) Act, 1972. All the three species of crocodiles, namely, Gharial (Gavialis gangeticus), Mugger crocodile (Crocodylus palustris) and Saltwater crocodile (Crocodylus porosus), in the river systems of Odisha were on the verge of extinction by the seventies. Crocodile population started to decline because of the increasing human activity in the rivers and their other traditional habitats, and consequent reduction in the extent of habitable stretches. Also, the survival rate of the crocodile hatchlings was relatively low because of predation. Piecemeal efforts were being made since the sixties to save the crocodile. FAO Expert, Dr. H.R.Bustard, engaged by UNDP/FAO and Government of India studied the prospects of crocodile rehabilitation, and based on his report and guidance a Crocodile Conservation Project was launched in 1975 in different States. Since Odisha is recognized for the existence of all the three species of Indian crocodilians, the Gharial and Saltwater crocodile conservation programme was first implemented in Odisha in early 1975 and subsequently, the Mugger conservation programme was initiated. The UNDP/ FAO provided funds and other technical support through the Government of India.</p>

				<h4 class="card-title">Management Objectives</h4>
				<img src="assets/images/CROCODILE3.jpg" class="img-fluid float-right img-inner" alt="image">
				<ul class="custom-list">
					<li>To protect the remaining population of crocodilians in their natural habitat by creating sanctuaries.</li>
					<li>
						To rebuild natural population quickly through 'grow and release' or 'rear and release' technique that involves the following phases of operation.<br>
						1. Collection of eggs from natural nests as soon as these are laid,<br>
						2. Incubation of these eggs under ideal temperature and humidity maintained in artificial hatcheries,<br>
						3. Hatching and rearing the young crocodilians in ideal captive-husbandry conditions,<br>
						4. Marking and releasing young crocodiles in protected areas, and<br>
						5. Assessing the result of the released crocodiles and protection of the same.
					</li>
					<li>To promote captive breeding</li>
					<li>
						To take-up research to improve management. Some of the major researches have been in the following directions.<br>
						1. Interpretation of the various types of data collected during survey and census.<br>
						2. Determination of parameters for maximizing success in egg collection, egg incubation, hatching, rearing and release; including husbandry aspects on feeding, food conversion and growth.<br>
						3. Study of habitat features and population structure.<br>
						4. Study of behavioral biology including reproduction, thermo-regulation, feeding, water-orientation, locomotion etc 
					</li>
					<li> To build up the skills of the personnel for better continuity of the project through trainings imparted at the project-sites and the Central Crocodile Breeding and Management Training Institute, Hyderabad.</li>
					<li>
						To involve the local people intimately through the following:<br>
						1. Developing a strong level of acceptance of the project by the people, i.e., by locating the projects in rural areas where people could both see and participate in the entire programme.<br>
						2. Protect the immediate and long-term interests of fishermen residing within the sanctuaries who depend on fishing for their livelihood, by providing an alternative source of income that is not detrimental to the conservation aims, if necessary.<br>
						3. Extend the conservation programme to village-level by introducing commercial crocodile farming, so that people can earn an income by conserving crocodiles and their habitats.
					</li>
				</ul>

				<h4 class="card-title">Project sites in Odisha</h4>
				<p>During 1976, the survey of (i) Salt-water crocodiles and (ii) Gharial crocodiles, was conducted in the river system of Bhitarkanika and Mahanadi, respectively. The number of salt-water crocodiles in Bhitarkanika was estimated to be 95, including 34 adults. Similarly, in Mahanadi ,the number of Gharials was estimated to be 8, which included 4 adults. No detailed survey was, however, conducted for Mugger crocodiles at that time, although the species occurred at several places in the State. The breeding population of Mugger known at that time was the Balimela Dam in Koraput district. The Crocodile Project started with an objective of building the population to a stage where incidence of sighting could be 5 to 6 crocodiles per KM length in water. The Project sought to make up the natural losses by death and predation through rear and release operation. To accomplish these tasks, 3 separate research units were established at Tikarpara, Dangmal and Ramatirtha for the Gharial, Salt Water Crocodile and the Mugger, respectively. At the Nandankanan Biological Park, captive breeding plans for all three species were pursued.</p>

				<h4 class="card-title">'BAULA' project at Dangamal</h4>
				<img src="assets/images/crocodile_img2.gif" class="img-fluid float-right img-inner" alt="image">
				<p>‘Baula’ is the Oriya term for Saltwater Crocodile. At Dangmal in Bhitarkanika sanctuary, salt-water crocodile eggs have been collected locally; and young crocodiles have been released in the creeks and the estuaries; and more than 2200 crocodiles have been released in phases since 1977.</p>
				<p>This operation has been reasonably successful and the crocodile population in the Bhitarkanika river system has gradually been built up. Above 50 released female Saltwater Crocodiles have laid eggs in the wild and bred successfully.</p>
				<p>The annual census conducted in the river systems of Bhitarkanika wildlife sanctuary in January 2004 indicated that there were 1308 Saltwater crocodiles and is on increasing trend. The details about the census results are as under:</p>

				<div class="table-responsive">
					<table class="table table-sm table-bordered">
						<thead>
							<tr>
								<th colspan="6" class="text-center">Size class and number of crocodiles</th>
							</tr>
							<tr>
								<th>Hatch-lings</th>
								<th>Year-lings</th>
								<th>Juvenile</th>
								<th>Sub-adult</th>
								<th>Adult</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>525</td>
								<td>303</td>
								<td>210</td>
								<td>100</td>
								<td>220</td>
								<td>1358</td>
							</tr>
							<tr>
								<td>38.60%</td>
								<td>22.30%</td>
								<td>15.50%</td>
								<td>7.40%</td>
								<td>16.20%</td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>

				<h5>Comparison of census results (2000-2004)</h5>
				<div class="table-responsive">
					<table class="table table-sm table-bordered">
						<thead>
							<tr>
								<th colspan="5" class="text-center">Census year and no. of crocodiles</th>
							</tr>
							<tr>
								<th>2004</th>
								<th>2003</th>
								<th>2002</th>
								<th>2001</th>
								<th>2000</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1358</td>
								<td>1308</td>
								<td>1330</td>
								<td>1098</td>
								<td>914</td>
							</tr>
						</tbody>
					</table>
				</div>

				<h5>Range wise crocodile number (2004)</h5>
				<div class="table-responsive">
					<table class="table table-sm table-bordered">
						<thead>
							<tr>
								<th colspan="5" class="text-center">Forest ranges and no. of crocodiles</th>
							</tr>
							<tr>
								<th>Kanika</th>
								<th>Rajnagar</th>
								<th>Mahakalapada</th>
								<th>Chandbali</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1149 (84.6%)</td>
								<td>172 (12.67%)</td>
								<td>19 (1.40%)</td>
								<td>18 (1.33%)</td>
								<td>1358</td>
							</tr>
						</tbody>
					</table>
				</div>

				<h5>Results indicate the followings :</h5>
				<ul class="custom-list">
					<li>There are 220 full grown adults among 1358 crocodiles counted;</li>
					<li>There is an increase of 3.8% over 2003-04 results;</li>
					<li>Six adults are above 18’ length.</li>
					<li>Kanika range holds 84% of the entire present population</li>
				</ul>

				<h5>The areas having higher concentration of crocodiles have :</h5>
				<ul class="custom-list">
					<li>Good mangrove cover / fringing mangrove vegetation,</li>
					<li>Less human disturbances (little or no illegal fishing activities), and</li>
					<li>A network of the river, creeks and creek lets.</li>
				</ul>
				<p>Thus, above 70% of the entire crocodile population is restricted to nearly 30% areas /habitat i.e., Khola-Brahmani river confluence to Bhitarkanika-Pathasala river confluence, of the Bhitarkanika National Park.</p>

				<h4 class="card-title">MUGGER PROJECT AT RAMATIRTHA</h4>
				<img src="assets/images/crocodile_img3.gif" class="img-fluid float-right img-inner" alt="image">
				<p>The Ramatirtha center, meant for Mugger crocodiles, initially started with eggs and juveniles of Mugger procured from Tamilnadu. Since 1984, the breeding of Muggers and the release of young ones into Similipal have been carried out, and so far more than 600 crocodiles have been released in Similipal.</p>
				<p>A census was conducted to ascertain the population status of Mugger crocodiles in the prominent rivers/waterbodies of Similipal. The river-wise census results have been listed below.</p>

				<h4 class="card-title">The census results indicate the followings : </h4>
				<ul class="custom-list">
					<li>In the west of the Deo river, two stretches namely UBK-Patbil and Kandadhenu- Lower Barhakamuda hold 27 of 42 muggers (64.28%) of total muggers in about 15 km stretch of the river.</li>
					<li>In River Khairi, the stretches between Jenabil and Ransa, about 15 km long, hold 13 (65%) of 20 Muggers.</li>
					<li>The regular monitoring of the crocodiles have been carried out since 1980s which has resulted in an increase in the number of crocodiles.</li>
				</ul>

				<h4 class="card-title">River and size / length wise distribution of Crocodiles-2004</h4>
				<div class="table-responsive">
					<table class="table table-sm table-bordered">
						<thead>
							<tr>
								<th></th>
								<th colspan="5" class="text-center">No. of Crocodiles</th>
							</tr>
							<tr>
								<th>River</th>
								<th><1m</th>
								<th>1-1.5m</th>
								<th>1.5-2.0m</th>
								<th>>2.0m</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>West Deo</td>
								<td>14</td>
								<td>8</td>
								<td>16</td>
								<td>4</td>
								<td>42 (50.60%)</td>
							</tr>
							<tr>
								<td>Khadkei</td>
								<td>2</td>
								<td>3</td>
								<td>2</td>
								<td>0</td>
								<td>7(08.44%)</td>
							</tr>
							<tr>
								<td>Budha-balanga</td>
								<td>0</td>
								<td>2</td>
								<td>5</td>
								<td>2</td>
								<td>9(10.84)</td>
							</tr>
							<tr>
								<td>East Deo</td>
								<td>1</td>
								<td>4</td>
								<td>0</td>
								<td>0</td>
								<td>5(06.03%)</td>
							</tr>
							<tr>
								<td>Khairi</td>
								<td>5</td>
								<td>3</td>
								<td>10</td>
								<td>2</td>
								<td>20(24.09%)</td>
							</tr>
							<tr>
								<td>Total</td>
								<td>22</td>
								<td>20</td>
								<td>33</td>
								<td>8</td>
								<td>83</td>
							</tr>
						</tbody>
					</table>
				</div>

				<h4 class="card-title">GHARIAL PROJECT AT TIKARPADA</h4>
				<img src="assets/images/CROCODILE4.jpg" class="img-fluid float-right img-inner" alt="image">
				<p>At Tikarpara, Gharial eggs were obtained at regular intervals from Narayani and Kali rivers in Nepal, and Chambal sanctuary in Madhya Pradesh, Rajasthan and Uttar Pradesh. The eggs collected from Mahanadi were infertile. Some hatchlings of Gharial were obtained from eggs incubated in Royal Chitwan National Park of Nepal and Katarniyaghat sanctuary in Uttar Pradesh. All Gharials reared at Tikarpara and those produced from captive breeding at Nandankanan zoo, numbering to more than 700, were released in the Mahanadi river between Boudh and Katrang.</p>
				<p>One of the assessments of the rear and release operation with respect to Gharials was made during December, 1987 - January, 1988, when only 25 Gharials were found to be surviving in the entire stretch of the Mahanadi and the downstream of Hirakud Reservoir over a length of 400 Kms. According to the January 2003 census, one male and two female Gharials were sighted / counted in and outside of the sanctuary, respectively. A study was carried out to assess the reason for poor survival of the Gharial in Mahanadi. To increase the Gharial population to more viable levels, it is essential to minimize fishing and navigation on certain stretches of the river. It has been under consideration to rehabilitate Gharials in Mahanadi up-stream of Hirakud reservoir, and some stretches of the river Brahmani.</p>
				<p>The census conducted in winter-2004 in the river system of Mahanadi within the limits of Satkosia Gorge Sanctuary indicated survival of only one male Gharial (3-3.5m length). This Gharial was located between Binikei and Majhipada of the river Mahanadi / Satkosia Gorge.</p>

				<h4 class="card-title">Management in the Wild</h4>
				<p>Since the prime objective of crocodile project is to rebuild their population in the wild, restocking the sanctuaries with captive reared crocodiles is an ongoing programme. The resident and released populations of crocodiles are periodically monitored and the trend of nesting is also assessed.</p>
				<p>Protection of crocodiles is a round-the-year activity. Major threats are from intruding fishermen using nylon set nets.</p>
				<p>Because of the occasional reappearance of crocodiles in their former habitat, it is now shared by increasing human population; there are occasional instances of nuisance crocodile. Such crocodiles are generally captured and shifted to some other river/creek or are brought back to captivity after careful consideration of the case histories and field conditions.</p>

				<h4 class="card-title">Captive Breeding of Crocodiles at Nandankanan</h4>
				<p>Captive breeding units of all the three crocodilian species have been established at Nandankanan Zoo. Muggers bred at there have also been released in Satkosia Gorge. Although Gharials have failed to establish in Satkosia Gorge, Muggers have settled down well in this stretch of Mahanadi River. As per the survey carried out in January to March 2004, there are 64 Muggers in Mahanadi river system and 83 Muggers in the rivers of Similipal.</p>

				<h4 class="card-title">Research & Training</h4>
				<p>In-house research has been conducted to standardise 'the rear and release' technique. Studies have been carried out to determine the appropriate method of population assessment, egg collection, egg incubation, hatching, and husbandry of the young crocodiles and various aspects of behavioral biology of the three species of crocodiles and their habitat features. The baseline data collection on Indian crocodilians and their management has been possible because of the 300 publications produced from these conservation projects.</p>
				<p>Training has been imparted to all field staff employed in the project to help them to discharge their duties efficiently. Local people have been trained to bring live food for the hatchling in the pools. The research officers of this organisation have availed the overseas training and have also undertaken courses through the UNDP/FAO Programme. Based on their performance and contribution, they have been a part of the steering committee and also a member of the IUCN/ SSC Crocodile Specialist Group.</p>

				<h4 class="card-title">Contributions</h4>
				<p>All the three species of crocodiles have been saved from the brink of extinction, and many of their habitats have been brought under the protected area network of the State. The crocodile project that has been working at a slow-pace in the State has contributed handsomely to develop the know-how and spread the awareness. Future of the crocodiles can be secured with adequate protection of their habitats.</p>
				<p>Apart from producing a large number of crocodiles in a short span, the Crocodile Project has contributed in various ways to the entire approach of wildlife conservation, research and training such as :</p>
				<ul class="custom-list">
					<li>Local people have been intimately involved in the management of crocodiles</li>
					<li>Full time research personnel have been inducted into the wildlife wing to carry out research on crocodiles and other associated wildlife</li>
					<li>Some important wetland sanctuaries have been created with crocodiles as the flagship-species. Active management of other wetland species began in conjunction with the crocodilians. These included the Mangrove Plants, Marine Turtles, Freshwater Turtles, Monitor Lizards, Gangetic Dolphins, Otters and other reptilian fauna.</li>
					<li>It can be said that, the crocodile project is the beginning of an intimate overseas collaboration in the field of wildlife conservation, education and training.</li>
				</ul>

				<p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>
