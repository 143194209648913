<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <ul id="tabs" class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a id="tab-balukhandaPAmap" href="#pane-balukhandaPAmap" class="nav-link active" data-toggle="tab"
              role="tab">Map</a>
          </li>
          <li class="nav-item">
            <a id="tab-balukhandaPAinfo" href="#pane-balukhandaPAinfo" class="nav-link" data-toggle="tab"
              role="tab">Information</a>
          </li>
        </ul>

        <div id="accordion" class="tab-content" role="tablist">
          <div id="pane-balukhandaPAmap" class="card tab-pane fade show active" role="tabpanel"
            aria-labelledby="tab-balukhandaPAmap">
            <div class="card-header" role="tab" id="heading-balukhandaPAmap">
              <h5 class="mb-0">
                <a data-toggle="collapse" href="#collapse-balukhandaPAmap" aria-expanded="true"
                  aria-controls="collapse-balukhandaPAmap">
                  Map
                </a>
              </h5>
            </div>
            <div id="collapse-balukhandaPAmap" class="collapse show" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-balukhandaPAmap">
              <div class="card-body">
                <div id="mapkonark" class="map">
                  <div class="gle-icon">
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid"
                        alt="Street View" title="Street View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()'
                        alt="Satellite View" title="Satellite View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View"
                        title="Topo View">
                    </a>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div id="pane-balukhandaPAinfo" class="card tab-pane fade" role="tabpanel"
            aria-labelledby="tab-balukhandaPAinfo">
            <div class="card-header" role="tab" id="heading-balukhandaPAinfo">
              <h5 class="mb-0">
                <a class="collapsed" data-toggle="collapse" href="#collapse-balukhandaPAinfo" aria-expanded="false"
                  aria-controls="collapse-balukhandaPAinfo">
                  Map
                </a>
              </h5>
            </div>
            <div id="collapse-balukhandaPAinfo" class="collapse" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-balukhandaPAinfo">
              <div class="card-body">
                <h4 class="card-title">
                  Balukhand-Konark Sanctuary
                  <span class="custom-down">
                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i
                        class="fa fa-file-pdf">&nbsp;</i>Information</button>
                        <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                  </span>
                </h4>
                <div id="printcontent">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td><strong>Forest Division : </strong></td>
                              <td>Puri (Wildlife) Division</td>
                            </tr>
                            <tr>
                              <td><strong>District : </strong></td>
                              <td>Puri</td>
                            </tr>
                            <tr>
                              <td><strong>Date of Notification : </strong></td>
                              <td>01-09-1987</td>
                            </tr>
                            <tr>
                              <td><strong>Notified Area : </strong></td>
                              <td>87.00 Sq.kms</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-2 video-box no-print">
                      <a href="#" class="video-btn" data-toggle="modal"
                        data-src="https://www.youtube.com/embed/6zIWGY0A_1w" data-target="#myModal">
                        <h4>Balukhand-Konark Sanctuary</h4>
                        <div class="overlay">
                          <span class="icon">
                            <i class="fa fa-play-circle"></i>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="col-md-2">
                      <img src="assets/images/balu-kon.jpg" class="img-fluid float-right img-inner" alt="image">
                    </div>
                  </div>
                  <p>
                    This sanctuary is situated at convenient location along sea coast between Puri and
                    Konark.This Wildlife Sanctuary extends from Banki Muhan near Puri to Keluni Muhan
                    near Daluakani.The sanctuary is filled with lush green vegetation, cashew and
                    casuarina plantations and mangrove forest . Its undisturbed sandy beaches offer high
                    potentialities for developing as a major eco-tourism spot in Odisha.The sanctuary is
                    covered by plantation of casurina and cashew trees. It is home to herds of
                    herbivores(spotted deer),monkeys,jungle cat,hyena,monitos lizad, snakes etc.Olive
                    Ridley sea turtles are seen nesting in the beach. Balukhand-Konark Wildlife
                    Sanctuary is traversed by the rivulets such as Nuanai River, Kusabhadra River, Kadua
                    River and Prachi River.
                  </p>
                  <h4 class="card-title">Quick Facts</h4>
                  <ul class="custom-list">
                    <li>The landscape beauty of Balukhand-Konark Wildlife Sanctuary is a favourite
                      eco-tourism destination for both domestic and international tourists.</li>
                    <li>The flora of the area includes casuarina, acacia,cashew,eucalyptus,, neem,
                      karanjia, etc.</li>
                    <li>The region is famous for the occurrence of a large number of blackbucks and
                      spotted deer.</li>
                    <li>The prominent fauna also include monkey, squirrel, jungle cat, hyena, jackal,
                      mangoose and variety of birds and reptiles.</li>
                    <li>Olive Ridley Sea Turtles nest on the beaches.</li>
                  </ul>
                  <h4 class="card-title">How to reach</h4>
                  <p class="address-inner">
                    <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>The Puri-Konark Marine
                    Drive road passes through this Sanctuary. Puri is only 3 km from the Sanctuary entry
                    point.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong>The nearest railway
                    station is at Puri situated at a distance of about 3 kilometers from the Sanctuary.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>The nearest airport is at
                    Bhubaneswar which is 60 Km away.
                  </p>
                  <h4 class="card-title">Facilities available</h4>
                  <ul class="custom-list">
                    <li>Good road network including marine drive road.</li>
                    <li>The tourists can find the tranquillity and solitude of this Sanctuary on the
                      calm sandy beaches.</li>
                    <li>Enchanting boat ride in the river Nuanai closed to its confluence with the sea,
                      adds the beauty.</li>
                    <li>Nature camp at Nuania with tent accommodation(6Nos) for nigh stay.</li>
                  </ul>
                  <h4 class="card-title">Salient Detail</h4>
                  <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                      <tbody>
                        <tr>
                          <td width="15%"><strong>Geographic Extent : </strong></td>
                          <td width="85%">Longitudes 85° 51' to 86° 14'(east) Latitude 19° 54' to
                            19° 49'</td>
                        </tr>
                        <tr>
                          <td><strong>Climate : </strong></td>
                          <td>Temperature - In summer: 34°C(max), In winter: 16°C(min)</td>
                        </tr>
                        <tr>
                          <td><strong>Villages :</strong></td>
                          <td>30 villayes with in the ESZ</td>
                        </tr>
                        <tr>
                          <td><strong>Contact officer : </strong></td>
                          <td>
                            <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest
                            Officer<br>
                            <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Puri Wildlife
                            Division <br>
                            <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06752-228281<br>
                            <span class="address-inner"><i class="fa fa-fax">&nbsp;</i></span>06752-228281<br>
                            <span class="address-inner"><i class="fa fa-envelope">&nbsp;</i></span>dfopuri@gmail.com
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Best time to visit : </strong></td>
                          <td>November to February</td>
                        </tr>
                        <tr>
                          <td><strong>Accommodation : </strong></td>
                          <td>There is no accommodation within the Sanctuary. Tourist can stay at
                            Puri or Konark where a good number of hotels of different categories
                            are available. Nature camp at Nuania with tent accommodation
                            facilities in swiss cotton tents(6Nos) .</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
                      </strong>The
                      information contained herein are provided solely for informational purposes
                      only.The content is dynamic and may undergo change from time to time. The
                      snapshot of the content at a particular time is not to be referred as a legally
                      binding document.</small></p>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
