import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "../service/api.service";
import { AuthService } from "../service/auth.service";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import ImageWMS from 'ol/source/ImageWMS';
import { View } from 'ol';
import Map from 'ol/Map';
import * as layer from 'ol/layer';
import * as proj from 'ol/proj';
import { Tile as Tile, Vector as VectorLayer, Image as ImageLayer } from 'ol/layer';
import { OSM as OSM, Vector as VectorSource, Vector as MeasureSource } from 'ol/source';
import { defaults as defaultInteractions, Modify, Select } from 'ol/interaction';
import * as control from 'ol/control';
import Overlay from 'ol/Overlay';
import { click, pointerMove, altKeyOnly } from 'ol/events/condition';
import { LazyRoute, LiteralArrayExpr } from '@angular/compiler';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import { WFS, GeoJSON } from 'ol/format';
import Feature from 'ol/Feature';
import { Icon, Style, Stroke, Fill,Text } from 'ol/style';
import Point from 'ol/geom/Point';

import { WildlifeunitsbalasoreComponent } from '../wildlifeunits/wildlifeunitsbalasore/wildlifeunitsbalasore.component';
import { WildlifeunitsbamraComponent } from '../wildlifeunits/wildlifeunitsbamra/wildlifeunitsbamra.component';
import { WildlifeunitsbhadrakComponent } from '../wildlifeunits/wildlifeunitsbhadrak/wildlifeunitsbhadrak.component';
import { WildlifeunitschandakaComponent } from '../wildlifeunits/wildlifeunitschandaka/wildlifeunitschandaka.component';
import { WildlifeunitschilikaComponent } from '../wildlifeunits/wildlifeunitschilika/wildlifeunitschilika.component';
import { WildlifeunitshirakudComponent } from '../wildlifeunits/wildlifeunitshirakud/wildlifeunitshirakud.component';
import { WildlifeunitsKeonjharComponent } from '../wildlifeunits/wildlifeunits-keonjhar/wildlifeunits-keonjhar.component';
import { WildlifeunitsMahanadiComponent } from '../wildlifeunits/wildlifeunits-mahanadi/wildlifeunits-mahanadi.component';
import { WildlifeunitsNandankananComponent } from '../wildlifeunits/wildlifeunits-nandankanan/wildlifeunits-nandankanan.component';
import { WildlifeunitsPuriComponent } from '../wildlifeunits/wildlifeunits-puri/wildlifeunits-puri.component';
import { WildlifeunitsMangroveComponent } from '../wildlifeunits/wildlifeunits-mangrove/wildlifeunits-mangrove.component';
import { WildlifeunitsSatkosiaComponent } from '../wildlifeunits/wildlifeunits-satkosia/wildlifeunits-satkosia.component';
import {WildlifeunitsSimilipalSouthComponent } from '../wildlifeunits/wildlifeunits-similipal-south/wildlifeunits-similipal-south.component';
import { WildlifeunitsSimilipalNorthComponent } from '../wildlifeunits/wildlifeunits-similipal-north/wildlifeunits-similipal-north.component';
import { WildlifeunitsSimilipalTigerReserveComponent } from '../wildlifeunits/wildlifeunits-similipal-tiger-reserve/wildlifeunits-similipal-tiger-reserve.component';
import { WildlifeunitsSunabedaWLComponent } from '../wildlifeunits/wildlifeunits-sunabeda-wl/wildlifeunits-sunabeda-wl.component';
import { WildlifeunitsBaligudaFDComponent } from '../wildlifeunits/wildlifeunits-baliguda-fd/wildlifeunits-baliguda-fd.component';
import { WildlifeunitsDhenkanalFDComponent } from '../wildlifeunits/wildlifeunits-dhenkanal-fd/wildlifeunits-dhenkanal-fd.component';
import { WildlifeunitsKalahandiSouthFDComponent } from '../wildlifeunits/wildlifeunits-kalahandi-south-fd/wildlifeunits-kalahandi-south-fd.component';
import { WildlifeunitsParlakhemundiFDComponent } from '../wildlifeunits/wildlifeunits-parlakhemundi-fd/wildlifeunits-parlakhemundi-fd.component';

import { ProtectedareasBadramaComponent } from '../protectedareas/protectedareas-badrama/protectedareas-badrama.component';
import { ProtectedareasBaisipalliComponent } from '../protectedareas/protectedareas-baisipalli/protectedareas-baisipalli.component';
import { ProtectedareasBalukhandKonarkComponent } from '../protectedareas/protectedareas-balukhand-konark/protectedareas-balukhand-konark.component';
import { ProtectedareasBhitarakanikaComponent } from '../protectedareas/protectedareas-bhitarakanika/protectedareas-bhitarakanika.component';
import { ProtectedareasChandakaDampadaComponent } from '../protectedareas/protectedareas-chandaka-dampada/protectedareas-chandaka-dampada.component';
import { ProtectedareasChilikaNalabanComponent } from '../protectedareas/protectedareas-chilika-nalaban/protectedareas-chilika-nalaban.component';
import { ProtectedareasDebrigarhComponent } from '../protectedareas/protectedareas-debrigarh/protectedareas-debrigarh.component';
import { ProtectedareasGahirmathaMarineComponent } from '../protectedareas/protectedareas-gahirmatha-marine/protectedareas-gahirmatha-marine.component';
import { ProtectedareasHadgarhComponent } from '../protectedareas/protectedareas-hadgarh/protectedareas-hadgarh.component';
import { ProtectedareasKarlapatComponent } from '../protectedareas/protectedareas-karlapat/protectedareas-karlapat.component';
import { ProtectedareasKapilashComponent } from '../protectedareas/protectedareas-kapilash/protectedareas-kapilash.component';
import { ProtectedareasKhalasuniComponent } from '../protectedareas/protectedareas-khalasuni/protectedareas-khalasuni.component';
import { ProtectedareasKuldihaComponent } from '../protectedareas/protectedareas-kuldiha/protectedareas-kuldiha.component';
import { ProtectedareasKotgarhComponent } from '../protectedareas/protectedareas-kotgarh/protectedareas-kotgarh.component';
import { ProtectedareasLahkeriValleyComponent } from '../protectedareas/protectedareas-lahkeri-valley/protectedareas-lahkeri-valley.component';
import { ProtectedareasNandankananComponent } from '../protectedareas/protectedareas-nandankanan/protectedareas-nandankanan.component';
import { ProtectedareasSatkosiaGorgeComponent } from '../protectedareas/protectedareas-satkosia-gorge/protectedareas-satkosia-gorge.component';
import { ProtectedareasSimilipalComponent } from '../protectedareas/protectedareas-similipal/protectedareas-similipal.component';
import { ProtectedareasSunabedaComponent } from '../protectedareas/protectedareas-sunabeda/protectedareas-sunabeda.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { NotificationService } from '../service/notification.service';

declare var jQuery: any;

@Component({
  selector: 'app-dashboarduser',
  templateUrl: './dashboarduser.component.html',
  styleUrls: ['./dashboarduser.component.css']
})
export class DashboarduserComponent implements OnInit, AfterViewInit {
  public loader: boolean = false;
  public divisionSelected = 'All';
  public divid: any;
  public PAid: any;
  public divisionlist: any[];
  public PAlist: any[];
  public coordinateslist: any[];
  public code: any;
  public divlayerextends: any;
  public divisionnamefromlayer: any;
  public GOverlay: any;
  public showpdfdownloads: boolean = false;
  public showprotectedarea: boolean = false;

  // Protected Areas
  _protectedAreaKuldhia: layer.Image;
  _protectedAreaChandaka: layer.Image;
  _protectedAreaChilika: layer.Image;
  _protectedAreaKapilash: layer.Image;
  _protectedAreaHadgarh: layer.Image;
  _protectedAreaBaisipali: layer.Image;
  _protectedAreaBalukhandaKonark: layer.Image;
  _protectedAreaSatakosia: layer.Image;
  _protectedAreaSunabeda: layer.Image;
  _protectedAreaBadramaKhalasuni: layer.Image;
  _protectedAreaDebrigarh: layer.Image;
  _protectedAreaGahiramathaBhitarakanika: layer.Image;
  _protectedAreaKarlapat: layer.Image;
  _protectedAreaLahkeri: layer.Image;
  _protectedAreaKotagarh: layer.Image;
  _protectedAreaSimilipal: layer.Image;
  _protectedAreaNandaankanan: layer.Image;



  @ViewChild(WildlifeunitsbalasoreComponent) _balesoreEleRef: WildlifeunitsbalasoreComponent;
  @ViewChild(WildlifeunitsBaligudaFDComponent) _baligudaEleRef: WildlifeunitsBaligudaFDComponent;
  @ViewChild(WildlifeunitsbamraComponent) _bamaraEleRef: WildlifeunitsbamraComponent;
  @ViewChild(WildlifeunitsbhadrakComponent) _bhadrakEleRef: WildlifeunitsbhadrakComponent;
  @ViewChild(WildlifeunitschandakaComponent) _chandakaEleRef: WildlifeunitschandakaComponent;
  @ViewChild(WildlifeunitschilikaComponent) _chilikaEleRef: WildlifeunitschilikaComponent;
  @ViewChild(WildlifeunitsDhenkanalFDComponent) _dehenkanalEleRef: WildlifeunitsDhenkanalFDComponent;
  @ViewChild(WildlifeunitshirakudComponent) _hirakudEleRef: WildlifeunitshirakudComponent;
  @ViewChild(WildlifeunitsKalahandiSouthFDComponent) _kalahandiSEleRef: WildlifeunitsKalahandiSouthFDComponent;
  @ViewChild(WildlifeunitsKeonjharComponent) _keonjharEleRef: WildlifeunitsKeonjharComponent;
  @ViewChild(WildlifeunitsMahanadiComponent) _mahanadiEleRef: WildlifeunitsMahanadiComponent;
  @ViewChild(WildlifeunitsParlakhemundiFDComponent) _paralakhemundiEleRef: WildlifeunitsParlakhemundiFDComponent;
  @ViewChild(WildlifeunitsPuriComponent) _puriEleRef: WildlifeunitsPuriComponent;
  @ViewChild(WildlifeunitsSatkosiaComponent) _satakosiaEleRef: WildlifeunitsSatkosiaComponent;
  @ViewChild(WildlifeunitsSunabedaWLComponent) _sunabedaEleRef: WildlifeunitsSunabedaWLComponent;
  @ViewChild(WildlifeunitsNandankananComponent) _nandankananEleRef: WildlifeunitsNandankananComponent;
  @ViewChild(WildlifeunitsSimilipalSouthComponent) _similipalSouthEleRef: WildlifeunitsSimilipalSouthComponent;
  @ViewChild(WildlifeunitsSimilipalNorthComponent) _similipalNorthEleRef: WildlifeunitsSimilipalNorthComponent;
  @ViewChild(WildlifeunitsSimilipalTigerReserveComponent) _similipaltigerreseveEleRef: WildlifeunitsSimilipalTigerReserveComponent;
  @ViewChild(WildlifeunitsMangroveComponent) _mangroveEleRef: WildlifeunitsMangroveComponent;

  @ViewChild(ProtectedareasBadramaComponent) _paBadramaEleRef: ProtectedareasBadramaComponent;
  @ViewChild(ProtectedareasBaisipalliComponent) _paBaisipalliEleRef: ProtectedareasBaisipalliComponent;
  @ViewChild(ProtectedareasBalukhandKonarkComponent) _paBalukhandakonarkEleRef: ProtectedareasBalukhandKonarkComponent;
  @ViewChild(ProtectedareasBhitarakanikaComponent) _paBhitarakanikaEleRef: ProtectedareasBhitarakanikaComponent;
  @ViewChild(ProtectedareasChandakaDampadaComponent) _paChandakaEleRef: ProtectedareasChandakaDampadaComponent;
  @ViewChild(ProtectedareasChilikaNalabanComponent) _paBChilikaEleRef: ProtectedareasChilikaNalabanComponent;
  @ViewChild(ProtectedareasDebrigarhComponent) _paDebrigarhEleRef: ProtectedareasDebrigarhComponent;
  @ViewChild(ProtectedareasGahirmathaMarineComponent) _paGahiramathaEleRef: ProtectedareasGahirmathaMarineComponent;
  @ViewChild(ProtectedareasHadgarhComponent) _paHadgarhEleRef: ProtectedareasHadgarhComponent;
  @ViewChild(ProtectedareasKarlapatComponent) _paKarlapatEleRef: ProtectedareasKarlapatComponent;
  @ViewChild(ProtectedareasKapilashComponent) _paKapilashEleRef: ProtectedareasKapilashComponent;
  @ViewChild(ProtectedareasKhalasuniComponent) _paKhalasuniEleRef: ProtectedareasKhalasuniComponent;
  @ViewChild(ProtectedareasKuldihaComponent) _paKuldhiaEleRef: ProtectedareasKuldihaComponent;
  @ViewChild(ProtectedareasKotgarhComponent) _paKotagarhEleRef: ProtectedareasKotgarhComponent;
  @ViewChild(ProtectedareasLahkeriValleyComponent) _paLahkeriEleRef: ProtectedareasLahkeriValleyComponent;
  @ViewChild(ProtectedareasNandankananComponent) _paNandankananEleRef: ProtectedareasNandankananComponent;
  @ViewChild(ProtectedareasSatkosiaGorgeComponent) _paSatakosiaEleRef: ProtectedareasSatkosiaGorgeComponent;
  @ViewChild(ProtectedareasSimilipalComponent) _paSimilipalEleRef: ProtectedareasSimilipalComponent;
  @ViewChild(ProtectedareasSunabedaComponent) _paSunabedaEleRef: ProtectedareasSunabedaComponent;

  // sidebar

  @ViewChild(SidebarComponent) _sidebarEleRef: SidebarComponent;
  // Variable for Protected Area
  theunique: Map;
  vectorparcelextendglobal: any;
  private _CircleBoundary: any;
  sanctuaryList: any;
  global_division: any;
  global_pa: any;
  selectedSanctuaryName: any;



  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private notifyService: NotificationService, private authService: AuthService, private ref: ChangeDetectorRef) { }


  ngOnInit(): void {
    this.loader = true;//set loader for visible
    this.getAllDivision();
    this.getAllPA();
    this.divid = "0";
    this.PAid = "0";
    this.code = "1";
    this.firstMap();
    var self = this;
    $(".tab-slider--body").hide();
    $(".tab-slider--body:first").show();
    (function ($) {
      $("document").ready(function () {
      });
      $(".tab-slider--nav li").click(function () {
        $(".tab-slider--body").hide();
        var activeTab = $(this).attr("rel");
        $("#" + activeTab).fadeIn();
        if ($(this).attr("rel") == "referenceArea") {
          $('.tab-slider--tabs').addClass('slide');
        } else {
          $('.tab-slider--tabs').removeClass('slide');
        }
        $(".tab-slider--nav li").removeClass("active");
        $(this).addClass("active");
      });

      $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });
    })(jQuery);
    this.loader = false;
  }
  ngAfterViewInit() {
    this._sidebarEleRef.contains = '1';
  }
  firstMap() {
    var imegary = new Tile({
      visible: false,
      source: new OSM({
        url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
      })
    });
    var streerMap = new Tile({
      visible: true,
      source: new OSM({
        url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      })
    });
    var _StateBoundary = new layer.Image({
      source: new ImageWMS({
        url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wms',
        params: {
          'FORMAT': 'image/png',
          'VERSION': '1.1.0',
          STYLES: '',
          LAYERS: 'iwlms:odisha_state_boundary'
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
      }),
      visible: true
    });

    // Division Boundary Layers Intialisation to local variable
    //-------------------------------------- Division Layers Finished -------------------------------------------

    // Protected Area Layer intialisation to Local Variable

    // -------------------------------------- PROTECTED AREA FINISHED ------------------------------------

    var container = document.getElementById('popup');
    var content1 = document.getElementById('popup-content');
    let closer1 = document.getElementById('popup-closer');
    const overlay = new Overlay({
      element: container,
      autoPan: false,
      autoPanAnimation:
      {
        duration: 250,
      },
      stopEvent: false
    });
    this.GOverlay = overlay;
    var maplocal = new Map({
      layers: [
        imegary, streerMap, _StateBoundary
      ],
      controls: [
        new control.Zoom(),
        new control.ZoomSlider(),
        new control.ScaleLine
          ({
            units: 'metric',
            bar: true,
            steps: 4,
            text: true,
            minWidth: 140
          })
      ],
      overlays: [overlay],
      target: 'theunique',
      view:
        new View({
          center: proj.fromLonLat([85.190055, 20.886785]),
          zoom: 7,
          maxZoom: 23,
          minZoom: 5,
        })
    });
    this.theunique = maplocal;
    var ds = this;

    maplocal.on('singleclick', function (evt) {
      //div
      if (ds.code == '1') {
        ds.showprotectedarea = false;
        ds.showpdfdownloads = true;
      }
      //// pa
      // else if (ds.code == '0') {
      //   ds.showprotectedarea = true;
      //   ds.showpdfdownloads = false;
      //   if (ds.PAid == '1' || ds.PAid == '12') {
      //     var url = _BadramaKhalasuniPALayer.getSource().getFeatureInfoUrl(
      //       evt.coordinate, maplocal.getView().getResolution(),
      //       maplocal.getView().getProjection(),
      //       { 'INFO_FORMAT': 'application/json' });
      //   }
      //   else if (ds.PAid == '2') {
      //     var url = _baisipaliProtectedArea.getSource().getFeatureInfoUrl(
      //       evt.coordinate, maplocal.getView().getResolution(),
      //       maplocal.getView().getProjection(),
      //       { 'INFO_FORMAT': 'application/json' });
      //   }
      //   else if (ds.PAid == '3') {
      //     var url = _balukhandaKonarkProtectedAreaLayer.getSource().getFeatureInfoUrl(
      //       evt.coordinate, maplocal.getView().getResolution(),
      //       maplocal.getView().getProjection(),
      //       { 'INFO_FORMAT': 'application/json' });
      //   }
      //   else if (ds.PAid == '4' || ds.PAid == '8') {
      //     var url = MangroveProtectedAreaLayer.getSource().getFeatureInfoUrl(
      //       evt.coordinate, maplocal.getView().getResolution(),
      //       maplocal.getView().getProjection(),
      //       { 'INFO_FORMAT': 'application/json' });
      //   }
      //   else if (ds.PAid == '5') {
      //     var url = _ChandakaProtectedAreaLayer.getSource().getFeatureInfoUrl(
      //       evt.coordinate, maplocal.getView().getResolution(),
      //       maplocal.getView().getProjection(),
      //       { 'INFO_FORMAT': 'application/json' });
      //   }
      //   else if (ds.PAid == '6') {
      //     var url = _ChilikaProtectedAreaLayer.getSource().getFeatureInfoUrl(
      //       evt.coordinate, maplocal.getView().getResolution(),
      //       maplocal.getView().getProjection(),
      //       { 'INFO_FORMAT': 'application/json' });
      //   }
      //   else if (ds.PAid == '7') {
      //     var url = DebrigarhProtectedAreaLayer.getSource().getFeatureInfoUrl(
      //       evt.coordinate, maplocal.getView().getResolution(),
      //       maplocal.getView().getProjection(),
      //       { 'INFO_FORMAT': 'application/json' });
      //   }
      //   else if (ds.PAid == '9') {
      //     var url = _hadGarhProtectedArea.getSource().getFeatureInfoUrl(
      //       evt.coordinate, maplocal.getView().getResolution(),
      //       maplocal.getView().getProjection(),
      //       { 'INFO_FORMAT': 'application/json' });
      //   }
      //   else if (ds.PAid == '10') {
      //     var url = karlapatPALayer.getSource().getFeatureInfoUrl(
      //       evt.coordinate, maplocal.getView().getResolution(),
      //       maplocal.getView().getProjection(),
      //       { 'INFO_FORMAT': 'application/json' });
      //   }
      //   else if (ds.PAid == '11') {
      //     var url = _kapilashProtectedAreaLayer.getSource().getFeatureInfoUrl(
      //       evt.coordinate, maplocal.getView().getResolution(),
      //       maplocal.getView().getProjection(),
      //       { 'INFO_FORMAT': 'application/json' });
      //   }
      //   else if (ds.PAid == '13') {
      //     var url = _kuldhiaProtectedArea.getSource().getFeatureInfoUrl(
      //       evt.coordinate, maplocal.getView().getResolution(),
      //       maplocal.getView().getProjection(),
      //       { 'INFO_FORMAT': 'application/json' });
      //   }
      //   else if (ds.PAid == '14') {
      //     var url = KotagarhPALayer.getSource().getFeatureInfoUrl(
      //       evt.coordinate, maplocal.getView().getResolution(),
      //       maplocal.getView().getProjection(),
      //       { 'INFO_FORMAT': 'application/json' });
      //   }
      //   else if (ds.PAid == '15') {
      //     var url = LahkeriPALayer.getSource().getFeatureInfoUrl(
      //       evt.coordinate, maplocal.getView().getResolution(),
      //       maplocal.getView().getProjection(),
      //       { 'INFO_FORMAT': 'application/json' });
      //   }
      //   else if (ds.PAid == '16') {
      //     //Nandankanan to added here
      //     // var url= ________.getSource().getFeatureInfoUrl(
      //     //   evt.coordinate, maplocal.getView().getResolution(),
      //     //   maplocal.getView().getProjection(),
      //     //   { 'INFO_FORMAT': 'application/json' });
      //   }
      //   else if (ds.PAid == '17') {
      //     var url = _SatakosiaProtectedAreaLayer.getSource().getFeatureInfoUrl(
      //       evt.coordinate, maplocal.getView().getResolution(),
      //       maplocal.getView().getProjection(),
      //       { 'INFO_FORMAT': 'application/json' });
      //   }
      //   else if (ds.PAid == '18') {
      //     var url = SimilipalPAArea.getSource().getFeatureInfoUrl(
      //       evt.coordinate, maplocal.getView().getResolution(),
      //       maplocal.getView().getProjection(),
      //       { 'INFO_FORMAT': 'application/json' });
      //   }
      //   else if (ds.PAid == '19') {
      //     var url = _SunabedaProtectedAreaLayer.getSource().getFeatureInfoUrl(
      //       evt.coordinate, maplocal.getView().getResolution(),
      //       maplocal.getView().getProjection(),
      //       { 'INFO_FORMAT': 'application/json' });
      //   }
      // }
      // if (url) {

      //   fetch(url)
      //     .then(function (response) {
      //       ds.divisionnamefromlayer = "";

      //       return response.json();
      //     })
      //     .then(function (html) {
      //       var fetchfeatures = html.features[0];
      //       //console.error(fetchfeatures);
      //       var fetchproperty = fetchfeatures["properties"];
      //       // console.error(fetchproperty);
      //       if (ds.code == "0") {
      //         ds.divisionnamefromlayer = fetchproperty.sanc_name;
      //       }
      //       else if (ds.code == "1") {
      //         ds.divisionnamefromlayer = fetchproperty.divn_name;
      //       }

      //       ds.GOverlay.setPosition(evt.coordinate);
      //     });
      // }
    });
    closer1.onclick = function () {
      overlay.setPosition(undefined);
      //content1.innerHTML = '';
    };
  }

  downloadthemap(filename) {
    var url = 'assets/divisiongeopdf/' + filename + ".pdf";
    //alert(url)
    window.open(url, '_blank');
  }
  getAllDivision() {
    return this.apiService.request('GET', 'api/v1/masters/getAllDivisionByType').subscribe((data: any) => {
      if (data) {
        this.divisionlist = data;
      }
    });
  }
  getAllPA() {
    return this.apiService.request('GET', 'api/v1/masters/getAllSanctuary').subscribe((data: any) => {
      if (data) {
        this.sanctuaryList = data;
      }
    });
  }
  onDivChangeloadWLDashboard(value: string) {
    this.divid = value;
    this.PAid = "0";
    this.code = "1";
   if(this.divid=="0"){
      this.resetmap();
   }
   else{
    this.extendtofit("Division");
    this.ref.detectChanges();
    this.setInfoonly();
   }
  }
  onDivChangeloadPADashboard(event: Event) {

    let selectElementText = event.target['options'][event.target['options'].selectedIndex].text;
    this.selectedSanctuaryName=selectElementText;
    this.divid = "0";
    this.PAid = event.target['value']
    this.code = "0";
    if(this.PAid=="0"){
      this.resetmap();
    }
    else{
      this.extendtofit("ProtectedArea");
      this.ref.detectChanges();
      this.setInfoonly();
    }
  }
  setInfoonly(){
   if (this.code == "0") {
     if (this.PAid == "6") {
        this._paBadramaEleRef.setinfoonly();
      }
      else if (this.PAid == "8") {
        this._paBaisipalliEleRef.setinfoonly();
      }
      else if (this.PAid == "1") {
        this._paBalukhandakonarkEleRef.setinfoonly();
      }
      else if (this.PAid == "17") {
        this._paBhitarakanikaEleRef.setinfoonly();
      }
      else if (this.PAid == "10") {
        this._paChandakaEleRef.setinfoonly();
      }
      else if (this.PAid == "16") {
        this._paBChilikaEleRef.setinfoonly();
      }
      else if (this.PAid == "2") {
        this._paDebrigarhEleRef.setinfoonly();
      }
      else if (this.PAid == "18") {
        this._paGahiramathaEleRef.setinfoonly();
      }
      else if (this.PAid == "15") {
        this._paHadgarhEleRef.setinfoonly();
      }
      else if (this.PAid == "7") {
        this._paKarlapatEleRef.setinfoonly();
      }
      else if (this.PAid == "14") {
        this._paKapilashEleRef.setinfoonly();
      }
      else if (this.PAid == "5") {
        this._paKhalasuniEleRef.setinfoonly();
      }
      else if (this.PAid == "19") {
        this._paKuldhiaEleRef.setinfoonly();
      }
      else if (this.PAid == "4") {
        this._paKotagarhEleRef.setinfoonly();
      }
      else if (this.PAid == "13") {
        this._paLahkeriEleRef.setinfoonly();
      }
      else if (this.PAid == "11") {
        this._paNandankananEleRef.setinfoonly();
      }
      else if (this.PAid == "9") {
        this._paSatakosiaEleRef.setinfoonly();
      }
      else if (this.PAid == "12") {
        this._paSimilipalEleRef.setinfoonly();
      }
      else if (this.PAid == "3") {
        this._paSunabedaEleRef.setinfoonly();
      }

     }
   else if (this.code == "1") {
      if (this.divid == "4") {
        this._balesoreEleRef.setinfoonly();
      }
      else if (this.divid == "5") {
        this._baligudaEleRef.setinfoonly();
      }
      else if (this.divid == "6") {
        this._bamaraEleRef.setinfoonly();
      }
      else if (this.divid == "10") {
        this._bhadrakEleRef.setinfoonly();
      }
      else if (this.divid == "14") {
        this._chandakaEleRef.setinfoonly();
      }
      else if (this.divid == "15") {
        this._chilikaEleRef.setinfoonly();
      }
      else if (this.divid == "19") {
        this._dehenkanalEleRef.setinfoonly();
      }
      else if (this.divid == "22") {
        this._hirakudEleRef.setinfoonly();
      }
      else if (this.divid == "26") {
        this._kalahandiSEleRef.setinfoonly();
      }
      else if (this.divid == "29") {
        this._keonjharEleRef.setinfoonly();
      }
      else if (this.divid == "33") {
        this._mahanadiEleRef.setinfoonly();
      }
      else if (this.divid == "38") {
        this._paralakhemundiEleRef.setinfoonly();
      }
      else if (this.divid == "40") {
        this._puriEleRef.setinfoonly();
      }
      else if (this.divid == "46") {
        this._satakosiaEleRef.setinfoonly();
      }
      else if (this.divid == "49") {
        this._sunabedaEleRef.setinfoonly();
      }
      else if (this.divid == "35") {
        this._mangroveEleRef.setinfoonly();
      }
      else if (this.divid == "57") {
        this._similipalSouthEleRef.setinfoonly();
      } else if (this.divid == "58") {
        this._similipalNorthEleRef.setinfoonly();
      }
    }
  }
  extendtofit(layertype) {
    const layername_div = 'https://odishaforestgis.in/ofms/index.php/webservice_api/getDivisionGeojson';
    var querydiv;

    if (layertype == "Division") {
      if (this.divid != "0") {
        querydiv = "{\"division_id\" : \"" + this.divid + "\"}";
      }
      if (querydiv != "") {
        let formdata: FormData = new FormData();
        formdata.append('data', querydiv);
        try {
          this.apiService.requestGeoJson(layername_div, formdata).subscribe((response: any[]) => {
            if (response) {
              if (response["status"] == 1) {
                if (this.global_division) {
                  this.theunique.removeLayer(this.global_division);
                }
                if (this.global_pa) {
                  this.theunique.removeLayer(this.global_pa);
                }
                const styleFunction =
                new Style({
                  stroke: new Stroke({
                    color: 'rgba(31, 28, 28, 2)',
                    width: 3,
                    lineDash: [.1, 5] //or other combinations
                  }),
                  zIndex: 2,
                  fill: new Fill({
                    color: 'rgba(32, 28, 28, 0.1)',
                  }),
                  text: new Text({
                    font: 'bold 17px Calibri,sans-serif',
                    fill: new Fill({
                      color: '#eb7734'
                    })
                  })
                });
                const vectorSource = new VectorSource();
                var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
                var gjFormat = new GeoJSON({
                  featureProjection: 'EPSG:3857',
                });
                var features = gjFormat.readFeatures(geojson);
                vectorSource.clear();
                var featureOverlayNew = new VectorLayer({
                 // map: this.map,
                  source: vectorSource,
                  style: function () {
                    styleFunction.getText().setText(response["post"][0].name_e + " Division");
                    return styleFunction;
                  },
                  updateWhileAnimating: true,
                  updateWhileInteracting: true
                });

                featureOverlayNew.getSource().addFeatures(features);
                this.theunique.addLayer(featureOverlayNew);
                this.theunique.getView().setZoom(10);
                this.theunique.getView().fit(featureOverlayNew.getSource().getExtent(), "");
                this.global_division = featureOverlayNew;
                this.loader=false;
              } else {
                this.resetmap();
                this.notifyService.showError("Map Data Not Available", "Error");
                this.loader = false
              }
            } else {
              this.resetmap();
              this.notifyService.showError("Map Data Not Available", "Error");
              this.loader = false
            }
          }, (error) => {
            this.resetmap();
            const errors = error;
            this.loader = false;
          });
        } catch (e) {
          this.resetmap();
          this.notifyService.showError("Something Error!!", "Error");
          this.loader = false
        } finally {
          this.loader = false
        }
      }
    }
    else if (layertype == "ProtectedArea") {
      console.log("ProtectedArea");
      let PAlayername='https://odisha4kgeo.in/index.php/mapview/getSanctuaryGeom';
      var sanctuaryName="";
      if (this.PAid != "")
      {

        let formdata2:FormData = new FormData();
        formdata2.append('gis_id',this.PAid);
        if(this.selectedSanctuaryName){
          sanctuaryName=this.selectedSanctuaryName;
        }
        try
        {
          this.apiService.requestGeoJson(PAlayername, formdata2).subscribe((response: any[]) => {
            if (response) {
              if (this.global_division) {
                this.theunique.removeLayer(this.global_division);
              }
              if (this.global_pa) {
                this.theunique.removeLayer(this.global_pa);
              }
              if(response["status"]==1){
                var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"].sanctuary_boundary[0].geojson + '}';
                const vectorSource = new VectorSource();
                var gjFormat = new GeoJSON({
                  featureProjection: 'EPSG:3857',
                });
                var styles =
                  new Style({
                      stroke: new Stroke({
                        width: 3,
                        color: 'rgba(109, 166, 181, 1)',
                        lineDash: [.1, 5] //or other combinations
                      }),
                      zIndex: 2,
                      fill: new Fill({
                        color: 'rgba(32, 28, 28, 0.1)',
                      }),
                      text: new Text({
                        font: 'bold 14px Calibri,sans-serif',
                        fill: new Fill({
                            color: '#eb7734'
                         })
                      })
                });
                var features = gjFormat.readFeatures(geojson);
                vectorSource.clear();
                var featureOverlayNew = new VectorLayer({
                    source: vectorSource,
                    style: function() {
                      styles.getText().setText(sanctuaryName+' Sanctuary');
                      return styles;
                    },
                    updateWhileAnimating: true,
                    updateWhileInteracting: true
                });
                featureOverlayNew.getSource().addFeatures(features);
                this.theunique.addLayer(featureOverlayNew);
                this.theunique.getView().setZoom(4);
                this.theunique.getView().fit(featureOverlayNew.getSource().getExtent(), "");
                this.global_pa = featureOverlayNew;
                this.loader=false;
              }else{
                this.notifyService.showError("Map Data Not Available","Error");
                this.loader=false
              }
             }else{
              this.notifyService.showError("Map Data Not Available","Error");
                this.loader=false
             }
          },(error) => {
            const errors = error;
            this.notifyService.showError("Map Data Not Available","Error");
            this.loader=false
          });
        }catch(e){
        this.notifyService.showError("Something Error!!","Error");
        this.loader=false
        }finally{
          this.loader=false
        }
      }
    }
  }
  resetmap(){
    if (this.global_division) {
      this.theunique.removeLayer(this.global_division);
    }
    if (this.global_pa) {
      this.theunique.removeLayer(this.global_pa);
    }
  }
  checkdivisonactive() {
    if (this.divid == "0") {
      alert("Please Select Division");
      var taby = document.getElementById("tab-Y") as HTMLAnchorElement;
      taby.href = "javascript:void(0)";
    }
    else if (this.PAid == "0") {
      alert("Please Select Protected Area");
      var taby = document.getElementById("tab-Y") as HTMLAnchorElement;
      taby.href = "javascript:void(0)";

    }
    else {
      var taby = document.getElementById("tab-Y") as HTMLAnchorElement;
      taby.href = "#pane-Y";
    }
  }

  // resetmap(codecame, divisionval, paval) {
  //   this.PAid = paval != "" ? paval : "0";
  //   this.divid = divisionval != "" ? divisionval : "0";
  //   this.code = codecame;
  //   this.ref.detectChanges();
  // }

}
