<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <ul id="tabs" class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a id="tab-gahirimathaPAmap" href="#pane-gahirimathaPAmap" class="nav-link active" data-toggle="tab"
              role="tab">Map</a>
          </li>
          <li class="nav-item">
            <a id="tab-gahirimathaPAinfo" href="#pane-gahirimathaPAinfo" class="nav-link" data-toggle="tab"
              role="tab">Information</a>
          </li>
        </ul>

        <div id="accordion" class="tab-content" role="tablist">
          <div id="pane-gahirimathaPAmap" class="card tab-pane fade show active" role="tabpanel"
            aria-labelledby="tab-gahirimathaPAmap">
            <div class="card-header" role="tab" id="heading-gahirimathaPAmap">
              <h5 class="mb-0">
                <a data-toggle="collapse" href="#collapse-gahirimathaPAmap" aria-expanded="true"
                  aria-controls="collapse-gahirimathaPAmap">
                  Map
                </a>
              </h5>
            </div>
            <div id="collapse-gahirimathaPAmap" class="collapse show" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-gahirimathaPAmap">
              <div class="card-body">
                <div class="map" id="mapgahirmatha">
                  <div class="gle-icon">
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid"
                        alt="Street View" title="Street View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()'
                        alt="Satellite View" title="Satellite View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View"
                        title="Topo View">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="pane-gahirimathaPAinfo" class="card tab-pane fade" role="tabpanel"
            aria-labelledby="tab-gahirimathaPAinfo">
            <div class="card-header" role="tab" id="heading-gahirimathaPAinfo">
              <h5 class="mb-0">
                <a class="collapsed" data-toggle="collapse" href="#collapse-gahirimathaPAinfo" aria-expanded="false"
                  aria-controls="collapse-gahirimathaPAinfo">
                  Information
                </a>
              </h5>
            </div>
            <div id="collapse-gahirimathaPAinfo" class="collapse" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-gahirimathaPAinfo">

              <div class="card-body">

                <h4 class="card-title">
                  Gahirmatha Sanctuary
                  <span class="custom-down">
                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i
                        class="fa fa-file-pdf">&nbsp;</i>Information</button>
                        <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                  </span>
                </h4>
                <div class="row">
                  <div class="col-md-8">
                    <div class="table-responsive">
                      <table class="table table-sm table-bordered">
                        <tbody>
                          <tr>
                            <td><strong>Forest Division : </strong></td>
                            <td>Mangrove Forests Division (WL) Rajnagar</td>
                          </tr>
                          <tr>
                            <td><strong>District : </strong></td>
                            <td>Kendrapara</td>
                          </tr>
                          <tr>
                            <td><strong>Date of Notification : </strong></td>
                            <td>27-09-1997</td>
                          </tr>
                          <tr>
                            <td><strong>Notified Area : </strong></td>
                            <td>1435 Sq. km</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-2 video-box no-print">
                    <a href="#" class="video-btn" data-toggle="modal"
                      data-src="https://www.youtube.com/embed/71qLj6AUCBI" data-target="#myModal">
                      <h4>Gahirmatha Sanctuary</h4>
                      <div class="overlay">
                        <span class="icon">
                          <i class="fa fa-play-circle"></i>
                        </span>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-2">
                    <img src="assets/images/oliveRidley.jpg" class="img-fluid float-right img-inner" alt="image">
                  </div>
                </div>
                <p>
                  Gahirmatha is the lone mass nesting spot in Indian Ocean region and the only turtle sanctuary in
                  Odisha. The Olive Ridley turtles travel across the South Pacific to breed on the coast of
                  Gahirmatha.Million of Olive Ridley turtles visit the beach during mass nesting every year. Olive
                  Ridley turtles usually nest during night time.The sanctuary is also home to variety of flora and
                  fauna. Wild boar, barking deer, bear, leopard, crocodile, jungle fowl, samber and wild dogs are among
                  the other wildlife seen in the sanctuary.
                </p>
                <h4 class="card-title">Quick Facts</h4>
                <ul class="custom-list">
                  <li>Gahirmatha was declared a turtle sanctuary in 1997 by Odisha government after considering its
                    ecological importance and as part of efforts to save the sea turtles.</li>
                  <li>Olive Ridley turtles get their name from the coloring of their heart-shaped shell.It starts out
                    gray but changes to olive green when the turtles turn adults.</li>
                  <li>It is an ideal destination because it is the world’s largest nesting beach of Olive Ridley Sea
                    Turtles.</li>
                  <li>The government with the help of NGOs have succeeded in creating a safe habitat for these turtles
                    and transformed the area into a restricted zone to ensure complete protection for these animals.
                  </li>
                </ul>
                <h4 class="card-title">How to reach</h4>
                <p class="address-inner">
                  <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>The closest highway is NH5 running from
                  Kolkata to Chennai. While travelling by road you have to take NH5 from Bhubaneswar on to Cuttack,
                  Kendrapara and Rajnagar.
                </p>
                <p class="address-inner">
                  <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong>The nearest railway station is Bhadrak
                  (49kms).
                </p>
                <p class="address-inner">
                  <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>The nearest airport is Bhubaneswar (75kms)
                </p>
                <h4 class="card-title">Facilities available</h4>
                <ul class="custom-list">
                  <li>No facility Available.</li>
                </ul>
                <h4 class="card-title">Salient Detail</h4>
                <div class="table-responsive">
                  <table class="table table-sm table-bordered">
                    <tbody>
                      <tr>
                        <td width="15%"><strong>Geographic Extent : </strong></td>
                        <td width="85%" colspan="3">Longitude 86° 44' to 87° 15'(East) Latitude 20° 48' to 20°
                          16'(North)</td>
                      </tr>
                      <tr>
                        <td><strong>Climate : </strong></td>
                        <td colspan="3">Temperature - In summer: 40°C, In winter: 10°C</td>
                      </tr>
                      <tr>
                        <td><strong>Villages :</strong></td>
                        <td colspan="3">None</td>
                      </tr>
                      <tr>
                        <td><strong>Contact officer : </strong></td>
                        <td>
                          <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest
                          Officer<br>
                          <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Mangrove Forests
                          Division (WL), Rajnagar <br>
                          <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06729-242460 <br>
                          <span class="address-inner"><i class="fa fa-fax">&nbsp;</i></span>242460<br>
                        </td>
                        <td>
                          <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>ACF<br>
                          <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Chandbali <br>
                          <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06786-220072 <br>
                        </td>
                        <td>
                          <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>ACF<br>
                          <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Paradeep <br>
                          <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06722-220905 <br>
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Best time to visit : </strong></td>
                        <td colspan="3">October to May.</td>
                      </tr>
                      <tr>
                        <td><strong>Accommodation : </strong></td>
                        <td colspan="3">No Accommodation Available
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
                    </strong>The information contained herein are provided solely for informational purposes only.The
                    content is dynamic and may undergo change from time to time. The snapshot of the content at a
                    particular time is not to be referred as a legally binding document.</small></p>
                <div id="printcontent"></div>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
