import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from '../service/api.service';
import { NotificationService } from '../service/notification.service';
declare var jQuery: any;
declare var SimpleMDE: any;
declare var FileUploadWithPreview: any;
const baseUrlFile = environment.FILE_API_URL;
@Component({
  selector: 'app-viewcms',
  templateUrl: './viewcms.component.html',
  styleUrls: ['./viewcms.component.css']
})
export class ViewcmsComponent implements OnInit,AfterViewInit {
 // public loader: boolean=false;
  public loader2: boolean=false;
  public IsAdmin: boolean=false;
  NewContentList: any;
  NewsList: any;
  PubListArray: any[];
  galleryList: any[];
  tenderList: any[];
  galleryType: any[];
  noticelist: any[];
  divisionList:any[];
  newsEditor: any;
  newsContent:string;
  newsEditorContent: any;
  newContentEditor: any;
  newContent: any;
  newUpdateElement: any;
  updateNewsForm: any;
  updateNewContentForm: any;
  updatePublishedForm: any;
  selectedPubImgFiles: any;
  selectedPubPdfFiles: any;
  typeList: any;
  selectedGalImgFiles: any;
  galleryImgPanel: any;
  updateTenderForm: any;
  selectedTenderPdfFiles: any;
  updateNoticeForm: any;
  selectedNoticePdfFiles: any;

  @ViewChild('fd') sdate : ElementRef;


  @ViewChild('NewsUpdateEle') NewsUpdateEle: ElementRef;
  @ViewChild('NewContent') NewContent: ElementRef;
  @ViewChild('Publication') Publication: ElementRef;
  @ViewChild('Gallery') Gallery: ElementRef;
  @ViewChild('GalleryType') GalleryType: ElementRef;
  @ViewChild('Tender') Tender: ElementRef;
  @ViewChild('Notice') Notice: ElementRef;
  @ViewChild('fdNotice') noticeSdate: ElementRef;
  @ViewChild('fdNotice_valid') noticeVdate: ElementRef;
  @ViewChild('ControlRoomInfo') ControlRoomInfo: ElementRef;


  updateDivisionForm: any;
  

  constructor(private formBuilder: FormBuilder,private router: Router,private apiService: ApiService,private notifyService:NotificationService) { }
  dtTrigger: Subject<any> = new Subject<any>();
  dtTrigger2: Subject<any> = new Subject<any>();
  dtTrigger3: Subject<any> = new Subject<any>();
  dtTrigger4: Subject<any> = new Subject<any>();
  dtTrigger5: Subject<any> = new Subject<any>();
  dtTrigger6: Subject<any> = new Subject<any>();
  dtTrigger7: Subject<any> = new Subject<any>();
  dtTrigger8: Subject<any> = new Subject<any>();
  dtOptions: any = {};
  @ViewChild('modalEditNewsUpdatesClosebutton') closebuttonNews;
  @ViewChild('modalEditWhatIsNewClosebutton') closebuttonNew;
  @ViewChild('modalEditBlogClosebutton') closebuttonPublish;
  @ViewChild('modalEditTenderUpdatesClosebutton') closebuttonTender;
  @ViewChild('modalEditNOTICEUpdatesClosebutton') closebuttonNotice;
  @ViewChild('modalEditDivisionUpdatesClosebutton') closebuttonDivision;
  
  
  

  ngAfterViewInit(){

    const userdata = sessionStorage.getItem('user');
    if (userdata != null) {
       const user = JSON.parse(userdata);
       if(user.roleId==1){
        this.IsAdmin=true;
       }
      if(user.roleId==2){
        this.NewsUpdateEle.nativeElement.remove();
        this.NewContent.nativeElement.remove();
        this.Publication.nativeElement.remove();
        this.Notice.nativeElement.remove();
      }
    }
  }
  ngOnInit(): void {
    this.updateNewsForm = this.formBuilder.group({
      newsContent:  ['',[Validators.required]],
      newsid: ['',[Validators.required]]
    });
    this.updateNewContentForm = this.formBuilder.group({
      newContent:  ['',[Validators.required]],
      contentId: ['',[Validators.required]]
    });
    this.updatePublishedForm = this.formBuilder.group({
      title: ['',[Validators.required]],
      publicationThumbLineimg: [''],
      publicationPdfFile: [''],
      publishedId: ['',[Validators.required]]
    });
    this.updateTenderForm= this.formBuilder.group({
      title: ['',[Validators.required]],
      tenderNo: ['',[Validators.required]],
      fromdate: [''],
      tenderfile: [''],
      tenderId: ['',[Validators.required]]
    });
    this.updateNoticeForm= this.formBuilder.group({
      noticeId: ['',[Validators.required]],
      noticeNumber:['',[Validators.required]],
      noticeName: ['', [Validators.required]],
      noticeDate: [''],
      noticeValidDate: [''],
      noticefile: ['', [Validators.required]]
    });
    this.updateDivisionForm=this.formBuilder.group({
      divisionId: ['',[Validators.required]],
      controlRoomNumber: ['',Validators.compose([Validators.required,Validators.pattern("[0-9 ]{10}")])],
    });
    var ds=this;
  	(function($){
    $('a[data-toggle="tab"]').on('shown.bs.tab', function(e){
    $($.fn.dataTable.tables(true)).DataTable()
        .columns.adjust()
        .responsive.recalc();
    });
    	// Basic CK Editor for NEWS Content
    var newsEditor=new SimpleMDE({
      element: document.getElementById("news"),
      spellChecker: false,
    });
    ds.newsEditor=newsEditor;
    newsEditor.codemirror.on("change", function(){
      var testPlain = ds.newsEditor.value(),
      testMarkdown = ds.newsEditor.markdown(testPlain);
      ds.newsContent=testMarkdown;
    });
    $('#modalEditNewsUpdates').on('shown.bs.modal', function () {
       ds.newsEditor.value(ds.newsEditorContent);
    });
    // Basic CK Editor for NEW Update
		var newContentEditor=new SimpleMDE({
			element: document.getElementById("newContent"),
			spellChecker: false,
		});
    ds.newContentEditor=newContentEditor;
    newContentEditor.codemirror.on("change", function(){
      var testPlain = ds.newContentEditor.value(),
      testMarkdown = ds.newContentEditor.markdown(testPlain);
      ds.newContent=testMarkdown;
    });
    $('#modalEditWhatIsNew').on('shown.bs.modal', function () {
       ds.newContentEditor.value(ds.newUpdateElement);
    });

		// Single File Upload
    ds.galleryImgPanel = new FileUploadWithPreview('myFirstImage')

		$("#datepicker").datepicker({
        	autoclose: true,
        	todayHighlight: true
	  	}).datepicker('update', new Date());
      $("#datepicker1").datepicker({
        autoclose: true,
        todayHighlight: true
    }).datepicker('update', new Date());
    $("#datepicker2").datepicker({
      autoclose: true,
      todayHighlight: true
  }).datepicker('update', new Date());


    })(jQuery);
    this.dtOptions = {
      // pagingType: 'full_numbers',
      // pageLength: 5,
      // lengthMenu : [5, 10, 25],
      // processing: true,
      // responsive: true
    };
    this.loadnews();
    this.loadnewContent();
    this.loadPublication();
    this.loadGallery();
    this.getActiveGalleryTypeList();
    this.loadGalleryType();
    this.loadTender();
    this.loadNotice();
    this.getDivisionInfoList();
  }
  // NEWS
  loadnews(){
    try{
     //this.loader=true;
      this.apiService.request('GET', 'api/v1/cms/getNews').subscribe((data: any) => {
        if (data) {
          data.sort((a, b) => {
            return <any>new Date(b.updatedOn) - <any>new Date(a.updatedOn);
          });
          this.NewsList=data;
          this.dtTrigger.next();
          //this.loader=false;
        }
      });
    }
    catch(e){
      //this.loader=false;
    }
  }
  getNewsRecord(element:any){
      this.newsEditorContent=element.content;
       this.updateNewsForm.get('newsid').setValue(element.id);
  }
  updateNewsRecord(){
    try{
     //this.loader=true;
      const userdata=sessionStorage.getItem('user');
      if(userdata!=null){
        const user=JSON.parse(userdata);

            if(this.updateNewsForm.value.newsid!=null && this.newsContent!="")
            {
             this.apiService.request('PUT', 'api/v1/cms/updateNewsById?id='+this.updateNewsForm.value.newsid,{
                userid:user.userid,
                content: this.newsContent
             }).subscribe(
            (res: any) => {
              //console.log(res);
              var response = JSON.parse(res);
                if(response.message=="success"){
                  this.closebuttonNews.nativeElement.click();
                  this.notifyService.showSuccesswithTimeout("News Record Update Successfully","Success");
                  $("#tableNewsUpdates").DataTable().destroy();
                  this.loadnews();
                  //this.loader=false;
                }else if(response.message=="fail"){
                  this.notifyService.showError("UnSuccessful","Error");
                  //this.loader=false;
                }
            },err => {
              //this.loader=false;
              });
            }else{
              this.notifyService.showError("Required Fields","Error");
              //this.loader=false;
            }
          }else{
          this.router.navigate(['']);
            return;
        }
    }catch{
      //this.loader=false;
    }
  }
  deleteNews(element:any){
    //console.log(element)
     var flag=confirm('Are you sure!!!!');
     if(flag){
        const userdata=sessionStorage.getItem('user');
            if(userdata!=null)
             {
              const user=JSON.parse(userdata);
                  this.apiService.request('PUT', 'api/v1/cms/deleteNewsById?userId='+user.userid+'&newsId='+element.id).subscribe((res: any) => {
                    var response = JSON.parse(res);
                    if(response.message=="success"){
                        this.notifyService.showSuccesswithTimeout("Deleted Successfully","Success");
                          $("#tableNewsUpdates").DataTable().destroy();
                          this.loadnews();
                          //this.loader=false;
                        }else{
                          this.notifyService.showError(" UnSuccessfull","Error");
                          //this.loader=false;
                        }
                      });
              } else{
                this.router.navigate(['']);
                  return;
              }
      }else{
        // $("#tableNewsUpdates").DataTable().destroy();
        // this.loadnews();
        this.notifyService.showError("You clicked the Cancel button! ","");
        //this.loader=false;
        }
  }
  changeNewsActiveStatus(newsId:any,status:boolean){
    //console.log(status);
    var flag=confirm('Are you sure!!!!');
   if(flag){
    const userdata=sessionStorage.getItem('user');
     if(userdata!=null)
       {
         const user=JSON.parse(userdata);
         this.apiService.request('PUT', 'api/v1/cms/changeNewsActiveStatus?userId='+user.userid+'&newsId='+newsId+'&isActive='+status).subscribe((message:any) => {
            if(message){
              //console.log(message)
              if(message=="success"){
                if(status==true){
                  this.notifyService.showSuccess("Deactivate Successfull","Success");
                }else{
                  this.notifyService.showSuccess("Activate Successfull","Success");
                }
                $("#tableNewsUpdates").DataTable().destroy();
                this.loadnews();
                //this.loader=false;

              }else{
                this.notifyService.showError("UnSuccessfull","Error");
                $("#tableNewsUpdates").DataTable().destroy();
                this.loadnews();
                //this.loader=false;
              }
             }
           });
      }else{
        this.router.navigate(['']);
          return;
      }
   }else{
    this.notifyService.showError("You clicked the Cancel button! ","Error");
    //this.loader=false;
   }

  }

  //New Content
  loadnewContent(){
   //this.loader=true;
    this.apiService.request('GET', 'api/v1/cms/getAllNewContent').subscribe((data: any) => {
      if (data) {
        data.sort((a, b) => {
          return <any>new Date(b.updatedOn) - <any>new Date(a.updatedOn);
        });
        this.NewContentList=data;
       // //console.log(this.NewContentList)
        this.dtTrigger2.next();
        //this.loader=false;
      }
    });
  }
  getNewContent(element:any){
    this.newUpdateElement=element.content;
    this.updateNewContentForm.get('contentId').setValue(element.id);
  }
  updateNewContent(){
    try{
     //this.loader=true;
      const userdata=sessionStorage.getItem('user');
      if(userdata!=null){
        const user=JSON.parse(userdata);
            if(this.updateNewContentForm.value.contentId!=null && this.newContent!=""){
             this.apiService.request('PUT', 'api/v1/cms/updateNewContentById?id='+this.updateNewContentForm.value.contentId,{
              userId:user.userid,
              content: this.newContent
             }).subscribe(
            (res: any) => {
              //console.log(res);
              var response = JSON.parse(res);
                if(response.message=="success"){
                  this.closebuttonNew.nativeElement.click();
                  $("#tableWhatIsNew").DataTable().destroy();
                  this.loadnewContent();
                  //this.loader=false;
                  this.notifyService.showSuccesswithTimeout("New Content Update Successfully","Success");
                }else if(response.message=="fail"){
                  this.closebuttonNew.nativeElement.click();
                  this.notifyService.showError("UnSuccessful","Error");
                  //this.loader=false;
                }
            },err => {
              this.notifyService.showError("UnSuccessful","Error");
              //this.loader=false;
              });
          }else{
            this.notifyService.showError("Required Fields","Error");
            //this.loader=false;
          }
      }else{
          this.router.navigate(['']);
            return;
        }
    }catch{
      //this.loader=false;
    }
  }
  deleteNewContent(element:any){
    //console.log(element)
     var flag=confirm('Are you sure!!!!');
     if(flag){
        const userdata=sessionStorage.getItem('user');
            if(userdata!=null)
             {
              const user=JSON.parse(userdata);
                  this.apiService.request('PUT', 'api/v1/cms/deleteNewContentById?userId='+user.userid+'&contentId='+element.id).subscribe((res: any) => {
                    var response = JSON.parse(res);
                    if(response.message=="success"){
                        this.notifyService.showSuccesswithTimeout("Deleted Successfully","Success");
                          $("#tableWhatIsNew").DataTable().destroy();
                          this.loadnewContent();
                          //this.loader=false;
                        }else{
                          this.notifyService.showError(" UnSuccessfull","Error");
                          //this.loader=false;
                        }
                      });
              } else{
                this.router.navigate(['']);
                  return;
              }
      }else{
        // $("#tableWhatIsNew").DataTable().destroy();
        // this.loadnewContent();
        this.notifyService.showError("You clicked the Cancel button! ","");
        //this.loader=false;
        }
  }

  //Publication
  loadPublication(){
   //this.loader=true;
    this.apiService.request('GET', 'api/v1/cms/getAllPublication').subscribe((data: any) => {
      if (data) {
        this.PubListArray = new Array() ;
        data.sort(function(a, b) {
          return b.seqNo- a.seqNo;
        });
        data.sort((a, b) => {
          return <any>new Date(b.updatedOn) - <any>new Date(a.updatedOn);
        });
        data.map(item=>{
          return {
            id:item['id'],
            thumbnilImgPath : baseUrlFile+'publication/'+item['thumbnilImgPath'],
            pdfPath: baseUrlFile+'publication/'+item['pdfPath'],
            publicationTitle: item['publicationTitle'],
            craetedBy: item['craetedBy'],
            craetedOn: item['craetedOn'],
            publicationNum: item['publicationNum'],
            updatedBy: item['updatedBy'],
            updatedOn: item['updatedOn']
          }
        }).forEach(item=> this.PubListArray.push(item));
        this.dtTrigger3.next();
        //this.loader=false;
      }
    });
  }
  getPublishedRecord(element:any){
    this.updatePublishedForm.get('publishedId').setValue(element.id);
    this.updatePublishedForm.get('title').setValue(element.publicationTitle);

  }
  updatePublishedDoc(){
    try{
     //this.loader=true;
      const userdata=sessionStorage.getItem('user');
      if(userdata!=null){
        const user=JSON.parse(userdata);
            if(this.selectedPubImgFiles!=undefined || this.selectedPubPdfFiles!=undefined){
              let formdata: FormData = new FormData();
              if(this.selectedPubImgFiles!=undefined){
                formdata.append('file', this.selectedPubImgFiles);
              } if(this.selectedPubPdfFiles!=undefined){
                formdata.append('pdfFile', this.selectedPubPdfFiles);
              }
                this.apiService.request('PUT', 'api/v1/cms/updatePublicationById?userId='+user.userid+'&publicationTitle='+this.updatePublishedForm.value.title+'&type=publication&publicationid='+this.updatePublishedForm.value.publishedId, formdata).subscribe((res: any) => {
                  //console.log(res);
                  var response = JSON.parse(res);
                    if(response.message=="success"){
                      this.closebuttonPublish.nativeElement.click();
                      $("#tablePublication").DataTable().destroy();
                      this.loadPublication();
                      //this.loader=false;
                      this.notifyService.showSuccesswithTimeout("Update Successfully","Success");
                    }else if(response.message=="failed"){
                      this.closebuttonPublish.nativeElement.click();
                      this.notifyService.showError("UnSuccessful","Error");
                      //this.loader=false;
                    }
                },err => {
                  this.notifyService.showError("UnSuccessful","Error");
                  //this.loader=false;
              });
           }else  if(this.selectedPubImgFiles==undefined || this.selectedPubPdfFiles==undefined){
            this.apiService.request('PUT', 'api/v1/cms/updatePublicationByIdWithoutFile?userId='+user.userid+'&publicationTitle='+this.updatePublishedForm.value.title+'&type=publication&publicationid='+this.updatePublishedForm.value.publishedId).subscribe((res: any) => {
              //console.log(res);
              var response = JSON.parse(res);
                if(response.message=="success"){
                  this.closebuttonPublish.nativeElement.click();
                  $("#tablePublication").DataTable().destroy();
                  this.loadPublication();
                  //this.loader=false;
                  this.notifyService.showSuccesswithTimeout("Update Successfully","Success");
                }else if(response.message=="failed"){
                  this.closebuttonPublish.nativeElement.click();
                  this.notifyService.showError("UnSuccessful","Error");
                  //this.loader=false;
                }
            },err => {
              this.notifyService.showError("UnSuccessful","Error");
              //this.loader=false;
          });
           }

      }else{
          this.router.navigate(['']);
            return;
        }
    }catch{
      //this.loader=false;
    }
  }
  onSelectPubThumbnailFile(event) {
    const file = event.target.files.item(0)
    if (file.type.match('image.*')) {
      this.selectedPubImgFiles = file;
    } else {
      this.notifyService.showError("invalid format!.Only images are supported.","Error");
      this.updatePublishedForm.controls.publicationThumbLineimg.setValue("");
    }
    if (event.length === 0)
        this.selectedPubImgFiles="";
      return;
  }
  OnSelectPubPdfFile(event) {
    const file = event.target.files.item(0)
      //console.log(file)
      //console.log(event)
    if (file.type.match('pdf.*')) {
      this.selectedPubPdfFiles = file;
    } else {
      this.notifyService.showError("invalid format!.Only pdf is supported.","Error");
      this.updatePublishedForm.controls.publicationPdfFile.setValue("");
    }
    if (event.length === 0)
    this.selectedPubPdfFiles ="";
      return;
  }
  deletePublishedRecord(element:any){
    //console.log(element)
     var flag=confirm('Are you sure!!!!');
     if(flag){
        const userdata=sessionStorage.getItem('user');
            if(userdata!=null)
             {
              const user=JSON.parse(userdata);
                  this.apiService.request('PUT', 'api/v1/cms/deletePublicationById?userId='+user.userid+'&publicationId='+element.id).subscribe((res: any) => {
                    var response = JSON.parse(res);
                    if(response.message=="success"){
                        this.notifyService.showSuccesswithTimeout("Deleted Successfully","Success");
                          $("#tablePublication").DataTable().destroy();
                          this.loadPublication();
                          //this.loader=false;
                        }else{
                          this.notifyService.showError(" UnSuccessfull","Error");
                          //this.loader=false;
                        }
                      });
              } else{
                this.router.navigate(['']);
                  return;
              }
      }else{
        // $("#tablePublication").DataTable().destroy();
        // this.loadPublication();
        this.notifyService.showError("You clicked the Cancel button! ","");
        //this.loader=false;
        }
  }

  //Gallery
  loadGallery() {
   //this.loader=true;
    this.apiService.request('GET', 'api/v1/cms/getAllGallery').subscribe((data: any) => {
      if (data) {
        data.sort((a, b) => {
          return <any>new Date(b.updatedOn) - <any>new Date(a.updatedOn);
        });
        this.galleryList = new Array() ;
        data.map(item=>{
          return {
            id:item['id'],
            imgid:item['imgid'],
            imgPath : baseUrlFile+'gallery/'+item['imgPath'],
            type: item['typemaster']['type'],
            typeId: item['typemaster']['typeId'],
            createdBy:item['createdBy'],
            createdOn:item['createdOn'],
            updatedOn:item['updatedOn']
          }
        }).forEach(item=> this.galleryList.push(item));
        this.dtTrigger4.next();
        //this.loader=false;
      }
    });
  }
  loadGalleryType(){
   //this.loader=true;
    this.apiService.request('GET', 'api/v1/cms/getGalleryType').subscribe((data: any) => {
      if (data) {
        this.galleryType = new Array() ;
        data.map(item=>{
          return {
            type: item['type'],
            typeId: item['typeId'],
            active: item['active'],
            createdBy:item['createdBy'],
            createdOn:item['createdOn'],
            updatedOn:item['updatedOn']
          }
        }).forEach(item=> this.galleryType.push(item));
        this.dtTrigger6.next();
        //this.loader=false;

      }
    });
  }
  getActiveGalleryTypeList() {
    return this.apiService.request('GET', 'api/v1/cms/getActiveGalleryType').subscribe((data: any) => {
	    if(data)
	    {
        this.typeList=data;
      }
    });
  }
  getGalleryImgByType(type:any){
    $("#tableGallery").DataTable().destroy();
    if(type==0){
       this.loadGallery();
    }else{
    this.apiService.request('GET', 'api/v1/cms/getAllGalleryImgByType?typeId='+type).subscribe((data: any) => {
      if (data) {
        data.sort((a, b) => {
          return <any>new Date(b.updatedOn) - <any>new Date(a.updatedOn);
        });
        this.galleryList = new Array() ;
        data.map(item=>{
          return {
            id:item['id'],
            imgid:item['imgid'],
            imgPath : baseUrlFile+'gallery/'+item['imgPath'],
            type: item['typemaster']['type'],
            typeId: item['typemaster']['typeId'],
            createdBy:item['createdBy'],
            createdOn:item['createdOn'],
            updatedOn:item['updatedOn']
          }
        }).forEach(item=> this.galleryList.push(item));
        this.dtTrigger4.next();
      }
    });
   }
  }
  changeGalleryTypeStatus(typeId:any,status:boolean){
    //console.log(status);
   var flag=confirm('Are you sure!!!!');
   if(flag){
    const userdata=sessionStorage.getItem('user');
     if(userdata!=null)
       {
         const user=JSON.parse(userdata);
         this.apiService.request('PUT', 'api/v1/cms/changeGalleryTypeStatus?userId='+user.userid+'&typeId='+typeId+'&isActive='+status).subscribe((message:any) => {
            if(message){
              //console.log(message)
              if(message=="success"){
                if(status==true){
                  this.notifyService.showSuccess("Deactivate Successfull","Success");
                }else{
                  this.notifyService.showSuccess("Activate Successfull","Success");
                }
                $("#tableGalleryType").DataTable().destroy();
                this.loadGalleryType();
                $("#tableGallery").DataTable().destroy();
                this.loadGallery()
                //this.loader=false;

              }else{
                this.notifyService.showError("UnSuccessfull","Error");
                $("#tableGalleryType").DataTable().destroy();
                this.loadGalleryType();
                $("#tableGallery").DataTable().destroy();
                this.loadGallery()
                //this.loader=false;
              }
             }
           });
      }else{
        this.router.navigate(['']);
          return;
      }
   }else{
    $("#tableGalleryType").DataTable().destroy();
     this.loadGalleryType();
     $("#tableGallery").DataTable().destroy();
     this.loadGallery()
    this.notifyService.showError("You clicked the Cancel button! ","Error");
    //this.loader=false;
   }

  }
  deleteGalleryItem(element:any){
    //console.log(element)
     var flag=confirm('Are you sure!!!!');
     if(flag){
        const userdata=sessionStorage.getItem('user');
            if(userdata!=null)
             {
              const user=JSON.parse(userdata);
                  this.apiService.request('PUT', 'api/v1/cms/deleteGalleryById?userId='+user.userid+'&publicationId='+element.id).subscribe((res: any) => {
                    var response = JSON.parse(res);
                    if(response.message=="success"){
                        this.notifyService.showSuccesswithTimeout("Deleted Successfully","Success");
                          $("#tableGallery").DataTable().destroy();
                          this.loadGallery()
                          //this.loader=false;
                        }else{
                          this.notifyService.showError(" UnSuccessfull","Error");
                          //this.loader=false;
                        }
                      });
              } else{
                this.router.navigate(['']);
                  return;
              }
      }else{
        // $("#tableGallery").DataTable().destroy();
        // this.loadGallery()
        this.notifyService.showError("You clicked the Cancel button! ","");
        //this.loader=false;
        }
  }

  //Tender
  loadTender() {
   //this.loader=true;
    this.apiService.request('GET', 'api/v1/cms/getAllTender').subscribe((data: any) => {
      if (data) {
        this.tenderList = new Array() ;
        data.map(item=>{
          return {
            tenderId:item['tenderId'],
            uploadPath : baseUrlFile+'tender/'+item['uploadPath'],
            tenderName: item['tenderName'],
            tenderNum: item['tenderNum'],
            title:item['title'],
            publicationDate:item['publicationDate'],
            craetedOn:item["craetedOn"]
          }
        }).forEach(item=> this.tenderList.push(item));
        this.dtTrigger5.next();

        //this.loader=false;
      }
    });
  }
  getTenderRecord(element:any){
    //console.log(element)
    this.updateTenderForm.get('tenderId').setValue(element.tenderId);
    this.updateTenderForm.get('title').setValue(element.title);
    //console.log(element.publicationDate);

    var date=new Date(element.publicationDate).toDateString();;
    //console.log(date);
    this.updateTenderForm.controls.fromdate.setValue(new Date("2015-03-25"));
    this.updateTenderForm.get('fromdate').setValue(date);
    this.updateTenderForm.get('tenderNo').setValue(element.tenderNum);
    this.updateTenderForm.get('tenderName').setValue(element.tenderName);
  }
  formatDate(date) {
    let dateexct=date.split('-');
    var day = dateexct [0];
    var month = dateexct [1];
    var year = dateexct [2];
    var fullDay=year+'-'+month+'-'+day;
    return fullDay;
 }
 updateTenderDoc(){
    const userdata=sessionStorage.getItem('user');
    if(userdata!=null){
      const user=JSON.parse(userdata);
        if(user!=null){
          let startDate = this.sdate.nativeElement.value;
          if(startDate!=''){
            startDate=this.formatDate(startDate);
          }else if(startDate==''){
            alert("Please Provide date");
            this.sdate.nativeElement.focus();
            //this.loader=false;
            return false;
          }
          if(this.selectedTenderPdfFiles!=undefined){
            let formdata: FormData = new FormData();
            formdata.append('file', this.selectedTenderPdfFiles);
            this.apiService.request('PUT', 'api/v1/cms/updateTender?tenderId='+this.updateTenderForm.value.tenderId+'&title='+this.updateTenderForm.value.title+'&publicationDate='+startDate+'&userid='+user.userid+'&type=tender', formdata).subscribe((res: any) => {
              var response = JSON.parse(res);
                if(response.message=="Success"){
                  this.closebuttonTender.nativeElement.click();
                  this.notifyService.showSuccess("Tender Update Successfully","Success");
                  $("#tableTender").DataTable().destroy();
                  this.loadTender();
                  this.loader2=false;
                  //this.loader=false;
                }else{
                  this.loader2=false;
                  //this.loader=false;
                  this.notifyService.showError("UnSuccessful","Error");
                }
            });
          }else{
            this.apiService.request('PUT', 'api/v1/cms/updateTenderWithoutFile?tenderId='+this.updateTenderForm.value.tenderId+'&title='+this.updateTenderForm.value.title+'&publicationDate='+startDate+'&userid='+user.userid).subscribe((res: any) => {
              var response = JSON.parse(res);
                if(response.message=="Success"){
                  this.closebuttonTender.nativeElement.click();
                  this.notifyService.showSuccess("Tender Update Successfully","Success");
                  $("#tableTender").DataTable().destroy();
                  this.loadTender();
                  this.notifyService.showSuccess("Tender Update Successfully","Success");
                }else{
                  this.notifyService.showError("UnSuccessful","Error");
                }
            });
          }
        }
    } else{
      this.router.navigate(['']);
       return;
    }
  }
  OnSelectTenderPdfFile(event){
    const file = event.target.files.item(0)
    if (file.type.match('pdf.*')) {
      this.selectedTenderPdfFiles = file;
    } else {
      this.notifyService.showError("invalid format!.Only pdf is supported.","Error");
      this.updateTenderForm.controls.tenderfile.setValue("");
    }
    if (event.length === 0)
        this.notifyService.showError("Required.","Error");
      return;
}
  deleteTenderRecord(element:any){
    //console.log(element)
     var flag=confirm('Are you sure!!!!');
     if(flag){
        const userdata=sessionStorage.getItem('user');
            if(userdata!=null)
             {
              const user=JSON.parse(userdata);
                  this.apiService.request('PUT', 'api/v1/cms/deleteTenderById?userId='+user.userid+'&tenderId='+element.tenderId).subscribe((res: any) => {
                    var response = JSON.parse(res);
                    if(response.message=="success"){
                        this.notifyService.showSuccesswithTimeout("Deleted Successfully","Success");
                          $("#tableTender").DataTable().destroy();
                          this.loadTender()
                          //this.loader=false;
                        }else{
                          this.notifyService.showError(" UnSuccessfull","Error");
                          //this.loader=false;
                        }
                      });
              } else{
                this.router.navigate(['']);
                  return;
              }
      }else{
        this.notifyService.showError("You clicked the Cancel button! ","");
        //this.loader=false;
        }
  }

//Notice
loadNotice(){
 //this.loader=true;
  this.apiService.request('GET', 'api/v1/cms/getAllNotice').subscribe((data: any) => {
    if (data) {
      this.noticelist = new Array();
      data.map(item => {
        return {
          noticeId: item['id'],
          uploadPath: baseUrlFile + 'notice/' + item['noticePath'],
          noticeName: item['noticeName'],
          noticeNumber: item['noticeNumber'],
          noticeValidDate: item['noticeValidDate'],
          noticeDate: item['noticeDate']
        }
      }).forEach(item => this.noticelist.push(item));
      this.dtTrigger7.next();
      //this.loader=false;
    }
  });
}
getNoticeRecord(element:any){
  this.updateNoticeForm.get('noticeId').setValue(element.noticeId);
  this.updateNoticeForm.get('noticeNumber').setValue(element.noticeNumber);
  var _noticeDate=new Date(element.noticeDate).toDateString();
  this.updateNoticeForm.get('noticeDate').setValue(_noticeDate);
  var _noticeValidDate=new Date(element.noticeValidDate).toDateString();
  this.updateNoticeForm.get('noticeValidDate').setValue(_noticeValidDate);
  this.updateNoticeForm.get('noticeName').setValue(element.noticeName);
}
UpdateNotice(){
  try{
    this.loader2=true;
    const userdata=sessionStorage.getItem('user');
    if(userdata!=null){
      const user=JSON.parse(userdata);
        if(user!=null){
          let startDate = this.noticeSdate.nativeElement.value;
          let validDate = this.noticeVdate.nativeElement.value;
          if(startDate==''){
            alert("Please Provide Notice date");
            this.noticeSdate.nativeElement.focus();
            //this.loader=false;
            return false;
          }if(validDate==''){
            alert("Please Provide date");
            this.noticeVdate.nativeElement.focus();
            //this.loader=false;
            return false;
          }
          if(startDate>validDate){
            alert("Please Notice  date should br greater than Notive Validation Date");
            this.noticeSdate.nativeElement.focus();
            //this.loader=false;
            return false;
          }
          if(startDate!='' && validDate!=''){
            if(this.selectedNoticePdfFiles!=undefined){
              let formdata: FormData = new FormData();
              formdata.append('file', this.selectedNoticePdfFiles);
                this.apiService.request('PUT', 'api/v1/cms/updateNotice?noticeId='+this.updateNoticeForm.value.noticeId+'&noticeName='+this.updateNoticeForm.value.noticeName+'&noticeDate='+startDate+'&noticeValidDate='+validDate+'&updateBy='+user.userid, formdata).subscribe((res: any) => {
                  var response = JSON.parse(res);
                    if(response.message=="success"){
                      this.closebuttonNotice.nativeElement.click();
                      this.notifyService.showSuccess(" Notice Updated Successfully","Success");
                      $("#tableNotice").DataTable().destroy();
                      this.loadNotice();
                      this.loader2=false;
                      //this.loader=false;
                    }else if(response.message=="Fail"){
                      this.loader2=false;
                      //this.loader=false;
                      this.notifyService.showError("UnSuccessful","Error");
                    }
              },err=>{
                this.loader2=false;
               // this.loader=false;
                });
            }else{
               this.apiService.request('PUT', 'api/v1/cms/updateNoticeWithoutFile?noticeId='+this.updateNoticeForm.value.noticeId+'&noticeName='+this.updateNoticeForm.value.noticeName+'&noticeDate='+startDate+'&noticeValidDate='+validDate+'&updateBy='+user.userid).subscribe((res: any) => {
                var response = JSON.parse(res);
                  if(response.message=="success"){
                    this.closebuttonNotice.nativeElement.click();
                    this.notifyService.showSuccess(" Notice Updated Successfully","Success");
                    $("#tableNotice").DataTable().destroy();
                    this.loadNotice();
                    this.loader2=false;
                    //this.loader=false;
                  }else if(response.message=="Fail"){
                    this.loader2=false;
                    //this.loader=false;
                    this.notifyService.showError("UnSuccessful","Error");
                  }
            },err=>{
              this.loader2=false;
             //this.loader=false;
              });
            }  
        }
      }
    } else {
      this.router.navigate(['']);
      return;
    }
  }catch{
    this.loader2=false;
    //this.loader=false;
  }finally{
    this.loader2=false;
    //this.loader=false;
  }

}
OnSelectNoticePdfFile(event){
  const file = event.target.files.item(0)
  if (file.type.match('pdf.*')) {
    this.selectedNoticePdfFiles = file;
  } else {
    this.notifyService.showError("invalid format!.Only pdf is supported.", "Error");
    this.updateNoticeForm.controls.noticefile.setValue("");
  }
  if (event.length === 0)
    return;
}
deleteNoticeRecord(element:any){
  //console.log(element)
   var flag=confirm('Are you sure!!!!');
   if(flag){
    const userdata=sessionStorage.getItem('user');
    if(userdata!=null)
      {
         const user=JSON.parse(userdata);
         this.apiService.request('PUT', 'api/v1/cms/deleteNotice?userId='+user.userid+'&noticeId='+element.noticeId).subscribe((res: any) => {
         var response = JSON.parse(res);
            if(response.message=="Success")
              {
                    this.notifyService.showSuccesswithTimeout("Deleted Successfully","Success");
                      $("#tableNotice").DataTable().destroy();
                      this.loadNotice();
                      //this.loader=false;
             }else{
                      this.notifyService.showError(" UnSuccessfull","Error");
                      //this.loader=false;
                    }
          });
      } else{
        this.router.navigate(['']);
          return;
      }
    }else{
      this.notifyService.showError("You clicked the Cancel button! ","");
      //this.loader=false;
      }
}
//Division Control Room
getDivisionInfoList() {
    try{
      //this.loader=true;
      this.apiService.request('GET', 'api/v1/cms/getDivisionInfoList').subscribe((data: any) => {
        if (data) {
          let division = new Array();
          data.map(item => {
            return {

              circleName: item['circle']['circleName'],
              divisionId: item['divisionId'],
              divisionName: item['divisionName'],
              controlRoomPHno: item['controlRoomPHno'],
            }
          }).forEach(item => division.push(item));
          this.divisionList = new Array();
          this.divisionList=division.sort((a,b) => 
          (a.circleName.toLowerCase() < b.circleName.toLowerCase()) ? -1 : 
          ((b.circleName.toLowerCase() > a.circleName.toLowerCase()) ? 1 : 0));
          this.dtTrigger8.next();
          //this.loader=false;
        }
      },err=>{
        //this.loader=false;
      });
  }catch(e){
    //this.loader=false;
  }
}
getDivisionInfo(element:any){
  this.updateDivisionForm.get('divisionId').setValue(element.divisionId);
  this.updateDivisionForm.get('controlRoomNumber').setValue(element.controlRoomPHno);
}
UpdateDivisionControlRoom(){
  try{
   //this.loader=true;
    const userdata=sessionStorage.getItem('user');
    if(userdata!=null){
      const user=JSON.parse(userdata);
      if(this.updateDivisionForm.value.divisionId!=null && this.updateDivisionForm.value.controlRoomNumber!="")
          {
           this.apiService.request('PUT', 'api/v1/cms/UpdateDivisionInfoList',{
            userid:user.userid,
            divisionid:this.updateDivisionForm.value.divisionId,
            number:this.updateDivisionForm.value.controlRoomNumber
         }).subscribe(
          (res: any) => {
              var response = JSON.parse(res);
              if(response.message=="Success"){
                this.closebuttonDivision.nativeElement.click();
                this.notifyService.showSuccesswithTimeout("Update Successfully","Success");
                $("#tableControlRoomInfo").DataTable().destroy();
                this.getDivisionInfoList();
                //this.loader=false;
              }else if(response.message=="Failed"){
                this.notifyService.showError("UnSuccessful","Error");
                //this.loader=false;
              }
          },err => {
            this.notifyService.showError("UnSuccessful","Error");
            //this.loader=false;
            });
          }else{
            this.notifyService.showError("Required Fields","Error");
            //this.loader=false;
          }
        }else{
        this.router.navigate(['']);
          return;
      }
  }catch{
    //this.loader=false;
  }
}
///
defaultUrl(event) {
    event.target.src='assets/images/no_img.png';
  }
}
