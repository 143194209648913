
<app-masterheader></app-masterheader> 

<div class="wrapper">
      
    <app-sidebar></app-sidebar>
        
    <div id="content">

        <!-- <app-breadcrumb></app-breadcrumb> -->

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Add Primary Link</h4>
                                    <form>
                                    	<div class="row">
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label for="">Primary Link in English</label>
                                                    <input type="text" class="form-control" id="" placeholder="Primary Link in English">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label for="">Primary Link in Odia</label>
                                                    <input type="text" class="form-control" id="" placeholder="Primary Link in Odia">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label for="">Path Name</label>
                                                    <input type="text" class="form-control" id="" placeholder="Path Name">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label for="">Icon</label>
                                                    <input type="text" class="form-control" id="" placeholder="Icon">
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row">
                                        	<div class="col-md-12 text-center">
                                        		<button type="submit" class="btn btn-sm btn-success">Save</button>
                                        	</div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">View Primary Link</h4>
                                    <table class="table table-bordered row-border hover" datatable [dtOptions]="dtOptions">
                                        <thead>
                                            <tr>
                                                <th>Sl. No.</th>
                                                <th>Primary Link in English</th>
                                                <th>Primary Link in Odia</th>
                                                <th>Path Name</th>
                                                <th>Icon</th>
                                                <th>Action</th>
                                            </tr>
                                         </thead>
                                        <tbody>
                                            <tr *ngFor="let group of data">
                                                <td>{{group.slno}}</td>
                                                <td>{{group.englishlink}}</td>
                                                <td>{{group.odialink}}</td>
                                                <td>{{group.path}}</td>
                                                <td>{{group.icon}}</td>
                                                <td>
                                                    <button class="btn btn-sm btn-info mr-1" data-toggle="modal" data-target="#manageLinkModal"><i class="fa fa-edit"></i></button>
                                                    <button class="btn btn-sm btn-danger"><i class="fa fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="modal fade" id="manageLinkModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
		  	<div class="modal-dialog modal-dialog-centered" role="document">
		    	<div class="modal-content">
			      	<div class="modal-header">
			        	<h4 class="modal-title" id="exampleModalLongTitle">Edit Primary Link</h4>
			        	<button type="button" class="close" data-dismiss="modal" aria-label="Close">
			          		<span aria-hidden="true">&times;</span>
			        	</button>
			      	</div>
		      		<div class="modal-body">
		        		<form>
                        	<div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="">Primary Link in English</label>
                                        <input type="text" class="form-control" id="" placeholder="Primary Link in English">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="">Primary Link in Odia</label>
                                        <input type="text" class="form-control" id="" placeholder="Primary Link in Odia">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="">Path Name</label>
                                        <input type="text" class="form-control" id="" placeholder="Path Name">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="">Icon</label>
                                        <input type="text" class="form-control" id="" placeholder="Icon">
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                            	<div class="col-md-12 text-center">
                            		<button type="submit" class="btn btn-sm btn-success">Update</button>
                            	</div>
                            </div>
                        </form>
		      		</div>
		    	</div>
		  	</div>
		</div>
        
    </div>

    <div id="footer">
        <app-masterfooter></app-masterfooter>
    </div>

</div>

<app-masterfooter></app-masterfooter>
