import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "../service/api.service";
import { AuthService } from "../service/auth.service";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import ImageWMS from 'ol/source/ImageWMS';
import { View } from 'ol';
import Map from 'ol/Map'
import * as layer from 'ol/layer';
import * as proj from 'ol/proj';
import { Tile as Tile, Vector as VectorLayer, Image as ImageLayer } from 'ol/layer';
import { OSM as OSM, Vector as VectorSource, Vector as MeasureSource } from 'ol/source';
import { WFS, GeoJSON } from 'ol/format';
import * as control from 'ol/control';
import 'ol/ol.css';
import Feature from 'ol/Feature';
import Overlay from 'ol/Overlay';
import { Icon, Style, Fill, Stroke } from 'ol/style';
import Point from 'ol/geom/Point';
import { Observable, Subscription, interval, Subject } from 'rxjs';
import { SidebarComponent } from '../sidebar/sidebar.component';
// import { EventList } from '../model/eventlist';
// import { Audit_Trail } from '../audit_trail';
// import { TranslateService } from '@ngx-translate/core'
import {
  equalTo as equalToFilter,
  like as likeFilter,
  and as andFilter
} from 'ol/format/filter';
import { allowedNodeEnvironmentFlags } from 'process';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment.prod';
import { NotificationService } from '../service/notification.service';


const directimgpath = "../assets/images/direct_gray_new.gif";
const indirectimgpath = "../assets/images/indirect_pink_new.gif";
const problematicimgpath = "../assets/images/problemetic.png";

const wms_url = environment.GEOSERVER_WMS_URL;
const baseUrlFile = environment.FILE_API_URL;
declare var jQuery: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  private updateSubscription: Subscription;
 // public loader: boolean = false;
  public circleDisabled: boolean = false;
  public divisionDisabled: boolean = false;
  public rangeDisabled: boolean = false;
  public tableData: any[];

  public circleSelected = 'All';
  public divisionSelected = 'All';
  public rangeSelected = 'All';

  public circleid: string;
  public divid: string;
  public rangeid: string;

  public divisionlist: any[];
  public circleList: any[];
  public rangelist: any[];

  public Elephant_Sighted: number;
  public Direct_Sighting: number;
  public indirect_Sighting: number;
  _protectedAreaBalesore: layer.Image;
  _protectedAreaChandaka: layer.Image;
  _protectedAreaChilika: layer.Image;
  _protectedAreaDhenkanal: layer.Image;
  _protectedAreaKeonjhar: layer.Image;
  _protectedAreaMahanadi: layer.Image;
  _protectedAreaPuri: layer.Image;
  _protectedAreaSatakosia: layer.Image;
  _protectedAreaSunabeda: layer.Image;

  _divisionBoundarySunabeda: layer.Image;
  _divisionBoundarySatakosia: layer.Image;
  _divisionBoundaryChilika: layer.Image;
  _divisionBoundaryChandaka: layer.Image;
  _divisionBoundaryBalesore: layer.Image;
  _divisionBoundaryBhadrak: layer.Image;
  _divisionBoundaryDhenkanal: layer.Image;
  _divisionBoundaryPuri: layer.Image;
  _divisionBoundaryMahanadi: layer.Image;
  _divisionBoundaryHirakud: layer.Image;
  _divisionBoundaryKeonjhar: layer.Image;
  _divisionBoundaryBaliguda: layer.Image;
  _divisionBoundaryKalahandiS: layer.Image;
  _divisionBoundaryParlakhemundi: layer.Image;
  _divisionBoundaryBamra: layer.Image;
  _divisionBoundaryMangrove: layer.Image;

  imegary: layer.Tile;
  streetmap: layer.Tile;
  osmMap: layer.Tile;
  map: Map;
  modify: any;

  vectorparcelextendglobal: any;
  elephantpoitnsglobal: Array<any> = new Array();
  elephantpoitnsglobal2: Array<any> = new Array();
  nillReport_Sighting: any;
  problematicReport_Sighting: any;
  ElephantDeath_Sighting: any;
  FireAlert_Sighting: any;
  chardata: any;
  roleId: any;
  juridictionId: any;
  latlongarray: Array<any>;
  public startDateSelected = "";
  public endDateSelected = "";
  latlongarray2: any[];
  latlongarray_d: any[];
  latlongarray2_d: any[];
  private _CircleBoundary: any;
  vectorparcelextendglobal_circle: any;
  vectorparcelextendglobal_division: any;
  vectorparcelextendglobal_range: any;
  ol: any;


  constructor(@Inject(DOCUMENT) private _document: Document, private formBuilder: FormBuilder, private ref: ChangeDetectorRef, private router: Router, private apiService: ApiService, private authService: AuthService, private notifyService: NotificationService) { }

  // sidebar
  @ViewChild(SidebarComponent) _sidebarEleRef: SidebarComponent;
  ngAfterViewInit() {
    this._sidebarEleRef.contains = '1';
    ////this.loader = false;;
    this.ref.detectChanges();
  }

  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {};
  ngOnInit(): void {
    //this.loader = true;
    const userdata = sessionStorage.getItem('user');
    if (userdata != null) {
      //this.loader=true;//set loader for visible
      this.circleid = "0";
      this.divid = "0";
      this.rangeid = "0";
      this.getCurentDate();
      this.inhisiatemap();
      this.getAllCircle();
      this.isCircleDivisionRangeDisabled();
     // 600000
      this.updateSubscription = interval(900000).subscribe(
        (val) => { this.refreshDashboard()});

      //this.getChartData();
      (function ($) {
        $("document").ready(function () {
          $(".tab-slider--body").hide();
          $(".tab-slider--body:first").show();
        });

        $(".tab-slider--nav li").click(function () {
          $(".tab-slider--body").hide();
          var activeTab = $(this).attr("rel");
          $("#" + activeTab).fadeIn();
          if ($(this).attr("rel") == "tab2") {
            $('.tab-slider--tabs').addClass('slide');
          } else {
            $('.tab-slider--tabs').removeClass('slide');
          }
          $(".tab-slider--nav li").removeClass("active");
          $(this).addClass("active");
        });
      })(jQuery);
      var format = 'image/png';
      this.dtOptions = {
        // pagingType: 'full_numbers',
        // pageLength: 5,
        // processing: true,
        // responsive: true,
        dom: 'Bfrtip',
        buttons: [
          'copy', 'excel', 'pdf', 'print'
        ]
      };
    } else {
      ////this.loader = false;;
      this.router.navigate(['/']);
    }
  }
  getCurentDate() {
    var Yestrerdaywithtime;
    var Todaywithtime;
    var mydate = new Date();
    var yester = new Date();
    yester = new Date(yester.setDate(yester.getDate() - 1));
    if (mydate.getMonth() < 9) {
      Todaywithtime = mydate.getFullYear() + "-0" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
    } else {
      Todaywithtime = mydate.getFullYear() + "-" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
    } if (yester.getMonth() < 9) {
      Yestrerdaywithtime = yester.getFullYear() + "-0" + (yester.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
    } else {
      Yestrerdaywithtime = yester.getFullYear() + "-" + (yester.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
    }
    if (this.startDateSelected == "") { this.startDateSelected = Yestrerdaywithtime; }
    if (this.endDateSelected == "") { this.endDateSelected = Todaywithtime; }
  }
  isCircleDivisionRangeDisabled() {
    const userdata = sessionStorage.getItem('user');
    if (userdata != null) {
      const user = JSON.parse(userdata);
      this.roleId = user.roleId;
      this.juridictionId = user.juridictionId;
      if (this.roleId == 1 || this.roleId == 3) {
        this.circleDisabled = false;
        this.divisionDisabled = false;
        this.rangeDisabled = false;
        this.viewDashboardinfo();
        this.reporttable();
      }
      else if (this.roleId == 6) {

        this.apiService.request('GET', 'api/v1/masters/getAssignCircleDivisionRangeByUserId?roleId=' + this.roleId + '&jurdicitionId=' + this.juridictionId).subscribe((data: any) => {
          if (data) {

            this.circleDisabled = true;
            this.circleid = data.circleId
            this.circleSelected = data.circleId;
            this.getAllDivisionByCircleId();
            this.setTheMaptolayer("Circle");
            this.getAllRangeByDivId();
            this.viewDashboardinfo();
            this.reporttable();
          }
        });
      }
      else if (this.roleId == 2) {
        //alert(this.roleId)
        this.apiService.request('GET', 'api/v1/masters/getAssignCircleDivisionRangeByUserId?roleId=' + this.roleId + '&jurdicitionId=' + this.juridictionId).subscribe((data: any) => {
          if (data) {

            this.circleDisabled = true;
            this.circleid = data.circleId
            this.circleSelected = data.circleId;
            this.divid = data.divisonId;

            this.getAllDivisionByCircleId();
            this.divisionSelected = data.divisonId;
            this.divisionDisabled = true;
            this.setTheMaptolayer("Division");
            this.getAllRangeByDivId();
            this.viewDashboardinfo();
            this.reporttable();
          }
        });
      }
      else if (this.roleId == 4 || this.roleId == 5) {
        this.apiService.request('GET', 'api/v1/masters/getAssignCircleDivisionRangeByUserId?roleId=' + this.roleId + '&jurdicitionId=' + this.juridictionId).subscribe((data: any) => {
          if (data) {

            this.circleDisabled = true;
            this.circleid = data.circleId
            this.circleSelected = data.circleId;
            this.getAllDivisionByCircleId();
            this.divisionSelected = data.divisonId;
            this.divid = data.divisonId;
            this.divisionDisabled = true;
            this.getAllRangeByDivId();
            this.rangeSelected = data.rangeId;
            this.rangeid = data.rangeId;
            this.rangeDisabled = true;
            this.setTheMaptolayer("Range");
            this.viewDashboardinfo();
            this.reporttable();
          }
        });
      }
    } else {

    }
  }
  refreshDashboard() {

    const userdata = sessionStorage.getItem('user');
    if (userdata != null) {
      const user = JSON.parse(userdata);
      this.roleId = user.roleId;
      this.juridictionId = user.juridictionId;
      this.viewDashboardinfo();
      this.reporttable();
    } else {
      this.router.navigate(['']);
      return;
    }
  }
  getAllCircle() {
    return this.apiService.request('GET', 'api/v1/masters/getAllCircle').subscribe((data: any) => {
      if (data) {
        this.circleList = data;
      }
    });
  }
  getAllDivisionByCircleId() {
    if (this.circleSelected != 'All') {
      return this.apiService.request('GET', 'api/v1/masters/getAllDivisionByCircleId?id=' + this.circleSelected).subscribe((data: any) => {
        if (data) {
          this.divisionlist = data;
        }
      });
    }
  }
  getAllRangeByDivId() {
    if (this.divisionSelected != 'All') {
      return this.apiService.request('GET', 'api/v1/masters/getAllRangeByDivid?id=' + this.divisionSelected).subscribe((data: any) => {
        if (data) {
          this.rangelist = data;
        }
      });
    }
  }
  // refreshPage() {
  //   setTimeout(function () {
  //     this.isCircleDivisionRangeDisabled();
  //   }, 1205);//12050000
  // }
  onCircleChangeloadDashboard(value: string) {
    //this.loader = true;//set loader for visible
    if (value == "0") {
      this.circleid = "0";
      this.circleSelected = "All";
      this.divisionlist = [];
      this.divisionSelected = 'All';
      this.divid = "0";
      this.rangeSelected = 'All';
      this.rangelist = [];
      this.map.addLayer(this._CircleBoundary);
      if (this.vectorparcelextendglobal_circle) {
        this.map.removeLayer(this.vectorparcelextendglobal_circle);
      }
      if (this.vectorparcelextendglobal_division) {
        this.map.removeLayer(this.vectorparcelextendglobal_division);
      }
      if (this.vectorparcelextendglobal_range) {
        this.map.removeLayer(this.vectorparcelextendglobal_range);
      }
      this.viewDashboardinfo();
      $("#reportTable").DataTable().destroy();
      this.reporttable();
      this.ref.detectChanges();
    }
    else {
      this.circleSelected = value;
      this.circleid = value;
      this.setTheMaptolayer("Circle");
      this.getAllDivisionByCircleId();
      this.divisionSelected = 'All';
      this.divid = "0";
      this.rangeSelected = 'All';
      this.rangelist = [];
      this.viewDashboardinfo();
      $("#reportTable").DataTable().destroy();
      this.reporttable();
      this.ref.detectChanges();
    }
    ////this.loader = false;
  }
  onDivChangeloadWLDashboard(value: string) {
    //this.loader = true;//set loader for visible
    if (value == "0") {
      this.divisionSelected = 'All';
      this.divid = "0";
      this.rangeid = "0";
      this.rangeSelected = 'All';
      this.rangelist = [];
      if (this.vectorparcelextendglobal_division) {
        this.map.removeLayer(this.vectorparcelextendglobal_division);
      } if (this.vectorparcelextendglobal_range) {
        this.map.removeLayer(this.vectorparcelextendglobal_range);
      }
      this.setTheMaptolayer("Division");
      this.viewDashboardinfo();
      $("#reportTable").DataTable().destroy();
      this.reporttable();
      this.ref.detectChanges();
    }
    else {
      this.divisionSelected = value;
      this.setTheMaptolayer("Division");
      this.getAllRangeByDivId();
      this.rangeSelected = 'All';
      this.rangeid = "0";
      this.viewDashboardinfo();
      $("#reportTable").DataTable().destroy();
      this.reporttable();
      this.ref.detectChanges();
    }
    ////this.loader = false;;
  }
  onRangeChangeloadDashboard(value: string) {
    //this.loader = true;//set loader for visible
    if (value == "0") {
      this.rangeSelected = 'All';
      this.rangeid = "0";
       if (this.vectorparcelextendglobal_range) {
        this.map.removeLayer(this.vectorparcelextendglobal_range);
      }
      this.setTheMaptolayer("Range");
      this.viewDashboardinfo();
      $("#reportTable").DataTable().destroy();
      this.reporttable();
      this.ref.detectChanges();
    } else {
      this.rangeSelected = value;
      this.setTheMaptolayer("Range");
      this.viewDashboardinfo();
      $("#reportTable").DataTable().destroy();
      this.reporttable();
      this.ref.detectChanges();
    }
    ////this.loader = false;;
  }
  viewDashboardinfo() {
    return this.apiService.request('GET', 'api/v1/reports/getAllReportCountIn24Hrsss?circle=' + this.circleSelected + '&division=' + this.divisionSelected + '&range=' + this.rangeSelected + '&startDate=' + this.startDateSelected + '&endDate=' + this.endDateSelected).subscribe((response: any) => {
      if (response) {
        this.Elephant_Sighted = response.allReportCount;
        this.Direct_Sighting = response.allReportCountDirect;
        this.indirect_Sighting = response.allReportCountInDirect;
        this.nillReport_Sighting = response.nillReportCount;
        this.problematicReport_Sighting=response.allProblemeticReportCount;
        this.ElephantDeath_Sighting = response.allElephantDeathReportCount;
        this.FireAlert_Sighting = response.allFireAlertReportCount;

      }
    })
  }
  reporttable() {
    //this.loader = true;
    try {
      if (this.elephantpoitnsglobal.length > 0) {
        for (let index = 0; index < this.elephantpoitnsglobal.length; index++) {
          const element = this.elephantpoitnsglobal[index];
          this.map.removeLayer(element);
        }
      }
      if (this.elephantpoitnsglobal2.length > 0) {
        for (let index = 0; index < this.elephantpoitnsglobal2.length; index++) {
          const element = this.elephantpoitnsglobal2[index];
          this.map.removeLayer(element);
        }
      }
     
       
      this.apiService.request('GET', 'api/v1/reports/viewReportLast24hrs?circle=' + this.circleSelected + '&division=' + this.divisionSelected + '&range=' + this.rangeSelected + '&startDate=' + this.startDateSelected + '&endDate=' + this.endDateSelected).subscribe((response: any[]) => {
        if (response) {
         
          
          $("#reportTable").DataTable().destroy();
          this.tableData = new Array();
          this.tableData = response;
          this.dtTrigger.next();
          response.forEach(element => {
            if (element['reportType'] == "direct") {
              if (element['duplicateReport'] == null) {
                   
                let imagepath=element['problemetic']?problematicimgpath:directimgpath;
                var iconFeature = new Feature({
                  geometry: new Point(proj.fromLonLat([element['longitude'], element['latitude']])),
                  type: 'Direct',
                  evidenceImgPath:element['imgAcqlocation'],
                  sightingDate:element['sightingDate'],
                  sightingTimeTo:element['sightingTimeTo'],
                  sightingTimefrom:element['sightingTimefrom'],
                  location:element['location'],
                  total:element['total'],
                  divisionName:element['divisionName'],
                  rangeName:element['rangeName'],
                  secName:element['secName'],
                  beatName:element['beatName'],
                  uniqueId:	element['uniqueId'],
                  inchargeOfficer:element['inchargeOfficer'],
                  problemetic:element['problemetic'],
                });
                var iconStyle = new Style({
                  image: new Icon({
                    src: imagepath,
                  }),
                });
                iconFeature.setStyle(iconStyle);
                var vectorSource = new VectorSource({
                  features: [iconFeature],
                });
                var vectorLayer = new VectorLayer({
                  source: vectorSource,
                });
                this.elephantpoitnsglobal.push(vectorLayer);
                this.map.addLayer(vectorLayer);
                ////this.loader = false;;
              
              }
            }//direct
            else if (element['reportType'] == "indirect") {
              if (element['duplicateReport'] == null) {
                var routePath = {
                  longitude: element['longitude'],
                  latitude: element['latitude']
                };
                let imagepath=element['problemetic']?problematicimgpath:indirectimgpath;
                var iconFeature = new Feature({
                  geometry: new Point(proj.fromLonLat([element['longitude'], element['latitude']])),
                  type:'Indirect',
                  evidenceImgPath:element['imgAcqlocation'],
                  sightingDate:element['sightingDate'],
                  sightingTimeTo:element['sightingTimeTo'],
                  sightingTimefrom:element['sightingTimefrom'],
                  location:element['location'],
                  total:element['total'],
                  divisionName:element['divisionName'],
                  rangeName:element['rangeName'],
                  secName:element['secName'],
                  beatName:element['beatName'],
                  inchargeOfficer:element['inchargeOfficer'],
                  uniqueId:	element['uniqueId'],
                  problemetic:element['problemetic'],
                });
                var iconStyle = new Style({
                  image: new Icon({
                    src: imagepath,
                  }),
                });
                iconFeature.setStyle(iconStyle);
                var vectorSource = new VectorSource({
                  features: [iconFeature],
                });
                var vectorLayer = new VectorLayer({
                  source: vectorSource,
                });
                this.elephantpoitnsglobal2.push(vectorLayer);
                this.map.addLayer(vectorLayer);
                ////this.loader = false;;

              }
            } //indirect
          });
        }
      });
    } catch (e) {
      ////this.loader = false;;
    } finally {
      ////this.loader = false;;
    }
  }
  inhisiatemap() {
    var imegary = new Tile({
      visible: false,
      source: new OSM({
        url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
      })
    });

    var streerMap = new Tile({
      visible: true,
      source: new OSM({
        url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      })
    });
    this.imegary = imegary;
    this.streetmap = streerMap;

    // State Boundary

    var _StateBoundary = new layer.Image({
      source: new ImageWMS({
        url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wms',
        params: {
          'FORMAT': 'image/png',
          'VERSION': '1.1.0',
          STYLES: '',
          LAYERS: 'iwlms:odisha_state_boundary'
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
      }),
      visible: true
    });
    this._CircleBoundary = new layer.Image({
      source: new ImageWMS({
        url: wms_url,
        params: {
          'FORMAT': 'image/png',
          'VERSION': '1.1.0',
          STYLES: '',
          LAYERS: 'odisha:master_area_circle',
          CQL_FILTER: "id<>11 and active=1"

        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
      }),
      visible: true
    });

    this.map = new Map({
      layers: [imegary, streerMap, _StateBoundary, this._CircleBoundary],
      controls: [
        new control.Zoom(),
        new control.ZoomSlider(),
        new control.FullScreen(),
        new control.ScaleLine
          ({
            units: 'metric',
            bar: true,
            steps: 4,
            text: true,
            minWidth: 140
          })
      ],
      target: 'elephantdashboardmap',
      view:
        new View({
          center: proj.fromLonLat([85.190055, 20.886785]),
          zoom: 7,
          maxZoom: 23,
          minZoom: 5,
        })
    });
     // generate map to its target and set to a global variable
    const container = document.getElementById('popup');
    const content = document.getElementById('popup-content');
    const closer = document.getElementById('popup-closer');
    const popup = new Overlay({
        element: container,
        autoPan: {
          animation: {
            duration: 250,
          },
        },
      });
    closer.onclick = function () {
      popup.setPosition(undefined);
        closer.blur();
        return false;
      };
      this.map.addOverlay(popup);

    // display popup on click
    var _this=this;
    this.map.on("pointermove", function (evt) {
      var hit = this.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {
           var type=feature.getGeometry().getType();
           if(type=="Point")
            return true;
      }); 
      if (hit) {
          this.getTargetElement().style.cursor = 'pointer';
      } else {
          this.getTargetElement().style.cursor = '';
      }
    });
    this.map.on('click', function (evt) {
      const features = this.forEachFeatureAtPixel(evt.pixel, function (feature) {
       
        return feature;
      });
     
      features.get('type')
      if (!features) {
        return;
      }else{
        if (popup!=undefined) {
          popup.setPosition(undefined);
          closer.blur();
        }
       var html=`<table class="table table-striped table-bordered  table-responsive popuptable" ><tr><td style="width:50%">Report Type</td><td style="width:50%">${features.get('type')}</td></tr>
                        <tr><td>Date</td><td>${features.get('sightingDate')}</td></tr>
                        <tr><td>From Time</td><td>${features.get('sightingTimefrom')}</td></tr>
                        <tr><td>To Time</td><td>${features.get('sightingTimeTo')}</td></tr>
                        <tr><td>Total</td><td>${features.get('total')}</td></tr>
                        <tr><td>Location</td><td>${features.get('location')}</td></tr>
                        <tr><td>Division</td><td>${features.get('divisionName')}</td></tr>
                        <tr><td>Range</td><td>${features.get('rangeName')}</td></tr>
                        <tr><td>Section</td><td>${features.get('secName')}</td></tr>
                        <tr><td>Beat</td><td>${features.get('beatName')}</td></tr>
                        <tr><td>Evidence</td><td><img src=${baseUrlFile}report/${features.get('evidenceImgPath')} 
                        class="img-fluid mr-2 mb-2" style="height:85px;width:100px;" alt=""></td></tr>
                </table>`;
        content.innerHTML =html;
        popup.setPosition(evt.coordinate);
      }
    });
  }
 
  setTheMaptolayer(layertype) {
    var layername_circle = 'https://odishaforestgis.in/ofms/index.php/webservice_api/getCircleGeojson';
    var layername_div = 'https://odishaforestgis.in/ofms/index.php/webservice_api/getDivisionGeojson';
    var layername_range = 'https://odishaforestgis.in/ofms/index.php/webservice_api/getRangeGeojson';
    var querycircle = "";
    var querydiv = "";
    var queryrange = "";
    if (layertype == "Circle") {
      if (this.circleid != "0") {
        querycircle = "{\"circle_id\" : \"" + this.circleid + "\"}";
      }
    }
    else if (layertype == "Division") {
      if (this.divid != "0") {
        querydiv = "{\"division_id\" : \"" + this.divid + "\"}";
      }
    }
    else if (layertype = "Range") {
      if (this.rangeid != "0") {
        queryrange = "{\"range_id\" : \"" + this.rangeid + "\"}";
      }
    }
    this.map.removeLayer(this._CircleBoundary);
    if (layertype == "Circle") {
      if (querycircle != "") {
        let formdata: FormData = new FormData();
        formdata.append('data', querycircle);
        try {
          this.apiService.requestGeoJson(layername_circle, formdata).subscribe((response: any[]) => {
            if (response) {
              if (response["status"] == 1) {
                if (this.vectorparcelextendglobal_range) {
                  this.map.removeLayer(this.vectorparcelextendglobal_range);
                }
                if (this.vectorparcelextendglobal_division) {
                  this.map.removeLayer(this.vectorparcelextendglobal_division);
                }
                if (this.vectorparcelextendglobal_circle) {
                  this.map.removeLayer(this.vectorparcelextendglobal_circle);
                }
                const styleFunction = [
                  new Style({
                    stroke: new Stroke({
                      color: 'rgba(32, 5, 132, 2)',
                      width: 2,
                    }),
                    fill: new Fill({
                      color: 'rgba(0, 0, 255, 0.1)',
                    }),
                  })];
                const vectorSource = new VectorSource();
                var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
                var gjFormat = new GeoJSON({
                  featureProjection: 'EPSG:3857',
                });
                var features = gjFormat.readFeatures(geojson);
                vectorSource.clear();
                var featureOverlayNew = new VectorLayer({
                 // map: this.map,
                  source: vectorSource,
                  style: styleFunction,
                  updateWhileAnimating: true,
                  updateWhileInteracting: true
                });
                featureOverlayNew.getSource().addFeatures(features);
                this.map.addLayer(featureOverlayNew);
                this.map.getView().setZoom(10);
                this.map.getView().fit(featureOverlayNew.getSource().getExtent(), "");

                this.vectorparcelextendglobal_circle = featureOverlayNew;
                //this.loader = false;
              } else {
                this.notifyService.showError("Map Data Not Available", "Error");
                //this.loader = false;
              }
            } else {
              this.notifyService.showError("Map Data Not Available", "Error");
              //this.loader = false;
            }
          }, (error) => {
            const errors = error;
            ////this.loader = false;;

          });
        } catch (e) {
          this.notifyService.showError("Something Error!!", "Error");
          //this.loader = false;
        } finally {
          //this.loader = false;
        }
      }
    }
    if (layertype == "Division") {
      if (querydiv != "") {
        let formdata: FormData = new FormData();
        formdata.append('data', querydiv);
        try {
          this.apiService.requestGeoJson(layername_div, formdata).subscribe((response: any[]) => {
            if (response) {
              if (response["status"] == 1) {
                if (this.vectorparcelextendglobal_division) {
                  this.map.removeLayer(this.vectorparcelextendglobal_division);
                } if (this.vectorparcelextendglobal_range) {
                  this.map.removeLayer(this.vectorparcelextendglobal_range);
                }
                const styleFunction = [
                  new Style({
                    stroke: new Stroke({
                      color: 'rgba(31, 28, 28, 2)',
                      width: 2,
                    }),
                    fill: new Fill({
                      color: 'rgba(32, 28, 28, 0.1)',
                    }),
                  })];
                const vectorSource = new VectorSource();
                var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
                var gjFormat = new GeoJSON({
                  featureProjection: 'EPSG:3857',
                });
                var features = gjFormat.readFeatures(geojson);
                vectorSource.clear();
                var featureOverlayNew = new VectorLayer({
                 // map: this.map,
                  source: vectorSource,
                  style: styleFunction,
                  updateWhileAnimating: true,
                  updateWhileInteracting: true
                });
                featureOverlayNew.getSource().addFeatures(features);
                this.map.addLayer(featureOverlayNew);
                this.map.getView().setZoom(10);
                this.map.getView().fit(featureOverlayNew.getSource().getExtent(), "");
                this.vectorparcelextendglobal_division = featureOverlayNew;
                //this.loader = false;
              } else {
                this.notifyService.showError("Map Data Not Available", "Error");
                //this.loader = false;
              }
            } else {
              this.notifyService.showError("Map Data Not Available", "Error");
              //this.loader = false;
            }
          }, (error) => {
            const errors = error;
            ////this.loader = false;;
          });
        } catch (e) {
          this.notifyService.showError("Something Error!!", "Error");
          //this.loader = false;
        } finally {
          //this.loader = false;
        }
      }
    }
    if (layertype == "Range") {
      if (queryrange != "") {
        let formdata: FormData = new FormData();
        formdata.append('data', queryrange);
        try {
          this.apiService.requestGeoJson(layername_range, formdata).subscribe((response: any[]) => {
            if (response) {
              if (response["status"] == 1) {
                if (this.vectorparcelextendglobal_range) {
                  this.map.removeLayer(this.vectorparcelextendglobal_range);
                }
                const styleFunction = [
                  new Style({
                    stroke: new Stroke({
                      color: 'rgba(255, 165, 0, 2.5)',
                      width: 2,
                    }),
                    fill: new Fill({
                      color: 'rgba(255, 165, 0, 0.1)',
                    }),
                  })];
                  const vectorSource = new VectorSource();
                  var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
                  var gjFormat = new GeoJSON({
                    featureProjection: 'EPSG:3857',
                  });
                  var features = gjFormat.readFeatures(geojson);
                  vectorSource.clear();
                  var featureOverlayNew = new VectorLayer({
                   // map: this.map,
                    source: vectorSource,
                    style: styleFunction,
                    updateWhileAnimating: true,
                    updateWhileInteracting: true
                  });
                  featureOverlayNew.getSource().addFeatures(features);
                  this.map.addLayer(featureOverlayNew);
                  this.map.getView().setZoom(10);
                  this.map.getView().fit(featureOverlayNew.getSource().getExtent(), "");
                   this.vectorparcelextendglobal_range = featureOverlayNew;

              } else {
                this.notifyService.showError("Map Data Not Available", "Error");
                //this.loader = false;
              }
            } else {
              this.notifyService.showError("Map Data Not Available", "Error");
              //this.loader = false;
            }
          }, (error) => {
            const errors = error;
            ////this.loader = false;;
          });
        } catch (e) {
          this.notifyService.showError("Something Error!!", "Error");
          //this.loader = false;
        } finally {
          //this.loader = false;
        }
      }

    }
  }
  getChartData() {
    let circle: any, div: any, range: any;
    if (this.circleSelected != "All") { circle = this.circleSelected; } else { circle = ''; }
    if (this.divisionSelected != "All") { div = this.divisionSelected; range = ''; } else { div = ''; }
    if (this.rangeSelected != "All") { range = this.rangeSelected; } else { range = ''; }
    this.apiService.request('GET', 'api/v1/IncidentReport/viewAllIncidentReport?circlreId=' + circle + '&divisionId=' + div + '&rangeId=' + range).subscribe((response: any) => {
      if (response) {
        this.drawtheChart(response);
      }
    });
  }
  drawtheChart(response) {
    (function ($) {
      google.charts.load('current', { packages: ['corechart', 'bar'] });
      google.charts.setOnLoadCallback(drawBasic);

      function drawBasic() {
        var arrValues = new Array();
        var iCnt = 0;
        $.each(response, function () {
          // POPULATE ARRAY WITH THE EXTRACTED DATA.
          arrValues.push([response[iCnt].name, Number(response[iCnt].countValue)]);
          iCnt += 1;
        });
        var fata = google.visualization.arrayToDataTable(arrValues, true);
        //console.warn(fata);
        var options = {
          title: 'Incident',
          chartArea: { width: '50%' }, colors: ['#00c0ef', '#DD4B39'],
        };
        if (fata.getNumberOfRows() == 0) {
          $("#verticalBarChartOne").html("Sorry, not info available")
        } else {
          var chart = new google.visualization.BarChart(document.getElementById('verticalBarChartOne'));
          chart.draw(fata, options);
        }
      }
    })(jQuery);
  }

}

