<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->

<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

        <h4 class="card-title">Control Room information</h4>

	   <table class="table table-bordered row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
          <thead>
              <tr>
                  <th width="5%">Sr. No.</th>
                  <th>Circle</th>
                  <th>Division</th>
                  <th>Control Room Number</th>
              </tr>
           </thead>
          <tbody>

               <tr *ngFor="let group of divisionList ; let i = index" [attr.data-index]="i">
                   <td>{{i+1}} </td>
                  <td>{{group.circleName}}</td>
                  <td>{{group.divisionName}}</td>
                  <td>{{group.controlRoomPHno}}</td>
              </tr> 
          </tbody>
        </table>

        <p class="text-danger mt-3"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>

