
<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

        <h4 class="card-title">Ecotourism</h4>

				<table class="table table-bordered row-border hover" datatable>
          <thead>
              <tr>
                  <th>Sl. No.</th>
                  <th>District</th>
                  <th>Forest Division</th>
                  <th>Destination</th>
                  <th>Location</th>
                  <th>Link</th>
              </tr>
           </thead>
          <tbody>
              <tr *ngFor="let group of data">
                  <td>{{group.slno}}</td>
                  <td>{{group.district}}</td>
                  <td>{{group.division}}</td>
                  <td>{{group.destination}}</td>
                  <td>{{group.location}}</td>
                  <td class="text-center"><a href="{{group.link}}" class="btn btn-sm btn-info" target="_blank"><i class="fa fa-link"></i></a></td>
              </tr>
          </tbody>
        </table>

        <p class="text-danger mt-3"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>
