<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<app-loader></app-loader>
<nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">

        <a id="sidebarCollapse" class="header-clk header-expand">
            <i class="fa fa-indent"></i>
        </a>


        <a [routerLink]="['/dashboard']" class="header-logo">
            <img src="assets/images/odishalogo-light.png" alt="logo" class="logo">
            <h1>Odisha Wildlife Organization </h1>
            <p>Official Website of PCCF (Wildlife) & Chief Wildlife Warden, Odisha</p>
        </a>

        <a class="header-clk d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-align-justify"></i>
        </a>

        <div class="collapse navbar-collapse top-dropdown" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto nav-flex-icons">
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle no-arrow" id="navbarDropdownMenuLink-11" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                        <i class="fa fa-bell"></i>
                        <span class="badge badge-warning">4</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-default noti" aria-labelledby="navbarDropdownMenuLink-11">
                        <a class="dropdown-item" href="#"><i class="fa fa-bell"></i>Lorem ipsum dolor sit amet consectetur</a>
                        <a class="dropdown-item" href="#"><i class="fa fa-bell"></i>Duis aute irure dolor in reprehenderit</a>
                        <a class="dropdown-item" href="#"><i class="fa fa-bell"></i>Excepteur sint</a>
                    </div>
                </li> -->
                <li>
                    <a class="btn btn-sm btn-light btn-rounded mt-2 mr-2 pl-3 pr-3" href="https://odishaforestgis.in/ofms/" target="_blank">Goto OFMS</a>
                </li>
                <li class="" id="">
                    <a href="https://odishaforestgis.in/ofms_map_comparison/forest_fire_map.html" target="_blank">
                      <img src="assets/images/fire_mapper.png" class="img-fluid" height="37px" class="mt-1">
                    </a>
                  </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink-22" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <img [src]="retrievedImage" (error)="defaultUrl()" class="profile">

                        <!-- Tarini Prasad -->{{profileFirstName}} {{profilelastName}}
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-default" aria-labelledby="navbarDropdownMenuLink-22">
                        <a class="dropdown-item" [routerLink]="['/profile']"><i class="fa fa-cog"></i>Profile Settings</a>
                        <a class="dropdown-item" [routerLink]="['/changepassword']"><i class="fa fa-key"></i>Change Password</a>
                        <!-- <a class="dropdown-item video-btn" href="['/']" data-toggle="modal" data-src="https://www.youtube.com/embed/Jfrjeg26Cwk" data-target="#myModal"><i class="fa fa-info-circle"></i>Help</a> -->
                        <a href="javascript:void(0)" class="dropdown-item"  (click)="onLogout()"><i class="fa fa-power-off"></i>Logout</a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>


<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video"  allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>




