
<section>
  <div class="acme-news-ticker">
    <div class="acme-news-ticker-label">News Updates</div>
    <div class="acme-news-ticker-box">
      <marquee behavior="scroll" direction="left" scrollamount="8" onmouseover="this.stop();" onmouseout="this.start();">
        <span [innerHTML]="NewsList"></span>
      </marquee>
      <img src="assets/images/orsac-logo.png" alt="image" class="orsac-logo">
    </div>
  </div>

    <!-- <div class="acme-news-ticker">
        <div class="acme-news-ticker-label">News Updates</div>

        <div class="acme-news-ticker-box" >
             <span [innerHTML]="NewsList"></span> -->
            <!-- <ul class="my-news-ticker-2" [style.width.vw]="100">
               <li  *ngFor="let newsItem of NewsList"  [innerHTML]="newsItem.content"  [ngStyle]="{'display': 'inline-block', 'margin-right':'10px', 'float':'left' }"  >
                </li> -->

                 <!-- <li *ngFor="let newsItem of NewsList">{{newsItem.content}}</li> style="display:inline-block;margin-right:10px;float:left;"-->
                  <!-- <li >
                    Visitors are requested to visit Eco-Tourism website <a href="https://www.ecotourodisha.com" target="_blank">www.ecotourodisha.com</a> for reservation of accomodation in different eco-tourism destinations of odisha.<a href="https://www.ecotourodisha.com/exploreecotourism.php" target="_blank"> Bhitarkanika Nature Camps,Similipal Nature Camps</a> for more destinations<a href="https://www.ecotourodisha.com/ecotourism.php"> Click here</a>
                </li>
                <li >
                  Bhitarkanika Nature Camps,Similipal Nature Camps for more destinations<a href="https://www.ecotourodisha.com/ecotourism.php"> Click here</a>
              </li> -->

            <!-- </ul> -->

        <!-- </div>
        <div class="acme-news-ticker-controls acme-news-ticker-horizontal-controls">
            <span class="acme-news-ticker-pause"></span>
        </div>
        <img src="assets/images/orsac-logo.png" alt="image" class="orsac-logo">
    </div> -->
</section>
