import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../service/api.service';


@Component({
  selector: 'app-controlroominfo',
  templateUrl: './controlroominfo.component.html',
  styleUrls: ['./controlroominfo.component.css']
})
export class ControlroominfoComponent implements OnInit {
 // public loader: boolean = false;
  divisionList:any[];

  constructor(private apiService: ApiService) { }

  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {};

  ngOnInit(): void {
    
  	this.dtOptions = {
      responsive: true,
      pagingType: 'full_numbers',
      pageLength: 20,
      processing: true,
      dom: 'Bfrtip',
        buttons: [
            //'copy', 'excel', 'pdf', 'print'
        ]
    };

  	(function($){



    })(jQuery);
    this.getDivisionInfoList();
  }
  getDivisionInfoList() {
    try{
      //this.loader = true;
    this.apiService.request('GET', 'api/v1/cms/getDivisionInfoList').subscribe((data: any) => {
      if (data) {
        let division = new Array();
        data.map(item => {
          return {
            circleName: item['circle']['circleName'],
            divisionName: item['divisionName'],
            controlRoomPHno: item['controlRoomPHno'],
          }
        }).forEach(item => division.push(item));
        this.divisionList=new Array();
        this.divisionList=division.sort((a,b) => 
        (a.circleName.toLowerCase() < b.circleName.toLowerCase()) ? -1 : 
        ((b.circleName.toLowerCase() > a.circleName.toLowerCase()) ? 1 : 0));

     
        this.dtTrigger.next();
       // this.loader = false;
      }
    },err=>{
      //this.loader = false;
    });
  }catch(e){
   // this.loader = false;
  }
  }

}




