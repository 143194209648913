<app-header></app-header>
<app-menu></app-menu>
<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

        <img class="img-fluid mx-auto d-block" src="assets/images/Organogram.jpg" alt="image">

        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th width="5%">Sl. No.</th>
                <th>Circle</th>
                <th>Wildlife Division</th>
                <th>Forest Division</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>FD, STR - cum - R.C.C.F., Baripada</td>
                <td>DFO Balasore(WL), DFO Keonjhar (WL)</td>
                <td>Dy. Dir. Similipal North, Dy. Dir. Similipal South, DFO Balasore (WL), DFO Baripada, DFO Karanjia, DFO Rairangpur, DFO Keonjhar (WL)</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Director Nandankanan</td>
                <td>Dy. Director, Nandankanan Zoological Park (Nandankanan Sanctuary)</td>
                <td></td>
              </tr>
              <tr>
                <td>3</td>
                <td>R.C.C.F, Bhubaneswar</td>
                <td>DFO (WL), Chandaka, DFO (WL), Chilika, DFO (WL),Mangrove Forest Division (WL), DFO (WL), Puri, DFO (WL), Bhadrak</td>
                <td>DFO Bhadrak (WL), DFO Mangrove Forest Division (WL), DFO Puri (WL), DFO Chandaka (WL), DFO Chilika (WL), DFO Khurda, DFO Nayagarh, DFO City Forest</td>
              </tr>
              <tr>
                <td>4</td>
                <td>R.C.C.F, Berhampur</td>
                <td></td>
                <td>DFO Parlakhemundi, DFO Balliguda, DFO Boudh, DFO Ghumsur North, DFO Ghumsur South, DFO Phulbani, DFO Berhampur</td>
              </tr>
              <tr>
                <td>5</td>
                <td>R.C.C.F, Sambalpur</td>
                <td>DFO (WL), Hirakud, DFO (WL), Bamra</td>
                <td>DFO Hirakud (WL), DFO Bamra (WL), DFO Bargarh, DFO Jharsuguda, DFO Sambalpur, DFO Rairakhol</td>
              </tr>
              <tr>
                <td>6</td>
                <td>R.C.C.F, Bhawanipatna</td>
                <td>DFO (WL), Sunabeda</td>
                <td>DFO Sunabeda (WL), DFO Kalahandi South, DFO Kalahandi North, DFO Khariar, DFO Subarnapur, DFO Bolangir</td>
              </tr>
              <tr>
                <td>7</td>
                <td>R.C.C.F, Koraput</td>
                <td></td>
                <td>DFO Jeypore, DFO Koraput, DFO Rayagada, DFO Malkangiri, DFO Nabarangpur</td>
              </tr>
              <tr>
                <td>8</td>
                <td>R.C.C.F, Rourkela</td>
                <td>DFO (WL), Keonjhar (WL)</td>
                <td>DFO Bonai, DFO Keonjhar, DFO Deogarh, DFO Rourkela, DFO Sundargarh</td>
              </tr>
              <tr>
                <td>9</td>
                <td>FD, STR - cum - R.C.C.F., Angul</td>
                <td>DFO Satkosia (WL),DFO Mahanadi (WL)</td>
                <td>DFO Satkosia (WL), DFO Angul, DFO Athgarh, DFO Athmalik, DFO Cuttack, DFO Dhenkanal, DFO Mahanadi (WL)</td>
              </tr>
            </tbody>
          </table>
        </div>

        <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>

      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
