<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->

<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

				<h4 class="card-title">Publication</h4>

				<div class="row">
          <div class="col-md-2" *ngFor="let list of PubListArray">
            <a href="{{list.pdfPath}}" target="_blank">
              <div class="publication">
                <img src={{list.thumbnilImgPath}} (error)="defaultUrl($event)"  class="img-fluid" alt="image">
                <h4 class="title">{{list.publicationTitle}}</h4>
              </div>
            </a>
          </div>
					<!-- <div class="col-md-2">
						<a href="assets/files/WildlifeOdisha2015.pdf" target="_blank">
							<div class="publication">
								<img src="assets/images/WO2015.JPG" class="img-fluid" alt="image">
								<h4 class="title">Wildlife Odisha - 2015</h4>
							</div>
						</a>
					</div>
					<div class="col-md-2">
						<a href="assets/files/WildlifeOdisha2016.pdf" target="_blank">
							<div class="publication">
								<img src="assets/images/WO2016.JPG" class="img-fluid" alt="image">
								<h4 class="title">Wildlife Odisha - 2016</h4>
							</div>
						</a>
					</div>
					<div class="col-md-2">
						<a href="assets/files/DolphinsOdisha.pdf" target="_blank">
							<div class="publication">
								<img src="assets/images/DolphinsOdisha.jpg" class="img-fluid" alt="image">
								<h4 class="title">Whales, Dolphins & Porpoise of Odisha</h4>
							</div>
						</a>
					</div>
					<div class="col-md-2">
						<a href="assets/files/ThreatendedBird.pdf" target="_blank">
							<div class="publication">
								<img src="assets/images/ThreatendedBird.jpg" class="img-fluid" alt="image">
								<h4 class="title">Threatended Birds of Odisha</h4>
							</div>
						</a>
					</div>
					<div class="col-md-2">
						<a href="assets/files/Amphibians.pdf" target="_blank">
							<div class="publication">
								<img src="assets/images/Amphibian.jpg" class="img-fluid" alt="image">
								<h4 class="title">Amphibians of Nanadankanan</h4>
							</div>
						</a>
					</div>
					<div class="col-md-2">
						<a href="assets/files/Spiders.pdf" target="_blank">
							<div class="publication">
								<img src="assets/images/Spider.jpg" class="img-fluid" alt="image">
								<h4 class="title">Spiders of Nanadankanan</h4>
							</div>
						</a>
					</div>
					<div class="col-md-2">
						<a href="assets/files/SOP.pdf" target="_blank">
							<div class="publication">
								<img src="assets/images/SOP.jpg" class="img-fluid" alt="image">
								<h4 class="title">Standard Operating Procedure</h4>
							</div>
						</a>
					</div>
					<div class="col-md-2">
						<a href="assets/files/ZoosOfOdisha.pdf" target="_blank">
							<div class="publication">
								<img src="assets/images/ZoosOfOdisha.jpg" class="img-fluid" alt="image">
								<h4 class="title">Zoos of Orissa</h4>
							</div>
						</a>
					</div>
					<div class="col-md-2">
						<a href="assets/files/WL_Odisha_2019.pdf" target="_blank">
							<div class="publication">
								<img src="assets/images/WildlifeOdisha2019.jpg" class="img-fluid" alt="image">
								<h4 class="title">Wildlife Odisha - 2019</h4>
							</div>
						</a>
					</div>
					<div class="col-md-2">
						<a href="assets/files/Wildlife_Odisha_2020.pdf" target="_blank">
							<div class="publication">
								<img src="assets/images/WildLIfeOdishaPublication_2020.png" class="img-fluid" alt="image">
								<h4 class="title">Wildlife Odisha - 2020</h4>
							</div>
						</a>
					</div> -->
				</div>

				<p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>
