import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';

declare var jQuery: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


  constructor(private router: Router, private apiService: ApiService){}

  ngOnInit(): void {
    const userdata=sessionStorage.getItem('user');
    if(userdata!=null){
		this.router.navigate(['/dashboard']);
       }
  	(function($){



      })(jQuery)

  }

}
