import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
declare var jQuery: any;

@Component({
  selector: 'app-slidernews',
  templateUrl: './slidernews.component.html',
  styleUrls: ['./slidernews.component.css']
})
export class SlidernewsComponent implements OnInit {
  NewsList: any;

  constructor(private apiService: ApiService) { }


  ngOnInit(): void {
  	(function($){
      $('.my-news-ticker-2').AcmeTicker({
            type:'marquee',
            direction: 'left',
            speed: 0.05,
            controls: {
                toggle: $('.acme-news-ticker-pause'),
            }
        });
    })(jQuery);
    this.loadnews();
  }
  loadnews(){
    this.apiService.request('GET', 'api/v1/cms/getAllNews').subscribe((data: any) => {
      if (data) {
        this.NewsList=data.message;
        // data.map(item => {
        //   return {
        //     message:item.content
        //   }
        // }).forEach(item => list.push(item));
        // console.log(list);
        // var newsString="";
        // data.forEach(element => {
        //     if(newsString!="") {
        //       newsString=newsString+"|"+element.content;
        //     }else {
        //       newsString=element.content;
        //     }
        //     newsString.replace(/(\r\n|\n|\r)/gm, "");
        //   // if(this.NewsList!=null){
        //   //   this.NewsList=this.NewsList+element.content
        //   // }else{
        //   //   this.NewsList=element.content
        //   // }
        // });
        // console.log(newsString);
        // this.NewsList=newsString;

       // var CSVOf_arr = data.map((item) => { return item.content }).join(',')getAllNews
       // this.NewsList= Array.prototype.map.call(data, s => s.content).toString(); // "A,B,C"
//         data.map(item=>{
//           return {
//             content: this.joinnews(item['content'])

//           }
//         }).forEach(item=> this.NewsList.push(item));

//        // this.NewsList=data;

 //console.log(this.NewsList)
      }
    });
  }
  joinnews(news: any) {

    var newscontent;
    if(newscontent!=""){
      newscontent=newscontent+','+news;
    }else{
      newscontent=news;
    }
    return newscontent;
  }

}


