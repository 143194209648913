<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

        <h4 class="card-title">In News</h4>

        <!-- <div class="form-group row">
          <div class="col-md-2 mt-2">
            <label>Select Year :</label>
          </div>
          <div class="col-md-3">
            <select class="form-control">
              <option value="" selected="selected">All</option>
              <option value="">2021</option>
              <option value="">2020</option>
              <option value="">2019</option>
              <option value="">2018</option>
              <option value="">2017</option>
            </select>
          </div>
          <div class="col-md-2 mt-2">
            <label>Select Maonth :</label>
          </div>
          <div class="col-md-3">
            <select class="form-control">
              <option value="" selected="selected">All</option>
              <option value="">January</option>
              <option value="">February</option>
              <option value="">March</option>
              <option value="">April</option>
              <option value="">May</option>
              <option value="">June</option>
              <option value="">July</option>
              <option value="">August</option>
              <option value="">September</option>
              <option value="">October</option>
              <option value="">November</option>
              <option value="">December</option>
            </select>
          </div>
          <div class="col-md-2">
            <button type="submit" class="btn btn-success">Search</button>
          </div>
        </div>

        <hr> -->

				<table class="table table-bordered row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
          <thead>
              <tr>
                  <th>Sl. No.</th>
                  <th>Content</th>
                  <th>Date</th>
                  <!-- <th>View</th> -->
              </tr>
           </thead>
          <tbody>
            <tr *ngFor="let news of NewsList; let i = index" [attr.data-index]="i">
              <td>{{i+1}} </td>
              <td [innerHtml]="news.content | customHtmldecoderPipe"></td>
              <td>{{ (news.craetedOn  !== 'null') ? (news.craetedOn | date:'dd-MM-yyyy')  : '' }} {{ (news.craetedOn  !== 'null') ? (news.craetedOn  | date:'shortTime')  : '' }} </td>
                  <!-- <td>
                    <a href="#" target="_blank" class="btn btn-sm btn-success"><i class="fa fa-eye"></i></a>
                  </td> -->
              </tr>
          </tbody>
        </table>

        <p class="text-danger mt-3"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>
