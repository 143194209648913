import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from '../service/api.service';
import { NotificationService } from '../service/notification.service';
declare var jQuery: any;

@Component({
  selector: 'app-feedbackview',
  templateUrl: './feedbackview.component.html',
  styleUrls: ['./feedbackview.component.css']
})
export class FeedbackviewComponent implements OnInit {
  feedbacklist: any;
  userid: any;
  public loader: boolean=false;

  constructor(private notifyService:NotificationService, private apiService: ApiService) { }


  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {};

  ngOnInit(): void {

  	(function($){

      	$('a[data-toggle="tab"]').on('shown.bs.tab', function(e){
	    $($.fn.dataTable.tables(true)).DataTable()
	        .columns.adjust()
	        .responsive.recalc();
	    });

    })(jQuery);
    this.dtOptions = {
      // pagingType: 'full_numbers',
      // pageLength: 5,
      // lengthMenu : [5, 10, 25],
      // processing: true,
      // responsive: true,
      dom: 'Bfrtip',
         buttons: [
             'copy', 'excel', 'pdf', 'print'
         ]
    };
    this.getAllfeedback();

  }
  getAllfeedback(){
    this.loader=true;
    this.apiService.request('GET', 'api/v1/cms/getAllFeedback').subscribe((data: any) => {
	    if(data)
	    {
        $("#tableFeedback").DataTable().destroy();
        this.feedbacklist=data;
        this.dtTrigger.next();
        this.loader=false;
      }
    });
  }
  achieved(fid:any){
    const userdata=sessionStorage.getItem('user');
    //console.warn(userdata)
    if(userdata!=null){
      const user=JSON.parse(userdata);
      this.userid=user.userid;
    this.apiService.request('PUT', 'api/v1/cms/updateFeedbackStatus?userId='+this.userid+'&feedbackId='+fid).subscribe((res: any) => {
	    if(res)
	    {
        this.notifyService.showSuccess("Archived","Success");
        this.getAllfeedback();
      }
     });
    }
  }

}
