import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { AuthService } from '../service/auth.service';
import { NotificationService } from '../service/notification.service';
declare var jQuery: any;

@Component({
  selector: 'app-mpr',
  templateUrl: './mpr.component.html',
  styleUrls: ['./mpr.component.css']
})
export class MprComponent implements OnInit {
  circleList: any;
  divisionlist: any;
  public circleid: string;
  public divid: string;

  public circleSelected = 'All';
  public divisionSelected = 'All';

  MPR_D1  : FormGroup;
  MPR_D2: FormGroup;
  MPR_D10: FormGroup;
  MPR_D9: FormGroup;
  MPR_D8: FormGroup;
  MPR_D7: FormGroup;
  MPR_D6: FormGroup;
  MPR_D5: FormGroup;
  MPR_D4: FormGroup;
  MPR_D3: FormGroup;

  constructor(private formBuilder: FormBuilder,private notifyService: NotificationService,private apiService: ApiService, private authService: AuthService) { }

  ngOnInit(): void {

    (function($){
      
    })(jQuery);

    this.getAllCircle();
    this.circleid = "0";
    this.divid = "0";
    this.MPR_D1 = this.formBuilder.group({
      circle:['0'],
      division:['0']
    });
    this.MPR_D2 = this.formBuilder.group({
      circle:['0'],
      division:['0']
    });
    this.MPR_D3 = this.formBuilder.group({
      circle:['0'],
      division:['0']
    });
    this.MPR_D4 = this.formBuilder.group({
      circle:['0'],
      division:['0']
    });
    this.MPR_D5 = this.formBuilder.group({
      circle:['0'],
      division:['0']
    });
    this.MPR_D6 = this.formBuilder.group({
      circle:['0'],
      division:['0']
    });
    this.MPR_D7 = this.formBuilder.group({
      circle:['0'],
      division:['0']
    });
    this.MPR_D8 = this.formBuilder.group({
      circle:['0'],
      division:['0']
    });
    this.MPR_D9 = this.formBuilder.group({
      circle:['0'],
      division:['0']
    });
    this.MPR_D10 = this.formBuilder.group({
      circle:['0'],
      division:['0']
    });

    this.isCircleDivisionRangeDisabled();
  }
  isCircleDivisionRangeDisabled() {
    const userdata = sessionStorage.getItem('user');
    if (userdata != null) {
      const user = JSON.parse(userdata);
       if (user.roleId == 2) {
        this.apiService.request('GET', 'api/v1/masters/getAssignCircleDivisionRangeByUserId?roleId=' + user.roleId + '&jurdicitionId=' +user.juridictionId).subscribe((data: any) => {
          if (data) {
            this.circleid = data.circleId
            this.circleSelected = data.circleId;
            this.MPR_D1.get('circle').disable();
            this.MPR_D2.get('circle').disable();
            this.MPR_D3.get('circle').disable();
            this.MPR_D4.get('circle').disable();
            this.MPR_D5.get('circle').disable();
            this.MPR_D6.get('circle').disable();
            this.MPR_D7.get('circle').disable();
            this.MPR_D8.get('circle').disable();
            this.MPR_D9.get('circle').disable();
            this.MPR_D10.get('circle').disable();
            this.getAllDivisionByCircleId(data.circleId);
            this.divid = data.divisonId;
            this.divisionSelected = data.divisonId;
            this.MPR_D1.get('division').disable();
            this.MPR_D2.get('division').disable();
            this.MPR_D3.get('division').disable();
            this.MPR_D4.get('division').disable();
            this.MPR_D5.get('division').disable();
            this.MPR_D6.get('division').disable();
            this.MPR_D7.get('division').disable();
            this.MPR_D8.get('division').disable();
            this.MPR_D9.get('division').disable();
            this.MPR_D10.get('division').disable();
          }
        });
      }
    } else {
      this.authService.logout();
    }
  }
  getAllCircle() {
    return this.apiService.request('GET', 'api/v1/masters/getAllCircle').subscribe((data: any) => {
      if (data) {
        this.circleList = data;
      }
    });
  }
  getAllDivisionByCircleId(value:any) {
    if (value != '0') {
      return this.apiService.request('GET', 'api/v1/masters/getAllDivisionByCircleId?id=' + value).subscribe((data: any) => {
        if (data) {
          this.divisionlist = data;
        }
      });
    }
  }
  onSubmit_MPR_D1_form(value:any){

  }
  onSubmit_MPR_D2_form(value:any){

  }
  onSubmit_MPR_D3_form(value:any){

  }
  onSubmit_MPR_D4_form(value:any){

  }
  onSubmit_MPR_D5_form(value:any){

  }
  onSubmit_MPR_D6_form(value:any){

  }
  onSubmit_MPR_D7_form(value:any){

  }
  onSubmit_MPR_D8_form(value:any){

  }
  onSubmit_MPR_D9_form(value:any){

  }
  onSubmit_MPR_D10_form(value:any){

  }

}
