<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->

<app-masterheader></app-masterheader>
<div class="wrapper">

    <app-sidebar></app-sidebar>

    <div id="content">

        <!-- <app-breadcrumb></app-breadcrumb> -->

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
									<h4 class="card-title">Map View</h4>
									<div class="row">
										<div class="form-group">
											<select class="form-control">
												<option>1</option>
												<option>2</option>
											</select>
										</div>
									</div>
                                    <div class="fixHeightLeft">
                                    	<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7671816.78977033!2d79.93986037750877!3d20.13386855460047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a226aece9af3bfd%3A0x133625caa9cea81f!2sOdisha!5e0!3m2!1sen!2sin!4v1614253585320!5m2!1sen!2sin" width="100%" height="100%" class="map fixHeightLeft" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
									<h4 class="card-title">Map View</h4>
                                    <div class="fixHeightLeft">
                                    	<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7671816.78977033!2d79.93986037750877!3d20.13386855460047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a226aece9af3bfd%3A0x133625caa9cea81f!2sOdisha!5e0!3m2!1sen!2sin!4v1614253585320!5m2!1sen!2sin" width="100%" height="100%" class="map fixHeightLeft" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button id="queryBtn" class="btn btn-sm btn-query"><i class="fa fa-chevron-left rotate"></i></button>
						<div id="queryContent" class="query-content col-md-4" style="display:none">

							<div class="row">
							  	<div class="col-md-12">
							  		<div class="card">
							  			<div class="card-body">

											<ul id="tabs" class="nav nav-tabs" role="tablist">

												<li class="nav-item">
													<a id="tab-A" href="#pane-A" class="nav-link active" data-toggle="tab" role="tab">Layers</a>
												</li>
												<li class="nav-item">
													<a id="tab-B" href="#pane-B" class="nav-link " data-toggle="tab" role="tab">Legends</a>
												</li>
												<li class="nav-item">
													<a id="tab-C" href="#pane-C" class="nav-link" data-toggle="tab" role="tab">Basemap</a>
												</li>
											</ul>

											<div id="accordion" class="tab-content" role="tablist">
												<div id="pane-A" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-A">
													<div id="collapse-A" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-A">
														<div class="card-body">
															<div class="page-title mt-2">
																<h6>Layers</h6>
																<span></span>
															</div>
															dgdsgdgdsg
														</div>
													</div>
												</div>
												<div id="pane-B" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-B">
													<div id="collapse-B" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-B">
														<div class="card-body">
															<div class="page-title mt-2">
																<h6>Legends</h6>
																<span></span>
															</div>
															frgdrgdrg
														</div>
													</div>
												</div>
												<div id="pane-C" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-C">
													<div id="collapse-C" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-C">
														<div class="card-body">
															<div class="page-title mt-2">
																<h6>Basemap</h6>
																<span></span>
															</div>
															rdgrgdgr
														</div>
													</div>
												</div>
											</div>


									  	</div>
									</div>
							  	</div>

							</div>
						</div>
                    </div>

                </div>
            </div>
        </div>

    </div>

    <div id="footer">
        <app-masterfooter></app-masterfooter>
    </div>

</div>

