import { Component, OnInit } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-headerlanding',
  templateUrl: './headerlanding.component.html',
  styleUrls: ['./headerlanding.component.css']
})
export class HeaderlandingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    (function($){



    })(jQuery)

  }

}
