<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

				<h4 class="card-title">Info Dashboard</h4>

        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Description</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Wildlife Units</td>
                <td>18</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Protected Areas</td>
                <td>19</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Wildlife Census</td>
                <td>6875</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Corridors</td>
                <td>88</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Ecotourism</td>
                <td>47</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Important Circulars</td>
                <td>10</td>
              </tr>
            </tbody>
          </table>
        </div>

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>
