import { Component, OnInit } from '@angular/core';

declare var bootstrap: any;  // This allows us to use Bootstrap's modal API

@Component({
  selector: 'app-popupbanner',
  templateUrl: './popupbanner.component.html',
  styleUrls: ['./popupbanner.component.css']
})
export class PopupbannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.checkDate();
   
  }
  checkDate() {
    const today = new Date();  // Get today's date
    const targetDate = new Date('2025-03-31'); 
    
    if (today < targetDate) {
      const myModal = new bootstrap.Modal(document.getElementById('initialPopup'));
      myModal.show();
    } else {
      const myModal = new bootstrap.Modal(document.getElementById('initialPopup'));
      myModal.hide();
    }
  }
  closeModal() {
    const myModal = new bootstrap.Modal(document.getElementById('initialPopup'));
    myModal.hide();
  }

}
