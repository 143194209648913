<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <ul id="tabs" class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a id="tab-sunabedaPAmap" href="#pane-sunabedaPAmap" class="nav-link active" data-toggle="tab"
              role="tab">Map</a>
          </li>
          <li class="nav-item">
            <a id="tab-sunabedaPAinfo" href="#pane-sunabedaPAinfo" class="nav-link" data-toggle="tab"
              role="tab">Information</a>
          </li>
        </ul>

        <div id="accordion" class="tab-content" role="tablist">
          <div id="pane-sunabedaPAmap" class="card tab-pane fade show active" role="tabpanel"
            aria-labelledby="tab-sunabedaPAmap">
            <div class="card-header" role="tab" id="heading-sunabedaPAmap">
              <h5 class="mb-0">
                <a data-toggle="collapse" href="#collapse-sunabedaPAmap" aria-expanded="true"
                  aria-controls="collapse-sunabedaPAmap">
                  Map
                </a>
              </h5>
            </div>
            <div id="collapse-sunabedaPAmap" class="collapse show" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-sunabedaPAmap">
              <div class="card-body">
                <div class="map" id="mapsunabeda">
                  <div class="gle-icon">
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid"
                        alt="Street View" title="Street View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()'
                        alt="Satellite View" title="Satellite View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View"
                        title="Topo View">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="pane-sunabedaPAinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-sunabedaPAinfo">
            <div class="card-header" role="tab" id="heading-sunabedaPAinfo">
              <h5 class="mb-0">
                <a class="collapsed" data-toggle="collapse" href="#collapse-sunabedaPAinfo" aria-expanded="false"
                  aria-controls="collapse-sunabedaPAinfo">
                  Information
                </a>
              </h5>
            </div>
            <div id="collapse-sunabedaPAinfo" class="collapse" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-sunabedaPAinfo">

              <div class="card-body">

                <h4 class="card-title">
                  Sunabeda Sanctuary
                  <span class="custom-down">
                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i
                        class="fa fa-file-pdf">&nbsp;</i>Information</button>
                        <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                  </span>
                </h4>
                <div id="printcontent">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td><strong>Forest Division : </strong></td>
                              <td>Sunabeda (Wildlife) Division</td>
                            </tr>
                            <tr>
                              <td><strong>District : </strong></td>
                              <td>Nuapada</td>
                            </tr>
                            <tr>
                              <td><strong>Date of Notification : </strong></td>
                              <td>10-05-1988</td>
                            </tr>
                            <tr>
                              <td><strong>Notified Area : </strong></td>
                              <td>600sq.km</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-2 video-box no-print">
                      <a href="#" class="video-btn" data-toggle="modal"
                        data-src="https://www.youtube.com/embed/_VRZ7uPf17s" data-target="#myModal">
                        <h4>Sunabeda Sanctuary</h4>
                        <div class="overlay">
                          <span class="icon">
                            <i class="fa fa-play-circle"></i>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="col-md-2">
                      <img src="assets/images/suna_PA.jpg" class="img-fluid float-right img-inner" alt="image">
                    </div>
                  </div>
                  <p>
                    Sunabeda Wildlife Sanctuary in Nuapada district is largely a grass-covered plateau adjoining Udanti
                    and Sitanadi Sanctuaries of Chhatisgarh State. The sanctuary is an attraction to eco-tourists
                    because of waterfalls, caves, archaeological sites at Giribaman, Jumlagarh Fort and Muraguda, and
                    the bird observation points at Jalki and Raital Bandh in Maraguda. Sunabeda is home to 4 tigers and
                    20 leopards alongwith other species like Hyena, Barking Deer, Chital, Gaur, Sambar, Sloth Bear, Hill
                    Myna, Pea Fowl, Partridge etc and a number of reptilian species also inhabit the sanctuary.
                  </p>
                  <h4 class="card-title">Quick Facts</h4>
                  <ul class="custom-list">
                    <li>Sunabeda Wildlife Sanctuary has an excellent bio-diversity and an unique eco-system.</li>
                    <li>It has the potential for great tourist attraction due to the wilderness in its natural
                      condition, waterfalls and historical monuments.</li>
                    <li>This Sanctuary, being the source of origin and catchment of Jonk, Sunder and Indra rivers, plays
                      an important role in socio-economic development of the locality.</li>
                    <li>The valley, below the Sunabeda Wildlife Sanctuary along the Jonk river, has brick structures
                      that date back to the time of the Koshala kingdom.</li>
                    <li>The sanctuary is an ideal habitat for Barasingha.</li>
                    <li>It forms a migration link for wild buffaloes across the two states of Odisha and Chhatisgarh.
                    </li>
                  </ul>
                  <h4 class="card-title">How to reach</h4>
                  <p class="address-inner">
                    <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>Sunabeda Wildlife Sanctuary is 30 KM away
                    from District Headquarters, Nuapada.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong> The nearest railway station is at
                    Nuapada Road on Raipur-Vijayanagaram line of East Coast railway.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong> Raipur airport is the nearest which is
                    150 km away and is connected with Delhi, Hyderabad, Kolkata, Mumbai, Bengaluru.
                  </p>
                  <h4 class="card-title">Facilities available</h4>
                  <ul class="custom-list">
                    <li>Watch tower is available for the visitors to observe wildlife.</li>
                    <li>Forest Rest Houses, Circuit Houses and Inspection Bungalows are available for the tourists.</li>
                  </ul>
                  <h4 class="card-title">Salient Detail</h4>
                  <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                      <tbody>
                        <tr>
                          <td width="15%"><strong>Geographic Extent : </strong></td>
                          <td width="85%" colspan="2">Longitude 82° 20’ 06” to 82° 34’ 42”(East) and Latitude 20° 24' to
                            20° 44'(North)</td>
                        </tr>
                        <tr>
                          <td><strong>Climate : </strong></td>
                          <td colspan="2">Annual Rainfall - vary from 1100 to 1500mm</td>
                        </tr>
                        <tr>
                          <td><strong>Villages :</strong></td>
                          <td colspan="2"> 52 villages (30 Revenue village + 22 other settlements)</td>
                        </tr>
                        <tr>
                          <td><strong>Contact officer : </strong></td>
                          <td>
                            <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest Officer
                            <br>
                            <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Sunabeda Wildlife
                            Division, Nuapda <br>
                            <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span> 06678 - 225372 <br>
                            <span class="address-inner"><i class="fa fa-fax">&nbsp;</i></span>06678 - 225372<br>
                            <span class="address-inner"><i class="fa fa-mobile">&nbsp;</i></span>9437966372<br>
                          </td>
                          <td>
                            <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Assistant Conservator of
                            Forests<br>
                            <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Sunabeda Wildlife
                            Division, Nuapada <br>
                            <span class="address-inner"><i class="fa fa-mobile">&nbsp;</i></span>9937527247<br>
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Best time to visit : </strong></td>
                          <td colspan="2">October- February</td>
                        </tr>
                        <tr>
                          <td><strong>Accommodation : </strong></td>
                          <td colspan="2">Inspection Bungalows of different Departments of the State Govt. in nearby
                            places like Khariar & Khariar Road.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
                      </strong>The information contained herein are provided solely for informational purposes only.The
                      content is dynamic and may undergo change from time to time. The snapshot of the content at a
                      particular time is not to be referred as a legally binding document.</small></p>
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
