<app-masterheader></app-masterheader>

<div class="wrapper">

	<app-sidebar></app-sidebar>

	<div id="content">

		<!-- <app-breadcrumb></app-breadcrumb> -->

		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12">

					<div class="row">
						<div class="col-md-12">
							<div class="card">
								<div class="card-body">
									<h4 class="card-title">  <i class="fa fa-paw" style="color: #28a745; margin-right: 8px;"></i> 
										Elephant Movement Record</h4>
									<ul id="tabs" class="nav nav-tabs" role="tablist">
										<!-- <li class="nav-item">
								            <a id="tab-A" href="#pane-A" class="nav-link active" data-toggle="tab" role="tab">Updated Elephant Movement Record</a>
								        </li> -->
										<li class="nav-item">
											<a id="tab-B" href="#pane-B" class="nav-link active custom-tab" data-toggle="tab"
												(click)="loadtable(0)" role="tab"> <i class="fas fa-paw"></i> Direct Elephant Movement Record</a>
										</li>
										<li class="nav-item">
											<a id="tab-C" href="#pane-C" class="nav-link custom-tab" data-toggle="tab"
												(click)="loadtable(1)" role="tab"> <i class="fas fa-route"></i>Indirect Elephant Movement Record</a>
										</li>
										<li class="nav-item">
											<a id="tab-D" href="#pane-D" class="nav-link custom-tab" data-toggle="tab"
												(click)="loadtable(2)" role="tab"> <i class="fas fa-exclamation-triangle"></i> Problematic Elephant</a>
										</li>
									</ul>


									<div id="accordion" class="tab-content" role="tablist">
										<!-- <div id="pane-A" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-A">
								            <div class="card-header" role="tab" id="heading-A">
								                <h5 class="mb-0">
								                    <a data-toggle="collapse" href="#collapse-A" aria-expanded="true" aria-controls="collapse-A">
								                        Updated Elephant Movement Record
								                    </a>
								                </h5>
								            </div>
								            <div id="collapse-A" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-A">
								                <div class="card-body">
				                                    <table id="example" class="table table-bordered" width="100%">
													    <thead>
													        <tr>
													            <th>Sl. No.</th>
													            <th>Division</th>
													            <th>Officer Name</th>
													            <th>Last Updated Date</th>
													        </tr>
													    </thead>

													    <tbody>
													        <tr>
													            <td>1</td>
													            <td>Angul</td>
													            <td>Angul Forest Division</td>
													            <td>16.10.2020</td>
													        </tr>
													        <tr>
													            <td>2</td>
													            <td>Angul</td>
													            <td>Angul DFO</td>
													            <td>16.10.2020</td>
													        </tr>
													        <tr>
													            <td>3</td>
													            <td>Athagarh</td>
													            <td>Athagarh Forest Division</td>
													            <td>15.10.2020</td>
													        </tr>
													        <tr>
													            <td>4</td>
													            <td>Athagarh</td>
													            <td>Athagarh Forest Division</td>
													            <td>15.10.2020</td>
													        </tr>
													        <tr>
													            <td>5</td>
													            <td>Balasore (Wildlife) Division</td>
													            <td>Balasore WL Division</td>
													            <td>12.10.2020</td>
													        </tr>
													        <tr>
													            <td>6</td>
													            <td>Baliguda (T) Forest Division</td>
													            <td>Baliguda (T) Forest Division</td>
													            <td>15.10.2020</td>
													        </tr>
													    </tbody>
													</table>
								                </div>
								            </div>
								        </div> -->

										<div id="pane-B" class="card tab-pane fade show active" role="tabpanel"
											aria-labelledby="tab-B">
											<div class="card-header" role="tab" id="heading-B">
												<h5 class="mb-0">
													<a class="collapsed" data-toggle="collapse" href="#collapse-B"
														aria-expanded="false" aria-controls="collapse-B">
														View Elephant Movement Record
													</a>
												</h5>
											</div>
											<!-- direct table -->
											<div id="collapse-B" class="collapse" data-parent="#accordion"
												role="tabpanel" aria-labelledby="heading-B">
												<div class="card-body">
													<form [formGroup]="directForm"
														(ngSubmit)="onSubmitdirectMovementRecord(directForm.value)">
														<div class="row">
															<div class="col-md-6">
																<div class="row">
																	<div class="col-md-3 form-group mb-1">
																		<label>Circle</label>
																		<select class="form-control" id="circlewl"
																			formControlName="circle"
																			(change)="getAllDivisionByCircleId(circleSelect.value)"
																			[(ngModel)]='circleid'
																			[disabled]="circleDisabled" #circleSelect>
																			<option value="0">All Circle</option>
																			<option *ngFor="let circle of circleList "
																				value={{circle.circleId}}>
																				{{circle.circleName}}
																			</option>
																		</select>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<label>Division</label>
																		<select class="form-control" id="divisionwl"
																			formControlName="division"
																			(change)="getAllRangeByDivId(divisionSelect.value)"
																			[(ngModel)]='divid'
																			[disabled]="divisionDisabled"
																			#divisionSelect>
																			<option value="0">All Division</option>
																			<option
																				*ngFor="let division of divisionlist "
																				value={{division.divisionId}}>
																				{{division.divisionName}}
																			</option>
																		</select>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<label>Range</label>
																		<select class="form-control" id="rangewl"
																			formControlName="range"
																			(change)="getAllSectionByRangeId(rangeSelect.value)"
																			[(ngModel)]='rangeid'
																			[disabled]="rangeDisabled" #rangeSelect>
																			<option value="0">All Range</option>
																			<option *ngFor="let range of rangelist "
																				value={{range.rangeId}}>
																				{{range.rangeName}}
																			</option>
																		</select>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<label>Section</label>
																		<select class="form-control" id="sectionwl"
																			formControlName="section"
																			(change)="getAllBeatBySectionId(sectionSelect.value)"
																			[(ngModel)]='sectionid' #sectionSelect>
																			<option value="0">All Section</option>
																			<option *ngFor="let section of sectionlist "
																				value={{section.secId}}>
																				{{section.secName}}
																			</option>
																		</select>
																	</div>
																</div>
															</div>
															<div class="col-md-6">
																<div class="row">
																	<div class="col-md-3 form-group mb-1">
																		<label>Beat</label>
																		<select class="form-control" id="beatwl"
																			formControlName="beat" [(ngModel)]='beatid'
																			#beatSelect>
																			<option value="0">All Beat</option>
																			<option *ngFor="let beat of beatlist "
																				value={{beat.beatId}}>
																				{{beat.beatName}}
																			</option>
																		</select>
																	</div>
																	<!-- <div class="row"> -->
																	<div class="col-md-3 form-group mb-1">
																		<label>From Date</label>
																		<div id="datepicker" class="input-group date"
																			data-date-format="dd-mm-yyyy"
																			title="From Date">
																			<input class="form-control"
																				formControlName="startDate" #sd
																				id="startDate" type="text" />
																			<span class="input-group-addon"><i
																					class="mdi mdi-calendar"></i></span>
																		</div>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<label>To Date</label>
																		<div id="datepicker2" class="input-group date"
																			data-date-format="dd-mm-yyyy"
																			title="To Date">
																			<input class="form-control"
																				formControlName="endDate" #ed
																				id="endDate" type="text" />
																			<span class="input-group-addon"><i
																					class="mdi mdi-calendar"></i></span>
																		</div>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<button type="submit"
																			class="btn btn-sm btn-success custom-btn-top mr-1">View</button>
																		<button type="submit"
																			class="btn btn-sm btn-info custom-btn-top"
																			data-toggle="modal"
																			data-target="#viewDirectMapViewModal"><i
																				class="fa fa-map">&nbsp;</i>Map
																			View</button>
																	</div>
																</div>
															</div>


															<!-- </div> -->
														</div>
													</form>
													<hr>
													<div class="table-responsive">
														<table id="direct" class="table table-bordered responsive"
															width="99%" datatable [dtOptions]="dtOptions"
															[dtTrigger]="dtTrigger">
															<thead>
																<tr>
																	<th class="all" width="2%">Sl. No.</th>
																	<th class="all">Division</th>
																	<th class="all">Range</th>
																	<th class="all">Section</th>
																	<th class="all">Beat</th>
																	<th class="all" width="15%">Date of Movement</th>
																	<th class="all">From Time</th>
																	<th class="all">To Time</th>
																	<th class="all">Lat</th>
																	<th class="all">Long</th>
																	<th class="all">Total</th>
																	<th class="all">Device</th>
																	<th class="all">Action</th>
																</tr>
															</thead>

															<tbody>
																<tr *ngFor="let element of tabledata1; let i = index"
																	[attr.data-index]="i">
																	<td>{{i+1}}</td>
																	<td>{{element.divisionName}}</td>
																	<td>{{element.rangeName}}</td>
																	<td>{{element.secName}}</td>
																	<td>{{element.beatName}}</td>
																	<td>{{(element.sightingDate !=='null') ? (element.sightingDate |date:'dd-MM-yyyy HH:mm a') : '' }} </td>
																	<td>{{(element.sightingTimefrom !=='null') ? (element.sightingTimefrom |date:'shortTime') : '' }} </td>
																	<td>{{(element.sightingTimeTo !=='null') ? (element.sightingTimeTo |date:'shortTime') : '' }} </td>
																	<td>{{element.latitude}}</td>
																	<td>{{element.longitude}}</td>
																	<td>{{element.total}}</td>
																	<td><span class="text-danger" title="Computer"
																			*ngIf="element.reportThrough=='web'"><i
																				class="fa fa-desktop"></i></span>
																		<span class="text-primary font-18 "
																			title="Mobile"
																			*ngIf="element.reportThrough=='mobile'"><i
																				class="fa fa-mobile"></i></span>
																	</td>
																	<td>
																		<div class="dropdown">
																			<button
																				class="btn btn-sm btn-info btn-icon btn-radius dropdown-toggle"
																				type="button" id="dropdownMenuButton"
																				data-toggle="dropdown"
																				aria-haspopup="true"
																				aria-expanded="false">
																				<i class="fa fa-cog"></i>
																			</button>
																			<div class="dropdown-menu"
																				aria-labelledby="dropdownMenuButton">
																				<a class="dropdown-item" href="javascript:void(0)" data-toggle="modal"
																				    data-target="#viewElephantMovementRecordModal"
																					(click)="getMovementRecordByID(element)"><i class="fa fa-eye" aria-hidden="true">&nbsp;</i>View</a>
																			    <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
																				   data-target="#mapElephantMovementDirectRecordModal" id="directbtn"
																				   (click)="zoomthepoint(element.latitude,element.longitude,element.divisionId)"><i class="fa fa-map-marker" aria-hidden="true">&nbsp;</i>Map View</a>
																			  <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
																				   data-target="#editElephantMovementRecordModal"  *ngIf="isAdmin"
																				   (click)="getMovementRecordByIDForEdit(element)"><i class="fa fa-edit" aria-hidden="true">&nbsp;</i>Edit</a>
																			<a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
																				   data-target="#deleteElephantMovementRecordModal"  id="directDeletebtn" *ngIf="isAdmin"
																				   (click)="setDeleteRecord(element.id,'Direct')"><i class="fa fa-trash" aria-hidden="true">&nbsp;</i>Delete</a>
																			</div>
																		</div>
																		<!-- <button class="btn btn-sm btn-success mr-1"
																			data-toggle="modal"
																			data-target="#viewElephantMovementRecordModal"
																			(click)="getMovementRecordByID(element)">
																			<i class="fa fa-eye"></i>
																		</button>
																		<button class="btn btn-sm btn-info mr-1"
																			data-toggle="modal" id="directbtn"
																			(click)="zoomthepoint(element.latitude,element.longitude,element.divisionId)"
																			data-target="#mapElephantMovementDirectRecordModal"><i
																				class="fa fa-map-marker"
																				aria-hidden="true"></i>
																		</button>
																		<button class="btn btn-sm btn-secondary mr-1"
																			data-toggle="modal"
																			data-target="#viewElephantMovementRecordModal"
																			*ngIf="isAdmin"
																			(click)="getMovementRecordByID(element)">
																			<i class="fa fa-edit"></i>
																		</button>
																		<button class="btn btn-sm btn-danger mr-1"
																			data-toggle="modal"
																			data-target="#deleteElephantMovementRecordModal"
																			id="directDeletebtn" *ngIf="isAdmin"
																			(click)="setDeleteRecord(element.id,'Direct')">
																			<i class="fa fa-trash"
																				aria-hidden="true"></i>
																		</button> -->
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
										<div id="pane-C" class="card tab-pane fade" role="tabpanel"
											aria-labelledby="tab-C">
											<div class="card-header" role="tab" id="heading-C">
												<h5 class="mb-0">
													<a class="collapsed" data-toggle="collapse" href="#collapse-C"
														aria-expanded="false" aria-controls="collapse-C">
														Indirect Elephant Movement Record
													</a>
												</h5>
											</div>
											<div id="collapse-C" class="collapse" role="tabpanel"
												data-parent="#accordion" aria-labelledby="heading-C">
												<div class="card-body">
													<form [formGroup]="indirectForm"
														(ngSubmit)="onSubmitindirectMovementRecord(indirectForm.value)">
														<div class="row">
															<div class="col-md-6">
																<div class="row">
																	<div class="col-md-3 form-group mb-1">
																		<label>Circle</label>
																		<select class="form-control" id="circlewl_in"
																			formControlName="In_circle"
																			(change)="getAllDivisionByCircleId(circleSelect_in.value)"
																			[(ngModel)]='circleid_in'
																			[disabled]="circleDisabled"
																			#circleSelect_in>
																			<option value="0">All Circle</option>
																			<option *ngFor="let circle of circleList "
																				value={{circle.circleId}}>
																				{{circle.circleName}}
																			</option>
																		</select>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<label>Division</label>
																		<select class="form-control" id="divisionwl_in"
																			formControlName="In_division"
																			(change)="getAllRangeByDivId(divisionSelect_in.value)"
																			[(ngModel)]='divid_in'
																			[disabled]="divisionDisabled"
																			#divisionSelect_in>
																			<option value="0">All Division</option>
																			<option
																				*ngFor="let division of divisionlist "
																				value={{division.divisionId}}>
																				{{division.divisionName}}
																			</option>
																		</select>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<label>Range</label>
																		<select class="form-control" id="rangewl_in"
																			formControlName="In_range"
																			(change)="getAllSectionByRangeId(rangeSelect_in.value)"
																			[(ngModel)]='rangeid_in'
																			[disabled]="rangeDisabled" #rangeSelect_in>
																			<option value="0">All Range</option>
																			<option *ngFor="let range of rangelist "
																				value={{range.rangeId}}>
																				{{range.rangeName}}
																			</option>
																		</select>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<label>Section</label>
																		<select class="form-control" id="sectionwl_in"
																			formControlName="In_section"
																			(change)="getAllBeatBySectionId(sectionSelect_in.value)"
																			[(ngModel)]='sectionid_in'
																			#sectionSelect_in>
																			<option value="0">All Section</option>
																			<option *ngFor="let section of sectionlist "
																				value={{section.secId}}>
																				{{section.secName}}
																			</option>
																		</select>
																	</div>
																</div>
															</div>
															<div class="col-md-6">
																<div class="row">
																	<div class="col-md-3 form-group mb-1">
																		<label>Beat</label>
																		<select class="form-control" id="beatwl_in"
																			formControlName="In_beat"
																			[(ngModel)]='beatid_in' #beatSelect_in>
																			<option value="0">All Beat</option>
																			<option *ngFor="let beat of beatlist "
																				value={{beat.beatId}}>
																				{{beat.beatName}}
																			</option>
																		</select>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<label>From Date</label>
																		<div id="datepicker3" class="input-group date"
																			data-date-format="dd-mm-yyyy"
																			title="From Date">
																			<input class="form-control"
																				formControlName="In_startDate" #sd_in
																				id="startDate_in" type="text" />
																			<span class="input-group-addon"><i
																					class="mdi mdi-calendar"></i></span>
																		</div>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<label>To Date</label>
																		<div id="datepicker4" class="input-group date"
																			data-date-format="dd-mm-yyyy"
																			title="To Date">
																			<input class="form-control"
																				formControlName="In_endDate" #ed_in
																				id="endDate_in" type="text" />
																			<span class="input-group-addon"><i
																					class="mdi mdi-calendar"></i></span>
																		</div>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<button type="submit"
																			class="btn btn-sm btn-success custom-btn-top mr-1">View</button>
																		<button type="submit"
																			class="btn btn-sm btn-info custom-btn-top"
																			data-toggle="modal"
																			data-target="#viewInDirectMapViewModal"><i
																				class="fa fa-map">&nbsp;</i>Map
																			View</button>
																	</div>
																</div>
															</div>

														</div>
													</form>
													<hr>
													<div class="table-responsive">
														<table id="indirectTable"
															class="table table-bordered display responsive nowrap"
															width="98%" datatable [dtOptions]="dtOptions"
															[dtTrigger]="dtTrigger2">
															<thead>
																<tr>
																	<th class="all" width="2%">Sl. No.</th>
																	<th class="all">Division</th>
																	<th class="all">Range</th>
																	<th class="all">Section</th>
																	<th class="all">Beat</th>
																	<th class="all" width="15%">Date of Movement</th>
																	<th class="all">From Time</th>
																	<th class="all">To Time</th>
																	<th class="all">Lat</th>
																	<th class="all">Long</th>
																	<th class="all">Total</th>
																	<th class="all">Device</th>
																	<th class="all">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr
																	*ngFor="let element2 of tabledata2 ; let i = index;">
																	<td>{{i+1}}</td>
																	<td>{{element2.divisionName}}</td>
																	<td>{{element2.rangeName}}</td>
																	<td>{{element2.secName}}</td>
																	<td>{{element2.beatName}}</td>
																	<td>{{(element2.sightingDate !== 'null') ?(element2.sightingDate | date:'dd-MM-yyyy HH:mm a') : '' }} </td>
																	<td>{{(element2.sightingTimefrom !== 'null') ?(element2.sightingTimefrom | date:'shortTime') :'' }}</td>
																	<td>{{(element2.sightingTimeTo !== 'null') ?(element2.sightingTimeTo | date:'shortTime') :'' }}</td>
																	<td>{{element2.latitude}}</td>
																	<td>{{element2.longitude}}</td>
																	<td>{{element2.total}}</td>
																	<td><span class="text-danger" title="Computer"
																			*ngIf="element2.reportThrough=='web'"><i
																				class="fa fa-desktop"></i></span>
																		<span class="text-primary font-18 "
																			title="Mobile"
																			*ngIf="element2.reportThrough=='mobile'"><i
																				class="fa fa-mobile"></i></span>
																	</td>
																	<td>
																		<div class="dropdown">
																			<button
																				class="btn btn-sm btn-info btn-icon btn-radius dropdown-toggle"
																				type="button" id="dropdownMenuButton"
																				data-toggle="dropdown"
																				aria-haspopup="true"
																				aria-expanded="false">
																				<i class="fa fa-cog"></i>
																			</button>
																			<div class="dropdown-menu"
																				aria-labelledby="dropdownMenuButton">
																				<a class="dropdown-item" href="javascript:void(0)" data-toggle="modal"
																				    data-target="#indirectElephantMovementRecordModal"
																					(click)="getMovementRecordByID(element2)"><i class="fa fa-eye" aria-hidden="true">&nbsp;</i>View</a>
																			    <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
																				   data-target="#mapElephantMovementinDirectRecordModal" id="indirectbtn"
																				   (click)="zoomthepoint(element2.latitude,element2.longitude,element2.divisionId)"><i class="fa fa-map-marker" aria-hidden="true">&nbsp;</i>Map View</a>
																			  <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
																				   data-target="#editElephantMovementRecordModal"  *ngIf="isAdmin"
																				   (click)="getMovementRecordByIDForEdit(element2)"><i class="fa fa-edit" aria-hidden="true">&nbsp;</i>Edit</a>
																			<a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
																				   data-target="#deleteElephantMovementRecordModal"  id="indirectDeletebtn" *ngIf="isAdmin"
																				   (click)="setDeleteRecord(element2.id,'indirect')"><i class="fa fa-trash" aria-hidden="true">&nbsp;</i>Delete</a>
																			</div>
																		</div>
																		<!-- <button class="btn btn-sm btn-success mr-1"
																			data-toggle="modal"
																			data-target="#indirectElephantMovementRecordModal"
																			(click)="getMovementRecordByID(element2)"><i
																				class="fa fa-eye"></i></button>
																		<button class="btn btn-sm btn-info mr-1"
																			data-toggle="modal" id="indirectbtn"
																			(click)="zoomthepoint(element2.latitude,element2.longitude,element2.divisionId)"
																			data-target="#mapElephantMovementinDirectRecordModal"><i
																				class="fa fa-map-marker"
																				aria-hidden="true"></i></button>
																		<button class="btn btn-sm btn-danger mr-1"
																			data-toggle="modal"
																			data-target="#deleteElephantMovementRecordModal"
																			id="indirectDeletebtn" *ngIf="isAdmin"
																			(click)="setDeleteRecord(element2.id,'indirect')">
																			<i class="fa fa-trash"
																				aria-hidden="true"></i>
																		</button> -->
																		<!-- [disabled]="element2.reportThrough=='web'" -->
																		<!-- <button class="btn btn-sm btn-info mr-1"
																			data-toggle="modal"
																			data-target="#mapElephantMovementIndirectRecordModal"><i
																				class="fa fa-map-marker"></i></button> -->
																		<!-- (click)="inhisiatemap(element2.latitude,element2.longitude)" -->
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
										<div id="pane-D" class="card tab-pane fade" role="tabpanel"
											aria-labelledby="tab-D">
											<div class="card-header" role="tab" id="heading-D">
												<h5 class="mb-0">
													<a class="collapsed" data-toggle="collapse" href="#collapse-D"
														aria-expanded="false" aria-controls="collapse-D">
														Problematic Elephant Movement Reporting
													</a>
												</h5>
											</div>
											<div id="collapse-D" class="collapse" role="tabpanel"
												data-parent="#accordion" aria-labelledby="heading-D">
												<div class="card-body">
													<form [formGroup]="nillForm"
														(ngSubmit)="onSubmitNillMovementRecord(nillForm.value)">
														<div class="row">
															<div class="col-md-6">
																<div class="row">
																	<div class="col-md-3 form-group mb-1">
																		<label>Circle</label>
																		<select class="form-control" id="circlewl_nill"
																			formControlName="Nill_circle"
																			(change)="getAllDivisionByCircleId(circleSelect_nill.value)"
																			[(ngModel)]='circleid_nill'
																			[disabled]="circleDisabled"
																			#circleSelect_nill>
																			<option value="0">All Circle</option>
																			<option *ngFor="let circle of circleList "
																				value={{circle.circleId}}>
																				{{circle.circleName}}
																			</option>
																		</select>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<label>Division</label>
																		<select class="form-control"
																			id="divisionwl_nill"
																			formControlName="Nill_division"
																			(change)="getAllRangeByDivId(divisionSelect_nill.value)"
																			[(ngModel)]='divid_nill'
																			[disabled]="divisionDisabled"
																			#divisionSelect_nill>
																			<option value="0">All Division</option>
																			<option
																				*ngFor="let division of divisionlist "
																				value={{division.divisionId}}>
																				{{division.divisionName}}
																			</option>
																		</select>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<label>Range</label>
																		<select class="form-control" id="rangewl_nill"
																			formControlName="Nill_range"
																			(change)="getAllSectionByRangeId(rangeSelect_nill.value)"
																			[(ngModel)]='rangeid_nill'
																			[disabled]="rangeDisabled"
																			#rangeSelect_nill>
																			<option value="0">All Range</option>
																			<option *ngFor="let range of rangelist "
																				value={{range.rangeId}}>
																				{{range.rangeName}}
																			</option>
																		</select>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<label>Section</label>
																		<select class="form-control" id="sectionwl_nill"
																			formControlName="Nill_section"
																			(change)="getAllBeatBySectionId(sectionSelect_nill.value)"
																			[(ngModel)]='sectionid_nill'
																			#sectionSelect_nill>
																			<option value="0">All Section</option>
																			<option *ngFor="let section of sectionlist "
																				value={{section.secId}}>
																				{{section.secName}}
																			</option>
																		</select>
																	</div>
																</div>
															</div>
															<div class="col-md-6">
																<div class="row">
																	<div class="col-md-3 form-group mb-1">
																		<label>Beat</label>
																		<select class="form-control" id="beatwl_nill"
																			formControlName="Nill_beat"
																			[(ngModel)]='beatid_nill' #beatSelect_nill>
																			<option value="0">All Beat</option>
																			<option *ngFor="let beat of beatlist "
																				value={{beat.beatId}}>
																				{{beat.beatName}}
																			</option>
																		</select>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<label>From Date</label>
																		<div id="datepicker5" class="input-group date"
																			data-date-format="dd-mm-yyyy"
																			title="From Date">
																			<input class="form-control"
																				formControlName="Nill_startDate"
																				#sd_nill id="startDate_nill"
																				type="text" />
																			<span class="input-group-addon"><i
																					class="mdi mdi-calendar"></i></span>
																		</div>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<label>To Date</label>
																		<div id="datepicker6" class="input-group date"
																			data-date-format="dd-mm-yyyy"
																			title="To Date">
																			<input class="form-control"
																				formControlName="Nill_endDate" #ed_nill
																				id="endDate_nill" type="text" />
																			<span class="input-group-addon"><i
																					class="mdi mdi-calendar"></i></span>
																		</div>
																	</div>
																	<div class="col-md-3 form-group mb-1">
																		<button type="submit"
																			class="btn btn-sm btn-success custom-btn-top mr-1">View</button>
																			<button type="button"
																			class="btn btn-sm btn-info custom-btn-top"
																			data-toggle="modal"
																			data-target="#viewProblematicMapViewModal"><i
																				class="fa fa-map">&nbsp;</i>Map
																			View</button>
																	</div>
																</div>
															</div>
														</div>
													</form>
													<hr>
													<div class="table-responsive">
														<table id="NillTable"
															class="table table-bordered display responsive nowrap"
															width="99%" datatable [dtOptions]="dtOptions"
															[dtTrigger]="dtTrigger3">
															<thead>
																<tr>
																	<th class="all" width="2%">Sl. No.</th>
																	<th class="all">Division</th>
																	<th class="all">Range</th>
																	<th class="all">Section</th>
																	<th class="all">Beat</th>
																	<th class="all">Unique Id</th>
																	<th class="all">Reporting Date</th>
																	<th class="all">Reported By</th>
																	
																	<th class="all">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr
																	*ngFor="let element3 of tabledata3 ; let i = index;">
																	<td>{{i+1}}</td>
																	<td>{{element3.divisionName}}</td>
																	<td>{{element3.rangeName}}</td>
																	<td>{{element3.secName}}</td>
																	<td>{{element3.beatName}}</td>
																	<td>{{element3.uniqueId}}</td>	
																	<td>{{(element3.sightingDate !== 'null') ?(element3.sightingDate | date:'dd-MM-yyyy') : ''}} </td>
																	<td>{{element3.updatedBy}}</td>														
																	<td>
																		<div class="dropdown">
																			<button
																				class="btn btn-sm btn-info btn-icon btn-radius dropdown-toggle"
																				type="button" id="dropdownMenuButton"
																				data-toggle="dropdown"
																				aria-haspopup="true"
																				aria-expanded="false">
																				<i class="fa fa-cog"></i>
																			</button>
																			<div class="dropdown-menu"
																				aria-labelledby="dropdownMenuButton">
																				<a class="dropdown-item" href="javascript:void(0)" data-toggle="modal"
																				    data-target="#nillElephantMovementRecordModal"
																					(click)="getMovementRecordByID(element3)"><i class="fa fa-eye" aria-hidden="true">&nbsp;</i>View</a>
																					<a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
																					data-target="#mapElephantMovementinDirectRecordModal" id="indirectbtn"
																					(click)="zoomthepoint(element3.latitude,element3.longitude,element3.divisionId)"><i class="fa fa-map-marker" aria-hidden="true">&nbsp;</i>Map View</a>
																					<a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" 
																				   data-target="#deleteElephantMovementRecordModal"  id="directDeletebtn" *ngIf="isAdmin"
																				   (click)="setDeleteRecord(element3.id,'nill')"><i class="fa fa-trash" aria-hidden="true">&nbsp;</i>Delete</a>
																			</div>
																		</div>
																		</td>
																		<!-- <button class="btn btn-sm btn-success mr-1"
																			data-toggle="modal"
																			data-target="#nillElephantMovementRecordModal"
																			(click)="getMovementRecordByID(element3)"><i
																				class="fa fa-eye"></i></button>
																		<button class="btn btn-sm btn-danger mr-1"
																			data-toggle="modal"
																			data-target="#deleteElephantMovementRecordModal"
																			id="nillDeletebtn" *ngIf="isAdmin"
																			(click)="setDeleteRecord(element3.id,'nill')">
																			<i class="fa fa-trash"
																				aria-hidden="true"></i>
																		</button> -->
																		<!-- <button class="btn btn-sm btn-info mr-1"
					                                    data-toggle="modal"
					                                    data-target="#mapElephantMovementIndirectRecordModal"><i
					                                      class="fa fa-map-marker"></i></button> -->
																		<!-- (click)="inhisiatemap(element2.latitude,element2.longitude)" -->
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>



		<div class="modal fade gallery" id="viewElephantMovementRecordModal" tabindex="-1" role="dialog"
			aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="exampleModalLongTitle">View Direct Elephant Movement Record</h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="col-md-12 text-right mb-2">
							<button type="submit" printSectionId="printcontent_direct" [useExistingCss]="true"
								printTitle="Protected Area Info" ngxPrint class="btn btn-sm btn-info mr-1"><i
									class="fa fa-file-pdf">&nbsp;</i>Information</button>
						</div>
						<div class="ml-2 mr-2" id="printcontent_direct">
							<div class="row">
								<div class="col-md-6 border p-2"><strong>Date of Movement : </strong></div>
								<div class="col-md-6 border p-2">{{sightingDate | date:'dd-MMM-yyyy'}}
								</div>
								<div class="col-md-6 border p-2"><strong>Movement Time From: </strong></div>
								<div class="col-md-6 border p-2">{{sightingTimefrom | date:'shortTime'}}
								</div>
								<div class="col-md-6 border p-2"><strong>Movement Time To : </strong></div>
								<div class="col-md-6 border p-2">{{sightingTimeTo | date:'shortTime'}}
								</div>
								<div class="col-md-6 border p-2"><strong>Location (village, RF, PRF, etc...) : </strong>
								</div>
								<div class="col-md-6 border p-2">{{location}}</div>
								<div class="col-md-6 border p-2"><strong>Division : </strong></div>
								<div class="col-md-6 border p-2">{{divisionName}}</div>
								<div class="col-md-6 border p-2"><strong>Range : </strong></div>
								<div class="col-md-6 border p-2">{{rangeName}}</div>
								<div class="col-md-6 border p-2"><strong>Section : </strong></div>
								<div class="col-md-6 border p-2">{{secName}}</div>
								<div class="col-md-6 border p-2"><strong>Beat : </strong></div>
								<div class="col-md-6 border p-2">{{beatName}}</div>
								<div class="col-md-6 border p-2"><strong>No. of Elephants sighted : </strong></div>
								<div class="col-md-6 border p-2">{{(total !== 'null') ? total : 0 }}</div>
								<div class="col-md-6 border p-2"><strong>Herd : </strong></div>
								<div class="col-md-6 border p-2">{{(heard !== 'null') ? heard : 0 }}</div>
								<div class="col-md-6 border p-2"><strong>Calf : </strong></div>
								<div class="col-md-6 border p-2">{{(calf !== 'null') ? calf : 0 }}</div>
								<div class="col-md-6 border p-2"><strong>Female : </strong></div>
								<div class="col-md-6 border p-2">{{(female !== 'null') ? female : 0 }}
								</div>
								<div class="col-md-6 border p-2"><strong>Tusker : </strong></div>
								<div class="col-md-6 border p-2">{{(tusker !== 'null') ? tusker : 0 }}
								</div>
								<div class="col-md-6 border p-2"><strong>Tusker Adult : </strong></div>
								<div class="col-md-6 border p-2">{{(tuskerAdult !== 'null') ? tuskerAdult
									: 0 }}</div>
								<div class="col-md-6 border p-2"><strong>Tusker Sub Adult : </strong></div>
								<div class="col-md-6 border p-2">{{(tuskerSubAdult !== 'null') ?
									tuskerSubAdult : 0 }}</div>
								<div class="col-md-6 border p-2"><strong>Mukhna : </strong></div>
								<div class="col-md-6 border p-2">{{(mukhna !== 'null') ? mukhna : 0 }}
								</div>
								<div class="col-md-6 border p-2"><strong>Report Type : </strong></div>
								<div class="col-md-6 border p-2">{{reportType}}
									{{duplicateReport}} </div>
								<div class="col-md-6 border p-2"><strong> Report By : </strong></div>
								<div class="col-md-6 border p-2">{{(updatedBy !== 'null') ? updatedBy :
									''}}</div>
								<div class="col-md-6 border p-2"><strong>Latitude (N) : </strong></div>
								<div class="col-md-6 border p-2">{{latitude}}</div>
								<div class="col-md-6 border p-2"><strong>Langitude (N) : </strong></div>
								<div class="col-md-6 border p-2">{{longitude}}</div>
								<div class="col-md-6 border p-2"><strong>Remarks : </strong></div>
								<div class="col-md-6 border p-2">{{(remarks !== 'null') ? remarks : 'N/A'
									}}</div>
								<div class="col-md-6 border p-2"><strong>Evidence Photo : </strong></div>
								<div class="col-md-6 border p-2">
									<a href="{{imgAcqlocation}}" class="glightbox gallery_product filter elephant"
										data-fancybox="gallery" data-caption="">
										<img [src]="imgAcqlocation" *ngIf="imgAcqlocation" class="img-fluid mr-2 mb-2"
											style="height:85px" (error)="defaultUrl($event)" alt="">
										<div class="overlay">
											<span class="icon">
												<i class="fa fa-search"></i>
											</span>
										</div>
									</a>
								</div>
								<div class="col-md-6 border p-2"><strong>Selfie Photo With Staff: </strong></div>
								<div class="col-md-6 border p-2">
									<a href="{{selfieImglocation}}" class="glightbox gallery_product filter elephant"
										data-fancybox="gallery" data-caption="">
										<img [src]="selfieImglocation" *ngIf="selfieImglocation"
											class="img-fluid mr-2 mb-2" style="height:85px" (error)="defaultUrl($event)"
											alt="">
										<div class="overlay">
											<span class="icon">
												<i class="fa fa-search"></i>
											</span>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		
		<div class="modal fade gallery" id="indirectElephantMovementRecordModal" tabindex="-1" role="dialog"
			aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="exampleModalLongTitle">Indirect Elephant Movement Record</h4>
						<!-- <button  class="btn btn-sm btn-warning btn-modal-pdf" (click)="createPdf()" ><i class="fa fa-file-pdf-o"></i></button> -->
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="col-md-12 text-right mb-2">
							<button type="submit" printSectionId="printcontent_indirect" [useExistingCss]="true"
								printTitle="Protected Area Info" ngxPrint class="btn btn-sm btn-info mr-1"><i
									class="fa fa-file-pdf">&nbsp;</i>Information</button>
						</div>
						<div class="ml-2 mr-2" id="printcontent_indirect">
							<div class="row">
								<div class="col-md-6 border p-2"><strong>Date of Movement : </strong></div>
								<div class="col-md-6 border p-2">{{sightingDate | date:'dd-MMM-yyyy'}}
								</div>
								<div class="col-md-6 border p-2"><strong>Movement Time From: </strong></div>
								<div class="col-md-6 border p-2">{{sightingTimefrom | date:'shortTime'}}
								</div>
								<div class="col-md-6 border p-2"><strong>Movement Time To : </strong></div>
								<div class="col-md-6 border p-2">{{sightingTimeTo | date:'shortTime'}}
								</div>
								<div class="col-md-6 border p-2"><strong>Location (village, RF, PRF, etc...) : </strong>
								</div>
								<div class="col-md-6 border p-2">{{location}}</div>
								<div class="col-md-6 border p-2"><strong>Division : </strong></div>
								<div class="col-md-6 border p-2">{{divisionName}}</div>
								<div class="col-md-6 border p-2"><strong>Range : </strong></div>
								<div class="col-md-6 border p-2">{{rangeName}}</div>
								<div class="col-md-6 border p-2"><strong>Section : </strong></div>
								<div class="col-md-6 border p-2">{{secName}}</div>
								<div class="col-md-6 border p-2"><strong>Beat : </strong></div>
								<div class="col-md-6 border p-2">{{beatName}}</div>
								<div class="col-md-6 border p-2"><strong>No. of Elephants sighted : </strong></div>
								<div class="col-md-6 border p-2">{{(total !== 'null') ? total
									: 0 }}</div>
								<div class="col-md-6 border p-2"><strong>Herd : </strong></div>
								<div class="col-md-6 border p-2">{{(heard !== 'null') ? heard
									: 0 }}</div>
								<div class="col-md-6 border p-2"><strong>Calf : </strong></div>
								<div class="col-md-6 border p-2">{{(calf !== 'null') ? calf :
									0 }}</div>
								<div class="col-md-6 border p-2"><strong>Female : </strong></div>
								<div class="col-md-6 border p-2">{{(female !== 'null') ?
									female : 0 }}</div>
								<div class="col-md-6 border p-2"><strong>Tusker : </strong></div>
								<div class="col-md-6 border p-2">{{(tusker !== 'null') ?
									tusker : 0 }}</div>
								<div class="col-md-6 border p-2"><strong>Mukhna : </strong></div>
								<div class="col-md-6 border p-2">{{ (mukhna !== 'null') ?
									mukhna : 0 }}</div>
								<div class="col-md-6 border p-2"><strong>Report Type : </strong></div>
								<div class="col-md-6 border p-2">{{reportType}}
									{{duplicateReport}} </div>
								<div class="col-md-6 border p-2"><strong>Indirect Report Type : </strong></div>
								<div class="col-md-6 border p-2">{{(indirectReportType !== 'null' ) ?
									indirectReportType : ''}} </div>
								<div class="col-md-6 border p-2" *ngIf="indirectReportType=='Trumpeting call/Sound'">
									<strong>Trumpting
										Approx Distance : </strong></div>
								<div class="col-md-6 border p-2" *ngIf="indirectReportType=='Trumpeting call/Sound'">
									{{(trumptingApproxDistance !== '' ) ?
									trumptingApproxDistance +" mtrs" : ''}} </div>
								<div class="col-md-6 border p-2" *ngIf="indirectReportType=='Trumpeting call/Sound'">
									<strong>Trumpting
										Direction: </strong></div>
								<div class="col-md-6 border p-2" *ngIf="indirectReportType=='Trumpeting call/Sound'">
									{{(trumptingDirection !== '' ) ? trumptingDirection : ''}}
								</div>
								<div class="col-md-6 border p-2"><strong> Report By : </strong></div>
								<div class="col-md-6 border p-2">{{(updatedBy !== 'null') ?
									updatedBy : ''}}</div>
								<div class="col-md-6 border p-2"><strong>Latitude (N) : </strong></div>
								<div class="col-md-6 border p-2">{{latitude}}</div>
								<div class="col-md-6 border p-2"><strong>Langitude (N) : </strong></div>
								<div class="col-md-6 border p-2">{{longitude}}</div>
								<div class="col-md-6 border p-2"><strong>Remarks : </strong></div>
								<div class="col-md-6 border p-2">
									{{(remarks!=='null'?remarks:'N/A')}}</div>
								<div class="col-md-6 border p-2"><strong>Evidence Photo : </strong></div>
								<div class="col-md-6 border p-2">
									<a href="{{imgAcqlocation}}" class="glightbox gallery_product filter elephant"
										data-fancybox="gallery" data-caption="">
										<img [src]="imgAcqlocation" *ngIf="imgAcqlocation" class="img-fluid mr-2 mb-2"
											style="height:85px" (error)="defaultUrl($event)">
										<div class="overlay">
											<span class="icon">
												<i class="fa fa-search"></i>
											</span>
										</div>
									</a>
								</div>
								<div class="col-md-6 border p-2"><strong>Selfie Photo With Staff: </strong></div>
								<div class="col-md-6 border p-2">
									<a href="{{selfieImglocation}}" class="glightbox gallery_product filter elephant"
										data-fancybox="gallery" data-caption="">
										<img [src]="selfieImglocation" *ngIf="selfieImglocation"
											class="img-fluid mr-2 mb-2" style="height:85px"
											(error)="defaultUrl($event)">
										<div class="overlay">
											<span class="icon">
												<i class="fa fa-search"></i>
											</span>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
       <!-- Problematic elephant -->
		<div class="modal fade gallery" id="nillElephantMovementRecordModal" tabindex="-1" role="dialog"
			aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="exampleModalLongTitle">Problematic Elephant </h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-md-12 text-right mb-2">
								<button type="submit" printSectionId="printcontent_nill" [useExistingCss]="true"
									printTitle="Protected Area Info" ngxPrint class="btn btn-sm btn-info mr-1"><i
										class="fa fa-file-pdf">&nbsp;</i>Information</button>
							</div>
						</div>

						<div class="ml-2 mr-2" id="printcontent_nill">
							<div class="row">
								<div class="col-md-6 border p-2"><strong>Date of Movement : </strong></div>
								<div class="col-md-6 border p-2"> {{sightingDate | date:'dd-MMM-yyyy'}}
								</div>
								<div class="col-md-6 border p-2">
									<strong>Movement Time From: </strong>
								</div>
								<div class="col-md-6 border p-2">
									{{sightingTimefrom | date:'shortTime'}}
								</div>
								<div class="col-md-6 border p-2">
									<strong>Movement Time To : </strong>
								</div>
								<div class="col-md-6 border p-2">
									{{sightingTimeTo | date:'shortTime'}}
								</div>
								<div class="col-md-6 border p-2">
									<strong>Location (village, RF, PRF, etc...) : </strong>
								</div>
								<div class="col-md-6 border p-2">
									{{location}}
								</div>
								<div class="col-md-6 border p-2">
									<strong>Division : </strong>
								</div>
								<div class="col-md-6 border p-2">
									{{divisionName}}
								</div>
								<div class="col-md-6 border p-2">
									<strong>Range : </strong>
								</div>
								<div class="col-md-6 border p-2">
									{{rangeName}}
								</div>
								<div class="col-md-6 border p-2">
									<strong>Section : </strong>
								</div>
								<div class="col-md-6 border p-2">
									{{secName}}
								</div>
								<div class="col-md-6 border p-2">
									<strong>Beat : </strong>
								</div>
								<div class="col-md-6 border p-2">
									{{beatName}}
								</div>
								<div class="col-md-6 border p-2" *ngIf="problemetic!=undefined">
									Problemetic Elephant
								</div>
								<div class="col-md-6 border p-2" *ngIf="problemetic!=undefined">
									{{problemetic?"Yes":"No"}}
								</div>
								<div class="col-md-6 border p-2">
									Incharge Officer
								</div>
								<div class="col-md-6 border p-2">
									{{inchargeOfficer}}
								</div>
								<div class="col-md-6 border p-2">
									Unique Id
								</div>
								<div class="col-md-6 border p-2"><strong>{{uniqueId}}</strong>
								</div>
								<div class="col-md-6 border p-2"><strong>Report Type : </strong></div>
								<div class="col-md-6 border p-2">{{reportType}}
									{{duplicateReport}} </div>
									<div class="col-md-6 border p-2" *ngIf="indirectReportType">
										<strong>Indirect Report Type:</strong>
									  </div>
									  <div class="col-md-6 border p-2" *ngIf="indirectReportType">
										{{ indirectReportType }}
									  </div>
								<div class="col-md-6 border p-2" *ngIf="indirectReportType=='Trumpeting call/Sound'">
									<strong>Trumpting
										Approx Distance : </strong></div>
								<div class="col-md-6 border p-2" *ngIf="indirectReportType=='Trumpeting call/Sound'">
									{{(trumptingApproxDistance !== '' ) ?
									trumptingApproxDistance +" mtrs" : ''}} </div>
								<div class="col-md-6 border p-2" *ngIf="indirectReportType=='Trumpeting call/Sound'">
									<strong>Trumpting
										Direction: </strong></div>
								<div class="col-md-6 border p-2" *ngIf="indirectReportType=='Trumpeting call/Sound'">
									{{(trumptingDirection !== '' ) ? trumptingDirection : ''}}
								</div>
								<div class="col-md-6 border p-2"><strong> Report By : </strong></div>
								<div class="col-md-6 border p-2">{{(updatedBy !== 'null') ?
									updatedBy : ''}}</div>
								<div class="col-md-6 border p-2"><strong>Latitude (N) : </strong></div>
								<div class="col-md-6 border p-2">{{latitude}}</div>
								<div class="col-md-6 border p-2"><strong>Langitude (N) : </strong></div>
								<div class="col-md-6 border p-2">{{longitude}}</div>
								<div class="col-md-6 border p-2"><strong>Remarks : </strong></div>
								<div class="col-md-6 border p-2">
									{{(remarks!=='null'?remarks:'N/A')}}</div>
								<div class="col-md-6 border p-2"><strong>Evidence Photo : </strong></div>
								<div class="col-md-6 border p-2">
									<a href="{{imgAcqlocation}}" class="glightbox gallery_product filter elephant"
										data-fancybox="gallery" data-caption="">
										<img [src]="imgAcqlocation" *ngIf="imgAcqlocation" class="img-fluid mr-2 mb-2"
											style="height:85px" (error)="defaultUrl($event)">
										<div class="overlay">
											<span class="icon">
												<i class="fa fa-search"></i>
											</span>
										</div>
									</a>
								</div>
								<div class="col-md-6 border p-2"><strong>Selfie Photo With Staff: </strong></div>
								<div class="col-md-6 border p-2">
									<a href="{{selfieImglocation}}" class="glightbox gallery_product filter elephant"
										data-fancybox="gallery" data-caption="">
										<img [src]="selfieImglocation" *ngIf="selfieImglocation"
											class="img-fluid mr-2 mb-2" style="height:85px"
											(error)="defaultUrl($event)">
										<div class="overlay">
											<span class="icon">
												<i class="fa fa-search"></i>
											</span>
										</div>
									</a>
								</div>

							</div>
						</div>

					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="photoElephantMovementRecordModal" tabindex="-1" role="dialog"
			aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
			<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="exampleModalLongTitle">View Iploaded Photo</h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body" style="height:auto">
						<img src="assets/images/9.jpg" class="img-fluid">
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="mapElephantMovementDirectRecordModal" tabindex="-1" role="dialog"
			aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
			<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="exampleModalLongTitle1">Map View</h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div id="emdirect" style="height: 500px; width: 100%"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="mapElephantMovementinDirectRecordModal" tabindex="-1" role="dialog"
			aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
			<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="exampleModalLongTitle2">Map View</h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div id="emindirect" style="height: 500px; width: 100%"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="mapProblematicRecordModal" tabindex="-1" role="dialog"
			aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
			<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="exampleModalLongTitle3">Map View</h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div id="emproblem" style="height: 500px; width: 100%"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="viewDirectMapViewModal" tabindex="-1" role="dialog"
			aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
			<div class="modal-dialog modal-lg modal-dialog-centered custom-map-full" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="exampleModalLongTitle7">Map View</h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div id="mapViewdirect" style="height: 100%; width: 100%"></div>
						<div class="col-md-12">
							<p><img src="assets/images/icon-duplicate.png" alt="image" height="18"
									class="mr-1">indicates Duplicate Reporting.</p>
						</div>
					</div>

				</div>
			</div>
		</div>

		<div class="modal fade" id="viewInDirectMapViewModal" tabindex="-1" role="dialog"
			aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
			<div class="modal-dialog modal-lg modal-dialog-centered custom-map-full" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title" id="exampleModalLongTitle5">Map View</h4>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div id="mapViewindirect" style="height: 100%; width: 100%"></div>
						<div class="col-md-12">
							<p><img src="assets/images/icon-duplicate.png" alt="image" height="18"
									class="mr-1">indicates Duplicate Reporting.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="viewProblematicMapViewModal" tabindex="-1" role="dialog"
		aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
		<div class="modal-dialog modal-lg modal-dialog-centered custom-map-full" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title" id="exampleModalLongTitle4">Map View</h4>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div id="mapViewProblem" style="height: 100%; width: 100%"></div>
				</div>
			</div>
		</div>
	   </div>
		
		<div class="modal fade" id="deleteElephantMovementRecordModal" tabindex="-1" role="dialog"
			aria-labelledby="exampleModalCenterTitle_delete" aria-hidden="true">
			<div class="modal-dialog modal-confirm">
				<div class="modal-content">
					<div class="modal-header flex-column">
						<div class="icon-box">
							<i class="material-icons">&#xE5CD;</i>
						</div>
						<h4 class="modal-title w-100">Are you sure?</h4>
					</div>
					<div class="modal-body">
						<p>Do you really want to delete these records? This process cannot be undone.</p>
					</div>
					<div class="modal-footer justify-content-center">
						<div class="spinner text-center">
							<div *ngIf="loader2" class="spinner-border" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</div>
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-danger" *ngIf="!loader2"
							(click)="confirmDelete()">Delete</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade gallery" id="editElephantMovementRecordModal" tabindex="-1" role="dialog"
		aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title" id="exampleModalLongTitle">Edit Direct Elephant Movement Record</h4>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
			  
					<form [formGroup]="reporteditForm"  (ngSubmit)="updateReport()">
						<div class="row form-group">
							<div class="col-md-4">
								<label>Date of Movement</label>
								<div id="datepicker7" class="input-group date"
																		data-date-format="dd-mm-yyyy"
																		title="From Date">
										<input class="form-control"  #sd7 id="startDate7" type="text"/>
																		<span class="input-group-addon"><i
																				class="mdi mdi-calendar"></i></span>
							  </div>
							</div>
							<div class="col-md-4">
								<label>Movement Time From</label>
								<div class="input-group clockpicker1" data-autoclose="true">
									<input type="text" class="form-control" value="09:30"
									  #from_time id="from_time">
									<span class="input-group-addon">
									  <span class="fa fa-clock-o"></span>
									</span>
								  </div>
							</div>
							<div class="col-md-4">
								<label>Movement Time To</label>
								<div class="input-group clockpicker2" data-autoclose="true">
									<input type="text" class="form-control" value="09:30" 
									#to_time id="to_time">
									<span class="input-group-addon">
									  <span class="fa fa-clock-o"></span>
									</span>
								  </div>
							</div>
						</div>
						<div class="row form-group">
							<div class="col-md-4">
								<label>Location (village, RF, PRF, etc...)</label>
								<input type="text" class="form-control" placeholder="Location (village, RF, PRF, etc...)"  formControlName="location">
							</div>
							
							<div class="col-md-4">
								<label>No. of Elephantgs Sighted</label>
								<input type="number" class="form-control" placeholder="No. of Elephantgs Sighted" formControlName="elephant">
							</div>
							<div class="col-md-4">
								<label>Herd</label>
								<input type="number" class="form-control" placeholder="Herd" formControlName="herd">
							</div>
						</div>
						<div class="row form-group">
							
							<div class="col-md-4">
								<label>Calf</label>
								<input type="number" class="form-control" placeholder="Calf" formControlName="calf" (change)="getSumValue()">
							</div>
							<div class="col-md-4">
								<label>Female</label>
								<input type="number" class="form-control" placeholder="Female" formControlName="female" (change)="getSumValue()">
							</div>
							<div class="col-md-4">
								<label>Tusker</label>
								<input type="number" class="form-control" placeholder="Tusker"  formControlName="tusker" (change)="getSumValue()">
							</div>
						</div>
						<div class="row form-group">
							<div class="col-md-4">
								<label>Tusker Adult</label>
								<input type="number" class="form-control" placeholder="Tusker Adult"  formControlName="tuskerAdult" (change)="getSumValue()">
							</div>
							<div class="col-md-4">
								<label>Tusker Sub Adult</label>
								<input type="number" class="form-control" placeholder="Tusker Sub Adult"  formControlName="tuskerSubAdult" (change)="getSumValue()">
							</div>
							<div class="col-md-4">
								<label>Mukhna</label>
								<input type="number" class="form-control" placeholder="Mukhna"  formControlName="mukhna" (change)="getSumValue()">
							</div>
						</div>
						<div class="row form-group">
							<div class="col-md-4">
								<label>Remarks</label>
								<input type="text" class="form-control" placeholder="Remarks" formControlName="remarks">
							</div>
						</div>
						<hr>
						<div class="text-center">
							<button type="submit" class="btn btn-sm btn-success mr-1" [disabled]="!reporteditForm.touched">Update</button>
							<button type="button" class="btn btn-sm btn-danger" (click)="onclickCancel()">Cancel</button>
						</div>
					</form>
					
				</div>
			</div>
		</div>
	</div>



	</div>

	<div id="footer">
		<app-masterfooter></app-masterfooter>
	</div>

</div>