<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div>
<app-masterheader></app-masterheader>
<div class="wrapper">

    <app-sidebar></app-sidebar>

    <div id="content">

        <!-- <app-breadcrumb></app-breadcrumb> -->

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                	<div id="accordion" class="mapFilter">
                    <!-- NewsUpdate -->
                		<div class="card"  #NewsUpdateEle>
                			<div class="card-body">
		                        <a href="#collapseOne" class="text-collapse" data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne">
		                            <span class="d-block accordion-toggle">News Updates</span>
		                        </a>
                            <form>
		                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
		                            <div class="accordion-body">
                                  <mat-form-field>
		                            	<textarea id="news" #news matInput  matTextareaAutosize ></textarea>
                                </mat-form-field>
		                            	<hr>
									    <div class="text-center">
									    	<button type="button" (click)="addNews()"  class="btn btn-sm btn-success">Save</button>
									    </div>
		                            </div>
		                        </div>
                          </form>
		                    </div>
		                </div>
                      <!-- New -->
		                <div class="card" #NewContent>
                			<div class="card-body">
		                        <a href="#collapseTwo" class="text-collapse collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseTwo">
		                            <span class="d-block accordion-toggle">What is New</span>
		                        </a>
		                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
		                            <div class="accordion-body">
		                            	<textarea id="newContent"></textarea>
		                            	<hr>
									    <div class="text-center">
									    	<button type="submit" (click)="addNewContent()"  class="btn btn-sm btn-success">Save</button>
									    </div>
		                            </div>
		                        </div>
		                    </div>
		                </div>
                    <!-- Publication -->
		                <div class="card"  #Publication>
                			<div class="card-body">
		                        <a href="#collapseThree" class="text-collapse collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseThree">
		                            <span class="d-block accordion-toggle">Publication</span>
		                        </a>
								<form [formGroup]="publicationForm" (ngSubmit)="addPublication()">
		                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
		                            <div class="accordion-body">
		                            	<div class="table-responsive">

									      	<table class="table table-bordered">
									        	<tbody id="tbody">
									  				<tr>
									  					<td>
									  						<label>Title</label>
									  						<input class="form-control" type="text" placeholder="Title" formControlName="title" [ngClass]="{ 'is-invalid': publicationForm.controls['title'].invalid && (publicationForm.controls['title'].dirty || publicationForm.controls['title'].touched) }"/>
                                <div *ngIf="publicationForm.controls['title'].invalid && (publicationForm.controls['title'].dirty || publicationForm.controls['title'].touched)" class="text-danger mt-1">
                                  <small *ngIf="publicationForm.controls['title'].errors.required">
                                    Title is required.
                                  </small>
                                </div>
                              </td>
									  					<td>
									  						<label>Upload Thumbnail Image</label>
                                <input type="file" formControlName="publicationThumbLineimg" accept='image/*' (change)="onSelectPubThumbnailFile($event)"  class="form-control"  placeholder="Upload Thumbnail Image">
									  					</td>
									  					<td>
									  						<label>Upload PDF</label>
                                <input type="file" formControlName="publicationPdfFile" accept='pdf/*' (change)="OnSelectPubPdfFile($event)"  class="form-control"  placeholder="Upload PDF">
									  					</td>
									  					<!-- <td><button class="btn btn-sm btn-info custom-btn-top" id="addBtn" type="button"><i class="fa fa-plus"></i></button></td> -->
									  				</tr>
									        	</tbody>
									      	</table>

									    </div>
									    <hr>
									    <div class="text-center">
									    	<button type="submit" class="btn btn-sm btn-success" [disabled]="!publicationForm.valid" >Save</button>
									    </div>
		                            </div>
		                        </div>
							</form>
		                    </div>
		                </div>
                    <!-- Gallery -->
		                <div class="card"  #Gallery>
                			<div class="card-body">
		                        <a href="#collapseFour" class="text-collapse collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseFour">
		                            <span class="d-block accordion-toggle">Gallery</span>
		                        </a>
		                        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
		                            <div class="accordion-body">
                                  <form [formGroup]="galleryForm" (ngSubmit)="addGalaryImg(galleryForm.value)">
		                            		<div class="row form-group">
		                            			<div class="col-md-2 mt-2">
		                            				<label>Select Type</label>
		                            			</div>
		                            			<div class="col-md-3">
		                            				<select class="form-control" formControlName="type" [(ngModel)]='type' #typeselect>
		                            					<option value="0">--  Select --</option>
				                                          <option    *ngFor="let type of typeList"   value={{type.typeId}} >
				                                            {{type.type}}
				                                          </option>
		                            					<!-- <option >Project Elephant</option>
		                            					<option>Project Tiger</option>
		                            					<option>Crocodile Conservation</option>
		                            					<option>Sea Turtle Conservation</option> -->
		                            				</select>
		                            			</div>
		                            			<div class="col-md-7 text-right">
		                            				<button type="button" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#modalAddGalleryType"><i class="fa fa-plus">&nbsp;</i>Add New Type</button>
		                            			</div>
		                            			<!-- <div class="col-md-1 mt-2">
		                            				<label>Title</label>
		                            			</div>
		                            			<div class="col-md-3">
		                            				<input class="form-control" type="text" placeholder="Title" />
		                            			</div> -->
		                            		</div>
		                            		<div class="row">
		                            			<div class="col-md-12">
		                            				<!-- <div class="custom-file-container" data-upload-id="myFirstImage">
				                                        <label>Upload Image (Multiple) <a href="javascript:void(0)" class="custom-file-container__image-clear" title="Clear Image">x</a></label>
				                                        <label class="custom-file-container__custom-file" >
				                                            <input type="file" class="custom-file-container__custom-file__custom-file-input" multiple>
				                                            <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
				                                            <span class="custom-file-container__custom-file__custom-file-control"></span>
				                                        </label>
				                                        <div class="custom-file-container__image-preview"></div>
				                                    </div> -->
                                            <div class="custom-file-container" data-upload-id="myFirstImage">
                                              <label>Upload Image (Single) <a href="javascript:void(0)" class="custom-file-container__image-clear" title="Clear Image">x</a></label>
                                              <label class="custom-file-container__custom-file" >
                                                  <input type="file" class="custom-file-container__custom-file__custom-file-input" accept="image/*" formControlName="img"  (change)="onSelectGalleryImg($event)">
                                                  <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
                                                  <span class="custom-file-container__custom-file__custom-file-control"></span>
                                              </label>
                                              <div class="custom-file-container__image-preview"></div>
                                          </div>
		                            			</div>
		                            		</div>
		                            		<hr>
										    <div class="row">
		                            			<div class="col-md-12 text-center">
										    		<button type="submit" class="btn btn-sm btn-success">Save</button>
										    	</div>
										    </div>
		                            	</form>
		                            </div>
		                        </div>
		                    </div>
		                </div>
                    <!-- Tender -->
		                <div class="card"  #Tender>
                			<div class="card-body">
		                        <a href="#collapseFive" class="text-collapse collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseFive">
		                            <span class="d-block accordion-toggle">Tender</span>
		                        </a>
		                        <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
		                            <div class="accordion-body">
                                  <form [formGroup]="tenderForm" (ngSubmit)="addTender()">
		                            		<div class="row form-group">
												<!-- <div class="col-md-2">
													<label>Year</label>
													<input class="form-control" type="text" placeholder="Year"  formControlName="year"/>
												</div>
												<div class="col-md-2">
													<label>Month</label>
													<select class="form-control" formControlName="month">
														<option selected="selected" value="0">-- Select --</option>
														<option value="January">January</option>
														<option value="February">February</option>
														<option value="March">March</option>
														<option value="April">April</option>
														<option value="May">May</option>
														<option value="June">June</option>
														<option value="July">July</option>
														<option value="August">August</option>
														<option value="September">September</option>
														<option value="October">October</option>
														<option value="November">November</option>
														<option value="December">December</option>
													</select>
												</div> -->
												<div class="col-md-2">
													<label>Tender No.</label>
													<input class="form-control" type="text" placeholder="Tender No." [ngClass]="{ 'is-invalid': tenderForm.controls['tanderno'].invalid && (tenderForm.controls['tanderno'].dirty || tenderForm.controls['tanderno'].touched) }" formControlName="tanderno"/>
                          <div *ngIf="tenderForm.controls['tanderno'].invalid && (tenderForm.controls['tanderno'].dirty || tenderForm.controls['tanderno'].touched)" class="text-danger mt-1">
                            <small *ngIf="tenderForm.controls['tanderno'].errors.required">
                              Tender No. is required.
                            </small>
                          </div>
												</div>
												<div class="col-md-2">
													<label>Date</label>
													<div id="datepicker" class="input-group date"  data-date-format="dd-mm-yyyy" title="From Date">
				                                        <input class="form-control" formControlName="fromdate" #fd id="fromdate" type="text"  />
				                                        <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
				                                    </div>
												</div>
												<div class="col-md-4">
													<label>Upload File</label>
                          <input type="file" formControlName="tenderfile" accept='pdf/*' (change)="OnSelectTenderPdfFile($event)"  class="form-control"  placeholder="Upload PDF">
												</div>
											</div>
											<div class="row form-group">
												<div class="col-md-12">
													<label>Title</label>
													<textarea class="form-control" placeholder="Title" [ngClass]="{ 'is-invalid': tenderForm.controls['title'].invalid && (tenderForm.controls['title'].dirty || tenderForm.controls['title'].touched) }" formControlName="title"></textarea>
                          <div *ngIf="tenderForm.controls['title'].invalid && (tenderForm.controls['title'].dirty || tenderForm.controls['title'].touched)" class="text-danger mt-1">
                            <small *ngIf="tenderForm.controls['title'].errors.required">
                              Title is required.
                            </small>
                          </div>
												</div>
											</div>
											<hr>
											<div class="row">
											    <div class="col-md-12 text-center">
											    	<button type="submit" class="btn btn-sm btn-success">Save</button>
											    </div>
											</div>
		                            	</form>
		                            </div>
		                        </div>
		                    </div>
		                </div>
                    <!-- Notice -->
                    <div class="card" #Notice>
                			<div class="card-body">
		                        <a href="#collapseSix" class="text-collapse collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseSix">
		                            <span class="d-block accordion-toggle">Notice</span>
		                        </a>
		                        <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
		                            <div class="accordion-body">
                                  <form [formGroup]="noticeForm" (ngSubmit)="addNotice()">
		                            		<div class="row form-group">
												<div class="col-md-2">
													<label>Notice No.</label>
													<input class="form-control" type="text" placeholder="Notice No." [ngClass]="{ 'is-invalid': noticeForm.controls['noticeNumber'].invalid && (noticeForm.controls['noticeNumber'].dirty || noticeForm.controls['noticeNumber'].touched) }" formControlName="noticeNumber"/>
                          <div *ngIf="noticeForm.controls['noticeNumber'].invalid && (noticeForm.controls['noticeNumber'].dirty || noticeForm.controls['noticeNumber'].touched)" class="text-danger mt-1">
                            <small *ngIf="noticeForm.controls['noticeNumber'].errors.required">
                              Notice No. is required.
                            </small>
                          </div>
												</div>
												<div class="col-md-2">
													<label>Date</label>
													<div id="datepicker1" class="input-group date"  data-date-format="dd-mm-yyyy" title="From Date">
				                                        <input class="form-control" formControlName="noticeDate" #fdNotice id="fromdateNotice" type="text"  />
				                                        <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
				                                    </div>
												</div>
                        <div class="col-md-2">
													<label>Notice Validation Date</label>
													<div id="datepicker2" class="input-group date"  data-date-format="dd-mm-yyyy" title="From Date">
				                                        <input class="form-control" formControlName="noticeValidDate" #fdNotice_valid  id="fromdateNotice_valid"  type="text"  />
				                                        <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
				                                    </div>
												</div>
												<div class="col-md-4">
													<label>Upload File</label>
                          <input type="file" formControlName="noticefile" accept='pdf/*' (change)="OnSelectNoticePdfFile($event)"  class="form-control"  placeholder="Upload PDF">
												</div>
											</div>
											<div class="row form-group">
												<div class="col-md-12">
													<label>Title</label>
													<textarea class="form-control" placeholder="Title" [ngClass]="{ 'is-invalid': noticeForm.controls['noticeName'].invalid && (noticeForm.controls['noticeName'].dirty || noticeForm.controls['noticeName'].touched) }" formControlName="noticeName"></textarea>
                          <div *ngIf="noticeForm.controls['noticeName'].invalid && (noticeForm.controls['noticeName'].dirty || noticeForm.controls['noticeName'].touched)" class="text-danger mt-1">
                            <small *ngIf="noticeForm.controls['noticeName'].errors.required">
                              Title is required.
                            </small>
                          </div>
												</div>
											</div>
											<hr>
											<div class="row">
											    <div class="col-md-12 text-center">
											    	<button type="submit" class="btn btn-sm btn-success">Save</button>
											    </div>
											</div>
		                            	</form>
		                            </div>
		                        </div>
		                    </div>
		                </div>
                    </div><!--/accordion-->

                </div>
            </div>
        </div>

    </div>

    <!-- Modal Add Gallery Type -->
    <div class="modal fade" id="modalAddGalleryType" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title" id="exampleModalLongTitle">Add New Type</h4>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
          <form class="mt-3" [formGroup]="newGtypeForm" (ngSubmit)="onSubmitNewGType()">
						<div class="row form-group">
							<div class="col-md-12">
								<label>Add New Type</label>
								<input class="form-control"  [ngClass]="{ 'is-invalid': newGtypeForm.controls['type'].invalid && (newGtypeForm.controls['type'].dirty || newGtypeForm.controls['type'].touched) }" type="text" placeholder="Type"  formControlName="type"/>
							</div>
						</div>
						<hr>
						<div class="row">
						    <div class="col-md-12 text-center">
						    	<button type="submit" class="btn btn-sm btn-success" [disabled]="newGtypeForm.invalid">Save</button>
						    </div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>

    <div id="footer">
        <app-masterfooter></app-masterfooter>
    </div>

</div>

