import { Component, OnInit } from '@angular/core';
declare var jQuery: any;
declare var Swiper: any;

@Component({
  selector: 'app-slidergallery',
  templateUrl: './slidergallery.component.html',
  styleUrls: ['./slidergallery.component.css']
})
export class SlidergalleryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  	(function($){
      	var swiper = new Swiper('.carousel-gallery .swiper-container', {
	    effect: 'slide',
	    speed: 900,
	    slidesPerView: 4,
	    spaceBetween: 20,
	    simulateTouch: true,
	    autoplay: {
	      delay: 5000,
	      stopOnLastSlide: false,
	      disableOnInteraction: false },

	    pagination: {
	      el: '.carousel-gallery .swiper-pagination',
	      clickable: true },

	    breakpoints: {
	      // when window width is <= 320px
	      320: {
	        slidesPerView: 1,
	        spaceBetween: 5 },

	      // when window width is <= 480px
	      425: {
	        slidesPerView: 2,
	        spaceBetween: 10 },

	      // when window width is <= 640px
	      768: {
	        slidesPerView: 3,
	        spaceBetween: 20 } } 
	    });
    })(jQuery);
  }

}
