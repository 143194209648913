<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-3">

				<div class="custom-menu-inner">
					<h4 class="card-title">Projects & Conservation</h4>
					<ul>
						<li><a [routerLink]="['/projectelephant']">Project Elephant</a></li>
						<li><a [routerLink]="['/projecttiger']">Project Tiger</a></li>
						<li><a [routerLink]="['/crocodileconservation']">Crocodile Conservation</a></li>
						<li class="active"><a [routerLink]="['/seaturtleconservation']">Sea Turtle Conservation</a></li>
						<li><a [routerLink]="['/blackbuckconservation']">Blackbuck Conservation</a></li>
						<li><a [routerLink]="['/mangroves']">Mangroves</a></li>
					</ul>
				</div>

			</div>
			<div class="col-md-9">

				<h4 class="card-title">Ecological conditions favourable for Sea Turtles</h4>
				<img src="assets/images/turtle1.jpg" class="img-fluid float-right img-inner" alt="image">
				<p>In coastal Odisha, the area of confluence of any river and the sea, which is the deltaic region, is a
					fascinating ecosystem. Also called intertidal area, this is criss-crossed by estuary, rivers, creeks
					and creeklets. The land in these estuaries is continually inundated by the saline sea water under
					influence of sea tide and also sweet water of river. Such lands are covered with mangroves, a kind
					of vegetation endemic to these areas. The mangroves and the estuaries are the breeding and spawning
					grounds of varieties of marine life forms such as fishes, prawns, crabs and mollusks. These life
					forms spend their juvenile stage in the mangrove estuarine ecosystem, and migrate to the sea in the
					later part of their lives. October to May is the period during which these juveniles are available
					in abundance in the estuaries and the coastal sea. This is the time for the sea turtles to
					congregate in these areas, having migrated over large distances in the sea to feed on these fishes
					etc. in the shallow sea near the intertidal regions. During the months of October to May therefore,
					shallow sea near the river mouth becomes the feeding ground of the sea turtles, both for the adults
					and their juveniles. The sea turtles congregate and mate there and then look for suitable
					undisturbed beaches in these river mouth areas for laying eggs and nesting.</p>
				<p>Based on these ecological conditions, certain pockets in the Odisha coast have become the famous mass
					nesting sites of the Olive Ridley sea turtles. Significant populations of these turtles visit the
					congregation areas en-masse before the winter months for breeding and nesting. This is a yearly
					phenomenon. This population represents about 50% of the total world population of Olive Ridleys, and
					about 90% of the Indian population of sea turtles. Worldwide attention is naturally focused on these
					rookeries for conservation of this species.</p>

				<h4 class="card-title">ECOLOGICAL CONDITIONS FAVOURABLE FOR SEA TURTLES</h4>
				<img src="assets/images/turtle_img2.gif" class="img-fluid float-right img-inner" alt="image">
				<p>In coastal Odisha, the area of confluence of any river and the sea, which is the deltaic region, is a
					fascinating ecosystem. Also called intertidal area, this is criss-crossed by estuary, rivers, creeks
					and creeklets. The land in these estuaries is continually inundated by the saline sea water under
					influence of sea tide and also sweet water of river. Such lands are covered with mangroves, a kind
					of vegetation endemic to these areas. The mangroves and the estuaries are the breeding and spawning
					grounds of varieties of marine life forms such as fishes, prawns, crabs and mollusks. These life
					forms spend their juvenile stage in the mangrove estuarine ecosystem, and migrate to the sea in the
					later part of their lives. October to May is the period during which these juveniles are available
					in abundance in the estuaries and the coastal sea. This is the time for the sea turtles to
					congregate in these areas, having migrated over large distances in the sea to feed on these fishes
					etc. in the shallow sea near the intertidal regions. During the months of October to May therefore,
					shallow sea near the river mouth becomes the feeding ground of the sea turtles, both for the adults
					and their juveniles. The sea turtles congregate and mate there and then look for suitable
					undisturbed beaches in these river mouth areas for laying eggs and nesting.</p>
				<p>Based on these ecological conditions, certain pockets in the Odisha coast have become the famous mass
					nesting sites of the Olive Ridley sea turtles. Significant populations of these turtles visit the
					congregation areas en-masse before the winter months for breeding and nesting. This is a yearly
					phenomenon. This population represents about 50% of the total world population of Olive Ridleys, and
					about 90% of the Indian population of sea turtles. Worldwide attention is naturally focused on these
					rookeries for conservation of this species.</p>
				<p>The Crocodile Project started with the objective of building the population to a stage when incidence
					of sighting could be 5 to 6 crocodiles per KM length of water. The Project sought to make up the
					natural losses by death and predation through rear and release operation. This involved collection
					of eggs from the nests as soon as these were laid, incubation and hatching of these eggs in
					hatcheries under regulated conditions of temperature and humidity, rearing the young juveniles,
					marking and release of the young crocodiles into Nature in protected areas, and assessment of the
					degree of success in restocking any protected area with crocodiles released from the hatcheries. To
					accomplish these tasks, 3 separate research units were established at Tikarpara, Dangmal and
					Ramatirtha for the Gharial, Salt Water Crocodile and the Mugger, respectively. At the Nandankanan
					Biological Park, captive breeding plans for all three species were pursued.</p>

				<h4 class="card-title">Legal Status</h4>
				<p>The Olive ridleys are Schedule I species under the Wildlife (Protection) Act, and are listed as
					'endangered' in the IUCN Red Data Book, in the 'Appendix-I' of CITES (Convention on International
					Trade in Endangered species of wild Flora and Fauna), and also listed in the CMS (Convention on
					Migratory species). India being a signatory nation to all these conventions has the responsibility
					of protecting this species of sea turtle and its nesting beaches, breeding, feeding and congregation
					areas, as well as its migratory pathways in the sea.</p>

				<h4 class="card-title">Nesting Areas and Main Rookeries</h4>
				<p>Olive Ridley sea turtles come to Odisha coasts for nesting in large numbers. Hence the activity is
					termed 'mass nesting' or 'arribada'. Worldwide attention is focused on the conservation of Olive
					Ridley Sea Turtle (Lepidochelys olivacea) in Odisha because about 50% of its world population comes
					to Odisha coasts for nesting, and this is about 90% of the population of sea turtles along the
					Indian coast.</p>
				<p>During the period from October to early summer, shallow seas near the river mouths become ideal
					feeding grounds for the Olive Ridley Sea turtles. At these places they also breed and look for
					undisturbed beaches for nesting.</p>
				<p>The known major breeding grounds in Odisha where such congregation takes place are located near the
					river mouths off Dhamra, Devi, and Rushikulya, although there are also minor breeding grounds near
					other river mouths such as the Mahanadi, Subarnarekha, Budhabalanga, Keluni and Bahuda.</p>
				<p>The famous mass nesting site of Olive Ridleys close to the mouth of Brahmani-Baitarani (Dhamara),
					received worldwide recognition in 1975-76 as one of the largest rookeries of the Ridleys.</p>

				<h4 class="card-title">Threats</h4>
				<img src="assets/images/turtle2.jpg" class="img-fluid float-right img-inner" alt="image">
				<p>The sea fishing activities have direct adverse impact on the adult sea turtles and their hatchlings.
					Olive ridleys usually mate between October to February, when they congregate in the shallow coastal
					waters for prolonged periods, making them vulnerable to the fishing nets and propellers of the
					trawlers.</p>
				<p>The other threats to sea turtles include: (i) Loss or modification of the nesting beaches due to
					Casuarina plantation; (ii) Fishing by gill nets; and development of fishing bases at the potential
					nesting sites and breeding areas; (iii) Strong illumination around nesting beaches which greatly
					disorients the adult turtles as well as the hatchlings; (iv) Large scale vessel movement in
					congregation zones severely disturb mating and breeding; (v) Nests and eggs are destroyed by
					predators like dogs, jackals, hyenas, etc., and by beach erosion.</p>
				<p>The most significant of these threats is 'incidental catch' in marine fisheries (where a few thousand
					turtles are killed in trawl and gill nets every year), as well as depredation of turtles eggs by
					feral and wild predators.</p>

				<h4 class="card-title">Pragrammes and Activities</h4>
				<ul class="custom-list">
					<li>Declaration of Gahirmatha Marine Wildlife Sanctuary in September, 1997</li>
					<li>Formation of State Level Steering Committee for sea turtles under the chairmanship of Chief
						Secretary, Odisha in 1997</li>
					<li>Declaration of three major turtle congregation sites (nesting and breeding grounds) at
						Gahirmatha (Dhamara river mouth), Devi and Rushikulya River mouths as 'No Fishing Zones' during
						the reproductive and nesting period</li>
					<li>Use of 'Turtle Excluder Device' (TED) has to be mandatory in all trawl fishing nets</li>
					<li>Regular patrolling of the nesting beaches and congregation-breeding zones in the sea by setting
						camps along the sea coast, and by patrolling in sea by trawler</li>
					<li>Monitoring the nesting beaches against predators and human interference</li>
					<li>Motivating local people in conservation of the breeding and nesting turtles</li>
					<li>Awareness creation among the fishing community and local inhabitants</li>
				</ul>

				<p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
						</strong>The information contained herein are provided solely for informational purposes
						only.The content is dynamic and may undergo change from time to time. The snapshot of the
						content at a particular time is not to be referred as a legally binding document.</small></p>

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>