
<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<app-masterheader></app-masterheader>

<div class="wrapper">
    <app-sidebar></app-sidebar>
    <div id="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="query-content">
                                        <div class="card">
                                            <div class="card-body">
                                                <h4 class="card-title">Search by</h4>
                                                <div class="row form-group mb-0">
                                                    <div class="col-md-12">
                                                        <select class="form-control" id="divisionwl"  (change)="onDivChangeloadWLDashboard(divisionSelect.value)" [(ngModel)]='divid' #divisionSelect>
                                                            <option value="0">All Wildlife Division</option>
                                                            <option    *ngFor="let division of divisionlist" value={{division.divisionId}} >
                                                              {{division.divisionName}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                     <div class="col-md-12 text-center custom-rotate mt-2">
                                                        <span>OR</span>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <select class="form-control" id="protectedarea"   (change)="onDivChangeloadPADashboard($event)" [(ngModel)]='PAid'  #PASelect>
                                                            <option value="0">All Protected Area</option>
                                                          <option    *ngFor="let pa of sanctuaryList" value={{pa.sanctuaryId}} >
                                                            {{pa.sanctuaryName}}
                                                          </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-10">
                                    <div class="card">
                                        <div class="card-body">
                                          <ul id="tabs" class="nav nav-tabs" role="tablist">
                                            <li class="nav-item">
                                                <a id="tab-X" href="#pane-X" class="nav-link active" data-toggle="tab" role="tab">Map</a>
                                            </li>
                                            <li class="nav-item">
                                                <a id="tab-Y" href="#pane-Y" class="nav-link"   data-toggle="tab" role="tab">Information</a>
                                            </li>
                                         </ul>
                                         <!-- (click)="checkdivisonactive()" -->
                                          <div id="accordionTop" class="tab-content" role="tablist">
                                                <div id="pane-X" class="card mb-0 tab-pane fade show active" role="tabpanel" aria-labelledby="tab-X">
                                                    <div class="card-header" role="tab" id="heading-X">
                                                        <h5 class="mb-0">
                                                            <a data-toggle="collapse" href="#collapse-X" aria-expanded="true" aria-controls="collapse-X">
                                                              Map
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapse-X" class="collapse show" data-parent="#accordionTop" role="tabpanel" aria-labelledby="heading-X">
                                                      <div class="card-body">

                                                        <h4 class="card-title">Map</h4>

                                                            <div id="theunique" class="fixHeightLeft"></div>
                                                            <div id="popup" class="ol-popup">
                                                                <a href="javascript:void(0)" id="popup-closer"  class="ol-popup-closer"></a>
                                                                <div id="popup-content">
                                                                    <table class="table table-bordered" *ngIf = "showpdfdownloads">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td><strong>Division Name</strong></td>
                                                                                <td>{{divisionnamefromlayer}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><strong>Download PDF</strong></td>
                                                                                <td><a class="text-success" (click)="downloadthemap(divisionnamefromlayer)"><i class='fa fa-file-pdf-o'></i></a></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <table class="table table-bordered" *ngIf = "showprotectedarea">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td><strong>Protected Name</strong></td>
                                                                                <td>{{divisionnamefromlayer}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>

                                                                </div>
                                                            </div>

                                                    </div>

                                                    </div>
                                                </div>
                                                <div id="pane-Y" class="card mb-0 tab-pane fade " role="tabpanel" aria-labelledby="tab-Y">
                                                    <div class="card-header" role="tab" id="heading-Y">
                                                        <h5 class="mb-0">
                                                            <a class="collapsed" data-toggle="collapse" href="#collapse-Y" aria-expanded="false" aria-controls="collapse-Y">
                                                              Info
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapse-Y" class="collapse show" data-parent="#accordionTop" role="tabpanel" aria-labelledby="heading-Y">
                                                      <div class="card-body">
                                                            <div *ngIf="code==1">
                                                                <app-wildlifeunitsbalasore *ngIf="divid=='4'"></app-wildlifeunitsbalasore>
                                                                <app-wildlifeunits-baliguda-fd *ngIf="divid=='5'"></app-wildlifeunits-baliguda-fd>
                                                                <app-wildlifeunitsbamra *ngIf="divid=='6'"></app-wildlifeunitsbamra>
                                                                <app-wildlifeunitsbhadrak *ngIf="divid=='10'"></app-wildlifeunitsbhadrak>
                                                                <app-wildlifeunitschandaka *ngIf="divid=='14'"></app-wildlifeunitschandaka>
                                                                <app-wildlifeunitschilika *ngIf="divid=='15'"></app-wildlifeunitschilika>
                                                                <app-wildlifeunits-dhenkanal-fd *ngIf="divid=='19'"></app-wildlifeunits-dhenkanal-fd>
                                                                <app-wildlifeunitshirakud *ngIf="divid=='22'"></app-wildlifeunitshirakud>
                                                                <app-wildlifeunits-kalahandi-south-fd *ngIf="divid=='26'"></app-wildlifeunits-kalahandi-south-fd>
                                                                <app-wildlifeunits-keonjhar *ngIf="divid=='29'"></app-wildlifeunits-keonjhar>
                                                                <app-wildlifeunits-mahanadi *ngIf="divid=='33'"></app-wildlifeunits-mahanadi>
                                                                <app-wildlifeunits-parlakhemundi-fd *ngIf="divid=='38'"></app-wildlifeunits-parlakhemundi-fd>
                                                                <app-wildlifeunits-puri *ngIf="divid=='40'"></app-wildlifeunits-puri>
                                                                <app-wildlifeunits-satkosia *ngIf="divid=='46'"></app-wildlifeunits-satkosia>
                                                                <app-wildlifeunits-sunabeda-wl *ngIf="divid=='49'"></app-wildlifeunits-sunabeda-wl>
                                                                <app-wildlifeunits-mangrove *ngIf="divid=='35'"></app-wildlifeunits-mangrove>
                                                                <!-- <app-wildlifeunits-nandankanan *ngIf="divid=='36'"></app-wildlifeunits-nandankanan> -->
                                                                <app-wildlifeunits-similipal-south *ngIf="divid=='57'"></app-wildlifeunits-similipal-south>
                                                                <app-wildlifeunits-similipal-north *ngIf="divid=='58'"></app-wildlifeunits-similipal-north>

                                                            </div>
                                                            <div  *ngIf="code==0">
                                                                <app-protectedareas-badrama *ngIf="PAid=='6'"></app-protectedareas-badrama>
                                                                <app-protectedareas-baisipalli *ngIf="PAid=='8'"></app-protectedareas-baisipalli>
                                                                <app-protectedareas-balukhand-konark *ngIf="PAid=='1'"></app-protectedareas-balukhand-konark>
                                                                <app-protectedareas-bhitarakanika *ngIf="PAid=='17'"></app-protectedareas-bhitarakanika>
                                                                <app-protectedareas-chandaka-dampada *ngIf="PAid=='10'"></app-protectedareas-chandaka-dampada>
                                                                <app-protectedareas-chilika-nalaban *ngIf="PAid=='16'"></app-protectedareas-chilika-nalaban>
                                                                <app-protectedareas-debrigarh *ngIf="PAid=='2'"></app-protectedareas-debrigarh>
                                                                <app-protectedareas-gahirmatha-marine *ngIf="PAid=='18'"></app-protectedareas-gahirmatha-marine>
                                                                <app-protectedareas-hadgarh *ngIf="PAid=='15'"></app-protectedareas-hadgarh>
                                                                <app-protectedareas-kapilash *ngIf="PAid=='14'"></app-protectedareas-kapilash>
                                                                <app-protectedareas-karlapat *ngIf="PAid=='7'"></app-protectedareas-karlapat>
                                                                <app-protectedareas-khalasuni *ngIf="PAid=='5'"></app-protectedareas-khalasuni>
                                                                <app-protectedareas-kuldiha *ngIf="PAid=='19'"></app-protectedareas-kuldiha>
                                                                <app-protectedareas-kotgarh *ngIf="PAid=='4'"></app-protectedareas-kotgarh>
                                                                <app-protectedareas-lahkeri-valley *ngIf="PAid=='13'"></app-protectedareas-lahkeri-valley>
                                                                <app-protectedareas-nandankanan *ngIf="PAid=='11'"></app-protectedareas-nandankanan>
                                                                <app-protectedareas-satkosia-gorge *ngIf="PAid=='9'"></app-protectedareas-satkosia-gorge>
                                                                <app-protectedareas-similipal *ngIf="PAid=='12'"></app-protectedareas-similipal>
                                                                <app-protectedareas-sunabeda *ngIf="PAid=='3'"></app-protectedareas-sunabeda>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      </div>
      <div id="footer">
        <app-masterfooter></app-masterfooter>
      </div>



</div>

