import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';

declare var jQuery: any;
declare var Swiper : any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, private apiService: ApiService) { }

  ngOnInit(): void {
	const userdata=sessionStorage.getItem('user');
    if(userdata!=null){
		this.router.navigate(['/dashboard']);
       } else{
      this.router.navigate(['']);
       return;
    }
  	(function($){
		// $('#fullpage').fullpage({
		//   //sectionsColor: ['#B8AE9C', '#348899', '#F2AE72', '#5C832F', '#B8B89F'],
		//   sectionSelector: '.vertical-scrolling',
		//   slideSelector: '.horizontal-scrolling',
		//   navigation: true,
		//   slidesNavigation: true,
		//   controlArrows: false,
		// });
		// $('.scrollup').css("display", "none");


		// $(document).ready(function() {

		// 	if ($(window).width() < 700) {

		// 		$('.fp-tableCell').css('height','auto');
		// 		$('html.fp-enabled').css('height','auto');
		// 		$('html.fp-enabled').css('overflow','auto');
		// 		$('.fp-enabled body').css('height','auto');
		// 		$('.fp-enabled body').css('overflow','auto');

		// 	}

		// 	})


    })(jQuery);


  }
}
