
<div class="dual-gd-section">

	<div id="fp-nav" class="fp-show-active right" style="margin-top: 0px;">
	    <ul>
			<li>
				<a [routerLink]="['/']"><span></span></a>
				<a [routerLink]="['/']" class="fp-tooltip right">Amazing Wildlife</a>
			</li>
			<li>
				<a [routerLink]="['/slidercarousel']"><span></span></a>
				<a [routerLink]="['/slidercarousel']" class="fp-tooltip right">Discover Wildlife Odisha</a>
			</li>
			<li>
				<a [routerLink]="['/homecontent']" ><span></span></a>
				<a [routerLink]="['/homecontent']" class="fp-tooltip right">About Wildlife</a>
			</li>
			<li>
				<a [routerLink]="['/blogall']" class="active"><span></span></a>
				<a [routerLink]="['/blogall']" class="fp-tooltip right active">Blog / Publication</a>
			</li>
			<li>
				<a [routerLink]="['/filtergallery']"><span></span></a>
				<a [routerLink]="['/filtergallery']" class="fp-tooltip right">Gallery</a>
			</li>
			<li>
				<a [routerLink]="['/footerlanding']"><span></span></a>
				<a [routerLink]="['/footerlanding']" class="fp-tooltip right">Quick Links</a>
			</li>
		</ul>
	</div>

	<app-login></app-login>

	<app-blogpublication></app-blogpublication>
	<app-blog></app-blog>

</div>
