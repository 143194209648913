import { Component, OnInit } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-barchartmultipleone',
  templateUrl: './barchartmultipleone.component.html',
  styleUrls: ['./barchartmultipleone.component.css']
})
export class BarchartmultipleoneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  	(function($){
      	
  		google.charts.load('current', {packages: ['corechart', 'bar']});
		google.charts.setOnLoadCallback(drawBasic);

		function drawBasic() {

		      var data = google.visualization.arrayToDataTable([
		        ['Area', 'Deaths',],
		        ['Balasore', 10],
		        ['Baliguda', 0],
		        ['Bamra', 0],
		        ['Bhadrak', 1],
		        ['Chandaka', 0],
		        ['Chilika', 0],
		        ['Dhenkanal', 9],
		        ['Hirakud', 5],
		        ['Kalahandi', 0],
		        ['Keonjhar', 0],
		        ['Mahanadi', 1],
		        ['Nandankanan', 0],
		        ['Puri', 1],
		        ['Satkosia', 4],
		        ['Similipal', 1],
		        ['Sunabeda', 1]
		      ]);

		      var options = {
		        title: 'Animal Death',
		        chartArea: {width: '50%'},
		        hAxis: {
		          title: 'No. of Deaths',
		          minValue: 0
		        },
		        vAxis: {
		          title: 'Area'
		        }
		      };

		      var chart = new google.visualization.BarChart(document.getElementById('verticalBarChartOne'));

		      chart.draw(data, options);
		    }

    })(jQuery);
  }

}
