import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { GoogleChartsModule } from 'angular-google-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from 'angular-datatables';
import { ChartsModule } from '@rinminase/ng-charts';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ApiService } from './service/api.service';
import { UploadService } from './upload/upload.service';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { TokenInterceptor } from './Interceptor/token.interceptor';
import { ErrorInterceptor } from './Interceptor/error.interceptor';
import { AuthorizationCheckService } from './service/authorization-check.service'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrModule } from 'ngx-toastr';
import { MatSelectModule } from '@angular/material/select';
import { HomeComponent } from './home/home.component';
import { SlidermainComponent } from './slidermain/slidermain.component';
import { SlidergalleryComponent } from './slidergallery/slidergallery.component';
import { SlidernewsComponent } from './slidernews/slidernews.component';
import { SlidercarouselComponent } from './slidercarousel/slidercarousel.component';
import { HomecontentComponent } from './homecontent/homecontent.component';
import { HomeinfoComponent } from './homeinfo/homeinfo.component';
import { BlogComponent } from './blog/blog.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MasterheaderComponent } from './masterheader/masterheader.component';
import { MasterfooterComponent } from './masterfooter/masterfooter.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BarchartComponent } from './barchart/barchart.component';
import { PiechartComponent } from './piechart/piechart.component';
import { BarchartmultipleoneComponent } from './barchartmultipleone/barchartmultipleone.component';
import { BarchartmultipletwoComponent } from './barchartmultipletwo/barchartmultipletwo.component';
import { ManageprimarylinkComponent } from './manageprimarylink/manageprimarylink.component';
import { AddrolemanagementComponent } from './addrolemanagement/addrolemanagement.component';
import { ViewrolemanagementComponent } from './viewrolemanagement/viewrolemanagement.component';
import { UsermanagementComponent } from './usermanagement/usermanagement.component';
import { ElephantmovementrecordComponent } from './elephantmovementrecord/elephantmovementrecord.component';
import { ElephantcorridormapComponent } from './elephantcorridormap/elephantcorridormap.component';
import { DashboarduserComponent } from './dashboarduser/dashboarduser.component';
import { WlOrganisationComponent } from './wl-organisation/wl-organisation.component';
import { VisionComponent } from './vision/vision.component';
import { OrganogramComponent } from './organogram/organogram.component';
import { ResearchComponent } from './research/research.component';
import { WildlifeCensusComponent } from './wildlife-census/wildlife-census.component';
import { CorridorsComponent } from './corridors/corridors.component';
import { PublicationComponent } from './publication/publication.component';
import { ProjectelephantComponent } from './projectelephant/projectelephant.component';
import { ProjecttigerComponent } from './projecttiger/projecttiger.component';
import { CrocodileconservationComponent } from './crocodileconservation/crocodileconservation.component';
import { SeaturtleconservationComponent } from './seaturtleconservation/seaturtleconservation.component';
import { BlackbuckconservationComponent } from './blackbuckconservation/blackbuckconservation.component';
import { MangrovesComponent } from './mangroves/mangroves.component';
import { CommonelementComponent } from './commonelement/commonelement.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { EcotourismComponent } from './ecotourism/ecotourism.component';
import { MananimalinterfaceComponent } from './mananimalinterface/mananimalinterface.component';
import { ImportantcircularsComponent } from './importantcirculars/importantcirculars.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { InnewsComponent } from './innews/innews.component';
import { NoticesComponent } from './notices/notices.component';
import { InfodashboardComponent } from './infodashboard/infodashboard.component';
import { FiltergalleryComponent } from './filtergallery/filtergallery.component';
import { BlogpublicationComponent } from './blogpublication/blogpublication.component';
import { ShortfilmsprotectedareasComponent } from './shortfilmsprotectedareas/shortfilmsprotectedareas.component';
import { ProtectedareasodishaComponent } from './protectedareasodisha/protectedareasodisha.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ElephantanalyticsComponent } from './elephantanalytics/elephantanalytics.component';
import { ReportsComponent } from './reports/reports.component';
import { ElephantanalyticsintrendComponent } from './elephantanalyticsintrend/elephantanalyticsintrend.component';
import { CmsComponent } from './cms/cms.component';
import { ViewcmsComponent } from './viewcms/viewcms.component';
import { BlogdetailsComponent } from './blogdetails/blogdetails.component';
import { BlogapplicationComponent } from './blogapplication/blogapplication.component';
import { CustomHtmldecoderPipePipe } from './custom-htmldecoder-pipe.pipe';
import { GajabandhuComponent } from './gajabandhu/gajabandhu.component';
import { TenderComponent } from './tender/tender.component';
import { ElephantwebreportingComponent } from './elephantwebreporting/elephantwebreporting.component';
import { FeedbackviewComponent } from './feedbackview/feedbackview.component';
import { NgxPrintModule } from 'ngx-print';
import { CitizenuserComponent } from './citizenuser/citizenuser.component';
import { HeaderlandingComponent } from './headerlanding/headerlanding.component';
import { AuthorityuserComponent } from './authorityuser/authorityuser.component';
import { BlogallComponent } from './blogall/blogall.component';
import { FooterlandingComponent } from './footerlanding/footerlanding.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SharedhomeModule } from './sharedhome/sharedhome.module';
import { WildlifeunitsModule } from './wildlifeunits/wildlifeunits.module';
import { ProtectedareasModule } from './protectedareas/protectedareas.module';
import { MprComponent } from './mpr/mpr.component';
import { InfoModalComponent } from './modal/info-modal/info-modal.component';
import { TimeoutModalComponent } from './modal/timeout-modal/timeout-modal.component';
import { AuditreportComponent } from './auditreport/auditreport.component';
import { SmsauditComponent } from './smsaudit/smsaudit.component';
import { CitizenauditComponent } from './citizenaudit/citizenaudit.component';
import { LoginreportauditComponent } from './loginreportaudit/loginreportaudit.component';
import { ControlroominfoComponent } from './controlroominfo/controlroominfo.component';
import { LoaderInterceptor } from './Interceptor/loader.interceptor';
import { ElephantauditComponent } from './elephantaudit/elephantaudit.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PopupbannerComponent } from './popupbanner/popupbanner.component';
import { ManagesquardComponent } from './managesquard/managesquard.component';
import { SquardreportComponent } from './squardreport/squardreport.component';
import { ProblematicelphantComponent } from './problematicelphant/problematicelphant.component';
import { EventComponent } from './event/event.component';
import { SquadreportdashboardComponent } from './squadreportdashboard/squadreportdashboard.component';
import { AporeportComponent } from './aporeport/aporeport.component';


// import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SlidermainComponent,
    SlidergalleryComponent,
    SlidernewsComponent,
    SlidercarouselComponent,
    HomecontentComponent,
    HomeinfoComponent,
    BlogComponent,
    DashboardComponent,
    SidebarComponent,
    MasterheaderComponent,
    MasterfooterComponent,
    BreadcrumbComponent,
    BarchartComponent,
    PiechartComponent,
    BarchartmultipleoneComponent,
    BarchartmultipletwoComponent,
    ManageprimarylinkComponent,
    AddrolemanagementComponent,
    ViewrolemanagementComponent,
    UsermanagementComponent,
    ElephantmovementrecordComponent,
    ElephantcorridormapComponent,
    DashboarduserComponent,
    WlOrganisationComponent,
    VisionComponent,
    OrganogramComponent,
    ResearchComponent,
    WildlifeCensusComponent,
    CorridorsComponent,
    PublicationComponent,
    ProjectelephantComponent,
    ProjecttigerComponent,
    CrocodileconservationComponent,
    SeaturtleconservationComponent,
    BlackbuckconservationComponent,
    MangrovesComponent,
    CommonelementComponent,
    ProfileComponent,
    ChangepasswordComponent,
    EcotourismComponent,
    MananimalinterfaceComponent,
    ImportantcircularsComponent,
    NotificationsComponent,
    InnewsComponent,
    NoticesComponent,
    InfodashboardComponent,
    FiltergalleryComponent,
    BlogpublicationComponent,
    ShortfilmsprotectedareasComponent,
    ProtectedareasodishaComponent,
    GalleryComponent,
    ElephantanalyticsComponent,
    ReportsComponent,
    ElephantanalyticsintrendComponent,
    CmsComponent,
    ViewcmsComponent,
    BlogdetailsComponent,
    BlogapplicationComponent,
    CustomHtmldecoderPipePipe,
    GajabandhuComponent,
    TenderComponent,
    ElephantwebreportingComponent,
    FeedbackviewComponent,
    CitizenuserComponent,
    HeaderlandingComponent,
    AuthorityuserComponent,
    BlogallComponent,
    FooterlandingComponent,
    NotFoundComponent,
    MprComponent,
    InfoModalComponent,
    TimeoutModalComponent,
    AuditreportComponent,
    SmsauditComponent,
    CitizenauditComponent,
    LoginreportauditComponent,
    ControlroominfoComponent,
    ElephantauditComponent,
    PrivacyPolicyComponent,
    PopupbannerComponent,
    ManagesquardComponent,
    SquardreportComponent,
    ProblematicelphantComponent,
    EventComponent,
    SquadreportdashboardComponent,
    AporeportComponent,
  ],
  imports: [
    BrowserModule,
    MatInputModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DataTablesModule,
    ChartsModule,
    GoogleChartsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPrintModule,
    HttpClientModule, MatSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    SharedhomeModule.forRoot(),
    WildlifeunitsModule.forRoot(),
    ProtectedareasModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-custom',
      preventDuplicates: true,
    })
  ],
  providers: [DatePipe, AuthorizationCheckService, ApiService, UploadService, CommonelementComponent,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    // { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
