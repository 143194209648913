<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-3">

				<div class="custom-menu-inner">
					<h4 class="card-title">Projects & Conservation</h4>
					<ul>
						<li class="active"><a [routerLink]="['/projectelephant']">Project Elephant</a></li>
						<li><a [routerLink]="['/projecttiger']">Project Tiger</a></li>
						<li><a [routerLink]="['/crocodileconservation']">Crocodile Conservation</a></li>
						<li><a [routerLink]="['/seaturtleconservation']">Sea Turtle Conservation</a></li>
						<li><a [routerLink]="['/blackbuckconservation']">Blackbuck Conservation</a></li>
						<li><a [routerLink]="['/mangroves']">Mangroves</a></li>
					</ul>
				</div>

			</div>
			<div class="col-md-9">

				<h4 class="card-title">Elephant Reserve (Er) Network</h4>

				<img src="assets/images/elephntRes.jpg" class="img-fluid float-right img-inner" alt="image">
				<p>Elephant (Elephas maximus) being the largest terrestrial mammal requires large areas, and hence, is
					usually one of the first species to suffer the consequences of habitat fragmentation and
					destruction. The Elephant Reserves offers hope to rejuvenate some of the fragmented habitats.</p>
				<p>In the state of Odisha, where many forest habitats are shared by the tiger and elephant, the
					establishments of ERs have raised the hope to unify fragmented tiger habitats as well.</p>
				<p>Three Elephant Reserves (ERs) namely, Mayurbhanj ER, Mahanadi ER and Sambalpur ER, have been notified
					by the State of Odisha to conserve prime elephant habitats and launch various management
					interventions. The extent of notified and proposed forest blocks in these three reserves is 4129 sq.
					km, and the geographical area covered by these three reserves is 8509 sq. km. In order to (a)
					include most of the remaining important elephant habitats within ERs, (b) redress the problem of
					rising number of cases of elephant depredation and (c) reverse the trend of habitat fragmentation
					and degradation, it is proposed to expand the area of Mahanadi and Sambalpur ERs, and constitute two
					new ERs called the Brahmani-Baitarani ER and the South Odisha ER. After declaration, the forest area
					encompassed within ERs will be about 25% of the total forest area of the state; and about 90% of the
					elephants will be within these Reserves.</p>

				<h4 class="card-title">Mayurbhanj Elephant Reserve(Constituted: September 2001)</h4>
				<h5>Location</h5>
				<div class="table-responsive">
					<table class="table table-sm table-bordered">
						<tbody>
							<tr>
								<td><strong>Districts : </strong></td>
								<td>Portions of Mayurbhanj, Balasore, Bhadrak and Keonjhar</td>
							</tr>
							<tr>
								<td><strong>Forest Division : </strong></td>
								<td>Similipal Tiger Reserve, Karanjia, Baripada, Rairangpur, Balasore WL,Keonjhar WL
									Division</td>
							</tr>
							<tr>
								<td><strong>Latitude North : </strong></td>
								<td>21° 10' to 22° 35'</td>
							</tr>
							<tr>
								<td><strong>Longitude East : </strong></td>
								<td>85° 45' to 87° 05'</td>
							</tr>
							<tr>
								<td><strong>Area : </strong></td>
								<td>7043.74 sq. km</td>
							</tr>
						</tbody>
					</table>
				</div>

				<h4 class="card-title">Mahanadi Elephant Reserve(Constituted: June, 2002)</h4>
				<h5>Location</h5>
				<div class="table-responsive">
					<table class="table table-sm table-bordered">
						<tbody>
							<tr>
								<td><strong>Districts : </strong></td>
								<td>Angul, Cuttack, Nayagarh, Kondhmal, Dhenkanal</td>
							</tr>
							<tr>
								<td><strong>Forest Division : </strong></td>
								<td>Portions of Angul, Athagarh, Cuttack, Nayagarh, Boudh and Dhenkanal, and entire
									Satkosia Wildlife Division, Mahanadi Wildlife Division and Athamallik Forest
									Division</td>
							</tr>
							<tr>
								<td><strong>Latitude North : </strong></td>
								<td>20° 10´ to 21° 5´</td>
							</tr>
							<tr>
								<td><strong>Longitude East : </strong></td>
								<td>84° 15´ to 85° 58´</td>
							</tr>
							<tr>
								<td><strong>Area : </strong></td>
								<td>1038.30 sq km</td>
							</tr>
						</tbody>
					</table>
				</div>

				<h4 class="card-title">Sambalpur Elephant Reserve(Constituted: March, 2002)</h4>
				<h5>Location</h5>
				<div class="table-responsive">
					<table class="table table-sm table-bordered">
						<tbody>
							<tr>
								<td><strong>Districts : </strong></td>
								<td>Sambalpur, Sundargarh, Jharsuguda</td>
							</tr>
							<tr>
								<td><strong>Forest Division : </strong></td>
								<td>(Parts) of Bamra (Wildlife), Bonai, Sambalpur, Jharsuguda, Rairakhol</td>
							</tr>
							<tr>
								<td><strong>Latitude North : </strong></td>
								<td>20° 5’ and 22° 12</td>
							</tr>
							<tr>
								<td><strong>Longitude East : </strong></td>
								<td>83° 13’ and 84° 58</td>
							</tr>
							<tr>
								<td><strong>Area : </strong></td>
								<td>426.91 sq km</td>
							</tr>
						</tbody>
					</table>
				</div>

				<img src="assets/images/eleph.map.jpg" class="img-fluid" alt="image">

				<p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
						</strong>The information contained herein are provided solely for informational purposes
						only.The content is dynamic and may undergo change from time to time. The snapshot of the
						content at a particular time is not to be referred as a legally binding document.</small></p>

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>