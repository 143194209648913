import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart,NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  public title: string ="";
  isDashboard:boolean=false;
  isUserManage:boolean=false;
  constructor(private router: Router) {
    // on route change to '/login', set the variable showHead to false
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event['url'] == '/dashboarduser') {
           //this.isDashboard=true;
          this.title = 'User Dashboard';
        } else if (event['url'] == '/dashboard') {
          this.title = 'Elephant Dashboard';
        }  else if (event['url'] == '/elephantcorridormap') {
          this.title = 'Map View';
        } else if (event['url'] == '/elephantmovementrecord') {
            this.title = 'Elephant Movement Record';
        } else if (event['url'] == '/usermanagement') {
          this.isUserManage=true;
          this.title = 'Manage User';
        }
        else if (event['url'] == '/viewrolemanagement') {
          this.isUserManage=true;
          this.title = 'Role Management';
        }
        else if (event['url'] == '/profile') {
          this.title = 'Profile Settings';
        }
        else if (event['url'] == '/changepassword') {
          this.title = 'Change Password';
        }else if (event['url'] == '/reports') {
          this.title = 'Reports';
        }else if (event['url'] == '/elephantanalytics') {
          this.title = 'Elephant Movement Analytics';
        }else if (event['url'] == '/cms') {
          this.title = 'Content Management System';
        }
      }
    });
    }

  ngOnInit(): void {

  }

}
