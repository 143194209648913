<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<app-masterheader></app-masterheader>

<div class="wrapper">

  <app-sidebar></app-sidebar>

  <div id="content">

    <!-- <app-breadcrumb></app-breadcrumb> -->

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Elephant Web Reporting</h4>

                  <form [formGroup]="repotingForm" (ngSubmit)="onSubmitrepotingForm()">
                    <div class="row form-group">
                      <div class="col-md-3">
                        <label>Type <span class="text-danger">*</span></label>
                        <select class="form-control" formControlName="type" id="type"
                          (change)="onTypeSelect(TypeSelect.value)" #TypeSelect>
                          <option selected="selected" value="0">-- Select --</option>
                          <option value="direct">Direct</option>
                          <option value="indirect">Indirect</option>
                          <option value="nill">Nill</option>
                        </select>
                      </div>
                    </div>
                    <div class="row form-group">
                      
                          <div class="col-md-2">
                            <label>Date <span class="text-danger">*</span></label>
                            <div id="datepicker" class="input-group date" data-date-format="dd-mm-yyyy"
                              title="From Date">
                              <input class="form-control" formControlName="fromDate" #from_Date id="from_Date"
                                type="text" />
                              <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <label>From Time <span class="text-danger">*</span></label>
                            <div class="input-group clockpicker">
                              <input type="text" class="form-control" value="09:30" formControlName="fromtime"
                                #from_time id="from_time">
                              <span class="input-group-addon">
                                <span class="fa fa-clock-o"></span>
                              </span>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <label>To Time <span class="text-danger">*</span></label>
                            <div class="input-group clockpicker">
                              <input type="text" class="form-control" value="09:30" formControlName="totime" #to_time
                                id="to_time">
                              <span class="input-group-addon">
                                <span class="fa fa-clock-o"></span>
                              </span>
                            </div>
                          </div>
                        
                          <div class="col-md-2">
                            <label>Location <span class="text-danger">*</span></label>
                            <input type="text" formControlName="location" id="location" class="form-control"
                              placeholder="Location" required>
                          </div>
                          <div class="col-md-2">
                            <label>Latitude <span class="text-danger">*</span></label>
                            <input type="text" formControlName="latitude" id="latitude"
                              [ngClass]="{ 'is-invalid': repotingForm.controls['latitude'].invalid && (repotingForm.controls['latitude'].dirty || repotingForm.controls['latitude'].touched) }"
                              class="form-control" placeholder="Latitude" required>
                            <div
                              *ngIf="repotingForm.controls['latitude'].invalid && (repotingForm.controls['latitude'].dirty || repotingForm.controls['latitude'].touched)"
                              class="text-danger mt-1">
                              <small *ngIf="repotingForm.controls['latitude'].errors.required">
                                latitude is required.
                              </small>
                              <small *ngIf="repotingForm.controls['latitude'].errors.pattern">
                                Enter Valid latitude.
                              </small>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <label>Longitude <span class="text-danger">*</span></label>
                            <input type="text" formControlName="longitude" id="longitude"
                              [ngClass]="{ 'is-invalid': repotingForm.controls['longitude'].invalid && (repotingForm.controls['longitude'].dirty || repotingForm.controls['longitude'].touched) }"
                              class="form-control" placeholder="Longitude" required>
                              <small><a href="#mapmodal" data-toggle="modal" data-target="#mapmodal">click here for get Lat/Long</a></small>
                            <div
                              *ngIf="repotingForm.controls['longitude'].invalid && (repotingForm.controls['longitude'].dirty || repotingForm.controls['longitude'].touched)"
                              class="text-danger mt-1">
                              <small *ngIf="repotingForm.controls['longitude'].errors.required">
                                longitude is required.
                              </small>
                              <small *ngIf="repotingForm.controls['longitude'].errors.pattern">
                                Enter Valid longitude.
                              </small>
                            </div>
                          </div>
                        
                      <!-- <div class="col-md-2">
                        <span class="custom-or">- OR -</span>
                        <button type="submit" class="btn btn-sm btn-success custom-btn-top"><i class="fa fa-map">&nbsp;</i>Get From Map</button>
                      </div> -->


                    </div>
                    <div *ngIf="isShownrepotingFormDiv">
                      <div class="row form-group">
                        <div class="col-md-3" *ngIf="isShownReporting">
                          <label>Select Indirect Reporting Type :<span class="text-danger">*</span></label>
                          <select class="form-control" formControlName="indirectReportingType"
                            id="indirectReportingType"
                            (change)="onIndirectReportingTypeSelect(indirectReportingTypeSelect.value)"
                            #indirectReportingTypeSelect>
                            <option selected="selected" value="0">-- Select --</option>
                            <option value="Fresh Dungs">Fresh Dungs</option>
                            <option value="Fresh Footprints">Fresh Footprints</option>
                            <option value="Trumpeting call/Sound">Trumpeting call/Sound</option>
                            <option value="Others">Others</option>
                          </select>
                        </div>
                        <div class="col-md-3" *ngIf="isTrumptingSound">
                          <label>Trumpting Direction <span class="text-danger">*</span></label>
                          <input type="text" formControlName="trumptingDirection" id="trumptingDirection" class="form-control"
                            placeholder="Trumpting Direction">
                            <small>e.g 200 SE</small>
                        </div>
                        <div class="col-md-3" *ngIf="isTrumptingSound">
                          <label>Trumpting Approx Distance in  Meters <span class="text-danger">*</span></label>
                          <input type="number" formControlName="trumptingApproxDistance" id="trumptingApproxDistance" class="form-control"
                            placeholder="Trumpting Approx Distance">
                            <small>e.g 500</small>
                        </div>
                        <div class="col-md-3">
                          <label>Remarks <span class="text-danger">*</span></label>
                          <textarea formControlName="remarks" id="remarks" class="form-control custom-textarea"
                          placeholder="Remarks"></textarea>
                        </div>
                        <div class="col-md-3" *ngIf="isShownImgUpload">
                          <label>Image Upload <span class="text-danger">*</span></label>
                          <input type="file" formControlName="imgPath" accept='image/*' id="imgPath"
                            (change)="selectFile($event)" class="form-control" placeholder="Image Upload">
                        </div>
                      </div>
                      <div class="row form-group" *ngIf="isShownDivRngSecDropdown">
                        <div class="col-md-3">
                          <label>Division <span class="text-danger">*</span></label>
                          <select class="form-control" id="divisionwl" formControlName="division"
                            (change)="getAllRangeByDivId(divisionSelect.value)" [(ngModel)]='divid'
                            [disabled]="divisionDisabled" #divisionSelect required>
                            <option value="0">All Division</option>
                            <option *ngFor="let division of divisionlist " value={{division.divisionId}}>
                              {{division.divisionName}}
                            </option>
                          </select>
                        </div>
                        <div class="col-md-3">
                          <label>Range <span class="text-danger">*</span></label>
                          <select class="form-control" id="rangewl" formControlName="range"
                            (change)="getAllSectionByRangeId(rangeSelect.value)" [(ngModel)]='rangeid' #rangeSelect
                            required>
                            <option value="0">All Range</option>
                            <option *ngFor="let range of rangelist " value={{range.rangeId}}>
                              {{range.rangeName}}
                            </option>
                          </select>
                        </div>
                        <div class="col-md-3">
                          <label>Section <span class="text-danger">*</span></label>
                          <select class="form-control" id="sectionwl" formControlName="section"
                            (change)="getAllBeatBySectionId(sectionSelect.value)" [(ngModel)]='sectionid' #sectionSelect
                            required>
                            <option value="0">All Section</option>
                            <option *ngFor="let section of sectionlist " value={{section.secId}}>
                              {{section.secName}}
                            </option>
                          </select>
                        </div>
                        <div class="col-md-3">
                          <label>Beat <span class="text-danger">*</span></label>
                          <select class="form-control" id="beatwl" formControlName="beat" [(ngModel)]='beatid'
                            #beatSelect required>
                            <option value="0">All Beat</option>
                            <option *ngFor="let beat of beatlist" value={{beat.beatId}}>
                              {{beat.beatName}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <hr>
                      <div class="row form-group" *ngIf="isShownNumberBox">
                        <div class="col-md-11">
                          <div class="row">
                            <div class="col-md-4" *ngIf="isTuskerInfo">
                              <div class="border pt-2 pb-2 pl-3 pr-3">
                                <div class="row">
                                  <div class="col">
                                    <label>Adult Tusker</label>
                                    <input type="number" class="form-control" formControlName="tuskerAdult" id="adulttuskerwl"
                                      placeholder="Adult Tusker" [(ngModel)]='tuskerAdult' (change)="getSumValue()">
                                    <span class="sign-separator">+</span>
                                  </div>
                                  <div class="col">
                                    <label>Sub Adult Tusker</label>
                                    <input type="number" class="form-control" formControlName="tuskerSubAdult" id="subadulttuskerwl"
                                      placeholder=" Sub Adult Tusker" [(ngModel)]='tuskerSubAdult' (change)="getSumValue()">
                                    <span class="sign-separator">=</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-1 pt-2">
                              <label>Tusker</label>
                              <input type="number" class="form-control" formControlName="tusker" id="tuskerwl"
                                placeholder="Tusker" [(ngModel)]='tusker' (change)="getSumValue()">
                              <span class="sign-separator pt-2">+</span>
                            </div>
                            <div class="col-md-1 pt-2">
                              <label>Makhna</label>
                              <input type="number" class="form-control" formControlName="makhna" id="makhnawl"
                                placeholder="Makhna" [(ngModel)]='makhna' (change)="getSumValue()">
                              <span class="sign-separator pt-2">+</span>
                            </div>
                            <div class="col-md-1 pt-2">
                              <label>Female</label>
                              <input type="number" class="form-control" formControlName="female" id="femalewl"
                                placeholder="Female" [(ngModel)]='female' (change)="getSumValue()">
                              <span class="sign-separator pt-2">+</span>
                            </div>
                            <div class="col-md-1 pt-2">
                              <label>Calf</label>
                              <input type="number" class="form-control" formControlName="calf" id="calfwl"
                                placeholder="Calf" [(ngModel)]='calf' (change)="getSumValue()">
                              <span class="sign-separator pt-2">=</span>
                            </div>
                            <div class="col-md-3 pt-2">
                              <label>Total Elephant</label>
                              <input type="number" class="form-control" formControlName="total" id="totalwl"
                                placeholder="Total" [(ngModel)]='total' [disabled]="totalNumberDisabled">

                            </div>
                          </div>
                        </div>
                        <div class="col-md-1 pt-2">
                          <label>Herd</label>
                          <input type="number" class="form-control" formControlName="herd" id="herdwl"
                            placeholder="Herd">
                        </div>
                        
                      </div>

                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <button type="submit" class="btn btn-sm btn-success" [disabled]="repotingForm.invalid">Submit</button>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="modal fade" id="mapmodal" tabindex="-1" role="dialog"
              aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
     <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLongTitle">Get Latitude and Longitude</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body mb-0">
          <div id="map" class="map" style="height:540px"></div>
          <div id="popup" class="ol-popup">
            <a href="#" id="popup-closer" class="ol-popup-closer"></a>
            <div id="popup-content"></div>
        </div>
      </div>
    </div>
  </div>

  </div>

  <div id="footer">
    <app-masterfooter></app-masterfooter>
  </div>

</div>