<div class="loader-base" *ngIf="loader">
	<div class="loader"></div>
</div>

<app-masterheader></app-masterheader>
<div class="wrapper">

	<app-sidebar></app-sidebar>

	<div id="content">

		<!-- <app-breadcrumb></app-breadcrumb> -->

		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12">

					<div class="row">
						<div class="col-md-2">
							<!-- <button id="queryBtn" class="btn btn-sm btn-query pl-2">Advanced Filter<i class="fa fa-chevron-down rotate">&nbsp;</i></button> -->
							<div id="queryContent" class="query-content">
								<div class="row">
									<div class="col-md-12">
										<div class="card">
											<div class="card-body">
												<h4 class="card-title">Filter</h4>
												<div class="row">
													<div class="col-md-12">
														<div class="form-group mb-2">
															<!-- <label for="">Division <span class="text-danger">*</span></label> -->
															<select class="form-control" id="circlewl"
																(change)="circleFilter(circleSelect.value)"
																[(ngModel)]='circleid' [disabled]="circleDisabled" #circleSelect >
																<option value="0">All Circle</option>
																<option *ngFor="let circle of circlelist "
																	value={{circle.circleId}}>
																	{{circle.circleName}}
																</option>
															</select>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-12">
														<div class="form-group mb-2">
															<!-- <label for="">Division <span class="text-danger">*</span></label> -->
															<select class="form-control" id="divisionwl"
																(change)="DivisionFilter(divisionSelect.value)"
																[(ngModel)]='divid' [disabled]="divisionDisabled" #divisionSelect>
																<option value="0">All Division</option>
																<option *ngFor="let division of divisionlist "
																	value={{division.divisionId}}>
																	{{division.divisionName}}
																</option>
															</select>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-12">
														<div class="form-group mb-2">
															<!-- <label for="">Range <span class="text-danger">*</span></label> (change)="Rangefilter(rangeSelect.value)" -->
															<select class="form-control" id="rangewl"
																(change)="Rangefilter(rangeSelect.value)"
																[(ngModel)]='rangeid'  [disabled]="rangeDisabled" #rangeSelect>
																<option value="0">All Range</option>
																<option *ngFor="let item of rangelist "
																	value={{item.rangeId}}>
																	{{item.rangeName}}
																</option>
															</select>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-12">
														<div class="form-group mb-2">
															<!-- <label for="">Section <span class="text-danger">*</span></label> (change)="sectionsfilter(sectionsSelect.value)" -->
															<select class="form-control" id="sectionwl"
																(change)="SectionFilter(sectionsSelect.value)"
																[(ngModel)]='sectnid' #sectionsSelect>
																<option value="0">All Section</option>
																<option *ngFor="let item of sectionlist "
																	value={{item.secId}}>
																	{{item.secName}}
																</option>
															</select>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-12">
														<div class="form-group mb-2">
															<!-- <label for="">Beat <span class="text-danger">*</span></label> (change)="beatfilter(beatSelect.value)"  -->
															<select class="form-control" id="beatwl"
																(change)="BeatFilter(beatSelect.value)"
																[(ngModel)]='beatid' #beatSelect>
																<option value="0">All Beat</option>
																<option *ngFor="let item of beatlist "
																	value={{item.beatId}}>
																	{{item.beatName}}
																</option>
															</select>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-12">
														<div class="form-group mb-2">
															<!-- <label for="">Query <span class="text-danger">*</span></label> -->
															<div id="datepicker" class="input-group date"
																data-date-format="yyyy-mm-dd" title="Start Date">
																<input class="form-control" #sd [(ngModel)]="Sdate"
																	id="startDate" type="text" />
																<span class="input-group-addon"><i
																		class="mdi mdi-calendar"></i></span>
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-12">
														<div class="form-group mb-2">
															<!-- <label for="">Query <span class="text-danger">*</span></label> -->
															<div id="datepicker2" class="input-group date"
																data-date-format="yyyy-mm-dd" title="End Date">
																<input class="form-control" #ed id="endDate"
																	[(ngModel)]="Edate" type="text" />
																<span class="input-group-addon"><i
																		class="mdi mdi-calendar"></i></span>
															</div>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-12">
														<div class="form-group mb-2">
															<!--	(change)="querySelectfn(queryselect.value)" <label for="">Query <span class="text-danger">*</span></label> (change)="querySelectfn(queryselect.value)" -->
															<select class="form-control"  	[(ngModel)]="queryselect" >
																<option value="0" selected="selected">Select Query
																</option>
																<option value="1">Elephant Sighted</option>

															</select>
														</div>
													</div>
												</div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group text-center">
                              <button class="btn btn-sm btn-success mr-2" type="button" (click)="search()"><i class="fa fa-search">&nbsp;</i>Search Query</button>
                              <button class="btn btn-sm btn-warning" type="button" (click)="reset()"><i class="fa fa-refresh">&nbsp;</i>Reset</button>
                            </div>
                          </div>
                       </div>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>

						<div class="col-md-10">
							<div class="card">
								<div class="card-body">
									<h4 class="card-title">Map View</h4>
									<!-- (click)="closepopup()" -->
									<!-- <div id="popup" title="Welcome to OpenLayers"></div>  -->
									<div id="popup" class="ol-popup">
										<a href="javascript:void(0)" id="popup-closer" class="ol-popup-closer"></a>
										<div id="popup-content"></div>
									</div>
									<div class="fixHeightLeft">
										<div id="map" #map class="map fixHeightLeft"></div>
									</div>
									<div class="mapFilterBase col-md-3 col-sm-4">

										<div class="card">
											<div class="card-body">

												<div id="accordion" class="mapFilter">
													<a id="layerDiv" href="#collapseOne" class="text-collapse"
														data-toggle="collapse" aria-expanded="true"
														aria-controls="collapseOne">
														<span class="d-block"><img src="assets/images/icon-layers.png"
																style="width:20px"></span>
													</a>
													<a id="legendDiv" href="#collapseTwo"
														class="text-collapse collapsed" data-toggle="collapse"
														aria-expanded="false" aria-controls="collapseTwo">
														<span class="d-block"><img src="assets/images/icon-legends.png"
																style="width:20px"></span>
													</a>
													<a id="baseDiv" href="#collapseThree"
														class="text-collapse collapsed" data-toggle="collapse"
														aria-expanded="false" aria-controls="collapseThree">
														<span class="d-block"><img src="assets/images/icon-basemap.png"
																style="width:20px"></span>
													</a>
													<!-- <a id="measureDiv" href="#collapseFour" class="text-collapse collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseFour">
                                                        <span class="d-block"><img src="assets/images/icon-measurement.png" style="width:20px"></span>
                                                    </a> -->
													<div class="clearfix"></div>

													<div class="mb-0">
														<div id="collapseOne" class="collapse show"
															aria-labelledby="headingOne" data-parent="#accordion">
															<div class="fixHeightRight">
																<div class="tab-inr-title">
																	<h4>
																		Layers
																		<button
																			class="btn-sm btn-danger closeBtn collapsed"
																			type="button" data-toggle="collapse"
																			data-target="#collapseOne"
																			aria-expanded="false"
																			aria-controls="collapseOne"><span
																				aria-hidden="true">×</span></button>
																	</h4>
																</div>

																<div class="card-body">
																	<div id="accordion2">
																		<div class="collapse-inner">
																			<a id="layerDiv" href="#collapseInnerOne"
																				class="text-dark" data-toggle="collapse"
																				aria-expanded="true"
																				aria-controls="collapseInnerOne">
																				<h6 class="mt-0 panel-title mb-3">Forest
																					Administration </h6>
																			</a>
																			<div id="collapseInnerOne"
																				class="collapse p-2 show"
																				aria-labelledby="heading1"
																				data-parent="#accordion2">
																				<form class="form-horizontal" action="">
																					<div
																						class="custom-control custom-checkbox">
																						<input type="checkbox"
																							(change)="circleonchange($event)"
																							class="custom-control-input"
																							id="circleBoundary"
																							name="example1">
																						<label
																							class="custom-control-label"
																							for="circleBoundary">Circle
																							Boundary</label>
																					</div>
																					<div
																						class="custom-control custom-checkbox">
																						<input type="checkbox"
																							(change)="divonchange($event)"
																							class="custom-control-input"
																							id="divisionBoundary"
																							name="example1">
																						<label
																							class="custom-control-label"
																							for="divisionBoundary">Division
																							Boundary</label>
																					</div>
																					<div
																						class="custom-control custom-checkbox">
																						<!-- (change) ="setrangeVisibility($event)" -->
																						<input type="checkbox"
																							(change)="setrangeVisibility($event)"
																							class="custom-control-input"
																							id="rangeBoundary"
																							name="example1">
																						<label
																							class="custom-control-label"
																							for="rangeBoundary">Range
																							Boundary</label>
																					</div>
																					<div
																						class="custom-control custom-checkbox">
																						<!-- (change)="setSectionVisibility($event)" -->
																						<input type="checkbox"
																							(change)="setSectionVisibility($event)"
																							class="custom-control-input"
																							id="sectionBoundary"
																							name="example1">
																						<label
																							class="custom-control-label"
																							for="sectionBoundary">Section
																							Boundary</label>
																					</div>
																					<div
																						class="custom-control custom-checkbox">
																						<!-- (change)="setBeatVisibility($event)" -->
																						<input type="checkbox"
																							(change)="setBeatVisibility($event)"
																							class="custom-control-input"
																							id="beatBoundary"
																							name="example1">
																						<label
																							class="custom-control-label"
																							for="beatBoundary">Beat
																							Boundary</label>
																					</div>
																					<!-- <div
																						class="custom-control custom-checkbox">

																						<input type="checkbox"
																							class="custom-control-input"
																							(change)="sanctuarychange($event)"
																							id="sanctuaryBoundary"
																							name="example1">
																						<label
																							class="custom-control-label"
																							for="sanctuaryBoundary">Sanctuary
																							Boundary</label>
																					</div> -->
																					<!-- <div
																						class="custom-control custom-checkbox">
																						<input type="checkbox"
																							class="custom-control-input"
																							(change)="blockheadchange($event)"
																							id="blockheadpoints"
																							name="example1">
																						<label
																							class="custom-control-label"
																							for="blockheadpoints">Block
																							Headquaters</label>
																					</div> -->




																					<!-- <div class="custom-control custom-checkbox">
																					    <input type="checkbox" class="custom-control-input" id="railwayLine" name="example1">
																					    <label class="custom-control-label" for="railwayLine">Railway Line</label>
																					</div>
																					<div class="custom-control custom-checkbox">
																					    <input type="checkbox" class="custom-control-input" id="gajahCorridor" name="example1">
																					    <label class="custom-control-label" for="gajahCorridor">Gajah Corridor</label>
																					</div>
																					<div class="custom-control custom-checkbox">
																					    <input type="checkbox" class="custom-control-input" id="fsiForestCover" name="example1">
																					    <label class="custom-control-label" for="fsiForestCover">FSI Forest Cover - 2015</label>
																					</div> -->
																				</form>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>


													<div class="mb-0">
														<div id="collapseTwo" class="collapse"
															aria-labelledby="headingTwo" data-parent="#accordion">
															<div class="fixHeightRight">
																<div class="tab-inr-title">
																	<h4>
																		Legends
																		<button
																			class="btn-sm btn-danger closeBtn collapsed"
																			type="button" data-toggle="collapse"
																			data-target="#collapseTwo"
																			aria-expanded="false"
																			aria-controls="collapseTwo"><span
																				aria-hidden="true">×</span></button>
																	</h4>
																</div>
																<div class="card-body">
																	<form class="form-horizontal" action="">
																		<div class="widget-body">
																			<div class="maplayer-div">
																				<div id="legendDiv" class="widget-main">
																					<div class="mb-1">
																						<!-- <img src="assets/images/geoserver-GetLegendGraphic.image.png" class="img-legend"> -->
																						<!-- <img src="http://14.98.253.214:8087/geoserver/SLIM_SpatialWS/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=SLIM_SpatialWS:stateboundary"
																							class="img-legend"> -->
																						<!-- Odisha Boundary -->
																					</div>
																					<div class="mb-1"
																						*ngIf="this.legndivision.checked === true">
																						<!-- <img src="http://164.164.122.69:8080/geoserver/iwlms/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=iwlms:balesore_division_boundary"
																							class="img-legend"
																							class="img-legend"> -->
																						<!-- Division Boundary -->
																					</div>
																					<div class="mb-1"
																						*ngIf="this.legndrange.checked === true">
																						<!-- <img src="http://164.164.122.69:8080/geoserver/iwlms/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=iwlms:balesore_range_boundary"
																							class="img-legend"> -->
																						<!-- Range Boundary -->
																					</div>
																					<div class="mb-1"
																						*ngIf="this.legndsection.checked === true">
																						<!-- <img src="http://164.164.122.69:8080/geoserver/iwlms/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=iwlms:balesore_section_boundary"
																							class="img-legend"> -->
																						Section Boundary
																					</div>
																					<div class="mb-1"
																						*ngIf="this.legendbeat.checked === true">
																						<!-- <img src="http://164.164.122.69:8080/geoserver/iwlms/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=iwlms:balesore_beat_boundary"
																							class="img-legend"> -->
																						Beat Boundary
																					</div>
																					<!-- <div class="mb-1"
																						*ngIf="this.legendPA.checked === true">

																					</div> -->
																					<!-- <div class="mb-1">
                                                                                    	<img src="assets/images/legend07.png" class="img-legend">
                                                                                    	Open Forest
                                                                                    </div>
                                                                                    <div class="mb-1">
                                                                                    	<img src="assets/images/legend08.png" class="img-legend">
                                                                                    	Non Forest
                                                                                    </div>
                                                                                    <div class="mb-1">
                                                                                    	<img src="assets/images/legend09.png" class="img-legend">
                                                                                    	Scrub
                                                                                    </div>
                                                                                    <div class="mb-1">
                                                                                    	<img src="assets/images/legend10.png" class="img-legend">
                                                                                    	Water Body
                                                                                    </div> -->
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
														</div>
													</div>

													<div class="mb-0">
														<div id="collapseThree" class="collapse"
															aria-labelledby="headingThree" data-parent="#accordion">
															<div class="fixHeightRight">
																<div class="tab-inr-title">
																	<h4>
																		Base Map
																		<button
																			class="btn-sm btn-danger closeBtn collapsed"
																			type="button" data-toggle="collapse"
																			data-target="#collapseThree"
																			aria-expanded="false"
																			aria-controls="collapseThree"><span
																				aria-hidden="true">×</span></button>
																	</h4>
																</div>
																<div class="card-body p-2">
																	<form class="form-horizontal" action="">
																		<div
																			class="custom-control custom-checkbox mb-2">
																			<!-- (change) ="topochange($event)" -->
																			<input type="checkbox"
																				class="custom-control-input"
																				id="topoBTN"
																				(change)="topochange($event)"
																				name="example1">
																			<label class="custom-control-label"
																				for="topoBTN">Topo</label>
																			<img src="assets/images/basemap-topo.png"
																				class="img-basemap">
																		</div>
																		<div
																			class="custom-control custom-checkbox mb-2">
																			<!-- (change) ="streetchange($event)" -->
																			<input type="checkbox"
																				class="custom-control-input"
																				(change)="streetchange($event)"
																				id="streetBTN" name="example1">
																			<label class="custom-control-label"
																				for="streetBTN">Google Street
																				Map</label>
																			<img src="assets/images/basemap-street.png"
																				class="img-basemap">
																		</div>
																		<div
																			class="custom-control custom-checkbox mb-2">
																			<!-- (change) ="imagerychange($event)" -->
																			<input type="checkbox"
																				class="custom-control-input"
																				(change)="imagerychange($event)"
																				id="imageryBTN" name="example1">
																			<label class="custom-control-label"
																				for="imageryBTN">Google Sattelite
																				Map</label>
																			<img src="assets/images/basemap-imagery.png"
																				class="img-basemap">
																		</div>
																		<div
																			class="custom-control custom-checkbox mb-2">
																			<!-- (change) ="bingmapchange($event)" -->
																			<input type="checkbox"
																				class="custom-control-input"
																				(change)="bingmapchange($event)"
																				id="natgeoBTN" name="example1">
																			<label class="custom-control-label"
																				for="natgeoBTN">OSM Map</label>
																			<img src="assets/images/basemap-natgeo.png"
																				class="img-basemap">
																		</div>
																	</form>
																</div>
															</div>
														</div>
													</div>

													<!-- <div class="mb-0">
                                                        <div id="collapseFour" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                            <div class="fixHeightRight">
                                                            	<div class="tab-inr-title">
	                                                            	<h4>
	                                                            		Measurement
	                                                            		<button class="btn-sm btn-danger closeBtn collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"><span aria-hidden="true">×</span></button>
	                                                            	</h4>
	                                                            </div>
                                                                <div class="card-body">
                                                                    <form class="form-horizontal" action="">
                                                                        <div class="widget-body">
                                                                            <div id="measurementDiv" class="widget-main">
                                                                            	<div class="custom-control custom-radio">
																				    <input type="radio" class="custom-control-input" id="area" name="radioMeasurement" value="" checked="">
																				    <label class="custom-control-label" for="area">Area</label>
																				</div>
																				<div class="custom-control custom-radio">
																				    <input type="radio" class="custom-control-input" id="distance" name="radioMeasurement" value="">
																				    <label class="custom-control-label" for="distance">Distance</label>
																				</div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> -->

												</div>
												<!--/accordion-->

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>

	</div>

	<div id="footer">
		<app-masterfooter></app-masterfooter>
	</div>

</div>
