
<app-masterheader></app-masterheader>

<div class="wrapper">

    <app-sidebar></app-sidebar>

    <div id="content">

        <!-- <app-breadcrumb></app-breadcrumb> -->

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Add Role</h4>
                                    <form [formGroup]="setPermissionform" (ngSubmit)="setPermission()">
                                    	<div class="row">
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label for="">Role Name <span class="text-danger" >*</span></label>
                                                <select   class="form-control" formControlName="role"  id="role" (change)="getAllPLink(roleSelect.value)" #roleSelect  required>

													<option *ngFor="let role of role"  value={{role.id}}  >
                                                    {{role.name}}
												  </option>
												  </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                        	<div class="col-md-3" *ngFor="let plink of plinkList">
                                        		<div class="form-check form-check-inline mr-5 mb-3">
                                                    <input class="form-check-input" type="checkbox" [checked]="this.rpermissioncheck.includes(plink.id)" formControlName="permissions" value="{{plink.id}}" (change)="pushthevalue($event)" id="{{plink.id}}">
													<label class="form-check-label" for="permissionCheck1">
														{{plink.name}}
													</label>
                                        		</div>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row">
                                        	<div class="col-md-12 text-center">
                                        		<button type="submit" class="btn btn-sm btn-success">Save</button>
                                        	</div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

    <div id="footer">
        <app-masterfooter></app-masterfooter>
    </div>

</div>

