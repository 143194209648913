<section class="main-menu">
  <nav id="cssmenu">
    <div id="head-mobile"></div>
    <div class="button"></div>
    <ul class="menu" id="menu-menu-1" style="display: block;">
      <!-- <li class="menu-item menu-item-type-custom menu-item-object-custom" id="">
        <a href="/">Home</a>
      </li> -->
      <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has-sub" id=""><span class="submenu-button"></span>
        <a href="javascript:void(0)">About</a>
        <ul class="sub-menu">
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/WlOrganisation">Wildlife Organisation</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/Vision">Vision</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/Organogram">Organogram</a>
          </li>
          <!-- <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a href="https://forest.odisha.gov.in/" target="_blank">5T</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a href="https://mosarkar.odisha.gov.in/App/" target="_blank">Mo Sarkar</a>
          </li> -->
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a href="http://rtiodisha.gov.in/pa/T1RILzIyLzE4MTMvMTk=" target="_blank">RTI</a>
          </li>
        </ul>
      </li>
      <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has-sub" id=""><span class="submenu-button"></span>
        <a href="javascript:void(0)">Wildlife Units</a>
        <ul class="sub-menu large-menu">
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/wildlifeunitsbalasore"  >Balasore (WL) Division</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/Wildlifeunitsbamra">Bamra (WL) Division</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/Wildlifeunitsbhadrak">Bhadrak (WL) Division</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/Wildlifeunitschandaka">Chandaka (WL) Division</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/Wildlifeunitschilika">Chilika (WL) Division</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/Wildlifeunitshirakud">Hirakud (WL) Division</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/WildlifeunitsKeonjhar">Keonjhar (WL) Division</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/WildlifeunitsMahanadi">Mahanadi (WL) Division</a>
          </li>
          <!-- <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/WildlifeunitsNandankanan">Nandankanan</a>
          </li> -->
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/WildlifeunitsPuri">Puri (WL) Division</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/WildlifeunitsMangrove">Mangrove Forest Division (WL)</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/WildlifeunitsSatkosia">Satkosia (WL) Division</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/WildlifeunitsSimilipalNorth">Similipal North Division (WL)</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/WildlifeunitsSimilipalSouth">Similipal South  Division (WL)</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/WildlifeunitsSunabedaWL">Sunabeda (WL) Division</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/WildlifeunitsBaligudaFD">Baliguda (F) Division</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/WildlifeunitsDhenkanalFD">Dhenkanal (F) Division</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/WildlifeunitsKalahandiSouthFD">Kalahandi South (F) Division</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/wildlifeunits/WildlifeunitsParlakhemundiFD">Parlakhemundi (F) Division</a>
          </li>
        </ul>
      </li>
      <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has-sub" id=""><span class="submenu-button"></span>
        <a href="javascript:void(0)">Protected Areas</a>
        <ul class="sub-menu large-menu">
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasBadrama">Badrama</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasBaisipalli">Baisipalli</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasBalukhandKonark">Balukhand  - Konark</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasBhitarakanika">Bhitarakanika</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasChandakaDampada">Chandaka - Dampada</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasChilikaNalaban">Chilika (Nalaban)</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasDebrigarh">Debrigarh</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasGahirmathaMarine">Gahirmatha (Marine)</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasHadgarh">Hadgarh</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasKarlapat">Karlapat</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasKapilash">Kapilash</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasKhalasuni">Khalasuni</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasKuldiha">Kuldiha</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasKotgarh">Kotgarh</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasLahkeriValley">Lahkeri - Valley</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasNandankanan">Nandankanan</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasSatkosiaGorge">Satkosia Gorge</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasSimilipal">Similipal</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/protectedareas/ProtectedareasSunabeda">Sunabeda</a>
          </li>
        </ul>
      </li>
      <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has-sub" id=""><span class="submenu-button"></span>
        <a href="javascript:void(0)">Resources</a>
        <ul class="sub-menu">
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/Research">Research</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/WildlifeCensus">Wildlife Census</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/Corridors">Corridors</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/Publication">Publication</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/blogdetails">Blog</a>
          </li>
        </ul>
      </li>
      <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has-sub" id=""><span class="submenu-button"></span>
        <a href="javascript:void(0)">Programs</a>
        <ul class="sub-menu">
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/ecotourism">Ecotourism</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/mananimalinterface">Man - Animal Interface</a>
          </li>
        </ul>
      </li>
      <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has-sub" id=""><span class="submenu-button"></span>
        <a href="javascript:void(0)">Information</a>
        <ul class="sub-menu">
          <!-- <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/importantcirculars">Important Circulars</a>
          </li> -->
          <!-- <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a href="/notifications">Notifications</a>
          </li> -->
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/innews">In News</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/notices">Notices</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
            <a routerLink="/controlroominfo">Control Room Information</a>
          </li>
        </ul>
      </li>
      <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
        <a routerLink="/tender">Tender</a>
      </li>
      <!-- <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
        <a href="javascript:void(0)" data-toggle="modal" data-target="#modalLogin"><span class="i-sm">i</span>WLMS</a>
      </li> -->
      <!-- <li class="menu-item menu-item-type-post_type menu-item-object-page" id="">
        <a routerLink="/infodashboard">Info Dashboard</a>
      </li> -->
      <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children has-sub" id=""><span class="submenu-button"></span>
        <a href="javascript:void(0)">Important Links</a>
        <ul class="sub-menu">
          <li class="menu-item menu-item-type-post_type menu-item-object-page">
            <a href="https://www.odwlanukampa.in/index.php/home/applications" target="_blank">WL Anukampa</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page"><a href="http://www.nandankanan.org" target="_blank">Nandankanan Zoo</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page"><a href="http://www.satkosia.org" target="_blank">Satkosia</a></li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page">
            <a href="https://similipal.org/" target="_blank">Similipal Tiger Reserve</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page">
            <a href="https://www.ecotourodisha.com/" target="_blank">Eco Tourism Odisha</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page">
            <a href="https://website.iklmsodisha.in/" target="_blank">Kenduleaves</a>
          </li>
         
          <li class="menu-item menu-item-type-post_type menu-item-object-page">
            <a href="https://www.odishaforest.in/" target="_blank">PCCF & HoFF Odisha</a>
          </li>
          <li class="menu-item menu-item-type-post_type menu-item-object-page">
            <a href="https://forest.odisha.gov.in/" target="_blank">MoEF & CC Odisha </a>
          </li>
          
        </ul>
      </li>
      <!-- <li class="menu-item menu-item-type-post_type menu-item-object-page pull-right" id="">
        <a href="https://odishaforestgis.in/ofms_map_comparison/forest_fire_map.html" target="_blank" class="p-1">
          <img src="assets/images/fire_mapper.png" class="img-fluid">
        </a>
      </li> -->
      <!-- <div class="navbar">
        <ol>
          <li class="searchbar">
            <i class="fa fa-search" aria-hidden="true"></i>
            <div class="togglesearch">
              <input type="text" placeholder="" class="showSearch" />
              <input type="button" value="Search" />
            </div>
          </li>
        </ol>
      </div> -->
    </ul>
  </nav>
</section>
