<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-3">

				<div class="custom-menu-inner">
					<h4 class="card-title">Projects & Conservation</h4>
					<ul>
						<li><a [routerLink]="['/projectelephant']">Project Elephant</a></li>
						<li><a [routerLink]="['/projecttiger']">Project Tiger</a></li>
						<li><a [routerLink]="['/crocodileconservation']">Crocodile Conservation</a></li>
						<li><a [routerLink]="['/seaturtleconservation']">Sea Turtle Conservation</a></li>
						<li><a [routerLink]="['/blackbuckconservation']">Blackbuck Conservation</a></li>
						<li class="active"><a [routerLink]="['/mangroves']">Mangroves</a></li>
					</ul>
				</div>

			</div>
			<div class="col-md-9">

				<h4 class="card-title">Significance of Mangrove Ecosystem</h4>
				<p>Mangroves are salt tolerant plant species found in the inter-tidal regions along the creeks and
					estuaries near the coast, on the river mouth. They play a very significant role in protecting the
					hinterland against (i) cyclones and (ii) the ingress of sea water during tidal surge. Mangroves
					stabilize coastal land mass against sea erosion. They are repositories of immense biological
					diversity and are also the nursery and breeding ground of several marine life forms, such as species
					of prawns, crabs, fishes and molluscs. Mangroves sustain the ecological security of the coastal
					areas as well as livelihood security of the thousands of fisherman and other who leave in these
					areas.</p>

				<h4 class="card-title">Status of Mangroves in Odisha</h4>
				<img src="assets/images/mangroves_img1.gif" class="img-fluid float-right img-inner" alt="image">
				<p>The mangroves all along the Odisha coast are threatened due to high density of population in these
					areas and competing demand for land for agriculture and prawn farming. The mangrove belt in
					Kendrapada district called the Bhitarkanika mangrove forests, comprising areas between the Dhamara
					mouth to Barunei on the coast, has been notified as Bhitarkanika Sanctuary (672 Sq.km.). Part of
					this area (145 Sq.km) is notifies National Park. This letter stretch of mangrove is the only area,
					which is relatively well preserved. Mangrove vegetation in Mahanadi delta region between Barunei
					mouth to Mahanadi mouth (Paradip) is fragmented and degraded due to large-scale encroachment of
					these areas. Further south, sparse mangrove vegetation occurs along the coast from Mahanadi mouth to
					Devi mouth. Degraded mangroves also occur to the north of Dhamara mouth up to Chudamani in Bhadrakh
					District coast, and also on Subarnarekha mouth in Balasore District. The mangrove areas were the
					Zamindari forests till 1951. With abolition of the Zamindari system these lands vested in the State
					Government in 1952 (under Anchal Administration of Revenue Department). In 1957, the demarcated and
					notified protected forest blocks out of vested Zamindari forests were moved to the control of the
					Forest Department.</p>
				<p>So far only 5 forest blocks in Mahanadi delta have been constituted as Reserve Forest under the
					Odisha Forest Act, 1972. Forest settlement proceedings on the proposals for reservation of the other
					remaining forest blocks are yet to be finalized. The present status of the different mangrove forest
					blocks under administration of the forest department is summarized in the table below.</p>

				<h4 class="card-title">Wise Mangrove Forests Cover in Odisha</h4>
				<p>(As per the State of forests report of FSI 2001)</p>
				<div class="table-responsive">
					<table class="table table-sm table-bordered">
						<thead>
							<tr>
								<th>District</th>
								<th>Area under Dense Mangrove cover (km2)</th>
								<th>Area under Mangroves - open forests (km2)</th>
								<th>Total Area (km2)</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Balasore</td>
								<td>0</td>
								<td>3</td>
								<td>3</td>
							</tr>
							<tr>
								<td>Bhadrakh</td>
								<td>11</td>
								<td>8</td>
								<td>19</td>
							</tr>
							<tr>
								<td>Jagatsinghpur</td>
								<td>4</td>
								<td>1</td>
								<td>5</td>
							</tr>
							<tr>
								<td>Kendrapada</td>
								<td>179</td>
								<td>13</td>
								<td>192</td>
							</tr>
							<tr>
								<td><strong>Total</strong></td>
								<td>194</td>
								<td>25</td>
								<td>219</td>
							</tr>
						</tbody>
					</table>
				</div>

				<h4 class="card-title">Conservation Highlights</h4>
				<img src="assets/images/mangroves_img2.gif" class="img-fluid float-right img-inner" alt="image">
				<p>A state level Steering Committee under the chairmanship of Chief Secretary for Conservation and
					Management of Mangroves and Coral reefs in Odisha has been constituted vide Forest and Environment
					Department Resolution No. 1022/F & E/ dated 12.1.2000.</p>
				<p>Mangroves of Bramhani -Baitarani delta in Kendrapada district have been declared as: (a) Bhitarkanika
					Wildlife Sanctuary in April 1975 covering an area of 672 Sq.km; b) the core area of the sanctuary
					has been declared as Bhitarkanika National Park in September, 1998 covering an area of 145 Sq.km</p>

				<ul class="custom-list">
					<li>Bordering the Bhitarkanika sanctuary/National Park, an area of 1435 Sq. km. (out of which 1408
						Sq.km. is sea to a width of 20kms. from the coast) has been declared as Gahirmatha Marine
						Wildlife sanctuary in September, 1997. It covers two Reserve Forest Blocks of Mahanadi delta
						mangroves comprising 27 Sq.km.</li>
					<li>Creation of a Wildlife Division at Chandbali in 1980 which was later renamed as Mangrove Forest
						Division (Wildlife), Rajnagar, with jurisdiction over the entire coastal mangrove forests of
						Bhadrak, Kendrapada, and Jagatsinghpur district. With affect from 1.10.2003, the mangrove forest
						of Kendrapada and Jagatsinghpur Districts have been constituted into Rajnagar Wildlife Division,
						while the mangrove areas of Bhadrakh and Puri Districts are now part of Bhadrakh and Puri
						Wildlife Divisions.</li>
					<li>Bhitarkanika Sanctuary area has been designated as a 'RAMSAR SITE' (i.e., Wetland of
						International importance) during the 8th meeting of the contracting parties held at Valencia
						from 18-26th November 2002. In Odisha this is the 2nd wetland of International importance under
						Ramsar Convention and is one of the 19 such sites in the country.</li>
					<li>Mangroves of Bhitarkanika and Mahanadi delta have been considered as a single ecological unit
						and have been proposed to be designated as a Biosphere Reserve.</li>
					<li>Implementation of Management Action Plans (MAPs) since 2000-01 for conservation and management
						of mangroves at six identified locations, namely Bhitarkanika, Mahanadi delta, Dhamara,
						Devi-Kadua, Subarnarekha and Chilika under the central plan scheme of Government of India.</li>
				</ul>

				<h4 class="card-title">Conservation measures on the Envil</h4>
				<ul class="custom-list">
					<li>Completion of Reservation proceedings of all mangrove forest blocks, UN-surveyed areas, newly
						emerged islands/ mudflats, etc. in all deltaic regions and declaration of these areas as Reserve
						Forests within a specified time frame so as to provide proper legal cover against encroachment
						and unsustainable use of these forest/ Sanctuary lands. Disposal of all title suits and misc.
						court cases within a specified time frame.</li>
					<li>Final notification of Bhitarkanika Wildlife Sanctuary under section 26(A) of the Wildlife
						(Protection) Act, 1972.</li>
					<li>Demolition of all un-authorised prawn gherries in mangrove areas/ CRZ -I areas.</li>
					<li>Massive afforestation of degraded mangrove areas, banks of tidal rivers.</li>
					<li>Provision of adequate staff, transport and communication facilities.</li>
					<li>Preparation of mangrove atlas based on digitized GIS maps of all mangrove areas, showing their
						actual land use pattern.</li>
					<li>Removal and prevention of encroachment of mangrove wetlands.</li>
					<li>Prevention of re-encroachment of the mangrove areas retrieved after eviction on encroachers.
					</li>
					<li>Steps for identification, quantification and reduction of dependencies on mangrove forests.</li>
					<li>Providing alternate sources of livelihood and employment generation through Eco-development
						programmes. </li>
				</ul>

				<p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
						</strong>The information contained herein are provided solely for informational purposes
						only.The content is dynamic and may undergo change from time to time. The snapshot of the
						content at a particular time is not to be referred as a legally binding document.</small></p>

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>