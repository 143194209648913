<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<app-masterheader></app-masterheader>
<div class="wrapper">

    <app-sidebar></app-sidebar>

    <div id="content">

        <!-- <app-breadcrumb></app-breadcrumb> -->

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                	<div class="row">
						<div class="col-md-12">
							<div class="card">
								<div class="card-body">
									<h4 class="card-title">Gaja Bandhu</h4>
									<form [formGroup]="gajabandhuForm"
                  (ngSubmit)="onSubmitGajabandhuForm(gajabandhuForm.value)">
										<div class="row form-group">
											<div class="col-md-2">
			                                    <label>Circle <span class="text-danger">*</span></label>
			                                  	<select class="form-control" id="circlewl" formControlName="circle"  (change)="getAllDivisionByCircleId(circleSelect.value)" [(ngModel)]='circleid' [disabled]="circleDisabled"   #circleSelect>
                                            <option value="0">All Circle</option>
                                            <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                              {{circle.circleName}}
                                            </option>
                                        </select>
			                                </div>
			                                <div class="col-md-2">
			                                    <label>Division <span class="text-danger">*</span></label>
			                                  	<select class="form-control" id="divisionwl" formControlName="division" (change)="getAllRangeByDivId(divisionSelect.value)" [(ngModel)]='divid' [disabled]="divisionDisabled" #divisionSelect>
                                            <option value="0">All Division</option>
                                            <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                              {{division.divisionName}}
                                            </option>
                                        </select>
			                                </div>
			                                <div class="col-md-2">
			                                    <label>Range <span class="text-danger">*</span></label>
			                                  	<select class="form-control" id="rangewl"  formControlName="range" (change)="getAllSectionByRangeId(rangeSelect.value)" [(ngModel)]='rangeid' [disabled]="rangeDisabled"  #rangeSelect>
                                            <option value="0">All Range</option>
                                            <option    *ngFor="let range of rangelist "   value={{range.rangeId}} >
                                              {{range.rangeName}}
                                            </option>
                                        </select>
			                                </div>
			                                <div class="col-md-2">
			                                    <label>Section <span class="text-danger">*</span></label>
			                                  	<select class="form-control" id="sectionwl" formControlName="section" (change)="getAllBeatBySectionId(sectionSelect.value)" [(ngModel)]='sectionid'  #sectionSelect>
                                            <option value="0">All Section</option>
                                            <option    *ngFor="let section of sectionlist "   value={{section.secId}} >
                                              {{section.secName}}
                                            </option>
                                        </select>
			                                </div>
			                                <div class="col-md-2">
			                                    <label>Beat <span class="text-danger">*</span></label>
			                                  	<select class="form-control" id="beatwl" formControlName="beat"  [(ngModel)]='beatid'  #beatSelect>
                                            <option value="0">All Beat</option>
                                            <option    *ngFor="let beat of beatlist"   value={{beat.beatId}} >
                                              {{beat.beatName}}
                                            </option>
                                        </select>
			                                </div>
			                                <div class="col-md-2">
				                                <button type="submit" class="btn btn-sm btn-success custom-btn-top">Search</button>
				                            </div>
			                            </div>
									</form>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>

		 <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

					<div class="row">
						<div class="col-md-12">
							<div class="card">
								<div class="card-body">

									<div class="table-responsive">
		                            	<table id="tableGajaBandhu" class="table table-bordered" width="99%" datatable  [dtTrigger]="dtTrigger" [dtOptions]="dtOptions">
		                            		<thead>
		                            			<tr>
		                            				<th width="6%">Sl. No.</th>
		                            				<!-- <th>Circle</th> -->
		                            				<th>Division</th>
                                        <th>Range</th>
		                            				<th>Section</th>
		                            				<th>Beat</th>
												              	<th>Date</th>
                                        <th>Description</th>
		                            				<th>Status</th>
		                            				<th>Action</th>
		                            			</tr>
		                            		</thead>

                                    <tbody>
                                      <tr
                                      *ngFor="let element of tableData ; let i = index;">
                                      <td>{{i+1}}</td>
                                      <td>{{element.division}}</td>
                                      <td>{{element.range}}</td>
                                      <td>{{element.section}}</td>
                                      <td>{{element.beat}}</td>
                                      <td>{{ (element.reportingDate  !== 'null') ? (element.reportingDate | date:'dd-MM-yyyy')  : '' }}  </td>
                                       <td>{{element.description}}</td>
                                      <!-- <td>{{ (elementa.incidentReport  !== 'null') ? (element.incidentReport | date:'dd-MM-yyyy')  : '' }}  </td> -->
                                      <!-- <td><span class="badge badge-warning">Pending</span></td> -->
                                      <td (click)="$event.stopPropagation()"
											[ngClass]="{
													'text-warning' : element.status==='Pending',
													'text-success' : element.status==='Broadcasted',
													'text-danger' :  element.status==='Rejected'
												}"
										>{{element.status}}</td>
		                            				<td>
		                            					<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalImg"
                                          *ngIf="element.imgPath!=''" (click)="getRecord(element)" title="ViewImg">
	                            							<i class="fa fa-picture-o"></i>
														</button>
														<a class="btn btn-sm btn-success mr-1 audio-btn" target="_blank"  href="{{element.audioMessagePath}}" *ngIf="element.audioMessagePath!=''"  (click)="getRecord(element)" title="Viewaudio"
                            download>
	                            							<i class="fa fa-file-audio-o"></i>
                          </a>
                            <a  class="btn btn-sm btn-success mr-1 video-btn" href="{{element.videoMessagePath}}" target="_blank"   *ngIf="element.videoMessagePath!=''"   (click)="getRecord(element)" title="Viewvideo"
                            download>
                            <i class="fa fa-file-video-o"></i></a>
														<!-- <button class="btn btn-sm btn-success mr-1 video-btn" id="videobtn" data-toggle="modal" data-target="#modalVideo" data-src="element.videoMessagePath" *ngIf="element.videoMessagePath!=''"   (click)="getRecord(element)" title="Viewvideo">
	                            							<i class="fa fa-file-video-o"></i>
                            </button> -->

														<!-- <a  class="video-btn" href="#" data-toggle="modal" data-src="http://203.129.207.133:4200/assets/javaupload/gajabandhu/ga_9853824020_wc__924.mp4" data-target="#myModal"><i class="fa fa-info-circle"></i>{{videoSource}}</a> -->

														<button class="btn btn-sm btn-warning mr-1" data-toggle="modal" data-target="#modalPublishGaja"
                            title="Publish" [disabled]=" element.status!=='Pending'"  (click)="getbroadcastRecord(element)">
	                            							<i class="mdi mdi-access-point"></i>
														</button>
														<button class="btn btn-sm btn-danger" title="Reject" [disabled]=" element.status!=='Pending'"  (click)="rejectRecord(element)">
	                            							<i class="mdi mdi-close"></i>
														</button>
		                            				</td>
                                    </tr>
                                    </tbody>
		                            		<!-- <tbody>
		                            			<tr>
		                            				<td>1</td>
		                            				<td>Angul</td>
		                            				<td>Athagarh</td>
		                            				<td>Athagarh</td>
		                            				<td>Rajanagar</td>
		                            				<td>Patenigaon</td>
		                            				<td><span class="badge badge-success">Broadcasted</span></td>
		                            				<td>
		                            					<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalViewGaja" title="View">
	                            							<i class="fa fa-eye"></i>
														</button>
														<button class="btn btn-sm btn-warning" data-toggle="modal" data-target="#modalPublishGaja" title="Publish">
	                            							<i class="mdi mdi-access-point"></i>
														</button>
		                            				</td>
		                            			</tr>
		                            			<tr>
		                            				<td>2</td>
		                            				<td>Koraput</td>
		                            				<td>Jeypore</td>
		                            				<td>Borigumma</td>
		                            				<td>Kathargada</td>
		                            				<td>Mandra</td>
		                            				<td><span class="badge badge-danger">Rejected</span></td>
		                            				<td>
		                            					<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalViewGaja" title="View">
	                            							<i class="fa fa-eye"></i>
														</button>
														<button class="btn btn-sm btn-warning" data-toggle="modal" data-target="#modalPublishGaja" title="Publish">
	                            							<i class="mdi mdi-access-point"></i>
														</button>
		                            				</td>
		                            			</tr>
		                            			<tr>
		                            				<td>3</td>
		                            				<td>Sambalpur</td>
		                            				<td>Bargarh</td>
		                            				<td>Bhatli</td>
		                            				<td>Ambabhona</td>
		                            				<td>Bugbugi</td>
		                            				<td><span class="badge badge-success">Broadcasted</span></td>
		                            				<td>
		                            					<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalViewGaja" title="View">
	                            							<i class="fa fa-eye"></i>
														</button>
														<button class="btn btn-sm btn-warning" data-toggle="modal" data-target="#modalPublishGaja" title="Publish">
	                            							<i class="mdi mdi-access-point"></i>
														</button>
		                            				</td>
		                            			</tr>
		                            		</tbody> -->
		                            	</table>
		                            </div>

								</div>
							</div>
						</div>
					</div>

                </div>
            </div>
        </div>

    </div>

    <!-- Modal Img -->
    <div class="modal fade" id="modalImg" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title" id="exampleModalLongTitle">View Gaja Bandhu</h4>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <h4 class="card-title">Image</h4>
              <div class="img-column">
                <figure class="img-holder">
                    <img src={{imgSource}} (error)="defaultUrlImg($event)" class="img-fluid" alt="">
                </figure>
             </div>
            </div>
          </div>
				</div>
			</div>
		</div>
	</div>
     <!-- Modal Video-->
    <div class="modal fade" id="modalVideo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
	  <div class="modal-content">
		<div class="modal-header">
			<h4 class="modal-title" id="exampleModalLongTitle">Video</h4>
			<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">

		 <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		  </button> -->
		  <!-- 16:9 aspect ratio -->
		  <div class="embed-responsive embed-responsive-16by9">
			<iframe class="embed-responsive-item" src="" id="video"  allowscriptaccess="always" allow="autoplay"></iframe>
		  </div>
      <!-- <video controls id="video" style="width: 100%; height: auto; margin:0 auto; frameborder:0;">
        <source src="https://www.youtube.com/embed/Jfrjeg26Cwk" type="video/mp4">
        Your browser doesn't support HTML5 video tag.
      </video> -->

		</div>

	  </div>
	</div>
    </div>
	  <!-- Modal Audio-->
	  <div class="modal fade" id="modalAudio" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
		  <div class="modal-content">

			<div class="modal-body">

			 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span>
			  </button>
			  <!-- 16:9 aspect ratio -->
			  <div class="embed-responsive embed-responsive-16by9">
				<iframe class="embed-responsive-item" src="" id="audio"  allowscriptaccess="always" allow="autoplay"></iframe>
			  </div>

			</div>

		  </div>
		</div>
		</div>
	<!-- Modal Publish -->
    <div class="modal fade" id="modalPublishGaja" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title" id="exampleModalLongTitle">Publish Gaja Bandhu</h4>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<form   [formGroup]="broadcastForm">
						<div class="row form-group">
							<div class="col-md-12">
								<textarea rows="" class="form-control" formControlName="desc"  placeholder="Remarks"></textarea>
							</div>
						</div>
						<!-- <div class="row form-group">
							<div class="col-md-12 ml-4">
								<input type="checkbox" class="form-check-input" id="gajaCheck1">
    							<label class="form-check-label" for="gajaCheck1">Electricity</label>
							</div>
						</div>
						<div class="row form-group">
							<div class="col-md-12 ml-4">
								<input type="checkbox" class="form-check-input" id="gajaCheck2">
    							<label class="form-check-label" for="gajaCheck2">Railway</label>
							</div>
						</div> -->
						<!-- <div class="row form-group">
							<div class="col-md-12 ml-4">
								<input type="checkbox" class="form-check-input" id="gajaCheck3">
    							<label class="form-check-label" for="gajaCheck3">Check me out</label>
							</div>
						</div> -->
						<hr>
						<div class="row">
						    <div class="col-md-12 text-center">
						    	<button type="submit" class="btn btn-sm btn-success" (click)="broadcastRecord()">Broadcast</button>
						    </div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>

    <div id="footer">
        <app-masterfooter></app-masterfooter>
    </div>

</div>

