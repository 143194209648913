<div id="fp-nav" class="fp-show-active right" style="margin-top: 0px;">
  <ul>
    <li>
      <a [routerLink]="['/']"><span></span></a>
      <a [routerLink]="['/']" class="fp-tooltip right">Amazing Wildlife</a>
    </li>
    <li>
      <a [routerLink]="['/slidercarousel']" class="active"><span></span></a>
      <a [routerLink]="['/slidercarousel']" class="fp-tooltip right active">Discover Wildlife Odisha</a>
    </li>
    <li>
      <a [routerLink]="['/homecontent']"><span></span></a>
      <a [routerLink]="['/homecontent']" class="fp-tooltip right">About Wildlife</a>
    </li>
    <li>
      <a [routerLink]="['/blogall']"><span></span></a>
      <a [routerLink]="['/blogall']" class="fp-tooltip right">Blog / Publication</a>
    </li>
    <li>
      <a [routerLink]="['/filtergallery']"><span></span></a>
      <a [routerLink]="['/filtergallery']" class="fp-tooltip right">Gallery</a>
    </li>
    <li>
      <a [routerLink]="['/footerlanding']"><span></span></a>
      <a [routerLink]="['/footerlanding']" class="fp-tooltip right">Quick Links</a>
    </li>
  </ul>
</div>

<app-login></app-login>

<div class="login-floating">
  <button mat-raised-button data-toggle="modal" data-target="#modalLogin"><i class="fa fa-lock">&nbsp;</i>Login</button>
</div>
<section class="font-script">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div id="news-slider" class="owl-carousel">

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-01.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasBadrama" class="over-layer"><i class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasBadrama">Badrama</a>
              </h3>
              <p class="post-description">This is also known as Ushakothi Sanctury,nested in Bamra ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasBadrama" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-02.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasBaisipalli" class="over-layer"><i class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasBaisipalli">Baisipalli</a>
              </h3>
              <p class="post-description">It is located in the eastern highlands ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasBaisipalli" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-03.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasBalukhandKonark" class="over-layer"><i
                  class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasBalukhandKonark">Balukhanda</a>
              </h3>
              <p class="post-description">Balukhanda-Konark tract along the Bay of Bengal ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasBalukhandKonark" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-04.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasBhitarakanika" class="over-layer"><i
                  class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasBhitarakanika">Bhitarakanika</a>
              </h3>
              <p class="post-description">Bhitarakanika is named as per two odia words: 'Bhitar' meaning ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasBhitarakanika" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-05.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasChandakaDampada" class="over-layer"><i
                  class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasChandakaDampada">Chandaka</a>
              </h3>
              <p class="post-description">Truely inheriting the characteristics of eastern ghats ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasChandakaDampada" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-06.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasChilikaNalaban" class="over-layer"><i
                  class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasChilikaNalaban">Chilika</a>
              </h3>
              <p class="post-description">It is a brackish water lagoon spread over Puri, Khordha and ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasChilikaNalaban" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-07.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasDebrigarh" class="over-layer"><i class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasDebrigarh">Debrigarh</a>
              </h3>
              <p class="post-description">It is a paradise full of many moods and shades ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasDebrigarh" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-08.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasGahirmathaMarine" class="over-layer"><i
                  class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasGahirmathaMarine">Gahirmatha</a>
              </h3>
              <p class="post-description">Odisha has appeared in the turtle map because of the distinction ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasGahirmathaMarine" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-09.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasHadgarh" class="over-layer"><i class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasHadgarh">Hadgarh</a>
              </h3>
              <p class="post-description">Welcome to Hadgarh Wildlife Sanctury,the pristine backyard ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasHadgarh" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-10.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasKarlapat" class="over-layer"><i class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasKarlapat">Karlapat</a>
              </h3>
              <p class="post-description">Karlapat wildlife sanctury is situated 10km from Bhawanipatna ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasKarlapat" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-11.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasKapilash" class="over-layer"><i class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasKapilash">Kapilash</a>
              </h3>
              <p class="post-description">Kapilash Wildlife Sanctury is in Dhenkal District,central Odisha ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasKapilash" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-12.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasKhalasuni" class="over-layer"><i class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasKhalasuni">Khalasuni</a>
              </h3>
              <p class="post-description">This Wildlife Sanctury with its serene landscape ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasKhalasuni" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-13.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasKuldiha" class="over-layer"><i class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasKuldiha">Kuldiha</a>
              </h3>
              <p class="post-description">It is the conglomeration of three reserved forests,viz,kuldiha,Tenda ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasKuldiha" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-14.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasKotgarh" class="over-layer"><i class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasKotgarh">Kotagarh</a>
              </h3>
              <p class="post-description">It is in the Baliguda Subdivision of Kandhamal District ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasKotgarh" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-15.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasLahkeriValley" class="over-layer"><i
                  class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasLahkeriValley">Lakhari Valley</a>
              </h3>
              <p class="post-description">This is situated in the Ganjam and Gajapati districts in Odisha ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasLahkeriValley" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-16.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasNandankanan" class="over-layer"><i
                  class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasNandankanan">Nandankanan</a>
              </h3>
              <p class="post-description">It is located in the north eastern region of Khordha district ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasNandankanan" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-17.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasSatkosiaGorge" class="over-layer"><i
                  class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasSatkosiaGorge">Satkosia</a>
              </h3>
              <p class="post-description">The name Satkosia originates from two words, Sat means ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasSatkosiaGorge" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-18.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasSimilipal" class="over-layer"><i class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasSimilipal">Similipal</a>
              </h3>
              <p class="post-description">Flaming red flowers bursting through the verdant green, sun kissed ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasSimilipal" class="read-more">read more</a>
              </div>
            </div>
          </div>

          <div class="post-slide">
            <div class="post-img">
              <img src="assets/images/post-slide-19.jpg" alt="">
              <a routerLink="/protectedareas/ProtectedareasSunabeda" class="over-layer"><i class="fa fa-link"></i></a>
            </div>
            <div class="post-content">
              <h3 class="post-title">
                <a routerLink="/protectedareas/ProtectedareasSunabeda">Sunabeda</a>
              </h3>
              <p class="post-description">A proposed tiger reserve is located in nuapada district ...</p>
              <div class="post-date"><i class="fa fa-clock-o"></i>Oct 27, 2019</div>
              <div class="text-center mt-3">
                <a routerLink="/protectedareas/ProtectedareasSunabeda" class="read-more">read more</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
