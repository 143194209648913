<div class="container my-5">
  <!-- Hero Section with Gradient Background -->
<!-- Hero Section with Vibrant Background -->
<div class="hero-section position-relative text-center text-white p-5 rounded-4 shadow-lg">
    <div class="content position-relative">
        <h1 class="fw-bold display-4">
            🐢 Olive Ridley & Dolphins Awareness Month 🐬
        </h1>
        <p class="fs-5 fw-light">
            Join us in protecting Odisha’s marine treasures! Participate in exciting events and make a difference.
        </p>
        <!-- <a href="#event" class="btn btn-lg btn-warning fw-bold px-4 py-2 mt-3 shadow">Explore Events</a> -->
    </div>
</div>



  
    <!-- Event Image -->
    <!-- <div class="text-center my-4">
      <img src="assets/olive_ridley.jpg" class="img-fluid rounded shadow-lg" alt="Olive Ridley & Dolphins" style="max-height: 400px;">
    </div> -->
  
    <!-- Event Line-Up -->
    <h3 class="text-success mt-4 fw-bold">📅 Event Line-Up</h3>
    <div class="row mt-3">
      <div class="col-md-6">
        <div class="card border-0 shadow-sm mb-3">
          <div class="card-body">
            <p class="fw-bold">📌 Olive Ridley & Dolphins Workshop</p>
            <p class="text-muted">March 7-12</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card border-0 shadow-sm mb-3">
          <div class="card-body">
            <p class="fw-bold">📌 Beach Rally & Conservation Drive</p>
            <p class="text-muted">March 13-21</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card border-0 shadow-sm mb-3">
          <div class="card-body">
            <p class="fw-bold">📌 Fishermen Training</p>
            <p class="text-muted">March 23-31</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card border-0 shadow-sm mb-3">
          <div class="card-body">
            <p class="fw-bold">📌 Poster Competition</p>
            <p class="text-muted">Submit by March 25</p>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Quiz Section -->
    <div class="container">
        <div class="row">
          <!-- Quiz Competition Section -->
          <div class="col-md-6">
            <div class="bg-light p-4 rounded shadow-lg  mt-5 mb-5">
              <h2 class="text-danger fw-bold">🐬 Dolphin & Marine Turtle Quiz Competition</h2>
              <p class="text-muted">
                Test your knowledge – Win Exciting Prizes! Participate and explore history, habitat, life cycle, threats, and conservation practices.
              </p>
      
              <h5 class="text-primary">📅 Date: 25th March 2025</h5>
              <h5 class="text-primary">🌍 Mode: Online (Quiz link will be shared on 24th March)</h5>
      
              <h6 class="fw-bold mt-3">Who Can Participate?</h6>
              <ul class="text-muted">
                <li>✔️ Students</li>
                <li>✔️ Wildlife Enthusiasts</li>
                <li>✔️ Nature Lovers</li>
              </ul>
      
              <div class="text-center mt-4">
                <a href="https://shorturl.at/hvWhn" target="_blank" class="btn btn-lg btn-primary shadow-lg px-4 py-2 fw-bold">Register Now</a>
              </div>
      
              <p class="text-muted text-center mt-4">
                ⏳ Registration Deadline: 4 PM, 24th March 2025 | 🕛 Competition Timing: 12 PM on 25th March 2025
              </p>
            </div>
          </div>
      
          <!-- Poster Competition Section -->
          <div class="col-md-6">
            <div class="bg-light p-4 rounded shadow-lg mt-5">
              <h2 class="text-success fw-bold">🎨 Poster Competition</h2>
              <p class="text-muted">
                Showcase your ideas on Marine Conservation! Design an engaging poster that highlights the importance of saving Dolphins & Olive Ridleys.
              </p>
      
              <h6 class="fw-bold mt-3">🏆 Top 3 Entries Will Be Featured In:</h6>
              <ul class="text-muted">
                <li>📖 <strong>Wildlife Odisha Book 2025</strong></li>
                <li>🌊 Dolphin Citizen Science Program</li>
              </ul>
      
              <h6 class="fw-bold mt-3">🎁 Winner Prize:</h6>
              <p class="text-muted">An exclusive **Eco-Tourism Stay** at a wildlife sanctuary!</p>
      
              <h6 class="fw-bold mt-3">📩 Submission Deadline:</h6>
              <p class="text-muted">Submit your entries by **5 PM, 25th March 2025**</p>
      
              <h6 class="fw-bold">📧 Email Submission:</h6>
              <p class="text-muted">
                Send your posters to: <a href="mailto:events.odishawildlife@gmail.com" class="text-primary fw-bold">events.odishawildlife&#64;gmail.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      
  </div>
  