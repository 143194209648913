import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from '../service/api.service';
declare var jQuery: any;
const baseUrlFile = environment.FILE_API_URL;

@Component({
  selector: 'app-tender',
  templateUrl: './tender.component.html',
  styleUrls: ['./tender.component.css']
})
export class TenderComponent implements OnInit {
 // public loader: boolean = false;
  tenderList: any[];

  constructor(private apiService: ApiService) { }
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {};

  ngOnInit(): void {

    (function ($) {

      $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        $($.fn.dataTable.tables(true)).DataTable()
          .columns.adjust()
          .responsive.recalc();
      });

    })(jQuery);
    this.loadTender();
  }
  loadTender() {
   // this.loader = true;
    this.apiService.request('GET', 'api/v1/cms/getAllTender').subscribe((data: any) => {
      if (data) {
        this.tenderList = new Array();
        data.map(item => {
          return {
            tenderId: item['tenderId'],
            uploadPath: baseUrlFile + 'tender/' + item['uploadPath'],
            tenderName: item['tenderName'],
            tenderNum: item['tenderNum'],
            title: item['title'],
            publicationDate: item['publicationDate'],
          }
        }).forEach(item => this.tenderList.push(item));
        this.dtTrigger.next();

       // this.loader = false;
      }
    });
  }

}
