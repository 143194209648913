
<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

        <h4 class="card-title">Notifications</h4>

        <!-- <div class="form-group row">
          <div class="col-md-2 mt-2">
            <label>Select Year :</label>
          </div>
          <div class="col-md-3">
            <select class="form-control">
              <option value="" selected="selected">All</option>
              <option value="">2018</option>
              <option value="">2016</option>
              <option value="">2015</option>
              <option value="">2014</option>
              <option value="">2007</option>
              <option value="">2002</option>
              <option value="">2001</option>
              <option value="">1998</option>
              <option value="">1997</option>
              <option value="">1988</option>
              <option value="">1987</option>
              <option value="">1981</option>
              <option value="">1979</option>
              <option value="">1976</option>
              <option value="">1975</option>
            </select>
          </div>
          <div class="col-md-2 mt-2">
            <label>Select Maonth :</label>
          </div>
          <div class="col-md-3">
            <select class="form-control">
              <option value="" selected="selected">All</option>
              <option value="">January</option>
              <option value="">February</option>
              <option value="">March</option>
              <option value="">April</option>
              <option value="">May</option>
              <option value="">June</option>
              <option value="">July</option>
              <option value="">August</option>
              <option value="">September</option>
              <option value="">October</option>
              <option value="">November</option>
              <option value="">December</option>
            </select>
          </div>
          <div class="col-md-2">
            <button type="submit" class="btn btn-success">Search</button>
          </div>
        </div>

        <hr> -->

				<table class="table table-bordered row-border hover" datatable [dtOptions]="dtOptions">
          <thead>
              <tr>
                  <th>Sl. No.</th>
                  <th>Notification No.</th>
                  <th>Notification Name</th>
                  <th>Year</th>
                  <th>Month</th>
                  <th>View</th>
              </tr>
           </thead>
          <tbody>
              <tr *ngFor="let group of data">
                  <td>{{group.slno}}</td>
                  <td>{{group.notificationno}}</td>
                  <td>{{group.notificationname}}</td>
                  <td>{{group.year}}</td>
                  <td>{{group.month}}</td>
                  <td>
                    <a href="#" target="_blank" class="btn btn-sm btn-success"><i class="fa fa-eye"></i></a>
                  </td>
              </tr>
          </tbody>
        </table>

        <p class="text-danger mt-3"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>
