<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
					<li class="nav-item">
						<a id="tab-chilikamap" href="#pane-chilikamap" class="nav-link active" data-toggle="tab"
							role="tab">Map</a>
					</li>
					<li class="nav-item">
						<a id="tab-chilikainfo" href="#pane-chilikainfo" class="nav-link" data-toggle="tab"
							role="tab">Information</a>
					</li>
				</ul>

				<div id="accordion" class="tab-content" role="tablist">
					<div id="pane-chilikamap" class="card tab-pane fade show active" role="tabpanel"
						aria-labelledby="tab-chilikamap">
						<div class="card-header" role="tab" id="heading-chilikamap">
							<h5 class="mb-0">
								<a data-toggle="collapse" href="#collapse-chilikamap" aria-expanded="true"
									aria-controls="collapse-chilikamap">
									Map
								</a>
							</h5>
						</div>
						<div id="collapse-chilikamap" class="collapse show" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-chilikamap">
							<div class="card-body">
								<div id="chilikaWLmap" class="map">
									<div class="gle-icon">
										<a href="javascript:void(0)">
											<img src="assets/images/icon-street.png" (click)='streetchange()'
												class="img-fluid" alt="Street View" title="Street View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-satellite.png" class="img-fluid"
												(click)='stelitechange()' alt="Satellite View" title="Satellite View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-topo.png" class="img-fluid"
												(click)='topochange()' alt="Topo View" title="Topo View">
										</a>
									</div>
								</div>


							</div>
						</div>
					</div>

					<div id="pane-chilikainfo" class="card tab-pane fade" role="tabpanel"
						aria-labelledby="tab-chilikainfo">
						<div class="card-header" role="tab" id="heading-chilikainfo">
							<h5 class="mb-0">
								<a class="collapsed" data-toggle="collapse" href="#collapse-chilikainfo"
									aria-expanded="false" aria-controls="collapse-chilikainfo">
									Information
								</a>
							</h5>
						</div>
						<div id="collapse-chilikainfo" class="collapse" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-chilikainfo">
							<div class="card-body">
								<h4 class="card-title">
									Chilika Wildlife Division
									<span class="custom-down">
										<button type="" (click)="print()"
											class="btn btn-sm btn-info mr-1"><i
												class="fa fa-file-pdf">&nbsp;</i>Information</button>
										<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
												class="fa fa-file-pdf">&nbsp;</i>Map</button>
									</span>
								</h4>
								<div id="printcontent">
									<div class="row">
										<div class="col-md-9">
											<div class="table-responsive">
												<table class="table table-sm table-bordered">
													<tbody>
														<tr>
															<td><strong>District : </strong></td>
															<td>Khordha, Puri, Ganjam</td>
														</tr>
														<tr>
															<td><strong>Geographical Extent : </strong></td>
															<td>Longitude 85° 4'E and 85° 37'E & Latitude 19° 58'N and
																19° 24'N</td>
														</tr>
														<tr>
															<td><strong>Climate : </strong></td>
															<td>Temperature: 25ºC to 42ºC Rainfall: Avg 1200 mm
																Humidity: 80% to 90%</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div class="col-md-3">
											<img src="assets/images/wildlifeunits-chilika-01.jpg"
												class="img-fluid float-right img-inner" alt="image">
										</div>
									</div>
									<p>Chilika Wildlife Division is located in the district of Khurda, Puri and Ganjam
										in Odisha. Chilika Lake nestled in this Wildlife Division was designated the
										first Indian wetland of international importance under the Ramsar Convention.
										Chilika lake is the largest wintering ground for migratory birds on the Indian
										sub-continent.</p>
									<h4 class="card-title">Flora </h4>
									<p>Recent surveys revealed an overall 726 species of flowering plants belonging to
										496 genera and 120 families. The flora is predominantly of aquatic and
										sub-aquatic plants.Deciduous forests, Coastal casuarinas are also the part of
										flora in this division surrounding the lake.</p>
									<h4 class="card-title">Fauna </h4>
									<p>About 225 species of birds including about 100 migratory species, Irrawaddy
										dolphins in Chilika lake, Olive ridley sea turtles on coast of Bay of Bengal,
										barking deer, pea fowls, wild boars, migratory elephants in the forests
										surrounding the lake.</p>
									<h4 class="card-title">Set-Ups</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td><strong>Administrative set-up (Range, Section, beats) :</strong>
													</td>
													<td>Ranges- 5, Sections- 15,Beats- 45</td>
												</tr>
												<tr>
													<td><strong>EDC/JFM set-up: </strong></td>
													<td>
														EDCs - 0, VSS - 20
													</td>
												</tr>
												<tr>
													<td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
													<td>No. of RF- 0, No. of PRF- 7, No. of DPF- 3, No. of UDPF- 1, No.
														of VF- 0</td>
												</tr>
											</tbody>
										</table>
									</div>
									<h4 class="card-title">Major Attraction</h4>
									<div class="row">
										<div class="col-md-12">
											<img src="assets/images/wildlifeunits-chilika-02.jpg"
												class="img-fluid float-right img-inner" alt="image">
											<ul class="custom-list">
												<li>Chilika Lake offers visitors a spectacular display of its colorful
													avian charms in a thousand different hues presented by over 160
													species in the peak season between November and February, can be
													closely watched at Mangalajodi.</li>
												<li>Kalijai Temple - Located on an island in the Chilika Lake is one of
													the famous religious attractions.</li>
												<li>The Kalijai island is also a mesmerizing destination for pilgrims as
													well as tourists and can be reached via a boat from Chilika Lake.
												</li>
												<li>Bhagabati and Narayani temples add attraction to the visitors of
													Chilika</li>
												<li>Another major attraction at Chilika is Irrawady dolphins which are
													often spotted off Satpada Island.</li>
												<li>Chilika Lagoon is the first indian site to be declared as one of the
													internationally renowned Ramsar sites.</li>
											</ul>
										</div>
									</div>
									<h4 class="card-title">Salient Detail</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td><strong>HQ Address :</strong></td>
													<td>O/O Divisional Forest Officer, Chilika Wildlife Division At/Po-
														Gopinathpur, Ps- Balugaon, Dist- Khurda Pin-752030</td>
												</tr>
												<tr>
													<td><strong>Contact officer : </strong></td>
													<td>
														<span class="address-inner"><i
																class="fa fa-user">&nbsp;</i></span>Divisional Forest
														Officer<br>

													</td>
												</tr>
												<tr>
													<td><strong>Key activities : </strong></td>
													<td>Protection of migratory birds & their habitat management,
														protection of Irrawaddy dolphins Monitoring of sporadic nesting
														of olive ridley turtles, monitoring of movement of wild
														elephants,</td>
												</tr>
												<tr>
													<td><strong>Programmes : </strong></td>
													<td>ecotourism management,Protection through deployment of squad,
														boat patrolling, disease surveillance, minimal habitat
														interventions, awareness programmes</td>
												</tr>
												<tr>
													<td><strong>Places to see : </strong></td>
													<td>Kalijai, Mangalajodi, Satapada, Rajhans, Sea mouth, different
														islands</td>
												</tr>
											</tbody>
										</table>
									</div>
									<p class="text-danger"><small><i
												class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
											</strong>The information contained herein are provided solely for
											informational purposes only.The content is dynamic and may undergo change
											from time to time. The snapshot of the content at a particular time is not
											to be referred as a legally binding document.</small></p>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>
