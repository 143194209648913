<div class="loader-base" *ngIf="loader">
  <div class="loader"></div>
</div>
<app-masterheader></app-masterheader>

<div class="wrapper">

  <app-sidebar></app-sidebar>

  <div id="content">

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Citizen User</h4>

                  <form [formGroup]="citizenForm" (ngSubmit)="signup()">
                    <div class="row form-group">
                      <div class="col-md-4">
                        <label>District</label>
                        <select class="form-control" id="districtwl" formControlName="district"  [(ngModel)]='districtId'
                          (change)="getAllBlockByDistId(districtSelect.value)" #districtSelect>
                          <option value="0">All District</option>
                          <option *ngFor="let district of districtList " value={{district.districtId}}>
                            {{district.districtName}}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label>Block</label>
                        <select class="form-control" formControlName="block" [(ngModel)]='blockId'
                          (change)="getAllgpByBlockId(blockSelect.value)" #blockSelect >
                          <option value="0">All Block</option>
                          <option *ngFor="let blockList of blockList " value={{blockList.blockId}}>
                            {{blockList.blockName}}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label>GP</label>
                        <select class="form-control" formControlName="gp" (change)="getAllVilageByGpId(gpSelect.value)" [(ngModel)]='gpId'
                          #gpSelect>
                          <option value="0">All GP</option>
                          <option *ngFor="let gpList of gpList " value={{gpList.gpId}}>
                            {{gpList.gpName}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4">
                        <label>Village</label>
                        <select class="form-control" formControlName="village" [(ngModel)]='villageId'>
                          <option value="0">All Village</option>
                          <option *ngFor="let vilageList of vilageList " value={{vilageList.villageId}}>
                            {{vilageList.villageName}}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <label>Mobile Number<span class="text-danger">*</span></label>
                        <input type="number" class="form-control" placeholder="Mobile Number" formControlName="mobileno"
                          [ngClass]="{ 'is-invalid': citizenForm.controls['mobileno'].invalid && (citizenForm.controls['mobileno'].dirty || citizenForm.controls['mobileno'].touched) }"
                          class="form-control" id="mobileno" placeholder="Mobile Number"
                          (change)="phoneCheckUnique($event.target.value)">
                        <div
                          *ngIf="citizenForm.controls['mobileno'].invalid && (citizenForm.controls['mobileno'].dirty || citizenForm.controls['mobileno'].touched)"
                          class="text-danger mt-1">
                          <small *ngIf="citizenForm.controls['mobileno'].errors.required">
                            Mobile Number is required.
                          </small>
                          <small *ngIf="citizenForm.controls['mobileno'].errors.pattern">
                            Mobile Number is invalid. Mobile Number must be at least 10 characters long.
                            Phone Number cannot be more than 10 characters long.
                          </small>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <label for="">Full Name <span class="text-danger">*</span></label>
                        <input type="text" placeholder="Full Name" formControlName="name"
                          [ngClass]="{ 'is-invalid': citizenForm.controls['name'].invalid && (citizenForm.controls['name'].dirty || citizenForm.controls['name'].touched) }"
                          class="form-control" id="name">
                        <div
                          *ngIf="citizenForm.controls['name'].invalid && (citizenForm.controls['name'].dirty || citizenForm.controls['name'].touched)"
                          class="text-danger mt-1">
                          <small *ngIf="citizenForm.controls['name'].errors.required">
                            Full Name is required.
                          </small>
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <div class="spinner text-center">
                          <div *ngIf="loader2" class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                        <button type="submit" class="btn btn-sm btn-success"
                          [disabled]="citizenForm.invalid || loader2">Submit</button>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">View User
                    <div class="pull-right">
                      <button type="submit" class="btn btn-sm btn-warning mr-1" (click)="downloadSampleExcel()"><i class="fa fa-download" >&nbsp;</i>Download Sample</button>
                      <button type="submit" class="btn btn-sm btn-success" data-toggle="modal" data-target="#uploadExcelModal">Upload&nbsp;&nbsp;<i class="fa fa-file-excel"></i></button>
                    </div>
                  </h4>
                  <form [formGroup]="citizenuserTableForm" (ngSubmit)="onSubmitViewUserForm()">
                    <div class="row">
                      <div class="col-md-11">
                        <div class="row">
                          <div class="col-md-3">
                            <label>District</label>
                            <select class="form-control" id="districtwl_userView" formControlName="userView_district"
                              (change)="getAllBlockByDistId(districtSelect_userView.value)"
                              [(ngModel)]='district_userView'  #districtSelect_userView>
                              <option value="0">All District</option>
                              <option *ngFor="let district of districtList " value={{district.districtId}}>
                                {{district.districtName}}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-3">
                            <label>Block</label>
                            <select class="form-control" id="blockwl_userView" formControlName="userView_block"
                              (change)="getAllgpByBlockId(blockSelect_userView.value)"
                              [(ngModel)]='block_userView' #blockSelect_userView>
                              <option value="0">All Block</option>
                              <option *ngFor="let blockList of blockList " value={{blockList.blockId}}>
                                {{blockList.blockName}}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-3">
                            <label>GP</label>
                            <select class="form-control" id="gpwl_userView" formControlName="userView_gp"
                              (change)="getAllVilageByGpId(gpSelect_userView.value)"
                              [(ngModel)]='gp_userView'  #gpSelect_userView>
                              <option value="0">All GP</option>
                              <option *ngFor="let gpList of gpList " value={{gpList.gpId}}>
                                {{gpList.gpName}}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-3">
                            <label>Village</label>
                            <select class="form-control" id="villagewl_userView" formControlName="userView_village"
                              [(ngModel)]='village_userView' #villageSelect_userView>
                              <option value="0">All Village</option>
                              <option *ngFor="let vilageList of vilageList " value={{vilageList.villageId}}>
                                {{vilageList.villageName}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1">
                        <button type="submit" class="btn btn-sm btn-success custom-btn-top mr-1"  >View</button>
                      </div>


                    </div>
                  </form>
                  <hr>


                  <div class="table-responsive loader-sm">
                    <table id="citizenUserTable" class="table table-bordered row-border hover" datatable
                      [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" width="99%">
                      <thead>
                        <tr>
                          <th width="7%">Sl. No.</th>
                          <th>Full Name</th>
                          <th>District</th>
                          <th>Block</th>
                          <th>GP</th>
                          <th>Village</th>
                          <th>Division</th>
                          <th>Phone</th>
                          <th>Status</th>
                          <th>Action</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let group of tabledata; let i = index" [attr.data-index]="i">
                          <td>{{i+1}} </td>
                          <td>{{group.fullName}} </td>
                          <td>{{ (group.districtName !== 'null') ? group.districtName : '' }}</td>
                          <td>{{ (group.blockName !== 'null') ? group.blockName : '' }}</td>
                          <td>{{ (group.gpName !== 'null') ? group.gpName : '' }}</td>
                          <td>{{ (group.villName !== 'null') ? group.villName : '' }}</td>
                          <td>{{ (group.divisionName !== 'null') ? group.divisionName : '' }}</td>
                          <td>{{group.mobile}}</td>
                          <td><span class="badge badge-pills badge-success" *ngIf="group.active">Active</span>
                            <span class="badge badge-pills badge-secondary" *ngIf="!group.active">Inactive</span>
                          </td>
                          <td>
                            <button class="btn btn-sm btn-info mr-1 no-print" data-toggle="modal"
                            data-target="#citizenUserModal" (click)="getUserByID(group.userId)"><i
                              class="fa fa-edit"></i></button>
                            <!-- <button class="btn btn-sm btn-danger"><i class="fa fa-trash"></i></button> -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="modal fade" id="citizenUserModal" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLongTitle">Edit User Details</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form [formGroup]="updateForm" (ngSubmit)="editUser()" >
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Full Name <span class="text-danger">*</span></label>
                    <input type="hidden" formControlName="userId"  [(ngModel)]='citizenUserId' >

                    <input type="text" formControlName="fullName"  [(ngModel)]='fullName'
                      [ngClass]="{ 'is-invalid': updateForm.controls['fullName'].invalid && (updateForm.controls['fullName'].dirty || updateForm.controls['fullName'].touched) }"
                      class="form-control" id="fullName" placeholder="First Name" >
                    <div
                      *ngIf="updateForm.controls['fullName'].invalid && (updateForm.controls['fullName'].dirty || updateForm.controls['fullName'].touched)"
                      class="text-danger mt-1">
                      <small *ngIf="updateForm.controls['fullName'].errors.required">
                        First Name is required.
                      </small>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">Contact Number <span class="text-danger">*</span></label>
                    <input type="number" formControlName="mobile"  [(ngModel)]='mobile'
                      [ngClass]="{ 'is-invalid': updateForm.controls['mobile'].invalid && (updateForm.controls['mobile'].dirty || updateForm.controls['mobile'].touched) }"
                      class="form-control" id="mobile" placeholder="Mobile Number" (change)="phoneCheckUnique($event.target.value)" >
                    <div *ngIf="updateForm.controls['mobile'].invalid && (updateForm.controls['mobile'].dirty || updateForm.controls['mobile'].touched)"
                      class="text-danger mt-1">
                      <small *ngIf="updateForm.controls['mobile'].errors.required">
                        Phone Number is required.
                      </small>
                      <small *ngIf="updateForm.controls['mobile'].errors.pattern">
                        Phone Number is invalid. Phone Number must be at least 10 characters long.
                        Phone Number cannot be more than 10 characters long.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="">User Status <span class="text-danger">*</span></label>

                    <select class="form-control" id="userStatus" formControlName="active"   [(ngModel)]='active' >
                      <option value=true>Active</option>
                      <option value=false>Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-md-12 text-center">
                  <div class="spinner text-center">
                    <div *ngIf="loader3" class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <button *ngIf="!loader3" [disabled]="updateForm.invalid || loader3" type="submit"
                    class="btn btn-sm btn-success">Update</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
  </div>
  <div id="footer">
    <app-masterfooter></app-masterfooter>
  </div>

</div>

<div class="modal fade gallery" id="uploadExcelModal" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLongTitle"   >Upload Excel</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body"  >
            <form [formGroup]="ExcelUploadForm" (ngSubmit)="onSubmitViewExcelUpload()">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-2">
                      <label>District</label>
                      <select class="form-control" id="district_excel" formControlName="Excelupload_district"
                        (change)="getAllBlockByDistId(districtSelect_excelupload.value)"
                        [(ngModel)]=' district_excelupload'  #districtSelect_excelupload>
                        <option value="0">Select District</option>
                        <option *ngFor="let district of districtList " value={{district.districtId}}>
                          {{district.districtName}}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <label>Block</label>
                      <select class="form-control" id="block_excel" formControlName="Excelupload_block"
                        (change)="getAllgpByBlockId(blockSelect_excelupload.value)"
                        [(ngModel)]='block_excelupload' #blockSelect_excelupload>
                        <option value="0">Select Block</option>
                        <option *ngFor="let blockList of blockList " value={{blockList.blockId}}>
                          {{blockList.blockName}}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <label>GP</label>
                      <select class="form-control" id="gp_excel" formControlName="Excelupload_gp"
                        (change)="getAllVilageByGpId(gpSelect_excelupload.value)"
                        [(ngModel)]='gp_excelupload'  #gpSelect_excelupload>
                        <option value="0">Select GP</option>
                        <option *ngFor="let gpList of gpList " value={{gpList.gpId}}>
                          {{gpList.gpName}}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <label>Village</label>
                      <select class="form-control" id="village_excel" formControlName="Excelupload_village"
                        [(ngModel)]='village_excelupload' #villageSelect_excelupload>
                        <option value="0">Select Village</option>
                        <option *ngFor="let vilageList of vilageList " value={{vilageList.villageId}}>
                          {{vilageList.villageName}}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <label>Upload File</label>
                      <input type="file" class="form-control" placeholder="Upload File" formControlName="Excel_File"  (change)="onSelectExcelFile($event)" accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
                    </div>
                    <div class="col-md-2" style="margin-top:2rem">
                      <button type="submit" class="btn btn-sm btn-success">Upload&nbsp;&nbsp;<i class="fa fa-file-excel"></i></button>
                    </div>
                  </div>
                </div>
                

              </div>
            </form>
          
            
           

           
            
          </div>
        </div>
      </div>
    </div>
