<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

                <h4 class="card-title">Man Animal Interface</h4>

                <img src="assets/images/manwild_img.gif" class="img-fluid float-right img-inner" alt="image">
                <p>The instances of man-animal interface have grown in recent years in Odisha, particularly in respect of wild elephants and bear.</p>
                <p>There are cases of human kill, human injury, cattle kill, house damage & crop damage by wild animals; and also retaliatory killing of wild animals. During the period of ten years from 1994-95 to 2003-04, a total of 3425 instances of depredation have been recorded (excluding the cases of crop damage), on the basis of compassionate payment made to the victims, out of which 2888 cases were due to elephants (84%), 358 cases due to bears (10.45%), 69 cases due to tigers (2.01%), 67 cases due to crocodiles (1.95%) & 43 cases due to leopard (1.25%). Apart from this, elephants damaged 18,152.67 acres of paddy crop during the above period. Growing anthropogenic pressure exerted on wildlife habitats is the main reason for conflict with wildlife. The other reason is fragmentation and honeycombing of wild animal habitats. The loss of corridors & migratory routes of long ranging animals like elephant is the major reason for increasing man-elephant conflict in Odisha.</p>
                <p>Instances of wild animal killing resorted to by enraged villagers in retaliation to damage to their life & property have also been noticed. During the period from 1998-99 to 2003-04, a total of 8 such cases have been reported in respect of elephants with Keonjhar and Dhenkanal divisions accounting for as many as 7 cases. Similarly one case each has been reported from Ghumusar (North) & Baripada Divisions in respect of bear & leopard respectively, for the above period.</p>

                <h4 class="card-title">Man-Elephant</h4>
                <p>Total number of humans killed during the period from 1998-99 to 2003-04 (six year) due to elephant attack is 228, with Keonjhar district alone accounting for 93 numbers followed by Sambalpur & Sundargarh accounting for 28 & 23 number of deaths, respectively. Bamra & Bonai forest divisions have also recorded instances of elephant depredation. Only five forest divisions of Keonjhar and the adjoining Sambalpur, Sundargarh, Bamra and Bonai account for 57.8% of the total depredation instances due to elephants in the state of Odisha.</p>
                <p>In certain areas like Kamakshyanagar in Dhenkanal Division the forests are regenerating as a result of peoples' Participation. Elephants are moving towards such new areas after very long gap. Sometimes the local inhabitants, who did not have an elephant neighbour in their recent memory do not get a clue about how to live with wild elephants. As a result they fall easy victims to instances of depredation.</p>

                <h4 class="card-title">Man-Bear</h4>
                <p>Total number of humans killed during the period from 1998-99 to 2002-03 due to bear attack is 24, with Keonjhar District and Angul District accounting for 09 and 05 cases respectively. Rairakhole, Nabarangpur and Baripada forest districts also have recorded instances of human killing during these years. Other forest Divisions affected by man-bear conflict are Athamalik, Bamra, Chilika, Phulbani, Rajnagar, Sambalpur, Satkosia and Sundargarh.</p>
                <p>In most of the cases, victims had gone for collecting mohua flowers/ kendu fruits / other such NTFPs from the areas in early hours, which were found excellent foraging ground for bears at these hours.</p>

                <h4 class="card-title">Man-Crocodile</h4>
                <p>Total no. of humans kill caused due to crocodile attack during the period from 1998-99 to 2002-03 is 8, with Rajnagar Wildlife Division in Kendrapara district accounting for all the cases. Instances of crocodile attack or both human being and cattle have taken place along the river Dhamra, Baitarani, Mantei in Bhadrak District and Kharashrota, Brahmani, Hansua, Gobari in Kendrapara District. Bhitarkanika mangrove is home to 1358 estuarine crocodiles as per census 2004. Most of the killings have occurred when the victims had gone for collecting of prawn sheeds from the sanctuary. Loss of buffer area along the rivers and creeks and proliferation of villages adjoining National Park are the other reasons for such attack.</p>

                <h4 class="card-title">Man-Leopard</h4>
                <p>Depredation instances due to leopard were mostly in Athmallik, Sundargarh, Bonai, Keonjhar Deogarh & Bamra divisions. During the period between 1991-92 to 2003-04 total 78 no. of instances of depredation were noticed, out of which human injury accounted for 52.56%, cattle kill 28.20% and human kill 19.23% respectively. Athmallik and Sundargarh recorded the most number of leopard depredation instances (5 each) during the period 2001-02 to 2003-04. The number of cattle killed by leopard has increased during the year 2002-03 and 2003-04. The human kill or human injury by leopard has almost stopped in recent years.</p>

                <h4 class="card-title">Man-Tiger</h4>
                <p>No instance of human kill by tiger has been reported during the period from 1998-99 to 2002-03. However stray cases of cattle killing by tiger have been reported from Raigoda, Sundergarh, Deogarh, Angul, Balliguda, Satkosia Divisions.</p>

                <h4 class="card-title">Monkey Menace</h4>
                <img src="assets/images/manwild_img2.gif" class="img-fluid float-right img-inner" alt="image">
                <p>Instances of monkey-menace that have been reported to the Chief Wildlife Warden are from Kendrapada District, Jagatsinghpur District, Puri district, Jatni town in Khurda District, Salepur area of Cuttack District, the Bhubaneswar city, Cuttack city and Jajpur town. Complains about monkey menace in Kendrapada and Jagatsinghpur districts went up very high during the year 2000 to catch the attention of concerned district administration and ultimately the state administration. The reason for such rise was ascribed to the loss of habitat and scarcity of food for the resident monkeys after the super cyclone that hit the coasts of Odisha during October, 1999. Social and religious sentiments attached to monkeys have also resulted in unwanted hand-feeding of these animals seen in most of the premises of temples found all over the state. Problems caused by monkeys have normally resulted in bites to the people or destruction of fruit orchards, vegetable fields, and the thatched and asbestos roofs of houses.</p>

                <h4 class="card-title">Measures Taken</h4>
                <img src="assets/images/manwild_img1.gif" class="img-fluid float-right img-inner" alt="image">
                <p>Various types of measures have been taken to prevent, control and handle situations arising out of man-wildlife conflict. In order to prevent the conflict the methods used include (elephant-proof) trenches, rubble-walls and energized fences etc. Before the season when conflicts are anticipated, due publicity is given and education programs are launched through news papers, the electronic media and by distributing brochures/ leaflets. It is now planned to launch a scheme to organize anti-depredation watch committees in the line of Village Forest Protection Committees. These committees are expected to keep track of the problem animals or animal groups and inform the villagers and the Forest Department in case of approaching emergencies. A few anti depredation squads have been operating at divisional headquarters of vulnerable districts like Dhenkanal, Angul, Keonjhar.</p>
                <p>Measures taken to ward-off the problem-causing animals from the site include the use of high-intensity focus light, crackers, drums and fire-torches. Whenever some particular animal is linked with a series of incidents, it is generally removed from the site. This has been done for leopards, bear, elephants, crocodiles and monkeys. Apart from the above, compassionate payments have also been made to victims sustaining severe losses of various kinds.</p>

                <h4 class="card-title">Rate of Compassionate Payment</h4>
                <a href="assets/files/Rate_of_compassionate_payment.pdf" style="text-decoration: underline;" target="_blank">
                    Rate of Compassionate grant due to depredation by specified wild animals under provisions of Wildlife (Protection) (Odisha) Rules, 1974 in respect of State of Odisha.
                </a>
                

                <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>
   
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>
