import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedhomeRoutingModule } from './sharedhome-routing.module';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { ContactComponent } from './contact/contact.component';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  declarations: [
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    LoginComponent,
    FeedbackComponent,
    ContactComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedhomeRoutingModule
  ],exports: [
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    LoginComponent,
    FeedbackComponent,
    ContactComponent,
    LoaderComponent
  ],providers: []
})
export class SharedhomeModule {
  static forRoot(): any[] | import("@angular/core").Type<any> | ModuleWithProviders<{}> {
    return {
      ngModule: SharedhomeModule,
      providers: []
    };
  }
}
