import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { NotificationService } from '../service/notification.service';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { Report } from '../model/report.model';
import { environment } from 'src/environments/environment.prod';

declare var jQuery: any;
const baseUrlFile = environment.FILE_API_URL;

@Component({
  selector: 'app-squardreport',
  templateUrl: './squardreport.component.html',
  styleUrls: ['./squardreport.component.css']
})
export class SquardreportComponent implements OnInit {
  public loader: boolean=false;
  tabledata1:any[]=[];
  squardReportForm!:FormGroup;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {};

  circleList: any[]=[];
  divisionlist: any[]=[];
  rangelist: any[]=[];
  sectionlist: any[]=[];
  beatlist: any[]=[];

  public circleDisabled:boolean=false;
  public divisionDisabled:boolean=false;
  public rangeDisabled:boolean=false;

  public circleSelected='All';
  public divisionSelected='All';
  public rangeSelected='All';
  public sectionSelected='All';
  public beatSelected='All';

  @ViewChild('sd') sdate : ElementRef;
  @ViewChild('ed') edate : ElementRef;
  
  public selectedStartDate="";
  public selectedEndDate="";
  sightingDate: string;
  sightingTimefrom: string;
  sightingTimeTo: string;
  location: string;
  divisionName: string;
  rangeName: string;
  secName: string;
  beatName: string;
  latitude: string;
  longitude: string;
  remarks: string;
  imgAcqlocation: string;
  selfieImglocation: string;
  updatedBy: string;
  createdBy: string;

  isElephantSight: any;
  isotherSpeciesSight: any;
  species: any;
  isSuspiciousActivity: any;
  isTrapNoticed: any;

  roleId: any;
  isAdmin: boolean;
  loginuserid: any;
  juridictionId: any;
  circleId: any;
  divisionId: any;
  rangeId: any;
  sectionId: any;
  beatId: any;

  constructor(
    private fb: FormBuilder,private notifyService: NotificationService,private router: Router, private apiService: ApiService
  ) { }
  ngOnInit(): void {
    this.squardReportForm = this.fb.group({
      circle:['0'],
      division:['0'],
      range:['0'],
      section:['0'],
      beat:['0'],
      startDate:[''],
      endDate:['']
    });
    (function ($) {
      var day1before = new Date();
      $(function () {
        $("#datepicker2").datepicker({
          autoclose: true,
          todayHighlight: true
        }).datepicker('update', new Date());
        $("#datepicker1").datepicker({
          autoclose: true,
          todayHighlight: true
        }).datepicker('update', new Date(day1before.setDate(day1before.getDate() - 1)));
        $('.clockpicker').clockpicker();
      });
    })(jQuery);
    this.dtOptions = {
      dom: 'Bfrtip',
         buttons: [
             'copy', 'excel', 'pdf', 'print'
         ]
    };

    const userdata=sessionStorage.getItem('user');
    if(userdata!=null){
      const user=JSON.parse(userdata);
      this.roleId=user.roleId;
      if(user.roleId==1){
        this.isAdmin=true;
      }
      this.loginuserid=user.userid;
      this.juridictionId=user.juridictionId;
      this.circleId=user.circleId;
      this.divisionId=user.divisionId;
      this.rangeId=user.rangeId;
      this.sectionId=user.sectionId;
      this.beatId=user.beatId;

      this.getAllCircle();

      if( this.roleId==1 || this.roleId==3)
        {
          this.getSquadReport(); 
        }else{
          this.isCircleDivisionRangeDisabled();
        }
    }else{
      this.router.navigate(['']);
      this.loader=false;
      return;
    }
   
  }
  isCircleDivisionRangeDisabled(){
    if(this.roleId==6){//RCCF
      this.squardReportForm.controls["circle"].setValue(this.circleId);
       this.circleDisabled=true;
       this.squardReportForm.get('circle').disable();
       this.getSquadReport();
     }
     else if(this.roleId==2)
     {//DFO
      this.squardReportForm.controls["circle"].setValue(this.circleId);
       this.circleDisabled=true;
       this.squardReportForm.get('circle').disable();
       this.getAllDivisionByCircleId(this.circleId);
       this.squardReportForm.controls["division"].setValue(this.divisionId);
       this.squardReportForm.get('division').disable();
       this.getAllRangeByDivId(this.divisionId);
       this.getSquadReport();
     }
     else if(this.roleId==4 || this.roleId==5 )
     {
      this.circleDisabled=true;
      this.squardReportForm.get('circle').disable();
      this.getAllDivisionByCircleId(this.circleId);
      this.squardReportForm.controls["division"].setValue(this.divisionId);
      this.squardReportForm.get('division').disable();
      this.getAllRangeByDivId(this.divisionId);
      this.squardReportForm.controls["range"].setValue(this.rangeId);
      this.squardReportForm.get('range').disable();
      this.getSquadReport();
     }
   }
getAllCircle() {
    this.apiService.request('GET', 'api/v1/masters/getAllCircle').subscribe((data: any) => {
     if(data)
     {
       this.circleList=data;
     }
   });
 }
 getAllDivisionByCircleId(circle:any){
   if(circle!=0){
     this.apiService.request('GET', 'api/v1/masters/getAllDivisionByCircleId?id='+circle).subscribe((data: any) => {
     if(data)
     {
       this.divisionlist=data;
       this.rangelist=[];
       this.sectionlist=[];
       this.beatlist=[];
       this.squardReportForm.patchValue({
        range:['0'],
        section:['0'],
        beat:['0'],
       });
     }
    });
  }
 }
 getAllRangeByDivId(div:any) {
   if(div!=0){
  this.apiService.request('GET', 'api/v1/masters/getAllRangeByDivid?id='+div).subscribe((data: any) => {
     if(data)
     {
       this.rangelist=data;
       this.sectionlist=[];
       this.beatlist=[];
       this.squardReportForm.patchValue({
        section:['0'],
        beat:['0'],
       });
     }
   });
  }
 }
 getAllSectionByRangeId(range:any) {
   if(range!=0){
   this.apiService.request('GET', 'api/v1/masters/getAllSectionByRangeid?id='+range).subscribe((data: any) => {
     if(data)
     {
       this.sectionlist=data;
       this.beatlist=[];
       this.squardReportForm.patchValue({
        beat:['0'],
       });
     }
    });
  }
 }
 getAllBeatBySectionId(section:any) {
   if(section!=0){
   this.apiService.request('GET', 'api/v1/masters/getAllBeatBySecId?id='+section).subscribe((data: any) => {
     if(data)
     {
       this.beatlist=data;
     }
   }); }
 }
 getSquadReport(){
  const formValues = this.squardReportForm.getRawValue(); // Gets all values including disabled fields

  this.circleSelected = formValues.circle && formValues.circle !== "0" ? formValues.circle : "All";
  this.divisionSelected = formValues.division && formValues.division !== "0" ? formValues.division : "All";
  this.rangeSelected = formValues.range && formValues.range !== "0" ? formValues.range : "All";
  this.sectionSelected = formValues.section && formValues.section !== "0" ? formValues.section : "All";
  this.beatSelected = formValues.beat && formValues.beat !== "0" ? formValues.beat : "All";


  $("#squardreport").DataTable().destroy();
   //this.loader=true;
    this.getCurentDate();
    return this.apiService.request('GET', 'api/v1/reports/viewSquadReport?circle='+this.circleSelected+'&division='+this.divisionSelected+'&range='+this.rangeSelected+'&section='+this.sectionSelected+'&beat='+this.beatSelected+'&startDate='+this.selectedStartDate+'&endDate='+this.selectedEndDate).subscribe((data: any) => {
    if(data?.length){
      data.sort((a, b) => {
        return <any>new Date(b.sightingTimeTo) - <any>new Date(a.sightingTimeTo);
      });
      this.tabledata1=data;
      console.log(this.tabledata1);
     // this.tabledata1 = data.sort((a, b) => new Date(b.sightingTimeTo).getTime() - new Date(a.sightingTimeTo).getTime());
      //$("#squardreport").DataTable().destroy();
      $("#squardreport").DataTable().destroy();
        this.dtTrigger.next(null);
        //this.loader=false;
        console.log(this.tabledata1)
      }
    });
 
 }
 onSubmit(){
  try{
 //   console.log(this.squardReportForm.value);
    let startDate = this.sdate.nativeElement.value;
    let endDate = this.edate.nativeElement.value;
    if(startDate!=''){
      startDate=this.formatDate(startDate);
      //console.log(startDate);
    }else if(startDate==''){
      alert("Please Provide Start date")
      //this.loader=false;
      return false;
    }
    if(endDate!=''){
      endDate=this.formatDate(endDate);
      //console.log(endDate);
    }else if(endDate==''){
      alert("Please Provide End date")
     // this.loader=false;
      return false;
    }
    if(startDate>endDate){
      alert("Start date should be greater then End date.")
      //this.loader=false;
      return false;
    }

    this.selectedStartDate=startDate;
    this.selectedEndDate=endDate;

      // Assign form values with a default fallback
      const formValues = this.squardReportForm.getRawValue(); // Gets all values including disabled fields

      this.circleSelected = formValues.circle && formValues.circle !== "0" ? formValues.circle : "All";
      this.divisionSelected = formValues.division && formValues.division !== "0" ? formValues.division : "All";
      this.rangeSelected = formValues.range && formValues.range !== "0" ? formValues.range : "All";
      this.sectionSelected = formValues.section && formValues.section !== "0" ? formValues.section : "All";
      this.beatSelected = formValues.beat && formValues.beat !== "0" ? formValues.beat : "All";
    
    // console.log(this.squardReportForm.value);
    // console.log(this.squardReportForm.value.circle);
    // console.log(this.circleSelected);
    $("#squardreport").DataTable().destroy();
     //this.loader=true;
      this.getCurentDate();
      return this.apiService.request('GET', 'api/v1/reports/viewSquadReport?circle='+this.circleSelected+'&division='+this.divisionSelected+'&range='+this.rangeSelected+'&section='+this.sectionSelected+'&beat='+this.beatSelected+'&startDate='+this.selectedStartDate+'&endDate='+this.selectedEndDate).subscribe((data: any) => {
      if(data){
        data.sort((a, b) => {
          return <any>new Date(b.sightingTimeTo) - <any>new Date(a.sightingTimeTo);
        });
        $("#squardreport").DataTable().destroy();
          this.tabledata1=data;
          console.log(this.tabledata1);
          this.dtTrigger.next(null);
          //this.loader=false;
          //console.log(this.tabledata1)
        }
      });
  }catch{

  }
 }
 getCurentDate(){
  var Yestrerdaywithtime;
  var Todaywithtime;
  var mydate = new Date();
  var yester = new Date();
  yester = new Date(yester.setDate(yester.getDate() - 1));
  if (mydate.getMonth() < 9){
    Todaywithtime = mydate.getFullYear() + "-0" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
  }else{
    Todaywithtime = mydate.getFullYear() + "-" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
  }if(yester.getMonth() < 9){
    Yestrerdaywithtime = yester.getFullYear() + "-0" + (yester.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
  }else{
    Yestrerdaywithtime = yester.getFullYear() + "-" + (yester.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
  }
 if(this.selectedStartDate==""){this.selectedStartDate=Yestrerdaywithtime;}
 if(this.selectedEndDate==""){this.selectedEndDate=Todaywithtime;}

}
 formatDate(date) {
  let dateexct=date.split('-');
  var day = dateexct [0];
  var month = dateexct [1];
  var year = dateexct [2];
  var fullDay=year+'-'+month+'-'+day;
  return fullDay;
 }
 defaultUrl(event) {
  event.target.src='assets/images/no_img.png';
}
   getMovementRecordByID(report : Report){
        this.sightingDate='';
        this.sightingTimefrom='';
        this.sightingTimeTo='';
        this.location='';
        this.divisionName=''; 
        this.rangeName='';
        this.secName='';
        this.beatName='';
        this.latitude='';
        this.longitude='';
        this.remarks='';
        this.imgAcqlocation='';
        this.selfieImglocation='';
  
        this.sightingDate=report.sightingDate;
        this.sightingTimefrom=report.sightingTimefrom;
        this.sightingTimeTo=report.sightingTimeTo;
        this.location=report.location;
        this.divisionName=report.divisionName;
        this.rangeName=report.rangeName;
        this.secName=report.secName;
        this.beatName=report.beatName;

        this.isElephantSight=report.isElephantSight==true?"Yes":"No";
        this.isotherSpeciesSight=report.isotherSpeciesSight==true?"Yes":"No";
        this.species=report.species;
        this.isSuspiciousActivity=report.isSuspiciousActivity==true?"Yes":"No";
        this.isTrapNoticed=report.isTrapNoticed==true?"Yes":"No";

        this.latitude=report.latitude;
        this.longitude=report.longitude;
        this.remarks=report.remarks;
        this.updatedBy=report.updatedBy;
        this.createdBy=report.createdBy;
        this.imgAcqlocation=baseUrlFile+'report/'+report.imgAcqlocation;
        this.selfieImglocation=baseUrlFile+'report/'+report.selfieImglocation;
  
 }
}
