<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<!-- <div class="loader-base" *ngIf="loader">
  <div class="loader"></div>
</div> -->
<app-masterheader></app-masterheader>
<div class="wrapper">

  <app-sidebar></app-sidebar>

  <div id="content">

    <!-- <app-breadcrumb></app-breadcrumb> -->

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <div id="accordion" class="mapFilter">
            <!-- News Updates -->
            <div class="card" #NewsUpdateEle>
              <div class="card-body">
                <a href="#collapseOne" class="text-collapse" data-toggle="collapse" aria-expanded="true"
                  aria-controls="collapseOne">
                  <span class="d-block accordion-toggle">News Updates</span>
                </a>
                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                  <div class="accordion-body">
                    <div class="table-responsive">
                      <table id="tableNewsUpdates" class="table table-bordered responsive" width="99%" datatable
                        [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                          <tr>
                            <th width="6%">Sl. No.</th>
                            <th>Content</th>
                            <th>Created On</th>
                            <th>Last Updated On</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let news of NewsList; let i = index" [attr.data-index]="i">
                            <td>{{i+1}} </td>
                            <td [innerHtml]="news.content | customHtmldecoderPipe"></td>
                            <td>{{ (news.craetedOn !== 'null') ? (news.craetedOn | date:'dd-MM-yyyy') : '' }} {{
                              (news.craetedOn !== 'null') ? (news.craetedOn | date:'shortTime') : '' }} </td>
                            <td>{{ (news.updatedOn !== 'null') ? (news.updatedOn | date:'dd-MM-yyyy') : '' }} {{
                              (news.updatedOn !== 'null') ? (news.updatedOn | date:'shortTime') : '' }} </td>
                            <td>
                              <button class="btn btn-sm btn-success mr-1" data-toggle="modal"
                                data-target="#modalEditNewsUpdates" (click)="getNewsRecord(news)">
                                <i class="fa fa-edit"></i></button>
                              <button class="btn btn-sm btn-danger" (click)="deleteNews(news)"><i
                                  class="fa fa-trash"></i></button>
                            </td>
                          </tr>
                        </tbody>
                        <!-- <tbody>
		                            				<tr>
		                            					<td>1</td>
		                            					<td>Lorem Ipsum is simply dummy text of the printing</td>
		                            					<td>11.03.2021</td>
		                            					<td>11:30 AM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditNewsUpdates">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>2</td>
		                            					<td>There are many variations of passages of Lorem Ipsum available</td>
		                            					<td>www.google.com</td>
		                            					<td>03.03.2021</td>
		                            					<td>12:50 PM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditNewsUpdates">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>3</td>
		                            					<td>Contrary to popular belief, Lorem Ipsum</td>
		                            					<td>www.google.com</td>
		                            					<td>25.02.2021</td>
		                            					<td>01:15 PM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditNewsUpdates">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>4</td>
		                            					<td>The standard chunk of Lorem Ipsum used since</td>
		                            					<td>www.google.com</td>
		                            					<td>17.02.2021</td>
		                            					<td>11:05 AM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditNewsUpdates">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>5</td>
		                            					<td>Sed ut perspiciatis unde omnis iste natus error sit</td>
		                            					<td>www.google.com</td>
		                            					<td>29.01.2021</td>
		                            					<td>03:35 PM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditNewsUpdates">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            			</tbody> -->
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- What is New -->
            <div class="card" #NewContent>
              <div class="card-body">
                <a href="#collapseTwo" class="text-collapse collapsed" data-toggle="collapse" aria-expanded="false"
                  aria-controls="collapseTwo">
                  <span class="d-block accordion-toggle">What is New</span>
                </a>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                  <div class="accordion-body">
                    <div class="table-responsive">
                      <table id="tableWhatIsNew" class="table table-bordered" width="100%" datatable
                        [dtOptions]="dtOptions" [dtTrigger]="dtTrigger2">
                        <thead>
                          <tr>
                            <th width="6%">Sl. No.</th>
                            <th>Content</th>
                            <th>Created On</th>
                            <th>Last Updated On</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let new of NewContentList; let i = index" [attr.data-index]="i">
                            <td>{{i+1}} </td>
                            <td [innerHtml]="new.content | customHtmldecoderPipe"></td>
                            <td>{{ (new.createdOn !== 'null') ? (new.createdOn | date:'dd-MM-yyyy') : '' }} {{
                              (new.createdOn !== 'null') ? (new.createdOn | date:'shortTime') : '' }} </td>
                            <td>{{ (new.updatedOn !== 'null') ? (new.updatedOn | date:'dd-MM-yyyy') : '' }} {{
                              (new.updatedOn !== 'null') ? (new.updatedOn | date:'shortTime') : '' }} </td>
                            <td>
                              <button class="btn btn-sm btn-success mr-1" data-toggle="modal"
                                data-target="#modalEditWhatIsNew" (click)="getNewContent(new)">
                                <i class="fa fa-edit"></i></button>
                              <button class="btn btn-sm btn-danger" (click)="deleteNewContent(new)"><i
                                  class="fa fa-trash"></i></button>
                            </td>
                          </tr>
                        </tbody>
                        <!-- <tbody>
		                            				<tr>
		                            					<td>1</td>
		                            					<td>Lorem Ipsum is simply dummy text of the printing</td>
		                            					<td>www.google.com</td>
		                            					<td>11.03.2021</td>
		                            					<td>11:30 AM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditWhatIsNew">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>2</td>
		                            					<td>There are many variations of passages of Lorem Ipsum available</td>
		                            					<td>www.google.com</td>
		                            					<td>03.03.2021</td>
		                            					<td>12:50 PM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditWhatIsNew">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>3</td>
		                            					<td>Contrary to popular belief, Lorem Ipsum</td>
		                            					<td>www.google.com</td>
		                            					<td>25.02.2021</td>
		                            					<td>01:15 PM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditWhatIsNew">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>4</td>
		                            					<td>The standard chunk of Lorem Ipsum used since</td>
		                            					<td>www.google.com</td>
		                            					<td>17.02.2021</td>
		                            					<td>11:05 AM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditWhatIsNew">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>5</td>
		                            					<td>Sed ut perspiciatis unde omnis iste natus error sit</td>
		                            					<td>www.google.com</td>
		                            					<td>29.01.2021</td>
		                            					<td>03:35 PM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditWhatIsNew">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            			</tbody> -->
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Publication -->
            <div class="card" #Publication>
              <div class="card-body">
                <a href="#collapseThree" class="text-collapse collapsed" data-toggle="collapse" aria-expanded="false"
                  aria-controls="collapseThree">
                  <span class="d-block accordion-toggle">Publication</span>
                </a>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                  <div class="accordion-body">
                    <div class="table-responsive">
                      <table id="tablePublication" class="table table-bordered" width="99%" datatable
                        [dtOptions]="dtOptions" [dtTrigger]="dtTrigger3">
                        <thead>
                          <tr>
                            <th width="6%">Sl. No.</th>
                            <th>Title</th>
                            <th>Thumbnail Image</th>
                            <th>File</th>
                            <th>Created on</th>
                            <th>Last Updated On</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let list of PubListArray; let i = index" [attr.data-index]="i">
                            <td>{{i+1}} </td>
                            <td>{{list.publicationTitle}}</td>
                            <td>
                              <!-- <img src={{list.thumbnilImgPath}} (error)="defaultUrl($event)"   class="img-cms" alt="image"> -->
                              <a href="{{list.thumbnilImgPath}}" class="glightbox gallery_product filter elephant"
                                data-fancybox="gallery" data-caption="">
                                <img [src]="list.thumbnilImgPath" *ngIf="list.thumbnilImgPath"
                                  class="img-cms img-fluid mr-2 mb-2" alt="image" (error)="defaultUrl($event)" alt="">
                                <div class="overlay">
                                  <span class="icon">
                                    <i class="fa fa-search"></i>
                                  </span>
                                </div>
                              </a>
                            </td>
                            <td> <a href="{{list.pdfPath}}" target="_blank"><i
                                  class="fa fa-file-pdf-o text-danger"></i></a></td>
                            <td>{{ (list.craetedOn !== 'null') ? (list.craetedOn | date:'dd-MM-yyyy') : '' }} {{
                              (list.craetedOn !== 'null') ? (list.craetedOn | date:'shortTime') : '' }} </td>
                            <td>{{ (list.updatedOn !== 'null') ? (list.updatedOn | date:'dd-MM-yyyy') : '' }} {{
                              (list.updatedOn !== 'null') ? (list.updatedOn | date:'shortTime') : '' }}</td>
                            <td>
                              <button class="btn btn-sm btn-success mr-1" data-toggle="modal"
                                data-target="#modalEditBlog" (click)="getPublishedRecord(list)">
                                <i class="fa fa-edit"></i>
                              </button>
                              <button class="btn btn-sm btn-danger" (click)="deletePublishedRecord(list)">
                                <i class="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <!-- <tbody>
		                            				<tr>
		                            					<td>1</td>
		                            					<td>Lorem Ipsum is simply dummy text of the printing</td>
		                            					<td><img src="../../assets/images/WO2015.JPG" class="img-cms"></td>
		                            					<td><i class="fa fa-file-pdf-o text-danger"></i></td>
		                            					<td>11.03.2021</td>
		                            					<td>11:30 AM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditBlog">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>2</td>
		                            					<td>There are many variations of passages of Lorem Ipsum available</td>
		                            					<td><img src="../../assets/images/WO2016.JPG" class="img-cms"></td>
		                            					<td><i class="fa fa-file-pdf-o text-danger"></i></td>
		                            					<td>03.03.2021</td>
		                            					<td>12:50 PM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditBlog">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>3</td>
		                            					<td>Contrary to popular belief, Lorem Ipsum</td>
		                            					<td><img src="../../assets/images/ZoosOfOdisha.jpg" class="img-cms"></td>
		                            					<td><i class="fa fa-file-pdf-o text-danger"></i></td>
		                            					<td>25.02.2021</td>
		                            					<td>01:15 PM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditBlog">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>4</td>
		                            					<td>The standard chunk of Lorem Ipsum used since</td>
		                            					<td><img src="../../assets/images/Waterfowl.jpg" class="img-cms"></td>
		                            					<td><i class="fa fa-file-pdf-o text-danger"></i></td>
		                            					<td>17.02.2021</td>
		                            					<td>11:05 AM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditBlog">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>5</td>
		                            					<td>Sed ut perspiciatis unde omnis iste natus error sit</td>
		                            					<td><img src="../../assets/images/WildlifeOdisha2019.jpg" class="img-cms"></td>
		                            					<td><i class="fa fa-file-pdf-o text-danger"></i></td>
		                            					<td>29.01.2021</td>
		                            					<td>03:35 PM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditBlog">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            			</tbody> -->
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Gallery -->
            <div class="card" #Gallery>
              <div class="card-body">
                <a href="#collapseFour" class="text-collapse collapsed" data-toggle="collapse" aria-expanded="false"
                  aria-controls="collapseFour">
                  <span class="d-block accordion-toggle">Gallery</span>
                </a>

                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                  <div class="accordion-body">
                    <div class="table-responsive">

                      <div class="col-md-3">
                        <label>Type</label>
                        <select class="form-control" (change)="getGalleryImgByType(typeselect.value)" #typeselect>
                          <option value="0">-- Select All --</option>
                          <option *ngFor="let type of typeList" value={{type.typeId}}>
                            {{type.type}}
                          </option>
                        </select>
                      </div>
                      <hr>
                      <div class="table-responsive">
                        <table id="tableGallery" class="table table-bordered" width="99%" datatable
                          [dtOptions]="dtOptions" [dtTrigger]="dtTrigger4">
                          <thead>
                            <tr>
                              <th width="6%">Sl. No.</th>
                              <th>Type</th>
                              <!-- <th>Title</th> -->
                              <th>Image</th>
                              <th>Created on</th>
                              <th>Last Update on</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let list of galleryList; let i = index" [attr.data-index]="i">
                              <td>{{i+1}} </td>
                              <td>{{list.type}}</td>
                              <td>
                                <a href="{{list.imgPath}}" class="glightbox gallery_product filter elephant"
                                  data-fancybox="gallery" data-caption="">
                                  <img [src]="list.imgPath" *ngIf="list.imgPath" class="img-cms img-fluid mr-2 mb-2"
                                    alt="image" (error)="defaultUrl($event)" alt="">
                                  <div class="overlay">
                                    <span class="icon">
                                      <i class="fa fa-search"></i>
                                    </span>
                                  </div>
                                </a>
                              </td>
                              <td>{{ (list.createdOn !== 'null') ? (list.createdOn | date:'dd-MM-yyyy') : '' }} {{
                                (list.createdOn !== 'null') ? (list.createdOn | date:'shortTime') : '' }}</td>
                              <td> {{ (list.updatedOn !== 'null') ? (list.updatedOn | date:'dd-MM-yyyy') : '' }} {{
                                (list.updatedOn !== 'null') ? (list.updatedOn | date:'shortTime') : '' }} </td>
                              <td>
                                <!-- <button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditGallery" (click)="getGalleryItem(list)" >
                                                <i class="fa fa-edit"></i>
                                              </button> -->
                                <button *ngIf="IsAdmin" class="btn btn-sm btn-danger" (click)="deleteGalleryItem(list)">
                                  <i class="fa fa-trash"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                          <!-- <tbody>
		                            				<tr>
		                            					<td>1</td>
		                            					<td>Project Elephant</td>
		                            					<td>Elephants in Similipal</td>
		                            					<td><img src="../../assets/images/gallery-elephant-01.jpg" class="img-cms"></td>
		                            					<td>11.03.2021</td>
		                            					<td>11:30 AM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditGallery">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>2</td>
		                            					<td>Project Tiger</td>
		                            					<td>Rare Odisha Tiger</td>
		                            					<td><img src="../../assets/images/gallery-tiger-02.jpg" class="img-cms"></td>
		                            					<td>03.03.2021</td>
		                            					<td>12:50 PM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditGallery">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>3</td>
		                            					<td>Sea Turtle Conservation</td>
		                            					<td>Turtle Egg Hatching</td>
		                            					<td><img src="../../assets/images/gallery-turtle-01.jpg" class="img-cms"></td>
		                            					<td>25.02.2021</td>
		                            					<td>01:15 PM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditGallery">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>4</td>
		                            					<td>Project Elephant</td>
		                            					<td>Elephant Herd</td>
		                            					<td><img src="../../assets/images/gallery-elephant-02.jpg" class="img-cms"></td>
		                            					<td>17.02.2021</td>
		                            					<td>11:05 AM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditGallery">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>5</td>
		                            					<td>Crocodile Conservation</td>
		                            					<td>Bhiatarakanika Crocodile Conservation</td>
		                            					<td><img src="../../assets/images/gallery-crocodile-01.jpg" class="img-cms"></td>
		                            					<td>29.01.2021</td>
		                            					<td>03:35 PM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditGallery">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            			</tbody> -->
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Gallery Type -->
            <div class="card" #GalleryType>
              <div class="card-body">
                <a href="#collapseSix" class="text-collapse collapsed" data-toggle="collapse" aria-expanded="false"
                  aria-controls="collapseSix">
                  <span class="d-block accordion-toggle">Gallery Type</span>
                </a>
                <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                  <div class="accordion-body">
                    <div class="table-responsive">
                      <table id="tableGalleryType" class="table table-bordered" width="99%" datatable
                        [dtOptions]="dtOptions" [dtTrigger]="dtTrigger6">
                        <thead>
                          <tr>
                            <th width="6%">Sl. No.</th>
                            <th>Type</th>
                            <th>Created on</th>
                            <th>Last Update on</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let list of galleryType; let i = index" [attr.data-index]="i">
                            <td>{{i+1}} </td>
                            <td>{{list.type}}</td>
                            <td>{{ (list.createdOn !== 'null') ? (list.createdOn | date:'dd-MM-yyyy') : '' }} {{
                              (list.createdOn !== 'null') ? (list.createdOn | date:'shortTime') : '' }}</td>
                            <td> {{ (list.updatedOn !== 'null') ? (list.updatedOn | date:'dd-MM-yyyy') : '' }} {{
                              (list.updatedOn !== 'null') ? (list.updatedOn | date:'shortTime') : '' }} </td>
                            <td>
                              <label class="switch" *ngIf="IsAdmin">
                                <input type="checkbox" [checked]="list.active"
                                  (change)="changeGalleryTypeStatus(list.typeId,list.active)">
                                <span class="slider round"></span>
                                <span class="absolute-no">Inactive</span>
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Tender -->
            <div class="card" #Tender>
              <div class="card-body">
                <a href="#collapseFive" class="text-collapse collapsed" data-toggle="collapse" aria-expanded="false"
                  aria-controls="collapseFive">
                  <span class="d-block accordion-toggle">Tender</span>
                </a>
                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                  <div class="accordion-body">
                    <div class="table-responsive">
                      <table id="tableTender" class="table table-bordered" width="99%" datatable [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger5">
                        <thead>
                          <tr>
                            <th width="6%">Sl. No.</th>
                            <!-- <th>Year</th>
		                            					<th>Month</th> -->
                            <th>Tender No.</th>
                            <th>Title</th>
                            <th>File</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let list of tenderList; let i = index" [attr.data-index]="i">
                            <td>{{i+1}} </td>
                            <td>{{list.tenderNum}}</td>
                            <td>{{list.title}}</td>
                            <td> <a href="{{list.uploadPath}}" target="_blank"><i
                                  class="fa fa-file-pdf-o text-danger"></i></a></td>

                            <td>{{ (list.publicationDate !== 'null') ? (list.publicationDate | date:'dd-MM-yyyy') : ''
                              }}</td>
                            <td> {{ (list.publicationDate !== 'null') ? (list.publicationDate | date:'shortTime') : ''
                              }} </td>
                            <td>
                              <!-- <button  class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditTender" (click)="getTenderRecord(list)">
                                                <i class="fa fa-edit"></i>
                                              </button> -->
                              <button *ngIf="IsAdmin" class="btn btn-sm btn-danger" (click)="deleteTenderRecord(list)">
                                <i class="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <!-- <tbody>
		                            				<tr>
		                            					<td>1</td>
		                            					<td>2021</td>
		                            					<td>March</td>
		                            					<td>CX347PZ</td>
		                            					<td>Lorem Ipsum is simply dummy text of the printing</td>
		                            					<td><i class="fa fa-file-pdf-o text-danger"></i></td>
		                            					<td>11.03.2021</td>
		                            					<td>11:30 AM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditTender">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>2</td>
		                            					<td>2021</td>
		                            					<td>January</td>
		                            					<td>CX452JK</td>
		                            					<td>There are many variations of passages of Lorem</td>
		                            					<td><i class="fa fa-file-pdf-o text-danger"></i></td>
		                            					<td>03.03.2021</td>
		                            					<td>12:50 PM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditTender">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>3</td>
		                            					<td>2020</td>
		                            					<td>November</td>
		                            					<td>PD6756FT</td>
		                            					<td>Contrary to popular belief, Lorem Ipsum</td>
		                            					<td><i class="fa fa-file-pdf-o text-danger"></i></td>
		                            					<td>25.02.2021</td>
		                            					<td>01:15 PM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditTender">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>4</td>
		                            					<td>2020</td>
		                            					<td>July</td>
		                            					<td>PD5471GH</td>
		                            					<td>The standard chunk of Lorem Ipsum used since</td>
		                            					<td><i class="fa fa-file-pdf-o text-danger"></i></td>
		                            					<td>17.02.2021</td>
		                            					<td>11:05 AM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditTender">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            				<tr>
		                            					<td>5</td>
		                            					<td>2020</td>
		                            					<td>April</td>
		                            					<td>PD58741JV</td>
		                            					<td>Sed ut perspiciatis unde omnis iste natus error sit</td>
		                            					<td><i class="fa fa-file-pdf-o text-danger"></i></td>
		                            					<td>29.01.2021</td>
		                            					<td>03:35 PM</td>
		                            					<td>
		                            						<button class="btn btn-sm btn-success mr-1" data-toggle="modal" data-target="#modalEditTender">
		                            							<i class="fa fa-edit"></i>
															</button>
															<button class="btn btn-sm btn-danger">
		                            							<i class="fa fa-trash"></i>
															</button>
		                            					</td>
		                            				</tr>
		                            			</tbody> -->
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Notice -->
            <div class="card" #Notice>
              <div class="card-body">
                <a href="#collapseSeven" class="text-collapse collapsed" data-toggle="collapse" aria-expanded="false"
                  aria-controls="collapseSeven">
                  <span class="d-block accordion-toggle">Notice</span>
                </a>
                <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                  <div class="accordion-body">
                    <div class="table-responsive">
                      <table id="tableNotice" class="table table-bordered" width="99%" datatable [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger7">
                        <thead>
                          <tr>
                            <th width="6%">Sl. No.</th>
                            <!-- <th>Year</th>
		                            					<th>Month</th> -->
                            <th>Notice No.</th>
                            <th>Notice Date</th>
                            <th>Notice Validation Date</th>
                            <th>Notice Name</th>
                            <th>File</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let group of noticelist; let i = index" [attr.data-index]="i">
                            <td>{{i+1}} </td>
                            <td>{{group.noticeNumber}}</td>
                            <td>{{ (group.noticeDate !== 'null') ? (group.noticeDate | date:'dd-MM-yyyy') : '' }} {{
                              (group.noticeDate !== 'null') ? (group.noticeDate | date:'shortTime') : '' }}</td>
                            <td>{{ (group.noticeValidDate !== 'null') ? (group.noticeValidDate | date:'dd-MM-yyyy') : ''
                              }} {{ (group.noticeValidDate !== 'null') ? (group.noticeValidDate | date:'shortTime') : ''
                              }}</td>
                            <td>{{group.noticeName}}</td>
                            <td> <a href="{{group.uploadPath}}" target="_blank" class="btn btn-sm btn-success"><i
                                  class="fa fa-file-pdf-o text-danger"></i></a></td>
                            <td>
                              <button class="btn btn-sm btn-success mr-1" data-toggle="modal"
                                data-target="#modalEditNotice" (click)="getNoticeRecord(group)">
                                <i class="fa fa-edit"></i>
                              </button>
                              <button class="btn btn-sm btn-danger" (click)="deleteNoticeRecord(group)">
                                <i class="fa fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Control Room Info -->
            <div class="card" #ControlRoomInfo>
              <div class="card-body">
                <a href="#collapseEight" class="text-collapse collapsed" data-toggle="collapse" aria-expanded="false"
                  aria-controls="collapseEight">
                  <span class="d-block accordion-toggle">Control Room Info</span>
                </a>
                <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                  <div class="accordion-body">
                    <div class="table-responsive">
                      <table id="tableControlRoomInfo" class="table table-bordered" width="99%" datatable
                        [dtOptions]="dtOptions" [dtTrigger]="dtTrigger8">
                        <thead>
                          <tr>
                            <th width="5%">Sr. No.</th>
                            <th>Circle</th>
                            <th>Division</th>
                            <th>Control Room Number</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>

                          <tr *ngFor="let group of divisionList ; let i = index" [attr.data-index]="i">
                            <td>{{i+1}} </td>
                            <td>{{group.circleName}}</td>
                            <td>{{group.divisionName}}</td>
                            <td>{{group.controlRoomPHno}}</td>
                            <td>
                              <button class="btn btn-sm btn-success mr-1" data-toggle="modal"
                                data-target="#modalControlRoom" (click)="getDivisionInfo(group)">
                                <i class="fa fa-edit"></i>
                              </button>

                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--/accordion-->

        </div>
      </div>
    </div>

  </div>

  <!-- Modal News Updates -->
  <div class="modal fade" id="modalEditNewsUpdates" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLongTitle">Edit News Updates</h4>
          <button type="button" #modalEditNewsUpdatesClosebutton class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="updateNewsForm" (ngSubmit)="updateNewsRecord()">
            <input type="hidden" formControlName="newsid">
            <textarea id="news" formControlName="newsContent"
              [ngClass]="{ 'is-invalid': updateNewsForm.controls['newsContent'].invalid && (updateNewsForm.controls['newsContent'].dirty || updateNewsForm.controls['newsContent'].touched) }"></textarea>
            <div
              *ngIf="updateNewsForm.controls['newsContent'].invalid && (updateNewsForm.controls['newsContent'].dirty || updateNewsForm.controls['newsContent'].touched)"
              class="text-danger mt-1">
              <small *ngIf="updateNewsForm.controls['newsContent'].errors.required">
                Description is required.
              </small>
            </div>
            <hr>
            <div class="text-center">
              <button type="submit" class="btn btn-sm btn-success">Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal What is New -->
  <div class="modal fade" id="modalEditWhatIsNew" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLongTitle">Edit What is New</h4>
          <button type="button" #modalEditWhatIsNewClosebutton class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="updateNewContentForm" (ngSubmit)="updateNewContent()">
            <input type="hidden" formControlName="contentId">
            <textarea id="newContent" formControlName="newContent"
              [ngClass]="{ 'is-invalid': updateNewContentForm.controls['newContent'].invalid && (updateNewContentForm.controls['newContent'].dirty || updateNewContentForm.controls['newContent'].touched) }"></textarea>
            <div
              *ngIf="updateNewContentForm.controls['newContent'].invalid && (updateNewContentForm.controls['newContent'].dirty || updateNewContentForm.controls['newContent'].touched)"
              class="text-danger mt-1">
              <small *ngIf="updateNewContentForm.controls['newContent'].errors.required">
                Description is required.
              </small>
            </div>
            <hr>
            <div class="text-center">
              <button type="submit" class="btn btn-sm btn-success">Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Blog -->
  <div class="modal fade" id="modalEditBlog" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLongTitle">Edit Publication</h4>
          <button type="button" #modalEditBlogClosebutton class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="updatePublishedForm" (ngSubmit)="updatePublishedDoc()">
            <div class="row form-group">
              <div class="col-md-12">
                <label>Title</label>
                <input type="hidden" formControlName="publishedId">
                <input class="form-control" type="text" placeholder="Title" formControlName="title"
                  [ngClass]="{ 'is-invalid': updatePublishedForm.controls['title'].invalid && (updatePublishedForm.controls['title'].dirty || updatePublishedForm.controls['title'].touched) }" />
                <div
                  *ngIf="updatePublishedForm.controls['title'].invalid && (updatePublishedForm.controls['title'].dirty || updatePublishedForm.controls['title'].touched)"
                  class="text-danger mt-1">
                  <small *ngIf="updatePublishedForm.controls['title'].errors.required">
                    Title is required.
                  </small>
                </div>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-6">
                <label>Upload Thumbnail Image</label>
                <input type="file" formControlName="publicationThumbLineimg" accept='image/*'
                  (change)="onSelectPubThumbnailFile($event)" class="form-control" placeholder="Upload Thumbnail Image">
              </div>
              <div class="col-md-6">
                <label>Upload PDF</label>
                <input type="file" formControlName="publicationPdfFile" accept='pdf/*'
                  (change)="OnSelectPubPdfFile($event)" class="form-control" placeholder="Upload PDF">
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-md-12 text-center">
                <button type="submit" class="btn btn-sm btn-success">Update</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Gallery -->
  <div class="modal fade" id="modalEditGallery" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLongTitle">Edit Gallery</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row form-group">
              <div class="col-md-6">
                <label>Type</label>
                <!-- <select class="form-control" formControlName="type" [(ngModel)]='type' #typeselect>
                <option value="0">--  Select --</option>
                        <option    *ngFor="let type of typeList"   value={{type.typeId}} >
                          {{type.type}}
                        </option>
              </select> -->
              </div>
              <div class="col-md-6">
                <label>Title</label>
                <input class="form-control" type="text" placeholder="Title">
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-12">
                <div class="custom-file-container" data-upload-id="myFirstImage">
                  <label>Upload Image (Single) <a href="javascript:void(0)" class="custom-file-container__image-clear"
                      title="Clear Image">x</a></label>
                  <label class="custom-file-container__custom-file">
                    <input type="file" class="custom-file-container__custom-file__custom-file-input" accept="image/*">
                    <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
                    <span class="custom-file-container__custom-file__custom-file-control"></span>
                  </label>
                  <div class="custom-file-container__image-preview"></div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-md-12 text-center">
                <button type="submit" class="btn btn-sm btn-success">Update</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Tender -->
  <div class="modal fade" id="modalEditTender" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLongTitle">Edit Tender</h4>
          <button type="button"  #modalEditTenderUpdatesClosebutton class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="updateTenderForm" (ngSubmit)="updateTenderDoc()">
            <div class="row form-group">
              <div class="col-md-6">
                <label>Title</label>
                <input type="hidden" formControlName="tenderId">
                <textarea class="form-control" placeholder="Title" formControlName="title"
                  [ngClass]="{ 'is-invalid': updateTenderForm.controls['title'].invalid && (updateTenderForm.controls['title'].dirty || updateTenderForm.controls['title'].touched) }">	</textarea>
                <div
                  *ngIf="updateTenderForm.controls['title'].invalid && (updateTenderForm.controls['title'].dirty || updateTenderForm.controls['title'].touched)"
                  class="text-danger mt-1">
                  <small *ngIf="updateTenderForm.controls['title'].errors.required">
                    Title is required.
                  </small>
                </div>

              </div>
              <div class="col-md-6">
                <label>Tender No.</label>
                <input class="form-control" type="text" placeholder="Tender No." formControlName="tenderNo" readonly/>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-6">
                <label>Date</label>
                <div id="datepicker" class="input-group date" data-date-format="dd-mm-yyyy" title="From Date">
                  <input class="form-control" formControlName="fromdate" #fd id="fromdate" type="text" />
                  <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
                </div>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-12">
                <label>Upload File</label>
                <input class="form-control" type="file" placeholder="Upload File" formControlName="tenderfile"
                  accept='pdf/*' (change)="OnSelectTenderPdfFile($event)" />
              </div>
            </div>

            <hr>
            <div class="row">
              <div class="col-md-12 text-center">
                <button type="submit" class="btn btn-sm btn-success">Update</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Notice -->
  <div class="modal fade" id="modalEditNotice" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLongTitle">Edit Notice</h4>
          <button type="button" #modalEditNOTICEUpdatesClosebutton class="close" data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="updateNoticeForm" (ngSubmit)="UpdateNotice()">
            <input type="hidden" formControlName="noticeId">
            <div class="row form-group">
              <div class="col-md-3">
                <label>Notice No.</label>
                <input class="form-control" type="text" formControlName="noticeNumber" readonly />
              </div>
              <div class="col-md-3">
                <label>Date</label>
                <div id="datepicker1" class="input-group date" data-date-format="dd-mm-yyyy" title="From Date">
                  <input class="form-control" formControlName="noticeDate" #fdNotice id="fromdateNotice" type="text" />
                  <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
                </div>
              </div>
              <div class="col-md-3">
                <label>Notice Validation Date</label>
                <div id="datepicker2" class="input-group date" data-date-format="dd-mm-yyyy" title="From Date">
                  <input class="form-control" formControlName="noticeValidDate" #fdNotice_valid
                    id="fromdateNotice_valid" type="text" />
                  <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
                </div>
              </div>
              <div class="col-md-4">
                <label>Upload File</label>
                <input type="file" formControlName="noticefile" accept='pdf/*' (change)="OnSelectNoticePdfFile($event)"
                  class="form-control" placeholder="Upload PDF">
              </div>
            </div>
            <div class="row form-group">
              <div class="col-md-12">
                <label>Title</label>
                <textarea class="form-control" placeholder="Title"
                  [ngClass]="{ 'is-invalid': updateNoticeForm.controls['noticeName'].invalid && (updateNoticeForm.controls['noticeName'].dirty || updateNoticeForm.controls['noticeName'].touched) }"
                  formControlName="noticeName"></textarea>
                <div
                  *ngIf="updateNoticeForm.controls['noticeName'].invalid && (updateNoticeForm.controls['noticeName'].dirty || updateNoticeForm.controls['noticeName'].touched)"
                  class="text-danger mt-1">
                  <small *ngIf="updateNoticeForm.controls['noticeName'].errors.required">
                    Title is required.
                  </small>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-md-12 text-center">
                <div class="spinner text-center">
                  <div *ngIf="loader2" class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <button type="submit" class="btn btn-sm btn-success" [disabled]="loader2">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Division ControlRoom -->
  <div class="modal fade" id="modalControlRoom" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLongTitle">Edit Division Control Room</h4>
          <button type="button" #modalEditDivisionUpdatesClosebutton class="close" data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="updateDivisionForm" (ngSubmit)="UpdateDivisionControlRoom()">
            <input type="hidden" formControlName="divisionId">
            <div class="row form-group">
              <div class="col-md-4">
                <label>Control Room Number <span class="text-danger">*</span></label>
              </div>
              <div class="col-md-3">
                <input class="form-control" type="number" formControlName="controlRoomNumber"
                  [ngClass]="{ 'is-invalid': updateDivisionForm.controls['controlRoomNumber'].invalid && (updateDivisionForm.controls['controlRoomNumber'].dirty || updateDivisionForm.controls['controlRoomNumber'].touched) }"
                  class="form-control" id="controlRoomNumber" placeholder="Contact Number" />
              </div>
              <div *ngIf="updateDivisionForm.controls['controlRoomNumber'].invalid && (updateDivisionForm.controls['controlRoomNumber'].dirty || updateDivisionForm.controls['controlRoomNumber'].touched)"
                class="text-danger mt-1">
                <small *ngIf="updateDivisionForm.controls['controlRoomNumber'].errors.required">
                  Contact Number is required.
                </small>
                <small *ngIf="updateDivisionForm.controls['controlRoomNumber'].errors.pattern">
                  Contact Number is invalid. Contact Number must be at least 10 characters long.
                  Contact Number cannot be more than 10 characters long.
                </small>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-md-12 text-center">
                <button type="submit" class="btn btn-sm btn-success">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div id="footer">
    <app-masterfooter></app-masterfooter>
  </div>

</div>