import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogall',
  templateUrl: './blogall.component.html',
  styleUrls: ['./blogall.component.css']
})
export class BlogallComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
