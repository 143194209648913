<app-header *ngIf="isDashboard"></app-header>
<app-menu  *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
			        <li class="nav-item">
			            <a id="tab-debigarhPAmap" href="#pane-debigarhPAmap" class="nav-link active" data-toggle="tab" role="tab">Map</a>
			        </li>
			        <li class="nav-item">
			            <a id="tab-debigarhPAinfo" href="#pane-debigarhPAinfo" class="nav-link" data-toggle="tab" role="tab">Information</a>
			        </li>
			    </ul>

			    <div id="accordion" class="tab-content" role="tablist">
			        <div id="pane-debigarhPAmap" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-debigarhPAmap">
			            <div class="card-header" role="tab" id="heading-debigarhPAmap">
			                <h5 class="mb-0">
			                    <a data-toggle="collapse" href="#collapse-debigarhPAmap" aria-expanded="true" aria-controls="collapse-debigarhPAmap">
                            Map
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-debigarhPAmap" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-debigarhPAmap">
                    <div class="card-body">
                           <div class="map" id="mapdebrigarh">
                             <div class="gle-icon">
                              <a href="javascript:void(0)">
                                <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid" alt="Street View" title="Street View">
                            </a>
                            <a href="javascript:void(0)">
                                <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()' alt="Satellite View" title="Satellite View">
                            </a>
                            <a href="javascript:void(0)">
                                <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View" title="Topo View">
                            </a>
                                    </div>
                           </div>
                    </div>
			            </div>
			        </div>

			        <div id="pane-debigarhPAinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-debigarhPAinfo">
			            <div class="card-header" role="tab" id="heading-debigarhPAinfo">
			                <h5 class="mb-0">
			                    <a class="collapsed" data-toggle="collapse" href="#collapse-debigarhPAinfo" aria-expanded="false" aria-controls="collapse-debigarhPAinfo">
			                       Information
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-debigarhPAinfo" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-debigarhPAinfo">

                    <div class="card-body">

                      <h4 class="card-title">
                        Debrigarh Sanctuary
                        <span class="custom-down">
                          <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
                          <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                          </span>
                      </h4>
                      <div id="printcontent">
                      <div class="row">
                        <div class="col-md-8">
                          <div class="table-responsive">
                            <table class="table table-sm table-bordered">
                              <tbody>
                                <tr>
                                  <td><strong>Forest Division : </strong></td>
                                  <td>Hirakud (Wildlife) Division</td>
                                </tr>
                                <tr>
                                  <td><strong>District : </strong></td>
                                  <td>Sambalpur</td>
                                </tr>
                                <tr>
                                  <td><strong>Date of Notification : </strong></td>
                                  <td>08-02-1985</td>
                                </tr>
                                <tr>
                                  <td><strong>Notified Area : </strong></td>
                                  <td>346.91sq.kms</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-md-2 video-box no-print">
                          <a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/PFMAQF6nKIg" data-target="#myModal">
                            <h4>Debrigarh Sanctuary</h4>
                            <div class="overlay">
                              <span class="icon">
                                <i class="fa fa-play-circle"></i>
                              </span>
                            </div>
                          </a>
                        </div>
                        <div class="col-md-2">
                          <img src="assets/images/debri-pic.jpg" class="img-fluid float-right img-inner" alt="image">
                        </div>
                      </div>
                      <p>
                        Debrigarh Wildlife Sanctuary, an important site for in-situ conservation of wildlife and its habitat in the state of Odisha is home to an immense array of biodiversity, a large number of species of mammals, birds, reptiles, amphibians, fishes, odonates, butterflies and spiders are found here.The sanctuary is famous for its sylvan beauty and pristine wilderness. The dry deciduous forests with varied flora and fauna attract the nature-lovers of Odisha and neighboring states every year. The combination of dry deciduous mixed forests with rich wildlife, Hirakud reservior and attractive topographical features are the important features of Debrigarh wildlife sanctury.It is said to be one of the vibrant wildlife sanctuary of the state.
                      </p>
                      <h4 class="card-title">Quick Facts</h4>
                      <ul class="custom-list">
                        <li>It is one of the important sanctuary in Odisha, this is also known for the waterfalls falling down the hillsides in the monsoon season.</li>
                        <li>The Hirakud reservoir attracts large number of migratory birds during winter.</li>
                        <li>Chausingha,Gaur,elephant,Sambar and Spotted Deer, Bear, Leopard, porcupine, Jackal, Fox, Wolf, Hyena, Wild Dog are the main species found only at the Debrigarh Wildlife Sanctuary.</li>
                      </ul>
                      <h4 class="card-title">How to reach</h4>
                      <p class="address-inner">
                        <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>The Sanctuary can be entered through Dhodrokusum by the Hirakud reservoir and is situated 60 kms from Baragarh and 40 kms from Sambalpur.
                      </p>
                      <p class="address-inner">
        <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong>The nearest railway station is Sambalpur which is 40km Away.
      </p>
      <p class="address-inner">
        <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>The nearest airport is Raipur and Bhubaneswar.
                      </p>
                      <h4 class="card-title">Facilities available</h4>
                      <ul class="custom-list">
                        <li>There are 6 Eco-tourism Cottages in this Sanctuary which can be booked online from the site www.ecotourodisha.com</li>
                        <li>Boating facility is available at Debrigarh Sanctuary only on prior intimation.</li>
                        <li>Accommodation is available at the Forest Rest Houses in Dhodrokusum and DechuaGeneral visitors are allowed to visit the sanctuary from 6:00am to 6:00pm from Dhodrokusum to Chourasimal on the forest road(21kms approximately)</li>
                      </ul>
                      <h4 class="card-title">Salient Detail</h4>
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td width="15%"><strong>Geographic Extent : </strong></td>
                              <td width="85%">Longitude 83° 30' to 83° 46'(East) Latitude 21° 28' to 21° 44'(North)</td>
                            </tr>
                            <tr>
                              <td><strong>Climate : </strong></td>
                              <td>Temperature - In summer: 45°C, In winter: 8°C(Approx.).</td>
                            </tr>
                            <tr>
                              <td><strong>Villages :</strong></td>
                              <td>None</td>
                            </tr>
                            <tr>
                              <td><strong>Contact officer : </strong></td>
                              <td>
                                <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest Officer<br>
                                <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Hirakud Wildlife Division, Sambalpur.<br>
                                <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>0663-2548743 <br>
                                <span class="address-inner"><i class="fa fa-envelope">&nbsp;</i></span>hirakudwildlife1@gmail.com
                              </td>
                            </tr>
                            <tr>
                              <td><strong>Best time to visit : </strong></td>
                              <td>November - March (Sanctuary remains closed from 15th June to 30th October)</td>
                            </tr>
                            <tr>
                              <td><strong>Accommodation : </strong></td>
                              <td>6 numbers of cottages(5 double-bedded and one 4 bedded)which can be booked online from the website www.ecotourodisha.com</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>
                    </div>

            </div>
			            </div>
			        </div>

			    </div>

			</div>
		</div>
	</div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video"  allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
