import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
declare var jQuery: any;

@Component({
  selector: 'app-footerlanding',
  templateUrl: './footerlanding.component.html',
  styleUrls: ['./footerlanding.component.css']
})
export class FooterlandingComponent implements OnInit {
  smsCount:any;
  currentYear: number = new Date().getFullYear();
  constructor(private apiService: ApiService) { }

  ngOnInit(): void {

    (function($){

    })(jQuery);
    //this.getSmsCount();
  }
  // getSmsCount() {
  //   this.apiService.request('GET', 'api/v1/reports/getSMSCount24Hrs').subscribe((data:any) => {
  //     if (data) {
  //          this.smsCount=data;
  //       }
  //   });
  // }

}
