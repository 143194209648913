import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from '../service/api.service';
import { NotificationService } from '../service/notification.service';
declare var jQuery: any;
const baseUrlFile = environment.FILE_API_URL;

@Component({
  selector: 'app-blogapplication',
  templateUrl: './blogapplication.component.html',
  styleUrls: ['./blogapplication.component.css']
})
export class BlogapplicationComponent implements OnInit {
  public loader: boolean=false;
  blogForm: any;
  selectedBlogImgFiles: any;
  blogList: any;
  roleId: any;
  updateBlogForm: any;


  constructor(private formBuilder: FormBuilder,private router: Router,private apiService: ApiService,private notifyService:NotificationService) { }
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {};
  @ViewChild('closebutton') closebutton;

  ngOnInit(): void {
    this.blogForm = this.formBuilder.group({
      title:  ['',[Validators.required]],
      blogImg:  [''],
      blogDesc: ['',[Validators.required]]
    });
    this.updateBlogForm= this.formBuilder.group({
      title:  ['',[Validators.required]],
      file:  [''],
      blogid:[],
      blogDesc: ['',[Validators.required]]
    });
  	(function($){

      	$('a[data-toggle="tab"]').on('shown.bs.tab', function(e){
		    $($.fn.dataTable.tables(true)).DataTable()
		        .columns.adjust()
		        .responsive.recalc();
		});

    })(jQuery);
    const userdata=sessionStorage.getItem('user');
    if(userdata!=null){
      const data=JSON.parse(userdata);
        if(data!=null){
            this.roleId=data.roleId;
             this.getAllBlog();
         }
       } else{
      this.router.navigate(['']);
       return;
    }

  }
  getAllBlog() {
    this.loader=true;
    const userdata=sessionStorage.getItem('user');
    if(userdata!=null){
      const user=JSON.parse(userdata);
      //getAllBlogData
      if(this.roleId==1){
         this.apiService.request('GET', 'api/v1/cms/getAllBlogData').subscribe((data: any) => {
          if (data) {

            this.blogList = new Array() ;
            data.sort(function(a, b) {
              return b.seqNo- a.seqNo;
            });
            data.sort((a, b) => {
              return <any>new Date(b.craetedOn) - <any>new Date(a.craetedOn);
            });
            data.map(item=>{
              return {
                blogid:item['blog_id'],
                blogImgPath : baseUrlFile+'blog/'+item['blogImgPath'],
                blogNum:item['blogNum'],
                blogDesc:item['blogDesc'],
                pdfPath: baseUrlFile+'blog/'+item['pdfPath'],
                createdByName:item['createdByName'],
                craetedOn:item['craetedOn'],
                title: item['title']
              }
            }).forEach(item=> this.blogList.push(item));
            this.dtTrigger.next();
            this.loader=false;
            }
       });
      }else{
        this.apiService.request('GET', 'api/v1/cms/getAllBlogByUserId?id='+user.userid).subscribe((data: any) => {
          if (data) {
            this.blogList = new Array() ;
            data.sort(function(a, b) {
              return b.seqNo- a.seqNo;
            });
            data.map(item=>{
              return {
                blogid:item['blog_id'],
                blogImgPath : baseUrlFile+'blog/'+item['blogImgPath'],
                blogNum:item['blogNum'],
                blogDesc:item['blogDesc'],
                pdfPath: baseUrlFile+'blog/'+item['pdfPath'],
                craetedOn:item['craetedOn'],
                title: item['title']
              }
            }).forEach(item=> this.blogList.push(item));
            this.dtTrigger.next();
            this.loader=false;
            }
       });
      }
   }
  }
  getblogRecordByID(blog:any){
    //console.log(blog)
    this.updateBlogForm.get('title').setValue(blog.title);
    this.updateBlogForm.get('blogDesc').setValue(blog.blogDesc);
    this.updateBlogForm.get('blogid').setValue(blog.blogid);
  }

  defaultUrl(event) {
    event.target.src='assets/images/no_img.png';
  }
  onSelectBlogImg(event) {
    this.selectedBlogImgFiles='';
    const file = event.target.files.item(0)
      //console.log(file)
      //console.log(event)
        if (file.type.match('image.*')) {
          this.selectedBlogImgFiles = file;
        } else {
          this.notifyService.showError("invalid format!.Only images are supported.","Error");
          this.blogForm.controls.blogImg.setValue("");
        }
    if (event.length === 0)
      return;
  }

  addBlog(){
   this.loader=true;
    const userdata=sessionStorage.getItem('user');
    if(userdata!=null){
      const user=JSON.parse(userdata);
        if(user!=null){

          if(this.selectedBlogImgFiles!="" && this.blogForm.value.blogImg!=""){
            let formdata: FormData = new FormData();
            formdata.append('file', this.selectedBlogImgFiles);

            this.apiService.request('POST', 'api/v1/cms/addNewBlog?title='+this.blogForm.value.title+'&blogDesc='+this.blogForm.value.blogDesc+'&userId='+user.userid+'&type=blog',formdata).subscribe(
              (response: any) => {
                if(response.message=="success"){
                  this.notifyService.showSuccess("New Blog Added Successfully","Success");
                  $("#tableBlog").DataTable().destroy();
                  // this.blogForm.controls.title.setValue("");
                  // this.blogForm.controls.blogImg.setValue("");
                  // this.blogForm.controls.blogDesc.setValue("");
                  this.ngOnInit();
                  this.loader=false;
                }else if(response.message=="fail"){
                  this.notifyService.showError("UnSuccessful","Error");
                  this.loader=false;
                }
            },err => {
              this.loader=false;
            });
          }else{
            this.apiService.request('POST', 'api/v1/cms/addNewBlogWithoutImg?title='+this.blogForm.value.title+'&blogDesc='+this.blogForm.value.blogDesc+'&userId='+user.userid+'&type=blog').subscribe(
              (response: any) => {
                if(response.message=="success"){
                  this.notifyService.showSuccess("New Blog Added Successfully","Success");
                  $("#tableBlog").DataTable().destroy();
                  this.ngOnInit();
                  this.loader=false;
                }else if(response.message=="fail"){
                  this.notifyService.showError("UnSuccessful","Error");
                  this.loader=false;
                }
            },err => {
              this.loader=false;
            });
          }
        }
    } else{
      this.router.navigate(['']);
       return;
    }
  }
  UpdateBlog(){
 try{
     this.loader=true;
     const userdata=sessionStorage.getItem('user');
     if(userdata!=null){
       const user=JSON.parse(userdata);
         if(user!=null){
          //console.log(this.selectedBlogImgFiles);
          //console.log(this.updateBlogForm.value.file);
            if(this.selectedBlogImgFiles!="" && this.updateBlogForm.value.file!=""){
              let formdata: FormData = new FormData();
              formdata.append('file', this.selectedBlogImgFiles);
              this.apiService.request('PUT', 'api/v1/cms/updateBlog?blogTitle='+this.updateBlogForm.value.title+'&blogDesc='+this.updateBlogForm.value.blogDesc+'&userId='+user.userid+'&blogId='+this.updateBlogForm.value.blogid+'&type=blog',formdata).subscribe(
                (res: any) => {
                  var response = JSON.parse(res);
                  if(response.message=="success"){
                    this.closebutton.nativeElement.click();
                    this.notifyService.showSuccesswithTimeout("Blog Update Successfully","Success");
                    $("#tableBlog").DataTable().destroy();
                     this.getAllBlog();
                    this.loader=false;
                  }else if(response.message=="fail"){
                    this.notifyService.showError("UnSuccessful","Error");
                    this.loader=false;
                  }
              },err => {
                this.loader=false;
              });
            }else{

              this.apiService.request('PUT', 'api/v1/cms/updateBlogWithoutImage?blogTitle='+this.updateBlogForm.value.title+'&blogDesc='+this.updateBlogForm.value.blogDesc+'&userId='+user.userid+'&blogId='+this.updateBlogForm.value.blogid+'&type=blog').subscribe(
                (res: any) => {
                  //console.log(res)
                  var jsonData = JSON.parse(res);
                  if(jsonData.message=="success"){
                    this.closebutton.nativeElement.click();
                    this.notifyService.showSuccesswithTimeout("Blog Update Successfully","Success");
                    $("#tableBlog").DataTable().destroy();
                    this.getAllBlog();
                    this.loader=false;
                  }else if(jsonData.message=="fail"){

                    this.notifyService.showError("UnSuccessful","Error");
                    this.loader=false;
                  }
              },err => {
                this.loader=false;
              });
            }
         }
     } else{
       this.router.navigate(['']);
        return;
     }
    }catch{
      this.loader=false;
    }
   }
   deleteBlog(element:any){
     //console.log(element)
     var flag=confirm('Are you sure!!!!');
     if(flag){
        const userdata=sessionStorage.getItem('user');
            if(userdata!=null)
             {
              const user=JSON.parse(userdata);
                  this.apiService.request('PUT', 'api/v1/cms/deleteBlogById?userId='+user.userid+'&blogId='+element.blogid).subscribe((res: any) => {
                    var response = JSON.parse(res);
                    if(response.message=="success"){
                        this.notifyService.showSuccesswithTimeout("Deleted Successfully","Success");
                          $("#tableBlog").DataTable().destroy();
                          this.getAllBlog()
                          this.loader=false;
                        }else{
                          this.notifyService.showError(" UnSuccessfully","Error");
                          this.loader=false;
                        }
                      });
                  } else{
                    this.router.navigate(['']);
                      return;
                  }
      }else{
        $("#tableBlog").DataTable().destroy();
        this.getAllBlog();
        this.notifyService.showError("You clicked the Cancel button! ","");
        this.loader=false;
        }
   }
}
