import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators } from "@angular/forms";
import {Router} from "@angular/router";
import { BehaviorSubject, throwError } from 'rxjs';
import { AutologoutService } from 'src/app/service/autologout.service';
import {ApiService} from "../../service/api.service"
import { AuthService } from "../../service/auth.service";
import { NotificationService } from '../../service/notification.service';
import { ConfirmedValidator } from '../../validators/confirmed.validator';

declare var jQuery: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  private loggedIn = new BehaviorSubject<boolean>(false);
  private token: string;
  public loader: boolean=false;
  public showOtp:boolean=false;
  public showOtpforgotpw:boolean=false;
  public isOTPsend:boolean=false;
  public isVerified:boolean=false;
  public generatebtnforgotPwd:boolean=true;
  public generatebtn:boolean=true;
  public verifybtn:boolean=false;
  public verifybtnforgotPWd:boolean=false;
  public isResendOtp: boolean=false;
  public showPasswdbox: boolean=false;
  public submitbtnforgotPWd: boolean=false;
  mobno:any


  loginForm: FormGroup;
  loginFormMobile: FormGroup;
  loginErrorData: string;
  loginSuccessData: string;
  forgotpasswordForm: FormGroup;
  code: number;
  captcha: string;


  constructor(private formBuilder: FormBuilder,private notifyService:NotificationService, private router: Router,private autologout:AutologoutService, private apiService: ApiService,private authService: AuthService) {
     const userData = sessionStorage.getItem('user');
     if (userData) {
       const user = JSON.parse(userData);
       this.token = user.token;
       this.apiService.setLoggedIn(true, this.token);
       this.loggedIn.next(true);
     }
   }

  ngOnInit(): void {
   this.loader=false;
  this.loginForm = this.formBuilder.group({
     login_id: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9._-]{4,20}')]],
     password: ['', Validators.compose([Validators.required,Validators.minLength(8)])],
     captchaValue:['']
  });
  // this.loginForm = this.formBuilder.group({
 //   login_id: ['', Validators.compose([Validators.required])],
  //  password: ['', Validators.required],
 //   captchaValue:['']
  //});
  this.loginFormMobile = this.formBuilder.group({
    mobileno:  ['',Validators.compose([Validators.required,Validators.pattern("[0-9 ]{10}")])],
    otp:  ['',Validators.compose([Validators.required,Validators.pattern("[0-9 ]{6}")])]
  });
  this.forgotpasswordForm = this.formBuilder.group({
    mobilenumber:  ['',Validators.compose([Validators.required,Validators.pattern("[0-9 ]{10}")])],
    otpforgot:  ['',Validators.compose([Validators.required,Validators.pattern("[0-9 ]{6}")])],
    password: ['',Validators.compose([Validators.required,Validators.minLength(8)])],
    cpassword: ['', Validators.required]
  }, {
    validator: ConfirmedValidator('password', 'cpassword')
  });

  var ds=this;
    (function($){

      $('#modalPassword').on('shown.bs.modal', function () {
        ds.loader=false;
        ds.showOtpforgotpw=false;
       ds.isOTPsend=false;
       ds.isVerified=false;
       ds.generatebtnforgotPwd=true;
       ds.generatebtn=true;
       ds.verifybtn=false;
       ds.verifybtnforgotPWd=false;
       ds.showPasswdbox=false;
       ds.isResendOtp=false;
       ds.submitbtnforgotPWd=false;
       ds.forgotpasswordForm.reset();
       ds.forgotpasswordForm.controls.mobilenumber.enable();
       ds.forgotpasswordForm.controls.otpforgot.enable();
     });
     $('#modalLogin').off("shown.bs.modal").on('shown.bs.modal', function (e) {
      ds.loader=false;
      ds.showOtpforgotpw=false;
     ds.isOTPsend=false;
     ds.isVerified=false;
     ds.generatebtnforgotPwd=true;
     ds.generatebtn=true;
     ds.verifybtn=false;
     ds.verifybtnforgotPWd=false;
     ds.isResendOtp=false;
     ds.submitbtnforgotPWd=false;
     ds.forgotpasswordForm.controls.mobilenumber.enable();
     ds.forgotpasswordForm.controls.otpforgot.enable();
     ds.forgotpasswordForm.reset();
     window.sessionStorage.removeItem('code');
     ds.createCaptcha();
     e.preventDefault();
   });
   $("#closeBtn").on('click', function(event : any) {

     window.sessionStorage.removeItem('code');
    //window.sessionStorage.removeItem('code');
     ds.loader=false;
   });

    $("#show_hide_password a").on('click', function(event : any) {
        event.preventDefault();
        if($('#show_hide_password input').attr("type") == "text"){
            $('#show_hide_password input').attr('type', 'password');
            $('#show_hide_password i').addClass( "fa-eye-slash" );
            $('#show_hide_password i').removeClass( "fa-eye" );
        }else if($('#show_hide_password input').attr("type") == "password"){
            $('#show_hide_password input').attr('type', 'text');
            $('#show_hide_password i').removeClass( "fa-eye-slash" );
            $('#show_hide_password i').addClass( "fa-eye" );
        }
    });


    })(jQuery);

    window.sessionStorage.removeItem('token');
    window.sessionStorage.removeItem("username");
    window.sessionStorage.removeItem("user_type");
  }
  async onSubmit() {
    var y: number=parseInt(sessionStorage.getItem('code'));
    if (this.loginForm.value.captchaValue== y) {
      this.loginErrorData = "";
     this.loader=true;
      if (this.loginForm.valid)
      {
          try {
                 await this.authService.login(this.loginForm.value).subscribe((response: any) => {
                    // console.log(response);
                    if (response.username!==undefined  && response.accessToken !== undefined) {
                      this.token = response.accessToken;
                      this.apiService.setLoggedIn(true, this.token);
                      this.loggedIn.next(true);
                      const userData = {
                        userid:response.id,
                        username:response.username,
                        email:response.email,
                        roles:response.roles,
                        roleId:response.roleId,
                        juridictionId:response.juridictionId,
                        circleId:response.circleId,
                        divisionId: response.divisionId,
                        rangeId:response.rangeId,
                        sectionId:response.sectionId,
                        beatId: response.beatId,
                        token: this.token,
                        seqNo:response.seqNo
                      };

                      sessionStorage.setItem('user', JSON.stringify(userData));
                      sessionStorage.setItem('token', this.token);
                      this.autologout.setLastAction(Date.now());
                      window.sessionStorage.removeItem('code');
                      //window.location.href = 'dashboard';
                      this.router.navigate(['dashboard']);
                    }else   if (response.message=="invalidUser"){
                      this.notifyService.showError("Please check whether your username and password are correct.","Error");
                      this.loader=false;
                      window.sessionStorage.removeItem('token');
                      window.sessionStorage.removeItem("username");
                      window.sessionStorage.removeItem("user_type");
                    }
                  }
                  ,(error) => {
                      const errors = error;
                      this.loader=false;
                      //console.log(errors);
                     // this.notifyService.showError("Please check whether your username and password are correct.","");
                      //return throwError(errors);
                   }
                  );

              }catch (err) {
                const errors = err;
                this.loader=false;
                //console.log(errors)
                return throwError(errors);
              }
      } else {
        this.notifyService.showError("Login Failed","Error");
        this.loginErrorData =  "User not active.";
        this.loader=false;
        return;
      }
      setTimeout(() => {
        this.loginErrorData = "";
      }, 5000);
    }else{
      this.notifyService.showError("Invalid Captcha. try Again","Error");
      //alert("Invalid Captcha. try Again");
      this.loginForm.controls.captchaValue.setValue("");
      this.createCaptcha();
    }
  }
  closebtnClick(){
    sessionStorage.removeItem('code');
    //window.sessionStorage.removeItem('code');
    this.loader=false;
  }
   createCaptcha() {
    window.sessionStorage.removeItem('code');
    //clear the contents of captcha div first
    document.getElementById('captcha').innerHTML = "";
    var charsArray =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
    //var lengthOtp = 6;

    let x = Math.floor((Math.random() * 10) + 1);
    let y=  Math.floor((Math.random() * 10) + 1);


    let captcha = x.toString() + " + " +y.toString() + " = ";

    var canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 100;
    canv.height = 50;
    var ctx = canv.getContext("2d");
    ctx.font = "25px Georgia";
    ctx.strokeText(captcha, 0, 30);
    //storing captcha so that can validate you can save it somewhere else according to your specific requirements

    document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
   let z = x + y;
    sessionStorage.setItem('code',z.toString());

  }
  // createCaptcha() {

  //   //console.log("1")
  //   //clear the contents of captcha div first
  //   document.getElementById('captcha').innerHTML = "";
  //   var charsArray =
  //   "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
  //   var lengthOtp = 6;
  //   var captcha = [];
  //   for (var i = 0; i < lengthOtp; i++) {
  //     //below code will not allow Repetition of Characters
  //     var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
  //     if (captcha.indexOf(charsArray[index]) == -1)
  //       captcha.push(charsArray[index]);
  //     else i--;
  //   }
  //   var canv = document.createElement("canvas");
  //   canv.id = "captcha";
  //   canv.width = 100;
  //   canv.height = 50;
  //   var ctx = canv.getContext("2d");
  //   ctx.font = "25px Georgia";
  //   ctx.strokeText(captcha.join(""), 0, 30);
  //   //storing captcha so that can validate you can save it somewhere else according to your specific requirements
  //   this.code = captcha.join("");
  //   document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
  // }
  generateotp(){
    try{
      let mobile=this.loginFormMobile.value.mobileno;
      if (mobile !== '') {
         this.apiService.request('POST', 'api/v1/auth/generateotp?mobileNumber='+mobile).subscribe((response: any) => {
      //console.log(response.message);
          if(response.message=="success"){
            this.showOtp=true;
            this.generatebtn=false;
            this.verifybtn=true;
            this.notifyService.showSuccess("OTP generated successfully","Success");
            this.loginErrorData =  "";
            this.loginSuccessData="Success : OTP generated successfully";

          }else if(response.message=="notValid"){
            this.notifyService.showError("Mobile Number is not registered","Error");
            this.showOtp=false;
            this.generatebtn=true;
            this.verifybtn=false;

            this.loginErrorData = "Error : Mobile Number is not registered";
            this.loginSuccessData="";
          }
        },(error) => {
            const errors = error;
            //console.log(errors);
            return throwError(errors);
          });
        }else{
          this.notifyService.showError("Mobile Number is required","Error");
          this.loginErrorData =  "Error : Mobile Number is required";
          this.loginSuccessData="";
        }
      }catch(err){
        this.showOtp=false;
        this.generatebtn=true;
        this.verifybtn=false;
      }
  }
  loginWithOtp(){
    try{
      this.loader=true;
      let mobile=this.loginFormMobile.value.mobileno;
      let otp=this.loginFormMobile.value.otp;
      if(mobile!=""){
        if(otp!=""){
          return this.apiService.request('POST', 'api/v1/auth/otpBasedLogin', {
            login_id: mobile,
            password: otp
          }).subscribe((response: any) => {
            if (response.username!==undefined  && response.accessToken !== undefined) {
              this.token = response.accessToken;
              this.apiService.setLoggedIn(true, this.token);
              this.loggedIn.next(true);
              const userData = {
                userid:response.id,
                username:response.username,
                email:response.email,
                roles:response.roles,
                roleId:response.roleId,
                juridictionId:response.juridictionId,
                circleId:response.circleId,
                divisionId: response.divisionId,
                rangeId:response.rangeId,
                sectionId:response.sectionId,
                beatId: response.beatId,
                token: this.token,
                seqNo:response.seqNo
              };
              sessionStorage.setItem('user', JSON.stringify(userData));
              sessionStorage.setItem('token', this.token);
              //window.location.href = 'dashboard';
              this.router.navigate(['dashboard']);
            }else if(response.message=="invalidOtp"){
              this.notifyService.showError("Invalid OTP","Error");
              this.loginErrorData = "Error : Invalid OTP";
              this.loginSuccessData="";
              this.loader=false;
            }else if(response.message=="invalidMobile"){
              this.notifyService.showError("Invalid Mobile Number","Error");
              this.loginErrorData = "Error : Invalid Mobile Number";
              this.loginSuccessData="";
              this.loader=false;
            }
          }
          ,(error) => {
              const errors = error;
              //console.log(errors);
              return throwError(errors);
          }
          );
        }else{
          this.notifyService.showError("Please enter OTP ","Error");
          this.loader=false;
        }
      }else{
        this.notifyService.showError("Please enter Mobile Number ","Error");
        this.loader=false;
      }
    }catch(err){
      this.loader=false;
    }

  }

// forgotpassword

  generateotpforgotPwd(){
    try{
      if(this.isResendOtp==true){
        this.forgotpasswordForm.controls.otpforgot.setValue('');
      }
      this.loader=true;
      let mobile=this.forgotpasswordForm.value.mobilenumber;
      if (mobile !== '') {
         this.apiService.request('POST', 'api/v1/auth/generateotp?mobileNumber='+mobile).subscribe((response: any) => {
      //console.log(response.message);
          if(response.message=="success"){
           // this.forgotpasswordForm.controls.mobileno.disable();
            this.showOtpforgotpw=true;
            this.generatebtnforgotPwd=false;
            this.verifybtnforgotPWd=true;
            this.isResendOtp=true;
            this.isOTPsend=true;
            this.notifyService.showSuccess("OTP generated successfully","Success");
            this.loginErrorData =  "";
            this.loginSuccessData="Success : OTP generated successfully";
            this.loader=false;

          }else if(response.message=="notValid"){
            this.notifyService.showError("Mobile Number is not registered","Error");
            this.showOtpforgotpw=false;
            this.generatebtnforgotPwd=true;
            this.verifybtnforgotPWd=false;
            this.isOTPsend=false;
            this.loginErrorData = "Error : Mobile Number is not registered";
            this.loginSuccessData="";
            this.loader=false;
          }
        },(error) => {
            const errors = error;
            //console.log(errors);
            return throwError(errors);
          });
        }else{
          this.notifyService.showError("Mobile Number is required","Error");
          this.loginErrorData =  "Error : Mobile Number is required";
          this.loginSuccessData="";
          this.loader=false;
        }
      }catch(err){
        this.loader=false;
        this.showOtpforgotpw=false;
        this.generatebtnforgotPwd=true;
        this.verifybtnforgotPWd=false;
      }
  }
  verifyOtp(){
    try{
      this.loader=true;
      let mobile=this.forgotpasswordForm.value.mobilenumber;
      let otp=this.forgotpasswordForm.value.otpforgot;
      if(mobile!=""){
        if(otp!=""){
          return this.apiService.request('POST', 'api/v1/auth/otpVerified', {
            login_id: mobile,
            password: otp
          }).subscribe((response: any) => {
            //console.log(response);
            if (response.message=="verified") {
              this.forgotpasswordForm.controls.mobilenumber.disable();
              this.forgotpasswordForm.controls.otpforgot.disable();
              this.showOtpforgotpw=true;
              this.generatebtnforgotPwd=false;
              this.verifybtnforgotPWd=false;
              this.isResendOtp=false;
              this.isOTPsend=false;
              this.isVerified=true;
              this.showPasswdbox=true;
              this.submitbtnforgotPWd=true;
              this.notifyService.showSuccess("OTP Verified successfully","Success");
              this.loginErrorData =  "";
              this.loginSuccessData="Success : OTP Verified successfully";
              this.loader=false;
            }else if(response.message=="invalidOtp"){
              this.notifyService.showError("Invalid OTP","Error");
              this.loginErrorData = "Error : Invalid OTP";
              this.loginSuccessData="";
              this.loader=false;
            }else if(response.message=="invalidMobile"){
              this.notifyService.showError("Invalid Mobile Number","Error");
              this.loginErrorData = "Error : Invalid Mobile Number";
              this.loginSuccessData="";
              this.loader=false;
            }
          }
          ,(error) => {
              const errors = error;
              //console.log(errors);
              return throwError(errors);
          }
          );
        }else{
          this.notifyService.showError("Please enter OTP ","Error");
          this.loader=false;
        }
      }else{
        this.notifyService.showError("Please enter Mobile Number ","Error");
        this.loader=false;
      }
    }catch(err){
      this.loader=false;
    }
  }
  resetPwdwithOtp(value:any){
    try{
      this.loader=true;
      let mobile=this.mobno;
      let pwd=value.password;
      //console.log(this.mobno)
      if(mobile!=""){
        if(pwd!=""){
          return this.apiService.request('PUT', 'api/v1/auth/resetPasswordWithMobile?mobile='+mobile+'&newPassword='+pwd).subscribe((res: any) => {
            //console.log(res);
            var response = JSON.parse(res);
            if (response.message=="Success") {
             this.loader=false;
             this.showOtpforgotpw=false;
            this.isOTPsend=false;
            this.isVerified=false;
            this.generatebtnforgotPwd=true;
            this.generatebtn=true;
            this.verifybtn=false;
            this.verifybtnforgotPWd=false;
            this.showPasswdbox=false;
            this.isResendOtp=false;
            this.submitbtnforgotPWd=false;
            this.forgotpasswordForm.reset();
            this.forgotpasswordForm.controls.mobilenumber.enable();
            this.forgotpasswordForm.controls.otpforgot.enable();
              this.notifyService.showSuccess("Password Changed successfully","Success");
              this.loginErrorData =  "";
              this.loginSuccessData="Success : Password Changed successfully";
              this.loader=false;
            }else if(response.message=="failed"){
              this.notifyService.showError("Error","Error");
              this.loginErrorData = "Error";
              this.loginSuccessData="";
              this.loader=false;
            }else if(response.message=="invalidMobile"){
              this.notifyService.showError("Invalid Mobile Number","Error");
              this.loginErrorData = "Error : Invalid Mobile Number";
              this.loginSuccessData="";
              this.loader=false;
            }
          }
          ,(error) => {
              const errors = error;
              //console.log(errors);
              return throwError(errors);
          }
          );
        }else{
          this.notifyService.showError("Please enter Password ","Error");
          this.loader=false;
        }
      }else{
        this.notifyService.showError("Please enter Mobile Number ","Error");
        this.loader=false;
      }
    }catch(err){
      this.loader=false;
    }
  }

}
