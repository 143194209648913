


<app-masterheader></app-masterheader>

<div class="wrapper">

    <app-sidebar></app-sidebar>

    <div id="content">

        <!-- <app-breadcrumb></app-breadcrumb> -->

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Common Element</h4>

                                    <!-- <div class="loader"><div class="lds-loader"><div></div><div></div><div></div></div></div> -->

									<div class="row">
										<div class="col-md-12">
											<button id="success" class="btn btn-sm btn-success">Success</button>
											<button id="error" class="btn btn-sm btn-danger">Error</button>
											<button id="warning" class="btn btn-sm btn-warning">Warning</button>
											<button id="info" class="btn btn-sm btn-info">Info</button>
											<button id="question" class="btn btn-sm btn-secondary">Question</button>
											<button id="confirm" class="btn btn-sm btn-dark">Confirm</button>
										</div>
									</div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

    <div id="footer">
        <app-masterfooter></app-masterfooter>
    </div>

</div>


