import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WildlifeunitsbalasoreComponent } from './wildlifeunitsbalasore/wildlifeunitsbalasore.component';
import { WildlifeunitsbamraComponent } from './wildlifeunitsbamra/wildlifeunitsbamra.component';
import { WildlifeunitsbhadrakComponent } from './wildlifeunitsbhadrak/wildlifeunitsbhadrak.component';
import { WildlifeunitschandakaComponent } from './wildlifeunitschandaka/wildlifeunitschandaka.component';
import { WildlifeunitschilikaComponent } from './wildlifeunitschilika/wildlifeunitschilika.component';
import { WildlifeunitshirakudComponent } from './wildlifeunitshirakud/wildlifeunitshirakud.component';
import { WildlifeunitsKeonjharComponent } from './wildlifeunits-keonjhar/wildlifeunits-keonjhar.component';
import { WildlifeunitsMahanadiComponent } from './wildlifeunits-mahanadi/wildlifeunits-mahanadi.component';
import { WildlifeunitsNandankananComponent } from './wildlifeunits-nandankanan/wildlifeunits-nandankanan.component';
import { WildlifeunitsPuriComponent } from './wildlifeunits-puri/wildlifeunits-puri.component';
import { WildlifeunitsMangroveComponent } from './wildlifeunits-mangrove/wildlifeunits-mangrove.component';
import { WildlifeunitsSatkosiaComponent } from './wildlifeunits-satkosia/wildlifeunits-satkosia.component';
import { WildlifeunitsSimilipalTigerReserveComponent } from './wildlifeunits-similipal-tiger-reserve/wildlifeunits-similipal-tiger-reserve.component';
import { WildlifeunitsSimilipalNorthComponent } from './wildlifeunits-similipal-north/wildlifeunits-similipal-north.component';
import { WildlifeunitsSimilipalSouthComponent } from './wildlifeunits-similipal-south/wildlifeunits-similipal-south.component';
import { WildlifeunitsSunabedaWLComponent } from './wildlifeunits-sunabeda-wl/wildlifeunits-sunabeda-wl.component';
import { WildlifeunitsBaligudaFDComponent } from './wildlifeunits-baliguda-fd/wildlifeunits-baliguda-fd.component';
import { WildlifeunitsDhenkanalFDComponent } from './wildlifeunits-dhenkanal-fd/wildlifeunits-dhenkanal-fd.component';
import { WildlifeunitsKalahandiSouthFDComponent } from './wildlifeunits-kalahandi-south-fd/wildlifeunits-kalahandi-south-fd.component';
import { WildlifeunitsParlakhemundiFDComponent } from './wildlifeunits-parlakhemundi-fd/wildlifeunits-parlakhemundi-fd.component';


const routes: Routes = [
  {
    path:  'wildlifeunitsbalasore',
    component: WildlifeunitsbalasoreComponent
  },
  {
    path:  'Wildlifeunitsbamra',
    component: WildlifeunitsbamraComponent
  },
  {
    path:  'Wildlifeunitsbhadrak',
    component: WildlifeunitsbhadrakComponent
  },
  {
    path:  'Wildlifeunitschandaka',
    component: WildlifeunitschandakaComponent
  },
  {
    path:  'Wildlifeunitschilika',
    component: WildlifeunitschilikaComponent
  },
  {
    path:  'Wildlifeunitshirakud',
    component: WildlifeunitshirakudComponent
  },
  {
    path:  'WildlifeunitsKeonjhar',
    component: WildlifeunitsKeonjharComponent
  },
  {
    path:  'WildlifeunitsMahanadi',
    component: WildlifeunitsMahanadiComponent
  },
  {
    path:  'WildlifeunitsNandankanan',
    component: WildlifeunitsNandankananComponent
  },
  {
    path:  'WildlifeunitsPuri',
    component: WildlifeunitsPuriComponent
  },
  {
    path:  'WildlifeunitsMangrove',
    component: WildlifeunitsMangroveComponent
  },
  {
    path:  'WildlifeunitsSatkosia',
    component: WildlifeunitsSatkosiaComponent
  },
  {
    path:  'WildlifeunitsSimilipalTigerReserve',
    component: WildlifeunitsSimilipalTigerReserveComponent
  },
  {
    path:  'WildlifeunitsSimilipalNorth',
    component: WildlifeunitsSimilipalNorthComponent
  },
  {
    path:  'WildlifeunitsSimilipalSouth',
    component: WildlifeunitsSimilipalSouthComponent
  },
  {
    path:  'WildlifeunitsSunabedaWL',
    component: WildlifeunitsSunabedaWLComponent
  },
  {
    path:  'WildlifeunitsBaligudaFD',
    component: WildlifeunitsBaligudaFDComponent
  },
  {
    path:  'WildlifeunitsDhenkanalFD',
    component: WildlifeunitsDhenkanalFDComponent
  },
  {
    path:  'WildlifeunitsKalahandiSouthFD',
    component: WildlifeunitsKalahandiSouthFDComponent
  },
  {
    path:  'WildlifeunitsParlakhemundiFD',
    component: WildlifeunitsParlakhemundiFDComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WildlifeunitsRoutingModule { }
