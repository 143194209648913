import { Component, OnInit } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from '../service/api.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const baseUrlFile = environment.FILE_API_URL;
declare var jQuery: any;

declare var paginga: any;

@Component({
  selector: 'app-blogdetails',
  templateUrl: './blogdetails.component.html',
  styleUrls: ['./blogdetails.component.css']
})
export class BlogdetailsComponent implements OnInit {
  public loader: boolean=false;
  blogList: any[];
  constructor(private apiService: ApiService) { }

  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {};
  ngOnInit(): void {
    this.dtOptions = {
      responsive: true,
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: 'Bfrtip',
        buttons: [
            //'copy', 'excel', 'pdf', 'print'
        ]
    };
    this.getAllBlog();
  	// (function($){

  	// 	$(function() {
		// 	$(".paginate").paginga({
		// 		// use default options
		// 	});
		// });

    // })(jQuery)

  }
  getAllBlog() {
    this.loader=true;
    this.apiService.request('GET', 'api/v1/cms/getAllBlogData').subscribe((data: any) => {
      if (data) {
        this.blogList = new Array() ;
        data.sort(function(a, b) {
          return b.seqNo- a.seqNo;
        });
        data.sort((a, b) => {
          return <any>new Date(b.craetedOn) - <any>new Date(a.craetedOn);
        });
        var arrr=new Array();
        data.map(item=>{
          return {
            blogImgPath : baseUrlFile+'blog/'+item['blogImgPath'],
            blogNum:item['blogNum'],
            blogDesc:item['blogDesc'],
            pdfPath: baseUrlFile+'blog/'+item['pdfPath'],
            craetedBy:item['craetedBy'],
            createdByName:item['createdByName'],
            craetedOn:item['craetedOn'],
            title: item['title']
          }
        }).forEach(item=> this.blogList.push(item));

       $("#tableBlog").DataTable().destroy();
        this.dtTrigger.next();
        this.loader=false;

      }else{
        this.loader=false;
      }
    },err=>{
      this.loader = false;
    });
  }
  defaultUrl(event) {
    event.target.src='assets/images/no_img.png';
  }

}
