<app-header *ngIf="isDashboard"></app-header>
<app-menu  *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
			        <li class="nav-item">
			            <a id="tab-baleswarmap" href="#pane-baleswarmap" class="nav-link active" data-toggle="tab" role="tab">Map</a>
			        </li>
			        <li class="nav-item">
			            <a id="tab-baleswarinfo" href="#pane-baleswarinfo" class="nav-link" data-toggle="tab" role="tab">Information</a>
			        </li>
			    </ul>

			    <div id="accordion" class="tab-content" role="tablist">
			        <div id="pane-baleswarmap" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-baleswarmap">
			            <div class="card-header" role="tab" id="heading-baleswarmap">
			                <h5 class="mb-0">
			                    <a data-toggle="collapse" href="#collapse-baleswarmap" aria-expanded="true" aria-controls="collapse-baleswarmap">
			                        Map
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-baleswarmap" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-baleswarmap">
			                <div class="card-body">
								<div id="balesoreWLmap" class="map">
                                    <div class="gle-icon">
										<a href="javascript:void(0)">
                                            <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid" alt="Street View" title="Street View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()' alt="Satellite View" title="Satellite View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View" title="Topo View">
                                        </a>
                                    </div>
                                </div>
			                </div>
			            </div>
			        </div>

			        <div id="pane-baleswarinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-baleswarinfo">
			            <div class="card-header" role="tab" id="heading-baleswarinfo">
			                <h5 class="mb-0">
			                    <a class="collapsed" data-toggle="collapse" href="#collapse-baleswarinfo" aria-expanded="false" aria-controls="collapse-baleswarinfo">
									Information
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-baleswarinfo" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-baleswarinfo">
			                <div class="card-body">
			                	<h4 class="card-title">
			                		Balasore Wildlife Division
			                		<span class="custom-down">
                            <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
							<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
								class="fa fa-file-pdf">&nbsp;</i>Map</button>
			                		</span>
			                	</h4>
                        <div id="printcontent">
                                <div class="row">
                                	<div class="col-md-9">
                                		<div class="table-responsive">
		                                	<table class="table table-sm table-bordered">
		                                		<tbody>
		                                			<tr>
		                                				<td><strong>District : </strong></td>
		                                				<td>Balasore</td>
		                                			</tr>
                                                    <tr>
                                                        <td><strong>Geographical Extent : </strong></td>
                                                        <td>Longitude 86°21’19"E and 87°29’18"E & Latitude 21°C</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Climate : </strong></td>
                                                        <td>Maximum - 45° C (During April - May) Minimum - 18° C (During November - January)</td>
                                                    </tr>
		                                		</tbody>
		                                	</table>
		                                </div>
                                	</div>
                                	<div class="col-md-3">
                                		<img src="assets/images/wildlifeunits-balasore-01.jpg" class="img-fluid float-right img-inner" alt="image">
                                	</div>
                                </div>
                                <p>Balasore Wildlife Division has been created during re-organization of Forest Department w.e.f. 01.10.2003 as per Government of Odisha, Forest & Environment Department Resolution No. 1F (A) - 100/2003-13228/F&E and Notification No. 1F (A) 100/2003-13220/F&E both dated 08th August, 2003. After re-organization erstwhile Baripada Division is divided into 3 (three) Divisions, i.e. Baripada Division, Balasore Wildlife Division & Bhadrak Wildlife Division. The total geographical area of Balasore Wildlife Division extends over an area of 3806 sq. kilometer and boundary is co-terminus with that of geographical area of Balasore civil district. Balasore Wildlife Division is bounded by Baripada Division in the west, Bhadrak Wildlife Division on South, south- west, District Midnapur (West Bengal) on the North –East, Bay of Bengal on the South and South – East. There are five Ranges under Balasore Wildlife Division namely Chandipur, Jaleswar, Soro, Nilgiri and Kuldiha. Balasore Wildlife Division comprises of two revenue Sub-Divisions of Balasore District, i.e. Balasore & Nilgiri Sub-Division. Out of 3806 Sq. Km of total geographical area, 230.45 Sq. Km. comes under designated forest category (RF, PRF & VF). There are 9 Reserved Forest Block, 13 Proposed Reserved Forest and 50 Village Forest Blocks in the Division.</p>
                                <h4 class="card-title">Flora </h4>
                                <p>The forest of interior part of the district can be broadly classified into two categories, i.e. Dry deciduous Sal Forest and Moist deciduous Sal Forest with few patches of semi evergreen forests inside Kuldiha Wildlife Sanctuary. Casuarina  Mangrove forests are found in coastal areas of Jaleswar and Chandipur Wildlife Range. Casuarina species are suitable for sandy &amp; sandy clay soil of coastal line of the district. Muddy soil supports well growth of Mangrove species. Some natural Mangrove forests are also found in the above two Ranges. About 565 Hectare of blank area of Mangrove forests have been planted under the JICA assisted O.F.S.D.P project for 2005-06 to 2014-15 and 100 ha. planted under MGNREGS during 2014-15. The important constituent species of deciduous forest are Mangifera indica, Phoebe lanceolata, Saraca asoca, Polyalthia cerasoids, Litsea species, Bridelia retusa, Dillenia pentagyna, Gardenia &amp; Vitex species with Sal trees. The epiphytic flora is rich, comprising several species of ferns, mosses, lichens and orchids.</p>
                                <h4 class="card-title">Fauna </h4>
                                <p>Balasore Wildlife Division is rich in both terrestrial and marine wildlife. 32 species of mammals, 149 species of birds, 40 species of reptiles, 5 species of amphibians with many species of insects and microfauna are noticed. The mammals are represented by Elephant (Elephas maximus), Gaur or Indian bison (Bos gaurus), Cheetal (Axis axis), Sambar (Cervus unicolor), Common langur (Semnopithecus entellus), Rhesus macaque (Macaca mulata), Porcupine (Hystrix indica), Indian giant squirrel (Ratufa indica), Indian pangolin (Manis crassicaudata) etc. The carnivores are represented by Leopard (Panthera pardus), Leopard cat (Felis bengalensis) etc. Some common birds are Peafowl (Pavo cristatus), Shahin Falcon (Falco perigrinus perigrinator), Malabar pied hornbill (Anthracoceros coronatus), Owls, Hill myna (Gracula religiosa), Oriole black headed (Oriolus xantnomus), Red jungle fowl (Gallus gallus). Besides them, water birds like White Ibis (Threskiornis melanocephalus), Red wattled lapwig (Vanellus indicus), Teal (Nettapus coromandelianus), Herons, Egrets and King fishers e.g. Indian small blue king fisher (Alcedo atthis), Indian pied kingfisher (Ceryle rudis), White breasted kingfisher (Halcyon smyrnensis) are seen.</p>
                                <h4 class="card-title">Set-Ups</h4>
                                <div class="table-responsive">
                                	<table class="table table-sm table-bordered">
                                		<tbody>
                                            <tr>
                                				<td><strong>Administrative set-up (Range, Section, beats) : </strong></td>
                                				<td>Ranges- 5, Sections- 18,Beats- 52</td>
                                			</tr>
                                			<tr>
                                				<td><strong>EDC/JFM set-up : </strong></td>
                                				<td>EDCs - 13, VSS - 136</td>
                                			</tr>
                                			<tr>
                                                <td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
                                                <td>No. of RF- 9, No. of PRF- 13, No. of DPF- 0, No. of UDPF- 0, No. of VF- 50</td>
                                            </tr>
                                		</tbody>
                                	</table>
                                </div>
                                <h4 class="card-title">Major Attraction</h4>
                                <div class="row">
                                    <div class="col-md-12">
                                        <img src="assets/images/wildlifeunits-balasore-02.jpg" class="img-fluid float-right img-inner" alt="image">
                                        <ul class="custom-list">
                                        	<li>The golden sea beach at Chandipur.</li>
                                        	<li>Dagara sea beach of Baliapal,block.</li>
                                            <li>Talsari sea beach of Bhograi,block.</li>
                                            <li>Bichitrapur eco tourism at Khadibil, Bhograi,block.</li>
                                            <li>Bhusandeswar Mahadev and Chandaneswar temple of Bhograi Block.</li>
                                            <li>Khirachora Gopinath Temple at Remuna.</li>
                                            <li> Panchalingeswar Mahadev at Panchalingeswar, Nilgiri,block.</li>
                                            <li>Eco-tourism at Gohirabhol inside Kuldiha Wildlife Sanctuary.  </li>
                                            <li>Sunya Mandap at Kupari. </li>
                                        </ul>
                                    </div>
                                </div>
                                <h4 class="card-title">Salient Detail</h4>
                                <div class="table-responsive">
                                	<table class="table table-sm table-bordered">
                                		<tbody>
                                            <tr>
                                				<td width="15%"><strong>HQ Address :</strong></td>
                                				<td width="85%">Divisional Forest Office, Balasore Wildlife Division At / P.O.- Kuruda, Balasore</td>
                                			</tr>
                                			<tr>
                                				<td><strong>Contact officer : </strong></td>
                                				<td>
                                					<span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest Officer<br>
                                					<span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Balasore Wildlife Division, Balasore<br>
                                					<span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06782 - 256142<br>
                                					<span class="address-inner"><i class="fa fa-fax">&nbsp;</i></span>06782<br>
                                				</td>
                                			</tr>
                                			<tr>
                                				<td><strong>Key activities : </strong></td>
                                				<td>Protection of Forest and Wildlife with the help of Anti-poaching squad, Anti-smuggling squad, Forest protection squad, Fire protection squad., Involvement of VSS and EDC in Forest management in JFM mode., Anti depredation measures with the help of Anti-depredation squad., Ecotourism activities involving local EDC for Environment Conservation Education, Afforestation on forest land, government land and road side, Kuldiha Sanctuary management by improving habitat condition, etc.</td>
                                			</tr>
                                			<tr>
                                				<td><strong>Programmes : </strong></td>
                                				<td>Integrated Development of Wildlife habitat., Ama Jangal Yojana., Protection of Olive Ridley Sea Turtle., Soil & Moisture Conservation., Fire protection., CAMPA, Elephant Corridor Development., etc.</td>
                                            </tr>
                                            <tr>
                                				<td><strong>Places to see : </strong></td>
                                				<td>Kuldiha Sanctuary, Rissia Dam, Panchalingeswar, Chandipur Beach, etc.</td>
                                			</tr>
                                		</tbody>
                                	</table>
                                </div>
                                <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>
                              </div>
			                </div>
			            </div>
			        </div>

			    </div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>
