<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<app-masterheader></app-masterheader>

<div class="wrapper">

	<app-sidebar></app-sidebar>

	<div id="content">

		<!-- <app-breadcrumb></app-breadcrumb> -->

		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12">

					<div class="row">
						<div class="col-md-12">
							<div class="card">
								<div class="card-body">
									<h4 class="card-title">Annual Plan of Operation</h4>
                                    <form [formGroup]="apoForm" (ngSubmit)="onSubmitform()">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="row form-group">
                                                    <div class="col-md-6">
                                                        <label class="mt-1">Name of the Scheme :</label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select class="form-control form-control-sm"  formControlName="scheme" (change)="getAllComponentDDByScheme(schemSelected.value)" #schemSelected >
                                                        <option value="0">All Scheme</option>
                                                        <option    *ngFor="let scheme of schemeList "   value={{scheme.id}} >
                                                            {{scheme.name}}
                                                        </option>
                                                    </select>
                                                    </div>
                                                </div>
                                                  <div class="row form-group">
                                                    <div class="col-md-6">
                                                        <label class="mt-1">Name of the Component :</label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select class="form-control form-control-sm" formControlName="component"  >
                                                        <option value="0">All Component</option>
                                                        <option    *ngFor="let scheme of componentList "   value={{scheme.id}} >
                                                            {{scheme.name}}
                                                        </option>
                                                    </select>
                                                    </div>
                                                </div>
                                                  
                                            </div>
                                           
                                        </div>
                                        <div class="table-responsive" >
                                            <!-- <table class="table table-sm table-bordered">
                                                <thead class="text-center">
                                                    <tr>
                                                        <th colspan="14">{{formTitle}} <pre>{{formDesc}}</pre></th>

                                                    </tr>
                                                    <tr>
                                                        <th rowspan="3">Sl.No.</th> 
                                                       
                                                         <th rowspan="3">Item of works </th>
                                                        <th rowspan="3">Para No. of the Management Plan which specializes this item</th>
                                                        <th rowspan="3">Quantity</th>
                                                        <th rowspan="3">Unit Cost</th>
                                                        <th rowspan="3">Amount required</th>
                                                        <th colspan="7">Location with GPS Co-ordinates</th> 
                                                        <th rowspan="3">Site specific Justification</th> 
                                                      
                                                    </tr>
                                                     <tr>
                                                        <th rowspan="2">Location</th>
                                                        <th rowspan="2">DD</th>
                                                        <th rowspan="2">MM</th>
                                                        <th rowspan="2">SS</th>
                                                         <th rowspan="2">DD</th>
                                                        <th rowspan="2">MM</th>
                                                        <th rowspan="2">SS</th>
                                                    </tr>
                                                </thead>
                                             
                                                  <tbody>
                                                    <tr *ngFor="let row of tableData; trackBy: trackByIndex">
                                                       
                                                        <td>
                                                            <strong *ngIf="row.isItem">{{ row.serial }}</strong>
                                                            <em *ngIf="row.isTask">{{ row.serial }}</em>
                                                            <span *ngIf="row.isSubTask">{{ row.serial }}</span>
                                                        </td>

                                                        <td>
                                                          <strong *ngIf="row.isItem" [ngClass]="{'bold-text': row.isItem}" >{{ row.itemName }}</strong>
                                                          <span *ngIf="row.isTask" [ngClass]="{'italic-text': row.isTask}">{{ row.itemName }}</span>
                                                          <em *ngIf="row.isSubTask">{{ row.itemName }}</em>
                                                        </td>
                                                        <td *ngFor="let column of dynamicColumns; trackBy: trackByIndex">
                                                          <input *ngIf="!row.isItem && !row.isTask"  type="text" [(ngModel)]="row[column]">
                                                        </td>
                                                      </tr>
                                                  </tbody>

                                            </table> -->
                                        </div>
                                        <div class="text-center">
                                            <button type="submit" class="btn btn-sm btn-success">Submit</button>
                                        </div>
                                    </form>
                                     
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>

	<div id="footer">
		<app-masterfooter></app-masterfooter>
	</div>

</div>
