<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Privacy Policy</title>
</head>
<body>
  <header>
    <div class="container">
      <h1>Privacy Policy</h1>
    </div>
  </header>

  <main>
    <div class="container">
      <section class="privacy-policy">
        <h2>Introduction</h2>
        <p>This Privacy Policy outlines how Wildlife Odisha Application designed and developed by SPARC ("we", "us", or "our") collects, uses, discloses, and protects the personal information of users ("you" or "your") who visit our website https://wildlife.odisha.gov.in/(the "Site") and utilize our services (the "Services"). We are committed to safeguarding your privacy and ensuring that your personal information is handled responsibly and in compliance with applicable data protection laws, including the General Data Protection Regulation(GDPR).</p>

        <h2>Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul>
          <li><strong>Personal Information:</strong> This includes your name, email address, phone number, date of birth, and any other information you provide during registration or while using our Services.</li>
          <li><strong>Usage Data:</strong> We collect data about your interactions with our Site, such as access times, browser types, IP addresses, pages viewed, and the duration of your visit</li>
          <li><strong>Location Information:</strong> When you use our Services that require geolocation, we may collect information about your precise location through GPS, Wi-Fi, or other location-based technologies.</li>
          <li><strong>Camera and Photo Access:</strong> If you grant us permission, we may access your device’s camera and photo gallery to facilitate certain features of our Services, such as uploading images.</li>
      
        </ul>

        <h2>How We Use Your Information</h2>
        <p>Your information is used for the following purposes:</p>
        <ul>
          <li>To provide and improve our services.</li>
          <li>To communicate with you about updates and promotions.</li>
          <li>To analyze website usage and improve user experience.</li>
        </ul>

        <h2>How We Protect Your Information</h2>
        <p>We implement industry-standard security measures to protect your personal information, including encryption and secure data storage.</p>

        <h2>Your Rights</h2>
        <p>You have the right to access, correct, or delete your personal information. If you wish to exercise any of these rights, please contact us.</p>

        <h2>Changes to This Policy</h2>
        <p>We may update this privacy policy from time to time. Any changes will be posted on this page with an updated date.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this privacy policy, please contact us at:</p>
        <p>Email: support&#64;sparcindia.com</p>
      </section>
    </div>
  </main>

  <footer>
    <div class="container">
      <p>&copy; {{ today.getFullYear() }} SPARC. All rights reserved.</p>
    </div>
  </footer>
</body>
</html>
