
<div class="container-fluid">
	<div class="row">
		<div class="col-md-12">
			<div class="breadcrumb">
				<div class="row">
					<div class="col-md-6 text-left">
						<h4>{{title}}</h4>
					</div>
					<div class="col-md-6 text-right">
            <ol class="breadcrumb" *ngIf='isDashboard'>
							<li class="breadcrumb-item"><a [routerLink]="['/dashboarduser']">User Dashboard</a></li>
						</ol>
						<ol class="breadcrumb" *ngIf='!isUserManage && !isDashboard'>
							<li class="breadcrumb-item"><a [routerLink]="['/dashboarduser']">Dashboard</a></li>
							<li class="breadcrumb-item active">{{title}}</li>
            </ol>
            <ol class="breadcrumb" *ngIf='isUserManage && !isDashboard'>
              <li class="breadcrumb-item"><a [routerLink]="['/dashboarduser']">Dashboard</a></li>
              <li class="breadcrumb-item"><a >User Management</a></li>
							<li class="breadcrumb-item active">{{title}}</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
