import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "../service/api.service";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { AuthService } from "../service/auth.service";
import { SidebarComponent } from '../sidebar/sidebar.component';

declare var jQuery: any;

@Component({
  selector: 'app-addrolemanagement',
  templateUrl: './addrolemanagement.component.html',
  styleUrls: ['./addrolemanagement.component.css']
})
export class AddrolemanagementComponent implements OnInit {
  role: any;
  plinkList: any;
  permissions: Array<any> = [];
  setPermissionform: FormGroup;
  setpermissionarray: Array<any> = [];
  rpermissioncheck: Array<any> = [];
  constructor(private _formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private authService: AuthService) { }
  @ViewChild(SidebarComponent) _sidebarEleRef: SidebarComponent;
  ngAfterViewInit() {
    this._sidebarEleRef.contains='2';
  }

  ngOnInit(): void {
    this.getAllRole();

    //this.IsPlinkavail=true;
    (function ($) {

    })(jQuery);
    this.setPermissionform = this._formBuilder.group({
      //role: [},
      role: ['', Validators.required],
      permissions: ['']
    });
  }
  getAllRole() {
    return this.apiService.request('GET', 'api/v1/link/getAllRole').subscribe((data: any) => {
      if (data) {
        this.role = data;
      }
    });

  }

  getAllPLink(roleId: string) {
    //alert(roleId)
    return this.apiService.request('GET', 'api/v1/link/getAllGlink').subscribe((data: any) => {
      if (data) {
        this.plinkList = data;
        var apilink = 'api/v1/link/getAllLinkByRoleID?roleId='+roleId;
        this.apiService.request('GET',apilink).subscribe(
          returndata=> {
            var rdata = returndata["link"];
           for (let index = 0; index < rdata.length; index++) {
             this.rpermissioncheck.push(rdata[index].id);
             this.setpermissionarray.push(rdata[index].id);
           }
          }
        );
      }
    });

  }
  pushthevalue(event: any)
  {
    if (event.target.checked)
    {
      this.setpermissionarray.push(event.target.value);
    }
    else
    {
      let ix = this.setpermissionarray.findIndex((d) => d == event.target.value);
      this.setpermissionarray.splice(ix, 1);
    }
  }
  setPermission() {
   // console.log(this.setPermissionform);
    if (!this.setPermissionform.invalid) {
      this.apiService.request('POST','api/v1/link/setLinkToRole',{
        roleID: this.setPermissionform.controls.role.value,
        pLinkId : this.setpermissionarray
      } ).subscribe((response : any) =>{
          alert("Link Added successfully to Role.");
          window.location.reload();
      });


      // }

      // });
    }
  }
}
