import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import {FormBuilder, FormGroup} from "@angular/forms";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ApiService } from '../service/api.service';
import { Subject } from 'rxjs';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { Report } from '../model/report.model';
import { environment } from 'src/environments/environment.prod';
import { NotificationService } from '../service/notification.service'



import Map from 'ol/Map'
import * as layer from 'ol/layer';
import { Tile as Tile, Vector as VectorLayer } from 'ol/layer';
import { OSM as OSM, Vector as VectorSource, Vector as MeasureSource } from 'ol/source';
import XYZ from 'ol/source/XYZ';
import BingMaps from 'ol/source/BingMaps'
import ImageWMS from 'ol/source/ImageWMS';
import { View } from 'ol';
import { WFS, GeoJSON } from 'ol/format';
import * as control from 'ol/control';
import * as proj from 'ol/proj';
import 'ol/ol.css';
import Feature from 'ol/Feature';
import { Icon, Style } from 'ol/style';
import Point from 'ol/geom/Point';
import {Fill, Stroke,Text } from 'ol/style';
import { style } from '@angular/animations';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var jQuery: any;

const baseUrl = environment.API_URL;
const baseUrlFile = environment.FILE_API_URL;
const wms_url = environment.GEOSERVER_WMS_URL;

@Component({
  selector: 'app-elephantmovementrecord',
  templateUrl: './elephantmovementrecord.component.html',
  styleUrls: ['./elephantmovementrecord.component.css']
})
export class ElephantmovementrecordComponent implements OnInit {
  public loader: boolean=false;
  public loader2: boolean=false;
  public isAdmin: boolean=false;
  public circleDisabled:boolean=false;
  public divisionDisabled:boolean=false;
  public rangeDisabled:boolean=false;

  public circleSelected='All';
  public divisionSelected='All';
  public rangeSelected='All';
  public sectionSelected='All';
  public beatSelected='All';

  public circleSelected_In='All';
  public divisionSelected_In='All';
  public rangeSelected_In='All';
  public sectionSelected_In='All';
  public beatSelected_In='All';

  public circleSelected_nill='All';
  public divisionSelected_nill='All';
  public rangeSelected_nill='All';
  public sectionSelected_nill='All';
  public beatSelected_nill='All';

  public circleid: string;
  public divid: string;
  public rangeid: string;
  public sectionid: string;
  public beatid: string;

  public circleid_in: string;
  public divid_in: string;
  public rangeid_in: string;
  public sectionid_in: string;
  public beatid_in: string;

  public circleid_nill: string;
  public divid_nill: string;
  public rangeid_nill: string;
  public sectionid_nill: string;
  public beatid_nill: string;



  tabledata1: any[];
  tabledata2: any[];
  tabledata3: any[];
  reportlist: Array<object> = [];
  nillForm:FormGroup;
  indirectForm: FormGroup;
  directForm : FormGroup;

  public startDateSelected="";
  public endDateSelected="";

  public startDateSelected_In="";
  public endDateSelected_In="";

  public startDateSelected_nill="";
  public endDateSelected_nill="";

  loginid: string;

  sightingDate: string;
  sightingTimefrom: string;
  sightingTimeTo: string;
  location: string;
  divisionName: string;
  rangeName: string;
  secName: string;
  beatName: string;
  heard: string;
  total: string;
  tusker: string;
  mukhna: string;
  female: string;
  calf: string;
  latitude: string;
  longitude: string;
  reportType: string;
  imgAcqlocation:string;
  selfieImglocation: string;
  trumptingApproxDistance: string;
  trumptingDirection: string;
  tuskerAdult: string;
  tuskerSubAdult: string;
  remarks: string;
  indirectReportType: string;
  updatedBy: string;
  duplicateReport: string;

  imegary: layer.Image;
	streetmap: layer.Image;
  osmMap: layer.Image;
  _raster:layer.Image;
  _carto:layer.Image;
  mapdirect: Map;
  mapindirect : Map ;
  mapproblem:Map;
	modify: any;

  @ViewChild('sd') sdate : ElementRef;
  @ViewChild('ed') edate : ElementRef;
  @ViewChild('sd_in') sdate_in : ElementRef;
  @ViewChild('ed_in') edate_in : ElementRef;
  @ViewChild('sd_nill') sdate_nill : ElementRef;
  @ViewChild('ed_nill') edate_nill : ElementRef;
  @ViewChild('sd7') from_Date_ElementRef : ElementRef;
  @ViewChild('from_time') from_time_ElementRef : ElementRef;
  @ViewChild('to_time') to_time_ElementRef : ElementRef;
  @ViewChild(SidebarComponent) _sidebarEleRef: SidebarComponent;

  dtTrigger: Subject<any> = new Subject<any>();
  dtTrigger2: Subject<any> = new Subject<any>();
  dtTrigger3: Subject<any> = new Subject<any>();
  dtOptions: any = {};
  circleList: any;
  divisionlist: any;
  rangelist: any;
  sectionlist: any;
  beatlist: any;
  roleId: any;
  juridictionId: any;
  circleId: "";
  divisionId: "";
  rangeId: "";
  sectionId: "";
  beatId: "";
  divisionidtoextend: any;
  indirectmapView: any;
  directmapView: any;
  latlongarray_d: any;
  elephantpoitnsglobal_directMapview : Array<any> = new Array();
  latlongarray: any[];
  latlongarray2_d: any[];
  elephantpoitnsglobal_indirectMapview: Array<any> = new Array();
  latlongarray2: any[];
 
  elephantpointindirect_featureOverlayNew: any;
  elephantpointProblematic_featureOverlayNew: any;
  elephantpointdirect_featureOverlayNew: any;
  deleteSelectedRecord: any;
  loginuserid: any;
  deleteReportType: any;
  reporteditForm: FormGroup;
  reportIdEdit: any;
  reportTypeEdit: any;
  problemetic: boolean;
  inchargeOfficer: any;
  uniqueId: any;
  problemmapView: any;
  elephantpoitnsglobal_problemMapview: any;
  latlongarray3_d: any;
  latlongarray3: any;

  constructor( private formBuilder: FormBuilder,private notifyService: NotificationService,private router: Router, private apiService: ApiService) { }

  ngOnInit(): void {
    this.circleid="0";
    this.divid="0";
    this.rangeid="0";
    this.sectionid="0";
    this.beatid="0";

    this.circleid_in="0";
    this.divid_in="0";
    this.rangeid_in="0";
    this.sectionid_in="0";
    this.beatid_in="0";

    this.circleid_nill="0";
    this.divid_nill="0";
    this.rangeid_nill="0";
    this.sectionid_nill="0";
    this.beatid_nill="0";

   this.startDateSelected="";
   this.endDateSelected="";



   this.loginid='';
   this.directForm = this.formBuilder.group({
    circle:[''],
    division:[''],
    range:[''],
    section:[''],
    beat:[''],
    startDate:[''],
    endDate:['']
  });
  this.indirectForm= this.formBuilder.group({
    In_circle:[''],
    In_division:[''],
    In_range:[''],
    In_section:[''],
    In_beat:[''],
    In_startDate:[''],
    In_endDate:['']
  });
  this.nillForm= this.formBuilder.group({
    Nill_circle:[''],
    Nill_division:[''],
    Nill_range:[''],
    Nill_section:[''],
    Nill_beat:[''],
    Nill_startDate:[''],
    Nill_endDate:['']
  });
  this.reporteditForm= this.formBuilder.group({
    location:[''],
    elephant:[''],
    herd:[''],
    calf:[''],
    female:[''],
    tusker:[''],
    tuskerAdult:[''],
    tuskerSubAdult:[''],
    mukhna:[''],
    remarks:['']
   
  });
    this.dtOptions = {
      // pagingType: 'full_numbers',
      // pageLength: 5,
      // lengthMenu : [5, 10, 25],
      // processing: true,
      // responsive: true,
      dom: 'Bfrtip',
         buttons: [
             'copy', 'excel', 'pdf', 'print'
         ]
    };
    var ds = this ;
  	(function($){
        var url = document.location.toString();
        if (url.match('#')) {
            $('.nav-item a[href="#' + url.split('#')[1] + '"]').tab('show');
        }
        //Change hash for page-reload
        $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').on('shown', function (e) {
            window.location.hash = e.target.hash;
        });
        if (url.match('#pane-B')) {
          ds.loadtable(0);
        }
        if (url.match('#pane-C')) {
            ds.loadtable(1);
        }
        if (url.match('#pane-D')) {
          ds.loadtable(2);
      }

		$('a[data-toggle="tab"]').on('shown.bs.tab', function(e){
		    $($.fn.dataTable.tables(true)).DataTable()
        .columns.adjust()
        .responsive.recalc();
		});
    $('#mapElephantMovementDirectRecordModal').on('shown.bs.modal', function () {
      if(ds.mapdirect == null)
      {
        ds.inhisiatemapdirect();
        ds.letzoomin(ds.thelatitude, ds.thelongitude,"direct",ds.divisionidtoextend)
        // document.getElementById("directbtn").click();
         //$("#directbtn").trigger("click");
      }
      else
      {
        //alert("you can move on")
        ds.letzoomin(ds.thelatitude, ds.thelongitude,"direct",ds.divisionidtoextend)
      }

   });
   $('#mapElephantMovementinDirectRecordModal').on('shown.bs.modal', function () {
    if(ds.mapindirect == null)
    {
      ds.inhisiatemapindirect();
      ds.letzoomin(ds.thelatitude, ds.thelongitude,"indirect",ds.divisionidtoextend)
    }
    else
    {
      ds.letzoomin(ds.thelatitude, ds.thelongitude,"indirect",ds.divisionidtoextend)
    }
   });
   $('#mapElephantMovementinDirectRecordModal').on('shown.bs.modal', function () {
    if(ds.mapproblem == null)
    {
      ds.inhisiatemapproblemetic();
      ds.letzoomin(ds.thelatitude, ds.thelongitude,"problem",ds.divisionidtoextend)
    }
    else
    {
      ds.letzoomin(ds.thelatitude, ds.thelongitude,"problem",ds.divisionidtoextend)
    }
   });
   

//    $('#mapElephantMovementIndirectRecordModal').on('shown.bs.modal', function () {
//     ds.inhisiatemap2();
//  });
$('#viewDirectMapViewModal').on('shown.bs.modal', function () {
  if(ds.directmapView == null)
  {
    setTimeout(() => {
      ds.inhisiatemapViewdirect();
     }, 100);
  }
  else
  {
    ds.populatePoint("direct");
  }
});
$('#viewInDirectMapViewModal').on('shown.bs.modal', function () {
  if(ds.indirectmapView == null)
  {
    ds.inhisiatemapViewindirect();

  }
  else
  {
    ds.populatePoint("indirect");
  }
});
$('#viewProblematicMapViewModal').on('shown.bs.modal', function () {
  if(ds.problemmapView == null)
  {
    ds.inhisiatemapViewProblem();

  }
  else
  {
    ds.populatePoint("problem");
  }
});
var day1before = new Date();
		$(function () {
		  $("#datepicker2, #datepicker4,#datepicker6").datepicker({
		        autoclose: true,
		        todayHighlight: true
      }).datepicker('update', new Date());
      $("#datepicker , #datepicker3, #datepicker5").datepicker({
        autoclose: true,
        todayHighlight: true
  }).datepicker('update', new Date(day1before.setDate(day1before.getDate() - 1)));
  $('.clockpicker').clockpicker();
    });
    })(jQuery);
    //this.loader=true;//set loader for visible
    const userdata=sessionStorage.getItem('user');
    if(userdata!=null){
      const user=JSON.parse(userdata);

      this.roleId=user.roleId;
      if(user.roleId==1){
        this.isAdmin=true;
      }
      this.loginuserid=user.userid;
      this.juridictionId=user.juridictionId;
      this.circleId=user.circleId;
      this.divisionId=user.divisionId;
      this.rangeId=user.rangeId;
      this.sectionId=user.sectionId;
      this.beatId=user.beatId;

      this.getAllCircle();

      if( this.roleId==1 || this.roleId==3)
      {
        this.getDirectMovementRecord();
      }else{
        this.isCircleDivisionRangeDisabled();
      }

      // this.inhisiatemap();
      // this.inhisiatemap2();
    }else{
      this.router.navigate(['']);
      this.loader=false;
      return;
    }
  }
  getAllCircle() {
     this.apiService.request('GET', 'api/v1/masters/getAllCircle').subscribe((data: any) => {
	    if(data)
	    {
        this.circleList=data;
        if(this.circleId!=""){
          this.circleid=this.circleId;
          this.circleid_in=this.circleId;
          this.circleid_nill=this.circleId;
          this.circleSelected=this.circleId;
          this.circleSelected_In=this.circleId;
          this.circleSelected_nill=this.circleId;
          this.getAllDivisionByCircleId(this.circleId);
        }

        //this.isCircleDivisionRangeDisabled();
        if(this.divisionlist!=""){
          this.divisionlist=[];
          this.rangelist=[];
          this.sectionlist=[];
          this.beatlist=[];
          this.divid="0";
          this.divid_in="0";
          this.divid_nill="0";
        }

      }
    });
  }
  getAllDivisionByCircleId(circle:any){
    if(circle!=0){
      this.apiService.request('GET', 'api/v1/masters/getAllDivisionByCircleId?id='+circle).subscribe((data: any) => {
	    if(data)
	    {
        this.divisionlist=data;
        if(this.divisionId!=""){
          this.divid=this.divisionId;
          this.divid_in=this.divisionId;
          this.divid_nill=this.divisionId;
          this.getAllRangeByDivId(this.divisionId);
        }
         if(this.rangelist!=""){
          this.rangelist=[];
          this.sectionlist=[];
          this.beatlist=[];
           this.rangeid="0";
           this.rangeid_in="0";
           this.rangeid_nill="0";
         }
      }
    });
  }else{
         if(this.divisionlist!=""){
      this.divisionlist=[];
      this.rangelist=[];
      this.sectionlist=[];
      this.beatlist=[];
          }
          this.circleSelected="All";
          this.circleSelected_In="All";
          this.circleSelected_nill="All";
          this.divid="0";
          this.divid_in="0";
          this.divid_nill="0";
     }
  }
  getAllRangeByDivId(div:any) {
    if(div!=0){
   this.apiService.request('GET', 'api/v1/masters/getAllRangeByDivid?id='+div).subscribe((data: any) => {
	    if(data)
	    {
        this.rangelist=data;
        if(this.divisionId!=""){
          this.directForm.get('division').disable();
          this.indirectForm.get('In_division').disable();
          this.nillForm.get('Nill_division').disable();
          this.divisionDisabled=true;
          this.divid=this.divisionId;
          this.divid_in=this.divisionId;
          this.divid_nill=this.divisionId;
          this.divisionSelected=this.divisionId;
          this.divisionSelected_In=this.divisionId;
          this.divisionSelected_nill=this.divisionId;
        }
        if(this.rangeId!=""){
          this.rangeid=this.rangeId;
          this.rangeid_in=this.rangeId;
          this.rangeid_nill=this.rangeId;
          this.getAllSectionByRangeId(this.rangeId);
        }
        if(this.sectionlist!=""){
          this.sectionid="0";
          this.sectionid_in="0";
          this.sectionid_nill="0";
          this.sectionlist=[];
          this.beatlist=[];
        }
      }
    });
  }else{
    if(this.rangelist!=""){
      this.rangelist=[];
      this.sectionlist=[];
      this.beatlist=[];
     }
     this.rangeid="0";
     this.rangeid_in="0";
     this.rangeid_nill="0";
    }
  }
  getAllSectionByRangeId(range:any) {
    if(range!=0){
    this.apiService.request('GET', 'api/v1/masters/getAllSectionByRangeid?id='+range).subscribe((data: any) => {
	    if(data)
	    {
        this.sectionlist=data;
        this.sectionid="0";
        this.sectionid_in="0";
        this.sectionid_nill="0";
        if(this.rangeId!=""){
            this.rangeid=this.rangeId;
            this.rangeid_in=this.rangeId;
            this.rangeid_nill=this.rangeId;
            this.rangeSelected=this.rangeId;
            this.rangeSelected_In=this.rangeId;
            this.rangeSelected_nill=this.rangeId;
            this.rangeDisabled=true;
            this.directForm.get('range').disable();
            this.indirectForm.get('In_range').disable();
            this.nillForm.get('Nill_range').disable();
         }
        if(this.beatlist!=""){
          this.beatlist=[];
          this.beatid="0";
          this.beatid_in="0";
          this.beatid_nill="0";
        }
      }
    });
  }else{
    if(this.sectionlist!=""){
      this.sectionlist=[];
      this.beatlist=[];
     }
          this.sectionid="0";
          this.sectionid_in="0";
          this.sectionid_nill="0";
    }
  }
  getAllBeatBySectionId(section:any) {
    if(section!=0){
    this.apiService.request('GET', 'api/v1/masters/getAllBeatBySecId?id='+section).subscribe((data: any) => {
	    if(data)
	    {
        this.beatlist=data;
        this.beatid="0";
        this.beatid_in="0";
        this.beatid_nill="0";
      }
    }); }else{
      if(this.beatlist!="")
        this.beatlist=[];
      }
  }
  isCircleDivisionRangeDisabled(){
   if(this.roleId==6){
      this.circleDisabled=true;
      this.directForm.get('circle').disable();
      this.indirectForm.get('In_circle').disable();
      this.nillForm.get('Nill_circle').disable();
      this.getDirectMovementRecord();
    }
    else if(this.roleId==2)
    {
      this.directForm.get('circle').disable();
      this.indirectForm.get('In_circle').disable();
      this.nillForm.get('Nill_circle').disable();
      this.circleDisabled=true;
      this.getDirectMovementRecord();
    }
    else if(this.roleId==4 || this.roleId==5 )
    {
      this.directForm.get('circle').disable();
      this.indirectForm.get('In_circle').disable();
      this.nillForm.get('Nill_circle').disable();
      this.circleDisabled=true;
      this.circleid=this.circleId;
      this.circleid_in=this.circleId;
      this.circleid_nill=this.circleId;
      this.circleSelected=this.circleId;
      this.circleSelected_In=this.circleId;
      this.circleSelected_nill=this.circleId;
      this.getAllDivisionByCircleId(this.circleId);
      this.getDirectMovementRecord();
    }
  }
  getCurentDate(){
    var Yestrerdaywithtime;
		var Todaywithtime;
    var mydate = new Date();
		var yester = new Date();
    yester = new Date(yester.setDate(yester.getDate() - 1));
    if (mydate.getMonth() < 9){
      Todaywithtime = mydate.getFullYear() + "-0" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
    }else{
      Todaywithtime = mydate.getFullYear() + "-" + (mydate.getMonth() + 1) + "-" + mydate.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
    }if(yester.getMonth() < 9){
      Yestrerdaywithtime = yester.getFullYear() + "-0" + (yester.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
    }else{
      Yestrerdaywithtime = yester.getFullYear() + "-" + (yester.getMonth() + 1) + "-" + yester.getDate() + " " + mydate.getHours() + ":" + mydate.getMinutes() + ":" + mydate.getSeconds() + "." + mydate.getMilliseconds();
    }
   if(this.startDateSelected==""){this.startDateSelected=Yestrerdaywithtime;}
   if(this.endDateSelected==""){this.endDateSelected=Todaywithtime;}
   if(this.startDateSelected_In==""){this.startDateSelected_In=Yestrerdaywithtime;}
   if(this.endDateSelected_In==""){this.endDateSelected_In=Todaywithtime;}
   if(this.startDateSelected_nill==""){this.startDateSelected_nill=Yestrerdaywithtime;}
   if(this.endDateSelected_nill==""){this.endDateSelected_nill=Todaywithtime;}
  }
  getDirectMovementRecord(){
    try{

      if(this.circleId.toString()!="0" ){this.circleSelected=this.circleId.toString();}
      if(this.divisionId.toString()!="0"){this.divisionSelected=this.divisionId.toString();}
      if(this.rangeId.toString()!="0"){this.rangeSelected=this.rangeId.toString();}

    this.loader=true;
    this.getCurentDate();
    return this.apiService.request('GET', 'api/v1/reports/viewReport?circle='+this.circleSelected+'&division='+this.divisionSelected+'&range='+this.rangeSelected+'&section='+this.sectionSelected+'&beat='+this.beatSelected+'&startDate='+this.startDateSelected+'&endDate='+this.endDateSelected+'&reportType=direct').subscribe((data: any) => {
    if(data){
      data.sort((a, b) => {
        return <any>new Date(b.sightingTimeTo) - <any>new Date(a.sightingTimeTo);
      });
      $("#direct").DataTable().destroy();
        this.tabledata1=data;
        this.dtTrigger.next();
        this.loader=false;
      }
    });
  }catch{
    this.loader=false;
  }
  }
  getIndirectMovementRecord(){
    try{
      if(this.circleId.toString()!="0" ){this.circleSelected_In=this.circleId.toString();}
      if(this.divisionId.toString()!="0"){this.divisionSelected_In=this.divisionId.toString();}
      if(this.rangeId.toString()!="0"){this.rangeSelected_In=this.rangeId.toString();}
    this.loader=true;
    this.getCurentDate();
    return this.apiService.request('GET', 'api/v1/reports/viewReport?circle='+this.circleSelected_In+'&division='+this.divisionSelected_In+'&range='+this.rangeSelected_In+'&section='+this.sectionSelected_In+'&beat='+this.beatSelected_In+'&startDate='+this.startDateSelected_In+'&endDate='+this.endDateSelected_In+'&reportType=indirect').subscribe((data: any) => {
    if(data){
      data.sort((a, b) => {
        return <any>new Date(b.sightingDate) - <any>new Date(a.sightingDate);
      });
      $("#indirectTable").DataTable().destroy();
        this.tabledata2=data;
        this.dtTrigger2.next();
        this.loader=false;
      }
    });
  }catch{
    this.loader=false;
  }
  }
  getNillMovementRecord(){
    if(this.circleId.toString()!="0" ){this.circleSelected_nill=this.circleId.toString();}
    if(this.divisionId.toString()!="0"){this.divisionSelected_nill=this.divisionId.toString();}
    if(this.rangeId.toString()!="0"){this.rangeSelected_nill=this.rangeId.toString();}
    this.loader=true;
    this.getCurentDate();
    return this.apiService.request('GET', 'api/v1/reports/viewReport?circle='+this.circleSelected_nill+'&division='+this.divisionSelected_nill+'&range='+this.rangeSelected_nill+'&section='+this.sectionSelected_nill+'&beat='+this.beatSelected_nill+'&startDate='+this.startDateSelected_nill+'&endDate='+this.endDateSelected_nill+'&reportType=nill').subscribe((data: any) => {
    if(data){
      $("#NillTable").DataTable().destroy();
        this.tabledata3=data;
        //console.log(this.tabledata3)
        this.dtTrigger3.next();
        this.loader=false;
      }
    });
  }
  getProblemeticElephant(){
    try{
        if(this.circleId.toString()!="0" ){this.circleSelected_nill=this.circleId.toString();}
        if(this.divisionId.toString()!="0"){this.divisionSelected_nill=this.divisionId.toString();}
        if(this.rangeId.toString()!="0"){this.rangeSelected_nill=this.rangeId.toString();}
      this.loader=true;
      this.getCurentDate();
      return this.apiService.request('GET', 'api/v1/reports/viewProblemeticReport?circle='+this.circleSelected_nill+'&division='+this.divisionSelected_nill+'&range='+this.rangeSelected_nill+'&section='+this.sectionSelected_In+'&beat='+this.beatSelected_In+'&startDate='+this.startDateSelected_nill+'&endDate='+this.endDateSelected_nill).subscribe((data: any) => {
      if(data){
        data.sort((a, b) => {
          return <any>new Date(b.sightingDate) - <any>new Date(a.sightingDate);
        });
        $("#NillTable").DataTable().destroy();
          this.tabledata3=data;
          //console.log(data);
          this.dtTrigger3.next();
          this.loader=false;
        }
      });
    }catch{
      this.loader=false;
    }
  }
  loadtable(value:any){
    const userdata=sessionStorage.getItem('user');
    if(userdata!=null){
      const user=JSON.parse(userdata);
      this.roleId=user.roleId;
      this.juridictionId=user.juridictionId;
      this.circleId=user.circleId;
      this.divisionId=user.divisionId;
      this.rangeId=user.rangeId;
      this.sectionId=user.sectionId;
      this.beatId=user.beatId;
    if(this.divisionlist!=""){
     if(this.circleId.toString()=="0"){
      this.divisionlist=[];
      this.rangelist=[];
      this.sectionlist=[];
      this.beatlist=[];
      this.divid="0";
      this.divid_in="0";
      this.divid_nill="0";
      this.rangeid="0";
      this.rangeid_in="0";
      this.rangeid_nill="0";
      this.sectionid="0";
      this.sectionid_in="0";
      this.sectionid_nill="0";
      this.beatid="0";
      this.beatid_in="0";
      this.beatid_nill="0";
     }

     }
    if(value=="0"){
      if(this.circleId.toString()!="0" ){this.circleid=this.circleId.toString();}
      if(this.divisionId.toString()!="0"){this.divid=this.divisionId.toString();}
      if(this.rangeId.toString()!="0"){this.rangeid=this.rangeId.toString();}
      $("#direct").DataTable().destroy();
      this.getDirectMovementRecord();
    }else if(value=="1"){
      if(this.circleId.toString()!="0" ){this.circleid_in=this.circleId.toString();}
      if(this.divisionId.toString()!="0"){this.divid_in=this.divisionId.toString();}
      if(this.rangeId.toString()!="0"){this.rangeid_in=this.rangeId.toString();}
      $("#indirectTable").DataTable().destroy();
      this.getIndirectMovementRecord();
    }else if(value=="2"){
      if(this.circleId.toString()!="0" ){this.circleid_nill=this.circleId.toString();}
      if(this.divisionId.toString()!="0"){this.divid_nill=this.divisionId.toString();}
      if(this.rangeId.toString()!="0"){this.rangeid_nill=this.rangeId.toString();}
      $("#NillTable").DataTable().destroy();
      this.getProblemeticElephant();
     // this.getNillMovementRecord();
    }
   }else{
    this.router.navigate(['']);
     return;
  }
  }
  formatDate(date) {
    let dateexct=date.split('-');
    var day = dateexct [0];
    var month = dateexct [1];
    var year = dateexct [2];
    var fullDay=year+'-'+month+'-'+day;
    return fullDay;
 }
 formatDateEdit(date) {
  let dateexct=date.split('-');
  var year = dateexct [0];
  var month = dateexct [1];
  var day = dateexct[2].split(' ')[0];
  var fullDay=month+'-'+day+'-'+year;
  return fullDay;
}
 //direct table
  onSubmitdirectMovementRecord(value:any){
     this.loader=true;//set loader for visible
      let startDate = this.sdate.nativeElement.value;
      let endDate = this.edate.nativeElement.value;

      if(startDate!=''){
        startDate=this.formatDate(startDate);
        this.startDateSelected=startDate;
      }else if(startDate==''){
        alert("Please Provide Start date")
        this.loader=false;
        return false;
      }
      if(endDate!=''){
        endDate=this.formatDate(endDate);
        this.endDateSelected=endDate
      }else if(endDate==''){
        alert("Please Provide End date")
        this.loader=false;
        return false;
      }
      if(startDate>endDate){
        alert("Start date should be greater then End date.")
        this.loader=false;
        return false;
      }
      if(this.circleid.toString()!="0" ){this.circleSelected=this.circleid.toString();}
      else  if(this.circleid.toString()=="0" ){ this.circleSelected='All';}
      if(this.divid.toString()!="0"){this.divisionSelected=this.divid.toString();}
      else if(this.divid.toString()=="0"){this.divisionSelected='All';}
      if(this.rangeid.toString()!="0"){this.rangeSelected=this.rangeid.toString();}
      else if(this.rangeid.toString()=="0"){this.rangeSelected='All';}
      if(value.section!="0"){this.sectionSelected=value.section;}
      else if(value.section=="0"){this.sectionSelected='All';}
      if(value.beat!="0"){this.beatSelected=value.beat;}
      else if(value.beat=="0"){this.beatSelected='All';}

      $("#direct").DataTable().destroy();
       this.getDirectMovementRecord();
      //  if(this.tabledata1!=[]){
      //   this.populatePoint("direct");
      //  }
       this.loader=false;
  }
  //indirect table
  onSubmitindirectMovementRecord(value:any){
      this.loader=true;//set loader for visible
      let startDate_in = this.sdate_in.nativeElement.value;
      let endDate_in = this.edate_in.nativeElement.value;
      if(startDate_in!=null){
        startDate_in=this.formatDate(startDate_in);
        this.startDateSelected_In=startDate_in;
      }else{
        alert("Please Provide Start date")
        this.loader=false;
        return false;
      }
      if(endDate_in!=null){
        endDate_in=this.formatDate(endDate_in);
        this.endDateSelected_In=endDate_in
      }else{
        alert("Please Provide End date")
        this.loader=false;
        return false;
      }
      if(startDate_in>endDate_in){
        alert("Start date should be greater then End date.")
        this.loader=false;
        return false;
      }
      if(value.In_circle!="0"){this.circleSelected_In=value.In_circle;}
      else if(value.In_circle=="0"){this.circleSelected_In="All";}
      if(value.In_division!="0"){this.divisionSelected_In=value.In_division;}
      else if(value.In_division=="0"){this.divisionSelected_In="All";}
      if(value.In_range!="0"){this.rangeSelected_In=value.In_range;}
      else if(value.In_range=="0"){this.rangeSelected_In="All";}
      if(value.In_section!="0"){this.sectionSelected_In=value.In_section;}
      else if(value.In_section=="0"){this.sectionSelected_In="All";}
      if(value.In_beat!="0"){this.beatSelected_In=value.In_beat;}
      else if(value.In_beat=="0"){this.beatSelected_In="All";}
      $("#indirectTable").DataTable().destroy();
       this.getIndirectMovementRecord();
      //  if(this.tabledata2!=[]){
      //   this.populatePoint("indirect");
      //  }
       this.loader=false;
  }
  //nil
  onSubmitNillMovementRecord(value:any){
      this.loader=true;//set loader for visible
      let startDate_nill = this.sdate_nill.nativeElement.value;
      let endDate_nill = this.edate_nill.nativeElement.value;
      if(startDate_nill!=null){
        startDate_nill=this.formatDate(startDate_nill);
        this.startDateSelected_nill=startDate_nill;

      }else{
        alert("Please Provide Start date")
        this.loader=false;
        return false;
      }
      if(endDate_nill!=null){
        endDate_nill=this.formatDate(endDate_nill);
        this.endDateSelected_nill=endDate_nill;
      }else{
        alert("Please Provide End date")
        this.loader=false;
        return false;
      }
      if(startDate_nill>endDate_nill){
        alert("Start date should be greater then End date.")
        this.loader=false;
        return false;
      }
      if(value.Nill_circle!="0"){this.circleSelected_nill=value.Nill_circle;}
      else if(value.Nill_circle=="0"){this.circleSelected_nill="All";}
      if(value.Nill_division!="0"){this.divisionSelected_nill=value.Nill_division;}
      else if(value.Nill_division=="0"){this.divisionSelected_nill="All";}
      if(value.Nill_range!="0"){this.rangeSelected_nill=value.Nill_range;}
      else if(value.Nill_range=="0"){this.rangeSelected_nill="All";}
      if(value.Nill_section!="0"){this.sectionSelected_nill=value.Nill_section;}
      else if(value.Nill_section=="0"){this.sectionSelected_nill="All";}
      if(value.Nill_beat!="0"){this.beatSelected_nill=value.Nill_beat;}
      else if(value.Nill_beat=="0"){this.beatSelected_nill="All";}
      $("#NillTable").DataTable().destroy();
       this.getProblemeticElephant();
       this.loader=false;
  }
  getMovementRecordByID(report : Report){
      this.sightingDate='';
      this.sightingTimefrom='';
      this.sightingTimeTo='';
      this.location='';
      this.divisionName=''; 
      this.rangeName='';
      this.secName='';
      this.beatName='';
      this.heard='';
      this.total='';
      this.tusker='';
      this.mukhna='';
      this.female='';
      this.calf='';
      this.latitude='';
      this.longitude='';
      this.remarks='';
      this.tuskerAdult='';
      this.tuskerSubAdult='';
      this.reportType='';
      this.indirectReportType='';
      this.trumptingApproxDistance='';
      this.trumptingDirection='';
      this.duplicateReport="";
      this.imgAcqlocation='';
      this.selfieImglocation='';

      this.sightingDate=report.sightingDate;
      this.sightingTimefrom=report.sightingTimefrom;
      this.sightingTimeTo=report.sightingTimeTo;
      this.location=report.location;
      this.divisionName=report.divisionName;
      this.rangeName=report.rangeName;
      this.secName=report.secName;
      this.beatName=report.beatName;
      this.heard=report.heard;
      this.total=report.total;
      this.tusker=report.tusker;
      this.mukhna=report.mukhna;
      this.female=report.female;
      this.calf=report.calf;
      this.latitude=report.latitude;
      this.longitude=report.longitude;
      this.remarks=report.remarks;

      this.problemetic=report.problemetic;
      this.inchargeOfficer=report.inchargeOfficer;
      this.uniqueId=report.uniqueId;
      if(report.reportType=="direct"){
        this.reportType="Direct";
        this.tuskerAdult=report.tuskerAdult;
        this.tuskerSubAdult=report.tuskerSubAdult;
      }else if(report.reportType=="indirect"){
          this.reportType="Indirect";
          this.indirectReportType=report.indirectReportType;
          if(report.indirectReportType=="Trumpeting call/Sound"){
              this.trumptingApproxDistance=report.trumptingApproxDistance;
              this.trumptingDirection=report.trumptingDirection;
           }
      }else if(report.reportType=="nill"){
       this.reportType="Nil";
      }
      this.updatedBy=report.updatedBy;
      if(report.duplicateReport==null){
        this.duplicateReport="";
      }else  if(report.duplicateReport=="d"){
        this.duplicateReport="(Duplicate)";
      }
   
      this.imgAcqlocation=baseUrlFile+'report/'+report.imgAcqlocation;
      this.selfieImglocation=baseUrlFile+'report/'+report.selfieImglocation;

      ////this.imgAcqlocation=baseUrl+'api/v1/uploadController/downloadFile?location='+report.imgAcqlocation;
     // this.imgAcqlocation=baseUrlFile+'report/'+report.imgAcqlocation;
    //  // src="assets/images/blog-01.jpg"
    // //return this.apiService.request('GET', 'api/v1/reports/viewReportById?id='+id).subscribe((data: any) => {
    //   //if(data){
    //   //    //this.updateForm.setValue(data);
    //   //  }
    //  // });
 
  }
  setTime(times){
    //console.log(times)
    var date = new Date(times);
    var hours:any = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    // var am_pm = date.getHours() >= 12 ? "PM" : "AM";
    // console.log(am_pm)
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
   // console.log(seconds)
    var time = hours + ":" + minutes + ":"+ seconds ;
    //console.log(time)
    //time = hours + ":" + minutes + am_pm;
    return time;
  }
  setTimeEdit(times){
    //console.log(times)
    var date = new Date(times);
    var hours:any = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
   // console.log(date.getSeconds())
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    //console.log(seconds)
    var time = hours + ":" + minutes + ":"+ seconds ;
   // console.log(time)
    //time = hours + ":" + minutes + am_pm;
    return time;
  }
  getMovementRecordByIDForEdit(report : Report){
    // console.log(report);
     this.reportIdEdit=report.id;
     this.reportTypeEdit=report.reportType;
    // console.log(this.reportIdEdit);
     jQuery("#datepicker7").datepicker({
            autoclose: true,
        }).datepicker('update', new Date(this.formatDateEdit(report.sightingDate)));
       jQuery('.clockpicker1').clockpicker({'default': this.setTime(report.sightingTimefrom),autoclose: true}).find('input').val(this.setTime(report.sightingTimefrom));
       jQuery('.clockpicker2').clockpicker({'default': this.setTime(report.sightingTimeTo),autoclose: true}).find('input').val(this.setTime(report.sightingTimeTo));
       
     this.reporteditForm= this.formBuilder.group({
      location:[report.location],
      elephant:[Number(report.total)],
      herd:[Number(report.heard)],
      calf:[Number(report.calf)],
      female:[Number(report.female)],
      tusker:[Number(report.tusker)],
      tuskerAdult:[Number(report.tuskerAdult)],
      tuskerSubAdult:[Number(report.tuskerSubAdult)],
      mukhna:[Number(report.mukhna)],
      remarks:[report.remarks]
    });
  }

  createPdf(){
    window.print();
  }
  defaultUrl(event) {
    event.target.src='assets/images/no_img.png';
  }
  //For MapVIew
  inhisiatemapViewdirect(){
    //alert("i execute second");
      var imegary = new Tile({
        visible: false,
        source: new OSM({
          url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
        })
      });
      var streerMap = new Tile({
        visible: true,
        source: new OSM({
          url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        })
      });
      this.imegary = imegary;
      this.streetmap = streerMap;

      // State Boundary
      var _StateBoundary = new layer.Image({
        source: new ImageWMS({
          url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wms',
          params: {
            'FORMAT': 'image/png',
            'VERSION': '1.1.0',
            STYLES: '',
            LAYERS: 'iwlms:odisha_state_boundary'
          },
          serverType: 'geoserver',
          crossOrigin: 'anonymous'
        }),
        visible: true
      });
      // Circle Boundary
      var _CircleBoundary = new layer.Image({
        source: new ImageWMS({
          url: wms_url,
          params: {
            'FORMAT': 'image/png',
            'VERSION': '1.1.0',
            STYLES: '',
            LAYERS: 'odisha:master_area_circle',
            CQL_FILTER: "id<>11 and active=1"

          },
          serverType: 'geoserver',
          crossOrigin: 'anonymous'
        }),
        visible: true
      });

        setTimeout(() => {
        // generate map to its target and set to a global variable
        this.directmapView = new Map({
        layers: [imegary, streerMap, _StateBoundary,_CircleBoundary],
        controls: [
          new control.Zoom(),
          new control.ZoomSlider(),
          new control.ScaleLine
          ({
            units: 'metric',
            bar: true,
            steps: 4,
            text: true,
            minWidth: 140
          })
        ],
        overlays: [],
        target: 'mapViewdirect',
        view:
          new View({
          center: proj.fromLonLat([85.190055, 20.886785]),
          zoom: 7,
          maxZoom: 23,
          minZoom: 5,
          })
        });
        this.populatePoint("direct");
      }, 100);

  }
  inhisiatemapViewindirect(){
      var imegary = new Tile({
        visible: false,
        source: new OSM({
          url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
        })
      });
      var streerMap = new Tile({
        visible: true,
        source: new OSM({
          url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        })
      });
      this.imegary = imegary;
      this.streetmap = streerMap;

      // State Boundary
      var _StateBoundary = new layer.Image({
        source: new ImageWMS({
          url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wms',
          params: {
            'FORMAT': 'image/png',
            'VERSION': '1.1.0',
            STYLES: '',
            LAYERS: 'iwlms:odisha_state_boundary'
          },
          serverType: 'geoserver',
          crossOrigin: 'anonymous'
        }),
        visible: true
      });
       // Circle Boundary
       var _CircleBoundary = new layer.Image({
        source: new ImageWMS({
          url: wms_url,
          params: {
            'FORMAT': 'image/png',
            'VERSION': '1.1.0',
            STYLES: '',
            LAYERS: 'odisha:master_area_circle',
            CQL_FILTER: "id<>11 and active=1"

          },
          serverType: 'geoserver',
          crossOrigin: 'anonymous'
        }),
        visible: true
      });
        setTimeout(() => {
        // generate map to its target and set to a global variable
        this.indirectmapView = new Map({
        layers: [imegary, streerMap, _StateBoundary,_CircleBoundary],
        controls: [
          new control.Zoom(),
          new control.ZoomSlider(),
          new control.ScaleLine
          ({
            units: 'metric',
            bar: true,
            steps: 4,
            text: true,
            minWidth: 140
          })
        ],
        overlays: [],
        target: 'mapViewindirect',
        view:
          new View({
          center: proj.fromLonLat([85.190055, 20.886785]),
          zoom: 7,
          maxZoom: 23,
          minZoom: 5,
          })
        });
        this.populatePoint("indirect");
      }, 100);

  }
  inhisiatemapViewProblem(){
    var imegary = new Tile({
      visible: false,
      source: new OSM({
        url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
      })
    });
    var streerMap = new Tile({
      visible: true,
      source: new OSM({
        url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      })
    });
    this.imegary = imegary;
    this.streetmap = streerMap;

    // State Boundary
    var _StateBoundary = new layer.Image({
      source: new ImageWMS({
        url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wms',
        params: {
          'FORMAT': 'image/png',
          'VERSION': '1.1.0',
          STYLES: '',
          LAYERS: 'iwlms:odisha_state_boundary'
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
      }),
      visible: true
    });
     // Circle Boundary
     var _CircleBoundary = new layer.Image({
      source: new ImageWMS({
        url: wms_url,
        params: {
          'FORMAT': 'image/png',
          'VERSION': '1.1.0',
          STYLES: '',
          LAYERS: 'odisha:master_area_circle',
          CQL_FILTER: "id<>11 and active=1"

        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
      }),
      visible: true
    });
      setTimeout(() => {
      // generate map to its target and set to a global variable
      this.problemmapView = new Map({
      layers: [imegary, streerMap, _StateBoundary,_CircleBoundary],
      controls: [
        new control.Zoom(),
        new control.ZoomSlider(),
        new control.ScaleLine
        ({
          units: 'metric',
          bar: true,
          steps: 4,
          text: true,
          minWidth: 140
        })
      ],
      overlays: [],
      target: 'mapViewProblem',
      view:
        new View({
        center: proj.fromLonLat([85.190055, 20.886785]),
        zoom: 7,
        maxZoom: 23,
        minZoom: 5,
        })
      });
      this.populatePoint("problem");
    }, 100);

}
  populatePoint(type){
    try{
    this.loader=true;
    this.latlongarray = new Array();
    this.latlongarray_d = new Array();
    this.latlongarray2 = new Array();
    this.latlongarray2_d = new Array();
    this.latlongarray3 = new Array();
    this.latlongarray3_d = new Array();
    if (this.elephantpoitnsglobal_directMapview.length > 0) {
      for (let index = 0; index < this.elephantpoitnsglobal_directMapview.length; index++) {
        const element = this.elephantpoitnsglobal_directMapview[index];
        this.directmapView.removeLayer(element);
      }
    }
    if (this.elephantpoitnsglobal_indirectMapview.length > 0) {
      for (let index = 0; index < this.elephantpoitnsglobal_indirectMapview.length; index++) {
        const element = this.elephantpoitnsglobal_indirectMapview[index];
        this.indirectmapView.removeLayer(element);
      }
    }  if (this.elephantpoitnsglobal_problemMapview.length > 0) {
      for (let index = 0; index < this.elephantpoitnsglobal_problemMapview.length; index++) {
        const element = this.elephantpoitnsglobal_problemMapview[index];
        this.problemmapView.removeLayer(element);
      }
    }
    
    if(type=="direct")
    {
      if(this.tabledata1.length!==0){
        this.tabledata1.forEach(element => {
          //direct
          if (element['reportType'] == "direct") {
            if (element['duplicateReport'] == "d") {
              var routePath = {
                longitude: element['longitude'],
                latitude: element['latitude']
              };
              this.latlongarray_d.push(routePath);
              for (let index = 0; index < this.latlongarray_d.length; index++) {
                const element = this.latlongarray_d[index];
                var iconFeature = new Feature({
                  geometry: new Point(proj.fromLonLat([element.longitude, element.latitude])),
                  name: 'Null Island',
                  population: 4000,
                  rainfall: 500,
                });
                var iconStyle = new Style({
                  image: new Icon({
                    src: '../assets/images/duplicate.png',
                  }),
                });
                iconFeature.setStyle(iconStyle);
                var vectorSource = new VectorSource({
                  features: [iconFeature],
                });
                var vectorLayer = new VectorLayer({
                  source: vectorSource,
                });
                this.elephantpoitnsglobal_directMapview.push(vectorLayer);
                this.directmapView.addLayer(vectorLayer);
              }
            }
            if (element['duplicateReport'] == null) {
              var routePath = {
                longitude: element['longitude'],
                latitude: element['latitude']
              };
              this.latlongarray.push(routePath);
              for (let index = 0; index < this.latlongarray.length; index++) {
                const element = this.latlongarray[index];
                var iconFeature = new Feature({
                  geometry: new Point(proj.fromLonLat([element.longitude, element.latitude])),
                  name: 'Null Island',
                  population: 4000,
                  rainfall: 500,
                });
                var iconStyle = new Style({
                  image: new Icon({
                    src: '../assets/images/directmarker.png',
                  }),
                });
                iconFeature.setStyle(iconStyle);
                var vectorSource = new VectorSource({
                  features: [iconFeature],
                });
                var vectorLayer = new VectorLayer({
                  source: vectorSource,
                });
                this.elephantpoitnsglobal_directMapview.push(vectorLayer);
                this.directmapView.addLayer(vectorLayer);
                this.loader=false;
              }
            }
          }
        });
      }
    }
    else if (type == "indirect")
    {
       if(this.tabledata2.length!==0){
        this.tabledata2.forEach(element => {
          if (element['reportType'] == "indirect") {
            if (element['duplicateReport'] == "d") {
              var routePath = {
                longitude: element['longitude'],
                latitude: element['latitude']
              };
              this.latlongarray2_d.push(routePath);
              for (let index = 0; index < this.latlongarray2_d.length; index++) {
                const element = this.latlongarray2_d[index];
                var iconFeature = new Feature({
                  geometry: new Point(proj.fromLonLat([element.longitude, element.latitude])),
                  name: 'Null Island',
                  population: 4000,
                  rainfall: 500,
                });
                var iconStyle = new Style({
                  image: new Icon({
                    src: '../assets/images/duplicate.png',
                  }),
                });
                iconFeature.setStyle(iconStyle);
                var vectorSource = new VectorSource({
                  features: [iconFeature],
                });
                var vectorLayer = new VectorLayer({
                  source: vectorSource,
                });
                this.elephantpoitnsglobal_indirectMapview.push(vectorLayer);
                this.indirectmapView.addLayer(vectorLayer);
              }
            }
            if (element['duplicateReport'] == null) {
              var routePath = {
                longitude: element['longitude'],
                latitude: element['latitude']
              };
              this.latlongarray2.push(routePath);
              for (let index = 0; index < this.latlongarray2.length; index++) {
                const element = this.latlongarray2[index];
                var iconFeature = new Feature({
                  geometry: new Point(proj.fromLonLat([element.longitude, element.latitude])),
                  name: 'Null Island',
                  population: 4000,
                  rainfall: 500,
                });
                var iconStyle = new Style({
                  image: new Icon({
                    src: '../assets/images/indirectmarker.png',
                  }),
                });
                iconFeature.setStyle(iconStyle);
                var vectorSource = new VectorSource({
                  features: [iconFeature],
                });
                var vectorLayer = new VectorLayer({
                  source: vectorSource,
                });
                this.elephantpoitnsglobal_indirectMapview.push(vectorLayer);
                this.indirectmapView.addLayer(vectorLayer);
                this.loader=false;
              }
            }
          } //indirect
         });
       }
    }else if (type == "problem")
      {
         if(this.tabledata3.length!==0){
          this.tabledata3.forEach(element => {
             //direct
          if (element['reportType'] == "direct") {
            if (element['duplicateReport'] == "d") {
              var routePath = {
                longitude: element['longitude'],
                latitude: element['latitude']
              };
              this.latlongarray3_d.push(routePath);
              for (let index = 0; index < this.latlongarray3_d.length; index++) {
                const element = this.latlongarray3_d[index];
                var iconFeature = new Feature({
                  geometry: new Point(proj.fromLonLat([element.longitude, element.latitude])),
                  name: 'Null Island',
                  population: 4000,
                  rainfall: 500,
                });
                var iconStyle = new Style({
                  image: new Icon({
                    src: '../assets/images/duplicate.png',
                  }),
                });
                iconFeature.setStyle(iconStyle);
                var vectorSource = new VectorSource({
                  features: [iconFeature],
                });
                var vectorLayer = new VectorLayer({
                  source: vectorSource,
                });
                this.elephantpoitnsglobal_problemMapview.push(vectorLayer);
                this.problemmapView.addLayer(vectorLayer);
              }
            }
            if (element['duplicateReport'] == null) {
              var routePath = {
                longitude: element['longitude'],
                latitude: element['latitude']
              };
              this.latlongarray3.push(routePath);
              for (let index = 0; index < this.latlongarray3.length; index++) {
                const element = this.latlongarray3[index];
                var iconFeature = new Feature({
                  geometry: new Point(proj.fromLonLat([element.longitude, element.latitude])),
                  name: 'Null Island',
                  population: 4000,
                  rainfall: 500,
                });
                var iconStyle = new Style({
                  image: new Icon({
                    src: '../assets/images/directmarker.png',
                  }),
                });
                iconFeature.setStyle(iconStyle);
                var vectorSource = new VectorSource({
                  features: [iconFeature],
                });
                var vectorLayer = new VectorLayer({
                  source: vectorSource,
                });
                this.elephantpoitnsglobal_problemMapview.push(vectorLayer);
                this.problemmapView.addLayer(vectorLayer);
                this.loader=false;
              }
            }
          }
            if (element['reportType'] == "indirect") {
              if (element['duplicateReport'] == "d") {
                var routePath = {
                  longitude: element['longitude'],
                  latitude: element['latitude']
                };
                this.latlongarray3_d.push(routePath);
                for (let index = 0; index < this.latlongarray3_d.length; index++) {
                  const element = this.latlongarray3_d[index];
                  var iconFeature = new Feature({
                    geometry: new Point(proj.fromLonLat([element.longitude, element.latitude])),
                    name: 'Null Island',
                    population: 4000,
                    rainfall: 500,
                  });
                  var iconStyle = new Style({
                    image: new Icon({
                      src: '../assets/images/duplicate.png',
                    }),
                  });
                  iconFeature.setStyle(iconStyle);
                  var vectorSource = new VectorSource({
                    features: [iconFeature],
                  });
                  var vectorLayer = new VectorLayer({
                    source: vectorSource,
                  });
                  this.elephantpoitnsglobal_problemMapview.push(vectorLayer);
                  this.problemmapView.addLayer(vectorLayer);
                }
              }
              if (element['duplicateReport'] == null) {
                var routePath = {
                  longitude: element['longitude'],
                  latitude: element['latitude']
                };
                this.latlongarray3.push(routePath);
                for (let index = 0; index < this.latlongarray3.length; index++) {
                  const element = this.latlongarray3[index];
                  var iconFeature = new Feature({
                    geometry: new Point(proj.fromLonLat([element.longitude, element.latitude])),
                    name: 'Null Island',
                    population: 4000,
                    rainfall: 500,
                  });
                  var iconStyle = new Style({
                    image: new Icon({
                      src: '../assets/images/indirectmarker.png',
                    }),
                  });
                  iconFeature.setStyle(iconStyle);
                  var vectorSource = new VectorSource({
                    features: [iconFeature],
                  });
                  var vectorLayer = new VectorLayer({
                    source: vectorSource,
                  });
                  this.elephantpoitnsglobal_problemMapview.push(vectorLayer);
                  this.problemmapView.addLayer(vectorLayer);
                  this.loader=false;
                }
              }
            } //indirect
           });
         }
    }
   }catch{
    this.loader=false;
   }finally{
    this.loader=false;
   }
  }

  //For
  inhisiatemapdirect(){
    //alert("i execute second");
      var imegary = new Tile({
        visible: false,
        source: new OSM({
          url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
        })
      });
      var streerMap = new Tile({
        visible: true,
        source: new OSM({
          url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        })
      });
      this.imegary = imegary;
      this.streetmap = streerMap;

      // State Boundary
      var _StateBoundary = new layer.Image({
        source: new ImageWMS({
          url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wms',
          params: {
            'FORMAT': 'image/png',
            'VERSION': '1.1.0',
            STYLES: '',
            LAYERS: 'iwlms:odisha_state_boundary'
          },
          serverType: 'geoserver',
          crossOrigin: 'anonymous'
        }),
        visible: true
      });
        setTimeout(() => {
        // generate map to its target and set to a global variable
        this.mapdirect = new Map({
        layers: [imegary, streerMap, _StateBoundary],
        controls: [
          new control.Zoom(),
          new control.ZoomSlider(),
          new control.ScaleLine
          ({
            units: 'metric',
            bar: true,
            steps: 4,
            text: true,
            minWidth: 140
          })
        ],
        overlays: [],
        target: 'emdirect',
        view:
          new View({
          center: proj.fromLonLat([85.190055, 20.886785]),
          zoom: 7,
          maxZoom: 23,
          minZoom: 5,
          })
        });
      }, 100);

  }
  inhisiatemapindirect(){
    //alert("i execute second");
      var imegary = new Tile({
        visible: false,
        source: new OSM({
          url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
        })
      });
      var streerMap = new Tile({
        visible: true,
        source: new OSM({
          url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        })
      });
      this.imegary = imegary;
      this.streetmap = streerMap;

      // State Boundary
      var _StateBoundary = new layer.Image({
        source: new ImageWMS({
          url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wms',
          params: {
            'FORMAT': 'image/png',
            'VERSION': '1.1.0',
            STYLES: '',
            LAYERS: 'iwlms:odisha_state_boundary'
          },
          serverType: 'geoserver',
          crossOrigin: 'anonymous'
        }),
        visible: true
      });
        setTimeout(() => {
        // generate map to its target and set to a global variable
        this.mapindirect = new Map({
        layers: [imegary, streerMap, _StateBoundary],
        controls: [
          new control.Zoom(),
          new control.ZoomSlider(),
          new control.ScaleLine
          ({
            units: 'metric',
            bar: true,
            steps: 4,
            text: true,
            minWidth: 140
          })
        ],
        overlays: [],
        target: 'emindirect',
        view:
          new View({
          center: proj.fromLonLat([85.190055, 20.886785]),
          zoom: 7,
          maxZoom: 23,
          minZoom: 5,
          })
        });
      }, 100);

  }
  inhisiatemapproblemetic(){
    //alert("i execute second");
      var imegary = new Tile({
        visible: false,
        source: new OSM({
          url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
        })
      });
      var streerMap = new Tile({
        visible: true,
        source: new OSM({
          url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        })
      });
      this.imegary = imegary;
      this.streetmap = streerMap;

      // State Boundary
      var _StateBoundary = new layer.Image({
        source: new ImageWMS({
          url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wms',
          params: {
            'FORMAT': 'image/png',
            'VERSION': '1.1.0',
            STYLES: '',
            LAYERS: 'iwlms:odisha_state_boundary'
          },
          serverType: 'geoserver',
          crossOrigin: 'anonymous'
        }),
        visible: true
      });
        setTimeout(() => {
        // generate map to its target and set to a global variable
        this.mapproblem = new Map({
        layers: [imegary, streerMap, _StateBoundary],
        controls: [
          new control.Zoom(),
          new control.ZoomSlider(),
          new control.ScaleLine
          ({
            units: 'metric',
            bar: true,
            steps: 4,
            text: true,
            minWidth: 140
          })
        ],
        overlays: [],
        target: 'emproblem',
        view:
          new View({
          center: proj.fromLonLat([85.190055, 20.886785]),
          zoom: 7,
          maxZoom: 23,
          minZoom: 5,
          })
        });
      }, 100);

  }

  //elephantmovementmap2
public thelatitude : any;
public thelongitude : any;
  zoomthepoint(latitude:any,longitude:any,divisionid:any)
  {
    //alert("i execute first");
    //alert(latitude);
    this.divisionidtoextend=divisionid;
    this.thelatitude = latitude;
    this.thelongitude = longitude;

  }
  setDeleteRecord(elementId:any,reportType:any){
      this.deleteSelectedRecord=elementId;
      this.deleteReportType=reportType;
  }
  confirmDelete(){
    try{
    if(this.deleteSelectedRecord!=null){
      this.loader2=true;
     this.apiService.request('PUT', 'api/v1/reports/deleteReportById?loginId='+this.loginuserid+'&reportId='+this.deleteSelectedRecord).subscribe((message:any) => {
       if(message){
            if(message=="success")
            {
                this.notifyService.showSuccess("Deleted Successfully","Success");
                this.loader=false;
                this.loader2=false;
                jQuery("#deleteElephantMovementRecordModal").modal("hide");
                if(this.deleteReportType=='Direct'){
                   $("#direct").DataTable().destroy();
                    this.getDirectMovementRecord();
                }else if(this.deleteReportType=='indirect'){
                  $("#indirectTable").DataTable().destroy();
                    this.getIndirectMovementRecord();
               }if(this.deleteReportType=='nill'){
                  $("#NillTable").DataTable().destroy();
                  this.getProblemeticElephant();
               }
            }else if(message=="failed"){
              jQuery("#deleteElephantMovementRecordModal").modal("hide");
              this.notifyService.showError("UnSuccessfull","Error");
              this.loader=false;
              this.loader2=false;
            }else if(message=="notavailable"){
              jQuery("#deleteElephantMovementRecordModal").modal("hide");
              this.notifyService.showError("Record Not Available","Error");
              this.loader=false;
              this.loader2=false;
            }
         }
       });
    }else{
      jQuery("#deleteElephantMovementRecordModal").modal("hide");
     this.notifyService.showError("Record Not Available! ","Error");
     this.loader=false;
     this.loader2=false;
    }
    }catch{
      this.loader2=false;
    }finally{
      this.loader2=false;
    }
  }
  formatDateToYrMMDD(date) {
    let dateexct=date.split('-');
    var day = dateexct [0];
    var month = dateexct [1];
    var year = dateexct [2];
    var fullDay=year+'-'+month+'-'+day;
    return fullDay;
 }
 onclickCancel(){
  jQuery("#editElephantMovementRecordModal").modal("hide");
 }
 getSumValue(){
if(this.reportTypeEdit=="direct"){
  this.reporteditForm.controls.tusker.setValue(this.reporteditForm.value.tuskerAdult+this.reporteditForm.value.tuskerSubAdult);
}
this.reporteditForm.controls.elephant.setValue(this.reporteditForm.value.calf+this.reporteditForm.value.female+this.reporteditForm.value.mukhna+this.reporteditForm.value.tusker);
}
  updateReport(){
    let sightingDate=this.from_Date_ElementRef.nativeElement.value;
    sightingDate=this.formatDateToYrMMDD(sightingDate);
    let sightingTimefrom=sightingDate+" "+this.from_time_ElementRef.nativeElement.value;
    sightingTimefrom=this.setTimeEdit(sightingTimefrom);
  
    let sightingTimeTo=sightingDate+" "+this.to_time_ElementRef.nativeElement.value;
    sightingTimeTo=this.setTimeEdit(sightingTimeTo);
    if(sightingDate=="" || sightingDate==undefined){
      alert("Please Provide  date");
      return false;
    }
    if(sightingTimefrom==""){
      alert("Please Provide from time");
      return false;
    }
    if(sightingTimeTo==""){
      alert("Please Provide To time");
      return false;
    }
    if(sightingTimefrom>sightingTimeTo){
      alert(" from time should be smaller than To time");
      return false;
    }
    if(sightingTimeTo<sightingTimefrom){
      alert(" To time should be greater than from time");
      return false;
    }
    if(sightingDate!="" && sightingTimefrom!="" && sightingTimeTo!=""){
      var updateReportReq={
        updatedBy:this.loginuserid,
        sightingDate: sightingDate+" "+sightingTimefrom,
        sightingTimefrom:  sightingDate+" "+sightingTimefrom,
        sightingTimeTo:   sightingDate+" "+sightingTimeTo,
        location:this.reporteditForm.value.location,
        total:this.reporteditForm.value.elephant,
        heard:this.reporteditForm.value.herd,
        tusker:this.reporteditForm.value.tusker,
        tuskerAdult:this.reporteditForm.value.tuskerAdult,
        tuskerSubAdult:this.reporteditForm.value.tuskerSubAdult,
        mukhna:this.reporteditForm.value.mukhna,
        female:this.reporteditForm.value.female,
        calf:this.reporteditForm.value.calf,
        remarks:this.reporteditForm.value.remarks
        }
        //console.log(updateReportReq);
        //console.log(this.reportIdEdit);
        this.apiService.request('PUT', 'api/v1/reports/updateElephantMovementReportById?reportId='+this.reportIdEdit, updateReportReq).subscribe((res: any) => {
         // console.log(res);
             if(res=="success"){
               this.notifyService.showSuccess("Report Update Successfully","Success");
               jQuery("#editElephantMovementRecordModal").modal("hide");
               if(this.reportTypeEdit=="direct"){
                  this.getDirectMovementRecord();
               }else if(this.reportTypeEdit=="indirect"){
                this.getIndirectMovementRecord();
               }else{
                this.getNillMovementRecord();
               }
             }
             else if(res=="invalid"){
              this.notifyService.showError("invalid report","Error");
            } else if(res=="failed"){
              this.notifyService.showError("Failed","Error");
            }else if(res=="notavailable"){
              this.notifyService.showError("Not Available","Error");
            }
         });
    } 
  }
  elephantpointdirect : any;
  elephantpointindirect : any;
  letzoomin(latitude:any,longitude:any,type,div)
  {
    const layername_div = 'https://odishaforestgis.in/ofms/index.php/webservice_api/getDivisionGeojson';
		var querydiv;
    if(div!="0"){
      querydiv = "{\"division_id\" : \"" + div + "\"}";
    }
    if(type=="direct")
    {

      setTimeout(() => {
        var iconFeature = new Feature({
          geometry: new Point(proj.fromLonLat([longitude, latitude])),
          name: 'Null Island',
          population: 4000,
          rainfall: 500,
        });
        var iconStyle = new Style({
          image: new Icon({
          src: '../assets/images/directmarker.png',
          }),
        });

        iconFeature.setStyle(iconStyle);
        var vectorSource = new VectorSource({
          features: [iconFeature],
        });
        var vectorLayer = new VectorLayer({
          source: vectorSource
        });
        if (this.elephantpointdirect_featureOverlayNew) {
          this.mapdirect.removeLayer(this.elephantpointdirect_featureOverlayNew);
        }
        if (this.elephantpointdirect) {
          this.mapdirect.removeLayer(this.elephantpointdirect);
        }
        this.elephantpointdirect = vectorLayer;
        this.mapdirect.addLayer(vectorLayer);
        if(querydiv != "")
				{
          let formdata: FormData = new FormData();
          formdata.append('data', querydiv);
          try {
            this.apiService.requestGeoJson(layername_div, formdata).subscribe((response: any[]) => {
              if (response) {
                if (response["status"] == 1) {
                  if (this.elephantpointdirect_featureOverlayNew) {
                    this.mapdirect.removeLayer(this.elephantpointdirect_featureOverlayNew);
                  }
                  const styleFunction =
                    new Style({
                      stroke: new Stroke({
                        color: 'rgba(31, 28, 28, 2)',
                        width: 3,
                        lineDash: [.1, 5] //or other combinations
                      }),
                      zIndex: 2,
                      fill: new Fill({
                        color: 'rgba(32, 28, 28, 0.1)',
                      }),
                      text: new Text({
                        font: 'bold 17px Calibri,sans-serif',
                        fill: new Fill({
                          color: '#eb7734'
                        })
                      })
                    });
                  const vectorSource = new VectorSource();
                  var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
                  var gjFormat = new GeoJSON({
                    featureProjection: 'EPSG:3857',
                  });
                  var features = gjFormat.readFeatures(geojson);
                  vectorSource.clear();
                  var featureOverlayNew = new VectorLayer({
                    source: vectorSource,
                    style: function () {
                      styleFunction.getText().setText(response["post"][0].name_e + " Division");
                      return styleFunction;
                    },
                    updateWhileAnimating: true,
                    updateWhileInteracting: true
                  });
                  featureOverlayNew.getSource().addFeatures(features);
                  this.mapdirect.addLayer(featureOverlayNew);
                  this.mapdirect.getView().setZoom(10);
                  this.mapdirect.getView().fit(featureOverlayNew.getSource().getExtent(), "");
                  this.elephantpointdirect_featureOverlayNew = featureOverlayNew;
                  //vectorLayer.setVisible(true);
                  this.loader=false;
                } else {
                  this.notifyService.showError("Map Data Not Available", "Error");
                  this.loader = false
                }
              } else {
                this.notifyService.showError("Map Data Not Available", "Error");
                this.loader = false
              }
            }, (error) => {
              const errors = error;
              this.loader = false;
            });
          } catch (e) {
            this.notifyService.showError("Something Error!!", "Error");
            this.loader = false
          } finally {
            this.loader = false
          }
				}
      },100);
    }
    else if (type == "indirect")
    {
      setTimeout(() => {
        var iconFeature = new Feature({
          geometry: new Point(proj.fromLonLat([longitude, latitude])),
          name: 'Null Island',
          population: 4000,
          rainfall: 500,
        });
        var iconStyle = new Style({
          image: new Icon({
          src: '../assets/images/indirectmarker.png',
          }),
        });

        iconFeature.setStyle(iconStyle);
        var vectorSource = new VectorSource({
          features: [iconFeature],
        });
        var vectorLayer = new VectorLayer({
          source: vectorSource
        });
        if (this.elephantpointindirect_featureOverlayNew) {
          this.mapindirect.removeLayer(this.elephantpointindirect_featureOverlayNew);
        }
        if(this.elephantpointindirect)
        {
          this.mapindirect.removeLayer(this.elephantpointindirect);

        }
        this.elephantpointindirect = vectorLayer;
        this.mapindirect.addLayer(vectorLayer);
        if(querydiv != "")
				{
          let formdata: FormData = new FormData();
          formdata.append('data', querydiv);
          try {
            this.apiService.requestGeoJson(layername_div, formdata).subscribe((response: any[]) => {
              if (response) {
                if (response["status"] == 1) {
                  if (this.elephantpointindirect_featureOverlayNew) {
                    this.mapindirect.removeLayer(this.elephantpointindirect_featureOverlayNew);
                  }
                  const styleFunction =
                    new Style({
                      stroke: new Stroke({
                        color: 'rgba(31, 28, 28, 2)',
                        width: 3,
                        lineDash: [.1, 5] //or other combinations
                      }),
                      zIndex: 2,
                      fill: new Fill({
                        color: 'rgba(32, 28, 28, 0.1)',
                      }),
                      text: new Text({
                        font: 'bold 17px Calibri,sans-serif',
                        fill: new Fill({
                          color: '#eb7734'
                        })
                      })
                    });
                  const vectorSource = new VectorSource();
                  var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
                  var gjFormat = new GeoJSON({
                    featureProjection: 'EPSG:3857',
                  });
                  var features = gjFormat.readFeatures(geojson);
                  vectorSource.clear();
                  var featureOverlayNew = new VectorLayer({
                    source: vectorSource,
                    style: function () {
                      styleFunction.getText().setText(response["post"][0].name_e + " Division");
                      return styleFunction;
                    },
                    updateWhileAnimating: true,
                    updateWhileInteracting: true
                  });
                  featureOverlayNew.getSource().addFeatures(features);
                  this.mapindirect.addLayer(featureOverlayNew);
                  this.mapindirect.getView().setZoom(10);
                  this.mapindirect.getView().fit(featureOverlayNew.getSource().getExtent(), "");
                  this.elephantpointindirect_featureOverlayNew = featureOverlayNew;
                  //vectorLayer.setVisible(true);
                  this.loader=false;
                } else {
                  this.notifyService.showError("Map Data Not Available", "Error");
                  this.loader = false
                }
              } else {
                this.notifyService.showError("Map Data Not Available", "Error");
                this.loader = false
              }
            }, (error) => {
              const errors = error;
              this.loader = false;
            });
          } catch (e) {
            this.notifyService.showError("Something Error!!", "Error");
            this.loader = false
          } finally {
            this.loader = false
          }
				}
      },100);
    }else if (type == "problem")
      {
        setTimeout(() => {
          var iconFeature = new Feature({
            geometry: new Point(proj.fromLonLat([longitude, latitude])),
            name: 'Null Island',
            population: 4000,
            rainfall: 500,
          });
          var iconStyle = new Style({
            image: new Icon({
            src: '../assets/images/indirectmarker.png',
            }),
          });
  
          iconFeature.setStyle(iconStyle);
          var vectorSource = new VectorSource({
            features: [iconFeature],
          });
          var vectorLayer = new VectorLayer({
            source: vectorSource
          });
          if (this.elephantpointProblematic_featureOverlayNew) {
            this.mapproblem.removeLayer(this.elephantpointProblematic_featureOverlayNew);
          }
          if(this.elephantpointindirect)
          {
            this.mapproblem.removeLayer(this.elephantpointindirect);
  
          }
          this.elephantpointindirect = vectorLayer;
          this.mapproblem.addLayer(vectorLayer);
          if(querydiv != "")
          {
            let formdata: FormData = new FormData();
            formdata.append('data', querydiv);
            try {
              this.apiService.requestGeoJson(layername_div, formdata).subscribe((response: any[]) => {
                if (response) {
                  if (response["status"] == 1) {
                    if (this.elephantpointProblematic_featureOverlayNew) {
                      this.mapproblem.removeLayer(this.elephantpointProblematic_featureOverlayNew);
                    }
                    const styleFunction =
                      new Style({
                        stroke: new Stroke({
                          color: 'rgba(31, 28, 28, 2)',
                          width: 3,
                          lineDash: [.1, 5] //or other combinations
                        }),
                        zIndex: 2,
                        fill: new Fill({
                          color: 'rgba(32, 28, 28, 0.1)',
                        }),
                        text: new Text({
                          font: 'bold 17px Calibri,sans-serif',
                          fill: new Fill({
                            color: '#eb7734'
                          })
                        })
                      });
                    const vectorSource = new VectorSource();
                    var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
                    var gjFormat = new GeoJSON({
                      featureProjection: 'EPSG:3857',
                    });
                    var features = gjFormat.readFeatures(geojson);
                    vectorSource.clear();
                    var featureOverlayNew = new VectorLayer({
                      source: vectorSource,
                      style: function () {
                        styleFunction.getText().setText(response["post"][0].name_e + " Division");
                        return styleFunction;
                      },
                      updateWhileAnimating: true,
                      updateWhileInteracting: true
                    });
                    featureOverlayNew.getSource().addFeatures(features);
                    this.mapproblem.addLayer(featureOverlayNew);
                    this.mapproblem.getView().setZoom(10);
                    this.mapproblem.getView().fit(featureOverlayNew.getSource().getExtent(), "");
                    this.elephantpointProblematic_featureOverlayNew = featureOverlayNew;
                    //vectorLayer.setVisible(true);
                    this.loader=false;
                  } else {
                    this.notifyService.showError("Map Data Not Available", "Error");
                    this.loader = false
                  }
                } else {
                  this.notifyService.showError("Map Data Not Available", "Error");
                  this.loader = false
                }
              }, (error) => {
                const errors = error;
                this.loader = false;
              });
            } catch (e) {
              this.notifyService.showError("Something Error!!", "Error");
              this.loader = false
            } finally {
              this.loader = false
            }
          }
        },100);
    }
  }
  imagerychange(e)
  {
    if (e.target.checked == true) {
      this.imegary.setVisible(true);
    }
    else if (e.target.checked == false) {
      this.imegary.setVisible(false);
    }
  }
  streetchange(e)
  {
    if (e.target.checked == true) {
      this.streetmap.setVisible(true);
    }
    else if (e.target.checked == false) {
      this.streetmap.setVisible(false);
    }
  }
  bingmapchange(e)
  {
    if (e.target.checked == true) {}
    else if (e.target.checked == false) {}
  }
	topochange(e) {
		if (e.target.checked == true) {
			this._carto.setVisible(true);
		}
		else if (e.target.checked == false) {
			this._carto.setVisible(false);
		}
	}

}
