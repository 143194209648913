<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <ul id="tabs" class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a id="tab-chilikaPAmap" href="#pane-chilikaPAmap" class="nav-link active" data-toggle="tab"
              role="tab">Map</a>
          </li>
          <li class="nav-item">
            <a id="tab-chilikaPAinfo" href="#pane-chilikaPAinfo" class="nav-link" data-toggle="tab"
              role="tab">Information</a>
          </li>
        </ul>

        <div id="accordion" class="tab-content" role="tablist">
          <div id="pane-chilikaPAmap" class="card tab-pane fade show active" role="tabpanel"
            aria-labelledby="tab-chilikaPAmap">
            <div class="card-header" role="tab" id="heading-chilikaPAmap">
              <h5 class="mb-0">
                <a data-toggle="collapse" href="#collapse-chilikaPAmap" aria-expanded="true"
                  aria-controls="collapse-chilikaPAmap">
                  Map
                </a>
              </h5>
            </div>
            <div id="collapse-chilikaPAmap" class="collapse show" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-chilikaPAmap">
              <div class="card-body">
                <div class="map" id="mapchilika">
                  <div class="gle-icon">
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid"
                        alt="Street View" title="Street View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()'
                        alt="Satellite View" title="Satellite View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View"
                        title="Topo View">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="pane-chilikaPAinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-chilikaPAinfo">
            <div class="card-header" role="tab" id="heading-chilikaPAinfo">
              <h5 class="mb-0">
                <a class="collapsed" data-toggle="collapse" href="#collapse-chilikaPAinfo" aria-expanded="false"
                  aria-controls="collapse-chilikaPAinfo">
                  Information
                </a>
              </h5>
            </div>
            <div id="collapse-chilikaPAinfo" class="collapse" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-chilikaPAinfo">

              <div class="card-body">

                <h4 class="card-title">
                  Chilika-Nalabana Sanctuary
                  <span class="custom-down">
                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i
                        class="fa fa-file-pdf">&nbsp;</i>Information</button>
                        <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                  </span>
                </h4>
                <div id="printcontent">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td><strong>Forest Division : </strong></td>
                              <td>Chilika (Wildlife) Division</td>
                            </tr>
                            <tr>
                              <td><strong>District : </strong></td>
                              <td>Khurda,Puri & Ganjam</td>
                            </tr>
                            <tr>
                              <td><strong>Date of Notification : </strong></td>
                              <td>17-12-1987</td>
                            </tr>
                            <tr>
                              <td><strong>Notified Area : </strong></td>
                              <td>15.53sq.kms</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-2 video-box no-print">
                      <a href="#" class="video-btn" data-toggle="modal"
                        data-src="https://www.youtube.com/embed/QrqQWO1D47g" data-target="#myModal">
                        <h4>Chilika Sanctuary</h4>
                        <div class="overlay">
                          <span class="icon">
                            <i class="fa fa-play-circle"></i>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="col-md-2">
                      <img src="assets/images/Boating-in-Chilika-Lake.jpg" class="img-fluid float-right img-inner"
                        alt="image">
                    </div>
                  </div>
                  <p>
                    Chilika Lake in Orissa is Asia's biggest internal brackish water lake studded with few tiny islands.
                    This pear shaped lake, separated from the Bay of Bengal also imbibes one of the most supportive
                    ecosystem in the name of Chilka Wildlife Sanctuary in the region. It is also popularly known as
                    Nalabana Bird Sanctuary. The large Nalabana Island covering 15.53 sq km in the lagoon area was
                    declared a bird sanctuary in 1987. The core area of about 6 sq km attracts around 400,000 waterfowls
                    of different species.
                  </p>
                  <h4 class="card-title">Quick Facts</h4>
                  <ul class="custom-list">
                    <li>Nalaban island is completely submerged in the monsoon months.</li>
                    <li>This tiny island supports congregation of about 60% of total migratory birds visiting Chilika
                      lagoon each year.</li>
                    <li>Tourism is not allowed in the Sanctuay at present as per prescription of management plan.
                      However tourists visiting Kalijai temple and having boat ride in Chilika can have a glimpse of the
                      sanctuary from the sanctuary boundary.</li>
                  </ul>
                  <h4 class="card-title">How to reach</h4>
                  <p class="address-inner">
                    <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>Two hours journey from Bhubaneswar. From
                    there, From there, sanctuary is approachable with one hour boat journey
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong>The tourists can find the nearest rail
                    stations at Balugaon, on the Howrah-Chennai track From Balugaon
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>Bhubaneswar is the nearest airport
                    located at 120 km away.
                  </p>
                  <h4 class="card-title">Facilities available</h4>
                  <ul class="custom-list">
                    <li>Boat ride to Chilika is available at Balugaon, Rambha.</li>
                    <li>Staying facilities available at Panthanivas, Barkul run by Odisha Tourism Development
                      Corporation.</li>
                    <li>Many private hotels are also available at Balugaon area.</li>
                  </ul>
                  <h4 class="card-title">Salient Detail</h4>
                  <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                      <tbody>
                        <tr>
                          <td width="15%"><strong>Geographic Extent : </strong></td>
                          <td width="85%">Longitude 85º 05’ to 85º 38’ (East) Latitude 19º 28’ to 19º 54’ (North).</td>
                        </tr>
                        <tr>
                          <td><strong>Climate : </strong></td>
                          <td>Temperature - In summer: 42°C, In winter: 16°C</td>
                        </tr>
                        <tr>
                          <td><strong>Villages :</strong></td>
                          <td>None</td>
                        </tr>
                        <tr>
                          <td><strong>Contact officer : </strong></td>
                          <td>
                            <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest
                            Officer<br>
                            <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Chilika Wildlife
                            Division, Balugaon, Dist-Khurda<br>

                          </td>
                        </tr>
                        <tr>
                          <td><strong>Best time to visit : </strong></td>
                          <td>December - February.</td>
                        </tr>
                        <tr>
                          <td><strong>Accommodation : </strong></td>
                          <td>OTDC Guest Houses at Barkul, Rambha & several hotels at Balugaon.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
                      </strong>The information contained herein are provided solely for informational purposes only.The
                      content is dynamic and may undergo change from time to time. The snapshot of the content at a
                      particular time is not to be referred as a legally binding document.</small></p>
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
