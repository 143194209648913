<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <ul id="tabs" class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a id="tab-kuldihaPAmap" href="#pane-kuldihaPAmap" class="nav-link active" data-toggle="tab"
              role="tab">Map</a>
          </li>
          <li class="nav-item">
            <a id="tab-kuldihaPAinfo" href="#pane-kuldihaPAinfo" class="nav-link" data-toggle="tab"
              role="tab">Information</a>
          </li>
        </ul>

        <div id="accordion" class="tab-content" role="tablist">
          <div id="pane-kuldihaPAmap" class="card tab-pane fade show active" role="tabpanel"
            aria-labelledby="tab-kuldihaPAmap">
            <div class="card-header" role="tab" id="heading-kuldihaPAmap">
              <h5 class="mb-0">
                <a data-toggle="collapse" href="#collapse-kuldihaPAmap" aria-expanded="true"
                  aria-controls="collapse-kuldihaPAmap">
                  Map
                </a>
              </h5>
            </div>
            <div id="collapse-kuldihaPAmap" class="collapse show" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-kuldihaPAmap">
              <div class="card-body">
                <div class="map" id="mapkuldhia">
                  <div class="gle-icon">
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid"
                        alt="Street View" title="Street View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()'
                        alt="Satellite View" title="Satellite View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View"
                        title="Topo View">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="pane-kuldihaPAinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-kuldihaPAinfo">
            <div class="card-header" role="tab" id="heading-kuldihaPAinfo">
              <h5 class="mb-0">
                <a class="collapsed" data-toggle="collapse" href="#collapse-kuldihaPAinfo" aria-expanded="false"
                  aria-controls="collapse-kuldihaPAinfo">
                  Information
                </a>
              </h5>
            </div>
            <div id="collapse-kuldihaPAinfo" class="collapse" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-kuldihaPAinfo">



              <div class="card-body">

                <h4 class="card-title">
                  Kuldiha Sanctuary
                  <span class="custom-down">
                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i
                        class="fa fa-file-pdf">&nbsp;</i>Information</button>
                        <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                  </span>
                </h4>
                <div id="printcontent">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td><strong>Forest Division : </strong></td>
                              <td>Balasore (Wildlife) Division</td>
                            </tr>
                            <tr>
                              <td><strong>District : </strong></td>
                              <td>Balasore</td>
                            </tr>
                            <tr>
                              <td><strong>Date of Notification : </strong></td>
                              <td>04-01-1984</td>
                            </tr>
                            <tr>
                              <td><strong>Notified Area : </strong></td>
                              <td>272.75sq.km</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-2 video-box no-print">
                      <a href="#" class="video-btn" data-toggle="modal"
                        data-src="https://www.youtube.com/embed/FJIsrsVZRH0" data-target="#myModal">
                        <h4>Kuldiha Sanctuary</h4>
                        <div class="overlay">
                          <span class="icon">
                            <i class="fa fa-play-circle"></i>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="col-md-2">
                      <img src="assets/images/Eagle.jpg" class="img-fluid float-right img-inner" alt="image">
                    </div>
                  </div>
                  <p>
                    Kuldiha Wildlife Sanctuary is situated in South – Western side of this Division notified under
                    Section 18 of the Wildlife (Protection) Act, 1972 by the Govt. of Odisha, Forest, Fisheries & Animal
                    Husbandry Department vide their Notification No. 243-FFAH dt.04.01.1984, published in the Odisha
                    Gazette No.5 dt.03.02.1984 which comes under Deccan Peninsula in Chhotnagpur plateau of Garjat
                    Hills. The Sanctuary includes Kuldiha, Devgiri and Tenda Reserved Forests and other Protected
                    Forests. Kuldiha sanctuary area, adjoining Nilgiri forest in the North and Mayurbhanj Forest in
                    West, is a typical representative of mixture of peninsular (coastal) Sal forest and moist mixed
                    deciduous forest. It comes in the Biogeographic region of Deccan peninsular zone which is of
                    enormous genetic and ecological importance with a variety of flora and fauna. The significance of
                    the Kuldiha Wildlife Sanctuary can be categorized as follows: It abodes a number of endangered and
                    threatened wild animal species like Asiatic Elephant, Leopard, Gaur, Mouse deer, Pangolin, Ratel,
                    Giant squirrel etc. Besides that some endangered bird species like Hill myna, Wood pecker, Hornbill
                    and Eagles are also found in the Sanctuary. It forms part of Mayurbhanj Elephant Reserve and linked
                    to Similipal Biosphere Reserve through Hadgarh Wildlife Sanctuary, thus forming a vast landscape
                    contributing to the cause of conservation of long ranging Asiatic Elephants and Tiger. It plays a
                    significant role in ameliorating the local climate. It is the largest catchment which feeds to the
                    major river and tributaries of the district along with maintaining the ground water regime of the
                    locality. It is the major tourist destination in the District for nature lovers and one among the
                    important tourist destinations of the State. Panchalingeswar, the sacred shrine of Lord Shiva, draws
                    devotees from all corners of the State and outside.
                  </p>
                  <h4 class="card-title">Quick Facts</h4>
                  <ul class="custom-list">
                    <li>Nature Education camps at Rissia in Kuldiha Wildlife Sanctuary.</li>
                    <li>The mean annual rain fall is about 1630 mm & mean maximum temperature is 38° C during April –
                      May and minimum 18° C during November – January. The maximum temperature at times touches beyond
                      42° C .</li>
                    <li>The air is quite humid with relative humidity always exceeding 70%.</li>
                    <li>The forests of Kuldiha are broadly divided into i) Northern tropical semi evergreen forests, ii)
                      Peninsular (Coastal) Sal Forests, iii) Moist mixed deciduous forests and iv) Mixed Dry deciduous
                      forests.</li>
                    <li>The total road length in the Sanctuary is 116.5 Km which needs regular maintenance every year.
                    </li>
                    <li>There are 12 revenue villages including 54 hamlets and one encroached village(Chemchata) inside
                      the Sanctuary. Besides this there are 66 revenue villages all around the protected area.</li>
                  </ul>
                  <h4 class="card-title">How to reach</h4>
                  <p class="address-inner">
                    <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>From Bhubaneswar to Nature camp site
                    inside Kuldiha Wildlife Sanctuary 235 Km. /4hr. 15 min. From Cuttack to Nature camp site inside
                    Kuldiha Wildlife Sanctuary 206 Km. /3hr. 30 min. From Bhadrak to Nature camp site inside Kuldiha
                    Wildlife Sanctuary 95 Km. /1hr. 45 min. From Kolkata to Nature camp site inside Kuldiha Wildlife
                    Sanctuary 290 Km. /5hr. 00 min.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong> Bhubaneswar to Balasore 206 Km. /3hr.
                    30 min. Cuttack to Balasore 175 Km. /3hr. 00 min. Bhadrak to Balasore 70 Km./1hr.00 min. Howrah to
                    Balasore 221 Km. / 4 hr. 00 min.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>Bhubaneswar (Biju Pattnayak International
                    Airport). Kolkata Airport (Netaji Subhas Chandra Bose International Airport).
                  </p>
                  <h4 class="card-title">Facilities available</h4>
                  <ul class="custom-list">
                    <li>9Nos. Tented accommodation have been established for the Nature lover/ Eco-tourist at
                      Gohirabhola Nature camp site near Rissia Dam inside Kuldiha Wildlife Sanctuary.</li>
                    <li>All tents are double bedded with attached bath cum toilet in separate tent. Solar light, Solar
                      Fans are fitted in the tents.Hot water supplied to each tent through separate pipeline.</li>
                    <li>Fooding is provided to the visitors in the dinning hall the site.</li>
                    <li>Other facilities available in the Eco-tourism complex includes boating, animal sighting at salt
                      lick, and watch towers, swing, slides, hammock, tea cottage, sit outs, tour guides have been
                      provided for the visitors.</li>
                  </ul>
                  <h4 class="card-title">Salient Detail</h4>
                  <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                      <tbody>
                        <tr>
                          <td width="15%"><strong>Geographic Extent : </strong></td>
                          <td width="85%">Longitude 86° 26' to 86° 45'(East) and Latitude 21° 20' to 21° 30'(North)</td>
                        </tr>
                        <tr>
                          <td><strong>Climate : </strong></td>
                          <td>Relative Humidity - Morning: 70-100%, Midday in summer: 30% Rainfall -
                            220cm(88inches),rainy days: 100-130 days.</td>
                        </tr>
                        <tr>
                          <td><strong>Villages :</strong></td>
                          <td> 12 villages inside and 66 villages outside the sanctuary</td>
                        </tr>
                        <tr>
                          <td><strong>Contact officer : </strong></td>
                          <td>
                            <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest Officer
                            <br>
                            <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Balasore Wildlife
                            Division, Balasore<br>
                            <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06782- 256142 <br>
                            <span class="address-inner"><i class="fa fa-fax">&nbsp;</i></span>06782 – 256968<br>
                            <span class="address-inner"><i class="fa fa-mobile">&nbsp;</i></span>+91-9437062743<br>
                            <span class="address-inner"><i
                                class="fa fa-envelope">&nbsp;</i></span>dfobalasorewl.od@gov.in
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Best time to visit : </strong></td>
                          <td>October to May.</td>
                        </tr>
                        <tr>
                          <td><strong>Accommodation : </strong></td>
                          <td>Available at Gohirabhol.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
                      </strong>The information contained herein are provided solely for informational purposes only.The
                      content is dynamic and may undergo change from time to time. The snapshot of the content at a
                      particular time is not to be referred as a legally binding document.</small></p>
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
