import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup,FormControl, Validators, AbstractControl } from "@angular/forms";
import {Router} from "@angular/router";
import {ApiService} from "../service/api.service";
import { AuthService } from "../service/auth.service";
import { NotificationService } from '../service/notification.service';
import { ConfirmedValidator, PWDExistsValidator } from '../validators/confirmed.validator';
import {PWChangeValidators} from './pw-validators';
declare var jQuery: any;

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  public loader: boolean=false;
  changePasswordForm: FormGroup;
  oldpass:FormControl;
  newpass:FormControl;
  cpassword:FormControl;
  username: any;

  constructor(private formBuilder: FormBuilder, private notifyService:NotificationService,private router: Router, private apiService: ApiService,private authService: AuthService) { }

  ngOnInit(): void {

    (function($){

      $(document).ready(function() {
        $("#show_hide_password a").on('click', function(event : any) {
            event.preventDefault();
            if($('#show_hide_password input').attr("type") == "text"){
                $('#show_hide_password input').attr('type', 'password');
                $('#show_hide_password i').addClass( "fa-eye-slash" );
                $('#show_hide_password i').removeClass( "fa-eye" );
            }else if($('#show_hide_password input').attr("type") == "password"){
                $('#show_hide_password input').attr('type', 'text');
                $('#show_hide_password i').removeClass( "fa-eye-slash" );
                $('#show_hide_password i').addClass( "fa-eye" );
            }
        });
    });
    $(document).ready(function() {
      $("#show_hide_password_2 a").on('click', function(event : any) {
          event.preventDefault();
          if($('#show_hide_password_2 input').attr("type") == "text"){
              $('#show_hide_password_2 input').attr('type', 'password');
              $('#show_hide_password_2 i').addClass( "fa-eye-slash" );
              $('#show_hide_password_2 i').removeClass( "fa-eye" );
          }else if($('#show_hide_password_2 input').attr("type") == "password"){
              $('#show_hide_password_2 input').attr('type', 'text');
              $('#show_hide_password_2 i').removeClass( "fa-eye-slash" );
              $('#show_hide_password_2 i').addClass( "fa-eye" );
          }
      });
  });
  $(document).ready(function() {
    $("#show_hide_password_3 a").on('click', function(event : any) {
        event.preventDefault();
        if($('#show_hide_password_3 input').attr("type") == "text"){
            $('#show_hide_password_3 input').attr('type', 'password');
            $('#show_hide_password_3 i').addClass( "fa-eye-slash" );
            $('#show_hide_password_3 i').removeClass( "fa-eye" );
        }else if($('#show_hide_password_3 input').attr("type") == "password"){
            $('#show_hide_password_3 input').attr('type', 'text');
            $('#show_hide_password_3 i').removeClass( "fa-eye-slash" );
            $('#show_hide_password_3 i').addClass( "fa-eye" );
        }
    });
});

    })(jQuery);

    const userdata=sessionStorage.getItem('user');
    if(userdata!=null){
      const user=JSON.parse(userdata);
      this.username=user.username;
    }
    // this.oldpass=new FormControl('',[Validators.required]);
    // this.newpass=new FormControl('',[Validators.required,Validators.maxLength(15),Validators.minLength(8)]);
    // this.cpassword=new FormControl('',[Validators.required]);
    this.changePasswordForm = this.formBuilder.group({
      oldpass: ['', Validators.required],
      newpass: ['',Validators.compose([Validators.required,Validators.minLength(8)])],
      cpassword: ['', Validators.required]
    }, {
      validator:[ConfirmedValidator('newpass', 'cpassword'),PWDExistsValidator('oldpass', 'newpass')]
    }
    );
  }
  passwordReset(){
    this.apiService.request('POST', 'api/v1/auth/changePassWord',
    {
      oldpassword:this.changePasswordForm.value.oldpass,
      newPassword:this.changePasswordForm.value.newpass,
     username:this.username
    }).subscribe((response: any) => {
       //console.log(response);
       if(response.message=="Success"){
        this.notifyService.showSuccess("Password changed Successfully.","");
       }else if(response.message=="failed"){
        this.notifyService.showError("failed.","");
       }
       else if(response.message=="passwordmismatch"){
        this.notifyService.showError("Incorrect Current Password.","");
        //document.getElementById("oldpass").focus();
       // document.getElementById("oldpass").style.border="2px solid red";
       }
      // alert("success")
     });
  }

}
