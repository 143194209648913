<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>
<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->

<section class="common-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <ul id="tabs" class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a id="tab-badramaPAMap" href="#pane-badramaPAMap" class="nav-link active" data-toggle="tab"
              role="tab">Map</a>
          </li>
          <li class="nav-item">
            <a id="tab-badramaPAinfo" href="#pane-badramaPAinfo" class="nav-link" data-toggle="tab"
              role="tab">Information</a>
          </li>
        </ul>

        <div id="accordion" class="tab-content" role="tablist">
          <div id="pane-badramaPAMap" class="card tab-pane fade show active" role="tabpanel"
            aria-labelledby="tab-badramaPAMap">
            <div class="card-header" role="tab" id="heading-badramaPAMap">
              <h5 class="mb-0">
                <a data-toggle="collapse" href="#collapse-badramaPAMap" aria-expanded="true"
                  aria-controls="collapse-badramaPAMap">
                  Map
                </a>
              </h5>
            </div>
            <div id="collapse-badramaPAMap" class="collapse show" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-badramaPAMap">
              <div class="card-body">
                <div id="mapbadrama" class="map">
                  <div class="gle-icon">
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid"
                        alt="Street View" title="Street View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()'
                        alt="Satellite View" title="Satellite View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View"
                        title="Topo View">
                    </a>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div id="pane-badramaPAinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-badramaPAinfo">
            <div class="card-header" role="tab" id="heading-badramaPAinfo">
              <h5 class="mb-0">
                <a class="collapsed" data-toggle="collapse" href="#collapse-badramaPAinfo" aria-expanded="false"
                  aria-controls="collapse-badramaPAinfo">
                  Information
                </a>
              </h5>
            </div>
            <div id="collapse-badramaPAinfo" class="collapse" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-badramaPAinfo">
              <div class="card-body">
                <h4 class="card-title">
                  Badrama Sanctuary
                  <span class="custom-down">
                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
                    <button class="btn btn-sm btn-info" (click)="Mapalert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                  </span>
                </h4>
                <div id="printcontent">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td><strong>Forest Division : </strong></td>
                              <td>Bamra (Wildlife) Division</td>
                            </tr>
                            <tr>
                              <td><strong>District : </strong></td>
                              <td>Sambalpur</td>
                            </tr>
                            <tr>
                              <td><strong>Date of Notification : </strong></td>
                              <td>17-12-1987</td>
                            </tr>
                            <tr>
                              <td><strong>Notified Area : </strong></td>
                              <td>304.03sq.kms</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-2 video-box no-print">
                      <a href="#" class="video-btn" data-toggle="modal"
                        data-src="https://www.youtube.com/embed/kozy-xnlRmE" data-target="#myModal">
                        <h4>Badrama Sanctuary</h4>
                        <div class="overlay">
                          <span class="icon">
                            <i class="fa fa-play-circle"></i>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="col-md-2">
                      <img src="assets/images/Badrama.jpg" class="img-fluid float-right img-inner" alt="image">
                    </div>
                  </div>
                  <p>
                    “Badrama Wildlife Sanctuary”, which is also known as “Ushakothi Sanctuary” is situated at a distance
                    of 37 Km. from the district headquarters of Sambalpur District towards Deogarh on National Highway-
                    6.
                    The sanctuary comprises of parts of Badrama Reserve Forests, Ushakothi Reserve Forests, Binjipali
                    Reserve Forests and Additional Kansar Reserve Forests. The Sanctuary is characterized by the
                    presence
                    of Moist Sal Forests, Sal dominated mixed deciduous Forests, and bamboo forests. The Sanctuary forms
                    a
                    significant part of Sambalpur Elephant Reserve.
                  </p>
                  <h4 class="card-title">Quick Facts</h4>
                  <ul class="custom-list">
                    <li>The sanctuary has its own significance for its rich treasure of flora and fauna , which includes
                      many rare and endangered species.</li>
                    <li>The Badrama Wildlife Sanctuary provides a great enchantment and overwhelming experience for
                      nature
                      lovers. With place of scenic beauty and chances of wildlife sightings the sanctuary offers an
                      excellent place of recreation and nature education to the people.</li>
                    <li>The sanctuary is also an ideal plat form for ecologists, research scholars and nature lovers.
                    </li>
                    <li>A stay at Tikarpara and cruise along the 25 km long gorge with beautiful forests on either side
                      is
                      a memorable experience.</li>
                    <li>The sanctuary is an ideal tourist destination.</li>
                  </ul>
                  <h4 class="card-title">How to reach</h4>
                  <p class="address-inner">
                    <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>The Sanctuary is 364 kms away from the
                    State capital ,Bhubaneswar.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong> The nearest railway station is at
                    Sambalpur.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>The nearest airport is at Bhubaneswar
                  </p>
                  <h4 class="card-title">Facilities available</h4>
                  <ul class="custom-list">
                    <li>The Sanctuary offers trekking to reach to the Deodhara water fall</li>
                    <li>Kutab watch tower and university spot are available</li>
                  </ul>
                  <h4 class="card-title">Salient Detail</h4>
                  <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                      <tbody>
                        <tr>
                          <td width="15%"><strong>Geographic Extent : </strong></td>
                          <td width="85%">Longitude 84° 12' to 84° 28' (east) Latitude 21° 34' to 21° 21'(North)</td>
                        </tr>
                        <tr>
                          <td><strong>Climate : </strong></td>
                          <td>Temperature-In summer: 45°C, In winter: 10°C</td>
                        </tr>
                        <tr>
                          <td><strong>Villages :</strong></td>
                          <td> 25 Revenue villages.</td>
                        </tr>
                        <tr>
                          <td><strong>Contact officer : </strong></td>
                          <td>
                            <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest
                            Officer<br>
                            <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Bamra Wildlife
                            Division<br>
                            <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06642-229211 <br>
                            <span class="address-inner"><i class="fa fa-envelope">&nbsp;</i></span>dfobmbwl004@gmail.com
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Best time to visit : </strong></td>
                          <td>October- April</td>
                        </tr>
                        <tr>
                          <td><strong>Accommodation : </strong></td>
                          <td>Forest Rest House (FRH) at Badrama</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
                      </strong>The information contained herein are provided solely for informational purposes only.The
                      content is dynamic and may undergo change from time to time. The snapshot of the content at a
                      particular time is not to be referred as a legally binding document.</small></p>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
