<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->

<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

        <h4 class="card-title">Notices</h4>

        <!-- <div class="form-group row">
          <div class="col-md-2 mt-2">
            <label>Select Year :</label>
          </div>
          <div class="col-md-3">
            <select class="form-control">
              <option value="" selected="selected">All</option>
              <option value="">2020</option>
              <option value="">2018</option>
              <option value="">2017</option>
              <option value="">2016</option>
              <option value="">2015</option>
              <option value="">2014</option>
              <option value="">2001</option>
            </select>
          </div>
          <div class="col-md-2 mt-2">
            <label>Select Maonth :</label>
          </div>
          <div class="col-md-3">
            <select class="form-control">
              <option value="" selected="selected">All</option>
              <option value="">January</option>
              <option value="">February</option>
              <option value="">March</option>
              <option value="">April</option>
              <option value="">May</option>
              <option value="">June</option>
              <option value="">July</option>
              <option value="">August</option>
              <option value="">September</option>
              <option value="">October</option>
              <option value="">November</option>
              <option value="">December</option>
            </select>
          </div>
          <div class="col-md-2">
            <button type="submit" class="btn btn-success">Search</button>
          </div>
        </div>

        <hr> -->

				<table class="table table-bordered row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
          <thead>
              <tr>
                  <th width="5%">Sr. No.</th>
                  <th width="12%">Title</th>
                  <th width="6%">Number</th>
                  <th width="6%">Notice Date</th>
                  <!-- <th width="6%">Notice Valid Date</th> -->
                  <th width="5%">Download/Link</th>
              </tr>
           </thead>
          <tbody>

              <tr *ngFor="let group of noticeList ; let i = index" [attr.data-index]="i">
                   <td>{{i+1}} </td>
                  <td>{{group.noticeName}}</td>
                  <td>{{group.noticeNumber}}</td>
                  <td>{{ (group.noticeDate  !== 'null') ? (group.noticeDate | date:'dd-MM-yyyy')  : '' }}</td>
                  <!-- <td>{{ (group.noticeValidDate  !== 'null') ? (group.noticeValidDate | date:'dd-MM-yyyy')  : '' }}</td> -->
                  <td> <a href="{{group.uploadPath}}" target="_blank" class="btn btn-sm btn-danger"><i class="fa fa-file-pdf-o"></i>
                  </a><img src="assets/images/new_icon.gif" *ngIf="group.noticeIsExpire"></td>
              </tr>
          </tbody>
        </table>

        <p class="text-danger mt-3"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>
