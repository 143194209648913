import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from '../service/auth.service';
import {Observable,from} from "rxjs/index";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //Get Token data from local storage
    const webservice_api=[
      "https://odishaforestgis.in/ofms/index.php/webservice_api/getCircleGeojson",
      "https://odishaforestgis.in/ofms/index.php/webservice_api/getDivisionGeojson",
      "https://odishaforestgis.in/ofms/index.php/webservice_api/getRangeGeojson",
      "https://odishaforestgis.in/ofms/index.php/webservice_api/getSectionGeojson",
      "https://odishaforestgis.in/ofms/index.php/webservice_api/getBeatGeojson",
      "https://odisha4kgeo.in/index.php/mapview/getSanctuaryGeom"
    ];
      let token = sessionStorage.getItem('token');
      if(token){
        if(webservice_api.indexOf(request["url"]) !== -1){

        }
        else{
          request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.auth.getToken()}`
             }
          });
        }
      }
    return next.handle(request);
  }
}
