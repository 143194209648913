<app-header *ngIf="isDashboard"></app-header>
<app-menu  *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
			        <li class="nav-item">
			            <a id="tab-khalasuniPAmap" href="#pane-khalasuniPAmap" class="nav-link active" data-toggle="tab" role="tab">Map</a>
			        </li>
			        <li class="nav-item" >
			            <a id="tab-khalasuniPAinfo" href="#pane-khalasuniPAinfo" class="nav-link" data-toggle="tab" role="tab">Information</a>
			        </li>
			    </ul>

			    <div id="accordion" class="tab-content" role="tablist">
			        <div id="pane-khalasuniPAmap" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-khalasuniPAmap">
			            <div class="card-header" role="tab" id="heading-khalasuniPAmap">
			                <h5 class="mb-0">
			                    <a data-toggle="collapse" href="#collapse-khalasuniPAmap" aria-expanded="true" aria-controls="collapse-khalasuniPAmap">
                            Map
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-khalasuniPAmap" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-khalasuniPAmap">
                    <div class="card-body">
                           <div class="map" id="mapkhalasuni">
                             <div class="gle-icon">
                              <a href="javascript:void(0)">
                                <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid" alt="Street View" title="Street View">
                            </a>
                            <a href="javascript:void(0)">
                                <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()' alt="Satellite View" title="Satellite View">
                            </a>
                            <a href="javascript:void(0)">
                                <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View" title="Topo View">
                            </a>
                                    </div>
                           </div>
                    </div>
			            </div>
			        </div>

			        <div id="pane-khalasuniPAinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-khalasuniPAinfo">
			            <div class="card-header" role="tab" id="heading-khalasuniPAinfo">
			                <h5 class="mb-0">
			                    <a class="collapsed" data-toggle="collapse" href="#collapse-khalasuniPAinfo" aria-expanded="false" aria-controls="collapse-khalasuniPAinfo">
			                       Information
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-khalasuniPAinfo" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-khalasuniPAinfo">

                    <div class="card-body">

                      <h4 class="card-title">
                        Khalasuni Sanctuary
                        <span class="custom-down">
                          <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
                          <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                          </span>
                      </h4>
                      <div id="printcontent">
                      <div class="row">
                        <div class="col-md-8">
                          <div class="table-responsive">
                            <table class="table table-sm table-bordered">
                              <tbody>
                                <tr>
                                  <td><strong>Forest Division : </strong></td>
                                  <td>Bamra (Wildlife) Division</td>
                                </tr>
                                <tr>
                                  <td><strong>District : </strong></td>
                                  <td>Sambalpur</td>
                                </tr>
                                <tr>
                                  <td><strong>Date of Notification : </strong></td>
                                  <td>07-01-1982</td>
                                </tr>
                                <tr>
                                  <td><strong>Notified Area : </strong></td>
                                  <td>116 sq.km</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-md-2 video-box no-print">
                          <a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/sP5BqCvUwSA" data-target="#myModal">
                            <h4>Khalasuni Sanctuary</h4>
                            <div class="overlay">
                              <span class="icon">
                                <i class="fa fa-play-circle"></i>
                              </span>
                            </div>
                          </a>
                        </div>
                        <div class="col-md-2">
                          <img src="assets/images/khal-pic.jpg" class="img-fluid float-right img-inner" alt="image">
                        </div>
                      </div>
                      <p>
                        Khalasuni Wildlife Sanctuary with its panoramic greenery and serene landscape, cascading waterfalls, and variety of flora and fauna, is one of the most attractive places in Odisha. It is situated from N.H-42 at a distance of 60.0 Km in North-west of Rairakhol and 50.0 Km in North-east of Sambalpur. It is also accessible from Deogarh side, which is about 80.0 Kms. With the adjoining Badrama Wildlife Sanctuary, this sanctuary offers a good habitat with carrying capacity of a large number of elephants.The Sanctuary harbours a rich treasure of flora and fauna. The meandering footpaths take the visitor through dense forests and provides a thrill of wilderness to anyone who ventures this far. The sighting of wild animals in untouched forests adds to this thrill.
                      </p>
                      <h4 class="card-title">Quick Facts</h4>
                      <ul class="custom-list">
                        <li>The Khalsuni-Satkosia corridor is also an important elephant corridor in the state. The Sanctuary is a part of Sambalpur Elephant Reserve also.</li>
                        <li>Khalasuni Wildlife Sanctuary comprises of mostly Sal dominated forests along with its associates and miscellaneous species.</li>
                        <li>This Sanctuary is a dwelling place of the carnivorous animals such as Leopard, Jungle Cat, Wolf, Jackal, Civet, Small Indian mongoose reflecting the beauty nature of the Sanctuary .</li>
                      </ul>
                      <h4 class="card-title">How to reach</h4>
                      <p class="address-inner">
                        <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>This Sanctuary is 340.0 Km away from State Capital Bhubaneswar
                      </p>
                      <p class="address-inner">
        <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong>Nearest railway stations are Sambalpur and Rairakhol.
      </p>
      <p class="address-inner">
        <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>Nearest airport is in Bhubaneswar.
                      </p>
                      <h4 class="card-title">Facilities available</h4>
                      <ul class="custom-list">
                        <li>The Sanctuary offers systematically planned trekking packages and one day nature camps</li>
                        <li>The tourists get recreational and educational facilities during their visit at the Deer Park at Kuanria.</li>
                        <li>Watch towers from where one can watch the free movement of wild lives during evening hours.</li>
                      </ul>
                      <h4 class="card-title">Salient Detail</h4>
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td width="15%"><strong>Geographic Extent : </strong></td>
                              <td width="85%">Longitude 84° 15' to 84° 35'(East) and Latitude 21° 15' to 84° 25'(North)</td>
                            </tr>
                            <tr>
                              <td><strong>Climate : </strong></td>
                              <td>Moderate</td>
                            </tr>
                            <tr>
                              <td><strong>Villages :</strong></td>
                              <td>10 villages</td>
                            </tr>
                            <tr>
                              <td><strong>Contact officer : </strong></td>
                              <td>
                                <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest Officer <br>
                                <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Bamra Wildlife Division<br>
                                <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06642-229211<br>
                                <span class="address-inner"><i class="fa fa-envelope">&nbsp;</i></span>dfobmbwl004@gmail.com
                              </td>
                            </tr>
                            <tr>
                              <td><strong>Best time to visit : </strong></td>
                              <td>October to April</td>
                            </tr>
                            <tr>
                              <td><strong>Accommodation : </strong></td>
                              <td>Inside the Sanctuary there is no accomodation facility.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>
                    </div>

            </div>
			            </div>
			        </div>

			    </div>

			</div>
		</div>
	</div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video"  allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
