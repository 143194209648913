import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {Observable, Subject, throwError} from "rxjs/index";
import { BehaviorSubject } from 'rxjs';
import {ApiService} from "../service/api.service";
import{CommonelementComponent} from "src/app/commonelement/commonelement.component"
import { NotificationService } from '../service/notification.service'
import * as CryptoJS from 'crypto-js';

declare var jQuery: any;
const secretKey='1234567891113456';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  public loggedIn = new BehaviorSubject<boolean>(false);
  public token: string;
  isWebReporting: boolean=false;
  constructor(private router: Router, private apiService: ApiService,private notifyService:NotificationService, private sweetalert: CommonelementComponent,private location:Location) {
   // console.log('Auth Service');
    const userData = sessionStorage.getItem('user');
    //console.log(userData);
    if (userData) {
     // console.log('Logged in from memory');
      const user = JSON.parse(userData);
      this.token = user.token;
     // console.log(this.token);
      this.apiService.setLoggedIn(true, this.token);
      this.loggedIn.next(true);
    }
  }
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }
  public getToken(): string {
    return sessionStorage.getItem('token');
  }

//   login2(user: User): any {
//     console.log(user);
//     const headers = new HttpHeaders({
//       'Content-Type': 'application/json',
//     });
//    return this.http.post<Login>(this.baseUrl+'api/v1/auth/signin', user,{ headers: headers } );
//  }

//  login(user): Observable<any>  {

//    try{
//   if (user.login_id !== '' && user.password !== '' ) {
//     return this.apiService.request('POST', 'api/v1/auth/signin', {
//       login_id: user.login_id,
//       password: user.password
//     })
//     // .subscribe((response: any) => {
//     //   //console.log(response);
//     //   if (response.username!==undefined  && response.accessToken !== undefined) {
//     //     this.token = response.accessToken;
//     //     this.apiService.setLoggedIn(true, this.token);
//     //     this.loggedIn.next(true);
//     //     const userData = {
//     //       userid:response.id,
//     //       username:response.username,
//     //       email:response.email,
//     //       roles:response.roles,
//     //       roleId:response.roleId,
//     //       juridictionId:response.juridictionId,
//     //       circleId:response.circleId,
//     //       divisionId: response.divisionId,
//     //       rangeId:response.rangeId,
//     //       sectionId:response.sectionId,
//     //       beatId: response.beatId,
//     //       token: this.token,
//     //     };
//     //     sessionStorage.setItem('user', JSON.stringify(userData));
//     //     sessionStorage.setItem('token', this.token);
//     //     //this.location.replaceState('/');
//     //     //this.router.navigateByUrl('/dashboard');
//     //     //this.router.navigate(['dashboarduser']);
//     //    // window.location.href = 'dashboarduser';
//     //     window.location.href = 'dashboard';
//     //   }
//     // }
//     // ,(error) => {
//     //     const errors = error;

//     //     console.log(errors);
//     //     return throwError(errors);
//     // }
//     // );
//   }
//   }catch(err){
//     this.logout();
//   }
// }
login(user): Observable<any> {
  try {
    if (user.login_id !== '' && user.password !== '') {
      const encryptedLoginId = this.encryptData(user.login_id,secretKey);
      const encryptedPassword = this.encryptData(user.password,secretKey);

      return this.apiService.request('POST', 'api/v1/auth/signin', {
        login_id: encryptedLoginId,
        password: encryptedPassword
      });
    }
  } catch (err) {
    this.logout();
  }
}

// Encryption function using AES
 encryptData(plainText: string, secretKey: string): string {
  // Generate a random 16-byte IV
  const iv = CryptoJS.lib.WordArray.random(16);

  // Encrypt the plainText using AES
  const encrypted = CryptoJS.AES.encrypt(plainText, CryptoJS.enc.Utf8.parse(secretKey), {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  // Concatenate IV and encrypted data
  const encryptedDataWithIV = iv.concat(encrypted.ciphertext);

  // Base64 encode the result
  return encryptedDataWithIV.toString(CryptoJS.enc.Base64);
}
  // Logout function
  logout() {
    this.apiService.setLoggedIn(false);
    delete this.token;
    this.loggedIn.next(false);
    sessionStorage.clear();
    this.router.navigate(['/']);
    window.location.reload();
  }
  setWebReportingAccess(value:boolean){
    this.isWebReporting=value;
  }
  isWebRoportingAccess(){
    return this.isWebReporting;
  }
}


