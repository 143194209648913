<!-- Modal login   -->
<!-- <div class="loader-base"  *ngIf="loader"  #loaderdiv ><div class="loader"  ></div></div> -->
<div class="modal fade modal-custom" id="modalLogin" #modalLogin tabindex="-1" role="dialog"
	aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body">
				<div class="row">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeBtn click="closebtnClick()">
						<span aria-hidden="true">&times;</span>
					</button>
					<div class="col-md-4">
						<div class="bg-login"></div>
					</div>
					<div class="col-md-8 login-tab">
						<div class="header-logo mt-4 mb-4">
							<img src="assets/images/odishalogo.png" class="logo" alt="logo">
							<h1>
								Odisha Wildlife Organization <span class="text-dark">(<span
										class="text-high">i</span>WLMS)</span>
								<br>
								<small>Official Website of PCCF (Wildlife) & Chief Wildlife Warden, Odisha</small>
							</h1>
						</div>
						<h2 class="text-center">Authority Login</h2>

						<ul id="tabs" class="nav nav-tabs mr-3" role="tablist">
							<li class="nav-item">
								<a id="tab-A" href="#pane-A" class="nav-link active" data-toggle="tab" role="tab"><i
										class="fa fa-user">&nbsp;</i>Username</a>
							</li>
							<!-- <li class="nav-item">
								<a id="tab-B" href="#pane-B" class="nav-link" data-toggle="tab" role="tab"><i
										class="fa fa-mobile">&nbsp;</i>Mobile</a>
							</li> -->
						</ul>

						<div id="accordion" class="tab-content mr-3" role="tablist">
							<div id="pane-A" class="card tab-pane fade show active" role="tabpanel"
								aria-labelledby="tab-A">
								<div class="card-header" role="tab" id="heading-A">
									<h5 class="mb-0">
										<a data-toggle="collapse" href="#collapse-A" aria-expanded="true"
											aria-controls="collapse-A">
											Username
										</a>
									</h5>
								</div>
								<div id="collapse-A" class="collapse show" data-parent="#accordion" role="tabpanel"
									aria-labelledby="heading-A">
									<div class="card-body">
										<form class="mt-3" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
											<div class="form-group ml-2 mr-2">
												<div class="inner-addon left-addon">
													<i class="fa fa-user"></i>
													<input type="text" class="form-control" placeholder="Username"
														formControlName="login_id" id="login_id" onCopy="false"
														onDrag="false" onCut="false" onDrop="false"
														onPaste="false" autocomplete=off />

												</div>
											</div>
											<div class="form-group ml-2 mr-2">
												<div class="inner-addon left-addon">
													<i class="fa fa-lock"></i>

												</div>
												<div class="input-group" id="show_hide_password">
													<input type="password" class="form-control eye-pad"
														placeholder="Password" formControlName="password" id="password"
														onCopy="false" onDrag="false" onCut="false"
														onDrop="false" onPaste="false" autocomplete=off>
													<div class="input-group-addon pull-right">
														<a href=""><i class="fa fa-eye-slash"
																aria-hidden="true"></i></a>
													</div>
												</div>
											</div>
											<div class="form-group row ml-1 mr-1">
												<div id="captcha" class="col-md-5 img-fluid captcha">
													<!-- <img src="assets/images/captcha.jpg" class="img-fluid captcha"> -->
												</div>
												<div class="col-md-1">
													<!-- <button value="Refresh" onclick="return createCaptcha();"><i class="fa fa-refresh mt-2 fa-captcha"></i></button> -->
													<a id="refres" class="" title="reCAPTCHA"
														(click)="createCaptcha()"><i
															class="fa fa-refresh mt-2 fa-captcha"></i></a>

												</div>
												<div class="col-md-6">
													<input type="text" class="form-control"
														formControlName="captchaValue" placeholder="Enter Captcha">
												</div>
											</div>
											<div class="form-group text-right">
												<a href="#" class="text-danger" data-toggle="modal" data-dismiss="modal"
													data-target="#modalPassword"><i
														class="fa fa-question-circle">&nbsp;</i>Forgot Password</a>
											</div>
											<div class="form-group ml-4 text-center">
												<div class="spinner text-center">
													<div *ngIf="loader" class="spinner-border" role="status">
														<span class="sr-only">Loading...</span>
													</div>
												</div>
												<button *ngIf="!loader" type="submit" class="login"
													[disabled]="loginForm.invalid || loader">Login</button>
											</div>
											<div class="errorLogin p-2" *ngIf="loginErrorData ">
												{{loginErrorData}}
											</div>
										</form>
									</div>
								</div>
							</div>

							<div id="pane-B" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-B">
								<div class="card-header" role="tab" id="heading-B">
									<h5 class="mb-0">
										<a class="collapsed" data-toggle="collapse" href="#collapse-B"
											aria-expanded="false" aria-controls="collapse-B">
											Mobile
										</a>
									</h5>
								</div>
								<div id="collapse-B" class="collapse" data-parent="#accordion" role="tabpanel"
									aria-labelledby="heading-B">
									<div class="card-body">
										<form class="mt-3" [formGroup]="loginFormMobile">
											<div class="form-group ml-2 mr-2">
												<div class="inner-addon left-addon">
													<i class="fa fa-mobile"></i>
													<input type="text" class="form-control"
														placeholder="Enter Mobile No." id="mobileno"
														formControlName="mobileno" autocomplete=off />
												</div>
											</div>
											<div class="row form-group ml-2 mr-2" *ngIf="showOtp">
												<div class="col-md-8 inner-addon left-addon">
													<i class="fa fa-shield"></i>
													<input type="text" class="form-control" placeholder="Enter OTP"
														id="otp" formControlName="otp" autocomplete=off>
													<p class="text-success mt-1"><small>OTP has send successfully to
															your regd. mobile no.</small></p>
												</div>
												<div class="col-md-4 mt-2">
													<a href="javascript: void(0);" (click)="generateotp()"><i
															class="fa fa-refresh">&nbsp;</i>Resend</a>
												</div>
											</div>
											<div class="form-group ml-4 text-center" *ngIf="generatebtn">
												<button type="submit" class="login" (click)="generateotp()">Generate
													OTP</button>
											</div>
											<div class="form-group ml-4 text-center" *ngIf="verifybtn">
												<div class="spinner text-center">
													<div *ngIf="loader" class="spinner-border" role="status">
														<span class="sr-only">Loading...</span>
													</div>
												</div>
												<button *ngIf="!loader" type="submit" class="login"
													(click)="loginWithOtp()">Verify</button>
											</div>
											<p class="errorLogin" *ngIf="loginErrorData ">
												<small>{{loginErrorData}}</small>
											</p>
											<p class="successLogin" *ngIf="loginSuccessData ">
												<small>{{loginSuccessData}}</small>
											</p>
										</form>
									</div>
								</div>
							</div>

						</div>


					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Modala password -->
<div class="modal fade modal-custom" id="modalPassword" tabindex="-1" role="dialog"
	aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
	<div class="modal-dialog modal-lg modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-body">
				<div class="row">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
					<div class="col-md-4">
						<div class="bg-login"></div>
					</div>
					<div class="col-md-7">
						<div class="header-logo mt-4 mb-4">
							<img src="assets/images/odishalogo.png" class="logo" alt="logo">
							<h1>
								Odisha Wildlife Organization
								<br>
								<small>Official Website of PCCF (Wildlife) & Chief Wildlife Warden, Odisha</small>
							</h1>
						</div>
						<h2 class="text-center">Forgot Password</h2>
						<form [formGroup]="forgotpasswordForm">
							<div class="form-group ml-2 mr-2">
								<div class="inner-addon left-addon">
									<i class="fa fa-mobile"></i>
									<input type="text" class="form-control" placeholder="Enter Mobile No." id="mob"
										formControlName="mobilenumber" [(ngModel)]="mobno" autocomplete="offlass"
										[ngClass]="{ 'is-invalid': forgotpasswordForm.controls['mobilenumber'].invalid && (forgotpasswordForm.controls['mobilenumber'].dirty || forgotpasswordForm.controls['mobilenumber'].touched) }" />
									<div *ngIf="forgotpasswordForm.controls['mobilenumber'].invalid && (forgotpasswordForm.controls['mobilenumber'].dirty || forgotpasswordForm.controls['mobilenumber'].touched)"
										class="text-danger mt-1">
										<small *ngIf="forgotpasswordForm.controls['mobilenumber'].errors.required">
											Mobile Number is required!!.
										</small>
										<small *ngIf="forgotpasswordForm.controls['mobilenumber'].errors.pattern">
											Enter a Valid Mobile Number.
										</small>
									</div>
								</div>
							</div>
							<div class="row form-group ml-2 mr-2" *ngIf="showOtpforgotpw">
								<div class="col-md-8 inner-addon left-addon">
									<i class="fa fa-shield"></i>
									<input type="text" class="form-control" placeholder="Enter OTP" id="otpforgotpwd"
										formControlName="otpforgot" autocomplete=off>

									<p class="text-success mt-1" *ngIf="isOTPsend"><small>OTP has send successfully to
											your regd. mobile no.</small></p>

								</div>
								<div class="col-md-4 mt-2">
									<a href="javascript: void(0);" *ngIf="isResendOtp"
										(click)="generateotpforgotPwd()"><i class="fa fa-refresh">&nbsp;</i>Resend</a>
									<span class="text-success" *ngIf="isVerified"><i
											class="fa fa-check">&nbsp;</i>Verified</span>
								</div>
							</div>
							<div *ngIf="showPasswdbox">
								<div class="form-group ml-2 mr-2">
									<div class="inner-addon left-addon">
										<i class="fa fa-lock"></i>
										<input type="password" class="form-control"
											[ngClass]="{ 'is-invalid': forgotpasswordForm.controls['password'].invalid && (forgotpasswordForm.controls['password'].dirty || forgotpasswordForm.controls['password'].touched) }"
											formControlName="password" id="pwd" placeholder="Password"
											autocomplete="new-password">
										<div *ngIf="forgotpasswordForm.controls['password'].invalid && (forgotpasswordForm.controls['password'].dirty || forgotpasswordForm.controls['password'].touched)"
											class="text-danger mt-1">
											<small *ngIf="forgotpasswordForm.controls['password'].errors.required">
												Password is required.
											</small>
											<small *ngIf="forgotpasswordForm.controls['password'].errors.minlength">
												Password must be 8 characters.
											</small>
										</div>
									</div>
								</div>
								<div class="form-group ml-2 mr-2">
									<div class="inner-addon left-addon">
										<i class="fa fa-lock"></i>
										<input type="password" class="form-control"
											[ngClass]="{ 'is-invalid': forgotpasswordForm.controls['cpassword'].invalid && (forgotpasswordForm.controls['cpassword'].dirty || forgotpasswordForm.controls['cpassword'].touched) }"
											formControlName="cpassword" id="cpwd" placeholder="Confirm Password">
										<div *ngIf="forgotpasswordForm.controls['cpassword'].invalid && (forgotpasswordForm.controls['cpassword'].dirty || forgotpasswordForm.controls['cpassword'].touched)"
											class="text-danger mt-1">
											<small *ngIf="forgotpasswordForm.controls['cpassword'].errors.required">
												Confirm Password is required.
											</small>

											<small
												*ngIf="forgotpasswordForm.controls['cpassword'].errors.confirmedValidator">
												Password and Confirm Password must be match.
											</small>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group ml-4 text-center" *ngIf="generatebtnforgotPwd">
								<div class="spinner text-center">
									<div *ngIf="loader" class="spinner-border" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
								<button *ngIf="!loader" type="submit" class="login"
									(click)="generateotpforgotPwd()">Generate OTP</button>
							</div>
							<div class="form-group ml-4 text-center" *ngIf="verifybtnforgotPWd ">
								<div class="spinner text-center">
									<div *ngIf="loader" class="spinner-border" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
								<button *ngIf="!loader" type="submit" class="login"
									(click)="verifyOtp()">Verify</button>
							</div>
							<div class="form-group ml-4 text-center" *ngIf="submitbtnforgotPWd ">
								<div class="spinner text-center">
									<div *ngIf="loader" class="spinner-border" role="status">
										<span class="sr-only">Loading...</span>
									</div>
								</div>
								<button *ngIf="!loader" type="submit" class="login"
									(click)="resetPwdwithOtp(forgotpasswordForm.value)">Submit</button>
							</div>

							<div class="form-group text-right">
								<a href="#" class="text-danger" data-toggle="modal" data-dismiss="modal"
									data-target="#modalLogin"><i class="fa fa-chevron-circle-left">&nbsp;</i>Back to
									Login</a>
							</div>

							<!-- <div class="form-group ml-4 text-center">
				  	<button type="submit" class="login">Submit</button>
				  </div> -->
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
