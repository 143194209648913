<app-header *ngIf="isDashboard"></app-header>
<app-menu  *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
			        <li class="nav-item">
			            <a id="tab-kotgarhPAmap" href="#pane-kotgarhPAmap" class="nav-link active" data-toggle="tab" role="tab">Map</a>
			        </li>
			        <li class="nav-item" >
			            <a id="tab-kotgarhPAinfo" href="#pane-kotgarhPAinfo" class="nav-link" data-toggle="tab" role="tab">Information</a>
			        </li>
			    </ul>

			    <div id="accordion" class="tab-content" role="tablist">
			        <div id="pane-kotgarhPAmap" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-kotgarhPAmap">
			            <div class="card-header" role="tab" id="heading-kotgarhPAmap">
			                <h5 class="mb-0">
			                    <a data-toggle="collapse" href="#collapse-kotgarhPAmap" aria-expanded="true" aria-controls="collapse-kotgarhPAmap">
                            Map
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-kotgarhPAmap" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-kotgarhPAmap">
                    <div class="card-body">
                           <div class="map" id="mapkotgarh">
                             <div class="gle-icon">
                              <a href="javascript:void(0)">
                                <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid" alt="Street View" title="Street View">
                            </a>
                            <a href="javascript:void(0)">
                                <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()' alt="Satellite View" title="Satellite View">
                            </a>
                            <a href="javascript:void(0)">
                                <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View" title="Topo View">
                            </a>
                                    </div>
                           </div>
                    </div>
			            </div>
			        </div>

			        <div id="pane-kotgarhPAinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-kotgarhPAinfo">
			            <div class="card-header" role="tab" id="heading-kotgarhPAinfo">
			                <h5 class="mb-0">
			                    <a class="collapsed" data-toggle="collapse" href="#collapse-kotgarhPAinfo" aria-expanded="false" aria-controls="collapse-kotgarhPAinfo">
			                       Information
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-kotgarhPAinfo" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-kotgarhPAinfo">

                    <div class="card-body">

                      <h4 class="card-title">
                        Kotagarh Sanctuary
                        <span class="custom-down">
                          <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
                          <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                          </span>
                      </h4>
                      <div id="printcontent">
                      <div class="row">
                        <div class="col-md-8">
                          <div class="table-responsive">
                            <table class="table table-sm table-bordered">
                              <tbody>
                                <tr>
                                  <td><strong>Forest Division : </strong></td>
                                  <td>Baliguda (T) Forest Division</td>
                                </tr>
                                <tr>
                                  <td><strong>District : </strong></td>
                                  <td>Phulbani</td>
                                </tr>
                                <tr>
                                  <td><strong>Date of Notification : </strong></td>
                                  <td>03-12-1981</td>
                                </tr>
                                <tr>
                                  <td><strong>Notified Area : </strong></td>
                                  <td>399.05sq.km</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-md-2 video-box no-print">
                          <a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/0dnMDqNviMk" data-target="#myModal">
                            <h4>Kotagarh Sanctuary</h4>
                            <div class="overlay">
                              <span class="icon">
                                <i class="fa fa-play-circle"></i>
                              </span>
                            </div>
                          </a>
                        </div>
                        <div class="col-md-2">
                          <img src="assets/images/kot-pic.jpg" class="img-fluid float-right img-inner" alt="image">
                        </div>
                      </div>
                      <p>
                        Kotagarh wildlife sanctuary located in the Baliguda subdivision of Kandhamal district is rich in biodiversity.This wild Life Sanctuary with an extensive area of 399.5 Sq.Kms is well Spreaded over the part jurisdiction of Kotagarh and Tumudibandha Range under Kotagarh, Tumudibandha and Daringabadi C.D.Block of Balliguda Civil Sub-Division.b)The main vegetation of the place consists of dense deciduous forests with grasslands.The sanctuary is home to a large number of mammals and reptiles . Major wildlife species are elephant, gaur, sambar, spotted deer, peafowl, red jungle fowl and a variety of reptiles.
                      </p>
                      <h4 class="card-title">Quick Facts</h4>
                      <ul class="custom-list">
                        <li>The dense lush green forests with sylvan and serene picturesque beauty is the major attraction.</li>
                        <li>The forests bunglow at Belghar attracts many nature loving visitors.</li>
                        <li>The kotagarh Sanctuary with the serene ambience is an ideal place for tourist.</li>
                      </ul>
                      <h4 class="card-title">How to reach</h4>
                      <p class="address-inner">
                        <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>Baliguda is connected with Bhubaneswar, Berhampur, and other important places of the state by regular public bus service.
                      </p>
                      <p class="address-inner">
        <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong>Nearest Rail Head is at Berhampur on S.E. Railway 180 KMs from Muniguda.
      </p>
      <p class="address-inner">
        <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>Nearest Air Port is at Bhubaneswar which is 291 KMs from Balliguda
                      </p>
                      <h4 class="card-title">Facilities available</h4>
                      <ul class="custom-list">
                        <li>No Facility Available</li>
                      </ul>
                      <h4 class="card-title">Salient Detail</h4>
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td width="15%"><strong>Geographic Extent : </strong></td>
                              <td width="85%">Longitude 83° 36' to 84° 2'(East) and Latitude 19° 57' to 19° 43'(North)</td>
                            </tr>
                            <tr>
                              <td><strong>Climate : </strong></td>
                              <td>Moderate</td>
                            </tr>
                            <tr>
                              <td><strong>Villages :</strong></td>
                              <td>65 villages</td>
                            </tr>
                            <tr>
                              <td><strong>Contact officer : </strong></td>
                              <td>
                                <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest Officer<br>
                                <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Baliguda Division, Baliguda, Dist, Phulbani<br>
                              </td>
                            </tr>
                            <tr>
                              <td><strong>Best time to visit : </strong></td>
                              <td>October to April</td>
                            </tr>
                            <tr>
                              <td><strong>Accommodation : </strong></td>
                              <td>Rest house at Belgarh.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>
                    </div>

            </div>
			            </div>
			        </div>

			    </div>

			</div>
		</div>
	</div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video"  allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
