import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { NotificationService } from '../service/notification.service';
declare var jQuery: any;
declare var SimpleMDE: any;
declare var FileUploadWithPreview: any;

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.css']
})
export class CmsComponent implements OnInit,AfterViewInit {
  public loader: boolean = false;
  newsContent: string;
  newsForm: FormGroup;
  newContent: string;
  newsEditor: any;
  newContentEditor: any;
  publicationForm: FormGroup
  selectedPubImgFiles: any;
  selectedPubPdfFiles: any;
  tenderForm: FormGroup;
  galleryForm: FormGroup;
  selectedGalImgFiles: any;
  type: string;
  typeList: any;
  newGtypeForm: FormGroup;
  galleryImgPanel: any;

  @ViewChild('fd') sdate: ElementRef;
  @ViewChild('fdNotice') noticeSdate: ElementRef;
  @ViewChild('fdNotice_valid') noticeVdate: ElementRef;

  @ViewChild('NewsUpdateEle') NewsUpdateEle: ElementRef;
  @ViewChild('NewContent') NewContent: ElementRef;
  @ViewChild('Publication') Publication: ElementRef;
  @ViewChild('Gallery') Gallery: ElementRef;
  @ViewChild('Tender') Tender: ElementRef;
  @ViewChild('Notice') Notice: ElementRef;

  @ViewChild('typeselect') typeselect: ElementRef;


  selectedTenderPdfFiles: any;
  noticeForm: FormGroup;
  selectedNoticePdfFiles: any;

  constructor(private formBuilder: FormBuilder,  private router: Router, private apiService: ApiService, private notifyService: NotificationService) {
  }
  ngAfterViewInit(){

    const userdata = sessionStorage.getItem('user');
    if (userdata != null) {
       const user = JSON.parse(userdata);
      if(user.roleId==2){
        this.NewsUpdateEle.nativeElement.remove();
        this.NewContent.nativeElement.remove();
        this.Publication.nativeElement.remove();
        this.Notice.nativeElement.remove();
      }
    }
  }
  ngOnInit(): void {
    this.type = "0";
    this.newsForm = this.formBuilder.group({
      news: []
    });
    this.publicationForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      publicationThumbLineimg: ['', [Validators.required]],
      publicationPdfFile: ['', [Validators.required]]
    });
    this.galleryForm = this.formBuilder.group({
      newType: [''],
      type: [''],
      img: ['']
    });
    this.tenderForm = this.formBuilder.group({
      // year: ['',[Validators.required]],
      // month: ['',[Validators.required]],
      tanderno: ['', [Validators.required]],
      fromdate: [''],
      tenderfile: ['', [Validators.required]],
      title: ['', [Validators.required]]
    });
    this.noticeForm = this.formBuilder.group({
      noticeNumber: ['', [Validators.required]],
      noticeValidDate: [''],
      noticefile: ['', [Validators.required]],
      noticeName: ['', [Validators.required]],
      noticeDate: ['']
    });


    this.newGtypeForm = this.formBuilder.group({
      type: ['', [Validators.required]]
    });

    var ds = this;
    var render;
    (function ($) {

      // Basic CK Editor
      //News Editor

      var newsEditor = new SimpleMDE({
        element: document.getElementById("news"),
        spellChecker: false,
      });
      ds.newsEditor = newsEditor;
      newsEditor.codemirror.on("change", function () {
        var testPlain = newsEditor.value(),
          testMarkdown = newsEditor.markdown(testPlain);
        ds.newsContent = testMarkdown;
      });
      //New Content Editor
      var newContentEditor = new SimpleMDE({
        element: document.getElementById("newContent"),
        spellChecker: false,
      });
      ds.newContentEditor = newContentEditor;
      newContentEditor.codemirror.on("change", function () {
        var testPlain = newContentEditor.value(),
          testMarkdown = newContentEditor.markdown(testPlain);
        ds.newContent = testMarkdown;
      });


      var rowIdx = 0;

      // Multiple File Upload
      //var firstUpload = new FileUploadWithPreview('myFirstImage')
      ds.galleryImgPanel = new FileUploadWithPreview('myFirstImage');
      // jQuery button click event to add a row.
      $('#addBtn').on('click', function () {

        // Adding a row inside the tbody.
        $('#tbody').append(`<tr id="R${++rowIdx}">
		          <td class="row-index">
		          	<label>Title</label>
					<input class="form-control" type="text" placeholder="Title" />
		          </td>
		          <td class="row-index">
		          	<label>Upload Thumbnail Image</label>
					<input class="form-control" type="file" placeholder="Upload Thumbnail Image" />
		          </td>
		          <td class="row-index">
		          	<label>Upload PDF</label>
					<input class="form-control" type="file" placeholder="Upload PDF" />
		          </td>
		           <td class="">
		            <button class="btn btn-sm btn-danger custom-btn-top remove"
		                type="button"><i class="fa fa-trash"></i></button>
		            </td>
		           </tr>`);
      });
      $('#tbody').on('click', '.remove', function () {

        // Getting all the rows next to the
        // row containing the clicked button
        var child = $(this).closest('tr').nextAll();

        // Iterating across all the rows
        // obtained to change the index
        child.each(function () {

          // Getting <tr> id.
          var id = $(this).attr('id');

          // Getting the <p> inside the .row-index class.
          var idx = $(this).children('.row-index').children('p');

          // Gets the row number from <tr> id.
          var dig = parseInt(id.substring(1));

          // Modifying row index.
          idx.html(`Row ${dig - 1}`);

          // Modifying row id.
          $(this).attr('id', `R${dig - 1}`);
        });

        // Removing the current row.
        $(this).closest('tr').remove();

        // Decreasing the total number of rows by 1.
        rowIdx--;
      });

		$("#datepicker").datepicker({
        	autoclose: true,
        	todayHighlight: true
	  	}).datepicker('update', new Date());
      $("#datepicker1").datepicker({
        autoclose: true,
        todayHighlight: true
    }).datepicker('update', new Date());
    $("#datepicker2").datepicker({
      autoclose: true,
      todayHighlight: true
  }).datepicker('update', new Date());

    })(jQuery);
    this.getGalleryType();
  }

  getGalleryType() {
    return this.apiService.request('GET', 'api/v1/cms/getActiveGalleryType').subscribe((data: any) => {
      if (data) {
        this.typeList = data;
      }
    });
  }
  addNews() {
    const userdata = sessionStorage.getItem('user');
    if (userdata != null) {
      const user = JSON.parse(userdata);
      if (user != null) {
        if (this.newsContent) {
          this.newsContent = this.newsContent.replace("<p>", '').replace("</p>", '');
          this.apiService.request('POST', 'api/v1/cms/addNews', {
            userid: user.userid,
            content: this.newsContent
          }).subscribe((response: any) => {
            if (response.message == "success") {
              this.notifyService.showSuccess("News Added Successfully", "Success");
              this.newsEditor.value("");
            }
          },err=>{
            this.loader = false;
          });
        } else {
          this.notifyService.showError("Fill Something", "Error");
        }
      }
    } else {
      this.router.navigate(['']);
      return;
    }
  }
  addNewContent() {
    const userdata = sessionStorage.getItem('user');
    if (userdata != null) {
      const user = JSON.parse(userdata);
      if (user != null) {
        if (this.newContent) {
          this.newContent = this.newContent.replace("<p>", '').replace("</p>", '');
          this.apiService.request('POST', 'api/v1/cms/addNewContent', {
            userId: user.userid,
            content: this.newContent
          }).subscribe((response: any) => {
            if (response.message == "success") {
              this.notifyService.showSuccess("New Content Added Successfully", "Success");
              this.newContentEditor.value("");
            }
          },err=>{
            this.loader = false;
          });
        } else {
          this.notifyService.showError("Fill Something", "Error");
        }
      }
    } else {
      this.router.navigate(['']);
      return;
    }
  }
  onSelectPubThumbnailFile(event) {
    const file = event.target.files.item(0)
    //console.log(file)
    //console.log(event)
    if (file.type.match('image.*')) {
      this.selectedPubImgFiles = file;
    } else {
      this.notifyService.showError("invalid format!.Only images are supported.", "Error");
      this.publicationForm.controls.publicationThumbLineimg.setValue("");
    }
    if (event.length === 0)
      return;
  }
  OnSelectPubPdfFile(event) {
    const file = event.target.files.item(0)
    //console.log(file)
    //console.log(event)
    if (file.type.match('pdf.*')) {
      this.selectedPubPdfFiles = file;
    } else {
      this.notifyService.showError("invalid format!.Only pdf is supported.", "Error");
      this.publicationForm.controls.publicationPdfFile.setValue("");
    }
    if (event.length === 0)
      return;
  }
  addPublication() {
    try {
      this.loader = true;
      const userdata = sessionStorage.getItem('user');
      if (userdata != null) {
        const user = JSON.parse(userdata);
        let formdata: FormData = new FormData();
        formdata.append('file', this.selectedPubImgFiles);
        formdata.append('pdfFile', this.selectedPubPdfFiles);
        this.apiService.request('POST', 'api/v1/cms/addPublication?publicationTitle=' + this.publicationForm.value.title + '&userid=' + user.userid + '&type=publication', formdata).subscribe((response: any) => {

          if (response.message == "success") {
            this.notifyService.showSuccess("New Publication Added Successfully", "Success");
            this.publicationForm.controls.title.setValue("");
            this.publicationForm.controls.publicationThumbLineimg.setValue("");
            this.publicationForm.controls.publicationPdfFile.setValue("");
            this.loader = false;
          } else if (response.message == "fail") {
            this.notifyService.showError("UnSuccessful", "Error");
            this.loader = false;
          }
        },(error: any) => {
          this.loader = false;
        }
        );
      } else {
        this.router.navigate(['']);
        return;
      }
    } catch {
      this.loader = false;
    }finally{
      this.loader = false;
    }
  }
  onSelectGalleryImg(event) {
    try{
    const file = event.target.files.item(0)
    //console.log(file)
    //console.log(event)
    if (file.type.match('image.*')) {
      this.selectedGalImgFiles = file;
    } else {
      this.notifyService.showError("invalid format!.Only images are supported.", "Error");
      this.galleryImgPanel.cachedFileArray;
      this.galleryImgPanel.clearPreviewPanel();
    }
    if (event.length === 0)
      return;
  }catch{
    this.loader = false;
  }finally{
    this.loader = false;
  }
}
  onSubmitNewGType() {
    const userdata = sessionStorage.getItem('user');
    if (userdata != null) {
      const user = JSON.parse(userdata);
      if (user != null) {
        //console.log(this.newGtypeForm.value.type)
        if (this.newGtypeForm.value.type != "") {
          this.apiService.request('POST', 'api/v1/cms/addGalleryType?gtype=' + this.newGtypeForm.value.type + '&userid=' + user.userid).subscribe((response: any) => {
            //console.log(response)
            if (response.message == "success") {
              this.notifyService.showSuccess("New Gallery Image Added Successfully", "Success");
            } else if (response.message == "exists") {
              this.notifyService.showError("Already Exists!!!", "Error");
            }
            else if (response.message == "fail") {
              this.notifyService.showError("UnSuccessful", "Error");
            }
          },err=>{
            this.loader = false;
          });
        } else {
          this.notifyService.showError("Required Field", "Error");
        }
      }
    } else {
      this.router.navigate(['']);
      return;
    }
  }
  addGalaryImg(value: any) {
    const userdata = sessionStorage.getItem('user');
    if (userdata != null) {
      const user = JSON.parse(userdata);
      if (user != null) {
        if(this.galleryForm.value.type!=0){
          let formdata: FormData = new FormData();
          formdata.append('file', this.selectedGalImgFiles);
          if(this.selectedGalImgFiles!=null){
            this.apiService.request('POST', 'api/v1/cms/addGallery?gtype=' + this.galleryForm.value.type + '&userid=' + user.userid + '&type=gallery', formdata).subscribe((response: any) => {
              if (response.message == "success") {
                this.notifyService.showSuccess("New Gallery Image Added Successfully", "Success");
                this.galleryImgPanel.cachedFileArray;
                this.galleryImgPanel.clearPreviewPanel();
              } else if (response.message == "fail") {
                this.notifyService.showError("UnSuccessful", "Error");
                this.galleryImgPanel.cachedFileArray;
                this.galleryImgPanel.clearPreviewPanel();
              }
            },(error: any) => {
              this.loader = false;
            });
          }else{
            this.notifyService.showError("Please Select Image.","Error");
            return false;
          }
        }else{
          this.notifyService.showError("Please Select Gallery Type.","Error");
            this.typeselect.nativeElement.focus();
            return false;
        }
      }
    } else {
      this.router.navigate(['']);
      return;
    }
  }
  formatDate(date) {
    let dateexct = date.split('-');
    var day = dateexct[0];
    var month = dateexct[1];
    var year = dateexct[2];
    var fullDay = year + '-' + month + '-' + day;
    return fullDay;
  }
  OnSelectTenderPdfFile(event) {
    const file = event.target.files.item(0)
    if (file.type.match('pdf.*')) {
      this.selectedTenderPdfFiles = file;
    } else {
      this.notifyService.showError("invalid format!.Only pdf is supported.", "Error");
      this.tenderForm.controls.tenderfile.setValue("");
    }
    if (event.length === 0)
      return;
  }
  addTender() {
    this.loader=true;
    const userdata = sessionStorage.getItem('user');
    if (userdata != null) {
      const user = JSON.parse(userdata);
      if (user != null) {
          let startDate = this.sdate.nativeElement.value;
          if (startDate != '') {
            startDate =startDate //this.formatDate(startDate);
          } else if (startDate == '') {
            this.notifyService.showError("Please Provide Tender Publish date","Error");
            this.sdate.nativeElement.focus();
            this.loader = false;
            return false;
          }
          if(this.selectedTenderPdfFiles!=null){
            if(this.tenderForm.value.tanderno!=""){
              if(this.tenderForm.value.title!=""){
                let formdata: FormData = new FormData();
                formdata.append('file', this.selectedTenderPdfFiles);
                this.apiService.request('POST', 'api/v1/cms/addTender?title=' + this.tenderForm.value.title + '&tenderNum=' + this.tenderForm.value.tanderno + '&publicationDate=' + startDate + '&userid=' + user.userid + '&type=tender', formdata).subscribe((response: any) => {
                  if (response.message == "success") {
                    this.notifyService.showSuccess("New Tender Added Successfully", "Success");
                    this.tenderForm.controls.tanderno.setValue("");
                    this.tenderForm.controls.title.setValue("");

                    this.tenderForm.controls.fromdate.setValue("");
                    this.tenderForm.controls.tenderfile.setValue("");

                    this.loader=false;
                  } else if (response.message == "failed") {
                    this.notifyService.showError("UnSuccessful", "Error");
                    this.loader=false;
                  }
                },err=>{
                  this.loader = false;
                });
              }else{
                this.notifyService.showError("Tender Title  Required.","Error");
                this.loader=false;
                return false;
              }
            }else{
              this.notifyService.showError("Tender Number Required.","Error");
              this.loader=false;
              return false;
            }
          }else{
            this.notifyService.showError("Tender File Required.","Error");
            this.loader=false;
            return false;
          }
       }
    } else {
      this.router.navigate(['']);
      return;
    }
  }
  OnSelectNoticePdfFile(event) {
    const file = event.target.files.item(0)
    if (file.type.match('pdf.*')) {
      this.selectedNoticePdfFiles = file;
    } else {
      this.notifyService.showError("invalid format!.Only pdf is supported.", "Error");
      this.noticeForm.controls.noticefile.setValue("");
    }
    if (event.length === 0)
      return;
}
  addNotice(){
    try{
    const userdata=sessionStorage.getItem('user');
    if(userdata!=null){
      const user=JSON.parse(userdata);
        if(user!=null){
          let startDate = this.noticeSdate.nativeElement.value;
          let validDate = this.noticeVdate.nativeElement.value;
          if(startDate==''){
            alert("Please Provide Notice date");
            this.noticeSdate.nativeElement.focus();
            this.loader=false;
            return false;
          }if(validDate==''){
            alert("Please Provide date");
            this.noticeVdate.nativeElement.focus();
            this.loader=false;
            return false;
          }
          if(startDate>validDate){
            alert("Please Notice  date should br greater than Notive Validation Date");
            this.noticeSdate.nativeElement.focus();
            this.loader=false;
            return false;
          }
          if(startDate!='' && validDate!=''){
            //validDate=this.formatDate(validDate);
            //startDate=this.formatDate(startDate);
          let formdata: FormData = new FormData();
          formdata.append('file', this.selectedNoticePdfFiles);
          this.apiService.request('POST', 'api/v1/cms/addNotice?noticeName='+this.noticeForm.value.noticeName+'&noticeNumber='+this.noticeForm.value.noticeNumber+'&noticeDate='+startDate+'&noticeValidDate='+validDate+'&userid='+user.userid+'&type=notice', formdata).subscribe((response: any) => {
                 //console.log(response)
              if(response.message=="success"){
                this.notifyService.showSuccess("New Notice Added Successfully","Success");
                this.noticeForm.controls.tanderno.setValue("");
                this.noticeForm.controls.fromdate.setValue("");
                this.noticeForm.controls.tenderfile.setValue("");
                this.noticeForm.controls.title.setValue("");
              }else if(response.message=="failed"){
                this.notifyService.showError("UnSuccessful","Error");
              }
          },err=>{
            this.loader = false;
          });
        }
        // if (startDate != '') {
        //   startDate = this.formatDate(startDate);
        // } else if (startDate == '') {
        //   alert("Please Provide date");
        //   this.noticeSdate.nativeElement.focus();
        //   this.loader = false;
        //   return false;
        // }
        // let formdata: FormData = new FormData();
        // formdata.append('file', this.selectedNoticePdfFiles);
        // this.apiService.request('POST', 'api/v1/cms/addNotice?noticeName=' + this.noticeForm.value.noticeName + '&noticeNumber=' + this.noticeForm.value.noticeNumber + '&noticeDate=' + startDate + '&noticeValidDate=' + validDate + '&userid=' + user.userid + '&type=notice', formdata).subscribe((response: any) => {

        // });
      }
    } else {
      this.router.navigate(['']);
      return;
    }
  }catch{
    this.loader=false;
  }finally{
    this.loader=false;
  }
  }
  }


