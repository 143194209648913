<div id="fp-nav" class="fp-show-active right" style="margin-top: 0px;">
    <ul>
        <li>
            <a [routerLink]="['/']"><span></span></a>
            <a [routerLink]="['/']" class="fp-tooltip right">Amazing Wildlife</a>
        </li>
        <li>
            <a [routerLink]="['/slidercarousel']"><span></span></a>
            <a [routerLink]="['/slidercarousel']" class="fp-tooltip right">Discover Wildlife Odisha</a>
        </li>
        <li>
            <a [routerLink]="['/homecontent']"><span></span></a>
            <a [routerLink]="['/homecontent']" class="fp-tooltip right">About Wildlife</a>
        </li>
        <li>
            <a [routerLink]="['/blogall']"><span></span></a>
            <a [routerLink]="['/blogall']" class="fp-tooltip right">Blog / Publication</a>
        </li>
        <li>
            <a [routerLink]="['/filtergallery']"><span></span></a>
            <a [routerLink]="['/filtergallery']" class="fp-tooltip right">Gallery</a>
        </li>
        <li>
            <a [routerLink]="['/footerlanding']" class="active"><span></span></a>
            <a [routerLink]="['/footerlanding']" class="fp-tooltip right active">Quick Links</a>
        </li>
    </ul>
</div>

<app-login></app-login>

<footer class="main-footer">
    <div class="widgets-section">
        <div class="container">
            <div class="row">
                <!--Big Column-->
                <div class="big-column col-md-6 col-sm-12 col-xs-12">
                    <div class="row clearfix">

                        <!--Footer Column-->
                        <div class="col-md-6 col-sm-6 col-xs-12">

                            <div class="footer-widget about-column">
                                <div class="section-title">
                                    <h4>Quick Contact</h4>
                                </div>
                                <ul class="contact-info">
                                    <li><span class="fa fa-map-marker"></span>Office of the principal chief conservator
                                        of forests (Wildlife) and chief wildlife warden, Odisha<br>Prakruti Bhawan, Plot
                                        No - 1459, Saheed Nagar, Bhubaneswar - 751007, Odisha, India</li>
                                    <li><span class="fa fa-volume-control-phone"></span> <strong>PCCF (WL) & CWLW :
                                        </strong>0674 2602251<br><strong>Addl. PCCF : </strong>0674 2602252<br><div class="text-warning"><strong>Toll Free No. : </strong>18003456771</div></li>
                                    <li><span class="fa fa-envelope"></span>odishawildlife&#64;gmail.com</li>
                                </ul>
                            </div>
                        </div>
                        <!--Footer Column-->
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="footer-widget link-column">
                                <div class="section-title">
                                    <h4>Quick Links</h4>
                                </div>
                                <div class="widget-content">
                                    <ul class="list">
                                        <li><a [routerLink]="['/projectelephant']">Project Elephant</a></li>
                                        <li><a [routerLink]="['/projecttiger']">Project Tiger</a></li>
                                        <li><a [routerLink]="['/crocodileconservation']">Crocodile Conservation</a></li>
                                        <li><a [routerLink]="['/seaturtleconservation']">Sea Turtle Conservation</a>
                                        </li>
                                        <li><a [routerLink]="['/blackbuckconservation']">Blackbuck Conservation</a></li>
                                        <li><a [routerLink]="['/mangroves']">Mangroves</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Big Column-->
                <div class="big-column col-md-6 col-sm-12 col-xs-12">
                    <div class="row clearfix">

                        <!--Footer Column-->
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="footer-widget link-column">
                                <div class="section-title">
                                    <h4>Important Links</h4>
                                </div>
                                <div class="widget-content">
                                    <ul class="list">
                                        <li><a href="http://www.bhitarkanika.org" target="_blank">Bhitarkanika</a></li>
                                        <li><a href="https://odishaforest.in/campa.html" target="_blank">CAMPA</a></li>
                                        <li><a href="https://www.odishafdc.com/default.php" target="_blank">OFDC</a>
                                        </li>
                                        <li><a href="http://ofsds.in/" target="_blank">OFSDP</a></li>
                                        <li><a href="https://odishaforest.in/saw-mills.html" target="_blank">Saw Mills</a>
                                        </li>
                                        <li><a href="http://www.iczmpodisha.org/" target="_blank">ICZMP</a>
                                        </li>
                                        <li><a href="https://ntca.gov.in/"
                                                target="_blank">NTCA</a></li>
                                        <li> <a href="https://odisha.gov.in/" target="_blank">Govt of Odsiha</a>
                                            </li>
                                        <li><a href="https://moef.gov.in/en/"
                                                target="_blank">MoEF && CC, Govt of India</a></li>
                                    
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <!--Footer Column-->
                        <div class="col-md-6 col-sm-6 col-xs-12">
                            <div class="footer-widget link-column">
                                <div class="section-title">
                                    <h4>Quick Information</h4>
                                </div>
                                <div class="widget-content">
                                    <ul class="list">
                                        <li><a [routerLink]="['/Research']">Research</a></li>
                                        <li><a [routerLink]="['/WildlifeCensus']">Wildlife Census</a></li>
                                        <li><a [routerLink]="['/Corridors']">Corridors</a></li>
                                        <li><a [routerLink]="['/Publication']">Publication</a></li>
                                        <li><a [routerLink]="['/ecotourism']">Ecotourism</a></li>
                                        <!-- <li><a [routerLink]="['/importantcirculars']">Important Circulars</a></li> -->
                                        <li><a [routerLink]="['/notices']">Notices</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4 offset-md-4 text-center mt-3">

                    <!-- <div class="subscription">
                        <label>Subscribe to us</label>
                        <form>
                            <input type="email" id="" class="form-control" placeholder="example@gmail.com">
                            <button value="submit" class="btn btn-light">Subscribe</button>
                        </form>
                    </div> -->
                    <ul class="social-icon mt-2">
                        <li><a href="https://www.facebook.com/pccfwildlifeodisha" target="_blank"><i class="mdi mdi-facebook"></i></a></li>
                        <li><a href="https://twitter.com/odishawildlife" target="_blank"><i class="mdi mdi-twitter"></i></a></li>
                        <li><a href="https://www.youtube.com/c/WildlifeOdisha" target="_blank"><i class="mdi mdi-youtube"></i></a></li>
                    </ul>

                </div>
            </div>
        </div>
    </div>
</footer>
<section class="footer-bottom">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="text-left copy-text">
                    <p>Copyrights &copy;  {{ currentYear }}  | Odisha Wildlife Organization | All Rights Reserved
                        <!-- | Developed by <a href="https://sparcindia.com/" target="_blank">SPARC (P) Ltd.</a> -->
                    </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="text-right copy-text">
                    <a [routerLink]="['/PrivacyPolicy']">Privacy Policy</a>   
                </div>
            </div>

        </div>
    </div><!-- /.container -->
</section>
<!-- <button class="scrollup" style="display: block;"><span class="fa fa-angle-up"></span></button> -->
