<app-header *ngIf="isDashboard"></app-header>
<app-menu  *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
			        <li class="nav-item">
			            <a id="tab-nandankananPAmap" href="#pane-nandankananPAmap" class="nav-link active" data-toggle="tab" role="tab">Map</a>
			        </li>
			        <li class="nav-item">
			            <a id="tab-nandankananPAinfo" href="#pane-nandankananPAinfo" class="nav-link" data-toggle="tab" role="tab">Information</a>
			        </li>
			    </ul>

			    <div id="accordion" class="tab-content" role="tablist">
			        <div id="pane-nandankananPAmap" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-nandankananPAmap">
			            <div class="card-header" role="tab" id="heading-nandankananPAmap">
			                <h5 class="mb-0">
			                    <a data-toggle="collapse" href="#collapse-nandankananPAmap" aria-expanded="true" aria-controls="collapse-nandankananPAmap">
                            Map
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-nandankananPAmap" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-nandankananPAmap">
                    <div class="card-body">
                           <div class="map" id="mapnandakanan">
                             <div class="gle-icon">
                              <a href="javascript:void(0)">
                                <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid" alt="Street View" title="Street View">
                            </a>
                            <a href="javascript:void(0)">
                                <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()' alt="Satellite View" title="Satellite View">
                            </a>
                            <a href="javascript:void(0)">
                                <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View" title="Topo View">
                            </a>
                                    </div>
                           </div>
                    </div>
			            </div>
			        </div>

			        <div id="pane-nandankananPAinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-nandankananPAinfo">
			            <div class="card-header" role="tab" id="heading-nandankananPAinfo">
			                <h5 class="mb-0">
			                    <a class="collapsed" data-toggle="collapse" href="#collapse-nandankananPAinfo" aria-expanded="false" aria-controls="collapse-nandankananPAinfo">
			                       Information
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-nandankananPAinfo" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-nandankananPAinfo">

                    <div class="card-body">

                      <h4 class="card-title">
                        Nandankanan Sanctuary
                        <span class="custom-down">
                          <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
                          <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                          </span>
                      </h4>
                      <div id="printcontent">
                      <div class="row">
                        <div class="col-md-8">
                          <div class="table-responsive">
                            <table class="table table-sm table-bordered">
                              <tbody>
                                <tr>
                                  <td><strong>Forest Division : </strong></td>
                                  <td>Nandankanan</td>
                                </tr>
                                <tr>
                                  <td><strong>District : </strong></td>
                                  <td>Khurda</td>
                                </tr>
                                <tr>
                                  <td><strong>Date of Notification : </strong></td>
                                  <td>03-08-1979</td>
                                </tr>
                                <tr>
                                  <td><strong>Notified Area : </strong></td>
                                  <td>4.371sq.kms</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-md-2 video-box no-print">
                          <a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/B_JpTonioZE" data-target="#myModal">
                            <h4>Nandankanan Sanctuary</h4>
                            <div class="overlay">
                              <span class="icon">
                                <i class="fa fa-play-circle"></i>
                              </span>
                            </div>
                          </a>
                        </div>
                        <div class="col-md-2">
                          <img src="assets/images/nandankanan-zoo.jpg" class="img-fluid float-right img-inner" alt="image">
                        </div>
                      </div>
                      <p>
                        Nandankanan was established in 29 th December, 1960 over an area of 362 hectares. It has spearheaded many significant improvements to provide the highest standards of housing, husbandry and healthcare to the captive wild animals and has contributed towards enriching our knowledge of biology concerning endangered species. The zoo was conceived to protect the natural environment and the animals living in it. It was declared as a sanctuary on 3rd August 1979 spreading over an area of 437 hectares. Amidst the beauty of enigmatic nature, the sanctuary comprises Zoological Park, State Botanical Garden and a National wetland i.e. Kanjia lake.
                      </p>
                      <h4 class="card-title">Quick Facts</h4>
                      <ul class="custom-list">
                        <li>The beauty of Nandankanan is the world-class care and services for all inhabitants by providing quality food, medical care, equipment, up gradation of the enclosure and enrichments. Nandankanan thrives on care, love and affection.</li>
                        <li>Nandankanan is the pioneer in conservation breeding of Gharials.In 1980,gharials have bred in captivity for the first time in the world.</li>
                        <li> The Pangolin Conservation Breeding Center for the endangered Indian pangolin is the only conservation breeding center for the species in the world.</li>
                        <li>Nandankanan has been internationally acclaimed for its highest collection of white tigers in the world. Besides, gharials and white tigers, leopards, pangolin, mouse deer, lions, ratel and vultures are also bred here.</li>
                        <li>More than 3.3 million visitors including more than 70,000 school children visit Nandankanan annually.With its rich biodiversity and strategic location the Nandankanan serves an excellent site for the nature education.</li>
                      </ul>
                      <h4 class="card-title">How to reach</h4>
                      <p class="address-inner">
                        <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>Nandankanan is 15 kms from Odisha’s capital, Bhubaneswar, the state capital by road.There are frequent buses available from Bhuabaneswar to Nandankanan.
                      </p>
                      <p class="address-inner">
        <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong>The Bhubaneswar railway station is at a distance of 18 kms
      </p>
      <p class="address-inner">
        <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>The Biju Patnaik International Airport is at a distance of 20 kms.
                      </p>
                      <h4 class="card-title">Facilities available</h4>
                      <ul class="custom-list">
                         <li> The White Tiger Safari, established in 1991, offers visitors the heady excitement of viewing the rare white tigers from specially designed protected buses.</li>
                        <li>The lion safari offers an adventurous zing to the trip to Nandankanan.</li>
                        <li>The ropeway connecting the Zoo with Botanical Garden over the lake and the toy-train for children are under renovation.</li>
                        <li>The facilities at Nandakanan include day accommodations and boating.</li>
                        <li>Nandankanan offers all types of facilities for the children, pregnant women and physically disabled tourists for a pleasant experience.</li>
                    </ul>
                      <h4 class="card-title">Salient Detail</h4>
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td width="15%"><strong>Geographic Extent : </strong></td>
                              <td width="85%" colspan="2">Longitude 85° 48'8" to 85° 49'55"(East) and Latitude 20° 23'14" to 20° 24'35"(North)</td>
                            </tr>
                            <tr>
                              <td><strong>Climate : </strong></td>
                              <td colspan="2">Temperature - In summer: 40°C, In winter: 14°C</td>
                            </tr>
                            <tr>
                              <td><strong>Villages :</strong></td>
                              <td colspan="2">None</td>
                            </tr>
                            <tr>
                              <td><strong>Contact officer : </strong></td>
                              <td>
                                <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Director<br>
                                <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Nandankanan Biological Park, Mayur Bhawan, Saheed Nagar, Bhubaneswar-751007, (Odisha), India<br>
                                <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>+91-674-2547850<br>
                                <span class="address-inner"><i class="fa fa-fax">&nbsp;</i></span>+91-674-2547840<br>
                                      </td>
                                      <td>
                                          <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Deputy Director<br>
                                <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Nandankanan Zoo, Dist - Khurda<br>
                                <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>+91-674-2466075<br>
                              </td>
                            </tr>
                            <tr>
                              <td><strong>Best time to visit : </strong></td>
                              <td colspan="2">Round the year</td>
                            </tr>
                            <tr>
                              <td><strong>Accommodation : </strong></td>
                              <td colspan="2">FRH & cottages in both Zoological Park and Botanical garden inside the Sanctuary which are utilized for tourists in the daytime only.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>
                    </div>

            </div>
			            </div>
			        </div>

			    </div>

			</div>
		</div>
	</div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video"  allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
