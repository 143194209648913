<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

        <h4 class="card-title">Vision for the future</h4>
        <p>The Vision is to Create a proper space for wildlife in the overall scheme of things within the civil society and governance of the State - a space that would be suffused by our traditional values of care, concern and compassion for Human Beings.</p>
        <p>The two basic objectives are to reverse the trend of habitat fragmentation and loss, and to generate stakeholding and participation of the local populace and various sections of society in the programmes of wildlife conservation.</p>

        <h4 class="card-title">The elements of vision</h4>
        <ul class="custom-list">
          <li>10% of the geographical area of the State brought under the Protected Area (PA) network comprising of sanctuaries, national parks, conservation reserves and community reserves, (in place of the present 4.2%). Representative and critical habitats, species and genetic biodiversity secured within the PA network. Each forest/wildlife division in the State has at least one PA.</li>
          <li>25% forest area of the State which qualifies as prime elephant habitat within the Eastern Indian Elephant Range managed as Elephant Reserves, and 10% forest area which is prime tiger habitat managed as Tiger Reserves. 3/4ths of the Tigers and 90% of the Elephants are within the Tiger Reserves/Elephant Reserves.</li>
          <li>Each PA has a duly approved Management Plan which is faithfully implemented. A key element in any Management Plan is a strong incentive for participation of the people of the locality in protection of wildlife and its habitat. Enough livelihood opportunities created by PA management through enhancing eco-tourism and through eco-development measures.</li>
          <li>Each PA has digitised geo-referenced maps in the GIS format, with capacity to continually update the maps.</li>
          <li>Biodiversity conservation taken up at landscape level in a mosaic of forests, agriculture fields, wetlands, coastal zones instead of being confined to PAs only. Long term ecological monitoring grounded for all areas of the State so that genetic variations are identified and conserved, known species thrive, no further species reach threatened list, and those in the list bounce back.</li>
        </ul>

        <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>

      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
