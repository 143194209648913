
<section class="slider-gallery pt-150 pb-20">
   <div class="container">
      <div class="row">
         <div class="col-md-9 col-sm-10 col-xs-12">
             <div class="section-title">
                 <h2>Our Gallery</h2>
             </div>
         </div>
         <div class="col-md-3 col-sm-2 col-xs-12">
             <a href="#" class="thm-btn style-2 mb-3 pull-right">View All</a>
         </div>
      </div>

      <div class="carousel-gallery">
         <div class="swiper-container">
            <div class="swiper-wrapper">

               <div class="swiper-slide">
                  <a href="assets/images/gallery-01.jpg" data-fancybox="gallery" data-caption="Eco Tourism">
                     <div class="image" style="background-image: url(assets/images/gallery-01.jpg)">
                        <div class="overlay">
                           <em class="fa fa-search"></em>
                        </div>
                     </div>
                  </a>
               </div>

               <div class="swiper-slide">
                  <a href="assets/images/gallery-02.jpg" data-fancybox="gallery" data-caption="Mangroves">
                     <div class="image" style="background-image: url(assets/images/gallery-02.jpg)">
                        <div class="overlay">
                           <em class="fa fa-search"></em>
                        </div>
                     </div>
                  </a>
               </div>

               <div class="swiper-slide">
                  <a href="assets/images/gallery-03.jpg" data-fancybox="gallery" data-caption="Blackbuck">
                     <div class="image" style="background-image: url(assets/images/gallery-03.jpg)">
                        <div class="overlay">
                           <em class="fa fa-search"></em>
                        </div>
                     </div>
                  </a>
               </div>

               <div class="swiper-slide">
                  <a href="assets/images/gallery-04.jpg" data-fancybox="gallery" data-caption="Project Elephant">
                     <div class="image" style="background-image: url(assets/images/gallery-04.jpg)">
                        <div class="overlay">
                           <em class="fa fa-search"></em>
                        </div>
                     </div>
                  </a>
               </div>

               <div class="swiper-slide">
                  <a href="assets/images/gallery-05.jpg" data-fancybox="gallery" data-caption="Nandankanan">
                     <div class="image" style="background-image: url(assets/images/gallery-05.jpg)">
                        <div class="overlay">
                           <em class="fa fa-search"></em>
                        </div>
                     </div>
                  </a>
               </div>

               <div class="swiper-slide">
                  <a href="assets/images/gallery-06.jpg" data-fancybox="gallery" data-caption="Similipal">
                     <div class="image" style="background-image: url(assets/images/gallery-06.jpg)">
                        <div class="overlay">
                           <em class="fa fa-search"></em>
                        </div>
                     </div>
                  </a>
               </div>

            </div>
            <div class="swiper-pagination"></div>
         </div>
      </div>     
   </div>

</section>