<app-header *ngIf="isDashboard"></app-header>
<app-menu  *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
			        <li class="nav-item">
			            <a id="tab-lahkeriPAmap" href="#pane-lahkeriPAmap" class="nav-link active" data-toggle="tab" role="tab">Map</a>
			        </li>
			        <li class="nav-item">
			            <a id="tab-lahkeriPAinfo" href="#pane-lahkeriPAinfo" class="nav-link" data-toggle="tab" role="tab">Information</a>
			        </li>
			    </ul>

			    <div id="accordion" class="tab-content" role="tablist">
			        <div id="pane-lahkeriPAmap" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-lahkeriPAmap">
			            <div class="card-header" role="tab" id="heading-lahkeriPAmap">
			                <h5 class="mb-0">
			                    <a data-toggle="collapse" href="#collapse-lahkeriPAmap" aria-expanded="true" aria-controls="collapse-lahkeriPAmap">
                            Map
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-lahkeriPAmap" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-lahkeriPAmap">
                    <div class="card-body">
                           <div class="map" id="maplahkeri">
                             <div class="gle-icon">
                              <a href="javascript:void(0)">
                                <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid" alt="Street View" title="Street View">
                            </a>
                            <a href="javascript:void(0)">
                                <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()' alt="Satellite View" title="Satellite View">
                            </a>
                            <a href="javascript:void(0)">
                                <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View" title="Topo View">
                            </a>
                                    </div>
                           </div>
                    </div>
			            </div>
			        </div>

			        <div id="pane-lahkeriPAinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-lahkeriPAinfo">
			            <div class="card-header" role="tab" id="heading-lahkeriPAinfo">
			                <h5 class="mb-0">
			                    <a class="collapsed" data-toggle="collapse" href="#collapse-lahkeriPAinfo" aria-expanded="false" aria-controls="collapse-lahkeriPAinfo">
			                       Information
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-lahkeriPAinfo" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-lahkeriPAinfo">

                    <div class="card-body">

                      <h4 class="card-title">
                        Lakheri-Valley Sanctuary
                        <span class="custom-down">
                          <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
                          <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                          </span>
                      </h4>
                      <div id="printcontent">
                      <div class="row">
                        <div class="col-md-8">
                          <div class="table-responsive">
                            <table class="table table-sm table-bordered">
                              <tbody>
                                <tr>
                                  <td><strong>Forest Division : </strong></td>
                                  <td>Parlakhemundi (T) Forest Division</td>
                                </tr>
                                <tr>
                                  <td><strong>District : </strong></td>
                                  <td>Gajapati & boardering portion of Ganjam District.</td>
                                </tr>
                                <tr>
                                  <td><strong>Date of Notification : </strong></td>
                                  <td>08-02-1985  </td>
                                </tr>
                                <tr>
                                  <td><strong>Notified Area : </strong></td>
                                  <td>174.95 Sq Kms.</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-md-2 video-box no-print">
                          <a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/zlV2x6lcAeg" data-target="#myModal">
                            <h4>Lakheri-Valley Sanctuary</h4>
                            <div class="overlay">
                              <span class="icon">
                                <i class="fa fa-play-circle"></i>
                              </span>
                            </div>
                          </a>
                        </div>
                        <div class="col-md-2">
                          <img src="assets/images/lakkeri-valley.jpg" class="img-fluid float-right img-inner" alt="image">
                        </div>
                      </div>
                      <p>
                        This sanctuary is located in the district of Gajapati is rich with tropical mixed deciduous forest, hills, perennial water sources and a variety of wildlife such as Leopard, Porcupine, pangolin, Giant squirrel, civet, otter, wolf, peafowl, Parakeet, Owl, Myna, Heron, Hornbill, hyena, elephant, deer, sambar and a variety of birds and reptiles. This sanctuary can be approached from Berhampur, Taptapani (famous for its hot water spring) and Chandragiri. The sanctuary spreads over an area of around 175 sq.kms. The place has a tropical climate and receives a huge amount of rainfall each and every year.
                      </p>
                      <h4 class="card-title">Quick Facts</h4>
                      <ul class="custom-list">
                        <li>The Lakhari Valley Sanctuary was established in the year 1985. The place is blessed with a tropical climate and receives a huge amount of rainfall every year due to its proximity to the Bay of Bengal</li>
                        <li>The sanctuary is full of rich tropical forest, valleys, hills, water sources etc., which creates an enchanting ambience in the sanctuary.</li>
                        <li>The Lakhari Valley Sanctuary is a dwelling place of a large number of elephants. The sanctuary is one of the elephant populated sanctuaries in India.</li>
                      </ul>
                      <h4 class="card-title">How to reach</h4>
                      <p class="address-inner">
                        <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>The Lakhari Valley Sanctuary is well connected by road and nearest places by road is Chandragiri which is about 15km from wildlife reserve.
                      </p>
                      <p class="address-inner">
        <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong>Nearest Railway Station is at Brahampur Near about 70-75Kms.
      </p>
      <p class="address-inner">
        <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>The nearest airport to this sanctuary is at Bhubaneswar.
                      </p>
                      <h4 class="card-title">Facilities available</h4>
                      <ul class="custom-list">
                        <li>No Facility Available</li>
                      </ul>
                      <h4 class="card-title">Salient Detail</h4>
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td width="15%"><strong>Geographic Extent : </strong></td>
                              <td width="85%" colspan="2">Longitude 84° 15' to 84° 25'(East) and Latitude 19° 25' to 19° 15'(North)</td>
                            </tr>
                            <tr>
                              <td><strong>Climate : </strong></td>
                              <td colspan="2">Moderate</td>
                            </tr>
                            <tr>
                              <td><strong>Villages :</strong></td>
                              <td colspan="2">6 (villages inside Sanctuary) and 30 ( habitation ) 3457 population</td>
                            </tr>
                            <tr>
                              <td><strong>Contact officer : </strong></td>
                              <td>
                                <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest Officer<br>
                                <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>ParlakhemundiDivision, Paralakhemundi, At/PO. Parlakhemundi Dist,Gajapati. <br>
                                <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06815222680 <br>
                                <span class="address-inner"><i class="fa fa-mobile">&nbsp;</i></span>9437079579<br>
                              </td>
                              <td>
                                <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>RANGE OFFICER<br>
                                <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Chandragiri, HQ /At - Podamari, PO.- Podamari, Dist - Gajapati <br>
                              </td>
                            </tr>
                            <tr>
                              <td><strong>Best time to visit : </strong></td>
                              <td colspan="2">October- March</td>
                            </tr>
                            <tr>
                              <td><strong>Accommodation : </strong></td>
                              <td colspan="2">Available at Digapahandi and Taptapani (Panthanivas of OTDC) and Chandragiri Budhist Rest House at Jiranga.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>
                    </div>

            </div>
			            </div>
			        </div>

			    </div>

			</div>
		</div>
	</div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video"  allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
