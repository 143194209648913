<app-loader></app-loader>
<section id="header">
	<div class="header">
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12">
					<a href="/" class="header-logo">
						<img src="assets/images/odishalogo.png" class="logo" alt="logo">
						<h1>
							Odisha Wildlife Organization <!-- <span class="text-dark">(<span class="text-high">i</span>WLMS)</span> -->
							<br>
							<small>Official Website of PCCF (Wildlife) & Chief Wildlife Warden, Odisha</small>
						</h1>
					</a>
					<span class="img-hd">
						<!-- <a href="https://forest.odisha.gov.in/" target="_blank"><img src="assets/images/logo-5t.png"></a> -->
						<!-- <a href="https://mosarkar.odisha.gov.in/App/" target="_blank"><img src="assets/images/logo-mo-sarakar.png"></a> -->
						<a href="https://www.odwlanukampa.in/index.php/home/applications" target="_blank"><img src="assets/images/logo_wl_anukampa_app.png"></a>
					</span>
					<div class="header-link">
						<!-- <ul class="font-size">
							<li><a href="#">A-</a></li>
							<li><a href="#">A</a></li>
							<li><a href="#">A+</a></li>
						</ul> -->
						<h2 class="tollfreestyle">Toll Free No. : 18003456771</h2>
						<ul class="top-link">
							<!-- <li><a href="#">RTI</a></li> -->
							<!-- <li><a href="javascript:void(0)">Report Incidents</a></li> -->
							<li><a href="#" data-toggle="modal" data-dismiss="modal" data-target="#modalFeedback">Feedback</a></li>
							<li><a href="#" data-toggle="modal" data-dismiss="modal" data-target="#modalContact">Contact</a></li>
						</ul>
						<div class="login">
							<button mat-raised-button data-toggle="modal" data-target="#modalLogin">iWLMS Login</button>
						</div>
						<!-- <img src="assets/images/cmodisha.png" class="logo" alt="image"> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<app-login></app-login>
<app-feedback></app-feedback>
<app-contact></app-contact>




