import { Component, OnInit } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-barchartmultipletwo',
  templateUrl: './barchartmultipletwo.component.html',
  styleUrls: ['./barchartmultipletwo.component.css']
})
export class BarchartmultipletwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  	(function($){

      	google.charts.load('current', {packages: ['corechart', 'bar']});
		google.charts.setOnLoadCallback(drawBasic);

		function drawBasic() {

		      var data = google.visualization.arrayToDataTable([
		        ['Area', 'Deaths',],
		        ['Male', 15],
		        ['Female', 20]

          ]);
          // ['Area', 'Deaths',],
          // ['Male', 15],
          // ['Female', 20],
          // ['Bamra', 0],
          // ['Bhadrak', 0],
          // ['Chandaka', 0],
          // ['Chilika', 0],
          // ['Dhenkanal', 10],
          // ['Hirakud', 0],
          // ['Kalahandi', 0],
          // ['Keonjhar', 1],
          // ['Mahanadi', 0],
          // ['Nandankanan', 0],
          // ['Puri', 0],
          // ['Satkosia', 3],
          // ['Similipal', 1],
          // ['Sunabeda', 0]

		      var options = {
		        title: 'Elephant Death',
		        chartArea: {width: '50%'},
		        hAxis: {
		          title: 'No. of Deaths',
		          minValue: 0
		        },
		        vAxis: {
		          title: 'Area'
		        }
		      };

		      var chart = new google.visualization.BarChart(document.getElementById('verticalBarChartTwo'));

		      chart.draw(data, options);
		    }

    })(jQuery);

  }

}
