import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from '../service/api.service';
const baseUrlFile = environment.FILE_API_URL;
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  public loader: boolean=false;
  public blogExist: boolean=false;
  blogList: any[];
  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.getAllBlog();
  }
  getAllBlog() {
    this.loader=true;
    this.apiService.request('GET', 'api/v1/cms/getAllBlogData').subscribe((data: any) => {
      if (data.length>0) {
        this.blogList = new Array() ;
        data.sort(function(a, b) {
          return b.seqNo- a.seqNo;
        });
        data.sort((a, b) => {
          return <any>new Date(b.craetedOn) - <any>new Date(a.craetedOn);
        });
        var arrr=new Array();
        data.map(item=>{
          return {
            blogImgPath : baseUrlFile+'blog/'+item['blogImgPath'],
            blogNum:item['blogNum'],
            blogDesc:item['blogDesc'],
            pdfPath: baseUrlFile+'blog/'+item['pdfPath'],
            createdByName:item['createdByName'],
            craetedOn:item['craetedOn'],
            title: item['title']
          }
        }).forEach(item=> arrr.push(item));
        for (var i = 0; i < 2; i++) {
          this.blogList.push(arrr[i]);
        }
        this.blogExist=true;

        this.loader=false;
      }else{
        this.blogExist=false;
      }
    },err=>{
      this.loader = false;
    });
  }
  defaultUrl(event) {
    event.target.src='assets/images/no_img.png';
  }

}
