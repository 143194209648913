import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Form, FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-citizenaudit',
  templateUrl: './citizenaudit.component.html',
  styleUrls: ['./citizenaudit.component.css']
})
export class CitizenauditComponent implements OnInit {



  districtlist: any;
  blocklist: any;
  public districtSlected = 'All';
  public blockSelected = 'All';
  public distId: string;
  public blockId: string;
  citizenqueryForm1: FormGroup;
  dtTrigger1: Subject<any> = new Subject<any>();
  dtOptions: any = {};
  tabledata1: any[];
  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService) { }
  ngOnInit(): void {

    this.distId = "0";
    this.blockId = "0";
    this.citizenqueryForm1 = this.formBuilder.group({
      district: [''],
      block: ['']
    });
    this.dtOptions = {

      dom: 'Bfrtip',
      buttons: [
        'copy', 'excel', 'pdf', 'print'
      ]
    };
    this.getAllDistrict();
    this.getDistrictWiseCitizenCount();



  }
  backtoauditpage() {
    this.router.navigate(['auditreport']);
  }

  getAllDistrict() {
    this.apiService.request('GET', 'api/v1/masters/getAllDistrict').subscribe((data: any) => {
      if (data) {
        this.districtlist = data;
        // this.loader=false;
      }
    });
  }
  getAllBlockByDistId(district: any) {
    if (district != 0) {
      //let distId=parseInt(district);
      // this.loader=true;
      this.apiService.request('GET', 'api/v1/masters/getAllBlockByDistId?distId=' + district).subscribe((data: any) => {
        if (data) {
          this.blocklist = data;
          //  this.loader=false;
        }
      });
    } else {
      this.blocklist = [];

    }
  }
  loadtable(value: number) {

  }
  getDistrictWiseCitizenCount() {

    //  this.loader=true;
    this.apiService.request('GET', 'api/v1/audit/getDistrictWiseCitizenCount?districtId=' + this.districtSlected + '&blockId=' + this.blockSelected).subscribe((data: any) => {
      if (data.length > 0) {

        //   this.loader=false;
        $("#districtWiseCitizenTable").DataTable().destroy();
        this.tabledata1 = data;
        this.dtTrigger1.next();
      }
    });

    // this.loader=false;

    //  this.loader=false;

  }
  onSubmit(value: any) {
    try {
      if (value.district == "0") {
        this.districtSlected = "All";
      } else {
        this.districtSlected = value.district;
      } if (value.block == "0") {
        this.blockSelected = "All";
      } else {
        this.blockSelected = value.block;
      }
      this.getDistrictWiseCitizenCount();
    } catch {
      //  this.loader=false;
    } finally {
      //  this.loader=false;
    }
  }
}

