<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

				<h4 class="card-title">Blog</h4>

				<div class="table-responsive blog-table">
		            <table id="tableBlog" class="table table-bordered responsive" width="99.5%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
		            	<thead>
		            		<tr>
                      <th width="5%">Sr. No.</th>
		            			<th width="15%">Blog Image</th>
		            			<th width="30%">Blog Title</th>
                      <th>Created By</th>
                      <th>Date</th>
		            			<th>Blog Description</th>

		            		</tr>
		            	</thead>
		            	<tbody>
		            		<tr *ngFor="let blog of blogList; let i = index" [attr.data-index]="i">
                      <td>{{i+1}} </td>
		            			<td><img src={{blog.blogImgPath}} (error)="defaultUrl($event)" alt=""></td>
		            			<td><h4>{{ blog.title}}</h4></td>
                      <td>{{ blog.createdByName}}</td>
                      <td>{{ (blog.craetedOn  !== 'null') ? (blog.craetedOn | date:'dd-MM-yyyy')  : '' }} {{ (blog.craetedOn  !== 'null') ? (blog.craetedOn  | date:'shortTime')  : '' }}</td>
		            			<td><p>{{blog.blogDesc}}</p></td>
		            		</tr>
		            	</tbody>
		            </table>
		        </div>

				<!-- <div class="paginate 1">
					<div class="items row event-style1 blog-content">
			            <article class="col-md-12 col-sm-12 col-xs-12" *ngFor="let blog of blogList;" >
			              <div class="item style-2">
			                  <div class="clearfix">
			                      <div class="img-column">
			                          <figure class="img-holder">
			                            <img src={{blog.blogImgPath}} (error)="defaultUrl($event)" alt="">
			                          </figure>
			                      </div>
			                      <div class="text-column">
			                          <div class="lower-content">
			                            <h4>  {{ blog.title}} </h4>
			                              <div class="text">
			                                <p>{{blog.blogDesc}}</p>
			                            </div>
			                          </div>
			                          <ul class="post-meta list_inline">
			                            <li><i class="fa fa-clock-o"></i>{{ (blog.craetedOn  !== 'null') ? (blog.craetedOn | date:'dd-MM-yyyy')  : '' }} {{ (blog.craetedOn  !== 'null') ? (blog.craetedOn  | date:'shortTime')  : '' }}</li>  |&nbsp;&nbsp;&nbsp;
			                            <li><i class="fa fa-map-marker"></i> {{blog.craetedBy}}</li>
			                      </ul>
			                      </div>
			                  </div>
			              </div>
			            </article>
						<article class="col-md-12 col-sm-12 col-xs-12">
			                <div class="item style-2">
			                    <div class="clearfix">
			                        <div class="img-column">
			                            <figure class="img-holder">
			                                <img src="assets/images/blog-01-lg.jpg" alt="">
			                            </figure>
			                        </div>
			                        <div class="text-column">
			                            <div class="lower-content">
			                                <h4>Human elephant conflict management – A review of current management and future directions in Jharsuguda forest division, Odisha</h4>
			                                <div class="text">
				                            	<p>Human elephant conflict management – A review of current management and future directions in Jharsuguda forest division, Odisha is an attempt to describe the current scenario of management and future suggestions to improve it. the study has been done by me during my ON JOB TRAINING in ACF training to Jharsuguda Division. Pradeep Kumar Dey ACF, Similipal North WL Division.</p>
				                            </div>
			                            </div>
			                            <ul class="post-meta list_inline">
					                        <li><i class="fa fa-clock-o"></i>21.07.2020 9:23:00 PM </li>  |&nbsp;&nbsp;&nbsp;
					                        <li><i class="fa fa-map-marker"></i> Similipal North WL</li>
					                    </ul>
			                        </div>
			                    </div>
			                </div>
			            </article>
			            <article class="col-md-12 col-sm-12 col-xs-12">
			                <div class="item style-2">
			                    <div class="clearfix">
			                        <div class="img-column">
			                            <figure class="img-holder">
			                                <img src="assets/images/blog-02-lg.jpg" alt="">
			                            </figure>
			                        </div>
			                        <div class="text-column">
			                            <div class="lower-content">
			                                <h4>Supply of FRP 10 Seater Sea Worthy Speed Boats with outboard Petrol engine which should be feasible for large tidal affected creeks with comprehensive warranty and maintenance in Balasore WL Division</h4>
			                                <div class="text">
				                            	<p>Supply of FRP 10 Seater Sea Worthy Speed Boats with outboard Petrol engine which should be feasible for large tidal affected creeks with comprehensive warranty and maintenance in Balasore WL Division.</p>
				                            </div>
			                            </div>
			                            <ul class="post-meta list_inline">
					                        <li><i class="fa fa-clock-o"></i>13.02.2020 5:31:00 PM </li> |&nbsp;&nbsp;&nbsp;
					                        <li><i class="fa fa-map-marker"></i> DFO Balasore</li>
					                    </ul>
			                        </div>
			                    </div>
			                </div>
			            </article>
			            <article class="col-md-12 col-sm-12 col-xs-12">
			                <div class="item style-2">
			                    <div class="clearfix">
			                        <div class="img-column">
			                            <figure class="img-holder">

			                            </figure>
			                        </div>
			                        <div class="text-column">
			                            <div class="lower-content">
			                                <h4>Supply Installation and Commissioning of HDPE Floating Jetties in Bichitrapur Mangrove and Risia Nature Camp Under Balasore WL Division</h4>
			                                <div class="text">
				                            	<p>Supply Installation and Commissioning of HDPE Floating Jetties in Bichitrapur Mangrove and Risia Nature Camp Under Balasore WL Division.</p>
				                            </div>
			                            </div>
			                            <ul class="post-meta list_inline">
					                        <li><i class="fa fa-clock-o"></i>13.02.2020 5:30:00 PM </li> |&nbsp;&nbsp;&nbsp;
					                        <li><i class="fa fa-map-marker"></i> DFO Balasore</li>
					                    </ul>
			                        </div>
			                    </div>
			                </div>
			            </article>
					</div>
					<div class="pager d-flex justify-content-center">
						<div class="firstPage">&laquo;</div>
						<div class="previousPage">&lsaquo;</div>
						<div class="pageNumbers"></div>
						<div class="nextPage">&rsaquo;</div>
						<div class="lastPage">&raquo;</div>
					</div>
				</div> -->

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>
