<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <ul id="tabs" class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a id="tab-bhitarkaninikaPAmap" href="#pane-bhitarkaninikaPAmap" class="nav-link active" data-toggle="tab"
              role="tab">Map</a>
          </li>
          <li class="nav-item">
            <a id="tab-bhitarkaninikaPAinfo" href="#pane-bhitarkaninikaPAinfo" class="nav-link" data-toggle="tab"
              role="tab">Information</a>
          </li>
        </ul>

        <div id="accordion" class="tab-content" role="tablist">
          <div id="pane-bhitarkaninikaPAmap" class="card tab-pane fade show active" role="tabpanel"
            aria-labelledby="tab-bhitarkaninikaPAmap">
            <div class="card-header" role="tab" id="heading-bhitarkaninikaPAmap">
              <h5 class="mb-0">
                <a data-toggle="collapse" href="#collapse-bhitarkaninikaPAmap" aria-expanded="true"
                  aria-controls="collapse-bhitarkaninikaPAmap">
                  Map
                </a>
              </h5>
            </div>
            <div id="collapse-bhitarkaninikaPAmap" class="collapse show" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-bhitarkaninikaPAmap">
              <div class="card-body">
                <div class="map" id="mapbhitarakanika">
                  <div class="gle-icon">
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid"
                        alt="Street View" title="Street View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()'
                        alt="Satellite View" title="Satellite View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View"
                        title="Topo View">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="pane-bhitarkaninikaPAinfo" class="card tab-pane fade" role="tabpanel"
            aria-labelledby="tab-bhitarkaninikaPAinfo">
            <div class="card-header" role="tab" id="heading-bhitarkaninikaPAinfo">
              <h5 class="mb-0">
                <a class="collapsed" data-toggle="collapse" href="#collapse-bhitarkaninikaPAinfo" aria-expanded="false"
                  aria-controls="collapse-bhitarkaninikaPAinfo">
                  Information
                </a>
              </h5>
            </div>
            <div id="collapse-bhitarkaninikaPAinfo" class="collapse" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-bhitarkaninikaPAinfo">

              <div class="card-body">

                <h4 class="card-title">
                  Bhitarkanika Wildlife Sanctuary
                  <span class="custom-down">
                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i
                        class="fa fa-file-pdf">&nbsp;</i>Information</button>
                        <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                  </span>
                </h4>
                <div id="printcontent">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td><strong>Forest Division : </strong></td>
                              <td>Mangrove Forests Division (WL) Rajnagar</td>
                            </tr>
                            <tr>
                              <td><strong>District : </strong></td>
                              <td>Kendrapara</td>
                            </tr>
                            <tr>
                              <td><strong>Date of Notification : </strong></td>
                              <td>22-04-1975</td>
                            </tr>
                            <tr>
                              <td><strong>Notified Area : </strong></td>
                              <td>672.00 Sq.km.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-2 video-box no-print">
                      <a href="#" class="video-btn" data-toggle="modal"
                        data-src="https://www.youtube.com/embed/K4SWB5k1lcY" data-target="#myModal">
                        <h4>Bhitarkanika Wildlife Sanctuary</h4>
                        <div class="overlay">
                          <span class="icon">
                            <i class="fa fa-play-circle"></i>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="col-md-2">
                      <img src="assets/images/BhitarkanikaPa.jpg" class="img-fluid float-right img-inner" alt="image">
                    </div>
                  </div>
                  <p>
                    Bhitarkanika Wildlife Sanctuary is located in the estuarial region of Bramhani-Baitarani, in the
                    north-eastern place of Kendrapara district of Odisha (Orissa), the sanctuary covers an area of 672
                    square kilometers of Mangrove Forests and Wetland. Three rivers flow out to sea at Bhitarkanika
                    forming a tidal maze of muddy creeks and mangroves. Bhitarkanika Wildlife Sanctuary is home to
                    numerous species of birds. It is also home to the endangered and mysterious King Cobras. The
                    sanctuary has to its account is the inhibition of its ecosystem by the Giant Estuarine Crocodiles
                    and numerous varieties of other animal species.
                  </p>
                  <h4 class="card-title">Quick Facts</h4>
                  <ul class="custom-list">
                    <li>Bhitarkanika Mangrove Ecosystem is richest from bio-diversity point of view. The floral
                      diversity in Bhitarkanika is 2nd largest after Papua New Guinea.</li>
                    <li>The Protected Area encompasses variety of habitats and microhabitats. Mangroves serve as
                      nesting, feeding and breeding grounds for wide range of invertebrates and vertebrates.</li>
                    <li>The Sanctuary is home to the largest number of Estuarine Crocodiles in the Indian sub-continent.
                      With a population of more than 1600 crocodiles.</li>
                    <li>The heronry at Bagagahana / Mathaadia (largest in Asia) provides nesting space to a large number
                      of resident water birds during rainy season where as the wetlands spreading throughout the
                      sanctuary serve as feeding grounds for a number of migratory birds during winter.</li>
                    <li>It is the only major mangrove patch of the State of Odisha which is legally and administratively
                      well protected and has a long term role in protecting the life and property of the people of the
                      hinterland from cyclones, and other natural calamities.</li>
                  </ul>
                  <h4 class="card-title">How to reach</h4>
                  <p class="address-inner">
                    <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong> The area is accessible by road is via
                    Rajnagar & Chandbali
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong>The nearest Railway Stations are Cuttack
                    and Bhadrak, which are 110 Km. away from Rajnagar and 50 Km away from Chandbali respectively.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong> Nearest airport is Bhubaneswar, which is
                    130 Kms. awayfrom Rajnagar & 180 Kms away from Chandbali.
                  </p>
                  <h4 class="card-title">Facilities available</h4>
                  <ul class="custom-list">
                    <li>Wildlife sighting and Sightseeing is done on boats empanelled with Forest Department licenses.
                    </li>
                    <li>Accommodation is available at Eco-cottages in and around the sanctuary at affordable facilities.
                    </li>
                    <li>Bagagahana Island: Located within Bhitarkanika Forest Block of the sanctuary, is a large heronry
                      spread over 22 acres.</li>
                  </ul>
                  <h4 class="card-title">Salient Detail</h4>
                  <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                      <tbody>
                        <tr>
                          <td width="15%"><strong>Geographic Extent : </strong></td>
                          <td width="85%">Longitudes 86° 38' to 87° 3'(east )and Latitude 20° 47' to 20° 29' (north).
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Climate : </strong></td>
                          <td>Temperature - In summer: 43°C, In winter: 10°C</td>
                        </tr>
                        <tr>
                          <td><strong>Villages :</strong></td>
                          <td>410 villages</td>
                        </tr>
                        <tr>
                          <td><strong>Contact officer : </strong></td>
                          <td>
                            <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest
                            Officer<br>
                            <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Mangrove Forest
                            Division (WL), Rajnagar, Dist-Kendrapara <br>
                            <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06729-242460 <br>
                            <span class="address-inner"><i class="fa fa-fax">&nbsp;</i></span>06729-242460<br>
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Best time to visit : </strong></td>
                          <td>October to March</td>
                        </tr>
                        <tr>
                          <td><strong>Accommodation : </strong></td>
                          <td>Kuanria, Chhamundia & Badmul</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
                      </strong>The information contained herein are provided solely for informational purposes only.The
                      content is dynamic and may undergo change from time to time. The snapshot of the content at a
                      particular time is not to be referred as a legally binding document.</small></p>
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
