import {ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WildlifeunitsRoutingModule } from './wildlifeunits-routing.module';
import { WildlifeunitsbalasoreComponent } from './wildlifeunitsbalasore/wildlifeunitsbalasore.component';
import { WildlifeunitsbamraComponent } from './wildlifeunitsbamra/wildlifeunitsbamra.component';
import { WildlifeunitsbhadrakComponent } from './wildlifeunitsbhadrak/wildlifeunitsbhadrak.component';
import { WildlifeunitschandakaComponent } from './wildlifeunitschandaka/wildlifeunitschandaka.component';
import { WildlifeunitschilikaComponent } from './wildlifeunitschilika/wildlifeunitschilika.component';
import { WildlifeunitshirakudComponent } from './wildlifeunitshirakud/wildlifeunitshirakud.component';
import { WildlifeunitsKeonjharComponent } from './wildlifeunits-keonjhar/wildlifeunits-keonjhar.component';
import { WildlifeunitsMahanadiComponent } from './wildlifeunits-mahanadi/wildlifeunits-mahanadi.component';
import { WildlifeunitsNandankananComponent } from './wildlifeunits-nandankanan/wildlifeunits-nandankanan.component';
import { WildlifeunitsPuriComponent } from './wildlifeunits-puri/wildlifeunits-puri.component';
import { WildlifeunitsMangroveComponent } from './wildlifeunits-mangrove/wildlifeunits-mangrove.component';
import { WildlifeunitsSatkosiaComponent } from './wildlifeunits-satkosia/wildlifeunits-satkosia.component';
import { WildlifeunitsSimilipalTigerReserveComponent } from './wildlifeunits-similipal-tiger-reserve/wildlifeunits-similipal-tiger-reserve.component';
import { WildlifeunitsSunabedaWLComponent } from './wildlifeunits-sunabeda-wl/wildlifeunits-sunabeda-wl.component';
import { WildlifeunitsBaligudaFDComponent } from './wildlifeunits-baliguda-fd/wildlifeunits-baliguda-fd.component';
import { WildlifeunitsDhenkanalFDComponent } from './wildlifeunits-dhenkanal-fd/wildlifeunits-dhenkanal-fd.component';
import { WildlifeunitsKalahandiSouthFDComponent } from './wildlifeunits-kalahandi-south-fd/wildlifeunits-kalahandi-south-fd.component';
import { WildlifeunitsParlakhemundiFDComponent } from './wildlifeunits-parlakhemundi-fd/wildlifeunits-parlakhemundi-fd.component';
import { WildlifeunitsSimilipalNorthComponent } from './wildlifeunits-similipal-north/wildlifeunits-similipal-north.component';
import { WildlifeunitsSimilipalSouthComponent } from './wildlifeunits-similipal-south/wildlifeunits-similipal-south.component';
import { SharedhomeModule } from '../sharedhome/sharedhome.module';


@NgModule({
  declarations: [
    WildlifeunitsbalasoreComponent,
    WildlifeunitsbamraComponent,
    WildlifeunitsbhadrakComponent,
    WildlifeunitschandakaComponent,
    WildlifeunitschilikaComponent,
    WildlifeunitshirakudComponent,
    WildlifeunitsKeonjharComponent,
    WildlifeunitsMahanadiComponent,
    WildlifeunitsNandankananComponent,
    WildlifeunitsPuriComponent,
    WildlifeunitsMangroveComponent,
    WildlifeunitsSatkosiaComponent,
    WildlifeunitsSimilipalTigerReserveComponent,
    WildlifeunitsSunabedaWLComponent,
    WildlifeunitsBaligudaFDComponent,
    WildlifeunitsDhenkanalFDComponent,
    WildlifeunitsKalahandiSouthFDComponent,
    WildlifeunitsParlakhemundiFDComponent,
    WildlifeunitsSimilipalNorthComponent,
    WildlifeunitsSimilipalSouthComponent
  ],
  imports: [
    CommonModule,
    SharedhomeModule,
    WildlifeunitsRoutingModule
  ],exports: [
    WildlifeunitsbalasoreComponent,
    WildlifeunitsbamraComponent,
    WildlifeunitsbhadrakComponent,
    WildlifeunitschandakaComponent,
    WildlifeunitschilikaComponent,
    WildlifeunitshirakudComponent,
    WildlifeunitsKeonjharComponent,
    WildlifeunitsMahanadiComponent,
    WildlifeunitsNandankananComponent,
    WildlifeunitsPuriComponent,
    WildlifeunitsMangroveComponent,
    WildlifeunitsSatkosiaComponent,
    WildlifeunitsSimilipalTigerReserveComponent,
    WildlifeunitsSunabedaWLComponent,
    WildlifeunitsBaligudaFDComponent,
    WildlifeunitsDhenkanalFDComponent,
    WildlifeunitsKalahandiSouthFDComponent,
    WildlifeunitsParlakhemundiFDComponent,
    WildlifeunitsSimilipalNorthComponent,
    WildlifeunitsSimilipalSouthComponent
  ],providers: []
})
export class WildlifeunitsModule {
  static forRoot(): any[] | import("@angular/core").Type<any> | ModuleWithProviders<{}> {
    return {
      ngModule: WildlifeunitsModule,
      providers: []
    };
  }
 }
