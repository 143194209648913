import { Component, OnInit } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var jQuery: any;

@Component({
  selector: 'app-ecotourism',
  templateUrl: './ecotourism.component.html',
  styleUrls: ['./ecotourism.component.css']
})
export class EcotourismComponent implements OnInit {

	// public data = [
	//     {slno: '1', location: 'Bichitrapur', division: 'Balasore WL Division', range: 'Jaleswar', place: ''},
	//     {slno: '2', location: 'Gohirabhola', division: 'Balasore WL Division', range: 'Kuldiha', place: ''},
	//     {slno: '3', location: 'Gudguda Waterfall', division: 'Bamra WL Division', range: 'Kuchinda', place: 'Badabahal'},
	//     {slno: '4', location: 'Kutab', division: 'Bamra WL Division', range: 'Badarama', place: 'Kutab Watch Tower'},
	//     {slno: '5', location: 'Paturia', division: 'Bamra WL Division', range: 'Badarama', place: 'University Spot'},
	//     {slno: '6', location: 'Godibari', division: 'Chandaka WL Division', range: 'Chandaka', place: 'Godibari'},
	//     {slno: '7', location: 'Derasa', division: 'Chandaka WL Division', range: 'Chandaka', place: 'Derasa'},
	//     {slno: '8', location: 'Mangalajodi', division: 'Chilika WL Division', range: 'Tangi', place: 'Mangalajodi'},
	//     {slno: '9', location: 'Kapilash', division: 'Dhenkanal Forest Division', range: 'Kapilash', place: 'Kapilash Temple'},
	//     {slno: '10', location: 'Kapilash', division: 'Dhenkanal Forest Division', range: 'Kapilash', place: 'Saptasajya'},
	//     {slno: '11', location: 'Barakhandia', division: 'Hirakud WL Division', range: 'Kamgaon', place: ''},
	//     {slno: '12', location: 'Gandahati', division: 'Parlakhimundi Forest Division', range: 'Mohendra Range', place: ''},
	//     {slno: '13', location: 'Chhotkei', division: 'Satkosia WL Division', range: 'Purunakote', place: 'Chhotkei Nature Camp'},
	//     {slno: '14', location: 'Purunakote', division: 'Satkosia WL Division', range: 'Purunakote', place: 'Purunakote Nature Camp'},
	//     {slno: '15', location: 'Baghamunda', division: 'Satkosia WL Division', range: 'Purunakote', place: 'Baghamunda Biodiversity conservation awarness Camp'},
	//     {slno: '16', location: 'Tikarpada', division: 'Satkosia WL Division', range: 'Tikarpada', place: 'Tikarpada Paribesh Paryatan Samit (TIPPS)'},
	//     {slno: '17', location: 'Tarava', division: 'Satkosia WL Division', range: 'Pampasar', place: 'Tarava Nature Camp'},
	//     {slno: '18', location: 'Baliput', division: 'Satkosia WL Division', range: 'Jillinda', place: 'Baliput Nature Camp'},
	//     {slno: '19', location: 'Jamuani', division: 'STR Baripada WL Division', range: '', place: ''},
	//     {slno: '20', location: 'Guruguria', division: 'STR Baripada WL Division', range: 'Guruguria', place: ''},
	//     {slno: '21', location: 'Kumari', division: 'STR Baripada WL Division', range: 'Guruguria', place: ''},
	//     {slno: '22', location: 'Patora', division: 'Sunabeda WL Division', range: 'Nuapada', place: 'Eco-tourism locations'},
	//     {slno: '23', location: 'Maraguda', division: 'Sunabeda WL Division', range: 'Nuapada', place: 'Eco-tourism locations'},
	//     {slno: '24', location: 'Godhus', division: 'Sunabeda WL Division', range: 'Nuapada', place: 'Eco-tourism locations'},
	//     {slno: '25', location: 'Beniadhus', division: 'Sunabeda WL Division', range: 'Sunabeda', place: 'Eco-tourism locations'},
	//     {slno: '26', location: 'Bhitarkanika', division: 'Mangrove WL Division', range: 'Rajnagar', place: 'Bhitarkanika'},
	//     {slno: '27', location: 'Dangamal', division: 'Mangrove WL Division', range: 'Kanika', place: 'Dangamal'},
	//     {slno: '28', location: 'Habelikhati', division: 'Mangrove WL Division', range: 'Rajnagar', place: 'Habelikhati'},
	//     {slno: '29', location: 'Gupti Beat', division: 'Mangrove WL Division', range: 'Rajnagar', place: 'Gupti Beat'},
	//     {slno: '30', location: 'Batighar', division: 'Mangrove WL Division', range: 'Mahakalapada', place: 'Batighar'},
	//     {slno: '31', location: 'Barunei', division: 'Mangrove WL Division', range: 'Rajnagar', place: 'Barunei'},
	//     {slno: '32', location: 'Hukitola', division: 'Mangrove WL Division', range: 'Gahiramatha', place: 'Hukitola'},
	//     {slno: '33', location: 'Baliharachandi', division: 'Puri WL Division', range: 'Brahmagiri', place: ''},
	//     {slno: '34', location: 'Nuanai nature Camp', division: 'Puri WL Division', range: 'Balukhanda', place: ''},
	//     {slno: '35', location: 'Beleshwar', division: 'Puri WL Division', range: 'Balukhanda', place: ''},
	//     {slno: '36', location: 'Chandrabhaga', division: 'Puri WL Division', range: 'Konark', place: ''},
	//     {slno: '37', location: 'Ramchandi', division: 'Puri WL Division', range: 'Konark', place: ''},
	//     {slno: '38', location: 'Kapliseshwar', division: 'Puri WL Division', range: 'Konark', place: ''},
	//     {slno: '39', location: 'Jahania', division: 'Puri WL Division', range: 'Astrang', place: ''},
	//     {slno: '40', location: 'Ganga Devi', division: 'Puri WL Division', range: 'Astrang', place: ''},
	//     {slno: '41', location: 'Tavagolara', division: 'Puri WL Division', range: 'Astrang', place: ''},
	//     {slno: '42', location: 'Garudeshwar', division: 'Puri WL Division', range: 'Konark', place: ''},
	//     {slno: '43', location: 'Nuanai Polanga bana', division: 'Puri WL Division', range: 'Balukhanda', place: ''},
	//     {slno: '44', location: 'Manganasi', division: 'Mahanadi WL Division', range: 'Chhamundia', place: 'Satkosia Sand Resorts'},
	// ];

  public data = [
    {slno: '1', district: 'Mayurbhanja', division: 'STR Baripad', destination: 'Similipala Nature Camps', location: 'Gurguria', link: 'https://www.ecotourodisha.com/similipal-nature-camps.php'},
    {slno: '2', district: 'Mayurbhanja', division: 'STR Baripad', destination: 'Similipala Nature Camps', location: 'Jamuani', link: 'https://www.ecotourodisha.com/similipal-nature-camps.php'},
    {slno: '3', district: 'Mayurbhanja', division: 'STR Baripad', destination: 'Similipala Nature Camps', location: 'Kumari', link: 'https://www.ecotourodisha.com/similipal-nature-camps.php'},
    {slno: '4', district: 'Mayurbhanja', division: 'STR Baripad', destination: 'Similipala Nature Camps', location: 'Ramatirtha', link: 'https://www.ecotourodisha.com/similipal-nature-camps.php'},
    {slno: '5', district: 'Mayurbhanja', division: 'STR Baripad', destination: 'Similipala Nature Camps', location: 'Barehipani', link: 'https://www.ecotourodisha.com/similipal-nature-camps.php'},
    {slno: '6', district: 'Nayagarh', division: 'Mahanadi WL', destination: 'Satkosia Sand Resorts', location: 'Satkosia Sand Resorts', link: 'https://www.ecotourodisha.com/satkosia-sand-resort-badmul-nature-camp.php'},
    {slno: '7', district: 'Nayagarh', division: 'Nayagarh TR', destination: 'Sidhamula Nature Camps', location: 'Sidhamula', link: 'https://www.ecotourodisha.com/sidhamula-nature-camp.php'},
    {slno: '8', district: 'Kendrapada', division: 'Rajnagar WL', destination: 'Bhitarakanika Nature Camps', location: 'Dangmal', link: 'https://www.ecotourodisha.com/bhitarakanika-nature-camps.php'},
    {slno: '9', district: 'Kendrapada', division: 'Rajnagar WL', destination: 'Bhitarakanika Nature Camps', location: 'Gupti', link: 'https://www.ecotourodisha.com/bhitarakanika-nature-camps.php'},
    {slno: '10', district: 'Kendrapada', division: 'Rajnagar WL', destination: 'Bhitarakanika Nature Camps', location: 'Habalikhati', link: 'https://www.ecotourodisha.com/bhitarakanika-nature-camps.php'},
    {slno: '11', district: 'Angul', division: 'Satokosia WL', destination: 'Satokosia Nature Camps', location: 'Baghamunda', link: 'https://www.ecotourodisha.com/satkosia-nature-camps.php'},
    {slno: '12', district: 'Angul', division: 'Satokosia WL', destination: 'Satokosia Nature Camps', location: 'Chhotkei', link: 'https://www.ecotourodisha.com/satkosia-nature-camps.php'},
    {slno: '13', district: 'Angul', division: 'Satokosia WL', destination: 'Satokosia Nature Camps', location: 'Purunakote', link: 'https://www.ecotourodisha.com/satkosia-nature-camps.php'},
    {slno: '14', district: 'Angul', division: 'Satokosia WL', destination: 'Satokosia Nature Camps', location: 'Tarava', link: 'https://www.ecotourodisha.com/satkosia-nature-camps.php'},
    {slno: '15', district: 'Angul', division: 'Satokosia WL', destination: 'Satokosia Nature Camps', location: 'Tikarpada', link: 'https://www.ecotourodisha.com/satkosia-nature-camps.php'},
    {slno: '16', district: 'Angul', division: 'Satokosia WL', destination: 'Satokosia Nature Camps', location: 'Baliput', link: 'https://www.ecotourodisha.com/satkosia-nature-camps.php'},
    {slno: '17', district: 'Bargarh', division: 'Hirakud WL', destination: 'Debrigarh Nature Camps', location: 'Debrigarh', link: 'https://www.ecotourodisha.com/debrigarh-nature-camp.php'},
    {slno: '18', district: 'Kandhamal', division: 'Baliguda TR', destination: 'Belghar Nature Camps', location: 'Belghar', link: 'https://www.ecotourodisha.com/belghar-nature-camp.php'},
    {slno: '19', district: 'Kandhamal', division: 'Baliguda TR', destination: 'Daringbadi Nature Camps', location: 'Daringbadi', link: 'https://www.ecotourodisha.com/daringbadi-nature-camp.php'},
    {slno: '20', district: 'Puri', division: 'Chilika WL', destination: 'Chilika Nature Camps', location: 'Rajhans Nature Camp', link: 'https://www.ecotourodisha.com/chilika-nature-camps.php'},
    {slno: '21', district: 'Puri', division: 'Chilika WL', destination: 'Chilika Nature Camps', location: 'Manglajodi Nature Camp', link: 'https://www.ecotourodisha.com/chilika-nature-camps.php'},
    {slno: '22', district: 'Puri', division: 'Chilika WL', destination: 'Chilika Nature Camps', location: 'Berhampura Nature Camp', link: 'https://www.ecotourodisha.com/chilika-nature-camps.php'},
    {slno: '23', district: 'Puri', division: 'Puri WL', destination: 'Nuanai Nature Camps', location: 'Nuanai', link: 'https://www.ecotourodisha.com/nuanai-nature-camp.php'},
    {slno: '24', district: 'Balasore', division: 'Balasore WL', destination: 'Bichitrapur Nature Camps', location: 'Bichitrapur', link: 'https://www.ecotourodisha.com/bichitrapur-nature-camp.php'},
    {slno: '25', district: 'Balasore', division: 'Balasore WL', destination: 'Chandipur Nature Camps', location: 'Chandipur', link: 'https://www.ecotourodisha.com/chandipur-nature-camp.php'},
    {slno: '26', district: 'Balasore', division: 'Balasore WL', destination: 'Rissia Nature Camps', location: 'Rissia', link: 'https://www.ecotourodisha.com/rissia-nature-camp.php'},
    {slno: '27', district: 'Kandhamal', division: 'Phulbani TR', destination: 'Phulbani Nature Camps', location: 'Phulbani Nature Camps', link: 'https://www.ecotourodisha.com/phulbani-nature-camps.php'},
    {slno: '28', district: 'Kandhamal', division: 'Phulbani TR', destination: 'Phulbani Nature Camps', location: 'Mandasaru Valley Nature Camps', link: 'https://www.ecotourodisha.com/phulbani-nature-camps.php'},
    {slno: '29', district: 'Sundergarh', division: 'Bonai TR', destination: 'Khandadhar Nature Camps', location: 'Khandadhar', link: 'https://www.ecotourodisha.com/khandadhar-nature-camp.php'},
    {slno: '30', district: 'Sundergarh', division: 'Sundergarh TR', destination: 'Sarafgarh Nature Camps', location: 'Sarafgarh', link: 'https://www.ecotourodisha.com/sarafgarh-nature-camp.php'},
    {slno: '31', district: 'Khordha', division: 'Chandaka WL', destination: 'Chandaka Nature Camps', location: 'Deras', link: 'https://www.ecotourodisha.com/chandaka-nature-camps.php'},
    {slno: '32', district: 'Khordha', division: 'Chandaka WL', destination: 'Chandaka Nature Camps', location: 'Godibari', link: 'https://www.ecotourodisha.com/chandaka-nature-camps.php'},
    {slno: '33', district: 'Keonjhar', division: 'Keonjhar TR', destination: 'Sanghagra Nature Camps', location: 'Sanghagra', link: 'https://www.ecotourodisha.com/sanaghagara-nature-camp.php'},
    {slno: '34', district: 'Ganjam', division: 'Ghumsur North', destination: 'Giant Squirrel Nature Camps', location: 'Kaliamba', link: 'https://www.ecotourodisha.com/giantsquirrel-nature-camp.php'},
    {slno: '35', district: 'Ganjam', division: 'Ghumsur North', destination: 'Blackbuk Nature Camps', location: 'Saluapali Nature Camp', link: 'https://www.ecotourodisha.com/blackbuck-nature-camp.php'},
    {slno: '36', district: 'Ganjam', division: 'Ghumsur North', destination: 'Blackbuk Nature Camps', location: 'Bhanjanagar Dam Nature Camp', link: 'https://www.ecotourodisha.com/blackbuck-nature-camp.php'},
    {slno: '37', district: 'Ganjam', division: 'Ghumsur South', destination: 'Blackbuk Nature Camps', location: 'Chermaria Nature Camp', link: 'https://www.ecotourodisha.com/blackbuck-nature-camp.php'},
    {slno: '38', district: 'Cuttack', division: 'Athgarh TR', destination: 'Ansupa Nature Camps', location: 'Ansupa', link: 'https://www.ecotourodisha.com/ansupa-nature-camp.php'},
    {slno: '39', district: 'Cuttack', division: 'Cuttack WL', destination: 'Mahaviyanak Nature Camps', location: 'Mahaviyanak', link: 'https://www.ecotourodisha.com/mahavinayak-nature-camp.php'},
    {slno: '40', district: 'Jajpur', division: 'Cuttack WL', destination: 'Olasuni Nature Camps', location: 'Olasuni', link: 'https://www.ecotourodisha.com/olasuni-nature-camp.php'},
    {slno: '41', district: 'Dhenkanal', division: 'Dhenkanal TR', destination: 'Saptasajya Nature Camps', location: 'Saptasajya', link: 'https://www.ecotourodisha.com/saptasajya-nature-camp.php'},
    {slno: '42', district: 'Malkangiri', division: 'Malkangiri', destination: 'Chitrakonda Nature Camps', location: 'Chitrakonda', link: 'https://www.ecotourodisha.com/chitrakonda-nature-camp.php'},
    {slno: '43', district: 'Malkangiri', division: 'Malkangiri', destination: 'Satiguda Nature Camps', location: 'Satiguda', link: 'https://www.ecotourodisha.com/satiguda-nature-camp.php'},
    {slno: '44', district: 'Nupada', division: 'Khariar', destination: 'Patora Dam Nature Camps', location: 'Patora Dam', link: 'https://www.ecotourodisha.com/patora-nature-camp.php'},
    {slno: '45', district: 'Sundergarh', division: 'Sundergarh TR', destination: 'Tensa Nature Camps', location: 'Patora Dam', link: 'https://www.ecotourodisha.com/tensa-nature-camp.php'},
    {slno: '46', district: 'Koraput', division: 'Koraput', destination: 'Koraput Nature Camps', location: 'Koraput', link: 'https://www.ecotourodisha.com/tensa-nature-camp.php'},
    {slno: '47', district: 'Khordha', division: 'Khordha TR', destination: 'Barbera Nature Camps', location: 'Barbera', link: 'https://www.ecotourodisha.com/berbera-nature-camp.php'},
  ];
  //dtOptions: { responsive: boolean; pagingType: string; pageLength: number; processing: boolean; dom: string; buttons: any[]; };

  constructor() { }

  dtOptions: any = {};

  ngOnInit(): void {

  	this.dtOptions = {
      responsive: true,
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: 'Bfrtip',
        buttons: [
            //'copy', 'excel', 'pdf', 'print'
        ]
    };

  	(function($){



    })(jQuery);

  }

}
