<div class="loader-base" *ngIf="loader">
    <div class="loader"></div>
  </div>
  <app-masterheader></app-masterheader>
  
  <div class="wrapper">
  
    <app-sidebar></app-sidebar>
  
    <div id="content">
  
      <!-- <app-breadcrumb></app-breadcrumb> -->
  
      <!-- <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
  
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Manage Squard</h4>
                   
                  </div>
                </div>
              </div>
            </div>
  
          </div>
        </div>
      </div> -->
  
  
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
  
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title"> <img src="assets/images/squad_view.png" alt="Squad Icon" style="width: 24px; height: 24px; margin-right: 8px;">View Squad User</h4>
                    <form [formGroup]="UserFilterForm" (ngSubmit)="onSubmitFilter()">
                      <div class="row">
                          <div class="col-md-6">
                              <div class="row">
                                  <div class="col-md-3 form-group mb-1">
                                      <label>Circle</label>
                                      <select class="form-control" id="circlewl"
                                          formControlName="circle"
                                          (change)="getAllDivisionByCircleId(circleSelect.value)"
                                          #circleSelect>
                                          <option value="0">All Circle</option>
                                          <option *ngFor="let circle of circleList "
                                              value={{circle.circleId}}>
                                              {{circle.circleName}}
                                          </option>
                                      </select>
                                  </div>
                                  <div class="col-md-3 form-group mb-1">
                                      <label>Division</label>
                                      <select class="form-control" id="divisionwl"
                                          formControlName="division"
                                          (change)="getAllRangeByDivId(divisionSelect.value)"
                                      
                                          #divisionSelect>
                                          <option value="0">All Division</option>
                                          <option
                                              *ngFor="let division of divisionlist "
                                              value={{division.divisionId}}>
                                              {{division.divisionName}}
                                          </option>
                                      </select>
                                  </div>
                                  <div class="col-md-3 form-group mb-1">
                                      <label>Range</label>
                                      <select class="form-control" id="rangewl"
                                          formControlName="range"
                                          (change)="getAllSectionByRangeId(rangeSelect.value)"
                                         #rangeSelect>
                                          <option value="0">All Range</option>
                                          <option *ngFor="let range of rangelist "
                                              value={{range.rangeId}}>
                                              {{range.rangeName}}
                                          </option>
                                      </select>
                                  </div>
                                  <div class="col-md-3 form-group mb-1">
                                      <label>Section</label>
                                      <select class="form-control" id="sectionwl"
                                          formControlName="section"
                                          (change)="getAllBeatBySectionId(sectionSelect.value)"
                                          #sectionSelect>
                                          <option value="0">All Section</option>
                                          <option *ngFor="let section of sectionlist "
                                              value={{section.secId}}>
                                              {{section.secName}}
                                          </option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="row">
                                  <div class="col-md-3 form-group mb-1">
                                      <label>Beat</label>
                                      <select class="form-control" id="beatwl"
                                          formControlName="beat" 
                                          #beatSelect>
                                          <option value="0">All Beat</option>
                                          <option *ngFor="let beat of beatlist "
                                              value={{beat.beatId}}>
                                              {{beat.beatName}}
                                          </option>
                                      </select>
                                  </div>
                                  <div class="col-md-3 form-group mb-1">
                                      <button type="submit"
                                          class="btn btn-sm btn-success custom-btn-top mr-1">View</button>
                                          <button type="button"
                                          class="btn btn-sm btn-danger custom-btn-top mr-1" (click)="resetFilter()">Reset</button>
                                  </div>
                              </div>
                          </div>


                          <!-- </div> -->
                      </div>
                      </form>
                    <hr>
  
  
                    <div  class="table-responsive loader-sm">
                 
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
          </div>
        </div>
      </div>
  
    </div>
  
    <div id="footer">
      <app-masterfooter></app-masterfooter>
    </div>
  
  </div>
  
