import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auditreport',
  templateUrl: './auditreport.component.html',
  styleUrls: ['./auditreport.component.css']
})
export class AuditreportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
