import { Component, OnInit } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var jQuery: any;

@Component({
  selector: 'app-manageprimarylink',
  templateUrl: './manageprimarylink.component.html',
  styleUrls: ['./manageprimarylink.component.css']
})
export class ManageprimarylinkComponent implements OnInit {

  public data = [
	    {slno: '1', englishlink: '14.98.15.241', odialink: '14.98.15.253', path: 'customlink', icon:'mdi mdi-account-box'},
	    {slno: '2', englishlink: '14.98.15.242', odialink: '14.98.15.254', path: 'customlink', icon:'mdi mdi-account-plus'},
	    {slno: '3', englishlink: '14.98.15.243', odialink: '14.98.15.255', path: 'customlink', icon:'mdi mdi-clipboard'},
	    {slno: '4', englishlink: '14.98.15.244', odialink: '14.98.15.257', path: 'customlink', icon:'mdi mdi-cloud-check'},
	    {slno: '5', englishlink: '14.98.15.245', odialink: '14.98.15.258', path: 'customlink', icon:'mdi mdi-application'},
	    {slno: '6', englishlink: '14.98.15.246', odialink: '14.98.15.259', path: 'customlink', icon:'mdi mdi-comment'},
	];

  constructor() { }

  dtOptions: any = {};

  ngOnInit(): void {

  	this.dtOptions = {
  	  responsive: true,
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      dom: 'Bfrtip',
        buttons: [
            //'copy', 'excel', 'pdf', 'print'
        ]
    };

  	(function($){
      	
    })(jQuery);
  }

}
