import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
declare var jQuery: any;

@Component({
  selector: 'app-homecontent',
  templateUrl: './homecontent.component.html',
  styleUrls: ['./homecontent.component.css']
})
export class HomecontentComponent implements OnInit {
  NewContentList: any;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {

  	(function($){

    })(jQuery);
    this.loadnewContent();
  }
  loadnewContent(){
    this.apiService.request('GET', 'api/v1/cms/getAllNewContent').subscribe((data: any) => {
      if (data) {
        this.NewContentList=data;
        //console.log(this.NewContentList)
      }
    });
  }

}
