// Created on : 25-02-2021
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import Map from 'ol/Map'
import * as layer from 'ol/layer';
import { Tile as Tile, Vector as VectorLayer } from 'ol/layer';
import { OSM as OSM, Vector as VectorSource, Vector as MeasureSource } from 'ol/source';
import XYZ from 'ol/source/XYZ';
import BingMaps from 'ol/source/BingMaps'
import ImageWMS from 'ol/source/ImageWMS';
import { View } from 'ol';
import * as control from 'ol/control';
import * as proj from 'ol/proj';
import Overlay from 'ol/Overlay';
import * as reqwest from 'reqwest';
import geom from 'ol/geom';
import { Location } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { WFS, GeoJSON } from 'ol/format';
import { defaults as defaultInteractions, Modify, Select } from 'ol/interaction';
import ZoomToExtent from 'ol/control/ZoomToExtent';
import { Fill, Stroke, Style, Text, Circle, Icon } from 'ol/style';
import { click, pointerMove, altKeyOnly } from 'ol/events/condition';
import * as interaction from 'ol/interaction';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getArea, getLength } from 'ol/sphere';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {
	equalTo as equalToFilter,
	like as likeFilter,
	and as andFilter
} from 'ol/format/filter';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../service/notification.service';
// import  Months  from  '../../assets/json/elephantAnalyticsMonths.json';
// import  Years  from  '../../assets/json/elephantAnalyticsYear.json';


declare var jQuery: any;

@Component({
	selector: 'app-elephantanalytics',
	templateUrl: './elephantanalytics.component.html',
	styleUrls: ['./elephantanalytics.component.css']
})
export class ElephantanalyticsComponent implements OnInit {
	public loader: boolean = false;
	public eleanalyticsmap: Map;
	divid: any;
	yrid: any;
	mntid: any;
	rangeid: any;
	sectnid: any;
	beatid: any;
	jsonDataYear: any[];
	jsonDataMonth: any[];
	rangeenable: boolean = true;
	monthenable: boolean = true;

	imegary: any;
	streetmap: any;
	_carto: any;
	_raster: any;

	divisionlist: any[];
	rangelist: any[];
	sectionlist: any[];
	beatlist: any[];
	analyticsForm: FormGroup;
	vectorparcelextendglobal: any;
	vectorrangeextendglobal: any;
  vectorparcelextendglobal_division: any;
  vectorparcelextendglobal_range: any;
	constructor(private apiService: ApiService,private notifyService:NotificationService, private ref: ChangeDetectorRef, private http: HttpClient, private formBuilder: FormBuilder, private toastr: ToastrService) {

	}

	ngOnInit(): void {
		// this.yrid="0";
		// this.mntid="0";

		this.divid = "0";
		this.rangeid = "0";
		this.sectnid = "0";
		this.beatid = "0";
		this.getAllDivision();
		this.startthemap();
		this.http.get('assets/json/elephantAnalyticsYear.json').subscribe((res: any[]) => {
			this.jsonDataYear = res;
		});
		this.http.get('assets/json/elephantAnalyticsMonths.json').subscribe((res: any[]) => {
			this.jsonDataMonth = res;
		});
		this.analyticsForm = this.formBuilder.group({
			division: [''],
			range: [''],
			//section : [''],
			//beat : [''],
			year: [''],
			frommonth: [''],
			//tomonth : ['']
		});

		(function ($) {

			$("document").ready(function () {
				$(".tab-slider--body").hide();
				$(".tab-slider--body:first").show();
			});

			$(".tab-slider--nav li").click(function () {
				$(".tab-slider--body").hide();
				var activeTab = $(this).attr("rel");
				$("#" + activeTab).fadeIn();
				if ($(this).attr("rel") == "tab2") {
					$('.tab-slider--tabs').addClass('slide');
				} else {
					$('.tab-slider--tabs').removeClass('slide');
				}
				$(".tab-slider--nav li").removeClass("active");
				$(this).addClass("active");
			});

			$(function () {

				/*$('#queryBtn').click(function () {
					$('#queryContent').slideToggle("slow");
					$('.rotate').toggleClass("down");
				});*/
				/*$('.rotate').click(function () {
					$(this).toggleClass("down");
				});*/
			});

			$(function () {
				$("#datepicker, #datepicker2").datepicker({
					autoclose: true,
					todayHighlight: true
				}).datepicker('update', new Date());
			});
		})(jQuery);
      setTimeout(() => {
      const toSelectyear = this.jsonDataYear.find(c => c.yearID == 0);
      const toSelectmonth = this.jsonDataYear.find(c => c.monthID == 0);
      this.analyticsForm.get('year').setValue(toSelectyear);
      this.analyticsForm.get('frommonth').setValue(toSelectmonth);
    }, 10000);
	}

	startthemap() {
		var raster = new Tile({
			type: 'base',
			title: 'Google Satelite',
			visible: false,
			source: new OSM()
		});
		// var raster = new Tile({
		// 	visible: false,
		// 	source: new OSM()
		//   });
		var carto = new Tile({
			title: 'Cartography',
			visible: false,
			type: 'base',
			source: new XYZ({
				url: 'https://{1-4}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
			})
		});
		var imegary = new Tile({
			visible: false,
			source: new OSM({
				url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
			})
		});

		var streerMap = new Tile({
			visible: true,
			source: new OSM({
				url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
			})
		});

		// State Boundary

    var _StateBoundary = new layer.Image({
      source: new ImageWMS({
        url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wms',
        params: {
          'FORMAT': 'image/png',
          'VERSION': '1.1.0',
          STYLES: '',
          LAYERS: 'iwlms:odisha_state_boundary'
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
      }),
      visible: true
    });
		// info window pop up
		var container = document.getElementById('popup');
		var content1 = document.getElementById('popup-content');
		let closer1 = document.getElementById('popup-closer');

		this.imegary = imegary;
		this.streetmap = streerMap;
		this._carto = carto;
		this._raster = raster;

		var maplocal;
		// set the map
		setTimeout(() => {
			maplocal = new Map({
				layers:
					[
						raster, carto, imegary, streerMap, _StateBoundary,
					],
				controls: [
					new control.Zoom(), new control.ZoomSlider(), new control.ScaleLine({
						units: 'metric',
						bar: true,
						steps: 4,
						text: true,
						minWidth: 140
					}),],
				overlays: [],
				target: 'elephantAnalyticsMap',
				view: new View({
					center: proj.fromLonLat([85.190055, 20.886785]),
					zoom: 7,
					maxZoom: 23,
					minZoom: 5,
				})
			});

			this.eleanalyticsmap = maplocal;
			var ds = this;
			// Info window in map on click
			// maplocal.on('singleclick',function(evt){
			// 	content1.innerHTML = "";
			// 	//Balasore division
			// 	if(ds.divid == "1")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if(ds.sectnid != "0")
			// 			{
			// 				if(ds.beatid != "0")
			// 				{
			// 					var url = balesoreBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = balesoresection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								//console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = balesorerange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								//console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _BalesoreDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				//console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// 	//Baliguda division
			// 	else if(ds.divid == "2")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if(ds.sectnid != "0")
			// 			{
			// 				if(ds.beatid != "0")
			// 				{
			// 					var url = baligudaBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = baligudasection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					////console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = baligudarange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _BaligudaDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				////console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// 	//Bamara division
			// 	else if(ds.divid == "3")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if(ds.sectnid != "0")
			// 			{
			// 				if(ds.beatid != "0")
			// 				{
			// 					var url = bamaraBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = bamarasection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					////console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = bamararange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _BamaraDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				////console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// 	//Bhadrak division
			// 	else if(ds.divid == "4")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if(ds.sectnid != "0")
			// 			{
			// 				if(ds.beatid != "0")
			// 				{
			// 					var url = bhadrakBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = bhadraksection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					////console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = bhadrakrange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _BhadrakDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				////console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// 	//Chandaka division
			// 	else if(ds.divid == "5")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if(ds.sectnid != "0")
			// 			{
			// 				if(ds.beatid != "0")
			// 				{
			// 					var url = chandakaBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = chandakasection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					////console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = chandakarange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _ChandakaDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				////console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// 	//chilika division
			// 	else if(ds.divid == "6")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if(ds.sectnid != "0")
			// 			{
			// 				if(ds.beatid != "0")
			// 				{
			// 					var url = chilikaBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = chilikasection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					////console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = chilikarange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _ChilikaDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				////console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// 	//Dhenkanal division
			// 	else if(ds.divid == "7")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if(ds.sectnid != "0")
			// 			{
			// 				if(ds.beatid != "0")
			// 				{
			// 					var url = dhenkanalBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = dhenkanalsection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					////console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = dhenkanalrange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _DhenkanalDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				////console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// 	//hirakuda division
			// 	else if(ds.divid == "8")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if(ds.sectnid != "0")
			// 			{
			// 				if(ds.beatid != "0")
			// 				{
			// 					var url = hirakudBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = hirakudsection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					////console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = hirakudrange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _HirakudDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				////console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// 	//KalahandiSouth division
			// 	else if(ds.divid == "9")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if(ds.sectnid != "0")
			// 			{
			// 				if(ds.beatid != "0")
			// 				{
			// 					var url = kalahandiSBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = kalahandiSsection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					////console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = kalahamdiSrange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _KalahandiSDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				////console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// 	//keonjhar division
			// 	else if(ds.divid == "10")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if(ds.sectnid != "0")
			// 			{
			// 				if(ds.beatid != "0")
			// 				{
			// 					var url =kaeojharaBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = kaeojharasection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					////console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = kaeojhararange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _KeonjharDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				////console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// 	//Mahanadi division
			// 	else if(ds.divid == "11")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if(ds.sectnid != "0")
			// 			{
			// 				if(ds.beatid != "0")
			// 				{
			// 					var url = mahanadiBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = mahanadisection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					////console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = mahanadirange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _MahanadiDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				////console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// 	//Nandankanan division
			// 	else if(ds.divid == "12")
			// 	{
			// 		// if(ds.rangeid != "0")
			// 		// {
			// 		// 	if(ds.sectnid != "0")
			// 		// 	{
			// 		// 		if(ds.beatid != "0")
			// 		// 		{
			// 		// 			var url = Nandankananbeat.getSource().getFeatureInfoUrl(
			// 		// 				evt.coordinate, maplocal.getView().getResolution(),
			// 		// 				maplocal.getView().getProjection(),
			// 		// 				{ 'INFO_FORMAT': 'application/json' });
			// 		// 			if (url) {
			// 		// 				fetch(url)
			// 		// 					.then(function (response) { return response.json(); })
			// 		// 					.then(function (html) {
			// 		// 						var fetchfeatures = html.features[0];
			// 		// 						////console.error(fetchfeatures)
			// 		// 						var info =
			// 		// 							"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 		// 							"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 		// 							"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 		// 							"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 		// 							"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 		// 						content1.innerHTML = info;
			// 		// 						overlay.setPosition(evt.coordinate);
			// 		// 					});
			// 		// 			}
			// 		// 		}
			// 		// 		else
			// 		// 		{
			// 		// 			// till section info window will show
			// 		// 			var url = nandankanansection.getSource().getFeatureInfoUrl(
			// 		// 				evt.coordinate, maplocal.getView().getResolution(),
			// 		// 				maplocal.getView().getProjection(),
			// 		// 				{ 'INFO_FORMAT': 'application/json' });
			// 		// 			////console.warn(url);
			// 		// 			if (url) {
			// 		// 				fetch(url)
			// 		// 					.then(function (response) { return response.json(); })
			// 		// 					.then(function (html) {
			// 		// 						var fetchfeatures = html.features[0];
			// 		// 						////console.error(fetchfeatures)
			// 		// 						var info =
			// 		// 							"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 		// 							"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 		// 							"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 		// 							"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 		// 						content1.innerHTML = info;
			// 		// 						overlay.setPosition(evt.coordinate);
			// 		// 					});
			// 		// 			}
			// 		// 		}
			// 		// 	}
			// 		// 	else
			// 		// 	{
			// 		// 		// Till Range info window will show
			// 		// 			var url = nandankananrange.getSource().getFeatureInfoUrl(
			// 		// 				evt.coordinate, maplocal.getView().getResolution(),
			// 		// 				maplocal.getView().getProjection(),
			// 		// 				{ 'INFO_FORMAT': 'application/json' });
			// 		// 			//console.warn(url);
			// 		// 			if (url) {
			// 		// 				fetch(url)
			// 		// 					.then(function (response) { return response.json(); })
			// 		// 					.then(function (html) {
			// 		// 						var fetchfeatures = html.features[0];
			// 		// 						////console.error(fetchfeatures)
			// 		// 						var info =
			// 		// 							"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 		// 							"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 		// 							"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 		// 						content1.innerHTML = info;
			// 		// 						overlay.setPosition(evt.coordinate);
			// 		// 					});
			// 		// 			}
			// 		// 	}
			// 		// }
			// 		// else
			// 		// {
			// 		// 	// till Division info window will show
			// 		// 		var url = _nandankananDivisionBoundary.getSource().getFeatureInfoUrl(
			// 		// 			evt.coordinate, maplocal.getView().getResolution(),
			// 		// 			maplocal.getView().getProjection(),
			// 		// 			{ 'INFO_FORMAT': 'application/json' });
			// 		// 		////console.warn(url);
			// 		// 		if (url) {
			// 		// 			fetch(url)
			// 		// 				.then(function (response) { return response.json(); })
			// 		// 				.then(function (html) {
			// 		// 					var fetchfeatures = html.features[0];
			// 		// 					//console.error(fetchfeatures)
			// 		// 					var info =
			// 		// 					//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 		// 						"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 		// 						"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 		// 					content1.innerHTML = info;
			// 		// 					overlay.setPosition(evt.coordinate);
			// 		// 				});
			// 		// 		}
			// 		// }
			// 	}
			// 	// paralakhemundi
			// 	else if(ds.divid == "13")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if(ds.sectnid != "0")
			// 			{
			// 				if(ds.beatid != "0")
			// 				{
			// 					var url = paralaBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = paralasection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					////console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = paralarange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _ParalaDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				////console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// 	// puri
			// 	else if(ds.divid == "14")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if(ds.sectnid != "0")
			// 			{
			// 				if(ds.beatid != "0")
			// 				{
			// 					var url = puriBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = purisection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					////console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = purirange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _PuriDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				////console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// 	// Rajanagar
			// 	else if(ds.divid == "15")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if(ds.sectnid != "0")
			// 			{
			// 				if(ds.beatid != "0")
			// 				{
			// 					var url = rajanagarBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = rajanagarsection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					////console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = rajanagarrange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _RajanagarDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				////console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// 	// satakosia
			// 	else if(ds.divid == "16")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if(ds.sectnid != "0")
			// 			{
			// 				if(ds.beatid != "0")
			// 				{
			// 					var url = satakosiaBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = satakosiasection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					////console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = satakosiarange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _SatakosiaDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				////console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// 	// similipala N and S (Baripada)
			// 	else if(ds.divid == "17" || ds.divid == "18")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if (ds.sectnid != "0")
			// 			{
			// 				if (ds.beatid != "0")
			// 				{
			// 					var url = baripadaBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 						if (url){
			// 							fetch(url)
			// 								.then(function (response) { return response.json(); })
			// 								.then(function (html) {
			// 									var fetchfeatures = html.features[0];
			// 									////console.error(fetchfeatures)
			// 									var info =
			// 										"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 										"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 										"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 										"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 										"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 									content1.innerHTML = info;
			// 									overlay.setPosition(evt.coordinate);
			// 								});
			// 						}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = baripadasection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					////console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = baripadarange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _BaripadaDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				////console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// 	// Sunabeda
			// 	else if(ds.divid == "19")
			// 	{
			// 		if(ds.rangeid != "0")
			// 		{
			// 			if(ds.sectnid != "0")
			// 			{
			// 				if(ds.beatid != "0")
			// 				{
			// 					var url = sunabedaBeat.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Beat Name:</b>" + fetchfeatures.properties.beat_name + "<br/>" +
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Beat Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 				else
			// 				{
			// 					// till section info window will show
			// 					var url = sunabedasection.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					////console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Section Name:</b>" + fetchfeatures.properties.sec_name + "<br/>" +
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Section Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 				}
			// 			}
			// 			else
			// 			{
			// 				// Till Range info window will show
			// 					var url = sunabedarange.getSource().getFeatureInfoUrl(
			// 						evt.coordinate, maplocal.getView().getResolution(),
			// 						maplocal.getView().getProjection(),
			// 						{ 'INFO_FORMAT': 'application/json' });
			// 					//console.warn(url);
			// 					if (url) {
			// 						fetch(url)
			// 							.then(function (response) { return response.json(); })
			// 							.then(function (html) {
			// 								var fetchfeatures = html.features[0];
			// 								////console.error(fetchfeatures)
			// 								var info =
			// 									"<b>Range Name:</b>" + fetchfeatures.properties.rng_name + "<br/>" +
			// 									"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 									"<b>Range Area(in sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 								content1.innerHTML = info;
			// 								overlay.setPosition(evt.coordinate);
			// 							});
			// 					}
			// 			}
			// 		}
			// 		else
			// 		{
			// 			// till Division info window will show
			// 				var url = _SunabedaDivisionBoundary.getSource().getFeatureInfoUrl(
			// 					evt.coordinate, maplocal.getView().getResolution(),
			// 					maplocal.getView().getProjection(),
			// 					{ 'INFO_FORMAT': 'application/json' });
			// 				////console.warn(url);
			// 				if (url) {
			// 					fetch(url)
			// 						.then(function (response) { return response.json(); })
			// 						.then(function (html) {
			// 							var fetchfeatures = html.features[0];
			// 							//console.error(fetchfeatures)
			// 							var info =
			// 							//"<b>Division Code:</b>" + fetchfeatures.properties.divn_code + "<br/>" +
			// 								"<b>Division Name:</b>" + fetchfeatures.properties.divn_name + "<br/>" +
			// 								"<b>Division Area(sqm):</b>" + fetchfeatures.properties.area_sqkm + "<br/>";
			// 							content1.innerHTML = info;
			// 							overlay.setPosition(evt.coordinate);
			// 						});
			// 				}
			// 		}
			// 	}
			// });
		}, 100);

	}
	elephantpoints: any;
	onSubmit() {
		//console.log(this.analyticsForm);
		var url;
		var query;
		if (this.elephantpoints) {
			this.eleanalyticsmap.removeLayer(this.elephantpoints);
		}
		//division not selected
		if (this.analyticsForm.controls.division.value == "0") {
			// show the alert
			this.notifyService.showWarning('Message', 'Please Select Division');
		}
		// division selected
		else {
			  // no range selected
        if (this.analyticsForm.controls.range.value == "0") {
          // no year selected
          if (this.analyticsForm.controls.year.value == null) {
            // only division will pass in query
            query = "divn_code='" + this.analyticsForm.controls.division.value + "'";
          }
          // you got year selected here
          else {
            // year more than 1
            if (this.analyticsForm.controls.year.value.length > 1) {
              // no month selected
              if (this.analyticsForm.controls.frommonth.value == null) {
                var years = this.analyticsForm.controls.year.value;
                var queryforelement;
                var queryfinal;
                for (let index = 0; index < years.length; index++) {
                  const element = years[index];
                  queryforelement = "divn_code='" + this.analyticsForm.controls.division.value + "'" + "and yr='" + element + "'"
                  if (index == 0) {
                    queryfinal = queryforelement;
                  }
                  else {
                    queryfinal = queryfinal + " or " + queryforelement;
                  }

                  if (index + 1 == years.length) {
                    query = queryfinal;
                  }

                }
              }
              // month selected
              else {
                // month is more than 1
                if (this.analyticsForm.controls.frommonth.value.length > 1) {
                  var years = this.analyticsForm.controls.year.value;
                  var months = this.analyticsForm.controls.frommonth.value;
                  var queryforelement;
                  var queryforelementjoined;
                  var queryfinal;
                  for (let index1 = 0; index1 < years.length; index1++) {
                    for (let index2 = 0; index2 < months.length; index2++) {
                      queryforelement = "divn_code='" + this.analyticsForm.controls.division.value + "'" + "and yr='" + years[index1] + "' " + "and mt='" + months[index2] + "'";
                      if (index1 == 0 && index2 == 0) {
                        queryfinal = queryforelement;
                      }
                      else {
                        queryfinal = queryfinal + " or " + queryforelement;
                      }
                      if (index1 + 1 == years.length && index2 + 1 == months.length) {
                        query = queryfinal;
                      }
                    }
                  }
                }
                // month single
                else {
                  var years = this.analyticsForm.controls.year.value;
                  var queryforelement;
                  var queryfinal;
                  for (let index = 0; index < years.length; index++) {
                    const element = years[index];
                    queryforelement = "divn_code='" + this.analyticsForm.controls.division.value + "'" + "and yr='" + element + "'" + "and mt='" + this.analyticsForm.controls.frommonth.value + "'";
                    if (index == 0) {
                      queryfinal = queryforelement;
                      //console.log(queryfinal);
                    }
                    else {
                      queryfinal = queryfinal + " or " + queryforelement;
                      //console.log(queryfinal)
                    }

                    if (index + 1 == years.length) {
                      //console.error(queryfinal);
                      query = queryfinal;
                    }
                  }
                }
              }
            }
            // year single
            else {
              // no month selected
              if (this.analyticsForm.controls.frommonth.value == null) {
                query = "divn_code='" + this.analyticsForm.controls.division.value + "'" + "and yr='" + this.analyticsForm.controls.year.value + "'";
              }
              //month selected here
              else {
                // more than 1 month
                if (this.analyticsForm.controls.frommonth.value.length > 1) {
                  var months = this.analyticsForm.controls.frommonth.value;
                  var queryforelement;
                  var queryfinal;
                  for (let index = 0; index < months.length; index++) {
                    const element = months[index];
                    queryforelement = "divn_code='" + this.analyticsForm.controls.division.value + "'" + "and yr='" + this.analyticsForm.controls.year.value + "'" + "and mt='" + element + "'";
                    if (index == 0) {
                      queryfinal = queryforelement;
                    }
                    else {
                      queryfinal = queryfinal + " or " + queryforelement;
                    }
                    if (index + 1 == months.length) {
                      //console.error(queryfinal);
                      query = queryfinal;
                    }
                  }
                }
                // single month
                else {
                  query = "divn_code='" + this.analyticsForm.controls.division.value + "'" + "and yr='" + this.analyticsForm.controls.year.value + "'" + "and mt='" + this.analyticsForm.controls.frommonth.value + "'";
                }
              }
            }
          }
        }
        // range selected here
        else {
          // no year selected
          if (this.analyticsForm.controls.year.value == null) {
            // only division will pass in query
            query = "divn_code='" + this.analyticsForm.controls.division.value + "'" + "and rng_code='" + this.analyticsForm.controls.range.value + "'";
          }
          // you got year selected here
          else {
            // year more than 1
            console.log(this.analyticsForm.controls.year.value)
            if (this.analyticsForm.controls.year.value.length > 1) {
              // no month selected
              if (this.analyticsForm.controls.frommonth.value == null) {
                var years = this.analyticsForm.controls.year.value;
                var queryforelement;
                var queryfinal;
                for (let index = 0; index < years.length; index++) {
                  const element = years[index];
                  queryforelement = "divn_code='" + this.analyticsForm.controls.division.value + "'" + "and rng_code='" + this.analyticsForm.controls.range.value + "'" + "and yr='" + element + "'"
                  if (index == 0) {
                    queryfinal = queryforelement;
                  }
                  else {
                    queryfinal = queryfinal + " or " + queryforelement;
                  }
                  if (index + 1 == years.length) {
                    query = queryfinal;
                  }
                }
              }
              // month selected
              else {
                // month is more than 1
                if (this.analyticsForm.controls.frommonth.value.length > 1) {
                  var years = this.analyticsForm.controls.year.value;
                  var months = this.analyticsForm.controls.frommonth.value;
                  var queryforelement;
                  var queryforelementjoined;
                  var queryfinal;
                  for (let index1 = 0; index1 < years.length; index1++) {
                    for (let index2 = 0; index2 < months.length; index2++) {
                      queryforelement = "divn_code='" + this.analyticsForm.controls.division.value + "'" + "and rng_code='" + this.analyticsForm.controls.range.value + "'" + "and yr='" + years[index1] + "' " + "and mt='" + months[index2] + "'";
                      if (index1 == 0 && index2 == 0) {
                        queryfinal = queryforelement;
                      }
                      else {
                        queryfinal = queryfinal + " or " + queryforelement;
                      }
                      if (index1 + 1 == years.length && index2 + 1 == months.length) {
                        query = queryfinal;
                      }
                    }
                  }
                }
                // month single
                else {
                  var years = this.analyticsForm.controls.year.value;
                  var queryforelement;
                  var queryfinal;
                  for (let index = 0; index < years.length; index++) {
                    const element = years[index];
                    queryforelement = "divn_code='" + this.analyticsForm.controls.division.value + "'" + "and rng_code='" + this.analyticsForm.controls.range.value + "'" + "and yr='" + element + "'" + "and mt='" + this.analyticsForm.controls.frommonth.value + "'";
                    if (index == 0) {
                      queryfinal = queryforelement;
                      //console.log(queryfinal);
                    }
                    else {
                      queryfinal = queryfinal + " or " + queryforelement;
                      //console.log(queryfinal)
                    }
                    if (index + 1 == years.length) {
                      //console.error(queryfinal);
                      query = queryfinal;
                    }
                  }
                }
              }
            }
            // year single
            else {
              // no month selected
              if (this.analyticsForm.controls.frommonth.value == null) {
                query = "divn_code='" + this.analyticsForm.controls.division.value + "'" + "and rng_code='" + this.analyticsForm.controls.range.value + "'" + "and yr='" + this.analyticsForm.controls.year.value + "'";
              }
              //month selected here
              else {
                // more than 1 month
                if (this.analyticsForm.controls.frommonth.value.length > 1) {
                  var months = this.analyticsForm.controls.frommonth.value;
                  var queryforelement;
                  var queryfinal;
                  for (let index = 0; index < months.length; index++) {
                    const element = months[index];
                    queryforelement = "divn_code='" + this.analyticsForm.controls.division.value + "'" + "and rng_code='" + this.analyticsForm.controls.range.value + "'" + "and yr='" + this.analyticsForm.controls.year.value + "'" + "and mt='" + element + "'";
                    if (index == 0) {
                      queryfinal = queryforelement;
                    }
                    else {
                      queryfinal = queryfinal + " or " + queryforelement;
                    }
                    if (index + 1 == months.length) {
                      //console.error(queryfinal);
                      query = queryfinal;
                    }
                  }
                }
                // single month
                else {
                  query = "divn_code='" + this.analyticsForm.controls.division.value + "'" + "and rng_code='" + this.analyticsForm.controls.range.value + "'" + "and yr='" + this.analyticsForm.controls.year.value + "'" + "and mt='" + this.analyticsForm.controls.frommonth.value + "'";
                }
              }
            }
          }
        }
		}
		console.warn(query);
		var elephant_sighting = new layer.Image({
			source: new ImageWMS({
				url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wms',
				params: {
					'FORMAT': 'image/png',
					'VERSION': '1.1.0',
					STYLES: '',
					LAYERS: 'iwlms:elephant_sighting',
					CQL_FILTER: query
				},
				serverType: 'geoserver',
				crossOrigin: 'anonymous'
			}),
			visible: true
		});
		this.elephantpoints = elephant_sighting;
		this.eleanalyticsmap.addLayer(elephant_sighting);
	}
	reset() {
		location.reload();
	}
	getAllDivision() {
		return this.apiService.request('GET', 'api/v1/masters/getAllDivision').subscribe((data: any) => {
			if (data) {
				this.divisionlist = data;
			}
		});
	}
	DivisionFilter(value: string) {
		this.divid = value;
		if (value == "0") {
			this.rangeid = "0";
			this.sectnid = "0";
			this.beatid = "0";
			this.rangelist = [];
			this.sectionlist = [];
			this.beatlist = [];
		}
		else {
			this.apiService.request('GET', 'api/v1/masters/getAllRangeByDivid?id=' + value).subscribe((data: any) => {
				if (data) {
					this.rangelist = data;
				}
			});
			this.rangeid = "0";
			this.sectnid = "0";
			this.beatid = "0";
			this.rangelist = [];
			this.sectionlist = [];
			this.beatlist = [];

			if (this.elephantpoints) {
				this.eleanalyticsmap.removeLayer(this.elephantpoints);
			}
      var layername_div = 'https://odishaforestgis.in/ofms/index.php/webservice_api/getDivisionGeojson';
      var querydiv, querycircle = "";
      if (this.divid != "0") {
        querydiv = "{\"division_id\" : \"" + this.divid + "\"}";
      }
      if (this.divid != "0") {
        //querycircle = "circle_id='" + this.circleid + "'";
        if (querydiv != "") {
          let formdata:FormData = new FormData();
          formdata.append('data',querydiv);
          try{
          this.apiService.requestGeoJson(layername_div,formdata).subscribe((response: any[]) => {
            if (response) {
            if(response["status"]==1){
              if (this.vectorparcelextendglobal_range) {
              this.eleanalyticsmap.removeLayer(this.vectorparcelextendglobal_range);
              }
              if (this.vectorparcelextendglobal_division) {
              this.eleanalyticsmap.removeLayer(this.vectorparcelextendglobal_division);
              }
              var name = this.divisionlist.find(element => element.divisionId == this.divid).divisionName;
              const vectorSource = new VectorSource();
              const styleFunction = [
                new Style({
                  stroke: new Stroke({
                    color: 'rgba(31, 28, 28, 2.5)',
                    width: 3,
                    lineDash: [.1, 5] //or other combinations
                  }),
                  fill: new Fill({
                    color: 'rgba(32, 28, 28, 0.1)',
                  }),	text: new Text({
                    text: name,
                    scale: 1.8,
                    stroke: new Stroke({
                      color: '#000',
                      width: 0.5,
                    })
                  }),
                })];
              var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
              var gjFormat = new GeoJSON({
                featureProjection: 'EPSG:3857',
              });
              var features = gjFormat.readFeatures(geojson);
              vectorSource.clear();
              var featureOverlayNew = new VectorLayer({
                //map: this.map,
                source: vectorSource,
                style: styleFunction,
                updateWhileAnimating: true,
                updateWhileInteracting: true
              });
              featureOverlayNew.getSource().addFeatures(features);
              this.eleanalyticsmap.addLayer(featureOverlayNew);
              this.eleanalyticsmap.getView().setZoom(10);
              this.eleanalyticsmap.getView().fit(featureOverlayNew.getSource().getExtent(), "");
              this.vectorparcelextendglobal_division=featureOverlayNew;
              this.loader=false;
            }else{
              this.notifyService.showError("Map Data Not Available","Error");
              this.loader=false
            }
            }else{
            this.notifyService.showError("Map Data Not Available","Error");
              this.loader=false
            }
          },(error) => {
            const errors = error;
            this.loader=false;

          });
          }catch(e){
            this.notifyService.showError("Something Error!!","Error");
            this.loader=false
          }finally{
            this.loader=false
          }
        }
      }
		}
		this.ref.detectChanges();

	}
	Rangefilter(value: string) {
		if (value == "0") {
			this.sectnid = "0";
			this.beatid = "0";
			this.sectionlist = [];
			this.beatlist = [];
		}
		else {
			var queryrange;
			this.apiService.request('GET', 'api/v1/masters/getAllSectionByRangeid?id=' + value).subscribe((data: any) => {
				if (data) { this.sectionlist = data; }
			});
			this.beatid = "0";
			this.beatlist = [];
      var layername_range = 'https://odishaforestgis.in/ofms/index.php/webservice_api/getRangeGeojson';
      var querydiv, querycircle = "";
      if (this.rangeid != "0") {
        queryrange = "{\"range_id\" : \"" + this.rangeid + "\"}";
      }
      if (this.rangeid != "0") {
        //querycircle = "circle_id='" + this.circleid + "'";
        if (queryrange != "") {
          let formdata:FormData = new FormData();
          formdata.append('data',queryrange);
          try{
          this.apiService.requestGeoJson(layername_range,formdata).subscribe((response: any[]) => {
            if (response) {
            if(response["status"]==1){
              if (this.vectorparcelextendglobal_range) {
              this.eleanalyticsmap.removeLayer(this.vectorparcelextendglobal_range);
              }
              var name = this.rangelist.find(element => element.rangeId == value).rangeName;
              const styleFunction = [
                new Style({
                  stroke: new Stroke({
                    color: 'rgba(255, 165, 0, 2.5)',
                    width: 2,
                  }),
                  fill: new Fill({
                    color: 'rgba(255, 165, 0, 0.1)',
                  }),	text: new Text({
                    text: name,
                    scale: 1.8,
                    stroke: new Stroke({
                      color: 'rgba(255, 165, 0, 2.5)',
                      width: 0.5,
                    })
                  }),
                })];
                const vectorSource = new VectorSource();
                var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
                var gjFormat = new GeoJSON({
                  featureProjection: 'EPSG:3857',
                });
                var features = gjFormat.readFeatures(geojson);
                vectorSource.clear();
                var featureOverlayNew = new VectorLayer({
                  source: vectorSource,
                  style: styleFunction,
                  updateWhileAnimating: true,
                  updateWhileInteracting: true
                });
              featureOverlayNew.getSource().addFeatures(features);
              this.eleanalyticsmap.addLayer(featureOverlayNew);
              this.eleanalyticsmap.getView().setZoom(10);
              this.eleanalyticsmap.getView().fit(featureOverlayNew.getSource().getExtent(), "");
              this.vectorparcelextendglobal_range=featureOverlayNew;
              this.loader=false;
            }else{
              this.notifyService.showError("Map Data Not Available","Error");
              this.loader=false
            }
            }else{
            this.notifyService.showError("Map Data Not Available","Error");
              this.loader=false
            }
          },(error) => {
            const errors = error;
            this.loader=false;

          });
          }catch(e){
            this.notifyService.showError("Something Error!!","Error");
            this.loader=false
          }finally{
            this.loader=false
          }
        }
      }
		}
		this.ref.detectChanges();
	}
	SectionFilter(value: string) {
		if (value == "0") {
			this.beatid = "0";
			this.beatlist = [];
		}
		else {
			this.apiService.request('GET', 'api/v1/masters/getAllBeatBySecId?id=' + value).subscribe((data: any) => {
				if (data) {
					this.beatlist = data;
				}
			});
		}
		this.ref.detectChanges();
	}
	BeatFilter(value: string) {
	}
	topochange(e) {
		if (e.target.checked == true) {
			this._carto.setVisible(true);
			this.imegary.setVisible(false);
			this.streetmap.setVisible(false);
			this._raster.setVisible(false);
		}
		else if (e.target.checked == false) {
			this._carto.setVisible(false);
		}
		var streetbtn = document.getElementById("streetBTN") as HTMLInputElement;
		var imagerybtn = document.getElementById("imageryBTN") as HTMLInputElement;
		var natgeoBTN = document.getElementById("natgeoBTN") as HTMLInputElement;

		streetbtn.checked = false;
		imagerybtn.checked = false;
		natgeoBTN.checked = false;

		//topoBTN streetBTN imageryBTN natgeoBTN
	}
	imagerychange(e) {
		if (e.target.checked == true) {
			this.imegary.setVisible(true);
			this.streetmap.setVisible(false);
			this._carto.setVisible(false);
			this._raster.setVisible(false);
		}
		else if (e.target.checked == false) {
			this.imegary.setVisible(false);
		}
		var streetbtn = document.getElementById("streetBTN") as HTMLInputElement;
		var topoBTN = document.getElementById("topoBTN") as HTMLInputElement;
		var natgeoBTN = document.getElementById("natgeoBTN") as HTMLInputElement;

		streetbtn.checked = false;
		topoBTN.checked = false;
		natgeoBTN.checked = false;
	}
	streetchange(e) {
		var imagerybtn = document.getElementById("imageryBTN") as HTMLInputElement;
		var topoBTN = document.getElementById("topoBTN") as HTMLInputElement;
		var natgeoBTN = document.getElementById("natgeoBTN") as HTMLInputElement;

		imagerybtn.checked = false;
		topoBTN.checked = false;
		natgeoBTN.checked = false;
		if (e.target.checked == true) {
			this.streetmap.setVisible(true);
			this.imegary.setVisible(false);
			this._raster.setVisible(false);
			this._carto.setVisible(false);
		}
		else if (e.target.checked == false) {
			this.streetmap.setVisible(false);
		}
	}
	// bingmapchange(e) {
	// 	if (e.target.checked == true) {
	// 		this._raster.setVisible(true);
	// 		this.streetmap.setVisible(false);
	// 		this.imegary.setVisible(false);
	// 		this._raster.setVisible(false);
	// 	 }
	// 	else if (e.target.checked == false) {
	// 		this._raster.setVisible(false);
	// 	 }
	// 	var imagerybtn = document.getElementById("imageryBTN") as HTMLInputElement;
	// 	var topoBTN =  document.getElementById("topoBTN") as HTMLInputElement;
	// 	var streetbtn =  document.getElementById("streetBTN") as HTMLInputElement;

	// 	imagerybtn.checked= false;
	// 	topoBTN.checked= false;
	// 	streetbtn.checked= false;
	// }

}
