import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { ApiService } from '../service/api.service';
const baseUrlFile = environment.FILE_API_URL;

@Component({
  selector: 'app-publication',
  templateUrl: './publication.component.html',
  styleUrls: ['./publication.component.css']
})
export class PublicationComponent implements OnInit {

 // public loader: boolean=false;
  Gtlist: any[];
  publist: any;
  PubListArray: any[];
  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.getAllpublication();
  }
  getAllpublication() {
   // this.loader=true;
    this.apiService.request('GET', 'api/v1/cms/getAllPublication').subscribe((data: any) => {
      if (data) {
        this.PubListArray = new Array() ;
        data.sort(function(a, b) {
          return b.seqNo- a.seqNo;
        });
        data.map(item=>{
          return {
            thumbnilImgPath : baseUrlFile+'publication/'+item['thumbnilImgPath'],
            pdfPath: baseUrlFile+'publication/'+item['pdfPath'],
            publicationTitle: item['publicationTitle']
          }
        }).forEach(item=>  this.PubListArray.push(item));
      //  this.loader=false;
      }
    },err=>{
      //this.loader = false;
    });
  }
  defaultUrl(event) {
    event.target.src='assets/images/no_img.png';
  }

}
