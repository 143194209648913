
<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
  <div class="container">
    <div class="row">
      <div class="col-md-12">

        <h4 class="card-title">About Wildlife Organization</h4>
        <p>The <strong>State Wildlife Organisation</strong>  was formed on 14th August 1974,as sequel to adoption of the Wildlife (Protection) Act, 1972; and promulgation of the Wildlife (Protection)(Odisha) Rules,1974.</p>
        <p>The present Wildlife Organization is headed by Principal Chief Conservator of Forest (Wildlife) and is assisted by Addl.Principal Chief Conservator of Forest (Wildlife) and Three Deputy Conservator of Forests in the Headquarters and the Field Director,Similipal Tiger Reserve  Director,Nandankanan Biological Park,12 Divisional Forest Officer/Deputy Conservators of Different Divisions and three Deputy Directors I.e Similipal North Tiger Reserve,Similipal South Tiger Reserve and Nandankanan Zoological Park in the Field.All the RCCFs in charge of circles and all the DCFs in charge of 37 territorial Divisions and 14 Wildlife Divisions function as wildlife Wardens under Wildlife (Protection) Act,1972.</p>

        <h4 class="card-title">Introduction to the State of Odisha</h4>
        <p>The state of Odisha has a geographical area of 1,55,707 sq. Km.The recorded forest area is 58,136 sq.km. as per the State of Forest Report(SFR-2015) of Forest Survey of India.The state is quite rich in wildlife.The important wildlife found in state are Elephants,Tigers,Leopards,Hyena,Jungle cats,Sambar,Spotted deer,wild Boar,Giant squirrels,Pea fowl,Hill myna,Marsh Crocodile,Gharial,Salt water Crocodile,King Cobra,Sea Turtles and Monitor Lizard etc.To provide protection to wildlife and their habitat, a network of protected areas have been constituted.Special programs have been initiated for special care of endangered and threatened species.</p>
        <p>The State has 19 Sanctuaries including Gahirmatha Marine Wildlife Sanctuary, one National Park (Bhitarkanika), one proposed National Park (Similipal) which have been notified under the provisions of Indian Wildlife (Protection) Act, 1972 for in-situ conservation. These Protected Areas constitute 10.37% of the total forest area and 5.36% of the total geographical area of the State. One large Zoological Park (Nandankanan), three Small Zoos and seven Mini Zoos have been notified for ex-situ conservation and management of wildlife out side protected areas. The State has the singula distinction of having three stretches of mass nesting beaches of endangered Olive Ridley Sea turtles including the world's largest nesting ground if Olive Ridley Sea turtle, Lepidochelys olivacea at Gahirmatha. It has many natural wetlands including the largest wetland of Asia (Chilika) and Bhitarkanika which are declared as "Ramsar Sites". Six Eco-sensitive Zones finally notified (Kapilash,Chandaka, Balukhanda, Debrigarh, Kuldiha and Bhitarkanika).</p>

        <h4 class="card-title">The Wildlife Diversity of Odisha</h4>
        <p>A total of 27 species of amphibians,131 species of reptiles including 3 crocodilian species,524 species of birds and 27 species of mammals have so far been recorded in the state which include 54 species of threatened animals(17 species of reptiles,15 species of birds and 22 species mammals) as per the defination of IUCN Red data book.The state is blessed with an extreamly rich and unique assemblage of wildlife,inhabiting their respective habitats spread over three different bio-geographic zones.</p>
        <p>For captive and rescued animals provision of ex-situ conservation has been made.Various developmental project and mining etc are creating distrubances for wildlife and their habitat leading to man-animal conflicts.Hence habitat fragmentation and the ensuring man-animal conflicts are some of the major challenge before the Wildlife Organization.</p>

        <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>

      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
