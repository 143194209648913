<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <ul id="tabs" class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a id="tab-baisipaliPAmap" href="#pane-baisipaliPAmap" class="nav-link active" data-toggle="tab"
              role="tab">Map</a>
          </li>
          <li class="nav-item">
            <a id="tab-baisipaliPAinfo" href="#pane-baisipaliPAinfo" class="nav-link" data-toggle="tab"
              role="tab">Information</a>
          </li>
        </ul>

        <div id="accordion" class="tab-content" role="tablist">
          <div id="pane-baisipaliPAmap" class="card tab-pane fade show active" role="tabpanel"
            aria-labelledby="tab-baisipaliPAmap">
            <div class="card-header" role="tab" id="heading-baisipaliPAmap">
              <h5 class="mb-0">
                <a data-toggle="collapse" href="#collapse-baisipaliPAmap" aria-expanded="true"
                  aria-controls="collapse-baisipaliPAmap">
                  Map
                </a>
              </h5>
            </div>
            <div id="collapse-baisipaliPAmap" class="collapse show" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-baisipaliPAmap">
              <div class="card-body">
                <div id="mapbaisipalli" class="map">
                  <div class="gle-icon">
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-street.png" class="img-fluid" alt="Street View" title="Street View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-satellite.png" class="img-fluid" alt="Satellite View"
                        title="Satellite View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-topo.png" class="img-fluid" alt="Topo View" title="Topo View">
                    </a>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div id="pane-baisipaliPAinfo" class="card tab-pane fade" role="tabpanel"
            aria-labelledby="tab-baisipaliPAinfo">
            <div class="card-header" role="tab" id="heading-baisipaliPAinfoB">
              <h5 class="mb-0">
                <a class="collapsed" data-toggle="collapse" href="#collapse-baisipaliPAinfo" aria-expanded="false"
                  aria-controls="collapse-baisipaliPAinfo">
                  Information
                </a>
              </h5>
            </div>
            <div id="collapse-baisipaliPAinfo" class="collapse" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-baisipaliPAinfo">
              <div class="card-body">
                <h4 class="card-title">
                  Baisipalli Sanctuary
                  <span class="custom-down">
                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
                      <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                  </span>
                </h4>
                <div id="printcontent">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td><strong>Forest Division : </strong></td>
                              <td>Mahanadi (Wildlife) Division</td>
                            </tr>
                            <tr>
                              <td><strong>District : </strong></td>
                              <td>Nayagarh</td>
                            </tr>
                            <tr>
                              <td><strong>Date of Notification : </strong></td>
                              <td>07-11-1981</td>
                            </tr>
                            <tr>
                              <td><strong>Notified Area : </strong></td>
                              <td>168.35 sq.km</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-2 video-box no-print">
                      <a href="#" class="video-btn" data-toggle="modal"
                        data-src="https://www.youtube.com/embed/v-fkenyReDs" data-target="#myModal">
                        <h4>Baisipalli Sanctuary</h4>
                        <div class="overlay">
                          <span class="icon">
                            <i class="fa fa-play-circle"></i>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="col-md-2">
                      <img src="assets/images/baisi-pic.jpg" class="img-fluid float-right img-inner"
                        alt="image">
                    </div>
                  </div>
                  <p>
                    Baisipalli Wildlife Sanctuary Odisha, spread over 168.35 sq km entirely in Nayagarh district, makes
                    a
                    co-end with the Baisipalli Reserve Forest. It is Situated in the close proximity of the Satkosia
                    Gorge
                    Sanctuary, the Baisipalli Wildlife Sanctuary is lying in its south. Baisipalli Sanctuary is one of
                    the
                    eminent wildlife sanctuaries in Odisha that vividly illustrates the diverse natural heritage of the
                    east Indian state. The sanctuary is home to bears, elephants, leopards, Sambar Deer, and spotted
                    deer.
                  </p>
                  <h4 class="card-title">Quick Facts</h4>
                  <ul class="custom-list">
                    <li>The sanctuary is popular for its deciduous forests, dry deciduous forests and moist peninsular
                      Sal
                      forests.</li>
                    <li>Rich in flora and fauna, this place borders the Mahanadi River along the Satkosia gorge, which
                      also includes the Gharial Crocodile Sanctuary of Tikarpara.</li>
                    <li>The sanctuary is blessed with a well-wooded hilly terrain, rendering abode to elephants, tigers,
                      leopards, herbivores like Chousingha and water birds, and a few varieties of reptilian including
                      Python and King cobra.</li>
                    <li>A stay at Tikarpara and cruise along the 25 km long gorge with beautiful forests on either side
                      is
                      a memorable experience.</li>
                    <li>The sanctuary is an ideal tourist destination.</li>
                  </ul>
                  <h4 class="card-title">How to reach</h4>
                  <p class="address-inner">
                    <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>Tourists can reach Baisipalli Sanctuary
                    by
                    choosing the state highway that is connected from Bhubaneswar to Gania. It is located approximately
                    160 kms away from state capital Bhubaneswar.100 km from Bhubaneswar by bus.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong>The nearest railway station is at Khurda
                    Road Railway Station.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>The nearest airport is at Bhubaneswar
                  </p>
                  <h4 class="card-title">Facilities available</h4>
                  <ul class="custom-list">
                    <li>Accomodation is available at Kuanria Forest Rest House, Baisipalli Sanctuary,Mahanadi Wildlife
                      Division, Odisha.</li>
                    <li>The tourists get recreational and educational facilities during their visit at the Deer Park at
                      Kuanria.</li>
                    <li>Watch towers from where one can watch the free movement of wild lives during evening hours.</li>
                  </ul>
                  <h4 class="card-title">Salient Detail</h4>
                  <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                      <tbody>
                        <tr>
                          <td width="15%"><strong>Geographic Extent : </strong></td>
                          <td width="85%">Longitude 84° 35' to 84° 48'(east) and Latitude 20° 30' to 20° 24' (North)
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Climate : </strong></td>
                          <td>Temperature - In summer: 45°C, In winter: 5-6°C(at night) Average Humidity - 80% Annual
                            Rainfall - 1000mm</td>
                        </tr>
                        <tr>
                          <td><strong>Villages :</strong></td>
                          <td>18 villages inside sanctuary and 43 on the fringe area.</td>
                        </tr>
                        <tr>
                          <td><strong>Contact officer : </strong></td>
                          <td>
                            <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Divisional Forest
                            Officer,
                            Mahanadi Wildlife Division, Nayagarh<br>
                            <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>At - Collage Road,
                            Po
                            / Dist - Nayagarh <br>
                            <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06753-254015 <br>
                            <span class="address-inner"><i class="fa fa-fax">&nbsp;</i></span>06753-253971<br>
                            <span class="address-inner"><i class="fa fa-mobile">&nbsp;</i></span>9437202000<br>
                            <span class="address-inner"><i
                                class="fa fa-envelope">&nbsp;</i></span>mahanadiwl.dfo@gmail.com
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Best time to visit : </strong></td>
                          <td>October to March</td>
                        </tr>
                        <tr>
                          <td><strong>Accommodation : </strong></td>
                          <td>Kuanria, Chhamundia & Badmul</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
                      </strong>The information contained herein are provided solely for informational purposes only.The
                      content is dynamic and may undergo change from time to time. The snapshot of the content at a
                      particular time is not to be referred as a legally binding document.</small></p>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
