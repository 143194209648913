<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
			        <li class="nav-item">
			            <a id="tab-sunabedamap" href="#pane-sunabedamap" class="nav-link active" data-toggle="tab" role="tab">Map</a>
			        </li>
			        <li class="nav-item">
			            <a id="tab-sunabedainfo" href="#pane-sunabedainfo" class="nav-link" data-toggle="tab" role="tab">Information</a>
			        </li>
			    </ul>

			    <div id="accordion" class="tab-content" role="tablist">
			        <div id="pane-sunabedamap" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-sunabedamap">
			            <div class="card-header" role="tab" id="heading-sunabedamap">
			                <h5 class="mb-0">
			                    <a data-toggle="collapse" href="#collapse-sunabedamap" aria-expanded="true" aria-controls="collapse-sunabedamap">
			                        Map
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-sunabedamap" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-sunabedamap">
			                <div class="card-body">
								<div class="map" id="suanbedaWLmap">
                                    <div class="gle-icon">
										<a href="javascript:void(0)">
                                            <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid" alt="Street View" title="Street View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()' alt="Satellite View" title="Satellite View">
                                        </a>
                                        <a href="javascript:void(0)">
                                            <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View" title="Topo View">
                                        </a>
                                    </div>
                                </div>
			                </div>
			            </div>
			        </div>

			        <div id="pane-sunabedainfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-sunabedainfo">
			            <div class="card-header" role="tab" id="heading-sunabedainfo">
			                <h5 class="mb-0">
			                    <a class="collapsed" data-toggle="collapse" href="#collapse-sunabedainfo" aria-expanded="false" aria-controls="collapse-sunabedainfo">
			                       Map
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-sunabedainfo" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-sunabedainfo">
			                <div class="card-body">
			                	<h4 class="card-title">
                          Sunabeda Wildlife Division
                          <span class="custom-down">
                            <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
                            <button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
								class="fa fa-file-pdf">&nbsp;</i>Map</button>
                          </span>
                        </h4>
                        <div id="printcontent">
                        <div class="row">
                        	<div class="col-md-9">
                        		<div class="table-responsive">
                            	<table class="table table-sm table-bordered">
                            		<tbody>
                            			<tr>
                            				<td><strong>District : </strong></td>
                            				<td>Nuapada</td>
                            			</tr>
                                  <tr>
                                    <td><strong>Geographical Extent : </strong></td>
                                    <td>Longitude 82° 20' 06”E and 82° 34’ 42”E & latitude 20° 24’N and 20° 44'N</td>
                                  </tr>
                                  <tr>
                                    <td><strong>Climate : </strong></td>
                                    <td>The area receives most of its rains by South-West monsoon. Generally the monsoon breaks in the middle of June and rains continue up to the end of September. Winter starts from middle of November</td>
                                  </tr>
                            		</tbody>
                            	</table>
                            </div>
                        	</div>
                          <div class="col-md-3">
                        		<img src="assets/images/wildlifeunits-sunabeda-01.jpg" class="img-fluid float-right img-inner" alt="image">
                        	</div>
                        </div>
                        <p>In the district of Nuapada, out of two Forest Divisions one is Sunabeda Wildlife Division. The Sunabeda Sanctuary area comes under the Administrative control of Sunabeda Wild life Division with head Quarters at Nuapada. The Division is constituted with three ranges i.e. Nuapada, Komna, and Sunabeda, having head quarters at Nuapada, Komna, and Sunabeda respectively. The forests are characterized with a series of long hill ranges varying 727 mt to 900 mt height with slopes often steep to precipitous running approximately north to south. The intervening space is watered by three rivers, nine major streams etc.</p>
                        <h4 class="card-title">Flora </h4>
                        <p>Top Storey consists of Asan (Terminalia tomentosa), Dharua (Anogeissus latifolia), Bija (Pterocarpus marsupium), Haldu (Adina cordifolia), Mundi (Mitragyna parviflora), Kusum (Schleichera oleosa), Sissoo (Dalbergia latifolia), Harida (Terminalia chebula), Bahada (Terminalia belerica), Moi (Garuga pinnata), Teak (Tectona grandis), Kendu (Diospyros melanoxylon), Sidha (Lagerstroemia parviflora), Simul (Bombax ceiba), Jamun (Syzygium cumini) and Arjun (Terminalia arjuna) along nallah bank etc. The middle storey consists of: Amla (Emblica officinalis),Bandhan (Ougenia oojenensis), Sunari (Cassia fistula), Khair (Acacia catechu), Karla (Cleistanthus collinus), Kumbhi (Careya arborea), Chara (Buchnania lanzan), Siris (Albizzia lebbek), Bheru (Chloroxylon swietenia) Ground flora: Bana Khajuri(Phoenix acaulis),Ranikathi(Flemengia chapper),Kurei(Holorrhena antidysenterica),Gange siuli(Nyctanthus arbortristics),Gindhi(Indigofera pulchela),(Woodfordia fruiticosa),(Grewia hirsuta),(Helecteres isora) etc. Climbers: Siali (Bauhinia vahili), Latapalas (Butea superba), Muturi (Smilax macrophylla), Atundi (Combratum decandrum), Gaj (Milletia auriculata), Patchurimia (Ventilago calyculata), Satabari (Asparagus racemosus) etc. Grasses: Thatch grass, Sinkula, Bandhani, Broom Grass, Bena (Khus-Khus) (Vetiveria zizinoides) etc.</p>
                        <h4 class="card-title">Fauna </h4>
                        <p>The wildlife population appears to be very much encouraging. The major fauna found in this area are Royal Bengal Tiger, Leopard, Indian Bison, Hyena, Barking Deer, Chital, Sambar, Wild Pig, Ratel, Sloth Bear, Wild dog, Nilgai, Porcupine, Four horned antelope, Hares, Jackals, Peafowl, Hill Mynas , Jungle fowls etc. Besides this, avi fauna like Northern pintail, Pintail. Rudyshellduck, Lesser Whistling duck, Gargeny and Osprey are regular winter visitors along with a sizable population of other residential birds found in this area.</p>
                        <h4 class="card-title">Set-Ups</h4>
                        <div class="table-responsive">
                        	<table class="table table-sm table-bordered">
                        		<tbody>
                              <tr>
                        				<td><strong>Administrative set-up (Range, Section, beats) :</strong></td>
                        				<td>Ranges- 3, Sections- 10,Beats- 27</td>
                        			</tr>
                        			<tr>
                        				<td><strong>EDC/JFM set-up:  </strong></td>
                        				<td>EDCs - 4, VSS - 0</td>
                        			</tr>
                        			<tr>
                                <td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
                                <td>No. of RF- 0, No. of PRF- 6, No. of DPF- 0, No. of UDPF- 0, No. of VF- 0</td>
                              </tr>
                        		</tbody>
                        	</table>
                        </div>
                        <h4 class="card-title">Major Attraction</h4>
                        <div class="row">
                          <div class="col-md-12">
                            <img src="assets/images/wildlifeunits-sunabeda-02.jpg" class="img-fluid float-right img-inner" alt="image">
                            <ul class="custom-list">
                            	<li>Sunabeda Wildlife Division has three beautiful waterfalls amidst the ancient rock formation- Goudhus, Beniadhus and Kharaldhus.</li>
                              <li>It has the potential for great tourist attraction due to the wilderness in its natural condition, waterfalls, historical monuments and architectural ruins.</li>
                              <li>The presence of scenic landscapes in the form of waterfalls like Goudhus, Beniadhus and Kharaldhus.</li>
                              <li>Apart from three major rivers, the valleys are criss-crossed by nine major streams, 12 waterfalls and two springs.</li>
                              <li>There are teak forests, amidst which there is a significant presence of animals like tigers, leopards, gaurs and nilgais etc.</li>
                              <li>While there was a forest rest house in Goudhus, the eco-development committee near the Patora dam and the Jogeswar Temple of Shiva are the major attractions in the area.</li>
                            </ul>
                          </div>
                        </div>
                        <h4 class="card-title">Salient Detail</h4>
                        <div class="table-responsive">
                        	<table class="table table-sm table-bordered">
                        		<tbody>
                              <tr>
                        				<td width="15%"><strong>HQ Address :</strong></td>
                        				<td width="85%" colspan="2">Sunabeda Wildlife Division, Nuapada At-Nuapada district, Odisha</td>
                        			</tr>
                        			<tr>
                        				<td><strong>Contact officer : </strong></td>
                        				<td>
                                  <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>1.Divisional Forest Officer<br>
                        					<span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Sunabeda Wildlife Division, Nuapda<br>
                        					<span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06678-225372<br>
                        					<span class="address-inner"><i class="fa fa-fax">&nbsp;</i></span>06678-225372<br>
                                  <span class="address-inner"><i class="fa fa-mobile">&nbsp;</i></span>9437966372<br>
                                </td>
                                <td>
                                  <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>2. Assistant Conservator of Forests<br>
                        					<span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Sunabeda Wildlife Division, Nuapada <br>
                                  <span class="address-inner"><i class="fa fa-mobile">&nbsp;</i></span>9937527247<br>
                                </td>
                        			</tr>
                        			<tr>
                        				<td><strong>Key activities : </strong></td>
                        				<td colspan="2">Forest Protection, Habitat Improvement & restoration, Fire Prevention,Wildlife Conservation, Prevention of wildlife poaching & timber smuggling, Ceation and renovation of water bodies, maintainance of forest road and other infrastructures etc.</td>
                        			</tr>
                        			<tr>
                        				<td><strong>Programmes : </strong></td>
                        				<td colspan="2">Celebration of Wildlife week, Vanamahostava, World Forestry Day, International Tiger Day, Biodiversity Day, World Environment Day and creation of Awareness among people about protection and conservation through mass rally on various occasions, conducting sit and draw, debate quiz competitions etc.</td>
                              </tr>
                              <tr>
                        				<td><strong>Places to see : </strong></td>
                        				<td colspan="2">Sunabeda: Goddess Sunadei Temple, Hamlet of Ancient Tribes, Beniadhus: Water fall of 24 mtr. height, Kharaldhus: Water fall of 50 mtr. height, Adventure trekking track, Wildlife Conservation, Goudhus: Water fall of 30 mtr. height, Temple of lord Siva. Rock climbing, Trekking, Maraguda: A small wet land of 50ha. Migratory and residential birds, and scenic view of Patora dam and Patora: Ancient Siva Temple and bird watching in the dam site.</td>
                        			</tr>
                        		</tbody>
                        	</table>
                        </div>
                        <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>
                      </div>
			                </div>
			            </div>
			        </div>

			    </div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>
