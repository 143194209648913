import { Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-slidercarousel',
  templateUrl: './slidercarousel.component.html',
  styleUrls: ['./slidercarousel.component.css']
})
export class SlidercarouselComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  	(function($){
      	$(document).ready(function() {
		    $("#news-slider").owlCarousel({
		        /*items : 5,
		        slideBy: 3,
		        itemsDesktop:[1199,4],
		        itemsDesktopSmall:[980,3],
		        itemsMobile : [600,1],
		        navigation:true,
		        navigationText:["",""],
		        pagination:true,
		        autoPlay:true,*/
		        center: true,
			    loop: false,
			    margin: 20,
			    items: 5,
			    responsive: {
			      0: {
			        items: 1,
			        navigation: true,
			        nav: true,
			        slideBy: 1 
			      },
			      640: {
			        items: 2,
			        navigation: true,
			        nav: true,
			        slideBy: 2 
			      },
			      1024: {
			        items: 3,
			        navigation: true,
			        nav: true,
			        slideBy: 3 
			      }
			    },
			    scrollPerPage: true,
			    autoPlay:true,
			    navigation: true,
			    navigationText:["",""],
		    });
		});
    })(jQuery);
  }

}
