<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-3">

				<div class="custom-menu-inner">
					<h4 class="card-title">Projects & Conservation</h4>
					<ul>
						<li><a [routerLink]="['/projectelephant']">Project Elephant</a></li>
						<li class="active"><a [routerLink]="['/projecttiger']">Project Tiger</a></li>
						<li><a [routerLink]="['/crocodileconservation']">Crocodile Conservation</a></li>
						<li><a [routerLink]="['/seaturtleconservation']">Sea Turtle Conservation</a></li>
						<li><a [routerLink]="['/blackbuckconservation']">Blackbuck Conservation</a></li>
						<li><a [routerLink]="['/mangroves']">Mangroves</a></li>
					</ul>
				</div>

			</div>
			<div class="col-md-9">

				<h4 class="card-title">Tiger Reserve</h4>

				<img src="assets/images/Tiger_B_ Navaneethan_Bandhavgarh.jpg" class="img-fluid float-right img-inner"
					alt="image">
				<p>Tiger Reserves are visualized as breeding nuclei from which surplus tiger would migrate to adjacent
					forests.</p>
				<p>By virtue of being the top predator, the tiger functions as an umbrella species for the conservation
					of biodiversity, ecosystem functions, goods, and services in forest systems of Asia. According to
					the latest article published by The Indian Express, India is home to 2,226 tigers, representing 70
					per cent of the global population of the endangered big cat species as per the latest official
					count. Conceived under the Project Tiger launched in 1973, the Tiger Reserve aims at conserving the
					habitat to ensure a viable population of the tigers along with their prey base in their habitat.</p>

				<h4 class="card-title">Similipal Tiger Reserve</h4>
				<p>Mayurbhanj District holds the major population of tiger within the state. Less than thirty tigers
					were surviving here in the year 1975. Sustainable protection measures and management initiatives
					have resurrected the dwindling population of tigers. Recent census of tigers conducted by the use of
					both pugmark and camera trap techniques in the year 2016 shows presence of 29 tigers. Similipal is
					the only place in the world that houses the source population of melanistic tigers. Most of the
					tigers from North Similipal are moving to south Similipal that remains a better habitat, and the
					leopards, more adaptable than the tiger, are appearing in most of the habitats vacated by the tiger.
					These observations suggest that Similipal habitat may be reaching the maximum of its carrying
					capacity for tiger. The future seems dependent on adjoining forests of Hadgarh and Kuldiha that are
					better known for being elephant habitats.</p>
				<p>Tiger is only an indicator species but the project in Similipal has provided incidental conservation
					to the entire biota contained in these forests. The giant squirrel (Ratufa indica) of the upper tree
					canopy, the mugger crocodile (Crocodylus palustris) in the rivers of Similipal, and the 94-species
					of orchid flora are only a few that ‘indicate’ the positive impact of Project Tiger on the health of
					the ecosystem, and the prevailing microclimate of Similipal.</p>
				<p>Major threats to Similipal centre arise out of the four lakh people living in the periphery and ten
					thousand people living inside it. Most of them ‘use’ Similipal to make a living, and the population
					continues to rise at a rate of 20% for each decennial period. Similipal presents an accurate
					reflection of the declining status of a habitat in the face of growth of human population. However
					major success has been achieved in minimizing the age old practice of “Akhanda Shikar” prevalent
					among the local tribal community through organization of archery competitions with lucrative prizes.
				</p>

				<h4 class="card-title">Satkosia Tiger Reserve</h4>
				<img src="assets/images/tigerReserve.jpg" class="img-fluid float-right img-inner" alt="image">
				<p>Encompasses the Satkosia Gorge Sanctuary which was constituted in May 1976 and the Baisipalli
					Sanctuary, constituted in November 1981. The Tiger Reserve is rich in large cats and their prey
					along with wetland fauna (Gharial, Mugger, freshwater turtles, etc.) in the Mahanadi and rich
					biodiversity in the terrestrial ecosystem.</p>
				<p>The Tiger Reserve is located in the Central Zone of Odisha and spreads over Angul, Cuttack, Nayagarh
					and Boudh districts covering an area of 963.87 sq.kms and comprises of Moist Deciduous Forests, Dry
					Mixed Deciduous Forest, Bamboo Brakes, Dry Tropical Riparian Forest and Teak Plantations.</p>
				<p>Satkosia Gorge is the home to the most endangered Gharial (Gavialis gangeticus), the Mugger
					crocodiles (Crocodylus palustris) and a variety of freshwater turtles. Other faunal elements include
					Leopard, Wild dog, Gaur, Nilgai, Sambar, Spotted Deer, Barking Deer, Bear, Ratel, Jackal, Porcupine,
					Indian Hare, and Giant Squirrel etc. A viable but stabilized population of about 155 elephants
					thrives in this region which also forms a part of the Mahanadi Elephant Reserve.</p>
				<p>Both the resident as well as migratory birds are found in this sanctuary. The resident birds include
					the Hornbills, Peafowl, Kingfishers, Egrets, Cormorants, River Tern, Owls, Pigeons, Myna, Parakeets,
					Drongo, Doves, Woodpecker, Gulls, Golden Oriole, Vultures, Babblers, Barbets, Minivets, Sun Bird,
					Eagle, Crow Pheasants etc. The migratory birds include Brahminy Ducks, Bar Headed Geese etc.
					Recently the high vulnerable species of Indian Skimmers flocks have also been noticed in this area.
				</p>

				<p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
						</strong>The information contained herein are provided solely for informational purposes
						only.The content is dynamic and may undergo change from time to time. The snapshot of the
						content at a particular time is not to be referred as a legally binding document.</small></p>

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>