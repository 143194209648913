import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { FormBuilder } from '@angular/forms';
import { NotificationService } from '../service/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-squadreportdashboard',
  templateUrl: './squadreportdashboard.component.html',
  styleUrls: ['./squadreportdashboard.component.css']
})
export class SquadreportdashboardComponent implements OnInit {
  public circleDisabled: boolean = false;
  public divisionDisabled: boolean = false;
  public rangeDisabled: boolean = false;
  public tableData: any[];

  public circleSelected = 'All';
  public divisionSelected = 'All';
  public rangeSelected = 'All';

  public circleid: string = '0';
  public divid: string = '0';
  public rangeid: string = '0';

  public divisionlist: any[] = [];
  public circleList: any[] = [];
  public rangelist: any[] = [];

  roleId: any;
  juridictionId: any;
  startDateSelected: string = '';
  endDateSelected: string = '';
  squadDashboardSummary: any = { totalSquad: 0, reportedSquad: 0, notReportedSquad: 0 }; // Initialize with defaults
  consolidatedReport: any[]=[];

  // Chart configuration
  title = 'Division Performance';
  type = 'BarChart';
  columns = ['Division', 'Performance %', { role: 'annotation' }];
  barChartData:any[]=[];
  options = {
    title: 'Division Performance Metrics',
    titleTextStyle: {
      fontSize: 15,
      color: '#424242',
      bold: true
    },
    hAxis: {
      title: 'Performance (%)',
      titleTextStyle: { 
        color: '#616161', 
        italic: false,
        fontSize: 12
      },
      minValue: 0,
      maxValue: 100,
      format: '#\'%\'', // Ensures percentage sign is displayed
      gridlines: { 
        color: '#e0e0e0', // Light gray gridlines
        count: 6, // Explicitly sets 6 gridlines (0%, 20%, 40%, 60%, 80%, 100%)
      },
      minorGridlines: { 
        color: '#f5f5f5', // Even lighter minor gridlines
        count: 1 // Adds minor gridlines between majors
      },
      ticks: [0, 20, 40, 60, 80, 100], // Explicit tick marks
      textStyle: { 
        color: '#616161',
        fontSize: 11
      },
      baselineColor: '#e0e0e0', // Color for 0% line
      viewWindow: {
        min: 0,
        max: 100
      }
    },
    vAxis: {
      title: 'Division',
      textStyle: { color: '#424242', fontSize: 11 ,bold: true},
      textPosition: 'out',
      minTextSpacing: 100,
      showTextEvery: 1,     // Shows all labels
      slantedText: false,   // Prevents angled text
      maxAlternation: 15,   // Allows more label space
      gridlines: {
        color: 'transparent' // Removes vertical gridlines for cleaner look
      },// Format the division names to include rank
      format: (name: string, index: number) => {
        return `${index + 1}. ${name}`;
      }
    },
    bars: 'horizontal',
    legend: { position: 'none' },
    colors: ['#4dabf7'],
    height: 350,
    chartArea: {
      left: 120,      // More space for y-axis labels
      top: 40,        // Space for title
      width: '75%',   // Slightly reduced to accommodate labels
      height: '85%',
      backgroundColor: {
        stroke: '#e0e0e0',
        strokeWidth: 1
      }
    },
    backgroundColor: 'transparent',
    tooltip: {
      trigger: 'focus',
      isHtml: true,
      textStyle: {
        fontSize: 12
      }
    },
    // Enhanced tooltip with rank info
    format: (value: any, index: number) => {
      return `<div style="padding:5px;min-width:150px">
        <strong>Rank ${index + 1}</strong><br>
        <strong>${this.barChartData[index][0]}</strong><br>
        Performance: <b>${value}%</b>
      </div>`;
    },
    animation: {
      startup: true,
      duration: 800,
      easing: 'out'
    }
  };
  
  width = 600;  // Increased from 650 to accommodate full labels
  height = Math.max(400, this.barChartData.length * 45); // Dynamic height based on data
//********************************** */

lastUpdated = new Date();
showLast7Days = true;
performanceDays: number=7;
totalSquadCountDivisionList: any[]=[];
totalSquadCountRangeWiseList:any[]=[];

  constructor(  
    private fb: FormBuilder,
    private notifyService: NotificationService,
    private router: Router, 
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
       const userdata = sessionStorage.getItem('user');
        if (userdata != null) {
          this.getCurentDate();
          this.getAllCircle();
          this.isCircleDivisionRangeDisabled();
          this.loadConsolidatedReport();
          this.loadTopSquadPerformance();
        } else {
          this.router.navigate(['/']);
        }
  }
  getCurentDate(): void {
    const now = new Date();
    const yesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    
    const pad = (num: number) => num < 10 ? `0${num}` : num;
    this.startDateSelected = `${yesterday.getFullYear()}-${pad(yesterday.getMonth() + 1)}-${pad(yesterday.getDate())} ${pad(now.getHours())}:${pad(now.getMinutes())}:${pad(now.getSeconds())}.${now.getMilliseconds()}`;
    this.endDateSelected = `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())} ${pad(now.getHours())}:${pad(now.getMinutes())}:${pad(now.getSeconds())}.${now.getMilliseconds()}`;
    
    console.log('Start Date:', this.startDateSelected);
    console.log('End Date:', this.endDateSelected);
  }
  isCircleDivisionRangeDisabled() {
    const userdata = sessionStorage.getItem('user');
    if (userdata != null) {
      const user = JSON.parse(userdata);
      this.roleId = user.roleId;
      this.juridictionId = user.juridictionId;

      if (this.roleId == 1 || this.roleId == 3) {
        this.circleDisabled = false;
        this.divisionDisabled = false;
        this.rangeDisabled = false;
        this.loadSummary();
        // this.loadTotalSquadRegistered();
        // this.loadSquadReportedIn24Hrs();
        // this.loadSquadNotReportedIn24Hrs();
      }
      else if (this.roleId == 6) {
        this.apiService.request('GET', `api/v1/masters/getAssignCircleDivisionRangeByUserId?roleId=${this.roleId}&jurdicitionId=${this.juridictionId}`).subscribe({
          next: (data: any) => {
            this.circleDisabled = true;
            this.circleid = data.circleId;
            this.circleSelected = data.circleId;
            this.getAllDivisionByCircleId();
            this.getAllRangeByDivId();
            this.loadSummary();
            // this.loadTotalSquadRegistered();
            // this.loadSquadReportedIn24Hrs();
            // this.loadSquadNotReportedIn24Hrs();
          },
          error: (err) => console.error('Error fetching circle data:', err)
        });
      }
      else if (this.roleId == 2) {
        this.apiService.request('GET', `api/v1/masters/getAssignCircleDivisionRangeByUserId?roleId=${this.roleId}&jurdicitionId=${this.juridictionId}`).subscribe({
          next: (data: any) => {
            this.circleDisabled = true;
            this.circleid = data.circleId;
            this.circleSelected = data.circleId;
            this.divid = data.divisonId;
            this.divisionSelected = data.divisonId;
            this.divisionDisabled = true;
            this.getAllDivisionByCircleId();
            this.getAllRangeByDivId();
            this.loadSummary();
            // this.loadTotalSquadRegistered();
            // this.loadSquadReportedIn24Hrs();
            // this.loadSquadNotReportedIn24Hrs();
          },
          error: (err) => console.error('Error fetching division data:', err)
        });
      }
      else if (this.roleId == 4 || this.roleId == 5) {
        this.apiService.request('GET', 'api/v1/masters/getAssignCircleDivisionRangeByUserId?roleId=' + this.roleId + '&jurdicitionId=' + this.juridictionId).subscribe((data: any) => {
          if (data) {

            this.circleDisabled = true;
            this.circleid = data.circleId
            this.circleSelected = data.circleId;
            this.getAllDivisionByCircleId();
            this.divisionSelected = data.divisonId;
            this.divid = data.divisonId;
            this.divisionDisabled = true;
            this.getAllRangeByDivId();
            this.rangeSelected = data.rangeId;
            this.rangeid = data.rangeId;
            this.rangeDisabled = true;
            this.loadSummary();
            // this.loadTotalSquadRegistered();
            // this.loadSquadReportedIn24Hrs();
            // this.loadSquadNotReportedIn24Hrs();
          }
        });
      }
    } else {
      this.router.navigate(['/']);
    }
  }
  getAllCircle() {
    return this.apiService.request('GET', 'api/v1/masters/getAllCircle').subscribe({
      next: (data: any) => {
        this.circleList = data;
      },
      error: (err) => console.error('Error fetching circles:', err)
    });
  }
  getAllDivisionByCircleId() {
    if (this.circleSelected != 'All') {
      this.apiService.request('GET', `api/v1/masters/getAllDivisionByCircleId?id=${this.circleSelected}`).subscribe({
        next: (data: any) => {
          this.divisionlist = data;
          console.log('Division List:', this.divisionlist);
        },
        error: (err) => console.error('Error fetching divisions:', err)
      });
    }
  }
  getAllRangeByDivId() {
    if (this.divisionSelected != 'All') {
      this.apiService.request('GET', `api/v1/masters/getAllRangeByDivid?id=${this.divisionSelected}`).subscribe({
        next: (data: any) => {
          this.rangelist = data;
          console.log('Range List:', this.rangelist);
        },
        error: (err) => console.error('Error fetching ranges:', err)
      });
    }
  }
  onCircleChangeloadDashboard(value: string) {
    if (value === '0') {
      this.circleid = '0';
      this.circleSelected = 'All';
      this.divisionlist = [];
      this.divisionSelected = 'All';
      this.divid = '0';
      this.rangeSelected = 'All';
      this.rangelist = [];
    } else {
      this.circleSelected = value;
      this.circleid = value;
      this.getAllDivisionByCircleId();
      this.divisionSelected = 'All';
      this.divid = '0';
      this.rangeSelected = 'All';
      this.rangelist = [];
    }
    this.loadSummary();
    // this.loadTotalSquadRegistered();
    // this.loadSquadReportedIn24Hrs();
    // this.loadSquadNotReportedIn24Hrs();
  }
  onDivChangeloadWLDashboard(value: string) {
    if (value === '0') {
      this.divisionSelected = 'All';
      this.divid = '0';
      this.rangeid = '0';
      this.rangeSelected = 'All';
      this.rangelist = [];
    } else {
      this.divisionSelected = value;
      this.divid = value;
      this.getAllRangeByDivId();
      this.rangeSelected = 'All';
      this.rangeid = '0';
    }
    this.loadSummary();
   // this.loadTotalSquadRegistered();
   // this.loadSquadReportedIn24Hrs();
    //this.loadSquadNotReportedIn24Hrs();
  }
  onRangeChangeloadDashboard(value: string) {
    if (value === '0') {
      this.rangeSelected = 'All';
      this.rangeid = '0';
    } else {
      this.rangeSelected = value;
      this.rangeid = value;
    }
    this.loadSummary();
    this.loadSquadReportedIn24Hrs();
    this.loadSquadNotReportedIn24Hrs();
  }
  loadTotalSquadRegistered():void{
     if(this.circleid!='0' &&  this.divid=='0' &&  this.rangeid=='0'){
      this.apiService.request('GET', `api/v1/reports/squadCountByCircle? circle=${this.circleSelected}`).subscribe({
        next: (data: any) => {
          this.totalSquadCountDivisionList = data ;
        },
        error: (err) => {
          this.totalSquadCountDivisionList=[];
          this.totalSquadCountRangeWiseList=[];
          this.notifyService.showError('Failed to load squad summary','Error');
        }
      });
      this.totalSquadCountRangeWiseList=[];
     }else  if(this.circleid!='0' &&  this.divid!='0' &&  this.rangeid=='0'){
      this.totalSquadCountDivisionList=[];
      this.totalSquadCountRangeWiseList=[];
     }else  if(this.circleid!='0' &&  this.divid!='0' &&  this.rangeid!='0'){
      this.totalSquadCountDivisionList=[];
      this.totalSquadCountRangeWiseList=[];
     }
  }
  loadSquadReportedIn24Hrs():void{
    if(this.circleid!='0' &&  this.divid=='0' &&  this.rangeid=='0'){
        
    }else  if(this.circleid!='0' &&  this.divid!='0' &&  this.rangeid=='0'){
      
    }else  if(this.circleid!='0' &&  this.divid!='0' &&  this.rangeid!='0'){
      
    }
  }  
  loadSquadNotReportedIn24Hrs():void{
    if(this.circleid!='0' &&  this.divid=='0' &&  this.rangeid=='0'){
        
    }else  if(this.circleid!='0' &&  this.divid!='0' &&  this.rangeid=='0'){
      
    }else  if(this.circleid!='0' &&  this.divid!='0' &&  this.rangeid!='0'){
      
    }
  }
  loadSummary(): void {
      this.apiService.request('GET', `api/v1/reports/squad-summary?circle=${this.circleSelected}&division=${this.divisionSelected}&range=${this.rangeSelected}&startDate=${this.startDateSelected}&endDate=${this.endDateSelected}`)
      .subscribe({
        next: (data: any) => {
          this.squadDashboardSummary = data || { totalSquad: 0, reportedSquad: 0, notReportedSquad: 0 };
        },
        error: (err) => {
          this.squadDashboardSummary = { totalSquad: 0, reportedSquad: 0, notReportedSquad: 0 };
          this.notifyService.showError('Failed to load squad summary','Error');
        }
      });
  }
  loadConsolidatedReport(): void {
    this.apiService.request('GET', `api/v1/reports/consolidatedSquadReport24hrs`)
      .subscribe({
        next: (data: any) => {
          data.sort(function (a, b) {
            return b.totalCount - a.totalCount;
          });
          this.consolidatedReport = data;
          this.totalSquadCountDivisionList = data.map((item: any,index:any) => ({
            divisionName: item.divisionName, // Key
            totalCount: item.totalCount ,
            index:  index+1   // Value
          }));
          console.log(this.totalSquadCountDivisionList);
          this.lastUpdated = new Date();
        },
        error: (err) => {
          this.squadDashboardSummary = { totalSquad: 0, reportedSquad: 0, notReportedSquad: 0 };
          this.notifyService.showError('Failed to load squad summary', 'Error');
        }
      });
  }
  refreshData(): void {
    this.lastUpdated = new Date();
    this.loadDashboard();
  }
  loadDashboard() {
    this.loadSummary();
    this.loadConsolidatedReport();
    this.loadTopSquadPerformance();
    this.loadTotalSquadRegistered();
    this.loadSquadReportedIn24Hrs();
    this.loadSquadNotReportedIn24Hrs();
  }
  loadTopSquadPerformance(): void {
    // this.barChartData=[
    //   ['Angul',100,'100%'],
    //   ['Dhenkanal',80,'80%'],
    //   ['Similipal South',75,'75%'],
    // ]
    this.apiService.request('GET', `api/v1/reports/top-squad-performance?days=${this.performanceDays}`)
      .subscribe({
        next: (data: any) => {
           // Sort by performance descending
        data.sort((a: any, b: any) => b.performancePercentage - a.performancePercentage);
        
        this.barChartData = data.map((item: any, index: number) => [
          item.divisionName, // Name will be formatted with rank in vAxis.format
          item.performancePercentage, 
          `${item.performancePercentage}%`,
        ]);
        
        // Adjust height dynamically based on number of items
        this.height = Math.max(400, data.length * 45);
        },
        error: (err) => {
          console.error('Error fetching top squad performance:', err);
          this.notifyService.showError('Failed to load top squad performance', 'Error');
        }
      });
  }
  toggleTimeRange(): void {
    this.showLast7Days = !this.showLast7Days;
    this.performanceDays =  this.showLast7Days ? 7 : 30;;
    this.loadTopSquadPerformance();
  }
}
