
<app-masterheader></app-masterheader>

<div class="wrapper">
    <app-sidebar></app-sidebar>
    <div id="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <h4 class="card-title">Login Audit Report <button type="submit"
                            class="btn btn-xs btn-primary pull-right" (click)="backtoauditpage()"><i
                                class="fa fa-chevron-left">&nbsp;</i>Back</button></h4>
                    <div class="card">
                        <div class="card-body">
                            <form [formGroup]="loginqueryForm" (ngSubmit)="onSubmit(loginqueryForm.value)">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-2 mb-1">
                                                <label>Circle</label>
                                                <select class="form-control" id="circlewl" formControlName="circle"
                                                    [(ngModel)]='circleid'
                                                    (change)="getAllDivisionByCircleId(circleSelect.value)"
                                                    #circleSelect>
                                                    <option value="0">All Circle</option>
                                                    <option *ngFor="let circle of circleList "
                                                        value={{circle.circleId}}>
                                                        {{circle.circleName}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="col-md-2 mb-1">
                                                <label>Division</label>
                                                <select class="form-control" id="divisionwl" formControlName="division"
                                                    [(ngModel)]='divid' #divisionSelect>
                                                    <option value="0">All Division</option>
                                                    <option *ngFor="let division of divisionlist "
                                                        value={{division.divisionId}}>
                                                        {{division.divisionName}}
                                                    </option>
                                                </select>
                                            </div>
                                            <!-- <div class="col-md-2 mb-1">
                                                <label>Start Date</label>
                                                <div id="datepicker1" class="input-group date"
                                                    data-date-format="dd-mm-yyyy" title="Start Date">
                                                    <input class="form-control" type="text" formControlName="startDate"
                                                        #sd id="startDate" [(ngModel)]="sdate" />
                                                    <span class="input-group-addon"><i
                                                            class="mdi mdi-calendar"></i></span>
                                                </div>
                                            </div>
                                            <div class="col-md-2 mb-1">
                                                <label>End Date</label>
                                                <div id="datepicker2" class="input-group date"
                                                    data-date-format="dd-mm-yyyy" title="End Date">
                                                    <input class="form-control" type="text" formControlName="endDate"
                                                        #ed id="endDate" [(ngModel)]="edate" />
                                                    <span class="input-group-addon"><i
                                                            class="mdi mdi-calendar"></i></span>
                                                </div>
                                            </div> -->
                                            <div class="col-md-1">
                                                <div class="col-md-3">
                                                    <button type="submit"
                                                        class="btn btn-sm btn-success custom-btn-top">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <ul id="tabs" class="nav nav-tabs" role="tablist">
                                <li class="nav-item">
                                    <a id="tab-B" href="#pane-A" class="nav-link active" data-toggle="tab"
                                        role="tab">Login Audit Report</a>
                                </li>
                                <!-- <li class="nav-item">
                                    <a id="tab-C" href="#pane-B" class="nav-link" data-toggle="tab"
                                        (click)="loadtable(1)" role="tab">Division Wise Elephant Count Report</a>
                                </li> -->
                            </ul>
                            <div id="accordion" class="tab-content" role="tablist">
                                <div id="pane-A" class="card tab-pane fade show active" role="tabpanel"
                                    aria-labelledby="tab-A">
                                    <div class="card-header" role="tab" id="heading-A">
                                        <h5 class="mb-0"><a class="collapsed" data-toggle="collapse" href="#collapse-A"
                                                aria-expanded="false" aria-controls="collapse-A">Last Login Report
                                            </a>
                                        </h5>
                                    </div>
                                    <div id="collapse-A" class="collapse" data-parent="#accordion" role="tabpanel"
                                        aria-labelledby="heading-A">
                                        <div class="card-body">
                                            <div class="table-responsive">
                                                <table id="audittable"
                                                    class="table table-bordered display responsive nowrap" width="99%"
                                                    datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger1">
                                                    <thead>
                                                        <tr>
                                                            <th class="all" width="5%">Sl. No.</th>
                                                            <th class="all">Circle</th>
                                                            <th class="all">Division</th>
															<th class="all">Range</th>
															<th class="all">Section</th>
															<th class="all">Beat</th>
															<th class="all">Full Name</th>
															<th class="all">Designation</th>
															<th class="all">Username</th>
															<th class="all">Last Login Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let element1 of tabledata1 ; let i = index;">
                                                            <td>{{i+1}}</td>
															<td>{{ (element1.circle !== 'null') ?
                                                                (element1.circle) : '' }}</td>
															<td>{{ (element1.division !== 'null') ?
                                                                (element1.division) : '' }}</td>
															<td>{{ (element1.range !== 'null') ?
                                                                (element1.range) : '' }}</td>
															<td>{{ (element1.section !== 'null') ?
																	(element1.section) : '' }}</td>
															<td>{{ (element1.beat !== 'null') ?
																		(element1.beat) : '' }}</td>
															<td>{{element1.name}}</td>
															<td>{{element1.designation}}</td>
															<td>{{element1.userName}}</td>
															<td>{{ (element1.lastLoginDate !== 'null') ?
                                                                (element1.lastLoginDate) : '' }}</td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div id="footer">
        <app-masterfooter></app-masterfooter>
    </div>

</div>
