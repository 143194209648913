import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { TimeoutModalComponent } from '../modal/timeout-modal/timeout-modal.component';
import { NotificationService } from './notification.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';


const MINUTES_UNITL_AUTO_LOGOUT =60 // in mins
const CHECK_INTERVAL = 1000 // in ms
const STORE_KEY =  'lastAction';

@Injectable({
  providedIn: 'root'
})
export class AutologoutService {
  difference: number;
  modalOptions:NgbModalOptions;
  modalRef:any;

  constructor(private router: Router,private modalService: NgbModal,private auth:AuthService,private apiService:ApiService,private notifyService: NotificationService ) {
    const userdata=sessionStorage.getItem('user');
      if(userdata!=null){
      this.check();
    }

  }
  public getLastAction() {
    return parseInt(sessionStorage.getItem(STORE_KEY));
  }
 public setLastAction(lastAction: number) {
  sessionStorage.setItem(STORE_KEY, lastAction.toString());
  }
  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover',()=> this.reset());
    document.body.addEventListener('mouseout',() => this.reset());
    document.body.addEventListener('keydown',() => this.reset());
    document.body.addEventListener('keyup',() => this.reset());
    document.body.addEventListener('keypress',() => this.reset());
  }
  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }
  reset() {
    this.setLastAction(Date.now());
    var modalOpenStatus=this.modalService.hasOpenModals();
    if(modalOpenStatus==true){
       this.modalRef.close();
    }
  }
  IsAboutExpiry(){
    if(this.difference<60000){
       //this.notifyService.showWarning("You will time out in '"+this.difference/60000+"'  minutes!","Message");

         var modalOpenStatus=this.modalService.hasOpenModals();
         if(modalOpenStatus!=true){
          this.modalRef= this.modalService.open(TimeoutModalComponent,this.modalOptions);
          this.modalRef.componentInstance.info = Math.round(this.difference/1000);
         }else{
          this.modalRef.componentInstance.info = Math.round(this.difference)/1000;
         }


         //modalRef.close();
      }
  }
  check() {
    const userdata=sessionStorage.getItem('user');
    if(userdata!=null){
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    this.difference=diff;
    const isTimeout = diff < 0;
    if(this.difference>0){
      this.IsAboutExpiry();
    }else if(this.difference==0){
      var modalOpenStatus=this.modalService.hasOpenModals();
      if(modalOpenStatus==true){
         this.modalRef.close();
      }
    }


     if (isTimeout && this.auth.loggedIn)  {
         this.auth.logout();
     }
    }else{
      this.auth.logout();
    }
  }
}
