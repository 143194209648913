import { Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-masterfooter',
  templateUrl: './masterfooter.component.html',
  styleUrls: ['./masterfooter.component.css']
})
export class MasterfooterComponent implements OnInit {
	currentYear: number = new Date().getFullYear();
  constructor() { }

  ngOnInit(): void {
  	(function($){
      	
    })(jQuery);
  }

}
