<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
					<li class="nav-item">
						<a id="tab-similipalmap" href="#pane-similipalmap" class="nav-link active" data-toggle="tab"
							role="tab">Map</a>
					</li>
					<li class="nav-item">
						<a id="tab-similipalinfo" href="#pane-similipalinfo" class="nav-link" data-toggle="tab"
							role="tab">Information</a>
					</li>
				</ul>

				<div id="accordion" class="tab-content" role="tablist">
					<div id="pane-similipalmap" class="card tab-pane fade show active" role="tabpanel"
						aria-labelledby="tab-similipalmap">
						<div class="card-header" role="tab" id="heading-similipalmap">
							<h5 class="mb-0">
								<a data-toggle="collapse" href="#collapse-similipalmap" aria-expanded="true"
									aria-controls="collapse-similipalmap">
									Map
								</a>
							</h5>
						</div>
						<div id="collapse-similipalmap" class="collapse show" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-similipalmap">
							<div class="card-body">
								<div class="map" id="strsouthmap">
									<div class="gle-icon">
										<a href="javascript:void(0)">
											<img src="assets/images/icon-street.png" (click)='streetchange()'
												class="img-fluid" alt="Street View" title="Street View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-satellite.png" class="img-fluid"
												(click)='stelitechange()' alt="Satellite View" title="Satellite View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-topo.png" class="img-fluid"
												(click)='topochange()' alt="Topo View" title="Topo View">
										</a>
									</div>
								</div>


							</div>
						</div>
					</div>

					<div id="pane-similipalinfo" class="card tab-pane fade" role="tabpanel"
						aria-labelledby="tab-similipalinfo">
						<div class="card-header" role="tab" id="heading-similipalinfo">
							<h5 class="mb-0">
								<a class="collapsed" data-toggle="collapse" href="#collapse-similipalinfo"
									aria-expanded="false" aria-controls="collapse-similipalinfo">
									Information
								</a>
							</h5>
						</div>
						<div id="collapse-similipalinfo" class="collapse" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-similipalinfo">
							<div class="card-body">
								<h4 class="card-title">
									Similipal South
									<span class="custom-down">
										<button type="" (click)="print()"
											class="btn btn-sm btn-info mr-1"><i
												class="fa fa-file-pdf">&nbsp;</i>Information</button>
										<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
												class="fa fa-file-pdf">&nbsp;</i>Map</button>

									</span>
								</h4>
								<div id="printcontent">
									<div class="row">
										<div class="col-md-9">
											<div class="table-responsive">
												<table class="table table-sm table-bordered">
													<tbody>
														<tr>
															<td><strong>District : </strong></td>
															<td>Mayurbhanj</td>
														</tr>
														<tr>
															<td><strong>Geographical Extent : </strong></td>
															<td>Longitude 86° 03'E and 86° 35'E & Latitude 21° 14'N and
																22° 20'N</td>
														</tr>
														<tr>
															<td><strong>Climate : </strong></td>
															<td>Mesmerizing monsoon,chilling winter and pleasant summer
																characterize the climate of Similipal landscape</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div class="col-md-3">
											<img src="assets/images/wildlifeunits-similipal-01.jpg"
												class="img-fluid float-right img-inner" alt="image">
										</div>
									</div>
									<p>The entire area of Similipal Tiger Reserve, which is a compact block of elevated
										plateau located in central portion of the Mayurbhanj district. Similipal, “the
										Queen of forests of Orissa”, is a densely forested hill range spread over 2750
										sq.km in the heart of the Mayurbhanj . A true representative of Mahanadian
										biogeographical region, Similipal is not only a compact mass of forested hills,
										valleys, perennial streams and picturesque waterfalls but also a bewildering
										panorama of many hundreds of millions of trees, climbers, orchids, ferns,
										mosses, fungi, animals, birds, microorganisms . It is more than a worldly
										endeavor, as it represents the heartthrob and emotions of the people of
										Mayurbhanj. It has the appeal of a religious sanctity, a coherent bond of
										cultural assimilation and perennial source of livelihood for many tribal groups.
									</p>
									<h4 class="card-title">Flora </h4>
									<p>The Landscape support more than 1352 plant species with 94 species of orchids of
										which 3 species of orchids are endemic to simlipal. Majority of the area are
										under thick forest cover. The semi-evergreen and moist deciduous vegetation
										along the perennial water points form the reparian zones. The following
										vegetation types are found in Similipal Tiger Reserve. A) On Stream Beds: Salix
										terasperma, Trewia nudiflora, Macaranga peltata, Aphanamixis polystachya,
										Symplocos laurina, Glochidion spp., Bischofia javanica, Syzygium cumini,
										Pongamia pinnata, Diospyros peregrina, Saraca indica and at places Terminalia
										arjuna. B) A little Higher up in Damp Areas: Bombax ceiba, Alstonia scholaris,
										Ficus spp., Polyalthia cerasioides, Anthocephalus cadamba, Dillenia pentagyna,
										Litsea spp., and Citrus spp. are met with.The meadows although in small
										pockets,harbours majority of the herbivore population.</p>
									<h4 class="card-title">Fauna </h4>
									<p>There are 55 species of mammals, 361 species of birds, 62 species of reptiles, 21
										species of amphibians, with many species of insects and microfauna present in
										this unique habitat. The primary heterotrophs among the mammals are represented
										by Sambar (Cervus unicolor), Elephant (Elephas indicus), Gaur (Bos gaurus),
										Cheetal (Axis axis), Common langur (Presbytis entellus), Rhesus macaque (Macaca
										mulata), Porcupine (Hystrix indica), Malabar giant squirrel (Ratufa indica),
										Indian pangolin (Manis crassicaudata), Rufus tailed hare (Lepus nigricollis
										ruficaudatus) etc. Among the secondary heterotroughs are Tiger (Panthera
										tigris), Leopard (Panthera pardus), Leopard cat (Felis bengalensis) etc. some
										common birds are Peafowl (Pavo cristatus), Painted spur fowl (Galloperdix
										lunulata), Black patridge (Francolinus frankolinus), Shahin falcon (Falco
										perigrinus perigrinator), Malabar pied hornbill (Anthracoceros coronatus), Grey
										hornbill(Ocyceros birostris), Hill myna (Gracula religiosa), Golden
										oriole(Oriolus oriolus), Common snipe(Gallinago gallinago), Red jungle fowl
										(Gallus gallus), Crested serpent eagle, Crow pheasant, Doves, Parakeets,
										Pigeons, Sun birds.</p>
									<h4 class="card-title">Set-Ups</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td><strong>Administrative set-up (Range, Section, beats) :</strong>
													</td>
													<td>Ranges- 20, Sections- 52,Beats- 167</td>
												</tr>
												<tr>
													<td><strong>EDC/JFM set-up: </strong></td>
													<td>EDCs - 0, VSS - 0</td>
												</tr>
												<tr>
													<td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
													<td>No. of RF- 0, No. of PRF- 0, No. of DPF- 0, No. of UDPF- 0, No.
														of VF- 0/td>
												</tr>
											</tbody>
										</table>
									</div>
									<h4 class="card-title">Major Attraction</h4>
									<div class="row">
										<div class="col-md-12">
											<img src="assets/images/wildlifeunits-similipal-02.jpg"
												class="img-fluid float-right img-inner" alt="image">
											<ul class="custom-list">
												<li>Joranda Falls - This is the most picturesque waterfall in the
													Simlipal Tiger Reserve area to serve the visitors as a breathtaking
													attraction.</li>
												<li>Barheipani Falls - This mesmorizing waterfall is the origin of
													Bhudhabalanga River.</li>
												<li>Trekking - The forest which is decked with undulating hilly terrains
													offer all grades of trekking challenges to the adventurous tourists.
												</li>
												<li>Natural walks, hiking and rough trekking are the other attractions
													in this park to enjoy the natural splendors, the exquisite ambience
													and breathtaking landscapes.</li>
												<li>Simlipal is the ideal location to learn and enjoy the life and
													culture of different tribal group of Odisha.</li>
												<li>Ramatirtha-Interpretatoin centre,mugger crocodile research
													center,astral garden,picnic on the Bank of river Khairi attaract
													thousands of foot falls of visitore through-out the year.</li>
												<li>The vintage building at Chahala,the then hunting rest-house of Royal
													family of Mayurbhanj State during their reign;now surrounded by
													greenery meadow with herds of Cheetal and sambar creates immense
													pleasure for visitors.</li>
												<li>Tourists have ample scope for having the ethnic foods provided by
													tribal self help group in different location.</li>
											</ul>
										</div>
									</div>
									<h4 class="card-title">Salient Detail</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td width="15%"><strong>HQ Address :</strong></td>
													<td width="85%">Similipal Tiger Reserve,Bhanjpur, Baripada-757002
														At-Mayurbhanj district, Odisha</td>
												</tr>
												<tr>
													<td><strong>Contact officer : </strong></td>
													<td>
														<span class="address-inner"><i
																class="fa fa-user">&nbsp;</i></span>Range Officer<br>
														<span class="address-inner"><i
																class="fa fa-map-marker">&nbsp;</i></span>National Park
														Range, Jashipur<br>
													</td>
												</tr>
												<tr>
													<td><strong>Key activities : </strong></td>
													<td>Conservation of tigers,co-predators and their prey along with
														the critical wildlife habitat</td>
												</tr>
												<tr>
													<td><strong>Programmes : </strong></td>
													<td>Wildlife protection,scientific estimation of wildlife,habitat
														improvement,restoration of critical tiger
														habitat,ecotourism,research and monitoring of different
														landscape components.</td>
												</tr>
												<tr>
													<td><strong>Places to see : </strong></td>
													<td>Joranda Fall,Barheipani Fall,Uski Fall,Ramtirtha,Devkund.</td>
												</tr>
											</tbody>
										</table>
									</div>
									<p class="text-danger"><small><i
												class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
											</strong>The information contained herein are provided solely for
											informational purposes only.The content is dynamic and may undergo change
											from time to time. The snapshot of the content at a particular time is not
											to be referred as a legally binding document.</small></p>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>
