<div id="fp-nav" class="fp-show-active right" style="margin-top: 0px;">
	<ul>
		<li>
			<a [routerLink]="['/']"><span></span></a>
			<a [routerLink]="['/']" class="fp-tooltip right">Amazing Wildlife</a>
		</li>
		<li>
			<a [routerLink]="['/slidercarousel']"><span></span></a>
			<a [routerLink]="['/slidercarousel']" class="fp-tooltip right">Discover Wildlife Odisha</a>
		</li>
		<li>
			<a [routerLink]="['/homecontent']" class="active"><span></span></a>
			<a [routerLink]="['/homecontent']" class="fp-tooltip right active">About Wildlife</a>
		</li>
		<li>
			<a [routerLink]="['/blogall']"><span></span></a>
			<a [routerLink]="['/blogall']" class="fp-tooltip right">Blog / Publication</a>
		</li>
		<li>
			<a [routerLink]="['/filtergallery']"><span></span></a>
			<a [routerLink]="['/filtergallery']" class="fp-tooltip right">Gallery</a>
		</li>
		<li>
			<a [routerLink]="['/footerlanding']"><span></span></a>
			<a [routerLink]="['/footerlanding']" class="fp-tooltip right">Quick Links</a>
		</li>
	</ul>
</div>

<app-login></app-login>

<div class="login-floating">
	<button mat-raised-button data-toggle="modal" data-target="#modalLogin"><i
			class="fa fa-lock">&nbsp;</i>Login</button>
</div>
<section class="home-content service font-script">
	<div class="pt-150 pb-100">
		<div class="container">
			<div class="row">
				<div class="col-md-4">
					<div class="box overlay">
						<h2 class="text-white text-center">Projects & Conservation</h2>
						<ul class="custom-content">
							<li><a [routerLink]="['/projectelephant']">Project Elephant</a></li>
							<li><a [routerLink]="['/projecttiger']">Project Tiger</a></li>
							<li><a [routerLink]="['/crocodileconservation']">Crocodile Conservation</a></li>
							<li><a [routerLink]="['/seaturtleconservation']">Sea Turtle Conservation</a></li>
							<li><a [routerLink]="['/blackbuckconservation']">Blackbuck Conservation</a></li>
							<li><a [routerLink]="['/mangroves']">Mangroves</a></li>
						</ul>
					</div>
				</div>
				<div class="col-md-4">
					<a [routerLink]="['/shortfilmsprotectedareas']">
						<div class="service-item center">
							<div class="icon-box">
								<img src="assets/images/icon-service-03.png" alt="image">
							</div>
							<h4 class="text-center">Short Films on Protected Areas</h4>
						</div>
					</a>
				</div>
				<div class="col-md-4">
					<!-- <div class="box overlay">
						<h2 class="text-white text-center">Important Links</h2>
						<ul class="custom-content">
							<li><a href="http://www.bhitarkanika.org/live/" target="_blank">www.bhitarkanika.org</a></li>
							<li><a href="https://www.nandankanan.org/" target="_blank">www.nandankanan.org</a></li>
							<li><a href="http://www.satkosia.org/" target="_blank">www.satkosia.org</a></li>
							<li><a href="http://www.projecttiger.nic.in/" target="_blank">www.projecttiger.nic.in</a></li>
							<li><a href="http://www.envfor.nic.in/pe/pe.html" target="_blank">www.envfor.nic.in/pe/pe.html</a></li>
							<li><a href="https://www.ecotourodisha.com" target="_blank">www.ecotourodisha.com</a></li>
							<li><a href="http://www.ofsdp.org" target="_blank">www.ofsdp.org</a></li>
						</ul>
					</div> -->
					<div class="box overlay">
						<h2 class="text-white text-center">What is New</h2>
						<marquee direction="up" scrollamount="2" onMouseOver="this.stop()" onMouseOut="this.start()">
							<ul class="custom-content">
								<li *ngFor="let newContent of NewContentList" [innerHTML]="newContent.content">
								</li>
							</ul>
							<!-- <ul class="custom-content">
								<li><a href="#" target="_blank">"Wildlife Photography Competition - 2020"</a></li>
								<li><a href="#" target="_blank">Quotation for Designing & Printing of Coffee Table Book on Odisha Ecotourism</a></li>
								<li><a href="#" target="_blank">Nimination for the Biju Patnaik Award for Wildlife Conservation at Sub-divisional level 2020</a></li>
								<li><a href="#" target="_blank">Nomination for the state level  Biju Patnaik Award for Wildlife Conservation - 2020</a></li>
							</ul> -->
						</marquee>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>