import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  // {
  //   path:  'header',
  //   component: HeaderComponent
  // },
  // {
  //   path:  'menu',
  //   component: MenuComponent
  // },
  // {
  //   path:  'footer',
  //   component: FooterComponent
  // },
  // {
  //   path:  'login',
  //   component: LoginComponent
  // },
  {
    path:  'feedback',
    component: FeedbackComponent
  },
  {
    path:  'contact',
    component: ContactComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedhomeRoutingModule { }
