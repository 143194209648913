<app-masterheader></app-masterheader>

<div class="wrapper">

	<app-sidebar></app-sidebar>

	<div id="content">

		<!-- <app-breadcrumb></app-breadcrumb> -->

		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12">

					<div class="row">
						<div class="col-md-12">
							<div class="card">
								<div class="card-body">
									<h4 class="card-title">
                                        <img src="assets/images/squad.png" alt="Squad Icon" style="width: 24px; height: 24px; margin-right: 8px;">
                                        Squad Reporting
                                    </h4>
                                     
                                        <form [formGroup]="squardReportForm" (ngSubmit)=" onSubmit()">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-3 form-group mb-1">
                                                        <label>Circle</label>
                                                        <select class="form-control" id="circlewl"
                                                            formControlName="circle"
                                                            (change)="getAllDivisionByCircleId(circleSelect.value)"
                                                            [disabled]="circleDisabled" #circleSelect>
                                                            <option value="0">All Circle</option>
                                                            <option *ngFor="let circle of circleList "
                                                                value={{circle.circleId}}>
                                                                {{circle.circleName}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-3 form-group mb-1">
                                                        <label>Division</label>
                                                        <select class="form-control" id="divisionwl"
                                                            formControlName="division"
                                                            (change)="getAllRangeByDivId(divisionSelect.value)"
                                                            [disabled]="divisionDisabled"
                                                            #divisionSelect>
                                                            <option value="0">All Division</option>
                                                            <option
                                                                *ngFor="let division of divisionlist "
                                                                value={{division.divisionId}}>
                                                                {{division.divisionName}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-3 form-group mb-1">
                                                        <label>Range</label>
                                                        <select class="form-control" id="rangewl"
                                                            formControlName="range"
                                                            (change)="getAllSectionByRangeId(rangeSelect.value)"
                                                            [disabled]="rangeDisabled" #rangeSelect>
                                                            <option value="0">All Range</option>
                                                            <option *ngFor="let range of rangelist "
                                                                value={{range.rangeId}}>
                                                                {{range.rangeName}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-3 form-group mb-1">
                                                        <label>Section</label>
                                                        <select class="form-control" id="sectionwl"
                                                            formControlName="section"
                                                            (change)="getAllBeatBySectionId(sectionSelect.value)"
                                                            #sectionSelect>
                                                            <option value="0">All Section</option>
                                                            <option *ngFor="let section of sectionlist "
                                                                value={{section.secId}}>
                                                                {{section.secName}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-3 form-group mb-1">
                                                        <label>Beat</label>
                                                        <select class="form-control" id="beatwl"
                                                            formControlName="beat" 
                                                            #beatSelect>
                                                            <option value="0">All Beat</option>
                                                            <option *ngFor="let beat of beatlist "
                                                                value={{beat.beatId}}>
                                                                {{beat.beatName}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <!-- <div class="row"> -->
                                                    <div class="col-md-3 form-group mb-1">
                                                        <label>From Date</label>
                                                        <div id="datepicker1" class="input-group date"
                                                            data-date-format="dd-mm-yyyy"
                                                            title="From Date">
                                                            <input class="form-control"
                                                                formControlName="startDate" #sd
                                                                id="startDate" type="text" />
                                                            <span class="input-group-addon"><i
                                                                    class="mdi mdi-calendar"></i></span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 form-group mb-1">
                                                        <label>To Date</label>
                                                        <div id="datepicker2" class="input-group date"
                                                            data-date-format="dd-mm-yyyy"
                                                            title="To Date">
                                                            <input class="form-control"
                                                                formControlName="endDate" #ed
                                                                id="endDate" type="text" />
                                                            <span class="input-group-addon"><i
                                                                    class="mdi mdi-calendar"></i></span>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 form-group mb-1">
                                                        <button type="submit"
                                                            class="btn btn-sm btn-success custom-btn-top mr-1">View</button>
                                                        <button type="button"
                                                            class="btn btn-sm btn-info custom-btn-top"
                                                            data-toggle="modal"
                                                            data-target="#viewDirectMapViewModal"><i
                                                                class="fa fa-map">&nbsp;</i>Map
                                                            View</button>
                                                    </div>
                                                </div>
                                            </div>


                                            <!-- </div> -->
                                        </div>
                                        </form>
                                    <hr>
                                    <div class="table-responsive">
                                        <table id="squardreport" class="table table-bordered responsive"
                                            width="99%" datatable [dtOptions]="dtOptions"
                                            [dtTrigger]="dtTrigger">
                                            <thead>
                                                <tr>
                                                    <th class="all" width="2%">Sl. No.</th>
                                                    <th class="all">Division</th>
                                                    <th class="all">Range</th>
                                                    <th class="all">Section</th>
                                                    <th class="all">Beat</th>
                                                    <th class="all">Squad</th>
                                                    <th class="all" width="15%">Date of Movement</th>
                                                    <th class="all">From Time</th>
                                                    <th class="all">To Time</th>
                                                    <!-- <th class="all">Lat </th>
                                                    <th class="all">Long</th> -->                                                  
                                                    <th class="all">Action</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr *ngFor="let element of tabledata1; let i = index"
                                                    [attr.data-index]="i">
                                                    <td>{{i+1}}</td>
                                                    <td>{{element.divisionName}}</td>
                                                    <td>{{element.rangeName}}</td>
                                                    <td>{{element.secName}}</td>
                                                    <td>{{element.beatName}}</td>
                                                    <td>{{element.createdBy}}</td>
                                                    <td>{{(element.sightingDate !=='null') ? (element.sightingDate |date:'dd-MM-yyyy HH:mm a') : '' }} </td>
                                                    <td>{{(element.sightingTimefrom !=='null') ? (element.sightingTimefrom |date:'shortTime') : '' }} </td>
                                                    <td>{{(element.sightingDate !=='null') ? (element.sightingDate |date:'shortTime') : '' }} </td>
                                                    <!-- <td>{{element.latitude | number:'2.0-6'}}</td>
                                                    <td>{{element.longitude| number:'2.0-6'}}</td> -->
                                                  
                                                    <td>
                                                        <div class="dropdown">
                                                            <button
                                                                class="btn btn-sm btn-info btn-icon btn-radius dropdown-toggle"
                                                                type="button" id="dropdownMenuButton"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false">
                                                                <i class="fa fa-cog"></i>
                                                            </button>
                                                            <div class="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton">
                                                                <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal"
                                                                    data-target="#viewSquadReportViewModal"
                                                                    (click)="getMovementRecordByID(element)"><i class="fa fa-eye" aria-hidden="true">&nbsp;</i>View</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>

	<div id="footer">
		<app-masterfooter></app-masterfooter>
	</div>

</div>

<div class="modal fade gallery" id="viewSquadReportViewModal" tabindex="-1" role="dialog"
aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLongTitle">View Squad Report </h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="col-md-12 text-right mb-2">
                <button type="submit" printSectionId="printcontent_direct" [useExistingCss]="true"
                    printTitle="Protected Area Info" ngxPrint class="btn btn-sm btn-info mr-1"><i
                        class="fa fa-file-pdf">&nbsp;</i>Information</button>
            </div>
            <div class="ml-2 mr-2" id="printcontent_direct">
                <div class="row">
                    <div class="col-md-6 border p-2"><strong>Date of Movement : </strong></div>
                    <div class="col-md-6 border p-2">{{sightingDate | date:'dd-MMM-yyyy'}}
                    </div>
                    <div class="col-md-6 border p-2"><strong>Movement Time From: </strong></div>
                    <div class="col-md-6 border p-2">{{sightingTimefrom | date:'shortTime'}}
                    </div>
                    <div class="col-md-6 border p-2"><strong>Movement Time To : </strong></div>
                    <div class="col-md-6 border p-2">{{sightingTimeTo | date:'shortTime'}}
                    </div>
                    <div class="col-md-6 border p-2"><strong>Location (village, RF, PRF, etc...) : </strong>
                    </div>
                    <div class="col-md-6 border p-2">{{location}}</div>
                    <div class="col-md-6 border p-2"><strong>Division : </strong></div>
                    <div class="col-md-6 border p-2">{{divisionName}}</div>
                    <div class="col-md-6 border p-2"><strong>Range : </strong></div>
                    <div class="col-md-6 border p-2">{{rangeName}}</div>
                    <div class="col-md-6 border p-2"><strong>Section : </strong></div>
                    <div class="col-md-6 border p-2">{{secName}}</div>
                    <div class="col-md-6 border p-2"><strong>Beat : </strong></div>
                    <div class="col-md-6 border p-2">{{beatName}}</div>
                    <div class="col-md-6 border p-2"><strong> Report By : </strong></div>
                    <div class="col-md-6 border p-2">{{(createdBy !== 'null') ? createdBy :
                        ''}}</div>
                    <div class="col-md-6 border p-2"><strong>Latitude (N) : </strong></div>
                    <div class="col-md-6 border p-2">{{latitude}}</div>
                    <div class="col-md-6 border p-2"><strong>Langitude (N) : </strong></div>
                    <div class="col-md-6 border p-2">{{longitude}}</div>
                    <div class="col-md-6 border p-2"><strong>Remarks : </strong></div>
                    <div class="col-md-6 border p-2">{{(remarks !== 'null') ? remarks : 'N/A'
                        }}</div>
                       <div class="col-md-6 border p-2"><strong>Is Elephant Sighted : </strong></div>
                       <div class="col-md-6 border p-2">{{isElephantSight}}</div>
                       <div class="col-md-6 border p-2"><strong>Is other Species Sighted : </strong></div>
                       <div class="col-md-6 border p-2">{{isotherSpeciesSight}}</div>
                       <div class="col-md-6 border p-2"><strong>Species : </strong></div>
                       <div class="col-md-6 border p-2">{{species}}</div>
                       <div class="col-md-6 border p-2"><strong>Is Suspicious Activity : </strong></div>
                       <div class="col-md-6 border p-2">{{isSuspiciousActivity}}</div>
                       <div class="col-md-6 border p-2"><strong>Is Trap Noticed : </strong></div>
                       <div class="col-md-6 border p-2">{{isTrapNoticed}}</div>
                    <div class="col-md-6 border p-2"><strong>Evidence Photo : </strong></div>
                    <div class="col-md-6 border p-2">
                        <a href="{{imgAcqlocation}}" class="glightbox gallery_product filter elephant"
                            data-fancybox="gallery" data-caption="">
                            <img [src]="imgAcqlocation" *ngIf="imgAcqlocation" class="img-fluid mr-2 mb-2"
                                style="height:85px" (error)="defaultUrl($event)" alt="">
                            <div class="overlay">
                                <span class="icon">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-6 border p-2"><strong>Selfie Photo With Staff: </strong></div>
                    <div class="col-md-6 border p-2">
                        <a href="{{selfieImglocation}}" class="glightbox gallery_product filter elephant"
                            data-fancybox="gallery" data-caption="">
                            <img [src]="selfieImglocation" *ngIf="selfieImglocation"
                                class="img-fluid mr-2 mb-2" style="height:85px" (error)="defaultUrl($event)"
                                alt="">
                            <div class="overlay">
                                <span class="icon">
                                    <i class="fa fa-search"></i>
                                </span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
