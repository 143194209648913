import { Component, OnInit } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-authorityuser',
  templateUrl: './authorityuser.component.html',
  styleUrls: ['./authorityuser.component.css']
})
export class AuthorityuserComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    (function($){

      $(document).ready(function () {
          $(document).ready(function() {
            $('#multiselectRange, #multiselectSection, #multiselectBeat').multiselect({
                includeSelectAllOption: true,
                enableFiltering: true,
                buttonContainer: '<div class="btn-group w-100" />'
            });
        });
      });

    })(jQuery);

  }

}
