import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  showSuccess(message, title){
    //const options= { positionClass:'toast-custom' };
      this.toastr.success(message, title)
  }
  showSuccesswithTimeout(message, title){
    //const options= { positionClass:'toast-custom' };
      this.toastr.success(message, title,{
        timeOut: 7000
    });
  }
  showError(message, title) {
     this.toastr.error(message, title, {
         timeOut: 5000
     });
  }
    showInfo(message, title){
      this.toastr.info(message, title)
  }
  showWarning(message, title){
      this.toastr.warning(message, title)
  }
}
