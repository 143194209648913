<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

        <h4 class="card-title">Protected Areas in Odisha</h4>

        <div class="form-group row">
          <div class="col-md-2 mt-2">
            <label>Select Protected Area</label>
          </div>
          <div class="col-md-3">
            <select class="form-control" id="protectedarea"   (change)="onDivChangeloadPADashboard(PASelect.value)" [(ngModel)]='PAid'  #PASelect>
              <option value="0">Select</option>
            <option    *ngFor="let pa of sanctuaryList" value={{pa.sanctuaryId}} >
              {{pa.sanctuaryName}}
            </option>
          </select>
          </div>
          <!-- <div class="col-md-2">
            <button type="submit" class="btn btn-success">Search</button>
          </div> -->
        </div>

        <hr>

				<div>
          <app-protectedareas-balukhand-konark *ngIf="PAid=='1'"></app-protectedareas-balukhand-konark>
          <app-protectedareas-debrigarh *ngIf="PAid=='2'"></app-protectedareas-debrigarh>
          <app-protectedareas-sunabeda *ngIf="PAid=='3'"></app-protectedareas-sunabeda>
          <app-protectedareas-kotgarh *ngIf="PAid=='4'"></app-protectedareas-kotgarh>
          <app-protectedareas-khalasuni *ngIf="PAid=='5'"></app-protectedareas-khalasuni>
          <app-protectedareas-badrama *ngIf="PAid=='6'"></app-protectedareas-badrama>
          <app-protectedareas-karlapat *ngIf="PAid=='7'"></app-protectedareas-karlapat>
          <app-protectedareas-baisipalli *ngIf="PAid=='8'"></app-protectedareas-baisipalli>
          <app-protectedareas-satkosia-gorge *ngIf="PAid=='9'"></app-protectedareas-satkosia-gorge>
          <app-protectedareas-chandaka-dampada *ngIf="PAid=='10'"></app-protectedareas-chandaka-dampada>
          <app-protectedareas-nandankanan *ngIf="PAid=='11'"></app-protectedareas-nandankanan>
          <app-protectedareas-similipal *ngIf="PAid=='12'"></app-protectedareas-similipal>
          <app-protectedareas-lahkeri-valley *ngIf="PAid=='13'"></app-protectedareas-lahkeri-valley>
          <app-protectedareas-kapilash *ngIf="PAid=='14'"></app-protectedareas-kapilash>
          <app-protectedareas-hadgarh *ngIf="PAid=='15'"></app-protectedareas-hadgarh>
          <app-protectedareas-chilika-nalaban *ngIf="PAid=='16'"></app-protectedareas-chilika-nalaban>
          <app-protectedareas-bhitarakanika *ngIf="PAid=='17'"></app-protectedareas-bhitarakanika>
          <app-protectedareas-gahirmatha-marine *ngIf="PAid=='18'"></app-protectedareas-gahirmatha-marine>
          <app-protectedareas-kuldiha *ngIf="PAid=='19'"></app-protectedareas-kuldiha>
        </div>

			</div>
		</div>
	</div>
</section>

<app-footer></app-footer>
