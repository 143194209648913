<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->

<app-masterheader></app-masterheader>

<div class="wrapper">

    <app-sidebar></app-sidebar>

    <div id="content">

        <!-- <app-breadcrumb></app-breadcrumb> -->

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Role and Permission</h4>
                                    <div class="row">
                                      <div class="col-md-3">
                                          <div class="form-group">
                                              <label for="">Role Name <span class="text-danger" >*</span></label>
                                             <select class="form-control" [(ngModel)]='roleid'  id="roleid" (change)="rolePermissionTable(roleSelect.value)" #roleSelect  required>
                                                   <option value="0">Select Role</option>
                                                   <option *ngFor="let role of rolelist"  value={{role.id}}  >
                                                        {{role.name}}
                                                   </option>
                                              </select>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="table-responsive">
                                    <form [formGroup]="formArray" (submit)="submit()">
                                    <table class="table table-bordered row-border hover" *ngIf="GlinkList">
                                        <thead>
                                            <tr>
                                              <!-- <th></th> -->
                                                <th>Menu</th>
                                                <th>
                                                  Details
                                                  <span class="pull-right">
                                                    <button type="submit" class="btn btn-sm btn-success">Save</button>
                                                  </span>
                                                </th>
                                            </tr>
                                         </thead>
                                        <tbody>
                                            <tr *ngFor="let element of GlinkList; let i = index;">
                                                <!-- <td [attr.id]="i" ><input  type="checkbox" [checked]="element['isActive'] == true"  value="{{element.glink_id}}"  id="{{element.glink_id}}"></td> -->
                                                <td>{{element.name}}</td>
                                                 <td>
                                                    <!-- <span *ngFor="let item of group.link"> this.rpermissioncheck.includes(element.glink_id)
                                                    {{item.name}}
                                                    <span *ngIf='group.link.length>1'>,</span>
                                                    </span> -->
                                                    <table class="table table-bordered">
                                                      <thead>
                                                        <tr>
                                                          <th width="7%"></th>
                                                          <th width="8%">Sl. No.</th>
                                                          <th>Link Name</th>
                                                          <th>Link Path</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody *ngIf="element['pLInk'].length>0">
                                                        <tr *ngFor="let element2 of element['pLInk'] ; let i = index" [attr.data-index]="i">
                                                          <td [attr.id]="i">
                                                            <!-- <input class="form-check-input" type="checkbox" [checked]="this.rpermissioncheck.includes(plink.id)" formControlName="permissions" value="{{plink.id}}"
                                                            (change)="pushthevalue($event)" id="{{plink.id}}"> -->
                                                            <input  type="checkbox" [checked]="element2['isActive'] == 'true'" (change)="pushthevalue($event,element.glink_id,element2.id)"value="{{element2.id}}" id="{{element2.id}}" >
                                                          </td>
                                                          <td>{{i+1}} </td>
                                                          <td>{{element2.name}}</td>
                                                          <td>{{element2.linkName}}</td>
                                                        </tr>
                                                      </tbody>
                                                      <tbody *ngIf="element['pLInk'].length == 0">
                                                        <tr>
                                                          <td [attr.id]="i"><input  type="checkbox" [checked]="element['isActive'] == true"  value="{{element.glink_id}}"  id="{{element.glink_id}}" (change)="pushthevalueGlink($event,element.glink_id)"></td>
                                                          <td>1</td>
                                                          <td>{{element.name}}</td>
                                                          <td>{{element.linkName}}</td>
                                                        </tr>
                                                      </tbody>
                                                    </table>

                                                </td>
                                                <!-- <td>
                                                	<span class="badge badge-pill badge-success">Active</span>
                                                </td> -->
                                                <!-- <td>
                                                    <button class="btn btn-sm btn-info mr-1" data-toggle="modal" data-target="#viewRoleModal"><i class="fa fa-edit"></i></button>
                                                    <button class="btn btn-sm btn-danger"><i class="fa fa-trash"></i></button>
                                                </td> -->
                                            </tr>
                                            <tr>
                                              <td colspan="2">
                                                <div class="text-center">
                                                  <button type="submit" class="btn btn-sm btn-success">Save</button>
                                                </div>
                                              </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                  </form>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="viewRoleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
		  	<div class="modal-dialog modal-dialog-centered" role="document">
		    	<div class="modal-content">
			      	<div class="modal-header">
			        	<h4 class="modal-title" id="exampleModalLongTitle">Edit Role</h4>
			        	<button type="button" class="close" data-dismiss="modal" aria-label="Close">
			          		<span aria-hidden="true">&times;</span>
			        	</button>
			      	</div>
		      		<div class="modal-body">
		        		<form>
                        	<div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="">Role Name</label>
                                        <input type="text" class="form-control" id="" placeholder="Role Name">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                            		<div class="form-check form-check-inline mr-5 mb-3">
                            			<input class="form-check-input" type="checkbox" value="" id="permissionCheck1">
										<label class="form-check-label" for="permissionCheck1">
											Permission One
										</label>
                            		</div>
                                </div>
                                <div class="col-md-6">
                            		<div class="form-check form-check-inline mr-5 mb-3">
                            			<input class="form-check-input" type="checkbox" value="" id="permissionCheck2">
										<label class="form-check-label" for="permissionCheck2">
											Permission Two
										</label>
                            		</div>
                                </div>
                                <div class="col-md-6">
                            		<div class="form-check form-check-inline mr-5 mb-3">
                            			<input class="form-check-input" type="checkbox" value="" id="permissionCheck3" checked="">
										<label class="form-check-label" for="permissionCheck3">
											Permission Three
										</label>
                            		</div>
                                </div>
                                <div class="col-md-6">
                            		<div class="form-check form-check-inline mr-5 mb-3">
                            			<input class="form-check-input" type="checkbox" value="" id="permissionCheck4">
										<label class="form-check-label" for="permissionCheck4">
											Permission Four
										</label>
                            		</div>
                                </div>
                                <div class="col-md-6">
                            		<div class="form-check form-check-inline mr-5 mb-3">
                            			<input class="form-check-input" type="checkbox" value="" id="permissionCheck5">
										<label class="form-check-label" for="permissionCheck5">
											Permission Five
										</label>
                            		</div>
                                </div>
                                <div class="col-md-6">
                            		<div class="form-check form-check-inline mr-5 mb-3">
                            			<input class="form-check-input" type="checkbox" value="" id="permissionCheck6" checked="">
										<label class="form-check-label" for="permissionCheck6">
											Permission Six
										</label>
                            		</div>
                                </div>
                                <div class="col-md-6">
                            		<div class="form-check form-check-inline mr-5 mb-3">
                            			<input class="form-check-input" type="checkbox" value="" id="permissionCheck7">
										<label class="form-check-label" for="permissionCheck7">
											Permission Seven
										</label>
                            		</div>
                                </div>
                                <div class="col-md-6">
                            		<div class="form-check form-check-inline mr-5 mb-3">
                            			<input class="form-check-input" type="checkbox" value="" id="permissionCheck8">
										<label class="form-check-label" for="permissionCheck8">
											Permission Eight
										</label>
                            		</div>
                                </div>
                                <div class="col-md-6">
                            		<div class="form-check form-check-inline mr-5 mb-3">
                            			<input class="form-check-input" type="checkbox" value="" id="permissionCheck9">
										<label class="form-check-label" for="permissionCheck9">
											Permission Nine
										</label>
                            		</div>
                                </div>
                                <div class="col-md-6">
                            		<div class="form-check form-check-inline mr-5 mb-3">
                            			<input class="form-check-input" type="checkbox" value="" id="permissionCheck10">
										<label class="form-check-label" for="permissionCheck10">
											Permission Ten
										</label>
                            		</div>
                            	</div>
                            </div>
                            <hr>
                            <div class="row">
                            	<div class="col-md-12 text-center">
                            		<button type="submit" class="btn btn-sm btn-success">Update</button>
                            	</div>
                            </div>
                        </form>
		      		</div>
		    	</div>
		  	</div>
		</div>

    </div>

    <div id="footer">
        <app-masterfooter></app-masterfooter>
    </div>

</div>

