<app-header></app-header>
<app-menu></app-menu>

<section class="common-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

        <h4 class="card-title">Wildlife Census</h4>
        <p>Wildlife research and documentation is a recognized mandate of the Wildlife Wing. The conservation projects on crocodiles, sea turtles, Dolphin, tiger, elephant and blackbuck, along with the captive-breeding programme at Nandankanan, and eco-development need and approaches are some of the few areas generating lot of data which are used in planning and execution of wildlife conservation programmes.</p>
        <p>Population estimation of various wildlife species has been done regularly for better understanding of wildlife and their habitat which form a vital input for better management.</p>
        <p>As a practice, regular census of Elephant, Tiger and Blackbuck in the terrestrial (forest) habitat; the Gharial, Mugger and waterfowl in the freshwater habitat; the Estuarine Crocodile, the Waterfowl, Whale, Dolphins in fresh water and the nesting Turtles in the sea coast habitat are being conducted.</p>


        <!-- <div class="form-group row">
          <div class="col-md-1 mt-2">
            <label>Select Year :</label>
          </div>
          <div class="col-md-3">
            <select class="form-control">
              <option value="2007-2008">2007-2008</option>
              <option value="2008-2009">2008-2009</option>
              <option value="2009-2010">2009-2010</option>
              <option value="2010-2011">2010-2011</option>
              <option value="2011-2012">2011-2012</option>
              <option value="2012-2013">2012-2013</option>
              <option value="2013-2014">2013-2014</option>
              <option value="2014-2015">2014-2015</option>
              <option value="2015-2016">2015-2016</option>
              <option value="2016-2017" selected="selected">2016-2017</option>
              <option value="2017-2018">2017-2018</option>
              <option value="2018-2019">2018-2019</option>
              <option value="2019-2020">2019-2020</option>
            </select>
          </div>
        </div> -->

        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Sl. No.</th>
                <th>Species</th>
                <th>Name</th>
                <th>Census Area / Site</th>
                <th>2016-17</th>
                <th>2017-18</th>
                <th>2018-19</th>
                <th>2019-20</th>
                <th>2020-21</th>
                <th>2021-22</th>
              </tr>
            </thead>
            <tbody>
              <!-- Saltwater crocodile (Baula) -->
              <tr>
                <td>1</td>
                <td><img src="assets/images/PIC-BIJXKUISOA.jpg" height="70px"></td>
                <td>Saltwater crocodile (Baula)</td>
                <td>Bhitarkanika</td>
                <td>1694</td>
                <td>1713</td>
                <td>1763</td>
                <td>1809</td>
                <td>1830</td>
                <td>1819</td>
              </tr>
               <!-- Mugger crocodile -->
              <tr>
                <td rowspan="3">2</td>
                <td rowspan="3"><img src="assets/images/PIC-AFLXSPAONP.jpg" height="70px"></td>
                <td rowspan="3">Mugger crocodile</td>
                <td>(i) Satkosia / Mahanadi river</td>
                <td>86</td>
                <td>89</td>
                <td>104</td>
                <td>113</td>
                <td>95</td>
                <td>72</td>
              </tr>
              <tr>
                <td>(ii) River systems of Similipal Tiger Reserve</td>
                <td>77</td>
                <td>84</td>
                <td>85</td>
                <td>73</td>
                <td>80</td>
                <td>77</td>
              </tr>
              <tr>
                <td>(iii) Ghodahada Reservoir, Digapahandi, Ganjam</td>
                <td>55</td>
                <td>45</td>
                <td>58</td>
                <td>65</td>
                <td>72</td>
                <td>76</td>
              </tr>
               <!-- Gharial -->
              <tr>
                <td>3</td>
                <td><img src="assets/images/PIC-CDJKPIKDCX.jpg" height="70px"></td>
                <td>Gharial</td>
                <td>Mahanadi river system</td>
                <td>9</td>
                <td>7</td>
                <td>8</td>
                <td>8</td>
                <td>11</td>
                <td>7</td>
              </tr>
               <!-- Olive Ridley sea turtle -->
              <tr>
                <td rowspan="3">4</td>
                <td rowspan="3"><img src="assets/images/PIC-DMZKESAEYI.jpg" height="70px"></td>
                <td rowspan="3">Olive Ridley sea turtle</td>
                <td>Gahirmatha</td>
                <td>0.04 lakhs</td>
                <td>6.65 lakhs</td>
                <td>4.51 lakhs</td>
                <td>4.07 lakhs</td>
                <td>3.50 lakhs</td>
                <td>5.01 lakhs</td>
              </tr>
              <tr>
                <td>Rushikulya river mouth</td>
                <td>3.71 lakhs</td>
                <td>4.45 lakhs</td>
                <td>No Mass nesting</td>
                <td>3.23 lakhs</td>
                <td>No Mass nesting</td>
                <td>5.50 lakhs</td>
              </tr>
              <tr>
                <td><strong>Total</strong></td>
                <td>9.75 lakhs</td>
                <td>11.10 lakhs</td>
                <td>4.51 lakhs</td>
                <td>7.30 lakhs</td>
                <td>3.50 lakhs</td>
                <td>10.51 lakhs</td>
              </tr>
                <!-- Dolphins (7 species ) -->
                <tr>
                  <td rowspan="3">5</td>
                  <td rowspan="3"><img src="assets/images/PIC-YBIBZJVWCA.jpg" height="70px"></td>
                  <td>Irrawaddy Dolphins</td>
                  <td>Chilika lagoon</td>
                  <td>121</td>
                  <td>114</td>
                  <td>113</td>
                  <td>146</td>
                  <td>162</td>
                  <td>151</td>
                </tr>
                <tr>
                  <td>Bottle nose</td>
                  <td>Chilika lagoon</td>
                  <td>13</td>
                  <td>12</td>
                  <td>0</td>
                  <td>17</td>
                  <td>26</td>
                  <td>16</td>
                </tr>
              <tr>
                <td >Dolphins (7 species )</td>
                <td>Entire coastal Odisha including Chilika lagoon</td>
                <td><strong> 257     <br></strong>
                  Irrawaddy-181
                  Bottle Nose-31
                  Humpback -34
                  Humpback-6
                  Pantropical
                  spotted-5
                  Finless-0
                  Ganges River-0</td>
                  <td><strong>469  <br></strong>
                    Irrawaddy-163
                    Bottle Nose-121
                    Humpback-116
                    Humapback-69</td>
                  <td><strong>259 <br></strong>    
                    Irrawaddy-130
                    Bottle Nose-16
                    Humpback-107
                    Humapback-6</td>
                  <td><strong> 232<br></strong> 
                    Irrawady-208
                    Bottle Nose-22
                    Humpback-2</td>
                  <td><strong>544 <br></strong>      
                    Irrawaddy-209
                    Bottle Nose-54
                    Humpback-281</td>
                  <td> <strong>726 <br></strong> 
                    Irrawaddy-208
                    Bottle Nose-155
                    Humpback-332
                    Striped-13
                    Finless
                    porpoise-3
                    Spinner-15</td>
              </tr>
                 <!-- Blackbuck  -->
              <tr>
                <td rowspan="2">6</td>
                <td rowspan="2"><img src="assets/images/PIC-LMJXEJEBSU.jpg" height="70px"></td>
                <td rowspan="2">Blackbuck</td>
                <td>Balukhand-Konark Wildlife Sanctuary</td>
                <td>0</td>
                  <td>0</td>
                  <td>Census not done</td>
                  <td>Census not done</td>
                  <td>Census not done</td>
                  <td>151</td>
              </tr>
              <tr>
                <td>Blackbuck habitat area of
                  Ganjam District</td>
                <td>-</td>
                <td>4082</td>
                <td>Census not done</td>
                <td>6875</td>
                <td>7358</td>
                <td>-</td>  
              </tr>
                <!-- Elephant  -->
              <tr>
                <td>7</td>
                <td><img src="assets/images/PIC-GDATFBFYUV.jpg" height="70px"></td>
                <td>Elephant</td>
                <td>Entire State</td>
                <td>1976</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>Ongoing</td>
              </tr>
              <!-- Tiger  -->
              <tr>
                <td>10</td>
                <td><img src="assets/images/PIC-WFXIZPTIHA.jpg" height="70px"></td>
                <td>Tiger</td>
                <td>Entire State</td>
                <td>-</td>
                <td>-</td>
                <td>28 (26-30)
                  Summary
                  Report of NTCA
                  & WII, 2018</td>
                <td>-</td>
                <td>-</td>
                <td>Ongoing</td>
              </tr>
                <!-- Leopard  -->
              <tr>
                <td>11</td>
                <td><img src="assets/images/PIC-YLYQQBJEFM.jpg" height="70px"></td>
                <td>Leopard</td>
                <td>Entire State</td>
                <td>318 (2015-16)</td>
                <td>760 (727-793)*</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
                <!-- Migratory Birds  -->
              <tr>
                <td rowspan="3">7</td>
                <td rowspan="3"><img src="assets/images/PIC-VRZYEUHZAG.jpg" height="70px"></td>
                <td rowspan="3">Migratory Birds</td>
                <td>(i) Chilika lagoon</td>
                <td>9.24 lakhs <br>
                  100 species</td>
                <td>8.68 lakhs <br>
                  95 species</td>
                <td>10.21 lakhs <br>
                  105 species</td>
                <td>10.71 lakhs <br>
                  109 species</td>
                <td>12.04 lakhs <br>
                  111 Species</td>
                <td>10.74 lakhs
                  107 species</td>
              </tr>
              <tr>
                <td>(ii) Bhitarkanika Mangrove Wetland</td>
                <td>0.76 lakhs <br>
                  109 species</td>
                <td>1.13 lakhs <br>
                  83 species</td>
                <td>01.09 lakhs <br>
                  74 species</td>
                <td>1.18 lakhs  <br>
                  105 species</td>
                <td>1.36 lakhs <br>
                  121 species</td>
                <td>1.38 lakhs <br>
                  144 species</td>
              </tr>
              <tr>
                <td>(iii) Hirakud Reservoir</td>
                <td>0.78 lakhs <br>
                  57 species</td>
                <td>1.37 lakhs <br>
                  82 species</td>
                <td>01.46 lakhs <br>
                  92 species</td>
                <td>0.98 lakhs  <br>
                 93 species</td>
                <td>1.03 lakhs <br>
                  41 species</td>
                <td>2.08 lakhs <br>
                  104 species</td>
              </tr>
            </tbody>
          </table>
        </div>

        <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>

      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
