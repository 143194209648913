import { Component, OnInit } from '@angular/core';

declare var jQuery: any;

@Component({
  selector: 'app-barchart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.css']
})
export class BarchartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  	(function($){
      	
      	google.charts.load('current', {packages: ['corechart', 'bar']});
		google.charts.setOnLoadCallback(drawBasic);

		function drawBasic() {

		      var data = new google.visualization.DataTable();
		      data.addColumn('timeofday', 'No. of Incidents');
		      data.addColumn('number', 'No. of Incidents');

		      data.addRows([
		        [{v: [8, 0, 0], f: 'Human Kill'}, 4],
		        [{v: [9, 0, 0], f: 'Human Injury'}, 12],
		        [{v: [10, 0, 0], f:'Crop Damage (acres)'}, 87],
		        [{v: [11, 0, 0], f: 'House Damage'}, 38],
		        [{v: [12, 0, 0], f: 'Cattle Kill'}, 16],
		      ]);

		      var options = {
		        title: 'No. of Incidents',
		        hAxis: {
		          title: 'No. of Incidents',
		          format: 'h:mm a',
		          viewWindow: {
		            //min: [7, 30, 0],
		            //max: [17, 30, 0]
		          }
		        },
		        vAxis: {
		          title: 'Numbers (scale of 1-100)'
		        }
		      };

		      var chart = new google.visualization.ColumnChart(
		        document.getElementById('barChart'));

		      chart.draw(data, options);
		    }
		
    })(jQuery);
  }

}
