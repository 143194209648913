import { Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
      (function($){
        $(document).ready(function(){$(".fa-search").click(function(){$(".togglesearch").toggle();$(".showSearch").focus();});});
      })(jQuery)
  }

}
