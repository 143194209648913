<div class="loader-base" *ngIf="loader">
	<div class="loader"></div>
</div>
<app-masterheader></app-masterheader>
<div class="wrapper">
    <app-sidebar></app-sidebar>
    <div id="content">
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                	<div class="row">
                		<div class="col-md-2">
                			<!-- <button id="queryBtn" class="btn btn-sm btn-query pl-2">Advanced Filter<i class="fa fa-chevron-down rotate">&nbsp;</i></button> -->
							<div id="queryContent" class="query-content">
								<div class="row">
								  	<div class="col-md-12">
								  		<div class="card">
								  			<div class="card-body">
								  				<h4 class="card-title">Filter</h4>
												<form [formGroup]="analyticsForm" (ngSubmit)="onSubmit()">
										  		<div class="row">
										  			<div class="col-md-12">
		                                                <div class="form-group mb-2">
															<!-- <select class="form-control" id="divisionwl" formControlName="division"  (change)="DivisionFilter(divisionSelect.value)" [(ngModel)]='divid'  #divisionSelect>
																<option value="0">Select Division</option>
																<option    *ngFor="let division of divisionlist"   value={{division.divisionId}} >
																  {{division.divisionName}}
																</option>
															</select> -->
															<mat-form-field appearance="outline">
																<mat-select  formControlName="division"  (selectionChange)="DivisionFilter(divisionSelect.value)" [(ngModel)]='divid'  #divisionSelect placeholder="All Division">
                                  <!-- <mat-option value="0">All Division</mat-option> -->
                                  <mat-option  *ngFor="let division of divisionlist"   value={{division.divisionId}} >
																		{{division.divisionName}}
																	  </mat-option>
																</mat-select>
															</mat-form-field>
		                                                </div>
		                                            </div>
		                                        </div>
		                                        <div class="row">
		                                            <div class="col-md-12">
		                                            	<div class="form-group mb-2">
		                                                    <!-- <select class="form-control" id="rangewl" [disabled]="rangeenable" formControlName= "range" (change)="Rangefilter(rangeSelect.value)"  [(ngModel)]='rangeid'  #rangeSelect>
																<option value="0">Select Range</option>
																<option     >

																</option>
															</select> -->
															<!-- [disabled]="rangeenable" -->
															<mat-form-field appearance="outline">
															<mat-select formControlName="range" #rangeSelect (selectionChange)="Rangefilter(rangeSelect.value)"  placeholder="All Range" [(ngModel)]='rangeid'  >
                                <!-- <mat-option value="0">All Range</mat-option> -->
                                <mat-option *ngFor="let item of rangelist "   value={{item.rangeId}} >
																	{{item.rangeName}}
																  </mat-option>
															</mat-select>
														</mat-form-field>
		                                                </div>
		                                            </div>
		                                        </div>
		                                        <div class="row">
													<div class="col-md-12">
		                                            	<div class="form-group mb-2 multi multi1">
															<mat-form-field appearance="outline">
																<mat-select formControlName="year" placeholder="Year" multiple   >

																	<mat-option *ngFor="let year of jsonDataYear " value={{year.yearName}} >
																		{{year.yearName}}
																	  </mat-option>
																</mat-select>
															</mat-form-field>
		                                                </div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-12">
		                                            	<div class="form-group mb-2 multi multi2">
															<mat-form-field appearance="outline">
																<!-- [disabled]="monthenable" -->
															<mat-select formControlName="frommonth"  placeholder="Select From Month" multiple   >
																	<mat-option *ngFor="let month of jsonDataMonth " value={{month.monthID}} >
																		{{month.monthName}}
																	  </mat-option>
																</mat-select>
															</mat-form-field>
		                                                </div>
		                                            </div>
		                                        </div>
		                                        <div class="row">
		                                            <div class="col-md-12">
		                                            	<div class="form-group text-center">
		                                            		<button class="btn btn-sm btn-success mr-2" type="submit"><i class="fa fa-search">&nbsp;</i>Search</button>
		                                            		<button class="btn btn-sm btn-warning" type="button" (click)='reset()'><i class="fa fa-refresh">&nbsp;</i>Reset</button>
		                                            	</div>
		                                            </div>
										  		</div>
												</form>
										  	</div>
										</div>
								  	</div>

								</div>
							</div>
                		</div>

                        <div class="col-md-10">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Elephant Movement Analytics</h4>
                                    <div id="popup" class="ol-popup">
                                      <a href="javascript:void(0)" id="popup-closer"  class="ol-popup-closer"></a>
                                      <div id="popup-content"></div>
                                    </div>
                                    <div class="fixHeightLeft">
                                    	<div id="elephantAnalyticsMap" class="map fixHeightLeft"></div>
                                    </div>
                                    <div class="mapFilterBase col-md-3 col-sm-4">

                                        <div class="card">
                                            <div class="card-body">

                                                <div id="accordion" class="mapFilter">
                                                    <!-- <a id="layerDiv" href="#collapseOne" class="text-collapse" data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne">
                                                        <span class="d-block"><img src="assets/images/icon-layers.png" style="width:20px"></span>
                                                    </a> -->
                                                    <a id="legendDiv" href="#collapseTwo" class="text-collapse collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseTwo">
                                                        <span class="d-block"><img src="assets/images/icon-legends.png" style="width:20px"></span>
                                                    </a>
                                                    <a id="baseDiv" href="#collapseThree" class="text-collapse collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseThree">
                                                        <span class="d-block"><img src="assets/images/icon-basemap.png" style="width:20px"></span>
                                                    </a>
                                                    <!-- <a id="measureDiv" href="#collapseFour" class="text-collapse collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseFour">
                                                        <span class="d-block"><img src="assets/images/icon-measurement.png" style="width:20px"></span>
                                                    </a> -->
                                                    <div class="clearfix"></div>

                                                    <!-- <div class="mb-0">
                                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                                            <div class="fixHeightRight">
                                                            	<div class="tab-inr-title">
	                                                            	<h4>
	                                                            		Layers
	                                                            		<button class="btn-sm btn-danger closeBtn collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"><span aria-hidden="true">×</span></button>
	                                                            	</h4>
	                                                            </div>

                                                                <div class="card-body">
                                                                    <div id="accordion2">
                                                                    	<div class="collapse-inner">
	                                                                        <a id="layerDiv" href="#collapseInnerOne" class="text-dark" data-toggle="collapse" aria-expanded="true" aria-controls="collapseInnerOne">
	                                                                            <h6 class="mt-0 panel-title mb-3">Forest Administration </h6>
	                                                                        </a>
	                                                                         <div id="collapseInnerOne" class="collapse p-2 show" aria-labelledby="heading1" data-parent="#accordion2">
	                                                                            <form class="form-horizontal" action="">
	                                                                        		<div class="custom-control custom-checkbox">
																					    <input type="checkbox" class="custom-control-input" id="divisionBoundary" name="example1">
																					    <label class="custom-control-label" for="divisionBoundary">Division Boundary</label>
																					</div>
																					<div class="custom-control custom-checkbox">
																					    <input type="checkbox" class="custom-control-input" id="rangeBoundary" name="example1">
																					    <label class="custom-control-label" for="rangeBoundary">Range Boundary</label>
																					</div>
	                                                                                <div class="custom-control custom-checkbox">
																					    <input type="checkbox" class="custom-control-input" id="beatBoundary" name="example1">
																					    <label class="custom-control-label" for="beatBoundary">Beat Boundary</label>
																					</div>
																					<div class="custom-control custom-checkbox">
																					    <input type="checkbox" class="custom-control-input" id="sectionBoundary" name="example1">
																					    <label class="custom-control-label" for="sectionBoundary">Section Boundary</label>
																					</div>
																					<div class="custom-control custom-checkbox">
																					    <input type="checkbox" class="custom-control-input" id="sanctuaryBoundary" name="example1">
																					    <label class="custom-control-label" for="sanctuaryBoundary">Sanctuary Boundary</label>
																					</div>
																					 <div class="custom-control custom-checkbox">
																					    <input type="checkbox" class="custom-control-input" id="identifiedCorridor" name="example1">
																					    <label class="custom-control-label" for="identifiedCorridor">Identified Corridor</label>
																					</div>
																					<div class="custom-control custom-checkbox">
																					    <input type="checkbox" class="custom-control-input" id="railwayLine" name="example1">
																					    <label class="custom-control-label" for="railwayLine">Railway Line</label>
																					</div>
																					<div class="custom-control custom-checkbox">
																					    <input type="checkbox" class="custom-control-input" id="gajahCorridor" name="example1">
																					    <label class="custom-control-label" for="gajahCorridor">Gajah Corridor</label>
																					</div>
																					<div class="custom-control custom-checkbox">
																					    <input type="checkbox" class="custom-control-input" id="fsiForestCover" name="example1">
																					    <label class="custom-control-label" for="fsiForestCover">FSI Forest Cover - 2015</label>
																					</div>
	                                                                            </form>
	                                                                        </div>
	                                                                    </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> -->


                                                    <div class="mb-0">
                                                        <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                                                            <div class="fixHeightRight">
                                                            	<div class="tab-inr-title">
	                                                            	<h4>
	                                                            		Legends
	                                                            		<button class="btn-sm btn-danger closeBtn collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"><span aria-hidden="true">×</span></button>
	                                                            	</h4>
	                                                            </div>
                                                                <div class="card-body">
                                                                    <form class="form-horizontal" action="">
                                                                        <div class="widget-body">
                                                                            <div class="maplayer-div">
                                                                                <div id="legendDiv" class="widget-main">

																					<div class="mb-1">
                                            <img src="assets/images/geoserver-GetLegendGraphic.image.png" class="img-legend">
                                                                                    	<!-- <img src="http://164.164.122.69:8080/geoserver/iwlms/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=iwlms:elephant_sighting" class="img-legend"> -->
																						Year
	                                                                                    </div>
                                                                                    <!-- <div class="mb-1">
                                                                                    	<img src="assets/images/legend01.png" class="img-legend">
                                                                                    	Odisha Boundary
                                                                                    </div>

																					http://164.164.122.69:8080/geoserver/iwlms/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=iwlms:elephant_sighting

                                                                                    <div class="mb-1">
                                                                                    	<img src="assets/images/legend02.png" class="img-legend">
                                                                                    	Elephant Corridor
                                                                                    </div>
                                                                                    <div class="mb-1">
                                                                                    	<img src="assets/images/legend03.png" class="img-legend">
                                                                                    	Online Data
                                                                                    </div>
                                                                                    <div class="mb-1">
                                                                                    	<img src="assets/images/legend04.png" class="img-legend">
                                                                                    	Offline Data
                                                                                    </div>
                                                                                    <div class="mb-1">
                                                                                    	<img src="assets/images/legend05.png" class="img-legend">
                                                                                    	Very Dense Forest
                                                                                    </div>
                                                                                    <div class="mb-1">
                                                                                    	<img src="assets/images/legend06.png" class="img-legend">
                                                                                    	Mod. Dense Forest
                                                                                    </div>
                                                                                    <div class="mb-1">
                                                                                    	<img src="assets/images/legend07.png" class="img-legend">
                                                                                    	Open Forest
                                                                                    </div>
                                                                                    <div class="mb-1">
                                                                                    	<img src="assets/images/legend08.png" class="img-legend">
                                                                                    	Non Forest
                                                                                    </div>
                                                                                    <div class="mb-1">
                                                                                    	<img src="assets/images/legend09.png" class="img-legend">
                                                                                    	Scrub
                                                                                    </div>
                                                                                    <div class="mb-1">
                                                                                    	<img src="assets/images/legend10.png" class="img-legend">
                                                                                    	Water Body
                                                                                    </div> -->
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="mb-0">
                                                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                            <div class="fixHeightRight">
                                                            	<div class="tab-inr-title">
	                                                            	<h4>
	                                                            		Base Map
	                                                            		<button class="btn-sm btn-danger closeBtn collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"><span aria-hidden="true">×</span></button>
	                                                            	</h4>
	                                                            </div>
                                                                <div class="card-body p-2">
                                                                    <form class="form-horizontal" action="">
                                                                    	<div class="custom-control custom-checkbox mb-2">
																		    <input type="checkbox"  class="custom-control-input" id="topoBTN" (change) ="topochange($event)" name="example1">
																		    <label class="custom-control-label" for="topoBTN">Topo</label>
																		    <img src="assets/images/basemap-topo.png" class="img-basemap">
																		</div>
                                                                        <div class="custom-control custom-checkbox mb-2">
																		    <input type="checkbox"  class="custom-control-input" (change) ="streetchange($event)" id="streetBTN" name="example1" checked>
																		    <label class="custom-control-label" for="streetBTN">Google Street Map</label>
																		    <img src="assets/images/basemap-street.png" class="img-basemap">
																		</div>
																		<div class="custom-control custom-checkbox mb-2">
																		    <input type="checkbox"  class="custom-control-input" (change) ="imagerychange($event)" id="imageryBTN" name="example1">
																		    <label class="custom-control-label" for="imageryBTN">Google Sattelite Map</label>
																		    <img src="assets/images/basemap-imagery.png" class="img-basemap">
																		</div>
																		<!-- <div class="custom-control custom-checkbox mb-2">
																		    <input type="checkbox"  class="custom-control-input" (change) ="bingmapchange($event)" id="natgeoBTN" name="example1">
																		    <label class="custom-control-label" for="natgeoBTN">OSM Map</label>
																		    <img src="assets/images/basemap-natgeo.png" class="img-basemap">
																		</div> -->
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div><!--/accordion-->

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

    <div id="footer">
        <app-masterfooter></app-masterfooter>
    </div>

</div>

