import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup } from "@angular/forms";
import {Router} from "@angular/router";
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import {ApiService} from "../service/api.service";
import { AuthService } from "../service/auth.service";
import { NotificationService } from '../service/notification.service';
import { SidebarComponent } from '../sidebar/sidebar.component';
declare var jQuery: any;
const baseUrlFile = environment.FILE_API_URL;

@Component({
  selector: 'app-gajabandhu',
  templateUrl: './gajabandhu.component.html',
  styleUrls: ['./gajabandhu.component.css']
})
export class GajabandhuComponent implements OnInit {
  public loader: boolean=false;
  public circleDisabled:boolean=false;
  public divisionDisabled:boolean=false;
  public rangeDisabled:boolean=false;
  public tableData:any[];
  gajabandhuForm:FormGroup;

  public circleSelected='All';
  public divisionSelected='All';
  public rangeSelected='All';
  public sectionSelected='All';
  public beatSelected='All';

  public circleid: string;
  public divid: string;
  public rangeid: string;
  public sectionid: string;
  public beatid: string;


  public divisionlist: any;
  public circleList: any;
  public rangelist: any;
  public sectionlist: any;
  public beatlist: any;

  msbapTitle = '';
  msbapAudioUrl = '';
  msbapDisplayTitle = true;

  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  videoSource: string;
  imgSource: string;
  audioSource: string;
  broadcastelement: any;
  broadcastForm: FormGroup;

  constructor(private formBuilder: FormBuilder,private router: Router,private notifyService:NotificationService, private apiService: ApiService,private authService: AuthService) { }

  dtOptions: any = {};

  ngOnInit(): void {

    this.circleid="0";
    this.divid="0";
    this.rangeid="0";
    this.sectionid="0";
    this.beatid="0";

    this.gajabandhuForm= this.formBuilder.group({
      circle:new FormControl({value: '', disabled: false}),
      division:new FormControl({value: '', disabled: false}),
      range:new FormControl({value: '', disabled: false}),
      section:new FormControl({value: '', disabled: false}),
      beat:new FormControl({value: '', disabled: false}),

    });
    this.broadcastForm= this.formBuilder.group({
      desc:['']
    });
    this.dtOptions = {
      // pagingType: 'full_numbers',
      // pageLength: 5,
      // lengthMenu : [5, 10, 25],
      // processing: true,
      // responsive: true,
      dom: 'Bfrtip',
         buttons: [
             'copy', 'excel', 'pdf', 'print'
         ]
    };
    this.getGajabandhuReport();
    this.getAllCircle();
    (function($){
      $(document).ready(function() {
        // Gets the video src from the data-src on each button
        var $videoSrc;
        $(".video-btn").click(function() {

           $videoSrc = $(this).data( "src" );
        });
        ////console.log($videoSrc);

        // when the modal is opened autoplay it
        $('#modalVideo').on('shown.bs.modal', function (e) {

        // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
        $("#video").attr('src',$videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0" );
        })

        // // // stop playing the youtube video when I close the modal
        $('#modalVideo').on('hide.bs.modal', function (e) {
            // a poor man's stop video
            $("#video").attr('src',$videoSrc);
        })
        //////////////////////////////////////////////////////////////////////////////////////
        // Gets the video src from the data-src on each button
        var $audioSrc;
        $('.audio-btn').click(function() {
            $audioSrc = $(this).data( "src" );
        });
        ////console.log($videoSrc);

        // when the modal is opened autoplay it
        $('#modalAudio').on('shown.bs.modal', function (e) {

        // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
        $("#audio").attr('src',$audioSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0" );
        })

        // stop playing the youtube video when I close the modal
        $('#modalAudio').on('hide.bs.modal', function (e) {
            // a poor man's stop video
            $("#audio").attr('src',$audioSrc);
        })
      // document ready
      });
      	$('a[data-toggle="tab"]').on('shown.bs.tab', function(e){
		    $($.fn.dataTable.tables(true)).DataTable()
		        .columns.adjust()
		        .responsive.recalc();
		    });

        $("#datepicker, #datepicker2").datepicker({
            autoclose: true,
            todayHighlight: true
        }).datepicker('update', new Date());

    })(jQuery);
  }
  getAllCircle() {
    return this.apiService.request('GET', 'api/v1/masters/getAllCircle').subscribe((data: any) => {
	    if(data)
	    {
        this.circleList=data;
      }
    });

  }
  getAllDivisionByCircleId(circle:any){
     if(circle!="0"){
      this.apiService.request('GET', 'api/v1/masters/getAllDivisionByCircleId?id='+circle).subscribe((data: any) => {
        if(data)
        {
          this.divisionlist=data;
          this.divid="0";
           if(this.rangelist!=""){
            this.rangeid="0";
            this.rangelist=[];
            this.sectionlist=[];
            this.beatlist=[];
           }
        }
      });
     }else{
      if(this.divisionlist!=""){
        this.divisionlist=[];
        this.rangelist=[];
        this.sectionlist=[];
        this.beatlist=[];
       }
       this.divid="0";
    }
  }
  getAllRangeByDivId(div:any) {
    if(div!="0"){
   this.apiService.request('GET', 'api/v1/masters/getAllRangeByDivid?id='+div).subscribe((data: any) => {
	    if(data)
	    {
        this.rangelist=data;
        this.rangeid="0";
        if(this.sectionlist!=""){
          this.sectionid="0";
          this.sectionlist=[];
          this.beatlist=[];
        }
      }
    });
  }else{
    if(this.rangelist!=""){
      this.rangelist=[];
      this.sectionlist=[];
      this.beatlist=[];
     }
       this.rangeid="0";
    }
  }
  getAllSectionByRangeId(range:any) {
    if(range!=0){
    this.apiService.request('GET', 'api/v1/masters/getAllSectionByRangeid?id='+range).subscribe((data: any) => {
	    if(data)
	    {
        this.sectionlist=data;
        this.sectionid="0";
        if(this.beatlist!=""){
          this.beatlist=[];
          this.beatid="0";
        }
      }
    });
  }else{
    if(this.sectionlist!=""){
      this.sectionlist=[];
      this.beatlist=[];
     }
     this.sectionid="0";
    }
  }
  getAllBeatBySectionId(section:any) {
    if(section!="0"){
    this.apiService.request('GET', 'api/v1/masters/getAllBeatBySecId?id='+section).subscribe((data: any) => {
	    if(data)
	    {
        this.beatlist=data;
      }
    });
  }else{
    if(this.beatlist!=""){
      this.beatlist=[];
     }
     this.beatid="0";
    }
  }
  getGajabandhuReport(){
    this.loader=true;
    return this.apiService.request('GET', 'api/v1/gajasathiController/viewAllGajasathiReportData?division='+this.divisionSelected+'&range='+this.rangeSelected+'&section='+this.sectionSelected+'&beat='+this.beatSelected).subscribe((data: any) => {
    if(data){
      this.tableData = new Array() ;
      $("#tableGajaBandhu").DataTable().destroy();
      data.sort(function(a, b) {
        return b.reportId- a.reportId;
      });
      data.sort((a, b) => {
        return <any>new Date(b.updatedOn) - <any>new Date(a.updatedOn);
      });

      data.map(item=>{
        return {
          reportId: item['reportId'],
          imgPath : (item['imgPath']!="")?baseUrlFile+'gajabandhu/'+item['imgPath']:"",
          videoMessagePath: (item['videoMessagePath']!="")? baseUrlFile+'gajabandhu/'+item['videoMessagePath']:"",
          audioMessagePath: (item['audioMessagePath']!="")? baseUrlFile+'gajabandhu/'+item['audioMessagePath']:"",
          actionDate: item['actionDate'],
          beat: item['beat'],
          beatId: item['beatId'],
          division: item['division'],
          divisionId: item['divisionId'],
          range: item['range'],
          rangeId: item['rangeId'],
          publicationNum: item['publicationNum'],
          description: item['description'],
          updatedOn: item['updatedOn'],
          section: item['section'],
          sectionId: item['sectionId'],
          reportingDate: item['reportingDate'],
          latitude: item['latitude'],
          longitude: item['longitude'],
          status: item['status']
        }
      }).forEach(item=> this.tableData.push(item));

        this.dtTrigger.next();
        this.loader=false;
      }
    });
  }
  onSubmitGajabandhuForm(value:any){
    if(value.circle!="0"){this.circleSelected=value.circle;}
    if(value.division!="0"){this.divisionSelected=value.division;}
    if(value.range!="0"){this.rangeSelected=value.range;}
    if(value.section!="0"){this.sectionSelected=value.section;}
    if(value.beat!="0"){this.beatSelected=value.beat;}
     this.getGajabandhuReport();
  }
  getRecord(element:any){
     //console.log(element)
     this.imgSource=element.imgPath;
     this.audioSource=element.audioMessagePath;
    this.videoSource=element.videoMessagePath;

  }
  getbroadcastRecord(element:any){
    //console.log(element);
    this.broadcastelement=element;
  }
  broadcastRecord(){
  try{
    this.loader=true;
    //console.log(this.broadcastelement);
    const userdata=sessionStorage.getItem('user');

    if(userdata!=null){
      const user=JSON.parse(userdata);

      this.apiService.request('POST', 'api/v1/gajasathiController/broadCastMsg?divisionId='+this.broadcastelement.divisionId+'&lat='+this.broadcastelement.latitude+'&lon='+this.broadcastelement.longitude).subscribe((data: any) => {
        if(data.message=="success"){
          this.apiService.request('POST', 'api/v1/gajasathiController/broadCastedStatus?reportId='+this.broadcastelement.reportId+'&desc='+this.broadcastForm.value.desc+'&userid='+user.userid+'&status=Broadcasted').subscribe((data: any) => {
            if(data){
               window.location.reload();
               $("#tableGajaBandhu").DataTable().destroy();
               this.tableData=data;
                this.dtTrigger.next();
                this.loader=false;
                this.notifyService.showSuccess("Broadcasted Successfully","Success");
              }
            });

          }
        });

    } else{
      this.router.navigate(['']);
       return;
    }
  }catch{
    this.loader=false;
  }
 }
 rejectRecord(element:any){
  //console.log(element);

  var flag=confirm('Are you sure? .You Want to reject !!!!');
  if(flag){
  const userdata=sessionStorage.getItem('user');
  if(userdata!=null){
    const user=JSON.parse(userdata);
    this.broadcastForm.value.desc="";
  this.apiService.request('POST', 'api/v1/gajasathiController/broadCastedStatus?reportId='+element.reportId+'&desc='+this.broadcastForm.value.desc+'&userid='+user.userid+'&status=Rejected').subscribe((data: any) => {
    if(data){
      window.location.reload();
       $("#tableGajaBandhu").DataTable().destroy();
       this.tableData=data;
        this.dtTrigger.next();
        this.loader=false;
        this.notifyService.showSuccess("Rejected Successfully","Success");
      }
    });
  } else{
    this.router.navigate(['']);
     return;
  }
}else{
  this. getGajabandhuReport();
  this.notifyService.showError("You clicked the Cancel button! ","Error");
  this.loader=false;
 }
}
  defaultUrlImg(event) {
    event.target.src='assets/images/no_img.png';
  }
  toggleVideo() {
    this.videoplayer.nativeElement.play();
}
}
