<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">

        <ul id="tabs" class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a id="tab-similipalPAmap" href="#pane-similipalPAmap" class="nav-link active" data-toggle="tab"
              role="tab">Map</a>
          </li>
          <li class="nav-item">
            <a id="tab-similipalPAinfo" href="#pane-similipalPAinfo" class="nav-link" data-toggle="tab"
              role="tab">Information</a>
          </li>
        </ul>

        <div id="accordion" class="tab-content" role="tablist">
          <div id="pane-similipalPAmap" class="card tab-pane fade show active" role="tabpanel"
            aria-labelledby="tab-similipalPAmap">
            <div class="card-header" role="tab" id="heading-similipalPAmap">
              <h5 class="mb-0">
                <a data-toggle="collapse" href="#collapse-similipalPAmap" aria-expanded="true"
                  aria-controls="collapse-similipalPAmap">
                  Map
                </a>
              </h5>
            </div>
            <div id="collapse-similipalPAmap" class="collapse show" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-similipalPAmap">
              <div class="card-body">
                <div class="map" id="mapsimilipal">
                  <div class="gle-icon">
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid"
                        alt="Street View" title="Street View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()'
                        alt="Satellite View" title="Satellite View">
                    </a>
                    <a href="javascript:void(0)">
                      <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View"
                        title="Topo View">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="pane-similipalPAinfo" class="card tab-pane fade" role="tabpanel"
            aria-labelledby="tab-similipalPAinfo">
            <div class="card-header" role="tab" id="heading-similipalPAinfo">
              <h5 class="mb-0">
                <a class="collapsed" data-toggle="collapse" href="#collapse-similipalPAinfo" aria-expanded="false"
                  aria-controls="collapse-similipalPAinfo">
                  Information
                </a>
              </h5>
            </div>
            <div id="collapse-similipalPAinfo" class="collapse" data-parent="#accordion" role="tabpanel"
              aria-labelledby="heading-similipalPAinfo">

              <div class="card-body">

                <h4 class="card-title">
                  Similipal Sanctuary
                  <span class="custom-down">
                    <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i
                        class="fa fa-file-pdf">&nbsp;</i>Information</button>
                        <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                  </span>
                </h4>
                <div id="printcontent">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td><strong>Forest Division : </strong></td>
                              <td>Similipal Tiger Reserve</td>
                            </tr>
                            <tr>
                              <td><strong>District : </strong></td>
                              <td>Mayurbhanj</td>
                            </tr>
                            <tr>
                              <td><strong>Date of Notification : </strong></td>
                              <td>03-12-1979</td>
                            </tr>
                            <tr>
                              <td><strong>Notified Area : </strong></td>
                              <td>2750.00sq.km</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-md-2 video-box no-print">
                      <a href="#" class="video-btn" data-toggle="modal"
                        data-src="https://www.youtube.com/embed/xhxvy3XCFzc" data-target="#myModal">
                        <h4>Similipal Sanctuary</h4>
                        <div class="overlay">
                          <span class="icon">
                            <i class="fa fa-play-circle"></i>
                          </span>
                        </div>
                      </a>
                    </div>
                    <div class="col-md-2">
                      <img src="assets/images/simil-pic.jpg" class="img-fluid float-right img-inner" alt="image">
                    </div>
                  </div>
                  <p>
                    Similipal Tiger Reserve in Mayurbhanj district is endowed with high peaks like Khairiburu,Maghasini
                    and rocky cliffs like Ganapati facing the frost-valleys in Devasthali.The name ‘Simiipal’ is
                    believed to be derived from the abundance of Semul or Red Silk cotton trees which bloom vividly
                    every year.The biodiversity of the land scape with high degree of endemism is the striking feature
                    of the sanctuary.It harbours Tiger,Leopard,Sloth bear,Elephant,Gaur and other important major faunal
                    species.Similipal is the only home for the unique melanistic tiger in the world.There are 55 species
                    of mammals,361 species of birds,62 species of reptiles,21 species of amphibians,with many species of
                    invertebrate are present in the landscape.
                  </p>
                  <h4 class="card-title">Quick Facts</h4>
                  <ul class="custom-list">
                    <li>The undulating terrain and its perennial sources of water are the two striking features of the
                      entire area.</li>
                    <li>The forest boasts of innumerable medicinal and aromatic plants, which provide a source of
                      earnings for the tribal people.</li>
                    <li>The Tiger Reserve is rewarding for bird watchers and anthropologists.</li>
                    <li>Over 4.5lakh people generate livelihood possibilities from Similipal Tiger Reserve.</li>
                  </ul>
                  <h4 class="card-title">How to reach</h4>
                  <p class="address-inner">
                    <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>Baripada, the district headquarters of
                    Mayurbhanj, on the junction of NH 5 and 6, is 250 km from Bhubaneswar, 200 km from Kolkata and 60 km
                    from Balasore and 22 km from Pithabata, which is an entry point. The other entry point, Jashipur, is
                    94 km from Baripada on N.H. 6. Both places are well connected by regular bus services. Taxis and
                    jeeps are available.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong>Nearest railheads are Baripada,
                    Balasore, TataNagar (Jamshedpur) and Kharagpur.
                  </p>
                  <p class="address-inner">
                    <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>Nearest airports are Bhubaneswar,
                    Kolkata, Jamshedpur and Ranchi.
                  </p>
                  <h4 class="card-title">Facilities available</h4>
                  <ul class="custom-list">
                    <li>Natural walks, hiking and rough trekking are the facilities tourists can avail.</li>
                    <li>Jaranda Falls and Barheipani Falls with inducing a scintillating pond at its base to take a
                      soothing bath for the visitors.</li>
                    <li>Natural walks,hiking and rough trekking are the facilities tourists can avail.</li>
                  </ul>
                  <h4 class="card-title">Salient Detail</h4>
                  <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                      <tbody>
                        <tr>
                          <td width="15%"><strong>Geographic Extent : </strong></td>
                          <td width="85%" colspan="3">Longitude 86° 03' to 86° 35'(East) and Latitude 21° 14' to 22°
                            20'(North)</td>
                        </tr>
                        <tr>
                          <td><strong>Climate : </strong></td>
                          <td colspan="3">Temperature - In summer: 48°C, In winter: 2°C Rainfall - 2200cm, rainy days:
                            100-130 days</td>
                        </tr>
                        <tr>
                          <td><strong>Villages :</strong></td>
                          <td colspan="3">65 Villages including one in the Core area (Proposed National Park).</td>
                        </tr>
                        <tr>
                          <td><strong>Contact officer : </strong></td>
                          <td>
                            <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Field Director<br>
                            <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Similipal Tiger
                            Reserve, Baripada, Dist - Mayurbhanj (Odisha)<br>
                            <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06792-252593 (O), 252773
                            (R)
                          </td>
                          <td>
                            <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>DFO, Baripada
                            Division<br>
                            <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span> At/PO - Baripada,
                            Dist - Mayurbhanj<br>
                            <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06792-25613 (O), 252532
                            (R) <br>
                          </td>
                          <td>
                            <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>DFO, Karanjia
                            Division<br>
                            <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>At/PO - Karanjia,
                            Dist - Mayurbhanj<br>
                            <span class="address-inner"><i class="fa fa-phone">&nbsp;</i></span>06796-220226 (0), 220232
                            (R)
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Best time to visit : </strong></td>
                          <td colspan="3">Similipal for tourist open from 01 Nov. to 15 June.</td>
                        </tr>
                        <tr>
                          <td><strong>Accommodation : </strong></td>
                          <td colspan="3">
                            <p>Reservation for accommodation only through on-line booking WWW.ecotourodisha.com </p>
                            <p><strong>Jamuani : </strong>Tribal Hut-1 (Triple bedded-1 Suite), Tribal Hut-II (Triple
                              bedded-1 Suite)</p>
                            <p><strong>Kumari : </strong>Bamboo Manchan-2 suites (Doublebedded each), Bamboo Cattage-4
                              Cottages (Double bedded each)</p>
                            <p><strong>Gurguria : </strong>Tourist Cottage-I (Double Bedded-1 suite), Tourist Cottage-II
                              (Double Bedded-1 suite), Pine Villa (Double Bedded-2 suites), Pine Villa (Triple Bedded 2
                              suites)</p>
                            <p><strong>Ramatirtha : </strong>Chital Villa-Dormitory (10 Bedded-A/c), Chowsingha Villa
                              Dormitory (10 Bedded-non A/C) Chinkara Villa-Dormitory (10 Bedded-non A/C), Tribal Hut
                              (Double-Bedded-3 suites A/C), Devasthap cottage (Double Bedded-4 suites A/C)</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
                      </strong>The information contained herein are provided solely for informational purposes only.The
                      content is dynamic and may undergo change from time to time. The snapshot of the content at a
                      particular time is not to be referred as a legally binding document.</small></p>
                </div>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
