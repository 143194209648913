import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ApiService } from '../service/api.service';
import { NotificationService } from '../service/notification.service'
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

declare var jQuery: any;

@Component({
  selector: 'app-citizenuser',
  templateUrl: './citizenuser.component.html',
  styleUrls: ['./citizenuser.component.css']
})
export class CitizenuserComponent implements OnInit {

  public loader: boolean = false;
  public loader2: boolean = false;
  public loader3: boolean = false;
  districtList: any;
  blockList: any;
  gpList: any;
  vilageList: any;
  citizenForm: FormGroup;
  citizenuserTableForm: FormGroup;
  ExcelUploadForm: FormGroup;
  district_userView: string;
  block_userView: string;
  gp_userView: string;
  village_userView: string;
  district_excelupload: string;
  block_excelupload: string;
  gp_excelupload: string;
  village_excelupload: string;
  tabledata: any[];
  role: string;
  userId: string;
  districtId: string;
  blockId: string;
  gpId: string;
  villageId: string;
  updateForm: FormGroup;
  fullName: any;
  mobile: any;
  active: any;
  citizenUserId: any;
  phonecheck: any;
  selectedExcelFile: any;
  rejectedList: any;

  constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private notifyService: NotificationService) { }
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();

  ngOnInit(): void {
    this.citizenForm = this.formBuilder.group({
      district: [0],
      block: [0],
      gp: [0],
      village: [0],
      name: ['', Validators.required],
      mobileno: ['', Validators.compose([Validators.required, Validators.pattern("[0-9 ]{10}")]),]
    });
    this.citizenuserTableForm = this.formBuilder.group({
      userView_district: [0],
      userView_block: [0],
      userView_gp: [0],
      userView_village: [0]
    });
    this.ExcelUploadForm = this.formBuilder.group({
      Excelupload_district: [0],
      Excelupload_block: [0],
      Excelupload_gp: [0],
      Excelupload_village: [0],
      Excel_File: ['', [Validators.required]]
    });
    this.updateForm = this.formBuilder.group({
      userId: ['', Validators.required],
      fullName: ['', Validators.compose([Validators.required])],
      mobile: ['', Validators.compose([Validators.required, Validators.pattern("[0-9 ]{10}")]),],
      active: ['', Validators.required]
    });

    this.districtId = "0";
    this.blockId = "0";
    this.gpId = "0";
    this.villageId = "0";


    this.district_userView = "0";
    this.block_userView = "0";
    this.gp_userView = "0";
    this.village_userView = "0";
    this.block_excelupload = "0";
    this.district_excelupload = "0";
    this.gp_excelupload = "0";
    this.village_excelupload = "0";


    var ds = this;
    (function ($) {
      $('#uploadExcelModal').on('hidden.bs.modal', function () {
        ds.district_excelupload = "0";
        ds.block_excelupload = "0";
        ds.gp_excelupload = "0";
        ds.village_excelupload = "0";



      })
    })(jQuery);
    const userdata = sessionStorage.getItem("user");
    if (userdata) {
      const user = JSON.parse(userdata);
      this.userId = user.userid;
      this.role = user.roleId;
    }
    this.dtOptions = {
      dom: 'Bfrtip',
      buttons: [
        'copy', 'excel', 'pdf', 'print'
      ]
    };
    this.getAllDistrict();
    // this.citizenuserInfoTable();
    //this.downloadSampleExcel();
  }
  citizenuserInfoTable() {
    if (this.role == '2') {
      return this.apiService.request('GET', 'api/v1/auth/getAllCitizenUserByCreatedBy?id=' + this.userId).subscribe((data: any) => {
        if (data) {
          data.sort((a, b) => {
            return <any>new Date(b.createdOn) - <any>new Date(a.createdOn);
          });
          this.tabledata = new Array();
          this.tabledata = data;
          this.dtTrigger.next();
        }
      }, err => {
        this.loader = false;
      });
    } else {
      return this.apiService.request('GET', 'api/v1/auth/getAllCitizenUser').subscribe((data: any) => {
        if (data) {
          data.sort((a, b) => {
            return <any>new Date(b.createdOn) - <any>new Date(a.createdOn);
          });
          this.tabledata = new Array();
          this.tabledata = data;
          this.dtTrigger.next();
        }
      }, err => {
        this.loader = false;
      });
    }
  }
  getAllDistrict() {
    this.apiService.request('GET', 'api/v1/masters/getAllDistrict').subscribe((data: any) => {
      if (data) {
        this.districtList = data;
      }
    }, err => {
      this.loader = false;
    });
  }
  getAllBlockByDistId(dist: any) {
    this.loader = true;
    if (dist != 0) {
      this.apiService.request('GET', 'api/v1/masters/getAllBlockByDistId?distId=' + dist).subscribe((data: any) => {
        if (data) {
          this.blockList = data;
          this.blockId = "0";
          this.gpList = [];
          this.vilageList = [];
          this.loader = false;
        }
      }, err => {
        this.loader = false;
      });
    } else {
      if (this.blockList != "") {
        this.blockList = [];
        this.gpList = [];
        this.vilageList = [];
      }
      this.blockId = "0";
      this.loader = false;
    }
  }
  getAllgpByBlockId(block: any) {
    this.loader = true;
    if (block != 0) {
      this.apiService.request('GET', 'api/v1/masters/getAllGpByBlockId?blockId=' + block).subscribe((data: any) => {
        if (data) {
          this.gpList = data;
          this.gpId = "0";
          this.vilageList = [];
          this.loader = false;
        }
      }, err => {
        this.loader = false;
      });
    } else {
      if (this.gpList != "") {
        this.gpList = [];
        this.vilageList = [];
      }
      this.gpId = "0";
      this.loader = false;
    }
  }
  getAllVilageByGpId(gp: any) {
    this.loader = true;
    if (gp != 0) {
      this.apiService.request('GET', 'api/v1/masters/getAllVillageByGpId?gpId=' + gp).subscribe((data: any) => {
        if (data) {
          this.vilageList = data;
          this.villageId = "0";
          this.loader = false;
        }
      }, err => {
        this.loader = false;
      });
    } else {
      if (this.vilageList != "") {
        this.vilageList = [];
      }
      this.villageId = "0";
      this.loader = false;
    }
  }
  phoneCheckUnique(phone: any) {
    this.loader = true;
    if (this.mobile != phone) {
      this.apiService.request('GET', 'api/v1/auth/checkCitizenUserPhoneAvail?phone=' + phone).subscribe(res => {
        this.phonecheck = -res;
      }, err => {
        this.loader = false;
      });
    }
    if (this.phonecheck == true) {
      this.notifyService.showError("Mobile number Already Exist!!!", "Error");
      this.citizenForm.controls.mobileno.setValue("");
      this.updateForm.controls.mobile.setValue("");
      this.loader = false;
    }
    else {
      if (this.citizenForm.controls.mobileno.valid) {
        this.notifyService.showSuccess("Mobile number avail", "Success");
      } else if (this.updateForm.controls.mobile.valid) {
        this.loader = false;
        this.notifyService.showSuccess("Mobile number avail", "Success");
      }
      this.loader = false;
    }
  }
  signup() {
    if (this.citizenForm.valid) {
      this.loader2 = true;
      if (this.citizenForm.value.district != 0) {
        if (this.citizenForm.value.block != 0) {
          if (this.citizenForm.value.gp != 0) {
            if (this.citizenForm.value.village != 0) {
              return this.apiService.request('POST', 'api/v1/auth/citizensignup', {
                fullname: this.citizenForm.value.name,
                distId: this.citizenForm.value.district,
                blockId: this.citizenForm.value.block,
                gpId: this.citizenForm.value.gp,
                villageId: this.citizenForm.value.village,
                mobile: this.citizenForm.value.mobileno,
                createdBy: this.userId
              }).subscribe((response: any) => {
                if (response.message == "success") {
                  this.notifyService.showSuccess("Register Successfull", "Success");
                  $("#citizenUserTable").DataTable().destroy();
                  this.citizenForm.reset();
                  this.ngOnInit();
                  this.loader2 = false;
                  this.loader = false;//set loader  for invisible
                } else if (response.message == "failed") {
                  this.notifyService.showError("UnSuccessful", "Error");
                  this.loader2 = false;
                  this.loader = false;
                }
              }, (err) => {
                this.notifyService.showError(err.error, "Error");
                this.loader = false;//set loader  for invisible
                this.loader2 = false;
              }
              );
            }
            else {
              this.loader2 = false;
              this.notifyService.showWarning('Select village', 'Error');
            }
          }
          else {
            this.loader2 = false;
            this.notifyService.showWarning('Select gp', 'Error');
          }
        }
        else {
          this.loader2 = false;
          this.notifyService.showWarning('Select block', 'Error');
        }
      }
      else {
        this.loader2 = false;
        this.notifyService.showWarning('Select District', 'Error');
      }
    }
    else {
      this.loader2 = false;
      this.notifyService.showWarning('Enter all fields', 'Error');
    }
  }
  onSubmitViewUserForm() {
    this.loader = true;
    let district, block, gp, village;
    if (this.district_userView == "0") {
      district = "All";
    } else {
      district = this.district_userView.toString();
    }
    if (this.block_userView == "0") {
      block = "All";
    } else {
      block = this.block_userView.toString();
    }
    if (this.gp_userView == "0") {
      gp = "All";
    } else {
      gp = this.gp_userView.toString();
    }
    if (this.village_userView == "0") {
      village = "All";
    } else {
      village = this.village_userView.toString();
    }
    return this.apiService.request('POST', 'api/v1/auth/getAllCitizenUserByFilter', {
      district_id: district,
      block_id: block,
      gp_id: gp,
      village_id: village
    }).subscribe((data: any) => {
      if (data) {
        $("#citizenUserTable").DataTable().destroy();
        this.tabledata = new Array();
        this.tabledata = data;
        this.dtTrigger.next();
        this.loader = false;
      } else {
        this.loader = false;
      }
    }, err => {
      this.loader = false;
    });
  }
  onSubmitViewExcelUpload() {
    if (this.ExcelUploadForm.valid) {
      //  this.loader2 = true;
      if (this.ExcelUploadForm.value.Excelupload_district != 0) {
        if (this.ExcelUploadForm.value.Excelupload_block != 0) {
          if (this.ExcelUploadForm.value.Excelupload_gp != 0) {
            if (this.ExcelUploadForm.value.Excelupload_village != 0) {
              if (this.ExcelUploadForm.value.Excel_File != null) {
                let formdata: FormData = new FormData();
                formdata.append('file', this.selectedExcelFile);
                this.apiService.request('POST', 'api/v1/auth/addMultipleCitizenUser?userId=' + this.userId + '&distId=' + this.district_excelupload + '&blockId='
                  + this.block_excelupload + '&GPId=' + this.gp_excelupload + '&villageId=' + this.village_excelupload, formdata)
                  .subscribe((response: any) => {

                    if (response.message) {
                      this.notifyService.showError(response.message, "Error");
                    }
                    else {
                      jQuery("#uploadExcelModal").modal("hide");
                      this.notifyService.showSuccess(response[0].success, "Success");
                      if (response[0].rejectedList.length > 0) {
                        this.rejectedList = response[0].rejectedList;
                        Swal.fire({
                          title: 'Do you want to view the rejected data?',
                          showDenyButton: true,
                          showCancelButton: true,
                          confirmButtonText: 'Yes',
                          denyButtonText: `No`,
                        })
                          .then((result) => {
                            if (result.value) {

                              let Heading = [['Fullname', 'Mobile']];
                              const workbook: XLSX.WorkBook = XLSX.utils.book_new();
                              const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
                              XLSX.utils.sheet_add_aoa(ws, Heading);
                              XLSX.utils.sheet_add_json(ws, this.rejectedList, { origin: 'A2', skipHeader: true });
                              XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
                              XLSX.writeFile(workbook, "RejectList" + ".xlsx");
                            }
                          })
                      }



                    }



                  }, (err) => {
                    this.notifyService.showError(err.error, "Error");
                    this.loader = false;//set loader  for invisible
                    this.loader2 = false;
                  }
                  );
              } else {
                this.loader2 = false;
                this.notifyService.showError("Please Select Excel.", "Error");
              }
            }
            else {
              this.loader2 = false;
              this.notifyService.showWarning('Select village', 'Error');
            }
          }
          else {
            this.loader2 = false;
            this.notifyService.showWarning('Select gp', 'Error');
          }
        }
        else {
          this.loader2 = false;
          this.notifyService.showWarning('Select block', 'Error');
        }
      }
      else {
        this.loader2 = false;
        this.notifyService.showWarning('Select District', 'Error');
      }
    }
    else {
      this.loader2 = false;
      this.notifyService.showWarning('Enter all fields', 'Error');
    }


  }
  onSelectExcelFile(event) {
    const file = event.target.files.item(0)
    //console.log(file)
    //console.log(event)
    if (file.type.match("application/vnd.ms-excel") || file.type.match("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
      this.selectedExcelFile = file;
    } else {
      this.notifyService.showError("invalid format!.Only excelFile is supported.", "Error");
      this.ExcelUploadForm.controls.selectedExcelFile.setValue("");
    }
    if (event.length === 0)
      return;
  }
  getUserByID(userid: any) {
    try {
      this.loader = true;
      this.apiService.request('GET', 'api/v1/auth/getCitizenUserById?userId=' + userid).subscribe((data: any) => {
        if (data) {
          this.loader = false;
          this.fullName = data.fullName;
          this.mobile = data.mobile;
          this.active = data.active;
          this.citizenUserId = data.userId;
          //this.updateForm.setValue(data);

        } else {
          this.loader = false;
        }
      }, err => {
        this.loader = false;
      });

    } catch {
      this.loader = false;
    } finally {
      this.loader = false;
    }
  }
  editUser() {
    // this.loader=true;
    this.loader3 = true;
    try {
      if (!this.updateForm.invalid) {
        return this.apiService.request('PUT', 'api/v1/auth/updateCitizenUser', {
          fullname: this.updateForm.value.fullName,
          mobile: this.updateForm.value.mobile,
          isActive: this.updateForm.value.active,
          userid: this.citizenUserId,
          updatedBy: this.userId
        }).subscribe((response: any) => {
          var res = JSON.parse(response);
          if (res.message == "success") {

            this.loader3 = false;
            jQuery("#citizenUserModal").modal("hide");
            this.notifyService.showSuccess("User updated successfully!", "Success");
            $("#citizenUserTable").DataTable().destroy();
            this.onSubmitViewUserForm();
            //this.userInfTable();
          } else if (res.message == "failed") {
            this.loader3 = false;
            this.notifyService.showError("Unsuccessfull", "Error");
          }
        }, err => {
          this.loader = false;
          this.loader2 = false;
          this.loader3 = false;
        });
      }
    } catch {
      this.loader3 = false;
      //this.loader=false;
    } finally {
      this.loader3 = false;
      //this.loader=false;
    }
  }
  downloadSampleExcel() {
    console.log("here");
    // this.router.navigate[('/citizenuser')];
    // this.apiService.request('GET', 'api/v1/auth/downloadSampleExcel').subscribe(X => {
    //   this.router.navigate[('/citizenuser')];
    // });
    window.open('/assets/Excelfolder/citizen_user.xls', '_blank');


  }
}
