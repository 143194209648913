<app-header *ngIf="isDashboard"></app-header>
<app-menu  *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
			        <li class="nav-item">
			            <a id="tab-karlapatPAmap" href="#pane-karlapatPAmap" class="nav-link active" data-toggle="tab" role="tab">Map</a>
			        </li>
			        <li class="nav-item">
			            <a id="tab-karlapatPAinfo" href="#pane-karlapatPAinfo" class="nav-link" data-toggle="tab" role="tab">Information</a>
			        </li>
			    </ul>

			    <div id="accordion" class="tab-content" role="tablist">
			        <div id="pane-karlapatPAmap" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-karlapatPAmap">
			            <div class="card-header" role="tab" id="heading-karlapatPAmap">
			                <h5 class="mb-0">
			                    <a data-toggle="collapse" href="#collapse-karlapatPAmap" aria-expanded="true" aria-controls="collapse-karlapatPAmap">
                            Map
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-karlapatPAmap" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-karlapatPAmap">
                    <div class="card-body">
                           <div class="map" id="mapkarlapat">
                             <div class="gle-icon">
                              <a href="javascript:void(0)">
                                <img src="assets/images/icon-street.png" (click)='streetchange()' class="img-fluid" alt="Street View" title="Street View">
                            </a>
                            <a href="javascript:void(0)">
                                <img src="assets/images/icon-satellite.png" class="img-fluid" (click)='stelitechange()' alt="Satellite View" title="Satellite View">
                            </a>
                            <a href="javascript:void(0)">
                                <img src="assets/images/icon-topo.png" class="img-fluid" (click)='topochange()' alt="Topo View" title="Topo View">
                            </a>
                                    </div>
                           </div>
                    </div>
			            </div>
			        </div>

			        <div id="pane-karlapatPAinfo" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-karlapatPAinfo">
			            <div class="card-header" role="tab" id="heading-karlapatPAinfo">
			                <h5 class="mb-0">
			                    <a class="collapsed" data-toggle="collapse" href="#collapse-karlapatPAinfo" aria-expanded="false" aria-controls="collapse-karlapatPAinfo">
			                       Information
			                    </a>
			                </h5>
			            </div>
			            <div id="collapse-karlapatPAinfo" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-karlapatPAinfo">

                    <div class="card-body">

                      <h4 class="card-title">
                        Karlapat Sanctuary
                        <span class="custom-down">
                          <button type="" (click)="print()" class="btn btn-sm btn-info mr-1"><i class="fa fa-file-pdf">&nbsp;</i>Information</button>
                          <button class="btn btn-sm btn-info" (click)="alert()"><i class="fa fa-file-pdf">&nbsp;</i>Map</button>
                          </span>
                      </h4>
                      <div id="printcontent">
                      <div class="row">
                        <div class="col-md-8">
                          <div class="table-responsive">
                            <table class="table table-sm table-bordered">
                              <tbody>
                                <tr>
                                  <td><strong>Forest Division : </strong></td>
                                  <td>Kalahandi South (T) Forest Division</td>
                                </tr>
                                <tr>
                                  <td><strong>District : </strong></td>
                                  <td>Kalahandi</td>
                                </tr>
                                <tr>
                                  <td><strong>Date of Notification : </strong></td>
                                  <td>15-10-1992</td>
                                </tr>
                                <tr>
                                  <td><strong>Notified Area : </strong></td>
                                  <td>147.66sq.kms</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-md-2 video-box no-print">
                          <a href="#" class="video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/8mtiZHdE4ho" data-target="#myModal">
                            <h4>Karlapat Sanctuary</h4>
                            <div class="overlay">
                              <span class="icon">
                                <i class="fa fa-play-circle"></i>
                              </span>
                            </div>
                          </a>
                        </div>
                        <div class="col-md-2">
                          <img src="assets/images/Karlapat.jpg" class="img-fluid float-right img-inner" alt="image">
                        </div>
                      </div>
                      <p>
                        The Karlapat sanctuary is located in Kalahandi South Division which is 12km from Bhawanipatna in Kalahandi district covering a dense patch of lush green dry deciduous forest. A beautiful waterfall,'Phurlijharan' has been developed as a picnic spot for the local visitors and draws large number of visitors from far off places in and around Kalahandi District.Karlapat Wildlife Sanctuary is home to a plethora wildlife animals and birds.The sanctuary is rich in wildlife such as leopard, gaur, sambar, nilgai, barking deer, mouse deer,soft claws ottawa, a wide variety of birds and reptiles.
                      </p>
                      <h4 class="card-title">Quick Facts</h4>
                      <ul class="custom-list">
                        <li>Phurlijharan waterfall,a picnic spot is located at about 16 km from Bhawanipatna. However cooking inside is prohibited.</li>
                        <li>The undulated topography of the sanctuary, with hills, valley, perennial streams and deep water pools give it an unique charm.</li>
                        <li>A temple dedicated to Goddess Manikeswari is also located in Bhawanipatna city.</li>
                        <li>The government of Orissa has taken initiative to preserve and conserve sanctuary for a healthy wildlife population in order to strike a balance in the ecosystem .</li>
                      </ul>
                      <h4 class="card-title">How to reach</h4>
                      <p class="address-inner">
                        <i class="fa fa-road">&nbsp;</i><strong>By Road : </strong>You can take regular buses from bhubaneswar to Bhawanipatna
                      </p>
                      <p class="address-inner">
        <i class="fa fa-train">&nbsp;</i><strong>By Rail : </strong>Hirakhand Express(18448/47) runs daily from Bhubaneswar to Bhawanipatna (Jhunagarh) which is located 35km from Bhawanipatna. The nearest majorstation is Kesinga Railway Station
      </p>
      <p class="address-inner">
        <i class="fa fa-plane">&nbsp;</i><strong>By Air : </strong>The nearest airport to Bhawanipatna is in Raipur that is 250km away.
                      </p>
                      <h4 class="card-title">Facilities available</h4>
                      <ul class="custom-list">
                        <li>You can avail the government guesthouses in Bhawanipatna.</li>
                        <li>Many good Hotels are available in Bhawanipatna city.</li>
                      </ul>
                      <h4 class="card-title">Salient Detail</h4>
                      <div class="table-responsive">
                        <table class="table table-sm table-bordered">
                          <tbody>
                            <tr>
                              <td width="15%"><strong>Geographic Extent : </strong></td>
                              <td width="85%">Longitude 82° 58' to 83° 15'(East) and Latitude 19° 48' to 19° 39' (North)</td>
                            </tr>
                            <tr>
                              <td><strong>Climate : </strong></td>
                              <td>Moderate</td>
                            </tr>
                            <tr>
                              <td><strong>Villages :</strong></td>
                              <td>There are 19 villages existing inside the sanctuary. However, 110 villages in the periphery.</td>
                            </tr>
                            <tr>
                              <td><strong>Contact officer : </strong></td>
                              <td>
                                <span class="address-inner"><i class="fa fa-user">&nbsp;</i></span>Range Officer<br>
                                <span class="address-inner"><i class="fa fa-map-marker">&nbsp;</i></span>Karlapt sanctuary,Kalahandi South Division.<br>
                              </td>
                            </tr>
                            <tr>
                              <td><strong>Best time to visit : </strong></td>
                              <td>October- April</td>
                            </tr>
                            <tr>
                              <td><strong>Accommodation : </strong></td>
                              <td>Many good HOtels available at Bhawanipatna city which is about 25km away from the sanctuary. One can avail the govt guest house also.</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p class="text-danger"><small><i class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer : </strong>The information contained herein are provided solely for informational purposes only.The content is dynamic and may undergo change from time to time. The snapshot of the content at a particular time is not to be referred as a legally binding document.</small></p>
                    </div>

                     </div>
			            </div>
			        </div>

			    </div>

			</div>
		</div>
	</div>
</section>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">

      <div class="modal-body">

       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- 16:9 aspect ratio -->
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="" id="video"  allowscriptaccess="always" allow="autoplay"></iframe>
        </div>

      </div>

    </div>
  </div>
</div>

<app-footer *ngIf="isDashboard"></app-footer>
