<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->

<app-masterheader></app-masterheader>

<div class="wrapper">

	<app-sidebar></app-sidebar>

	<div id="content">

		<!-- <app-breadcrumb></app-breadcrumb> -->

		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12">

					<h4 class="card-title">Citizen Audit Report <button type="submit"
							class="btn btn-xs btn-primary pull-right" (click)="backtoauditpage()"><i
								class="fa fa-chevron-left">&nbsp;</i>Back</button></h4>
					<div class="card">
						<div class="card-body">
							<ul id="tabs" class="nav nav-tabs" role="tablist">
						
								<li class="nav-item">
									<a id="tab-B" href="#pane-B" class="nav-link active" data-toggle="tab"(click)="loadtable(01)" role="tab">District 
										Wise Citizen
										Report</a>
								</li>
							</ul>
							<div id="accordion" class="tab-content" role="tablist">
								<div id="pane-B" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-B">
									<div class="card-header" role="tab" id="heading-B">
										<h5 class="mb-0"><a class="collapsed" data-toggle="collapse" href="#collapse-B"
												aria-expanded="false" aria-controls="collapse-B">District
												Wise Citizen
												Report
											</a>
										</h5>
									</div>
									<div id="collapse-B" class="collapse" data-parent="#accordion" role="tabpanel"
										aria-labelledby="heading-B">
										<div class="card-body">
											<form [formGroup]="citizenqueryForm1"(ngSubmit)="onSubmit(citizenqueryForm1.value)">
												<div class="row">
													<div class="col-md-12">

														<div class="row">
															<div class="col-md-2 mb-1">
																<label>District</label>
																<select class="form-control" id="DistrictW1"
																	formControlName="district" [(ngModel)]='distId'
																	(change)="getAllBlockByDistId(distSelect.value)"
																	#distSelect>
																	<option value="0">All District</option>
																	<option *ngFor="let district of districtlist "
																		value={{district.districtId}}>
																		{{district.districtName}}
																</select>
															</div>
															<div class="col-md-2 mb-1">
																<label>Block</label>
																<select class="form-control" id="BlockW1"
																	formControlName="block" [(ngModel)]='blockId'
																	#blockSelect>
																	<option value="0">All Block</option>
																	<option *ngFor="let block of blocklist "
																		value={{block.blockId}}>
																		{{block.blockName}}
																</select>
															</div>

															<div class="col-md-1">
																<div class="col-md-3">
																	<button type="submit"
																		class="btn btn-sm btn-success custom-btn-top">Submit</button>
																</div>
															</div>
														</div>
													</div>
												</div>
												<hr>
											</form>
											<div class="table-responsive">
												<table id="districtWiseCitizenTable"
													class="table table-bordered display responsive nowrap" width="99%"
													datatable [dtOptions]="dtOptions"  [dtTrigger]="dtTrigger1">
													<thead>
														<tr>
															<th class="all">Sl. No.</th>
															<th class="all">District</th>
															<th class="all">Block</th>
															<th class="all">GP</th>
															<th class="all">Village</th>
															<th class="all">Total Citizen</th>
														</tr>
													</thead>
													<tbody>
                                                        <tr *ngFor="let element1 of tabledata1 ; let i = index;">
															<td>{{i+1}}</td>
															<td>{{element1.districtName}} </td>
															<td>{{element1.blockName}}</td>
															<td>{{element1.gpName}}</td>
															<td>{{element1.villageName}}</td>
															<td>{{element1.totalCitizen}}</td>
															</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div id="footer">
		<app-masterfooter></app-masterfooter>
	</div>

</div>