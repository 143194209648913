
<app-masterheader></app-masterheader>

<div class="wrapper">

    <app-sidebar></app-sidebar>

    <div id="content">

        <!-- <app-breadcrumb></app-breadcrumb> -->

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">

                                    <div class="row form-group mb-0">
                                        <div class="col-md-12">
                                            <div class="tab-slider--container">
                                                <div id="referenceDivision" class="tab-slider--body">
                                                    <div class="form-group row mb-0">
                                                        <div class="col-md-1 mt-2">
                                                            <label>Circle</label>
                                                        </div>
                                                        <div class="col-md-3">
                                                          <!-- <label>Circle</label> -->
                                                          <select class="form-control" id="circlewl"  (change)="onCircleChangeloadDashboard(circleSelect.value)" [(ngModel)]='circleid' [disabled]="circleDisabled"   #circleSelect>
                                                            <option value="0">All Circle</option>
                                                            <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                                              {{circle.circleName}}
                                                            </option>
                                                        </select>
                                                        </div>
                                                        <div class="col-md-1 mt-2">
                                                            <label>Division</label>
                                                        </div>
                                                        <div class="col-md-3">
                                                          <!-- <label>Division</label> -->
                                                          <select class="form-control" id="divisionwl"  (change)="onDivChangeloadWLDashboard(divisionSelect.value)" [(ngModel)]='divid' [disabled]="divisionDisabled" #divisionSelect>
                                                            <option value="0">All Division</option>
                                                            <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                                              {{division.divisionName}}
                                                            </option>
                                                        </select>
                                                        </div>
                                                        <div class="col-md-1 mt-2">
                                                            <label>Range</label>
                                                        </div>
                                                        <div class="col-md-3">
                                                          <!-- <label>Range</label> -->
                                                          <select class="form-control" id="rangewl"  (change)="onRangeChangeloadDashboard(rangeSelect.value)" [(ngModel)]='rangeid' [disabled]="rangeDisabled"  #rangeSelect>
                                                            <option value="0">All Range</option>
                                                            <option    *ngFor="let range of rangelist "   value={{range.rangeId}} >
                                                              {{range.rangeName}}
                                                            </option>
                                                        </select>
                                                        </div>
                                                        <!-- <div class="col-md-3">
                                                            <button type="submit" class="btn btn-sm btn-success"><i class="fa fa-search">&nbsp;</i>Search</button>
                                                        </div> -->
                                                    </div>
                                                </div>
                                                <div id="referenceArea" class="tab-slider--body">
                                                    <div class="form-group row mb-0">
                                                        <div class="col-md-3">
                                                            <select class="form-control">
                                                                <option selected="selected">All Protected Area ...</option>
                                                                <option>Angul</option>
                                                                <option>Berhampur</option>
                                                                <option>Bhawanipatna</option>
                                                                <option>Bhubaneswar</option>
                                                                <option>Koraput</option>
                                                                <option>Rourkela</option>
                                                                <option>Sambalpur</option>
                                                                <option>Similipal</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-md-1">
                                                            <button type="submit" class="btn btn-sm btn-success"><i class="fa fa-search"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-3">
                                            <select class="form-control"  id="division"   >
                                              <option *ngFor="let division of divisionlist"  value={{division.divId}}  >
                                                {{division.divName}}
                                              </option>
                                            </select>
                                        </div> -->
                                        <!-- <div class="col-md-1">
                                            <button type="submit" class="btn btn-sm btn-success"><i class="fa fa-search"></i></button>
                                        </div> -->
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-10">
                            <div class="card">
                                <div class="card-body">
                                  <div id="elephantdashboardmap" class="map" style="height:540px"></div>
                                  <div id="popup" class="ol-popup">
                                    <a href="#" id="popup-closer" class="ol-popup-closer"></a>
                                    <div id="popup-content"></div>
                                  </div>
                                    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7671827.980432994!2d79.93986032596823!3d20.13364058956313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a226aece9af3bfd%3A0x133625caa9cea81f!2sOdisha!5e0!3m2!1sen!2sin!4v1600083338610!5m2!1sen!2sin" width="100%" height="430" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">

                            <div class="card mini-stats-wid bg-success">
                                <div class="card-body">
                                    <div class="media">
                                        <div class="media-body text-white">
                                            <p class="font-weight-medium">Total Elephants Sighted in last 24 Hours</p>
                                            <h4 class="mb-0">{{Elephant_Sighted}}
                                               <!-- <a href="#"><i class="mdi mdi-arrow-right-bold-circle-outline">&nbsp;</i></a> -->
                                            </h4>
                                        </div>

                                        <div class="mini-stat-icon avatar-sm rounded-circle align-self-center">
                                            <span class="avatar-title">
                                                <i class="mdi mdi-evernote mdi-36px"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card mini-stats-wid bg-dark">
                                <div class="card-body">
                                    <div class="media">
                                        <div class="media-body text-white">
                                            <p class="font-weight-medium">Direct Elephants Sighted Status in 24 Hours</p>
                                            <h4 class="mb-0">{{Direct_Sighting}}
                                               <a [routerLink]="['/elephantmovementrecord']"><i class="mdi mdi-arrow-right-bold-circle-outline">&nbsp;</i></a>
                                              </h4>
                                        </div>

                                        <div class="mini-stat-icon avatar-sm rounded-circle align-self-center">
                                            <span class="avatar-title">
                                                <i class="mdi mdi-directions mdi-36px"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mini-stats-wid bg-pink">
                                <div class="card-body">
                                    <div class="media">
                                        <div class="media-body text-white">
                                            <p class="font-weight-medium">Indirect Elephants Sighted Status in 24 Hours</p>
                                            <h4 class="mb-0">{{indirect_Sighting}}
                                              <a [routerLink]="['/elephantmovementrecord']"><i class="mdi mdi-arrow-right-bold-circle-outline">&nbsp;</i></a>
                                            </h4>
                                        </div>

                                        <div class="mini-stat-icon avatar-sm rounded-circle align-self-center">
                                            <span class="avatar-title">
                                                <i class="mdi mdi-directions-fork mdi-36px"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Yellow Card for Problematic Reporting -->
                            <div class="card mini-stats-wid bg-yellow">
                                <div class="card-body">
                                    <div class="media">
                                        <div class="media-body text-white">
                                            <p class="font-weight-medium">Problematic Reporting in 24 Hours</p>
                                            <h4 class="mb-0">{{problematicReport_Sighting}}
                                                <a [routerLink]="['/elephantmovementrecord']"><i class="mdi mdi-arrow-right-bold-circle-outline">&nbsp;</i></a>
                                            </h4>
                                        </div>

                                        <div class="mini-stat-icon avatar-sm rounded-circle align-self-center">
                                            <span class="avatar-title">
                                                <i class="mdi mdi-directions mdi-36px"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mini-stats-wid bg-danger">
                                <div class="card-body">
                                    <div class="media">
                                        <div class="media-body text-white">
                                            <p class="font-weight-medium">Elephants Death in 24 Hours</p>
                                            <h4 class="mb-0">{{ElephantDeath_Sighting}}
                                                <a [routerLink]="['/reports']"><i class="mdi mdi-arrow-right-bold-circle-outline">&nbsp;</i></a>
                                            </h4>
                                        </div>
                            
                                        <div class="mini-stat-icon avatar-sm rounded-circle align-self-center">
                                            <span class="avatar-title">
                                                <i class="mdi mdi-road-variant mdi-36px"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                            <!-- <div class="card mini-stats-wid bg-danger">
                                <div class="card-body">
                                    <div class="media">
                                        <div class="media-body text-white">
                                            <p class="font-weight-medium">Incident Alert in 24 Hours</p>
                                            <h4 class="mb-0">{{FireAlert_Sighting}}
                                              <a href="#"><i class="mdi mdi-arrow-right-bold-circle-outline">&nbsp;</i></a>
                                            </h4>
                                        </div>

                                        <div class="mini-stat-icon avatar-sm rounded-circle align-self-center">
                                            <span class="avatar-title">
                                                <i class="mdi mdi-fire mdi-36px"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                        </div>
                        <!-- <div class="col-md-12">
                            <p><img src="assets/images/icon-duplicate.png" alt="image" height="18" class="mr-1">indicates Duplicate Reporting.</p>
                        </div> -->
                    </div>

                    <!-- <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Wildlife Depredation</h4>
                                    <app-barchart></app-barchart>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Incident Report Status</h4>
                                    <app-piechart></app-piechart>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!-- <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Incident</h4>
                                  <app-barchartmultipleone [chartdata]={{chardata}}></app-barchartmultipleone>
                                  <div id="verticalBarChartOne" style="width:100%;height:400px;"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Elephant Death</h4>
                                    <app-barchartmultipletwo></app-barchartmultipletwo>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">Total Elephants Sighted in last 24 hours</h4>
                                    <div class="table-responsive">
                                        <table id="reportTable" class="table table-bordered row-border hover" width="99%" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                            <thead>
                                                <tr>
                                                    <th>Sl. No.</th>
                                                    <th>Division</th>
                                                    <th>Range</th>
                                                    <th>No. of Herd</th>
                                                    <th>No. of Elephant</th>
                                                    <th>Sighting Types</th>
                                                    <th>From Time</th>
                                                    <th>To Time</th>
                                                    <!-- <th>Actions</th> -->
                                                </tr>
                                             </thead>
                                            <tbody>
                                                <tr *ngFor="let group of tableData ; let i = index" [attr.data-index]="i">
                                                    <td>{{i+1}} </td>
                                                    <td>{{group.divisionName}}</td>
                                                    <td>{{group.rangeName}}</td>
                                                    <td> {{ (group.heard  !== 'null') ? group.heard  : 0 }}</td>
                                                    <td> {{ (group.total  !== 'null') ? group.total  : 0 }}</td>
                                                    <td>{{group.reportType=='nill' ? 'nil' : group.reportType}}</td>
                                                    <td>{{ (group.sightingDate  !== 'null') ? (group.sightingDate | date:'dd-MM-yyyy')  : '' }} {{ (group.sightingTimefrom  !== 'null') ? (group.sightingTimefrom  | date:'shortTime')  : '' }} </td>
                                                    <td>{{group.sightingDate | date:'dd-MM-yyyy'}}  {{group.sightingTimeTo | date:'shortTime'}}</td>
                                                    <!-- <td>
                                                        <button class="btn btn-sm btn-success mr-1"><i class="fa fa-eye"></i></button>
                                                    </td> -->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

    <div id="footer">
        <app-masterfooter></app-masterfooter>
    </div>

</div>

