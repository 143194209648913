<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<app-masterheader></app-masterheader>

<div class="wrapper">

	<app-sidebar></app-sidebar>

	<div id="content">

		<!-- <app-breadcrumb></app-breadcrumb> -->

		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12">

					<div class="row">
						<div class="col-md-12">
							<div class="card">
								<div class="card-body">
									<h4 class="card-title">MPR</h4>

									<ul id="tabs" class="nav nav-tabs" role="tablist">
										<li class="nav-item">
								            <a id="tab-A" href="#pane-A" class="nav-link active" data-toggle="tab" role="tab">FORM: D-I</a>
								        </li>
										<li class="nav-item">
											<a id="tab-B" href="#pane-B" class="nav-link" data-toggle="tab" role="tab">FORM: D-II</a>
										</li>
										<li class="nav-item">
											<a id="tab-C" href="#pane-C" class="nav-link" data-toggle="tab" role="tab">FORM: D-III</a>
					                    </li>
					                    <li class="nav-item">
											<a id="tab-D" href="#pane-D" class="nav-link" data-toggle="tab" role="tab">FORM: D-IV</a>
										</li>
										<li class="nav-item">
											<a id="tab-E" href="#pane-E" class="nav-link" data-toggle="tab" role="tab">FORM: D-V</a>
										</li>
										<li class="nav-item">
											<a id="tab-F" href="#pane-F" class="nav-link" data-toggle="tab" role="tab">FORM: D-VI</a>
										</li>
										<li class="nav-item">
											<a id="tab-G" href="#pane-G" class="nav-link" data-toggle="tab" role="tab">FORM: D-VII</a>
										</li>
										<li class="nav-item">
											<a id="tab-H" href="#pane-H" class="nav-link" data-toggle="tab" role="tab">FORM: D-VIII</a>
										</li>
										<li class="nav-item">
											<a id="tab-I" href="#pane-I" class="nav-link" data-toggle="tab" role="tab">FORM: D-IX</a>
										</li>
										<li class="nav-item">
											<a id="tab-J" href="#pane-J" class="nav-link" data-toggle="tab" role="tab">FORM: D-X</a>
										</li>
									</ul>

									<div id="accordion" class="tab-content" role="tablist">

										<div id="pane-A" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-A">
								            <div class="card-header" role="tab" id="heading-A">
								                <h5 class="mb-0">
								                    <a data-toggle="collapse" href="#collapse-A" aria-expanded="true" aria-controls="collapse-A">
								                        FORM: D-I
								                    </a>
								                </h5>
								            </div>
								            <div id="collapse-A" class="collapse show" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-A">
								                <div class="card-body">
								                	<h4 class="text-right">FORM: D-I</h4>
				                                    <h4 class="card-title text-center">
				                                    	MPR ON<br>
														COMPASSIONATE PAYMENT DUE TO WILD ANIMAL DEPREDATION<br>
														(The report is confined to present year only i.e. from April to March every year)
				                                    </h4>
                                            		<form [formGroup]="MPR_D1" (ngSubmit)="onSubmit_MPR_D1_form(MPR_D1.value)">
				                                    	<div class="row">
				                                    		<div class="col-md-6">
				                                    			<div class="row form-group">
				                                    				<div class="col-md-6">
				                                    					<label class="mt-1">Name of the Circle :</label>
				                                    				</div>
				                                    				<div class="col-md-6">
				                                    					<select class="form-control form-control-sm" id="circlewl" formControlName="circle"  (change)="getAllDivisionByCircleId(circleSelect.value)" [(ngModel)]='circleid'    #circleSelect>
								                                        <option value="0">All Circle</option>
								                                        <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
								                                          {{circle.circleName}}
								                                        </option>
								                                    </select>
				                                    				</div>
				                                    			</div>
                                              					<div class="row form-group">
				                                    				<div class="col-md-6">
				                                    					<label class="mt-1">Name of the Division :</label>
				                                    				</div>
				                                    				<div class="col-md-6">
				                                    					<select class="form-control form-control-sm" formControlName="division"  [(ngModel)]='divid'  #divisionSelect>
								                                        <option value="0">All Division</option>
								                                        <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
								                                          {{division.divisionName}}
								                                        </option>
								                                    </select>
				                                    				</div>
				                                    			</div>
				                                    			<div class="row form-group">
				                                    				<div class="col-md-6">
				                                    					<label class="">Funds received upto previous month :<br><small>(Present year only)</small></label>
				                                    				</div>
				                                    				<div class="col-md-6">
				                                    					<input type="number" class="form-control form-control-sm" placeholder="Funds received upto previous month">
				                                    				</div>
				                                    			</div>
				                                    			<div class="row form-group">
				                                    				<div class="col-md-6">
				                                    					<label class="mt-1">Funds received during the month :</label>
				                                    				</div>
				                                    				<div class="col-md-6">
				                                    					<input type="number" class="form-control form-control-sm" placeholder="Funds received during the month">
				                                    				</div>
				                                    			</div>
				                                    			<div class="row form-group">
				                                    				<div class="col-md-6">
				                                    					<label class="mt-1">Total Funds received :</label>
				                                    				</div>
				                                    				<div class="col-md-6">
				                                    					<input type="number" class="form-control form-control-sm" placeholder="Total Funds received">
				                                    				</div>
				                                    			</div>
				                                    		</div>
				                                    		<div class="col-md-6 align-self-end">
				                                    			<div class="row form-group">
				                                    				<div class="col-md-6 text-right">
				                                    					<label class="mt-1">Month :</label>
				                                    				</div>
				                                    				<div class="col-md-6">
				                                    					<select class="form-control form-control-sm">
				                                    						<option selected="selected">-- Select Month --</option>
				                                    						<option>January</option>
				                                    						<option>February</option>
				                                    						<option>March</option>
				                                    						<option>April</option>
				                                    						<option>May</option>
				                                    						<option>June</option>
				                                    						<option>July</option>
				                                    						<option>August</option>
				                                    						<option>September</option>
				                                    						<option>October</option>
				                                    						<option>November</option>
				                                    						<option>December</option>
				                                    					</select>
				                                    				</div>
				                                    			</div>
				                                    		</div>
				                                    	</div>
				                                    	<div class="table-responsive">
				                                    		<table class="table table-sm table-bordered">
				                                    			<thead class="text-center">
				                                    				<tr>
																		<th rowspan="3">Type of Depredation</th>
																		<th colspan="7">Details of compassionate payment during the month</th>
																		<th rowspan="3">Total amount paid up to end of the month</th>
																		<th rowspan="3"></th>
																		<th rowspan="3">Balance funds available with DFO at the end of the month</th>
																	</tr>
																	<tr>
																		<th rowspan="2">Wild Animal</th>
																		<th rowspan="2">No of victims</th>
																		<th rowspan="2">Extent of damage</th>
																		<th colspan="3">Compassionate amount paid</th>
																		<th rowspan="2">Total amount paid during the month</th>
																	</tr>
																	<tr>
																		<th>Sanction Order No.</th>
																		<th>Date</th>
																		<th>Amount paid</th>
																	</tr>
																	<tr>
																		<th>1</th>
																		<th width="9%">2</th>
																		<th width="9%">3</th>
																		<th width="9%">4</th>
																		<th width="9%">5</th>
																		<th width="9%">6</th>
																		<th width="9%">7</th>
																		<th width="9%">8</th>
																		<th width="9%">9</th>
																		<th width="5%"></th>
																		<th width="9%">10</th>
																	</tr>
				                                    			</thead>
				                                    			<tbody>
				                                    				<tr>
				                                    					<td><strong>1. Human death :</strong></td>
				                                    					<td colspan="9">
					                                    					<table id="faqs" class="table table-sm table-borderless mb-0">
					                                    						<tbody>
					                                    							<tr>
					                                    								<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td width="6%">
								                                    						<button class="btn btn-xs btn-danger mr-1"><i class="fa fa-trash"></i></button>
								                                    						<button onclick="addfaqs();" class="btn btn-xs btn-success"><i class="fa fa-plus"></i></button>
								                                    					</td>
					                                    							</tr>
					                                    						</tbody>
					                                    					</table>
				                                    					</td>
				                                    					<td rowspan="10"><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td><strong>2. Human injury</strong></td>
				                                    					<td colspan="9"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td>2.1. Permanent :</td>
				                                    					<td colspan="9">
					                                    					<table id="faqs2" class="table table-sm table-borderless mb-0">
					                                    						<tbody>
					                                    							<tr>
					                                    								<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td width="6%">
								                                    						<button class="btn btn-xs btn-danger mr-1"><i class="fa fa-trash"></i></button>
								                                    						<button onclick="addfaqs2();" class="btn btn-xs btn-success"><i class="fa fa-plus"></i></button>
								                                    					</td>
					                                    							</tr>
					                                    						</tbody>
					                                    					</table>
				                                    					</td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td>2.2. Temporary :</td>
				                                    					<td colspan="9">
					                                    					<table id="faqs3" class="table table-sm table-borderless mb-0">
					                                    						<tbody>
					                                    							<tr>
					                                    								<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td width="6%">
								                                    						<button class="btn btn-xs btn-danger mr-1"><i class="fa fa-trash"></i></button>
								                                    						<button onclick="addfaqs3();" class="btn btn-xs btn-success"><i class="fa fa-plus"></i></button>
								                                    					</td>
					                                    							</tr>
					                                    						</tbody>
					                                    					</table>
				                                    					</td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td><strong>Total Human injury</strong></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td><strong>3. Crop damage</strong></td>
				                                    					<td colspan="9"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td>3.1. Paddy & cereals :</td>
				                                    					<td colspan="9">
					                                    					<table id="faqs4" class="table table-sm table-borderless mb-0">
					                                    						<tbody>
					                                    							<tr>
					                                    								<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td width="6%">
								                                    						<button class="btn btn-xs btn-danger mr-1"><i class="fa fa-trash"></i></button>
								                                    						<button onclick="addfaqs4();" class="btn btn-xs btn-success"><i class="fa fa-plus"></i></button>
								                                    					</td>
					                                    							</tr>
					                                    						</tbody>
					                                    					</table>
				                                    					</td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td>Total Paddy Crop</td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td>3.2. Cash Crop:</td>
				                                    					<td colspan="9">
					                                    					<table id="faqs5" class="table table-sm table-borderless mb-0">
					                                    						<tbody>
					                                    							<tr>
					                                    								<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td><input type="number" class="form-control form-control-sm"></td>
								                                    					<td width="6%">
								                                    						<button class="btn btn-xs btn-danger mr-1"><i class="fa fa-trash"></i></button>
								                                    						<button onclick="addfaqs5();" class="btn btn-xs btn-success"><i class="fa fa-plus"></i></button>
								                                    					</td>
					                                    							</tr>
					                                    						</tbody>
					                                    					</table>
				                                    					</td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td><strong>Total Crop Damage</strong></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td></td>
				                                    				</tr>
				                                    			</tbody>
				                                    			<!-- <tbody>
				                                    				<tr>
				                                    					<td><strong>1. Human death :</strong></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td rowspan="2"><strong>1. Human injury</strong><br>2.1. Permanent :<br>2.2. Temporary :</td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td rowspan="2"><strong>3. Crop damage</strong><br>3.1. Paddy & cereals :<br>3.2. Cash :</td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td rowspan="2"><strong>4. House damage</strong><br>4.1. Partly :<br>4.2. Fully :</td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td rowspan="2"><strong>5. Cattle kill</strong><br>5.1. Cow/Bullock/Buffalo :<br>5.2. Calf :</td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td><strong>Total</strong></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    			</tbody> -->
				                                    		</table>
				                                    	</div>
				                                    	<div class="text-center">
				                                    		<button type="submit" class="btn btn-sm btn-success">Submit</button>
				                                    	</div>
				                                    </form>
								                </div>
								            </div>
								        </div>

										<div id="pane-B" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-B">
											<div class="card-header" role="tab" id="heading-B">
												<h5 class="mb-0">
													<a class="collapsed" data-toggle="collapse" href="#collapse-B" aria-expanded="false" aria-controls="collapse-B">
														FORM: D-II
													</a>
												</h5>
											</div>
											<!-- direct table -->
											<div id="collapse-B" class="collapse" data-parent="#accordion" role="tabpanel" aria-labelledby="heading-B">
												<div class="card-body">
													<h4 class="text-right">FORM: D-II</h4>
				                                    <h4 class="card-title text-center">
				                                    	MPR ON<br>
														ON DEATH OF WILD ANIMALS<br>
														(The report is confined to present year only i.e. from April to March every year)
				                                    </h4>
				                                    <form [formGroup]="MPR_D2" (ngSubmit)="onSubmit_MPR_D2_form(MPR_D2.value)">
				                                    	<div class="row">
				                                    		<div class="col-md-6">
                                                  <div class="row form-group">
				                                    				<div class="col-md-6">
				                                    					<label class="mt-1">Name of the Circle :</label>
				                                    				</div>
				                                    				<div class="col-md-6">
				                                    					<select class="form-control form-control-sm" formControlName="circle"  (change)="getAllDivisionByCircleId(circleSelect.value)" [(ngModel)]='circleid'    #circleSelect>
								                                        <option value="0">All Circle</option>
								                                        <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
								                                          {{circle.circleName}}
								                                        </option>
								                                    </select>
				                                    				</div>
				                                    			</div>
                                                  <div class="row form-group">
				                                    				<div class="col-md-6">
				                                    					<label class="mt-1">Name of the Division :</label>
				                                    				</div>
				                                    				<div class="col-md-6">
				                                    					<select class="form-control form-control-sm" formControlName="division"  [(ngModel)]='divid' >
								                                        <option value="0">All Division</option>
								                                        <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
								                                          {{division.divisionName}}
								                                        </option>
								                                    </select>
				                                    				</div>
				                                    			</div>
				                                    		</div>
				                                    		<div class="col-md-6 align-self-end">
				                                    			<div class="row form-group">
				                                    				<div class="col-md-6 text-right">
				                                    					<label class="mt-1">Month :</label>
				                                    				</div>
				                                    				<div class="col-md-6">
				                                    					<select class="form-control form-control-sm">
				                                    						<option selected="selected">-- Select Month --</option>
				                                    						<option>January</option>
				                                    						<option>February</option>
				                                    						<option>March</option>
				                                    						<option>April</option>
				                                    						<option>May</option>
				                                    						<option>June</option>
				                                    						<option>July</option>
				                                    						<option>August</option>
				                                    						<option>September</option>
				                                    						<option>October</option>
				                                    						<option>November</option>
				                                    						<option>December</option>
				                                    					</select>
				                                    				</div>
				                                    			</div>
				                                    		</div>
				                                    	</div>
				                                    	<div class="table-responsive">
				                                    		<table class="table table-sm table-bordered">
				                                    			<thead class="text-center">
				                                    				<tr>
																		<th rowspan="2">Species</th>
																		<th colspan="4">No of death up to end of last month</th>
																		<th colspan="7">No of death during the month</th>
																		<th colspan="4">Progressive total</th>
																		<th rowspan="2">Remarks (Whether coming under PA/ Sanctuary area)</th>
																	</tr>
																	<tr>
																		<th>Male</th>
																		<th>Female</th>
																		<th>Un Identified sex</th>
																		<th>Total</th>
																		<th>Date</th>
																		<th>Place</th>
																		<th>Cause of death</th>
																		<th>Male</th>
																		<th>Female</th>
																		<th>Un Identified sex</th>
																		<th>Total</th>
																		<th>Male</th>
																		<th>Female</th>
																		<th>Un Identified sex</th>
																		<th>Total</th>
																	</tr>
																	<tr>
																		<th width="10%">1</th>
																		<th width="5%">2</th>
																		<th width="5%">3</th>
																		<th width="5%">4</th>
																		<th width="5%">5</th>
																		<th width="5%">7</th>
																		<th width="5%">8</th>
																		<th width="5%">9</th>
																		<th width="5%">10</th>
																		<th width="5%">11</th>
																		<th width="5%">12</th>
																		<th width="5%">13</th>
																		<th width="5%">14</th>
																		<th width="5%">15</th>
																		<th width="5%">16</th>
																		<th width="5%">17</th>
																		<th width="15%">18</th>
																	</tr>
				                                    			</thead>
				                                    			<tbody>
				                                    				<tr>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    			</tbody>
				                                    		</table>
				                                    	</div>
				                                    	<p><strong>*Causes of death to be mentioned as</strong></p>
				                                    	<ul class="custom-list-mpr">
				                                    		<li>Poaching</li>
															<li>Poisoning</li>
															<li>Deliberate electrocution</li>
															<li>Accidental electrocution</li>
															<li>Rail accident</li>
															<li>Other Accident</li>
															<li>Natural</li>
															<li>Disease</li>
															<li>Reason not known</li>
				                                    	</ul>
				                                    	<div class="text-center">
				                                    		<button type="submit" class="btn btn-sm btn-success">Submit</button>
				                                    	</div>
				                                    </form>
												</div>
											</div>
										</div>

										<div id="pane-C" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-C">
											<div class="card-header" role="tab" id="heading-C">
												<h5 class="mb-0">
													<a class="collapsed" data-toggle="collapse" href="#collapse-C" aria-expanded="false" aria-controls="collapse-C">
														FORM: D-III
													</a>
												</h5>
											</div>
											<div id="collapse-C" class="collapse" role="tabpanel" data-parent="#accordion" aria-labelledby="heading-C">
												<div class="card-body">
													<h4 class="text-right">FORM: D-III</h4>
				                                    <h4 class="card-title text-center">
				                                    	MPR ON<br>
														WILD ANIMAL DEPREDATION<br>
														(The report is confined to present year only i.e. from April to March every year)
				                                    </h4>
				                                    <form [formGroup]="MPR_D3" (ngSubmit)="onSubmit_MPR_D3_form(MPR_D3.value)">
				                                    	<div class="row">
				                                    		<div class="col-md-6">
                                                    <div class="row form-group">
                                                      <div class="col-md-6">
                                                        <label class="mt-1">Name of the Circle :</label>
                                                      </div>
                                                      <div class="col-md-6">
                                                        <select class="form-control form-control-sm" formControlName="circle"  (change)="getAllDivisionByCircleId(circleSelect.value)" [(ngModel)]='circleid'    #circleSelect>
                                                          <option value="0">All Circle</option>
                                                          <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                                            {{circle.circleName}}
                                                          </option>
                                                      </select>
                                                      </div>
                                                    </div>
                                                    <div class="row form-group">
                                                      <div class="col-md-6">
                                                        <label class="mt-1">Name of the Division :</label>
                                                      </div>
                                                      <div class="col-md-6">
                                                          <select class="form-control form-control-sm" formControlName="division"  [(ngModel)]='divid' >
                                                            <option value="0">All Division</option>
                                                            <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                                              {{division.divisionName}}
                                                            </option>
                                                          </select>
                                                      </div>
                                                    </div>
				                                    		</div>
				                                    		<div class="col-md-6 align-self-end">
				                                    			<div class="row form-group">
				                                    				<div class="col-md-6 text-right">
				                                    					<label class="mt-1">Month :</label>
				                                    				</div>
				                                    				<div class="col-md-6">
				                                    					<select class="form-control form-control-sm">
				                                    						<option selected="selected">-- Select Month --</option>
				                                    						<option>January</option>
				                                    						<option>February</option>
				                                    						<option>March</option>
				                                    						<option>April</option>
				                                    						<option>May</option>
				                                    						<option>June</option>
				                                    						<option>July</option>
				                                    						<option>August</option>
				                                    						<option>September</option>
				                                    						<option>October</option>
				                                    						<option>November</option>
				                                    						<option>December</option>
				                                    					</select>
				                                    				</div>
				                                    			</div>
				                                    		</div>
				                                    	</div>
				                                    	<h4 class="card-title">1. HUMAN KILL / INJURY</h4>
				                                    	<div class="table-responsive">
				                                    		<table class="table table-sm table-bordered">
				                                    			<thead class="text-center">
				                                    				<tr>
																		<th rowspan="3">Name of wild animal</th>
																		<th rowspan="3">Date of incidence</th>
																		<th colspan="4">Number of human beings killed/injured during the month</th>
																		<th colspan="4">Progressive total up to end of the month</th>
																	</tr>
																	<tr>
																		<th rowspan="2">Kill</th>
																		<th colspan="3">Injury</th>
																		<th rowspan="2">Kill</th>
																		<th colspan="3">Injury</th>
																	</tr>
																	<tr>
																		<th>Permanent</th>
																		<th>Temporary</th>
																		<th>Total</th>
																		<th>Permanent</th>
																		<th>Temporary</th>
																		<th>Total</th>
																	</tr>
																	<tr>
																		<th>1</th>
																		<th>2</th>
																		<th>3</th>
																		<th>4</th>
																		<th>5</th>
																		<th>6</th>
																		<th>7</th>
																		<th>8</th>
																		<th>9</th>
																		<th>10</th>
																	</tr>
				                                    			</thead>
				                                    			<tbody>
				                                    				<tr>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td class="text-center"><strong>Total</strong></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    			</tbody>
				                                    		</table>
				                                    	</div>
				                                    	<h4 class="card-title">2. CATTLE KILL</h4>
				                                    	<div class="table-responsive">
				                                    		<table class="table table-sm table-bordered">
				                                    			<thead class="text-center">
				                                    				<tr>
																		<th rowspan="2">Name of wild animal</th>
																		<th colspan="3">Number of cattle killed during the month</th>
																		<th colspan="3">Progressive total up to end of the month</th>
																	</tr>
																	<tr>
																		<th>Bullock / cow / Buffalo</th>
																		<th>Calf</th>
																		<th>Total</th>
																		<th>Bullock / cow / Buffalo</th>
																		<th>Calf</th>
																		<th>Total</th>
																	</tr>
																	<tr>
																		<th>1</th>
																		<th>2</th>
																		<th>3</th>
																		<th>4</th>
																		<th>5</th>
																		<th>6</th>
																		<th>7</th>
																	</tr>
				                                    			</thead>
				                                    			<tbody>
				                                    				<tr>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    			</tbody>
				                                    		</table>
				                                    	</div>
				                                    	<h4 class="card-title">3. CROP / HOUSE DAMAGE</h4>
				                                    	<div class="table-responsive">
				                                    		<table class="table table-sm table-bordered">
				                                    			<thead class="text-center">
				                                    				<tr>
																		<th rowspan="3">Name of wild animal (Specify separately)</th>
																		<th colspan="5">Crop damage/House damage during the month</th>
																		<th colspan="5">Progressive total up to end of the month</th>
																	</tr>
																	<tr>
																		<th colspan="2">Crop damage</th>
																		<th colspan="3">House Damage</th>
																		<th colspan="2">Crop damage</th>
																		<th colspan="3">House Damage</th>
																	</tr>
																	<tr>
																		<th>No of victims</th>
																		<th>Area in Acre</th>
																		<th>Partly</th>
																		<th>Fully</th>
																		<th>Total</th>
																		<th>No of victims</th>
																		<th>Area in Acre</th>
																		<th>Partly</th>
																		<th>Fully</th>
																		<th>Total</th>
																	</tr>
																	<tr>
																		<th>1</th>
																		<th>2</th>
																		<th>3</th>
																		<th>4</th>
																		<th>5</th>
																		<th>6</th>
																		<th>7</th>
																		<th>8</th>
																		<th>9</th>
																		<th>10</th>
																		<th>11</th>
																	</tr>
				                                    			</thead>
				                                    			<tbody>
				                                    				<tr>
				                                    					<td><strong>Elephant</strong></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td><strong>Boar</strong></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td><strong>Bear</strong></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td><strong>Others</strong></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    				<tr>
				                                    					<td><strong>Total</strong></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    					<td><input type="number" class="form-control form-control-sm"></td>
				                                    				</tr>
				                                    			</tbody>
				                                    		</table>
				                                    	</div>
				                                    	<div class="text-center">
				                                    		<button type="submit" class="btn btn-sm btn-success">Submit</button>
				                                    	</div>
				                                    </form>
												</div>
											</div>
					                    </div>

					                    <div id="pane-D" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-D">
						                    <div class="card-header" role="tab" id="heading-D">
						                      <h5 class="mb-0">
						                        <a class="collapsed" data-toggle="collapse" href="#collapse-D" aria-expanded="false" aria-controls="collapse-D">
						                          FORM: D-IV
						                        </a>
						                      </h5>
						                    </div>
						                    <div id="collapse-D" class="collapse" role="tabpanel" data-parent="#accordion" aria-labelledby="heading-D">
						                      <div class="card-body">
						                      	<h4 class="text-right">FORM: D-IV</h4>
			                                    <h4 class="card-title text-center">
			                                    	MPR ON<br>
													WILDLIFE OFFENCE CASES BOOKED<br>
													(The report is confined to present year only i.e. from April to March every year)
			                                    </h4>
			                                    <form [formGroup]="MPR_D4" (ngSubmit)="onSubmit_MPR_D4_form(MPR_D4.value)">
			                                    	<div class="row">
			                                    		<div class="col-md-6">
                                                <div class="row form-group">
                                                  <div class="col-md-6">
                                                    <label class="mt-1">Name of the Circle :</label>
                                                  </div>
                                                  <div class="col-md-6">
                                                    <select class="form-control form-control-sm" formControlName="circle"  (change)="getAllDivisionByCircleId(circleSelect.value)" [(ngModel)]='circleid'    #circleSelect>
                                                      <option value="0">All Circle</option>
                                                      <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                                        {{circle.circleName}}
                                                      </option>
                                                  </select>
                                                  </div>
                                                </div>
                                                <div class="row form-group">
                                                  <div class="col-md-6">
                                                    <label class="mt-1">Name of the Division :</label>
                                                  </div>
                                                  <div class="col-md-6">
                                                      <select class="form-control form-control-sm" formControlName="division"  [(ngModel)]='divid' >
                                                        <option value="0">All Division</option>
                                                        <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                                          {{division.divisionName}}
                                                        </option>
                                                      </select>
                                                  </div>
                                                </div>
			                                    		</div>
			                                    		<div class="col-md-6 align-self-end">
			                                    			<div class="row form-group">
			                                    				<div class="col-md-6 text-right">
			                                    					<label class="mt-1">Month :</label>
			                                    				</div>
			                                    				<div class="col-md-6">
			                                    					<select class="form-control form-control-sm">
			                                    						<option selected="selected">-- Select Month --</option>
			                                    						<option>January</option>
			                                    						<option>February</option>
			                                    						<option>March</option>
			                                    						<option>April</option>
			                                    						<option>May</option>
			                                    						<option>June</option>
			                                    						<option>July</option>
			                                    						<option>August</option>
			                                    						<option>September</option>
			                                    						<option>October</option>
			                                    						<option>November</option>
			                                    						<option>December</option>
			                                    					</select>
			                                    				</div>
			                                    			</div>
			                                    		</div>
			                                    	</div>
			                                    	<h4 class="card-title">Part-I. OFFENCE CASES BOOKED</h4>
			                                    	<div class="table-responsive">
			                                    		<table class="table table-sm table-bordered">
			                                    			<thead class="text-center">
			                                    				<tr>
																	<th rowspan="2">Details of seizure / case booked (Specify the wild animal)</th>
																	<th colspan="3">Cases booked up to last month (Present year only)</th>
																	<th colspan="3">Cases booked during the month</th>
																	<th colspan="3">Progressive total</th>
																	<th colspan="4">Status of disposal (progressive total)</th>
																</tr>
																<tr>
																	<th>No of UD cases</th>
																	<th>No of PR cases</th>
																	<th>No of offenders arrested</th>
																	<th>No of UD cases</th>
																	<th>No of OR cases</th>
																	<th>No of offenders arrested</th>
																	<th>No of UD cases</th>
																	<th>No of OR cases</th>
																	<th>No of offenders arrested</th>
																	<th>No of prosecution report sent to Court</th>
																	<th>No of offenders arrested and forwarded to Court</th>
																	<th>No. of wild animals released</th>
																	<th>Disposal of seized product</th>
																</tr>
																<tr>
																	<th>1</th>
																	<th>2</th>
																	<th>3</th>
																	<th>4</th>
																	<th>5</th>
																	<th>6</th>
																	<th>7</th>
																	<th>8</th>
																	<th>9</th>
																	<th>10</th>
																	<th>11</th>
																	<th>12</th>
																	<th>13</th>
																	<th>14</th>
																</tr>
			                                    			</thead>
			                                    			<tbody>
			                                    				<tr>
			                                    					<td>Wild Animal (live)*</td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td>Wild Animal (dead)*</td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td>Wild life articles*</td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td>Arms and ammunition</td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td>Vehicle / carts / Boat</td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td>Encroachment</td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td>Illegal entry to PA</td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td>Others if any (In detail)</td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    			</tbody>
			                                    		</table>
			                                    	</div>
			                                    	<h4 class="card-title">Part II. STATUS OF SEIZED WILD ANIMAL/TROPHY/OTHER MATERIAL ETC. DURING THE MONTH</h4>
			                                    	<div class="table-responsive">
			                                    		<table class="table table-sm table-bordered">
			                                    			<thead class="text-center">
			                                    				<tr>
																	<th rowspan="2">Name of wild animal*</th>
																	<th colspan="2">No.</th>
																	<th rowspan="2">Details of trophy skin / tusk / derivatives and others</th>
																	<th rowspan="2">Details of arms &  ammunitions</th>
																	<th rowspan="2">Vehicle seized if any</th>
																	<th colspan="2">Disposal of  seized animal</th>
																	<th rowspan="2">Remarks</th>
																</tr>
																<tr>
																	<th>Live</th>
																	<th>Dead</th>
																	<th>Live</th>
																	<th>Dead</th>
																</tr>
																<tr>
																	<th>1</th>
																	<th>2</th>
																	<th>3</th>
																	<th>4</th>
																	<th>5</th>
																	<th>6</th>
																	<th>7</th>
																	<th>8</th>
																	<th>9</th>
																</tr>
			                                    			</thead>
			                                    			<tbody>
			                                    				<tr>
			                                    					<td>Elephant</td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td>Leopard</td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td>Barking Deer</td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td>and others ....</td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    			</tbody>
			                                    		</table>
			                                    	</div>
			                                    	<h4 class="card-title">Part- III.  DETAILS ON IMPORTANT WILDLIFE CASES BOOKED DURING THE MONTH</h4>
			                                    	<div class="table-responsive">
			                                    		<table class="table table-sm table-bordered">
			                                    			<thead class="text-center">
			                                    				<tr>
																	<th>Date of detection</th>
																	<th>Place of detection (District / Division / Range)</th>
																	<th>Wild animal (live & dead), wild animal article, Arms and ammunition, vehicle / cart, Encroachment/ illegal entry / others involved</th>
																	<th>Case No. & Penal Section</th>
																	<th>Brief facts of the case</th>
																	<th>Property recovered (live / dead animal / other material etc. (in detail)</th>
																	<th>Name, age and address of the accused</th>
																	<th>Date of arrest and forwarding to the Court</th>
																	<th>Name of the Court and Court Case No.</th>
																	<th>Present status of the case</th>
																</tr>
																<tr>
																	<th>1</th>
																	<th>2</th>
																	<th>3</th>
																	<th>4</th>
																	<th>5</th>
																	<th>6</th>
																	<th>7</th>
																	<th>8</th>
																	<th>9</th>
																	<th>10</th>
																</tr>
			                                    			</thead>
			                                    			<tbody>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    			</tbody>
			                                    		</table>
			                                    	</div>
			                                    	<p><strong>*Name of wild animal must be mentioned in each case.</strong></p>
			                                    	<div class="text-center">
			                                    		<button type="submit" class="btn btn-sm btn-success">Submit</button>
			                                    	</div>
			                                    </form>
						                      </div>
						                    </div>
					                    </div>

					                    <div id="pane-E" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-E">
						                    <div class="card-header" role="tab" id="heading-E">
						                      <h5 class="mb-0">
						                        <a class="collapsed" data-toggle="collapse" href="#collapse-E" aria-expanded="false" aria-controls="collapse-E">
						                          FORM: D-V
						                        </a>
						                      </h5>
						                    </div>
						                    <div id="collapse-E" class="collapse" role="tabpanel" data-parent="#accordion" aria-labelledby="heading-E">
						                      <div class="card-body">
						                      	<h4 class="text-right">FORM: D-V</h4>
			                                    <h4 class="card-title text-center">
			                                    	MPR ON<br>
													RESCUE AND RELEASE OF WILD LIVES<br>
													(The report is confined to present year only i.e. from April to March every year)
			                                    </h4>
			                                    <form [formGroup]="MPR_D5" (ngSubmit)="onSubmit_MPR_D5_form(MPR_D5.value)">
			                                    	<div class="row">
			                                    		<div class="col-md-6">
                                                <div class="row form-group">
                                                  <div class="col-md-6">
                                                    <label class="mt-1">Name of the Circle :</label>
                                                  </div>
                                                  <div class="col-md-6">
                                                    <select class="form-control form-control-sm" formControlName="circle"  (change)="getAllDivisionByCircleId(circleSelect.value)" [(ngModel)]='circleid'    #circleSelect>
                                                      <option value="0">All Circle</option>
                                                      <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                                        {{circle.circleName}}
                                                      </option>
                                                  </select>
                                                  </div>
                                                </div>
                                                <div class="row form-group">
                                                  <div class="col-md-6">
                                                    <label class="mt-1">Name of the Division :</label>
                                                  </div>
                                                  <div class="col-md-6">
                                                      <select class="form-control form-control-sm" formControlName="division"  [(ngModel)]='divid' >
                                                        <option value="0">All Division</option>
                                                        <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                                          {{division.divisionName}}
                                                        </option>
                                                      </select>
                                                  </div>
                                                </div>
			                                    		</div>
			                                    		<div class="col-md-6 align-self-end">
			                                    			<div class="row form-group">
			                                    				<div class="col-md-6 text-right">
			                                    					<label class="mt-1">Month :</label>
			                                    				</div>
			                                    				<div class="col-md-6">
			                                    					<select class="form-control form-control-sm">
			                                    						<option selected="selected">-- Select Month --</option>
			                                    						<option>January</option>
			                                    						<option>February</option>
			                                    						<option>March</option>
			                                    						<option>April</option>
			                                    						<option>May</option>
			                                    						<option>June</option>
			                                    						<option>July</option>
			                                    						<option>August</option>
			                                    						<option>September</option>
			                                    						<option>October</option>
			                                    						<option>November</option>
			                                    						<option>December</option>
			                                    					</select>
			                                    				</div>
			                                    			</div>
			                                    		</div>
			                                    	</div>
			                                    	<div class="table-responsive">
			                                    		<table class="table table-sm table-bordered">
			                                    			<thead class="text-center">
			                                    				<tr>
																	<th rowspan="3">Name, sex & age of wild animal</th>
																	<th rowspan="3">No. of Wild animals</th>
																	<th rowspan="3">Place of rescue</th>
																	<th rowspan="3">Date of rescue</th>
																	<th colspan="3">Details of release/temporary captivity during the month</th>
																	<th colspan="3" rowspan="2">Progressive total up to end of the month</th>
																	<th rowspan="3">Remarks</th>
																</tr>
																<tr>
																	<th rowspan="2">Date</th>
																	<th colspan="2">Place of</th>
																</tr>
																<tr>
																	<th>Captivity</th>
																	<th>Release</th>
																	<th>Rescue</th>
																	<th>Release</th>
																	<th>Temporary Captivity</th>
																</tr>
																<tr>
																	<th>1</th>
																	<th>2</th>
																	<th>3</th>
																	<th>4</th>
																	<th>5</th>
																	<th>6</th>
																	<th>7</th>
																	<th>8</th>
																	<th>9</th>
																	<th>10</th>
																	<th>11</th>
																</tr>
			                                    			</thead>
			                                    			<tbody>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td colspan="7" class="text-right"><strong>Total:</strong></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    			</tbody>
			                                    		</table>
			                                    	</div>
			                                    	<div class="text-center">
			                                    		<button type="submit" class="btn btn-sm btn-success">Submit</button>
			                                    	</div>
			                                    </form>
						                      </div>
						                    </div>
					                    </div>

					                    <div id="pane-F" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-F">
						                    <div class="card-header" role="tab" id="heading-F">
						                      <h5 class="mb-0">
						                        <a class="collapsed" data-toggle="collapse" href="#collapse-F" aria-expanded="false" aria-controls="collapse-F">
						                          FORM: D-VI
						                        </a>
						                      </h5>
						                    </div>
						                    <div id="collapse-F" class="collapse" role="tabpanel" data-parent="#accordion" aria-labelledby="heading-F">
						                      <div class="card-body">
						                      	<h4 class="text-right">FORM: D-VI</h4>
			                                    <h4 class="card-title text-center">
			                                    	MPR ON<br>
													TIGER AND LEOPARD EVIDENCES
			                                    </h4>
			                                    <form [formGroup]="MPR_D6" (ngSubmit)="onSubmit_MPR_D6_form(MPR_D6.value)">
			                                    	<div class="row">
			                                    		<div class="col-md-6">
                                                <div class="row form-group">
                                                  <div class="col-md-6">
                                                    <label class="mt-1">Name of the Circle :</label>
                                                  </div>
                                                  <div class="col-md-6">
                                                    <select class="form-control form-control-sm" formControlName="circle"  (change)="getAllDivisionByCircleId(circleSelect.value)" [(ngModel)]='circleid'    #circleSelect>
                                                      <option value="0">All Circle</option>
                                                      <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                                        {{circle.circleName}}
                                                      </option>
                                                  </select>
                                                  </div>
                                                </div>
                                                <div class="row form-group">
                                                  <div class="col-md-6">
                                                    <label class="mt-1">Name of the Division :</label>
                                                  </div>
                                                  <div class="col-md-6">
                                                      <select class="form-control form-control-sm" formControlName="division"  [(ngModel)]='divid' >
                                                        <option value="0">All Division</option>
                                                        <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                                          {{division.divisionName}}
                                                        </option>
                                                      </select>
                                                  </div>
                                                </div>
			                                    		</div>
			                                    		<div class="col-md-6 align-self-end">
			                                    			<div class="row form-group">
			                                    				<div class="col-md-6 text-right">
			                                    					<label class="mt-1">Month :</label>
			                                    				</div>
			                                    				<div class="col-md-6">
			                                    					<select class="form-control form-control-sm">
			                                    						<option selected="selected">-- Select Month --</option>
			                                    						<option>January</option>
			                                    						<option>February</option>
			                                    						<option>March</option>
			                                    						<option>April</option>
			                                    						<option>May</option>
			                                    						<option>June</option>
			                                    						<option>July</option>
			                                    						<option>August</option>
			                                    						<option>September</option>
			                                    						<option>October</option>
			                                    						<option>November</option>
			                                    						<option>December</option>
			                                    					</select>
			                                    				</div>
			                                    			</div>
			                                    		</div>
			                                    	</div>
			                                    	<div class="table-responsive">
			                                    		<table class="table table-sm table-bordered">
			                                    			<thead class="text-center">
			                                    				<tr>
																	<th rowspan="3">SL No.</th>
																	<th rowspan="3">Name of Beat / Section / Range / Division</th>
																	<th rowspan="3">Date of Detection</th>
																	<th rowspan="3">Details of the locality (Village, Forest etc.)</th>
																	<th rowspan="3">Carnivore species</th>
																	<th rowspan="3">Sign type</th>
																	<th rowspan="3">Livestock predation number</th>
																	<th colspan="6">GPS</th>
																	<th rowspan="3">Remarks</th>
																</tr>
																<tr>
																	<th colspan="3">N</th>
																	<th colspan="3">E</th>
																</tr>
																<tr>
																	<th>D</th>
																	<th>M</th>
																	<th>S</th>
																	<th>D</th>
																	<th>M</th>
																	<th>S</th>
																</tr>
			                                    			</thead>
			                                    			<tbody>
			                                    				<tr>
			                                    					<td width="5%"><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td width="5%"><input type="number" class="form-control form-control-sm"></td>
			                                    					<td width="5%"><input type="number" class="form-control form-control-sm"></td>
			                                    					<td width="5%"><input type="number" class="form-control form-control-sm"></td>
			                                    					<td width="5%"><input type="number" class="form-control form-control-sm"></td>
			                                    					<td width="5%"><input type="number" class="form-control form-control-sm"></td>
			                                    					<td width="5%"><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    			</tbody>
			                                    		</table>
			                                    	</div>
			                                    	<div class="text-center">
			                                    		<button type="submit" class="btn btn-sm btn-success">Submit</button>
			                                    	</div>
			                                    </form>
						                      </div>
						                    </div>
					                    </div>

					                    <div id="pane-G" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-G">
						                    <div class="card-header" role="tab" id="heading-G">
						                      <h5 class="mb-0">
						                        <a class="collapsed" data-toggle="collapse" href="#collapse-G" aria-expanded="false" aria-controls="collapse-G">
						                          FORM: D-VII
						                        </a>
						                      </h5>
						                    </div>
						                    <div id="collapse-G" class="collapse" role="tabpanel" data-parent="#accordion" aria-labelledby="heading-G">
						                      <div class="card-body">
						                      	<h4 class="text-right">FORM: D-VII</h4>
			                                    <h4 class="card-title text-center">
			                                    	QUARTERLY PROGRESS REPORT ON<br>
													IMPORTANT WILDLIFE CASES PENDING TRIAL IN COURTS
			                                    </h4>
			                                    <form [formGroup]="MPR_D7" (ngSubmit)="onSubmit_MPR_D7_form(MPR_D7.value)">
			                                    	<div class="row">
			                                    		<div class="col-md-6">
                                                <div class="row form-group">
                                                  <div class="col-md-6">
                                                    <label class="mt-1">Name of the Circle :</label>
                                                  </div>
                                                  <div class="col-md-6">
                                                    <select class="form-control form-control-sm" formControlName="circle"  (change)="getAllDivisionByCircleId(circleSelect.value)" [(ngModel)]='circleid'    #circleSelect>
                                                      <option value="0">All Circle</option>
                                                      <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                                        {{circle.circleName}}
                                                      </option>
                                                  </select>
                                                  </div>
                                                </div>
                                                <div class="row form-group">
                                                  <div class="col-md-6">
                                                    <label class="mt-1">Name of the Division :</label>
                                                  </div>
                                                  <div class="col-md-6">
                                                      <select class="form-control form-control-sm" formControlName="division"  [(ngModel)]='divid' >
                                                        <option value="0">All Division</option>
                                                        <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                                          {{division.divisionName}}
                                                        </option>
                                                      </select>
                                                  </div>
                                                </div>
			                                    		</div>
			                                    		<div class="col-md-6 align-self-end">
			                                    			<div class="row form-group">
			                                    				<div class="col-md-6 text-right">
			                                    					<label class="mt-1">Quarter ending :</label>
			                                    				</div>
			                                    				<div class="col-md-6">
			                                    					<input type="text" class="form-control form-control-sm" placeholder="Quarter ending">
			                                    				</div>
			                                    			</div>
			                                    		</div>
			                                    	</div>
			                                    	<div class="table-responsive">
			                                    		<table class="table table-sm table-bordered">
			                                    			<thead class="text-center">
			                                    				<tr>
																	<th>Case No. / Penal Section / Place</th>
																	<th>Investigating Agency</th>
																	<th>Brief Facts of the case</th>
																	<th>Material recovered and Status</th>
																	<th>Name of the accused with age and address</th>
																	<th>Date of filling of complaint in Court </th>
																	<th>Name of the Court and court case No.</th>
																	<th>Present status of the case</th>
																	<th>No. of witness / documents remaining for examination</th>
																	<th>Judgment / Conviction Order</th>
																</tr>
																<tr>
																	<th>1</th>
																	<th>2</th>
																	<th>3</th>
																	<th>4</th>
																	<th>5</th>
																	<th>6</th>
																	<th>7</th>
																	<th>8</th>
																	<th>9</th>
																	<th>10</th>
																</tr>
			                                    			</thead>
			                                    			<tbody>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    			</tbody>
			                                    		</table>
			                                    	</div>
			                                    	<div class="text-center">
			                                    		<button type="submit" class="btn btn-sm btn-success">Submit</button>
			                                    	</div>
			                                    </form>
						                      </div>
						                    </div>
					                    </div>

					                    <div id="pane-H" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-H">
						                    <div class="card-header" role="tab" id="heading-H">
						                      <h5 class="mb-0">
						                        <a class="collapsed" data-toggle="collapse" href="#collapse-H" aria-expanded="false" aria-controls="collapse-H">
						                          FORM: D-VIII
						                        </a>
						                      </h5>
						                    </div>
						                    <div id="collapse-H" class="collapse" role="tabpanel" data-parent="#accordion" aria-labelledby="heading-H">
						                      <div class="card-body">
						                      	<h4 class="text-right">FORM: D-VIII</h4>
			                                    <h4 class="card-title text-center">
			                                    	QUARTERLY PROGRESS REPORT ON<br>
													DEATH/INJURY TO STAFF/ANTIDEPREDATION SQUAD BY WILD ANIMALS
			                                    </h4>
			                                    <form [formGroup]="MPR_D8" (ngSubmit)="onSubmit_MPR_D8_form(MPR_D8.value)">
			                                    	<div class="row">
			                                    		<div class="col-md-6">
                                                <div class="row form-group">
                                                  <div class="col-md-6">
                                                    <label class="mt-1">Name of the Circle :</label>
                                                  </div>
                                                  <div class="col-md-6">
                                                    <select class="form-control form-control-sm" formControlName="circle"  (change)="getAllDivisionByCircleId(circleSelect.value)" [(ngModel)]='circleid'    #circleSelect>
                                                      <option value="0">All Circle</option>
                                                      <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                                        {{circle.circleName}}
                                                      </option>
                                                  </select>
                                                  </div>
                                                </div>
                                                <div class="row form-group">
                                                  <div class="col-md-6">
                                                    <label class="mt-1">Name of the Division :</label>
                                                  </div>
                                                  <div class="col-md-6">
                                                      <select class="form-control form-control-sm" formControlName="division"  [(ngModel)]='divid' >
                                                        <option value="0">All Division</option>
                                                        <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                                          {{division.divisionName}}
                                                        </option>
                                                      </select>
                                                  </div>
                                                </div>
			                                    		</div>
			                                    		<div class="col-md-6 align-self-end">
			                                    			<div class="row form-group">
			                                    				<div class="col-md-6 text-right">
			                                    					<label class="mt-1">Quarter ending :</label>
			                                    				</div>
			                                    				<div class="col-md-6">
			                                    					<input type="text" class="form-control form-control-sm" placeholder="Quarter ending">
			                                    				</div>
			                                    			</div>
			                                    		</div>
			                                    	</div>
			                                    	<div class="table-responsive">
			                                    		<table class="table table-sm table-bordered">
			                                    			<thead class="text-center">
			                                    				<tr>
																	<th rowspan="2">Name of the Range / Section / Beat</th>
																	<th rowspan="2">Name of the wild animal</th>
																	<th rowspan="2">Name of the staff / anti depredation squad including daily wages, killed / injured</th>
																	<th colspan="3">Place / date & time of occurrence</th>
																	<th rowspan="2">Brief description of the incident</th>
																</tr>
																<tr>
																	<th>Place</th>
																	<th>Date</th>
																	<th>Time</th>
																</tr>
																<tr>
																	<th width="13%">1</th>
																	<th width="13%">2</th>
																	<th width="18%">3</th>
																	<th width="13%">4</th>
																	<th width="13%">5</th>
																	<th width="13%">6</th>
																	<th width="17%">7</th>
																</tr>
			                                    			</thead>
			                                    			<tbody>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    			</tbody>
			                                    		</table>
			                                    	</div>
			                                    	<div class="text-center">
			                                    		<button type="submit" class="btn btn-sm btn-success">Submit</button>
			                                    	</div>
			                                    </form>
						                      </div>
						                    </div>
					                    </div>

					                    <div id="pane-I" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-I">
						                    <div class="card-header" role="tab" id="heading-I">
						                      <h5 class="mb-0">
						                        <a class="collapsed" data-toggle="collapse" href="#collapse-I" aria-expanded="false" aria-controls="collapse-I">
						                          FORM: D-IX
						                        </a>
						                      </h5>
						                    </div>
						                    <div id="collapse-I" class="collapse" role="tabpanel" data-parent="#accordion" aria-labelledby="heading-I">
						                      <div class="card-body">
						                      	<h4 class="text-right">FORM: D-IX</h4>
			                                    <h4 class="card-title text-center">
			                                    	QUARTERLY PROGRESS REPORT ON<br>
													PREVENTION OF DEATH OF ELEPHANTS DUE TO ELECTROCUTION
			                                    </h4>
			                                    <form [formGroup]="MPR_D9" (ngSubmit)="onSubmit_MPR_D9_form(MPR_D9.value)">
			                                    	<div class="row">
			                                    		<div class="col-md-6">
                                                <div class="row form-group">
                                                  <div class="col-md-6">
                                                    <label class="mt-1">Name of the Circle :</label>
                                                  </div>
                                                  <div class="col-md-6">
                                                    <select class="form-control form-control-sm" formControlName="circle"  (change)="getAllDivisionByCircleId(circleSelect.value)" [(ngModel)]='circleid'    #circleSelect>
                                                      <option value="0">All Circle</option>
                                                      <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                                        {{circle.circleName}}
                                                      </option>
                                                  </select>
                                                  </div>
                                                </div>
                                                <div class="row form-group">
                                                  <div class="col-md-6">
                                                    <label class="mt-1">Name of the Division :</label>
                                                  </div>
                                                  <div class="col-md-6">
                                                      <select class="form-control form-control-sm" formControlName="division"  [(ngModel)]='divid' >
                                                        <option value="0">All Division</option>
                                                        <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                                          {{division.divisionName}}
                                                        </option>
                                                      </select>

                                                  </div>
                                                </div>
			                                    		</div>
			                                    		<div class="col-md-6 align-self-end">
			                                    			<div class="row form-group">
			                                    				<div class="col-md-6 text-right">
			                                    					<label class="mt-1">Quarter ending :</label>
			                                    				</div>
			                                    				<div class="col-md-6">
			                                    					<input type="text" class="form-control form-control-sm" placeholder="Quarter ending">
			                                    				</div>
			                                    			</div>
			                                    		</div>
			                                    	</div>
			                                    	<div class="table-responsive">
			                                    		<table class="table table-sm table-bordered">
			                                    			<thead class="text-center">
			                                    				<tr>
																	<th>Name of the District</th>
																	<th>Name of the Range</th>
																	<th>Location (RF / PF / Village etc.)</th>
																	<th>Name of KV Line / Other Supply line</th>
																	<th>Jurisdiction of Executive Engineer (Place)</th>
																	<th>Date of observation of threat (sagging) tilting of pole/etc)</th>
																	<th>Type of threat</th>
																	<th>Date on which matter was reported to the SDO/Executive Engineer</th>
																	<th>Status of rectification of the threat reported last month</th>
																	<th>Number of Elephant deaths due to electrocution during the Current month of Reporting</th>
																</tr>
																<tr>
																	<th>1</th>
																	<th>2</th>
																	<th>3</th>
																	<th>4</th>
																	<th>5</th>
																	<th>6</th>
																	<th>7</th>
																	<th>8</th>
																	<th>9</th>
																	<th>10</th>
																</tr>
			                                    			</thead>
			                                    			<tbody>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    			</tbody>
			                                    		</table>
			                                    	</div>
			                                    	<div class="text-center">
			                                    		<button type="submit" class="btn btn-sm btn-success">Submit</button>
			                                    	</div>
			                                    </form>
						                      </div>
						                    </div>
					                    </div>

					                    <div id="pane-J" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-J">
						                    <div class="card-header" role="tab" id="heading-J">
						                      <h5 class="mb-0">
						                        <a class="collapsed" data-toggle="collapse" href="#collapse-J" aria-expanded="false" aria-controls="collapse-J">
						                          FORM: D-X
						                        </a>
						                      </h5>
						                    </div>
						                    <div id="collapse-J" class="collapse" role="tabpanel" data-parent="#accordion" aria-labelledby="heading-J">
						                      <div class="card-body">
						                      	<h4 class="text-right">FORM: D-X</h4>
						                      	<h4 class="card-title text-center">
			                                    	QUARTERLY PROGRESS REPORT ON<br>
													HUMAN BEING KILL BY ELEPHANT AND DEATH OF ELEPHANT
			                                    </h4>
			                                    <form [formGroup]="MPR_D10" (ngSubmit)="onSubmit_MPR_D10_form(MPR_D10.value)">
			                                    	<div class="row">
			                                    		<div class="col-md-6">
                                                <div class="row form-group">
                                                  <div class="col-md-6">
                                                    <label class="mt-1">Name of the Circle :</label>
                                                  </div>
                                                  <div class="col-md-6">
                                                    <select class="form-control form-control-sm" formControlName="circle"  (change)="getAllDivisionByCircleId(circleSelect.value)" [(ngModel)]='circleid'    #circleSelect>
                                                      <option value="0">All Circle</option>
                                                      <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                                                        {{circle.circleName}}
                                                      </option>
                                                  </select>
                                                  </div>
                                                </div>
                                                <div class="row form-group">
                                                  <div class="col-md-6">
                                                    <label class="mt-1">Name of the Division :</label>
                                                  </div>
                                                  <div class="col-md-6">
                                                      <select class="form-control form-control-sm" formControlName="division"  [(ngModel)]='divid' >
                                                        <option value="0">All Division</option>
                                                        <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                                                          {{division.divisionName}}
                                                        </option>
                                                      </select>
                                                  </div>
                                                </div>
			                                    		</div>
			                                    		<div class="col-md-6 align-self-end">
			                                    			<div class="row form-group">
			                                    				<div class="col-md-6 text-right">
			                                    					<label class="mt-1">Quarter ending :</label>
			                                    				</div>
			                                    				<div class="col-md-6">
			                                    					<input type="text" class="form-control form-control-sm" placeholder="Quarter ending">
			                                    				</div>
			                                    			</div>
			                                    		</div>
			                                    	</div>
			                                    	<div class="table-responsive">
			                                    		<table class="table table-sm table-bordered">
			                                    			<thead class="text-center">
			                                    				<tr>
																	<th rowspan="2">Month</th>
																	<th colspan="4">Details of human being killed by elephant</th>
																	<th></th>
																	<th colspan="7">Details of death of elephant</th>
																</tr>
																<tr>
																	<th>SL No.</th>
																	<th>Name, address and age of the victim</th>
																	<th>Date & time of occurrence</th>
																	<th>Place of occurrence</th>
																	<th></th>
																	<th>SL No.</th>
																	<th>Sex</th>
																	<th>Age</th>
																	<th>Place of death</th>
																	<th>Date & time of occurrence</th>
																	<th>Date & time of detection</th>
																	<th>Cause of death</th>
																</tr>
																<tr>
																	<th>1</th>
																	<th width="5%">2</th>
																	<th>3</th>
																	<th>4</th>
																	<th>5</th>
																	<th width="1%"></th>
																	<th width="5%">6</th>
																	<th>7</th>
																	<th>8</th>
																	<th>9</th>
																	<th>10</th>
																	<th>11</th>
																	<th>12</th>
																</tr>
			                                    			</thead>
			                                    			<tbody>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    				<tr>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    					<td><input type="number" class="form-control form-control-sm"></td>
			                                    				</tr>
			                                    			</tbody>
			                                    		</table>
			                                    	</div>
			                                    	<div class="text-center">
			                                    		<button type="submit" class="btn btn-sm btn-success">Submit</button>
			                                    	</div>
			                                    </form>
						                      </div>
						                    </div>
					                    </div>

									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>

	<div id="footer">
		<app-masterfooter></app-masterfooter>
	</div>

</div>
