<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<!-- <div class="loader-base" *ngIf="loader">
	<div class="loader"></div>
</div> -->
<app-masterheader></app-masterheader>

<div class="wrapper">

	<app-sidebar></app-sidebar>

	<div id="content">

		<!-- <app-breadcrumb></app-breadcrumb> -->

		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12">

					<h4 class="card-title">SMS Report <button type="submit" class="btn btn-xs btn-primary pull-right" (click)="backtoauditpage()"><i class="fa fa-chevron-left">&nbsp;</i>Back</button></h4>

					<div class="card">
						<div class="card-body">
							<form [formGroup]="smsqueryForm"
              (ngSubmit)="onSubmit(smsqueryForm.value)">
								<div class="row">
									 <div class="col-md-12">
										<!-- <div class="row" >
											<div class="col-md-2 mb-1">
			                                  	<label>District</label>
			                                  	<select class="form-control">
				                                    <option selected="selected">All District</option>
				                                    <option>11</option>
				                                    <option>11</option>
			                                	</select>
			                                </div>
			                                <div class="col-md-2 mb-1">
			                                  	<label>Block</label>
			                                  	<select class="form-control">
				                                    <option selected="selected">All Block</option>
				                                    <option>11</option>
				                                    <option>11</option>
			                                	</select>
			                                </div>
			                                <div class="col-md-2 mb-1">
			                                  	<label>GP</label>
			                                  	<select class="form-control">
				                                    <option selected="selected">All GP</option>
				                                    <option>11</option>
				                                    <option>11</option>
			                                	</select>
			                                </div>
			                                <div class="col-md-2 mb-1">
			                                  	<label>Village</label>
			                                  	<select class="form-control">
				                                    <option selected="selected">All Village</option>
				                                    <option>11</option>
				                                    <option>11</option>
			                                	</select>
			                                </div>
			                                <div class="col-md-2 mb-1">
			                                  	<label>Start Date</label>
			                                  	<div id="datepicker1" class="input-group date"  data-date-format="dd-mm-yyyy" title="Start Date">
				                                    <input class="form-control" type="text" />
				                                    <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
				                                </div>
			                                </div>
			                                <div class="col-md-2 mb-1">
			                                  	<label>End Date</label>
			                                  	<div id="datepicker2" class="input-group date"  data-date-format="dd-mm-yyyy" title="End Date">
				                                    <input class="form-control" type="text" />
				                                    <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
				                                </div>
			                                </div>
										</div> -->
										<div class="row">
											<div class="col-md-2 mb-1">
                        <label>Circle</label>
                        <select class="form-control" id="circlewl" formControlName="circle" [(ngModel)]='circleid' (change)="getAllDivisionByCircleId(circleSelect.value)"   #circleSelect>
                          <option value="0">All Circle</option>
                          <option    *ngFor="let circle of circleList "   value={{circle.circleId}} >
                            {{circle.circleName}}
                          </option>
                         </select>
                      </div>
                      <div class="col-md-2 mb-1">
                        <label>Division</label>
                        <select class="form-control" id="divisionwl" formControlName="division"  [(ngModel)]='divid'  #divisionSelect>
                          <option value="0">All Division</option>
                          <option    *ngFor="let division of divisionlist "   value={{division.divisionId}} >
                            {{division.divisionName}}
                          </option>
                      </select>
                      </div>
                      <div class="col-md-2 mb-1">
                          <label>Start Date</label>
                          <div id="datepicker1" class="input-group date"  data-date-format="dd-mm-yyyy" title="Start Date">
                            <input class="form-control" type="text" formControlName="startDate" #sd id="startDate" [(ngModel)]="sdate"/>
                            <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
                        </div>
                      </div>
                    <div class="col-md-2 mb-1">
                        <label>End Date</label>
                        <div id="datepicker2" class="input-group date"  data-date-format="dd-mm-yyyy" title="End Date">
                          <input class="form-control" type="text" formControlName="endDate" #ed id="endDate" [(ngModel)]="edate"/>
                          <span class="input-group-addon"><i class="mdi mdi-calendar"></i></span>
                      </div>
                    </div>
                    <div class="col-md-1">
                      <div class="col-md-3">
                        <button type="submit" class="btn btn-sm btn-success custom-btn-top">Submit</button>
                      </div>
									   </div>
										</div>
									</div>

									<!-- <div class="col-md-1">
										<div class="row">
			                                <div class="col-md-3 mb-1">
			                                	<button type="submit" class="btn btn-sm btn-info custom-btn-top" data-toggle="tooltip" title="Swipe"><i class="fa fa-random"></i></button>
			                                </div>
										</div>
									</div> -->
								</div>
								<hr>
								<!-- <div class="row">
									<div class="col-md-12 text-center">
										<button type="submit" class="btn btn-sm btn-success">Submit</button>
									</div>
								</div> -->

							</form>
						</div>
					</div>
				</div>
			</div>

      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <ul id="tabs" class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                  <a id="tab-A" href="#pane-A" class="nav-link active" data-toggle="tab"
                    (click)="loadtable(0)" role="tab">Date Wise SMS Count</a>
                </li>
                <li class="nav-item">
                  <a id="tab-B" href="#pane-B" class="nav-link" data-toggle="tab"
                    (click)="loadtable(1)" role="tab">Division Wise SMS Count</a>
                </li>
                <!-- <li class="nav-item">
                  <a id="tab-D" href="#pane-D" class="nav-link" data-toggle="tab"
                    (click)="loadtable(2)" role="tab">Date Wise SMS Details</a>
                </li>
                <li class="nav-item">
                  <a id="tab-E" href="#pane-E" class="nav-link" data-toggle="tab"
                    (click)="loadtable(3)" role="tab">Division Wise SMS Details</a>
                </li> -->
              </ul>
              <div id="accordion" class="tab-content" role="tablist">
                <div id="pane-A" class="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-A">
                    <div class="card-header" role="tab" id="heading-A">
                        <h5 class="mb-0"><a class="collapsed" data-toggle="collapse" href="#collapse-A"
                            aria-expanded="false" aria-controls="collapse-A">Date Wise SMS Count </a>
                        </h5>
                    </div>
                    <div id="collapse-A" class="collapse" data-parent="#accordion"
                          role="tabpanel" aria-labelledby="heading-A">
                        <div class="card-body">
                            <div class="table-responsive">
                              <table id="dateWisesmsTable" class="table table-bordered display responsive nowrap" width="99%"   datatable [dtOptions]="dtOptions"
                              [dtTrigger]="dtTrigger1">
                                <thead>
                                    <tr>
                                      <th class="all" width="5%">Sl. No.</th>
                                      <th class="all">Report Date</th>
                                      <th class="all" >SMS Delivered</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let element1 of tabledata1 ; let i = index;">
                                  <td>{{i+1}}</td>
                                  <td>{{ (element1.reportedDate  !== 'null') ? (element1.reportedDate | date:'dd-MM-yyyy')  : '' }} </td>
                                  <td>{{element1.smsdeliverd}}</td>
                                  </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="pane-B" class="card tab-pane fade" role="tabpanel" aria-labelledby="tab-B">
                  <div class="card-header" role="tab" id="heading-B">
                    <h5 class="mb-0"><a class="collapsed" data-toggle="collapse" href="#collapse-B"
                        aria-expanded="false" aria-controls="collapse-B">Date Wise SMS Count </a>
                    </h5>
                </div>
                <div id="collapse-B" class="collapse" data-parent="#accordion"
                      role="tabpanel" aria-labelledby="heading-B">
                    <div class="card-body">
                        <div class="table-responsive">
                          <table id="divisionWisesmsTable" class="table table-bordered display responsive nowrap" width="99%"  datatable [dtOptions]="dtOptions"
                          [dtTrigger]="dtTrigger2">
                            <thead>
                                <tr>
                                  <th class="all">Sl. No.</th>
                                  <th class="all" >Circle</th>
                                  <th class="all" >Division</th>
                                  <th class="all" >Report Type</th>
                                  <th class="all" >Report Date</th>
                                  <th class="all" >SMS Delivered</th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let element2 of tabledata2 ; let i = index;">
                              <td>{{i+1}}</td>
                              <td>{{element2.circle}}</td>
                              <td>{{element2.division}}</td>
                              <td>{{element2.reportType}}</td>
                              <td>{{ (element2.reportedDate  !== 'null') ? (element2.reportedDate | date:'dd-MM-yyyy')  : '' }} </td>
                              <td>{{element2.smsdeliverd}}</td>
                              </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
                </div>

                <div id="pane-D" class="card tab-pane fade" role="tabpanel"
                    aria-labelledby="tab-D">
                    <div class="card-header" role="tab" id="heading-D">
                      <h5 class="mb-0">
                        <a class="collapsed" data-toggle="collapse" href="#collapse-D"
                          aria-expanded="false" aria-controls="collapse-D">
                        Date Wise SMS Details
                        </a>
                      </h5>
                    </div>
                    <div id="collapse-D" class="collapse" role="tabpanel"
                      data-parent="#accordion" aria-labelledby="heading-D">
                      <div class="card-body">
                        Hati Hagidela
                      </div>
                    </div>
                  </div>

                  <div id="pane-E" class="card tab-pane fade" role="tabpanel"
                    aria-labelledby="tab-E">
                    <div class="card-header" role="tab" id="heading-E">
                      <h5 class="mb-0">
                        <a class="collapsed" data-toggle="collapse" href="#collapse-E"
                          aria-expanded="false" aria-controls="collapse-E">
                        Division Wise SMS Details
                        </a>
                      </h5>
                    </div>
                    <div id="collapse-E" class="collapse" role="tabpanel"
                      data-parent="#accordion" aria-labelledby="heading-E">
                      <div class="card-body">
                        Hati Tekidela
                      </div>
                    </div>
                  </div>

              </div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>

	<div id="footer">
		<app-masterfooter></app-masterfooter>
	</div>

</div>
