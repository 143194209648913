<!-- <div class="loader-base" *ngIf="loader">
  <div class="loader"></div>
</div> -->
<app-masterheader></app-masterheader>

<div class="wrapper">

  <app-sidebar></app-sidebar>

  <div id="content">

    <!-- <app-breadcrumb></app-breadcrumb> -->

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Authority User</h4>
                  <form>
                    <div class="row form-group">
                      <div class="col-md-3">
                          <label for="">Circle <span class="text-danger">*</span></label>
                          <select class="form-control">
                          	<option selected="selected">-- Select --</option>
                          	<option>Angul</option>
                          	<option>Baripada</option>
                            <option>Berhampur</option>
                            <option>Bhawanipatna</option>
                            <option>Bhubaneswar</option>
                            <option>Koraput</option>
                            <option>Nandankanan</option>
                            <option>Rourkela</option>
                            <option>Sambalpur</option>
                          </select>
                      </div>
                      <div class="col-md-3">
                          <label for="">Division <span class="text-danger">*</span></label>
                          <select class="form-control">
                          	<option selected="selected">-- Select --</option>
                          	<option>Angul</option>
                          	<option>Athagarh</option>
                            <option>Athamallik</option>
                            <option>Cuttack</option>
                            <option>Mahanadi WL</option>
                            <option>Satkosia WL</option>
                            <option>Dhenkanal</option>
                          </select>
                      </div>
                      <div class="col-md-3">
                          <label for="">Range <span class="text-danger">*</span></label>
                          <select id="multiselectRange" multiple="multiple">
                              <option value="angul">Angul</option>
                              <option value="bantala">Bantala</option>
                              <option value="chhendipada">Chhendipada</option>
                              <option value="jarpada">Jarpada</option>
                              <option value="kaniha">Kaniha</option>
                              <option value="talcher">Talcher</option>
                          </select>
                      </div>
                      <div class="col-md-3">
                          <label for="">Section <span class="text-danger">*</span></label>
                          <select id="multiselectSection" multiple="multiple">
                              <option value="angultownsection">Angul Town Section</option>
                              <option value="karatpatta">Karatpatta</option>
                              <option value="pokunda">Pokunda</option>
                              <option value="purnagarh">Purnagarh</option>
                          </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                          <label for="">Beat <span class="text-danger">*</span></label>
                          <select id="multiselectBeat" multiple="multiple">
                              <option value="angultown">Angul Town</option>
                              <option value="chandrabahal">Chandrabahal</option>
                              <option value="kuio">Kuio</option>
                              <option value="nalco">NALCO</option>
                          </select>
                      </div>
                      <div class="col-md-3">
                          <label for="">Name <span class="text-danger">*</span></label>
                          <input type="text" class="form-control" placeholder="Name">
                      </div>
                      <div class="col-md-3">
                          <label for="">Authority <span class="text-danger">*</span></label>
                          <select class="form-control">
                              <option selected="selected">-- Select --</option>
                              <option>Authority 1</option>
                              <option>Authority 2</option>
                          </select>
                      </div>
                      <div class="col-md-3">
                          <label for="">Mobile No <span class="text-danger">*</span></label>
                          <input type="number" class="form-control" placeholder="Mobile No.">
                      </div>                      
                  	</div>
                    <hr>
                    <div class="row">
                      <div class="col-md-12 text-center">
                        <button type="submit" class="btn btn-sm btn-success">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">

          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">

                  <form class="card-title">
                    <div class="row">
                      <div class="col-md-1 mt-2">
                        <label>Filter</label>
                      </div>
                      <div class="col-md-2">
                        <select class="form-control">
                          <option selected="selected">-- Select Authority --</option>
                          <option>Authority 1</option>
                          <option>Authority 2</option>
                        </select>
                      </div>
                    </div>
                  </form>

                  <div class="table-responsive loader-sm">
                    <table id="authorityTable" class="table table-bordered row-border hover" datatable width="99%">
                      <thead>
                        <tr>
                          <th width="6%">Sl. No.</th>
                          <th>Name</th>
                          <th>Authority</th>
                          <th>Mobile No.</th>
                          <th>Circle</th>
                          <th>Division</th>
                          <th>Range</th>
                          <th>Section</th>
                          <th>Beat</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Tarini Prasad</td>
                          <td>Authority 1</td>
                          <td>9853163546</td>
                          <td>Angul</td>
                          <td>Athagarh</td>
                          <td>Angul</td>
                          <td>Karatpatta</td>
                          <td>Chandrabahal</td>
                          <td>
                            <label class="switch">
                                <input type="checkbox" checked>
                                <span class="slider round"></span>
                                <span class="absolute-no">Active</span>
                            </label>
                          </td>
                          <td>
                            <button data-toggle="modal" data-target="#editAuthorityUserModal" class="btn btn-sm btn-info mr-1"><i class="fa fa-edit"></i></button>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Bishal Swain</td>
                          <td>Authority 2</td>
                          <td>7008541266</td>
                          <td>Angul</td>
                          <td>Athamallik</td>
                          <td>Chhendipada</td>
                          <td>Purnagarh</td>
                          <td>NALCO</td>
                          <td>
                            <label class="switch">
                                <input type="checkbox">
                                <span class="slider round"></span>
                                <span class="absolute-no">Inactive</span>
                            </label>
                          </td>
                          <td>
                            <button data-toggle="modal" data-target="#editAuthorityUserModal" class="btn btn-sm btn-info mr-1"><i class="fa fa-edit"></i></button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="modal fade" id="editAuthorityUserModal" tabindex="-1" role="dialog"
      aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLongTitle">Edit Authority User</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mb-0">
            <form>
              <div class="row form-group">
                <div class="col-md-6">
                    <label for="">Circle <span class="text-danger">*</span></label>
                    <select class="form-control">
                      <option selected="selected">-- Select --</option>
                      <option>Angul</option>
                      <option>Baripada</option>
                      <option>Berhampur</option>
                      <option>Bhawanipatna</option>
                      <option>Bhubaneswar</option>
                      <option>Koraput</option>
                      <option>Nandankanan</option>
                      <option>Rourkela</option>
                      <option>Sambalpur</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="">Division <span class="text-danger">*</span></label>
                    <select class="form-control">
                      <option selected="selected">-- Select --</option>
                      <option>Angul</option>
                      <option>Athagarh</option>
                      <option>Athamallik</option>
                      <option>Cuttack</option>
                      <option>Mahanadi WL</option>
                      <option>Satkosia WL</option>
                      <option>Dhenkanal</option>
                    </select>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-6">
                    <label for="">Range <span class="text-danger">*</span></label>
                    <select id="multiselectRange" multiple="multiple">
                        <option value="angul">Angul</option>
                        <option value="bantala">Bantala</option>
                        <option value="chhendipada">Chhendipada</option>
                        <option value="jarpada">Jarpada</option>
                        <option value="kaniha">Kaniha</option>
                        <option value="talcher">Talcher</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="">Section <span class="text-danger">*</span></label>
                    <select id="multiselectSection" multiple="multiple">
                        <option value="angultownsection">Angul Town Section</option>
                        <option value="karatpatta">Karatpatta</option>
                        <option value="pokunda">Pokunda</option>
                        <option value="purnagarh">Purnagarh</option>
                    </select>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-6">
                    <label for="">Beat <span class="text-danger">*</span></label>
                    <select id="multiselectBeat" multiple="multiple">
                        <option value="angultown">Angul Town</option>
                        <option value="chandrabahal">Chandrabahal</option>
                        <option value="kuio">Kuio</option>
                        <option value="nalco">NALCO</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="">Name <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" placeholder="Name">
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                    <label for="">Authority <span class="text-danger">*</span></label>
                    <select class="form-control">
                        <option selected="selected">-- Select --</option>
                        <option>Authority 1</option>
                        <option>Authority 2</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="">Mobile No <span class="text-danger">*</span></label>
                    <input type="number" class="form-control" placeholder="Mobile No.">
                </div>                      
              </div>
              <hr>
              <div class="row">
                <div class="col-md-12 text-center">
                  <button type="submit" class="btn btn-sm btn-success">Update</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div id="footer">
    <app-masterfooter></app-masterfooter>
  </div>

</div>
