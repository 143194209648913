import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import ImageWMS from 'ol/source/ImageWMS';
import { View } from 'ol';
import * as ol from 'ol';
import Map from 'ol/Map';
import * as proj from 'ol/proj';
import { Tile as Tile, Vector as VectorLayer, Image as ImageLayer } from 'ol/layer';
import { OSM as OSM, Vector as VectorSource, Vector as MeasureSource } from 'ol/source';
import { defaults as defaultInteractions, Modify, Select } from 'ol/interaction';
import * as control from 'ol/control';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import { WFS, GeoJSON } from 'ol/format';
import { writeFilter } from 'ol/format/WFS';
import * as layer from 'ol/layer';
import { ApiService } from 'src/app/service/api.service';
import { NotificationService } from 'src/app/service/notification.service';

import 'ol/ol.css';
import Feature from 'ol/Feature';
import { Icon, Style, Fill, Stroke,Text } from 'ol/style';
import Point from 'ol/geom/Point';

@Component({
  selector: 'app-wildlifeunitsbhadrak',
  templateUrl: './wildlifeunitsbhadrak.component.html',
  styleUrls: ['./wildlifeunitsbhadrak.component.css']
})
export class WildlifeunitsbhadrakComponent implements OnInit {

  isDashboard: boolean = false;
  public loader: boolean=false;
  map: any;
  paneA: HTMLDivElement;
  paneB: HTMLDivElement;
  CollapseA: HTMLDivElement;
  CollapseB: HTMLDivElement;
  tabA: HTMLAnchorElement;
  tabB: HTMLAnchorElement;

  // background maps
  imegary: layer.Image;
  streetmap: layer.Image;
  osmMap: layer.Image;

  constructor(private router: Router,private apiService: ApiService,private notifyService:NotificationService) {
    // on route change to '/login', set the variable showHead to false
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event['url'] == '/dashboarduser') {
          this.isDashboard = false;
        } else {
          // //console.log("NU")
          this.isDashboard = true;
        }
      }
    });
  }

  ngOnInit(): void {

    (function ($) {

      $(document).ready(function () {
        // Gets the video src from the data-src on each button
        var $videoSrc;
        $('.video-btn').click(function () {
          $videoSrc = $(this).data("src");
        });
        //console.log($videoSrc);

        // when the modal is opened autoplay it
        $('#myModal').on('shown.bs.modal', function (e) {

          // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
          $("#video").attr('src', $videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");
        })

        // stop playing the youtube video when I close the modal
        $('#myModal').on('hide.bs.modal', function (e) {
          // a poor man's stop video
          $("#video").attr('src', $videoSrc);
        })
        // document ready
      });

    })(jQuery);

    //let layername_div = "iwlms:division_boundary";
    let layername_div='https://odishaforestgis.in/ofms/index.php/webservice_api/getDivisionGeojson';
    let layername_circle = "iwlms:circle_boundary";
    // let querydiv = "division_id='10'";
    let querydiv = "{\"division_id\" : \""+10+"\"}";
    let querycircle = "circle_id='8'";
    this.setTheMap(layername_circle,layername_div,querydiv,querycircle);
  }
  setinfoonly() {
    this.paneA = document.getElementById("pane-bhadrakmap") as HTMLDivElement;
    this.paneB = document.getElementById("pane-bhadrakinfo") as HTMLDivElement;
    this.CollapseA = document.getElementById("collapse-bhadrakmap") as HTMLDivElement;
    this.CollapseB = document.getElementById("collapse-bhadrakinfo") as HTMLDivElement;
    this.tabA = document.getElementById("tab-bhadrakmap") as HTMLAnchorElement;
    this.tabB = document.getElementById("tab-bhadrakinfo") as HTMLAnchorElement;
    this.tabA.style.display = "none";
    this.paneA.style.display = "none";
    this.CollapseA.style.display = "none";
    this.tabB.classList.add('active');
    this.tabB.innerHTML = "";
    this.paneB.classList.add('show');
    this.paneB.classList.add('active');
    this.CollapseB.classList.add('show');
  }
  setTheMap(layername_circle, layername_div, querydiv, querycircle)
  {
    var imegary = new Tile({
      visible: false,
      source: new OSM({
        url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
      })
    });

    var streerMap = new Tile({
      visible: true,
      source: new OSM({
        url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      })
    });
    var raster = new Tile({
      type: 'base',
      visible: false,
      source: new OSM()
    });
    this.imegary = imegary;
    this.streetmap = streerMap;
    this.osmMap = raster;

    var _StateBoundary = new layer.Image({
      source: new ImageWMS({
        url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wms',
        params: {
          'FORMAT': 'image/png',
          'VERSION': '1.1.0',
          STYLES: '',
          LAYERS: 'iwlms:odisha_state_boundary'
        },
        serverType: 'geoserver',
        crossOrigin: 'anonymous'
      }),
      visible: true
    });
    this.map = new Map({
      layers: [imegary, streerMap, raster, _StateBoundary],
      controls: [
        new control.Zoom(),
        new control.ZoomSlider(),
        new control.ScaleLine
          ({
            units: 'metric',
            bar: true,
            steps: 4,
            text: true,
            minWidth: 140
          })
      ],
      overlays: [],
      target: 'bhadrakWLmap',
      view:
        new View({
          center: proj.fromLonLat([85.190055, 20.886785]),
          zoom: 7,
          maxZoom: 23,
          minZoom: 5,
        })
    });
    if (querycircle != "") {
      var vectorparcel = new VectorSource({
        url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wfs?service=WFS&version=1.1.0&request=GetFeature&typeName=' + layername_circle + '&maxFeatures=2000&outputFormat=application%2Fjson' + "&CQL_FILTER=" + querycircle,
        format: new GeoJSON(),
        wrapX: false,
      });
      var styles = [
        new Style({
          stroke: new Stroke({
            color: 'rgba(32, 5, 132, 2)',
            width: 2,
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
          }),
        })];
      var vectorparcelextend = new VectorLayer({
        source: vectorparcel,
        style: styles
      });
      // this.map.addLayer(vectorparcelextend);
      // vectorparcelextend.setVisible(true);
      // vectorparcelextend.getSource().on('addfeature', (e) => {
      //   this.map.getView().fit(vectorparcelextend.getSource().getExtent(), { "maxZoom": 8 });
      // });
    }
    // if (querydiv != "") {
    //   var vectorparcel = new VectorSource({
    //     url: 'https://mapserver.odisha4kgeo.in/geoserver/iwlms/wfs?service=WFS&version=1.1.0&request=GetFeature&typeName=' + layername_div + '&maxFeatures=2000&outputFormat=application%2Fjson' + "&CQL_FILTER=" + querydiv,
    //     format: new GeoJSON(),
    //     wrapX: false,
    //   });
    //   var styles = [
    //     new Style({
    //       stroke: new Stroke({
    //         color: 'rgba(31, 28, 28, 2)',
    //         width: 2,
    //       }),
    //       fill: new Fill({
    //         color: 'rgba(32, 28, 28, 0.1)',
    //       }),
    //     })];
    //   var vectorparcelextend = new VectorLayer({
    //     source: vectorparcel,
    //     style: styles
    //   });
    //   this.map.addLayer(vectorparcelextend);
    //   vectorparcelextend.setVisible(true);
    //   vectorparcelextend.getSource().on('addfeature', (e) => {
    //     this.map.getView().fit(vectorparcelextend.getSource().getExtent(), { "maxZoom": 8 });
    //   });
    // }
    if (querydiv != "")
      {
        let formdata:FormData = new FormData();
        formdata.append('data',querydiv);
        try
        {
          this.apiService.requestGeoJson(layername_div,formdata).subscribe((response: any[]) => {
            if (response) {
                if(response["status"]==1){

                 var geojson = '{"type": "Feature","properties": { },"geometry":' + response["post"][0].st_asgeojson + '}';
                const vectorSource = new VectorSource();
                var styles =
                  new Style({
                    stroke: new Stroke({
                      color: 'rgba(31, 28, 28, 2)',
                      width: 3,
                      lineDash: [.1, 5] //or other combinations
                    }),
                    zIndex: 2,
                    fill: new Fill({
                      color: 'rgba(32, 28, 28, 0.1)',
                    }),
                    text: new Text({
                      font: 'bold 17px Calibri,sans-serif',
                      fill: new Fill({
                          color: '#eb7734'
                       })
                    })
                  });
                  var gjFormat = new GeoJSON({
                    featureProjection: 'EPSG:3857',
                });
                var features = gjFormat.readFeatures(geojson);
                vectorSource.clear();
                var featureOverlayNew = new VectorLayer({
                    map: this.map,
                    source: vectorSource,
                    style: function() {
                      styles.getText().setText(response["post"][0].name_e+" Division");
                      return styles;
                    },
                    updateWhileAnimating: true,
                    updateWhileInteracting: true
                });
                featureOverlayNew.getSource().addFeatures(features);
                this.map.getView().setZoom(7);
                this.map.getView().fit(featureOverlayNew.getSource().getExtent(), "");
              }else{
                this.notifyService.showError("Map Data Not Available","Error");
                this.loader=false
              }
             }else{
              this.notifyService.showError("Map Data Not Available","Error");
                this.loader=false
             }
          },(error) => {
            const errors = error;
            this.notifyService.showError("Map Data Not Available","Error");
            this.loader=false
          });
        }catch(e){
        this.notifyService.showError("Something Error!!","Error");
        this.loader=false
        }finally{
          this.loader=false
        }
      }
  }
  streetchange() {
    this.streetmap.setVisible(true);
    this.imegary.setVisible(false);
    this.osmMap.setVisible(false);
  }
  stelitechange() {
    this.streetmap.setVisible(false);
    this.imegary.setVisible(true);
    this.osmMap.setVisible(false);
  }
  topochange() {
    this.streetmap.setVisible(false);
    this.imegary.setVisible(false);
    this.osmMap.setVisible(true);
  }

  downloadKmlFile() {
    saveAs('../assets/divisiongeopdf/BHADRAK_WL.pdf', "BHADRAK_WL");
  }
  print() {
    const printContent = document.getElementById("printcontent");
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }
}
