import { Component, OnInit, ViewChild } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import {Router} from "@angular/router";
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../service/auth.service";
import {ApiService} from "../service/api.service";
import { SidebarComponent } from '../sidebar/sidebar.component';
import { NotificationService } from '../service/notification.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var jQuery: any;

@Component({
  selector: 'app-viewrolemanagement',
  templateUrl: './viewrolemanagement.component.html',
  styleUrls: ['./viewrolemanagement.component.css']
})
export class ViewrolemanagementComponent implements OnInit {
  //public loader: boolean=false;
  public tabledata:any[];
  public roles:string;
  public GlinkList: Array<any>;
  roleid: any;
  rolelist: any;
  setpermissionarray: Array<any> = [];
  rpermissioncheck: Array<any> = [];
  formArray: FormArray = new FormArray([]);
  PermissionRequest: any[];
  roleId: string;
  constructor(private formBuilder: FormBuilder,private notifyService:NotificationService, private router: Router, private apiService: ApiService,private authService: AuthService) { }
  @ViewChild(SidebarComponent) _sidebarEleRef: SidebarComponent;
  ngAfterViewInit() {
    this._sidebarEleRef.contains='2';
  }
  dtOptions: any = {};

  ngOnInit(): void {
    //this.loader=true;//set loader true for visible
    this.roleid="0";
  	this.dtOptions = {
      responsive: true,
      pagingType: 'full_numbers',
      pageLength: 5,
      processing: true,
      dom: 'Bfrtip',
        buttons: [
            //'copy', 'excel', 'pdf', 'print'
        ]
    };

  	(function($){

    })(jQuery);
   // this.rolePermissionTable();

   this.getAllRole();
   //this.loader=false;//set loader true for invisible
  }
  submit() {
    //this.loader=true;
    this.apiService.request('POST','api/v1/link/setGLinkToRole',this.PermissionRequest).subscribe((message : any) =>{
      //   console.log(message.response)
           if(message.response=="permission added successfully"){
              this.notifyService.showSuccess("Permission Updated successfully","Success");
              //this.loader=false;
            }else{
              this.notifyService.showError("Unsuccessfull","Error");
              //this.loader=false;
            }
    });
  }
  getAllRole() {
    return this.apiService.request('GET', 'api/v1/link/getAllRole').subscribe((data: any) => {
      if (data) {
        this.rolelist = data;
      }
    });

  }
   rolePermissionTable(roleId: string){
    //this.loader=true;
    this.roleId=roleId;
    this.apiService.request('GET', 'api/v1/link/getAllGlinkByRoleId?roleId='+roleId).subscribe((data: Array<any>) => {
      if (data) {
            console.warn(data);
            //this.GlinkList = data;
            this.GlinkList = new Array() ;

          data.map(item=>{
            return {
              glink_id : item['glink_id'],
              globalSeq: item['globalSeq'],
              isActive: item['isActive'] == null ? (this.checkplink(item['pLInk']) == true ? true : false) : this.convertBoolean(item['isActive']),
              linkName: item['linkName'],
              name: item['name'],
              pLInk:item['pLInk'] == null ? [] : item['pLInk']
            }
          }).forEach(item=> this.GlinkList.push(item));
          //console.log(this.GlinkList)
          this.setPermissionRequest(data,roleId);
          //this.loader=false;
        }
      });
   }
   setPermissionRequest(data:any,roleId:any){
      this.PermissionRequest=new Array();
      var i;
      for (i = 0; i < data.length; i++) {
        if(data[i]['pLInk'] == null){
          var routePath={
            roleID:roleId,
            gLinkId:String(data[i]['glink_id']),
            pLinkId:"",
            isActive:this.convertBoolean(data[i]['isActive'])
          }
          this.PermissionRequest.push(routePath);
        }else{
          data[i]['pLInk'].forEach((o, j) => {
            var routePath={
                roleID:roleId,
                gLinkId:String(data[i]['glink_id']),
                pLinkId:String(data[i]['pLInk'][j]['id']),
                isActive:this.convertBoolean(data[i]['pLInk'][j]['isActive'])
            }
            this.PermissionRequest.push(routePath);
          });
        }
      }
   }
   convertBoolean(myValue:any){
       if(myValue=="true"){
         myValue=true;
       }else if(myValue=="false"){
        myValue=false;
      }
      return myValue;
   }
   checkplink(item : Array<any>)
   {
     var returnstmnt ;
     var count = 0;

    for (let index = 0; index < item.length; index++) {
      const element = item[index];
      // console.error("look here")
      // console.warn (element.isActive)
      // console.warn (element.isActive == "true")

      if(element.isActive == "true")
      {
        count = count+ 1 ;
      }
    }
     if(count > 0 )
     {
       returnstmnt = true;
     }
     else
     {
       returnstmnt = false;
     }
    return returnstmnt ;
   }
   setPermission(event: any,glink_id:any,plinkid:any) {
      this.apiService.request('PUT','api/v1/link/setLinkToRole',{
        roleID: this.roleId,
        gLinkId:String(glink_id),
        pLinkId : String(plinkid),
        isActive: event.target.checked
      } ).subscribe((message : any) =>{
             if(message=="added"){
                this.notifyService.showSuccess("Permission Added successfully","Success");
                //this.loader=false;
              }else if(message=="remove"){
                this.notifyService.showSuccess("Permission Remove successfully","Success");
                //this.loader=false;
              }else{
                this.notifyService.showError("Unsuccessfull","Error");
                //this.loader=false;
              }
      });
  }
   pushthevalue(event: any,glink_id:any,plinkid:any)
   {
    const elementsIndex = this.PermissionRequest.findIndex(element => element.gLinkId == glink_id &&  element.pLinkId == plinkid &&  element.roleID == this.roleId)
    this.PermissionRequest[elementsIndex].isActive=event.target.checked;
   }
   pushthevalueGlink(event: any,glink_id:any)
   {
    const elementsIndex = this.PermissionRequest.findIndex(element => element.gLinkId == glink_id  &&  element.roleID == this.roleId)
    this.PermissionRequest[elementsIndex].isActive=event.target.checked;
   }
  // rolePermissionTable(roleId: string){
  //   this.apiService.request('GET', 'api/v1/link/getAllGlinkByRoleId?roleId='+roleId).subscribe((data: any) => {
  //     if (data) {
  //       console.warn(data);
  //        this.GlinkList = data;

  //        //getAllGlink
  //       // var apilink = 'api/v1/link/getAllLinkByRoleID?roleId='+roleId;
  //       // this.apiService.request('GET',apilink).subscribe(
  //       //   returndata=> {
  //       //    // console.log(returndata);
  //       // for(let i = 0; i < data.length; i++){
  //       //   var rdata = data[i]["pLInk"];
  //       //   console.error(rdata);
  //       // }
  //              var rdata = data["pLInk"];
  //          for (let index = 0; index < rdata.length; index++) {
  //            this.rpermissioncheck.push(rdata[index].id);
  //            this.setpermissionarray.push(rdata[index].id);
  //          }
  //         }
  //       );


  //   this.apiService.request('GET', 'api/v1/link/getAllRole').subscribe((data: any) => {
  //   if(data){
  //    // this.tabledata=data;
  //      // console.log(data[0]);
  //     // for(var i=0;i<data.length;i++) {
  //     //   for(var j=0;j<data[i]['link'].length;j++){
  //     //     console.log(data[i]['link'][j]['name']);
  //     //   }
  //     // }
  //     }
  //   });
  // }

}
