import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { AuthService } from '../service/auth.service';
import { NotificationService } from '../service/notification.service';
declare var jQuery: any;

@Component({
  selector: 'app-aporeport',
  templateUrl: './aporeport.component.html',
  styleUrls: ['./aporeport.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush // 🚀 Optimized Change Detection
})
export class AporeportComponent implements OnInit {


  apoForm!:FormGroup;

  fieldsByComponent: { [key: string]: any[] } = {
    "5": [
      { name: 'item', label: 'Item of Expenditure', editable: false },
      { name: 'para', label: 'Para No. of the Management Plan which specializes this item', editable: true, type: 'number' },
      { name: 'quantity', label: 'Quantity', editable: true, type: 'number' },
      { name: 'unitCost', label: 'Unit Cost', editable: true, type: 'number' },
      { name: 'amountRequired', label: 'Amount Required', editable: true, type: 'number' },
      { name: 'location', label: 'Location', editable: true, type: 'text' },
      { name: 'gpsDD1', label: 'GPS DD', editable: true, type: 'number' },
      { name: 'gpsMM1', label: 'GPS MM', editable: true, type: 'number' },
      { name: 'gpsSS1', label: 'GPS SS', editable: true, type: 'number' },
      { name: 'gpsDD2', label: 'GPS DD', editable: true, type: 'number' },
      { name: 'gpsMM2', label: 'GPS MM', editable: true, type: 'number' },
      { name: 'gpsSS2', label: 'GPS SS', editable: true, type: 'number' },
      { name: 'site', label: 'Site specific Justification', editable: true, type: 'text' },
    ],
    "2": [
      { name: 'item', label: 'Item of Expenditure', editable: false },
      { name: 'distance', label: 'Distance (Km)', editable: true, type: 'number' },
      { name: 'location', label: 'Location', editable: true, type: 'text' },
      { name: 'gpsCoordinates', label: 'GPS Coordinates', editable: true, type: 'text' },
      { name: 'amount', label: 'Amount', editable: true, type: 'number' }
    ],
    "3": [
      { name: 'item', label: 'Item of Expenditure', editable: false },
      { name: 'task', label: 'Task', editable: true, type: 'text' },
      { name: 'subTask', label: 'Sub-Task', editable: true, type: 'text' },
      { name: 'cost', label: 'Total Cost', editable: true, type: 'number' },
      { name: 'justification', label: 'Justification', editable: true, type: 'text' }
    ]
  };
  // Dynamic items mapped to components
  itemsByComponent: any = {
    "1": [{id:1,name:"Habitat Management"}, {id:2,name:"Water Conservation"}, {id:3,name:"Vegetation Improvement"}],
    "2": [{id:4,name:"Protection & Enforcement"},  {id:5,name:"Anti-Poaching Measures"}],
    "3": [{id:6,name:"Eco-Tourism Development"}, {id:7,name:"Awareness & Education"}, {id:8,name:"Community Involvement"}]
  };
 
  tasksByItem: any = {
    "1": [{id:1,name:"Task 1 - Planting"}, {id:2,name:"Task 2 - Soil Improvement"}],
    "2": [{id:3,name:"Task 1 - Pond Creation"}, {id:4,name:"Task 2 - Rainwater Harvesting"}],
    "3": [{id:5,name:"Task 1 - Plantation"}, {id:6,name:"Task 2 - Grassland Management"}],
    "4": [{id:7,name:"Task 1 - Patrolling"}, {id:8,name:"Task 2 - Surveillance"}],
    "5": [{id:9,name:"Task 1 - Fencing"}, {id:10,name:"Task 2 - Community Watch"}],
    "6": [{id:11,name:"Task 1 - Nature Trails"}, {id:12,name:"Task 2 - Viewpoints"}],
    "7": [{id:13,name:"Task 1 - Workshops"}, {id:14,name:"Task 2 - School Visits"}],
    "8": [{id:15,name:"Task 1 - Skill Development"}, {id:16,name:"Task 2 - Livelihood Programs"}]
  };
  subTasksByTask: any = {
    "1": [{id:1,name:"Sub-Task 1 - Seed Collection"}, {id:2,name:"Sub-Task 2 - Plantation Execution"}],
    "2": [{id:3,name:"Sub-Task 1 - Soil Testing"}, {id:4,name:"Sub-Task 2 - Fertilization"}],
    "3": [{id:5,name:"Sub-Task 1 - Site Selection"}, {id:6,name:"Sub-Task 2 - Excavation"}],
    "4": [{id:7,name:"Sub-Task 1 - Tank Construction"}, {id:8,name: "Sub-Task 2 - Channeling"}],
    "7": [{id:9,name:"Sub-Task 1 - Night Patrolling"}, {id:10,name: "Sub-Task 2 - Drone Surveillance"}],
    "8": [{id:11,name:"Sub-Task 1 - CCTV Installation"}, {id:12,name: "Sub-Task 2 - Monitoring"}],
  };
  schemeList: any=[];
  componentList: any= [];
  tableHeaders: string[] = [];
   dynamicFields: any[] = [];
  structuredData: any[] = [];
  selectedComponent: string | null = null;
  formTitle: any="";
  formDesc: any="";
  tableData: any[];
  dynamicColumns: any;

  constructor(private fb: FormBuilder,private notifyService: NotificationService,private apiService: ApiService, private cdr: ChangeDetectorRef) { 
    this.apoForm = this.fb.group({
      scheme: ["0", Validators.required],
      component: ["0", Validators.required],
      tasks: this.fb.array([])
    });
   
  }

  ngOnInit(): void {
    (function($){
      
    })(jQuery);
   this.getAllSchemeDD();
  }
  getAllSchemeDD() {
     this.apiService.request('GET', 'api/v1/APO/getAllSchemeDD').subscribe((data: any) => {
      if (data) {
        this.schemeList = data;
        console.log( this.schemeList)
      }
    });
  }
  getAllComponentDDByScheme(value:any) {
    if (value != '0') {
       this.apiService.request('GET', 'api/v1/APO/getAllComponentDDByScheme?schemeId=' + value).subscribe((data: any) => {
        if (data) {
          this.componentList = data;
          console.log( this.componentList)
        }
      });
    }
  }

  onSubmitform() {
    if (this.apoForm.valid) {
      console.log("Submitted Data:", this.apoForm.value);
    } else {
      alert("Please fill all required fields.");
    }
  }

//******************************************************************************** */
//   onSelectComponent(event:any){
//     const selectedComponentId = event.target.value;
//     this.selectedComponent = selectedComponentId;
//     console.log(this.fieldsByComponent);
//     this.dynamicFields =this.fieldsByComponent[selectedComponentId] || [];
//    this.tableHeaders =this.fieldsByComponent[selectedComponentId].map(field => field.label);
//     this.apiService.request('GET',  `api/v1/APO/getAllReportFormDetails?componentId=${selectedComponentId}`).subscribe({
//           next: (data:any) => {
//             console.log(data);
//             this.formTitle= data?.[0]?.reportName;
//             this.formDesc= data?.[0]?.about;
//              // Store API response data for table binding
//              if (data && data.length > 0) {
//               setTimeout(() => { // 🚀 Smoother UI updates
//                 this.processTableData(data[0].itemList);
//                 this.cdr.markForCheck(); // 🚀 Manually trigger change detection
//               }, 10);
//             }
//           }, error: (err:any) => {
//               console.error(err);
//           }
//       });
//        // Reset structured data and form
//       // this.generateStructuredData();
//   }
//   trackByIndex(index: number, item: any) {
//     return index; // 🚀 Improves performance
//   }
//   processTableData(itemList: any[]) {
//     this.tableData = []; // Reset previous data
//     this.dynamicColumns = this.dynamicFields.map(field => field.label); // Store column headers dynamically
  
//     itemList.forEach((item, itemIndex) => {
//       const itemRow = {
//         serial: (itemIndex + 1).toString(), // Item Number (1, 2, 3, ...)
//         itemName: `**${item.itemName}**`, // Bold for Items
//         isItem: true,
//       };
      
//        // Add dynamic fields to itemRow
//     this.dynamicFields.forEach(field => {
//       itemRow[field.name] = ''; // Initialize dynamic fields
//     });

//     this.tableData.push(itemRow);

  
//     item.taskList.forEach((task, taskIndex) => {
//       const taskRow = {
//         serial: `(${this.toRoman(taskIndex + 1)})`, // Convert to Roman (i, ii, iii, ...)
//         itemName: `*${task.taskName}*`, // Italic for Tasks
//         isTask: true,
//       };

//       this.dynamicFields.forEach(field => {
//         taskRow[field.name] = ''; // Initialize dynamic fields
//       });

//       this.tableData.push(taskRow);
//       task.subTaskList.forEach((subTask, subTaskIndex) => {
//         const subTaskRow = {
//           serial: `(${String.fromCharCode(97 + subTaskIndex)})`, // Convert to (a), (b), (c), ...
//           itemName: subTask.taskName,
//           isSubTask: true,
//         };

//         this.dynamicFields.forEach(field => {
//           subTaskRow[field.name] = ''; // Initialize dynamic fields
//         });

//         this.tableData.push(subTaskRow);
//       });
//     });
//   });

//   console.log("Table Data:", this.tableData);
//   console.log("Dynamic Columns:", this.dynamicColumns);
// }
// // Convert numbers to Roman numerals (1 -> i, 2 -> ii, 3 -> iii, ...)
// toRoman(num: number): string {
//   const romanNumerals = ['i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix', 'x'];
//   return romanNumerals[num - 1] || num.toString();
// }
//   get taskForms(): FormArray {
//     return this.apoForm.get('tasks') as FormArray;
//   }

}
