import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpResponse,HttpErrorResponse } from '@angular/common/http';
import {Observable, of, throwError} from "rxjs/index";
import { catchError,map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { NotificationService } from '../service/notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
 constructor(private AuthenticationService: AuthService,private notifyService:NotificationService, private router: Router) { }

 intercept(request: HttpRequest<any>, newRequest: HttpHandler): Observable<HttpEvent<any>> {

 return newRequest.handle(request).pipe(
   catchError(err =>{
              if(err.status===400){
                if(err.error.message=='invalidUser'){
                  this.notifyService.showError("Invalid Username.","Error");
                }else if(err.error.message=='invalidPassword'){
                  this.notifyService.showError("Invalid Password.","Error");
                }else if(err.error.message=='BadCredentials'){
                  this.notifyService.showError("Bad Credentials","Error");
                }
                //this.notifyService.showError("the server cannot process the request due to an issue from the client","400: Bad Request");
              }else if(err.status===401){
                this.notifyService.showError("Unauthorized","Error");
              }else if(err.status===403){
                this.notifyService.showError("Server will not authorize it","403: Forbidden");
              }
              else if(err.status===404){
                this.notifyService.showError("Server does not exist  ","404: Not Found");
              }
              else if(err.status===405){
                this.notifyService.showError("Resource requested by the you is not supported by the server","405: Method Not Allowed ");
              }
              else if(err.status===406){
                this.notifyService.showError("Not Acceptable  ","406: Not Acceptable");
              }
              else if(err.status===407){
                this.notifyService.showError("You must first be authenticated","407: Proxy Authentication Required");
              }
              else if(err.status===408){
                this.notifyService.showError("Timeout Error   ","408: Request Timeout");
              }
              else if(err.status===429){
                this.notifyService.showError("Too Many Requests ","429 :Error Status Code");
              }
              else if(err.status===500){
                this.notifyService.showError("The server encountered an issue and cannot process the request","500: Internal Server Error ");
              }
              else if(err.status===502){
                this.notifyService.showError("Bad Gateway ","502:Error Status Code ");
              }
              else if(err.status===503){
                this.notifyService.showError("The server is currently overloaded with requests or out of resources, is currently in maintenance, or possibly that the application they are trying to access is down, and the server unable to complete the request due to the current state ","503: Service unavailable");
              }
              else if(err.status===504){
                this.notifyService.showError("The response from an origin server takes too long to respond ","504: Gateway timeout ");
              } else if(err.status===0){
                this.notifyService.showError("Server not found","Error ");
                //this.AuthenticationService.logout();
              }else{
                this.notifyService.showError(err.status,"Error");
              }
              //if 401 response returned from api, logout from application & redirect to login page.
             // this.AuthenticationService.logout();

        const errors = err;
        return throwError(errors);
      })
    );
 }
}
