import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import {ApiService} from "../service/api.service";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { UploadService} from '../upload/upload.service';
import { AutologoutService } from '../service/autologout.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

const baseUrl = environment.API_URL;
const baseUrlFile = environment.FILE_API_URL;

declare var jQuery: any;

@Component({
  selector: 'app-masterheader',
  templateUrl: './masterheader.component.html',
  styleUrls: ['./masterheader.component.css']
})
export class MasterheaderComponent implements OnInit {
  //public loader: boolean=false;
  public username:any;
  profileData: any;
  profileImg: any;
  profileFirstName: any;
  profilelastName: any;
  imgUrl: any;
  base64Data: any;
  retrieveResonse: any;
  retrievedImage: string;
  userid: any;

  constructor( private apiService: ApiService,private autologout:AutologoutService, private uploadService: UploadService, private router: Router,private authService: AuthService) { }

  ngOnInit(): void {
   // this.loader=true;//set loader true for visible

      const userdata=sessionStorage.getItem('user');
      if(userdata!=null){
        this.autologout.check();
        this.autologout.initListener();
        this.autologout.initInterval();
        const user=JSON.parse(userdata);
        this.username=user.username;
        this.userid=user.userid;
        const profile=JSON.parse(sessionStorage.getItem('profile'));
        if(profile==null){
          this.loadProfile();
        }else{
          this.profileFirstName= profile.firstName;
          this.profilelastName= profile.lastName;
          if(profile.imgPath!=null){
                this.retrievedImage=baseUrlFile+'profile/'+profile.imgPath;
          }else{
            this.retrievedImage='assets/images/profile.png';
          }
        }
      }else{
       // this.loader=false;
        this.router.navigate(['']);
        return;
      }
    (function($){

      $(document).ready(function() {
        // Gets the video src from the data-src on each button
        var $videoSrc;
        $('.video-btn').click(function() {
            $videoSrc = $(this).data( "src" );
        });
        //console.log($videoSrc);

        // when the modal is opened autoplay it
        $('#myModal').on('shown.bs.modal', function (e) {

        // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
        $("#video").attr('src',$videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0" );
        })

        // stop playing the youtube video when I close the modal
        $('#myModal').on('hide.bs.modal', function (e) {
            // a poor man's stop video
            $("#video").attr('src',$videoSrc);
        })
      // document ready
      });

    })(jQuery);


   // this.loader=false;
  }
  defaultUrl() {
    this.retrievedImage='assets/images/profile.png';
  }
  loadProfile(){
      return this.apiService.request('GET', 'api/v1/auth/getuserById?loginId='+this.userid).subscribe((data: any) => {
        if (data) {
          sessionStorage.setItem('profile', JSON.stringify(data));
          this.profileFirstName= data.firstName;
          this.profilelastName= data.lastName;
          if(data.imgPath!=null){
                this.retrievedImage=baseUrlFile+'profile/'+data.imgPath;
          }else{
            this.retrievedImage='assets/images/profile.png';
          }
        }
      }, err => {
        this.retrievedImage='assets/images/profile.png';
      });
    }
  onLogout() {
    //console.log("logout");
    this.authService.logout();
  }
  public triggerTimeout(timeout:any){
    console.log(timeout);
 }
  hideChildModal(): void {

  }

  stay() {

  }

}
