<!-- <div class="loader-base"  *ngIf="loader"><div class="loader"  ></div></div> -->
<app-masterheader></app-masterheader>

<div class="wrapper">

    <app-sidebar></app-sidebar>

    <div id="content">

        <!-- <app-breadcrumb></app-breadcrumb> -->

        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">

                                    <h4 class="card-title">Profile Settings</h4>

                                    <form [formGroup]="profileForm" (ngSubmit)="updateProfile()">
                                      <input hidden formControlName="userId">
                                        <div class="row form-group">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="">First Name <span class="text-danger">*</span></label>
                                                    <input type="text" formControlName="firstName" class="form-control"   placeholder="First Name">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="">Middle Name</label>
                                                    <input type="text" formControlName="middleName" class="form-control"   placeholder="middle Name">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="">Last Name <span class="text-danger">*</span></label>
                                                    <input type="text" formControlName="lastName" class="form-control"   placeholder="Last Name">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row form-group">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="">Email ID <span class="text-danger">*</span></label>
                                                    <!-- <input type="email" formControlName="email" class="form-control" id="" placeholder="Email ID"> -->
                                                    <input type="email" formControlName="email"  [ngClass]="{ 'is-invalid': profileForm.controls['email'].invalid && (profileForm.controls['email'].dirty || profileForm.controls['email'].touched) }" class="form-control" id="email" placeholder="Email ID" (change)="emailCheckUnique($event.target.value)" >
                                                    <div *ngIf="profileForm.controls['email'].invalid && (profileForm.controls['email'].dirty || profileForm.controls['email'].touched)" class="text-danger mt-1">
                                                      <small *ngIf="profileForm.controls['email'].errors.required">
                                                          E-mail is required.
                                                      </small>
                                                        <small *ngIf="profileForm.controls['email'].errors.email">
                                                          Enter Valid E-mail
                                                        </small>
                                                    </div>
                                                  </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="">Mobile No. <span class="text-danger">*</span></label>
                                                    <input type="number" formControlName="userPhoneNumber" readonly class="form-control"  placeholder="Mobile Number">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                              <div class="form-group">
                                                  <label for="">Juridiction<span class="text-danger">*</span></label>
                                                  <input type="text" formControlName="juridicitionName"  readonly class="form-control"  placeholder="Juridiction Name">
                                              </div>
                                          </div>

                                        </div>
                                        <div class="row form-group">
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label for="">Profile Photo</label>
                                                    <span style="color:red;" *ngIf="message">{{message}}</span>
                                                    <input #file type="file"  accept='image/*' (change)="selectFile($event)"  class="form-control custom-file"  placeholder="Profile Photo">

                                                  </div>
                                                <!-- <div *ngIf="currentFileUpload" class="progress">
                                                  <div class="progress-bar progress-bar-info progress-bar-striped"
                                                    role="progressbar" attr.aria-valuenow="{{progress.percentage}}"
                                                    aria-valuemin="0" aria-valuemax="100"
                                                    [ngStyle]="{width:progress.percentage+'%'}">
                                                    {{progress.percentage}}%</div>
                                                </div> -->
                                            </div>
                                                <div class="col-md-1">
                                                    <img [src]="imgURL" *ngIf="imgURL" alt="image" class="img-fluid img-profile">
                                                </div>
                                            <div class="col-md-8">
                                                <div class="form-group">
                                                    <label for="">Address</label>
                                                    <textarea class="form-control custom-textarea"  formControlName="userAddress" rows="" placeholder="Address"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="row form-group">
                                            <div class="col-md-12 text-center">
                                                <button type="submit"  class="btn btn-sm btn-success">Save</button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>

    <div id="footer">
        <app-masterfooter></app-masterfooter>
    </div>

</div>

