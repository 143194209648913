<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>
<div class="loader-base" *ngIf="loader">
	<div class="loader"></div>
</div>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
					<li class="nav-item">
						<a id="tab-baligudamap" href="#pane-baligudamap" class="nav-link active" data-toggle="tab"
							role="tab">Map</a>
					</li>
					<li class="nav-item">
						<a id="tab-baligudainfo" href="#pane-baligudainfo" class="nav-link" data-toggle="tab"
							role="tab">Information</a>
					</li>
				</ul>

				<div id="accordion" class="tab-content" role="tablist">
					<div id="pane-baligudamap" class="card tab-pane fade show active" role="tabpanel"
						aria-labelledby="tab-baligudamap">
						<div class="card-header" role="tab" id="heading-baligudamap">
							<h5 class="mb-0">
								<a data-toggle="collapse" href="#collapse-baligudamap" aria-expanded="true"
									aria-controls="collapse-baligudamap">
									Map
								</a>
							</h5>
						</div>
						<div id="collapse-baligudamap" class="collapse show" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-baligudamap">
							<div class="card-body">
								<div class="map" id="baligudaWLmap">
									<div class="gle-icon">
										<a href="javascript:void(0)">
											<img src="assets/images/icon-street.png" (click)='streetchange()'
												class="img-fluid" alt="Street View" title="Street View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-satellite.png" class="img-fluid"
												(click)='stelitechange()' alt="Satellite View" title="Satellite View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-topo.png" class="img-fluid"
												(click)='topochange()' alt="Topo View" title="Topo View">
										</a>
									</div>
								</div>


							</div>
						</div>
					</div>

					<div id="pane-baligudainfo" class="card tab-pane fade" role="tabpanel"
						aria-labelledby="tab-baligudainfo">
						<div class="card-header" role="tab" id="heading-baligudainfo">
							<h5 class="mb-0">
								<a class="collapsed" data-toggle="collapse" href="#collapse-baligudainfo"
									aria-expanded="false" aria-controls="collapse-baligudainfo">
									Information
								</a>
							</h5>
						</div>
						<div id="collapse-baligudainfo" class="collapse" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-baligudainfo">
							<div class="card-body">
								<h4 class="card-title">
									Baliguda Forest Division
									<span class="custom-down">
										<button type="button" (click)="print()" class="btn btn-sm btn-info mr-1"><i
												class="fa fa-file-pdf">&nbsp;</i>Information</button>
										<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
												class="fa fa-file-pdf">&nbsp;</i>Map</button>
									</span>
								</h4>
								<div id="printcontent">
									<div class="row">
										<div class="col-md-9">
											<div class="table-responsive">
												<table class="table table-sm table-bordered">
													<tbody>
														<tr>
															<td><strong>District : </strong></td>
															<td>Kandhamal</td>
														</tr>
														<tr>
															<td><strong>Geographical Extent : </strong></td>
															<td>Longitude 83° 28'E and 84° 17'E & Latitude 20° 27' N and
																19° 33'N</td>
														</tr>
														<tr>
															<td><strong>Climate : </strong></td>
															<td>Summer :temp rises upto 40°C, Avg. Rainfall :1400mm,
																Winter :temp drops to 10°C)</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div class="col-md-3">
											<img src="assets/images/wildlifeunits-baliguda-01.jpeg"
												class="img-fluid float-right img-inner" alt="image">
										</div>
									</div>
									<p>Baliguda Forest Division situated in the Kandhamal district of Odisha. The
										Kotagarh Sanctuary is nestled in the Baliguda Forest Division.“Kotagarh Wild
										Life Sanctuary” came to force during December – 1981 by Govt. of Orissa.The
										dense lush green forests with sylvan and serene picturesque beauty is the major
										attraction of this sanctuary. A wooden bunglow at Belghar attracts many nature
										loving visitors.</p>
									<h4 class="card-title">Flora </h4>
									<p>Flora includes Moist Peninsular Sal Forests, Terminalia Tormentosa Forest ,Dry
										Bamboo Brakes,Moist Sal Savannah.The scattered tree species found in the over
										wood are Anogeissus latifolia, Xylia xylocarpa and Diospyros melanoxylon. The
										under wood found in the area are Cleistanthus collinus, Careya arborea, Dillenia
										pantagyna and Morinda tinctoria. The common ground flora consists of woodfordia
										fructicosa, holrrhena antidysnerica and Phoenix acaulis. The common climbers are
										Bauhinia vahelii and Milletia auriculata.</p>
									<h4 class="card-title">Fauna </h4>
									<p>Carnivores:Tigers, Leopards, Jungle Cats, Indian Mangoose, Wolf, Jackal, Hayna,
										Wild dog, Fox, Leopard cat, Small Indian mangoose, Common otter,Small clawed
										otter,Porcupine, Small Indian Civet. Herbivores: Wild bear, Spotted deer, Mouse
										deer, Indian Elephants, Barking deer, Monkey, Rhesus macaque, Rat, Flying
										squirrels, Samber, Rafous – tailed Hare, Fruit bats. Birds:Acridotheres tristis
										(Bani), Ploceus philippinus (Baya), Astur badius (Bajapakhi), Gallus gallus
										(Jungle fowl red), Gallus sonneratii (Grey fowl), Ergetta garzetta (Baga), Anser
										indicus (Barheaded goose), Coracus bengalensis (Bhadbhadalia), Milvus migrans
										(common kite), Amanronis phoenicurvus (Dhauka), Anastomus oseitans (Gendalia),
										Psser domesticus (Ghar chatia), Oriolus xanthornus (Haladi basanta), Dicrurus
										maorocerus (Kajalapati), Corvus macrorhyreos (Kau damara), Anthrococeros
										coronatus (Kochila khai), Eudynamis scolopaceus (Koili), Centropus sinensis
										(Kumbhatua), Pavo cristatus (Mayura), Athena brama (Pecha).</p>
									<h4 class="card-title">Set-Ups</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td><strong>Administrative set-up (Range, Section, beats) :</strong>
													</td>
													<td>Ranges- 8, Sections- 25,Beats- 76</td>
												</tr>
												<tr>
													<td><strong>EDC / JFM set-up: </strong></td>
													<td>
														EDCs - 0, VSS - 0
													</td>
												</tr>
												<tr>
													<td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
													<td>No. of RF- 0, No. of PRF- 0, No. of DPF- 0, No. of UDPF- 0, No.
														of VF- 0</td>
												</tr>
											</tbody>
										</table>
									</div>
									<h4 class="card-title">Major Attraction</h4>
									<div class="row">
										<div class="col-md-12">
											<img src="assets/images/wildlifeunits-baliguda-02.jpg"
												class="img-fluid float-right img-inner" alt="image">
											<ul class="custom-list">
												<li>Elephant is the major attraction of the division.</li>
												<li>It has attractions, like panoramic coffee gardens, pine jungles,Ghat
													roads, hills and water falls, virigin forest and typical tribal
													village life.</li>
												<li>The region is proud of its rich cultural heritage.</li>
												<li>Daringbadi :Daringbadi, a vast area at the height of about 3000ft
													above sea level is an ideal summer resort which is popularly named
													as 'Kashmir of Orissa'.</li>
											</ul>
										</div>
									</div>
									<h4 class="card-title">Salient Detail</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td width="15%"><strong>HQ Address :</strong></td>
													<td width="85%">At/Po.- Balliguda,Dist.- Kandhamal,PIN- 762103</td>
												</tr>
												<tr>
													<td><strong>Contact officer : </strong></td>
													<td>
														<span class="address-inner"><i
																class="fa fa-user">&nbsp;</i></span>Divisional Forest
														Officer<br>
														<span class="address-inner"><i
																class="fa fa-map-marker">&nbsp;</i></span>Baliguda
														Forest Division, Baliguda, Kandhamal<br>
													</td>
												</tr>
												<tr>
													<td><strong>Key activities : </strong></td>
													<td>Habitat Improvement,Meadow development,creation of water
														harvesting structures etc.</td>
												</tr>
												<tr>
													<td><strong>Programmes : </strong></td>
													<td>Government programmes,educational programme,Health Awareness
														programmes,cattle immunization programme etc</td>
												</tr>
												<tr>
													<td><strong>Places to see : </strong></td>
													<td>Kotagarh sanctuary, Daringbadi,etc</td>
												</tr>
											</tbody>
										</table>
									</div>
									<p class="text-danger"><small><i
												class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
											</strong>The information contained herein are provided solely for
											informational purposes only.The content is dynamic and may undergo change
											from time to time. The snapshot of the content at a particular time is not
											to be referred as a legally binding document.</small></p>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>