<app-header *ngIf="isDashboard"></app-header>
<app-menu *ngIf="isDashboard"></app-menu>

<section class="common-section">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">

				<ul id="tabs" class="nav nav-tabs" role="tablist">
					<li class="nav-item">
						<a id="tab-bhadrakmap" href="#pane-bhadrakmap" class="nav-link active" data-toggle="tab"
							role="tab">Map</a>
					</li>
					<li class="nav-item">
						<a id="tab-bhadrakinfo" href="#pane-bhadrakinfo" class="nav-link" data-toggle="tab"
							role="tab">Information</a>
					</li>
				</ul>

				<div id="accordion" class="tab-content" role="tablist">
					<div id="pane-bhadrakmap" class="card tab-pane fade show active" role="tabpanel"
						aria-labelledby="tab-bhadrakmap">
						<div class="card-header" role="tab" id="heading-bhadrakmap">
							<h5 class="mb-0">
								<a data-toggle="collapse" href="#collapse-bhadrakmap" aria-expanded="true"
									aria-controls="collapse-bhadrakmap">
									Map
								</a>
							</h5>
						</div>
						<div id="collapse-bhadrakmap" class="collapse show" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-bhadrakmap">
							<div class="card-body">

								<div id="bhadrakWLmap" class="map">
									<div class="gle-icon">
										<a href="javascript:void(0)">
											<img src="assets/images/icon-street.png" (click)='streetchange()'
												class="img-fluid" alt="Street View" title="Street View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-satellite.png" class="img-fluid"
												(click)='stelitechange()' alt="Satellite View" title="Satellite View">
										</a>
										<a href="javascript:void(0)">
											<img src="assets/images/icon-topo.png" class="img-fluid"
												(click)='topochange()' alt="Topo View" title="Topo View">
										</a>
									</div>
								</div>

							</div>
						</div>
					</div>

					<div id="pane-bhadrakinfo" class="card tab-pane fade" role="tabpanel"
						aria-labelledby="tab-bhadrakinfo">
						<div class="card-header" role="tab" id="heading-bhadrakinfo">
							<h5 class="mb-0">
								<a class="collapsed" data-toggle="collapse" href="#collapse-bhadrakinfo"
									aria-expanded="false" aria-controls="collapse-bhadrakinfo">
									Information
								</a>
							</h5>
						</div>
						<div id="collapse-bhadrakinfo" class="collapse" data-parent="#accordion" role="tabpanel"
							aria-labelledby="heading-bhadrakinfo">
							<div class="card-body">


								<h4 class="card-title">
									Bhadrak Wildlife Division
									<span class="custom-down">
										<button type="" (click)="print()"
											class="btn btn-sm btn-info mr-1"><i
												class="fa fa-file-pdf">&nbsp;</i>Information</button>
										<button class="btn btn-sm btn-info" (click)="downloadKmlFile()"><i
												class="fa fa-file-pdf">&nbsp;</i>Map</button>
									</span>
								</h4>
								<div id="printcontent">
									<div class="row">
										<div class="col-md-9">
											<div class="table-responsive">
												<table class="table table-sm table-bordered">
													<tbody>
														<tr>
															<td><strong>District : </strong></td>
															<td>Bhadrak</td>
														</tr>
														<tr>
															<td><strong>Geographical Extent : </strong></td>
															<td>Longitude 86° 14' E to 87° 05' E & Latitude 20° 43' N to
																21° 15' N</td>
														</tr>
														<tr>
															<td><strong>Climate : </strong></td>
															<td>maximum temp- 39° C and minimum temp- 14°C</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div class="col-md-3">
											<img src="assets/images/wildlifeunits-bhadrak-01.jpg"
												class="img-fluid float-right img-inner" alt="image">
										</div>
									</div>
									<p>Bhadrak (Wildlife) Division came into existence with effect from 1st October,
										2003 in pursuant to reorganization of Forest & Environment Department
										Notification No.1F (A)-100/2003-13228 dated 08th August, 2003 of Govt. of
										Orissa, F&E Department. This Division was formed by carving out a portion of the
										Baripada Forest Division and a portion of Mangrove (Wildlife) Forest Division,
										Rajnagar with headquarters at Bhadrak. The Jurisdiction of Bhadrak (Wildlife)
										Division is the entire geographical area of Bhadak Revenue District and thus
										boundary of the Division is co-terminus with boundary of the Revenue District.
										The Headquarters of Bhadrak (Wildlife) Division is presently located at
										Chandbali with effect from 05.04.2006. However, the Divisional Forest Officer is
										attending the camp office at Bhadrak three days a week as per Govt. of Odisha,
										F&E Department Notification No.1F (A) Misc-2/2013-4674 dated 04th March, 2013.
										As per district statistical report the total Geographical area of the Division
										is 2505 Sq. Km. and forest area is 53.32 Sq. Km. This Division has four Ranges,
										their head quarter at Bhadrak, Dhamanagar, Chandbali & Basudevpur respectively.
									</p>
									<h4 class="card-title">Flora </h4>
									<p>The forest cover of this Division is almost pure mangrove forest and its few
										associates. The important species present including plantations are Avicennia
										alba, Ipomoea pes-carpae, Canavalia maritime, Hydrophlyx maritime, Sesuvium
										portulacastrum, Cascuta reflexa, Avicennia marina, Avicennia officinalis,
										Sonneratia alba, Sueda maritime, Sesuvium portulacastrum, Casuarina
										equistifolium, Azadirachata indica, Pongamia pinnata, Opuntia stricta, Ficus
										bengalensis, Calotropis gigantia, Ziziphus oenoplia, Acacia auriculiformis,
										Tamarindus indica, Borassus flabellifer, Sueda nudiflora, Vernonia cinera,
										Tylophora tenuissima, Ipomoea tuba etc.</p>
									<h4 class="card-title">Fauna </h4>
									<p>The mangroves support rich marine life including crabs, prawns, mudskippers and
										variety of fishes which form the base of biological pyramid. Besides, mangrove
										sustains a wide range of reptiles like watermonitor lizards, pythons, king
										cobras, kraits, other snakes and mammals such as fishing cat, mongoose, otter
										etc. The mangrove wetland serve as a potential habitat for variety species of
										birds. Apart from that, the rivers like Baitarani, Mantei and Salandi supports
										fauna like crocodiles.</p>
									<h4 class="card-title">Set-Ups</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td><strong>Administrative set-up (Range, Section, beats) :</strong>
													</td>
													<td>Ranges- 4, Sections- 10,Beats- 31</td>
												</tr>
												<tr>
													<td><strong>EDC/JFM set-up: </strong></td>
													<td>
														EDCs - 20, VSS - 44
													</td>
												</tr>
												<tr>
													<td><strong>Management set-up (RF/PRF/DPF/UDPF/VFs) : </strong></td>
													<td>No. of RF- 0, No. of PRF- 1, No. of DPF- 0, No. of UDPF- 9, No.
														of VF- 21</td>
												</tr>
											</tbody>
										</table>
									</div>
									<h4 class="card-title">Major Attraction</h4>
									<div class="row">
										<div class="col-md-12">
											<img src="assets/images/wildlifeunits-bhadrak-02.jpg"
												class="img-fluid float-right img-inner" alt="image">
											<ul class="custom-list">
												<li>Aradi: On the bank of river Baitarani about 35 KM from Bhadrak,
													Akhandalamani Temple is the abode of Lord Shiva. The chief interest
													of the place is its legendary history for which it shares a
													significant position in the tourism.</li>
												<li>Aharapada: The Temple of the Goddess Bhadrakali is located in the
													outskirts about 10 KM from Bhadrak town. As per the nomenclature of
													the goddess one tends to believe that it is the goddess Kali who is
													being worshiped in this Bhadrakali Pitha. Since the deity is seated
													on a lion, one school of thought has opined that the goddess can be
													no other than the goddess Durga.</li>
												<li>Palia: Sri Biranchinarayan Temple is in Palia village, which is
													located 15 KM south of Bhadrak, on the way from Bhadrak to
													Chandabali. The standing Biranchi Narayan Temple stands as evidence
													of the heritage of Surya Upasana in Odisha. Biranchi Narayan is
													another name for Lord Surya, the Sun God of light and lustre.
													Architecturally, the temple can be dated to the 13th century. It was
													renovated and reconstructed in the beginning of the 20th century by
													the generosity of a local zamindar.</li>
												<li>Dhamara: The temple of the Goddess Dhamrai is at Dhamara a small
													coastal town-ship, where the river Dhamra meets the Bay of Bengal.
													The place is about 60 KMs from the Bhadrak town in the eastern
													direction. The temple built by the then king Sailendra Narayan
													Bhanjadeo by the year 1953 got destroyed and the remains even was
													washed away into the sea. Subsequently, it has been renovated and
													people worshiping the deity.</li>
												<li>Dhamara port: Dhamara port is the second port of this district which
													is about 90 KMs away from Bhadrak town. Dhamra Port is well
													sheltered between the mainland and Kanika Sands Island on the mouth
													of the river Dhamra. The location of Dhamra is in close proximity to
													the mineral belt of Orissa, Jharkhand and West Bengal and its
													dedicated connectivity will help serve this hinterland with the
													greatest efficiency. It occupies a distinct place in Odisha's trade
													and navigation.</li>
												<li>Chandbali Port: It is an ancient port on the bank of river
													Baitarani, around 55 KMs from Bhadrak town. At present it acts as
													entry point for the tourists to Bhitarkanika National Park through
													river system.</li>
												<li>Eram: This famous place of sacrifice located at about 50 KM away
													from Bhadrak town. One side of this land is bounded by the Bay of
													Bengal and the other three sides are surrounded by two rivers the
													Gameye and Kansabansa. Secured by these natural boundaries this
													place is used as a citadel by the freedom fighters. Eram is
													popularly known as Rakta Tirtha (The Pilgrim of Blood).</li>
											</ul>
										</div>
									</div>
									<h4 class="card-title">Salient Detail</h4>
									<div class="table-responsive">
										<table class="table table-sm table-bordered">
											<tbody>
												<tr>
													<td><strong>HQ Address :</strong></td>
													<td>Bhadrak Wildlife Division, Chandabali At/P.O./P.S- Chandbali,,
														Dist- Bhadrak, Odisha</td>
												</tr>
												<tr>
													<td><strong>Contact officer : </strong></td>
													<td>
														<span class="address-inner"><i
																class="fa fa-user">&nbsp;</i></span>Divisional Forest
														Officer<br>
														<span class="address-inner"><i
																class="fa fa-map-marker">&nbsp;</i></span>Bhadrak
														Wildlife Division, Chandbali<br>
													</td>
												</tr>
												<tr>
													<td><strong>Key activities : </strong></td>
													<td>Forest & Wild animal protection, awareness for ecological
														preservation, plantations, etc</td>
												</tr>
												<tr>
													<td><strong>Programmes : </strong></td>
													<td>Conservation awareness activities, Wildlife health programmes,
														Eco- Development programmes, etc.</td>
												</tr>
												<tr>
													<td><strong>Places to see : </strong></td>
													<td>Aradi, Aharapada, Palia, Eram, Dhamara, Chandbali etc.</td>
												</tr>
											</tbody>
										</table>
									</div>
									<p class="text-danger"><small><i
												class="fa fa-exclamation-circle">&nbsp;</i><strong>Disclaimer :
											</strong>The information contained herein are provided solely for
											informational purposes only.The content is dynamic and may undergo change
											from time to time. The snapshot of the content at a particular time is not
											to be referred as a legally binding document.</small></p>
								</div>
								<!-- <div class="map-inner">
			                		<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1610252.4737363283!2d83.51504235529215!3d20.37937053780591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a226aece9af3bfd%3A0x133625caa9cea81f!2sOdisha!5e0!3m2!1sen!2sin!4v1606980374061!5m2!1sen!2sin" width="100%" height="550" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
			                	</div> -->
							</div>
						</div>
					</div>

				</div>

			</div>
		</div>
	</div>
</section>

<app-footer *ngIf="isDashboard"></app-footer>
